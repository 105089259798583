/* Built-in imports */
import React from 'react';

/* Third-party imports */
import { MenuItem } from '@mui/material';

/* Internal imports */
import styles from '../../../styles/DS/inputs.styles'
import PropTypes from 'prop-types';

const OutlinedSelectMenuItem = ({ children, value, ...other }) => {
  return(
    <MenuItem
      sx={styles.OutlinedSelectMenuItem}
      value={value}
      {...other}
    >
      {children}
    </MenuItem>
  );
};

OutlinedSelectMenuItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default OutlinedSelectMenuItem;
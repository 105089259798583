export const PPE_FORM_FIELD = {
  IS_PPE: 'isPpe',
  IS_PROCHE_PPE: 'isProchePpe',
  // PPE
  PAYS: 'paysExercice',
  FONCTION: 'fonction',
  FIN_FONCTION: 'finFonction',
  // PROCHE PPE
  NOM_PPE: 'nomPpe',
  PRENOM_PPE: 'prenomPpe',
  LIEN_PPE: 'lienPpe',
  PAYS_PPE: 'paysExercicePpe',
  FONCTION_PPE: 'fonctionPpe',
  FIN_FONCTION_PPE: 'finFonctionPpe'
};

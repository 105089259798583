export const MAXWIDTH = {
  TINY: '10rem',
  SMALL: '15rem',
  MEDIUM: '20rem',
  LARGE: '30rem',
}

export const TYPE = {
  DEFAULT: 'information',
  HELP: 'question',
}

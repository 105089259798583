/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import CustomeModal from '../../../../components/yes/utils/CustomeModal';
import YesLoader from '../../../../components/yes/utils/Loader';
import { URLS_ASSURANCE } from '../../../../utils/constantes/ROUTES/ASSURANCE';
import { addNotification } from '../../../../modules/notification/redux/notificationActions';
import { creerLiasseSouscription } from '../../../../services/LiasseService';
import { redirectDocuSign } from '../../../../utils/routes/RoutesUtils';
import { setLoader, setLiasse } from '../../../../modules/tunnel/redux/tunnelActions';

class AssuranceVerificationWLFPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToInformations: false,
      redirectToTarification: false,
      isWlfLocked: false,
      WlfMessage: ''
    };
  }

  componentDidMount() {
    const redirectUrl = window.location.origin + URLS_ASSURANCE.FINALISATION

    this.props.setLoader(true);
    creerLiasseSouscription({ uid: this.props.tunnel.dossier.uid })
      .then((liasse) => this.props.setLiasse(liasse))
      .then(() => {
        this.props.setLoader(false);
        this.setState({ redirectToTarification: !redirectDocuSign(this.props.tunnel.liasse, redirectUrl) });
      })
      .catch((error) => {
        this.props.setLoader(false);
        if (error && error.code === 'BLOQUAGE_WLF') {
          this.WlfMessage = error.message;
          this.handleOpen();
        } else {
          this.redirectToTarification();
        }
      });
  }

  redirectToTarification() {
    this.setState({
      redirectToTarification: true
    });
  }

  handleOpen = () => this.setState({ isWlfLocked: true });

  handleClose = () => {
    this.setState({ isWlfLocked: false });
    window.location = process.env.REACT_APP_STATIC_URL;
  };

  render() {
    const { redirectToTarification } = this.state;

    if (redirectToTarification) {
      return <Redirect to={URLS_ASSURANCE.TARIFICATION} />;
    }

    return (
      <div className="recapitulatifTunnel">
        <CustomeModal isOpen={this.state.isWlfLocked} onClose={this.handleClose}>
          <div dangerouslySetInnerHTML={{ __html: this.WlfMessage }} />
        </CustomeModal>
        <YesLoader />
      </div>
    );
  }
}

AssuranceVerificationWLFPage.propTypes = {
  setLoader: PropTypes.func.isRequired,
  tunnel: PropTypes.object
};

// REDUX + REDUX FORM
const mapStateToProps = ({ tunnel }) => ({
  tunnel
});

const mapDispatchToProps = {
  setLoader,
  setLiasse,
  addNotification
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssuranceVerificationWLFPage);

import React from 'react';
import PropTypes from 'prop-types';

const CustomeModal = ({ isOpen, onClose, children }) =>
  isOpen && (
    <div className="modal" aria-hidden="true" onClick={onClose}>
      <div className="modal-dialog">
        <div className="modal-header">
          <h2>INFORMATION</h2>
          <button className="close-button" type="button" onClick={onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">{children}</div>
        <div className="modal-footer">
          <button className="btn-modal" type="button" onClick={onClose}>
            FERMER
          </button>
        </div>
      </div>
    </div>
  );

CustomeModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.object
};

export default CustomeModal;

/* Built-in imports */
import React, { useEffect } from "react";

/* Third-party imports */
import { Field, formValueSelector, reduxForm } from "redux-form";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";

/* Internal imports */
import config from "../../../../../common/StaticSiteConfig";
import { BOOLEAN } from "../../../../../common/constants";
import { TransHtmlText } from "../../../../yes/utils/TransHtmlText";
import { YesButton } from "../../../../form/Button";
import { YesChoix } from "../../../../form/Choix";
import { isMessagesAccompagnementAccordsEnabled } from "../../../../../services/TenantService";
import { stringToBoolean } from "../../../../../common/FunctionsUtils";

const MaroquinerieInteressementForm = ({ interessement, accompagnementInteressement, change, handleSubmit, pristine, invalid, submitting }) => {

  useEffect(() => {
    if (interessement) {
      change('accompagnementInteressement', interessement.interessementActive ? BOOLEAN.YES : BOOLEAN.NO);
    }
  }, [interessement]);

  return (
    <form onSubmit={handleSubmit} className="formTunnel formInteressement">
      <label className="titreFormulaire" htmlFor="MaroquinerieInteressementForm">
        <Trans i18nKey="interessement.titre" />
      </label>

      <div className="grid-x grid-margin-x">
        <div className="cell medium-12">
          <h3>
            <TransHtmlText i18nKey="interessement.question.miseEnPlace" params={{ config: config }} />
          </h3>

          <div className="tunnelRadios">
            <div className="tunnelRadio">
              <Field
                name="accompagnementInteressement"
                component={YesChoix}
                type="radio"
                value={'oui'}
                label="Oui"
                id="accompagnementInteressement-oui"
              />
            </div>

            <div className="tunnelRadio">
              <Field
                name="accompagnementInteressement"
                component={YesChoix}
                type="radio"
                value={'non'}
                label="Non"
                id="accompagnementInteressement-non"
              />
            </div>
          </div>
        </div>
      </div>

      {stringToBoolean(accompagnementInteressement) && isMessagesAccompagnementAccordsEnabled() && (
        <div className="cell medium-12 messageAccompagnement">
          <TransHtmlText i18nKey="interessement.message.accompagnement.accordsMaroquinerie" params={{ config: config }} />
        </div>
      )}

      <div className="buttonCreationContainer">
        <YesButton text="Confirmer" disabled={invalid || submitting || pristine} />
      </div>
    </form>
  );
}

MaroquinerieInteressementForm.propTypes = {
  interessement: PropTypes.object.isRequired,
  accompagnementInteressement:PropTypes.string,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
}

// REDUX + REDUX FORM
const selectInteressementForm = formValueSelector('MaroquinerieInteressementForm');

const mapStateToProps = (state) => ({
  interessement: state.tunnel.dossier.interessement,
  accompagnementInteressement: selectInteressementForm(state, 'accompagnementInteressement'),
});

export default connect(mapStateToProps)(reduxForm({ form: 'MaroquinerieInteressementForm' })(MaroquinerieInteressementForm));

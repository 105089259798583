
const boxContainer = {
  width: '80%',
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '24px 16px',
  border: '1px solid #d0d7de',
  borderRadius: '5px'
};

const styles = {
  boxContainer
};

export default styles;
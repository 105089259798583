import React, { Component } from "react";
import NotificationContainer from "../../components/yes/notification/Notification";
import YesContact from "../../components/yes/utils/Contact";
import YesLoader from "../../components/yes/utils/Loader";
import { connect } from "react-redux";
import config from "../../common/StaticSiteConfig";
import { getSvgEnFonctionDuTenant } from "../../services/TenantService";

class CompteLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { illu: "" };
    this.handlerIllustration = this.handlerIllustration.bind(this);
  }

  handlerIllustration(illuChild) {
    this.setState({
      illu: illuChild
    });
  }

  render() {
    const { illu } = this.state;
    const urlIllu = getSvgEnFonctionDuTenant(
      "/assets/img/illustrations/",
      "/" + illu + ".svg"
    );
    const childrenWithIlluHandler = React.Children.map(
      this.props.children,
      child => {
        return React.cloneElement(child, {
          handlerIllustration: this.handlerIllustration
        });
      }
    );

    let classLayout = "App-compte grid-x";

    if (this.props.tunnel.loader) {
      classLayout = classLayout + " layoutDisable";
    }

    return (
      <React.Fragment>
        <NotificationContainer />
        <YesLoader />
        <div className={classLayout}>
          <div className="grid-container tunnel-container cell medium-7">
            <div className="grid-x grid-margin-x tunnel-ctn">
              <div className="cell medium-12 imageContainer">
                <a href={config.siteUrl}>
                  <img
                    className="logoTunnelYomoni"
                    width="50%"
                    src={getSvgEnFonctionDuTenant(
                      "/assets/img/logos/logo-",
                      "-tunnel.svg"
                    )}
                    alt="Yomoni"
                  />
                </a>
              </div>

              <div className="cell medium-12">
                <main className="App-intro">{childrenWithIlluHandler}</main>
              </div>
            </div>
          </div>

          <div
            className={
              "yes-illustration-container cell medium-5 show-for-medium " + illu
            }
          >
            <YesContact />
            <span className="show-for-medium helper-illu" />
            <img className="illu" src={urlIllu} alt="compte utilisateur" />
            <p className="show-for-medium conditionsUtilisations">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={config.mentionsLegalesUrl}
              >
                Politique de confidentialité
              </a>{" "}
              -
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={config.infosReglementairesUrl}
              >
                Conditions d’utilisation
              </a>
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompteLayout);

import { fetch } from './ResourcesService';
import { PREFIX_BACK } from '../common/Configuration';

export const creerCompteUtilisateur = ({ values }) =>
  fetch(false, PREFIX_BACK + 'utilisateurs', {
    method: 'POST',
    body: JSON.stringify(values)
  });

export const connexion = ({ values }) =>
  fetch(false, PREFIX_BACK + 'utilisateurs/connecter', {
    method: 'POST',
    body: JSON.stringify(values)
  });

export const deconnecter = (values) =>
  fetch(true, PREFIX_BACK + 'utilisateurs/deconnecter', {
    method: 'POST',
    body: JSON.stringify(values)
  });

export const rafraichirToken = () =>
  fetch(true, PREFIX_BACK + 'donnees/rafraichirJwtToken', {
    method: 'POST'
  });

export const demandeChangementMotDePasse = ({ values }) =>
  fetch(false, PREFIX_BACK + 'utilisateurs/demandeChangementMotDePasse', {
    method: 'POST',
    body: JSON.stringify(values)
  });

export const changerMotDePasseOublie = ({ values }) =>
  fetch(false, PREFIX_BACK + 'utilisateurs/motDePasse/reinitialiser', {
    method: 'PUT',
    body: JSON.stringify(values)
  });

export const getJwtContributeur = () => fetch(true, PREFIX_BACK + 'contributeurs/ssoIn');

/* built-in imports */
import React, { useEffect } from 'react';

/* third-party imports */
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

/* Internal imports */
import Alert from '../../DS/feedback/Alert';
import FormLabel from '../../DS/inputs/form/FormLabel';
import Switch from '../../DS/inputs/Switch';
import TextField from '../../DS/inputs/TextField'
import Typography from '../../DS/data-display/Typography';
import renderCheckbox from '../../DS/inputs/reduxForm/RenderCheckbox';
import {
  isSubscriptionReasonsError,
  isRetirementContractOptionsError
} from '../../../utils/US/components/workAdvicesSheetsModal/workAdvicesSheetsContractForm'

const renderSwitch = ( { checked, input } ) => (
  <Switch
    inputProps={{ role: 'switch' }}
    checked={checked}
    {...input}
  />
)

const renderTextField = ({ input, label, placeholder, ...custom }) => (
  <TextField
    sx={{ display: 'flex'}}
    label={label}
    placeholder={placeholder}
    variant="filled"
    {...input}
    {...custom}
  />
)

const WorkAdvicesSheetsContractForm = (props) => {
  const tradKey = 'components.US.form.work-advices-sheets-contract-form.radio.treat',
    { t } = useTranslation(),
    {
      isRetirementContract,
      retirementContractOptions,
      subscriptionReasons,
      handleIsRetirementContractChange,
      handleRetirementContractOptionsChange,
      handleSubscriptionsReasonsChange,
      showErrors,
    } = props;

  useEffect(() => {
    props.change("contract-retirement-other-contract", retirementContractOptions.otherContract);
  });

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: '1em'
          }}
        >
          <FormGroup
            sx={{
              flexDirection: 'row',
              flexGrow: 1,
              alignItems: 'center',
              flexWrap: 'nowrap',
            }}
          >
            <FormLabel
              sx={{ flexGrow: 1 }}
            >
              {t(`${tradKey}.label`)}
            </FormLabel>
            <Stack
              direction="row"
              alignItems="center"
            >
              <Typography>{t('utils.form.radio.no')}</Typography>
              <Field
                name="retirement-contract"
                component={renderSwitch}
                onChange={handleIsRetirementContractChange}
                checked={isRetirementContract}
              />
              <Typography>{t('utils.form.radio.yes')}</Typography>
            </Stack>
          </FormGroup>
        </Grid>
        {isRetirementContract &&
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              marginTop: '1em'
            }}
          >
            <Grid item xs={12}>
              <Field
                name="contract-retirement-collective-perco"
                component={renderCheckbox}
                onChange={(event) => handleRetirementContractOptionsChange(event, 'isRetirementCollectivePerco')}
                label={t(`${tradKey}.checkbox.options.collective.pero.label`)}
                checked={retirementContractOptions.isRetirementCollectivePerco}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="contract-retirement-collective-required-perco"
                component={renderCheckbox}
                onChange={(event) => handleRetirementContractOptionsChange(event, 'isRetirementCollectiveRequiredPerco')}
                label={t(`${tradKey}.checkbox.options.required.perco.label`)}
                checked={retirementContractOptions.isRetirementCollectiveRequiredPerco}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="contract-retirement-collective-percol"
                component={renderCheckbox}
                onChange={(event) => handleRetirementContractOptionsChange(event, 'isRetirementCollectivePercol')}
                label={t(`${tradKey}.checkbox.options.collective.percol.label`)}
                checked={retirementContractOptions.isRetirementCollectivePercol}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="contract-retirement-defined-contributions-article-83"
                component={renderCheckbox}
                onChange={(event) => handleRetirementContractOptionsChange(event, 'isRetirementArt83')}
                label={t(`${tradKey}.checkbox.options.defined-contributions.label`)}
                checked={retirementContractOptions.isRetirementArt83}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="contract-retirement-defined-prestations-article-39"
                component={renderCheckbox}
                onChange={(event) => handleRetirementContractOptionsChange(event, 'isRetirementArt39')}
                label={t(`${tradKey}.checkbox.options.defined-prestations.label`)}
                checked={retirementContractOptions.isRetirementArt39}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="contract-retirement-other-contract"
                label={t(`${tradKey}.checkbox.options.other.label`)}
                placeholder={t(`${tradKey}.checkbox.options.other.placeholder`)}
                component={renderTextField}
                onChange={(event) => handleRetirementContractOptionsChange(event, 'otherContract')}
                value={retirementContractOptions.otherContract}
              />
            </Grid>
            { isRetirementContractOptionsError(retirementContractOptions) && showErrors &&
              <Grid item xs={12} sx={{ marginTop: '.5em' }}>
                <Alert sx={{ alignItems: 'center' }} severity="error">{t('utils.form.errors.one.field.required')}</Alert>
              </Grid>
            }
          </Box>
        }

        <Divider
          sx={{
            flex: '0 0 100%',
            marginTop: '2em',
            marginBottom: '1em',
            borderColor: 'var(--cn-color-primary)'
          }}
        />

        <Grid
          item
          xs={12}
          sx={{
            marginTop: '1em'
          }}
        >
          <FormControl
            component="fieldset"
          >
            <FormLabel
              component="legend"
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {t(`${tradKey}.subscription`)}
            </FormLabel>
          </FormControl>
        </Grid>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginTop: '1em'
          }}
        >
          <Grid item xs={12}>
            <Field
              name="perob-to-pacte"
              component={renderCheckbox}
              onChange={(event) => handleSubscriptionsReasonsChange(event, 'isPerobToPacte')}
              label={t(`${tradKey}.subscription.checkbox.pacte-law`)}
              checked={subscriptionReasons.isPerobToPacte}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="capital-to-savings"
              component={renderCheckbox}
              onChange={(event) => handleSubscriptionsReasonsChange(event, 'isCapitalToSavings')}
              label={t(`${tradKey}.subscription.checkbox.capital`)}
              checked={subscriptionReasons.isCapitalToSavings}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="payments-voluntary-punctual"
              component={renderCheckbox}
              onChange={(event) => handleSubscriptionsReasonsChange(event, 'isPaymentsVoluntaryPunctual')}
              label={t(`${tradKey}.subscription.checkbox.voluntary-payment`)}
              checked={subscriptionReasons.isPaymentsVoluntaryPunctual}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="payments-savings"
              component={renderCheckbox}
              onChange={(event) => handleSubscriptionsReasonsChange(event, 'isPaymentsSavings')}
              label={t(`${tradKey}.subscription.checkbox.deposit`)}
              checked={subscriptionReasons.isPaymentsSavings}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="investments"
              component={renderCheckbox}
              onChange={(event) => handleSubscriptionsReasonsChange(event, 'isInvestments')}
              label={t(`${tradKey}.subscription.checkbox.payment-euros`)}
              checked={subscriptionReasons.isInvestments}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="employees-management-choice"
              component={renderCheckbox}
              onChange={(event) => handleSubscriptionsReasonsChange(event, 'isManagementChoice')}
              label={t(`${tradKey}.subscription.checkbox.employee-choice`)}
              checked={subscriptionReasons.isManagementChoice}
            />
          </Grid>
          { isSubscriptionReasonsError(subscriptionReasons) && showErrors &&
            <Grid item xs={12} sx={{ marginTop: '.5em' }}>
              <Alert sx={{ alignItems: 'center' }} severity="error">{t('utils.form.errors.one.field.required')}</Alert>
            </Grid>
          }
        </Box>
      </Grid>
    </Box>
  );
}

WorkAdvicesSheetsContractForm.propTypes = {
  isRetirementContract: PropTypes.bool.isRequired,
  retirementContractOptions: PropTypes.object.isRequired,
  subscriptionReasons: PropTypes.object.isRequired,
  handleIsRetirementContractChange: PropTypes.func.isRequired,
  handleRetirementContractOptionsChange: PropTypes.func.isRequired,
  handleSubscriptionsReasonsChange: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: 'WorkAdvicesSheetsContractForm'
})(WorkAdvicesSheetsContractForm)

import {
  DOCUMENTS,
  DOCUMENTS_ANNEXE_ASSURANCE,
  DOUCMENTS_IDENTITE_DIRIGEANT,
  TYPE_DOCUMENT
} from '../common/Configuration';

/**
 * Retourne le tenant courant
 * @param url
 * @param file
 * @returns {string}
 */
export const getTenant = () => process.env.REACT_APP_ID;

/**
 * Retourne le tenant courant en uppercase
 * @param url
 * @param file
 * @returns {string}
 */
export const getTenantUppercase = () => process.env.REACT_APP_ID.toUpperCase();

/**
 * Retourne le svg avec la bonne charte graphique en fonction du tenant
 * @param url
 * @param file
 * @returns {string}
 */
export const getSvgEnFonctionDuTenant = (url, file) => url + getTenant() + file;

/**
 * Activation ou non des pages d'authentification
 * @returns {boolean}
 */
export const isPageDeConnexionEnabled = () => process.env.REACT_APP_CANAUX_ALIMENTATION_ENABLED === 'true';

/**
 * Flag d'affichage de l'email de contact dans le volet d'aide
 * @returns {boolean}
 */
export const isEmailDeContactDisplayed = () => process.env.REACT_APP_CONTACT_EMAIL_DISPLAYED === 'true';

/**
 * Ajout de l'email dans le formulaire d'identité pour générali
 * @returns {boolean}
 */
export const isUtilisateurAutoriseASInscrire = () => process.env.REACT_APP_UTILISATEUR_AUTORISE_A_S_INSCRIRE === 'true';

/**
 * Flag d'affichage du deuxième bloc info sur la page de saisie du SIRET
 * @returns {boolean}
 */
export const isSiretInfoPartie2Enabled = () => process.env.REACT_APP_SIRET_INFO_PART_2_ENABLED === 'true';

/**
 * Flag d'affichage du deuxième bloc d'aide sur la page votre entreprise
 * @returns {boolean}
 */
export const isEntrepriseCardPart2Enabled = () => process.env.REACT_APP_ENTREPRISE_CARD_PART_2_ENABLED === 'true';

/**
 * Partie du formulaire canaux d'alimentation non affiché pour générali
 * @returns {boolean}
 */
export const isCanauxAlimentationFormEnabled = () => process.env.REACT_APP_CANAUX_ALIMENTATION_ENABLED === 'true';

/**
 * Flag d'affichage du troisième paragraphe d'aide sur la page de saisie du Dirigeant
 * @returns {boolean}
 */
export const isDirigeantCardPart3Enabled = () => process.env.REACT_APP_DIRIGEANT_CARD_PART_3_ENABLED === 'true';

/**
 * Flag d'affichage du deuxième paragraphe d'aide sur la page de participation
 * @returns {boolean}
 */
export const isParticipationCardPart2Enabled = () => process.env.REACT_APP_PARTICIPATION_CARD_PART_2_ENABLED === 'true';

/**
 * Autorisation de la redirection vers le portail après déconnexion
 * @returns {boolean}
 */
export const isDeconnexionVersPortailEnabled = () => process.env.REACT_APP_DECONNEXION_VERS_PORTAIL_ENABLED === 'true';

/**
 * Flag d'affichage de la page tarification version YOMONI
 * @returns {boolean}
 */
export const isYesTarificationEnabled = () => process.env.REACT_APP_YES_TARIFICATION_ENABLED === 'true';

/**
 * Flag d'affichage de la page tarification version YOMONI
 * @returns {boolean}
 */
export const isFraisConseilEnabled = () => process.env.REACT_APP_FRAIS_CONSEIL_ENABLED === 'true';
/**
 * Flag d'affichage du mode participation volontaire
 * @returns {boolean}
 */
export const isParticipationVolontaireEnabled = () => process.env.REACT_APP_PARTICIPATION_VOLONTAIRE_ENABLED === 'true';

/**
 * Flag d'affichage du bloc info sur la page d'intéressement
 * @returns {boolean}
 */
export const isMessagesAccompagnementAccordsEnabled = () =>
  process.env.REACT_APP_MESSAGES_ACCOMPAGNEMENT_ACCORDS_ENABLED === 'true';

/**
 * Flag d'ouverture pour les routes suivantes :
 * - Création de compte
 * - Login
 * - Mot de passe oublié
 * - Récupération de mot de passe
 * @returns {boolean}
 */
export const isRoutesAuthentificationEnabled = () => process.env.REACT_APP_ROUTES_AUTHENTIFICATION_ENABLED === 'true';

/**
 * Upload des documents dépendant du plan existant ou non
 * Si non, on autorise l'upload quelle que soit la situation
 * @returns {boolean}
 */
export const isPiecesJustificativesDependantPlanExistantEnabled = () =>
  process.env.REACT_APP_UPLOAD_JUSTIFICATIF_DEPENDANT_PLAN_EXISTANT_ENABLED === 'true';

/**
 * Flag d'ajout des documents GES
 * @returns {boolean}
 */
export const isDocumentsAnnexesEnabled = () => process.env.REACT_APP_UPLOAD_DOCUMENTS_ANNEXES_ENABLED === 'true';

/** Flag autorisant la fonctionnalité de transmission de dossier à l'étape Informations(3)
 * @returns {boolean}
 */
export const isInformationsTransmettreDossierEnabled = () =>
  process.env.REACT_APP_INFORMATIONS_TRANSMETTRE_DOSSIER_ENABLED === 'true';

/** Flag autorisant l'affichage de la liste de ratification dans les documents à uploader
 * @returns {boolean}
 */
export const isUploadListeRatificationEnabled = () => process.env.REACT_APP_UPLOAD_LISTE_RATIFICATION_ENABLED === 'true';

/**
 * La liste de documents est devenue dynamique à cause du multi tenant
 * Donc on la construit ici en fonction des différentes features activées
 * @param hasPlanExistant
 * @returns {*[]}
 */
export const getDocumentsAUploader = (planExistant, typeDossier) => {
  if (isDocumentsAnnexesEnabled()) {
    let mixedDocuments = getDocumentsByType(typeDossier);

    //Si pas de plan place, un supprime la partie plan existant de l'upload des pièces annexes
    if (!planExistant.accordInteressementEnPlace) {
      mixedDocuments = mixedDocuments.filter((document) => document.type !== TYPE_DOCUMENT.ACCORD_INTERESSEMENT);
    }
    if (!planExistant.accordParticipationEnPlace) {
      mixedDocuments = mixedDocuments.filter((document) => document.type !== TYPE_DOCUMENT.ACCORD_PARTICIPATION);
    }

    if (!isUploadListeRatificationEnabled()) {
      mixedDocuments = mixedDocuments.filter((document) => document.type !== TYPE_DOCUMENT.CONSULTATION_ENTREPRISE);
    }
    return mixedDocuments;
  }

  return DOCUMENTS;
};

//TODO: supprimer toute cette partie pour l'avoir dans des utils différents (épargne/assurance)
const getDocumentsByType = (typeDossier) => {
  switch (typeDossier) {
    case 'ASSURANCE':
      return [...DOUCMENTS_IDENTITE_DIRIGEANT, ...DOCUMENTS, ...DOCUMENTS_ANNEXE_ASSURANCE];
    default:
      return DOCUMENTS;
  }
};

/**
 * Autorisation du changement de wording fde la page informations en fonction
 * du plan existant ou non
 * @returns {boolean}
 */
export const isWordingPageInformationsDependantPlanExistantEnabled = () =>
  process.env.REACT_APP_WORDING_INFORMATIONS_DEPENDANT_PLAN_EXISTANT_ENABLED === 'true';

/**
 * Flag d'autorisation des paliers d'abondement
 * @returns {boolean}
 */
export const isPaliersAbondementEnabled = () => process.env.REACT_APP_PALIERS_ABONDEMENT_ENABLED === 'true';

/**
 * Flag d'affichage des sources de versement abondées
 * @returns {boolean}
 */
export const isSourcesVersementAbondeesEnabled = () => process.env.REACT_APP_SOURCES_VERSEMENT_ABONDEES_ENABLED === 'true';

/**
 * Flag d'affichage des sources de versement abondées
 * @returns {boolean}
 */
export const isPeiPercoiIndependantEnabled = () => process.env.REACT_APP_PEI_PERCOI_SEUL_ENABLED === 'true';

/**
 * Flag d'aide à la completion du dossier après signature
 * @returns {boolean}
 */
export const isPostSignatureDossierACompleterEnabled = () =>
  process.env.REACT_APP_POST_SIGNATURE_DOSSIER_A_COMPLETER_ENABLED === 'true';

/**
 * Flag d'aide à la completion du dossier après signature
 * @returns {boolean}
 */
export const isCumulPercoiAbondementUnilateralEnabled = () =>
  process.env.REACT_APP_CUMUL_PERCOI_ABONDEMENT_UNILATERAL_ENABLED === 'true';

/**
 * Flag d'affichage de la card du volet d'aide
 * @returns {boolean}
 */
export const isAideCardOptionnelleEnabled = () => process.env.REACT_APP_AIDE_CARD_OTIONNELLE_ENABLED === 'true';

/**
 * Flag d'affichage de la partie infos de la page votre entreprise
 * @returns {boolean}
 */
export const isInfoCardEntreprisePart1Enabled = () => process.env.REACT_APP_ENTREPRISE_INFO_1_ENABLED === 'true';

/**
 * Flag d'affichage du volet d'infos sur la page du dirigeant
 * @returns {boolean}
 */
export const isDirigeantInfoEnabled = () => process.env.REACT_APP_DIRIGEANT_INFOS_ENABLED === 'true';

import API from '../../utils/constantes/API';
import CITRA from '../../api/citra';
import { CITRA_DATA_ACTIONS } from '../../utils/constantes/REDUCERS/CITRA_DATA_ACTIONS';

export const getPass = () => (dispatch) => {
  CITRA().get(`${API.DATA.PASS}`)
    .then((result) => {
      dispatch({
        type: CITRA_DATA_ACTIONS.UPDATE_PASS_VALUE_SUCCESS,
        res: result.data
      });
    }).catch((error) => {
    dispatch({
      type: CITRA_DATA_ACTIONS.UPDATE_PASS_VALUE_FAILED,
      error
    });
  });
};
const ENTREPRISE_FORM_FIELDS_COMMON = {
    SIRET: 'siret',
    RAISON_SOCIALE: 'raisonSociale',
    DATE_CREATION: 'dateCreation',
    CODE_NAF: 'codeNaf',
    FORME_JURIDIQUE: 'formeJuridique',
    RUE: 'rue',
    CODE_POSTAL: 'codePostal',
    VILLE: 'ville',
    PAYS: 'pays',
    SIREN: 'siren'
};

export const ENTREPRISE_FORM_FIELDS_EPARGNE = {
    ...ENTREPRISE_FORM_FIELDS_COMMON
}

export const ENTREPRISE_FORM_FIELDS_ASSURANCE = {
    ...ENTREPRISE_FORM_FIELDS_COMMON,
    NOUVEAU_CLIENT: 'nouveauClient',
    RNA: 'rna',
    LIBELLE_NAF: 'libelleNaf',
    AUTRE_IDENTIFIANT: 'autreIdentifiant',
    AUTRE_FORME_JURIDIQUE: 'autreFormeJuridique',
    NOMBRE_SALARIES: 'nombreSalaries',
    DATE_CLOTURE_COMPTABLE: 'dateClotureComptable'
};
import React, { Component } from "react";

export class YesChoix extends Component {
  render() {
    const { input, label, id, ...rest } = this.props;

    let cssClassNotChecked = "";
    if (!input.checked) {
      cssClassNotChecked = "noChecked";
    }

    const classnameDisabled = this.props.disabled ? " disableChoix" : "";

    const classNameCircle =
      (this.props.circle && !this.props.checkedMode) ||
      (this.props.checkedMode && input.checked)
        ? " whiteCircleBackground"
        : "";

    const classNameCheckedMode = this.props.checkedMode
      ? "modeCheckedRadio"
      : "";

    return (
      <div>
        <div
          className={
            "yes-circle " +
            cssClassNotChecked +
            classnameDisabled +
            " " +
            classNameCircle +
            " " +
            classNameCheckedMode
          }
        >
          {this.props.disabled && (
            <input className="choixObj" disabled id={id} {...input} {...rest} />
          )}

          {!this.props.disabled && (
            <input className="choixObj" id={id} {...input} {...rest} />
          )}

          {/* Cas nominal */}
          {!this.props.circle && (
            <img
              className="icon-check"
              src="/assets/img/icons/yes/correct.svg"
              alt="check"
            />
          )}

          {/* Si on veut un rond plutot qu'une icone check */}
          {this.props.circle && !this.props.checkedMode && input.checked && (
            <span className="circlebuttonRadio" />
          )}

          {/* checkedMode affiche une icone check même si le bouton radio n'est pas coché. S'il est coché, on affiche un rond */}
          {this.props.checkedMode && input.checked && (
            <span className="circlebuttonRadio" />
          )}

          {/* checkedMode affiche une icone check même si le bouton radio n'est pas coché. S'il n'est PAS coché, on affiche donc l'icone check */}
          {this.props.checkedMode && !input.checked && (
            <img
              className="icon-check-checked-mode"
              src="/assets/img/icons/yes/correct.svg"
              alt="check"
            />
          )}
        </div>

        {label && (
          <label htmlFor={id} className={"labelChoix" + classnameDisabled}>
            {label}
          </label>
        )}
      </div>
    );
  }
}

/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

/* Internal imports */
import SelectCountries from '../../../form/SelectCountries';
import SelectDialingCode from "../../common/forms/SelectDialingCode";
import TooltipInfo from "../../../../utils/US/components/TooltipInfo";
import Typography from "../../../DS/data-display/Typography";
import YesInput from '../../../form/Input';
import { CIVILITES_LIST } from '../../../../common/constants';
import { IDENTITE_FIELDS_ASSURANCE } from '../../../../utils/constantes/US/IDENTITEFORM';
import { YesButton } from '../../../form/Button';
import { YesDropdown } from '../../../form/Dropdown';
import { initFieldsValuesAssurance } from '../../../../utils/US/components/assurance/forms/identiteUtils';
import { isUtilisateurAutoriseASInscrire } from '../../../../services/TenantService';
import {
  CodePostal,
  DateFr,
  Email,
  MaxLength100,
  MaxLength40,
  MaxLength50,
  NIR,
  Required,
  Telephone
} from '../../../../common/Validators';


class IdentiteForm extends Component {
  constructor(props) {
    super(props);
    const { pristine, invalid, submitting } = props;
    this.state = {
      submitDisabled: pristine || invalid || submitting
    };
  }

  componentDidMount() {
    const { change, dirigeant } = this.props;

    initFieldsValuesAssurance(dirigeant, change)
    if (!isUtilisateurAutoriseASInscrire() && dirigeant?.adresseEmail) {
      change(IDENTITE_FIELDS_ASSURANCE.ADRESSE_EMAIL, dirigeant.adresseEmail);
    }
  }

  componentDidUpdate(prevProps) {
    const { pristine, invalid, submitting } = this.props;
    if (prevProps.invalid !== invalid || prevProps.submitting !== submitting || prevProps.pristine !== pristine) {
      this.setState({
        submitDisabled: invalid || submitting || pristine
      });
    }
  }

  render() {
    const { handleSubmit, emailSentPEROB, emailAdressChanged } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <label className="titreFormulaire">
          <Trans i18nKey="dirigeant.titre" />
        </label>

        <div className="grid-x grid-margin-x">

          <div className="cell medium-4 civilite">
            <Field
              id="civilite"
              name={IDENTITE_FIELDS_ASSURANCE.CIVILITE}
              label="Civilité"
              component={YesDropdown}
              item={CIVILITES_LIST}
            />
          </div>

          <div className="cell medium-4">
            <Field
              component={YesInput}
              type="text"
              name={IDENTITE_FIELDS_ASSURANCE.NOM}
              labeltext="Nom"
              validate={[Required, MaxLength50]}
              maxLength="50"
            />
          </div>

          <div className="cell medium-4">
            <Field
              component={YesInput}
              type="text"
              name={IDENTITE_FIELDS_ASSURANCE.PRENOM}
              labeltext="Prénom"
              validate={[Required, MaxLength40]}
              maxLength="40"
            />
          </div>

          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={IDENTITE_FIELDS_ASSURANCE.NOM_NAISSANCE}
              labeltext="Nom de naissance"
              validate={[MaxLength50]}
              maxLength="50"
            />
          </div>

          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              inputMode="numeric"
              name={IDENTITE_FIELDS_ASSURANCE.DATE_NAISSANCE}
              labeltext="Date de naissance"
              validate={[Required, DateFr]}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              placeholder="jj/mm/aaaa"
            />
          </div>
          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={IDENTITE_FIELDS_ASSURANCE.LIEU_NAIISANCE}
              labeltext="Lieu de naissance"
              validate={[Required, MaxLength50]}
              maxLength="50"
            />
          </div>
          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={IDENTITE_FIELDS_ASSURANCE.CODE_POSTAL_NAISSANCE}
              labeltext="Code postal de naissance"
              validate={[Required, CodePostal]}
              maxLength="5"
            />
          </div>
          <div className="cell medium-6">
            <Field
              name={IDENTITE_FIELDS_ASSURANCE.PAYS_NAISSANCE}
              labeltext="Pays de naissance"
              component={SelectCountries}
              validate={Required}
            />
          </div>
          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={IDENTITE_FIELDS_ASSURANCE.NATIONALITES}
              labeltext="Nationalité(s)"
              validate={[Required, MaxLength50]}
              maxLength="50"
            />
          </div>
          <div className="cell medium-6">
            <Field
              name={IDENTITE_FIELDS_ASSURANCE.PAYS_RESIDENCE_FISCALE}
              labeltext="Pays de résidence fiscale"
              component={SelectCountries}
              validate={Required}
            />
          </div>
          <div className="cell medium-6 separateur">
            <Field
              component={YesInput}
              type="text"
              name={IDENTITE_FIELDS_ASSURANCE.NIR}
              labeltext="NIR (Numéro de sécurité sociale)"
              validate={[Required, NIR]}
              maxLength="13"
            />
          </div>

          <div className="cell medium-12">
            <Field
              component={YesInput}
              type="text"
              name={IDENTITE_FIELDS_ASSURANCE.FONCTION}
              labeltext="Fonction"
              validate={[Required, MaxLength100]}
              maxLength="100"
            />
          </div>

          {!isUtilisateurAutoriseASInscrire() && (
            <div className="cell medium-5">
              <Field
                component={YesInput}
                type="text"
                name={IDENTITE_FIELDS_ASSURANCE.ADRESSE_EMAIL}
                labeltext="Adresse e-mail"
                validate={[Required, Email]}
                maxLength="100"
                onChange={(event) => emailAdressChanged(event.target.value)}
              />
            </div>
          )}

          <div className="cell medium-3">
            <Field
              name={IDENTITE_FIELDS_ASSURANCE.INDICATIF}
              labeltext="Indicatif du pays"
              component={SelectDialingCode}
              validate={[Required]}
            />
          </div>

          <Box className="cell medium-4 separateur" sx={{
            display: 'flex',
            alignItems: 'baseline'
          }}>
            <Box sx={{flexGrow: 1}}>
              <Field
                component={YesInput}
                type="tel"
                name={IDENTITE_FIELDS_ASSURANCE.TELEPHONE}
                labeltext="N° téléphone portable"
                validate={[Required, Telephone]}
                mask={[/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]}
                guide={false}
              />
            </Box>
            <Box>
              <TooltipInfo placement="top-start" tooltipContent={
                <Typography info>
                  <Trans i18nKey="dirigeant.info.part.2" />
                </Typography>
              }/>
            </Box>
          </Box>
        </div>

        <label className="titreFormulaire">
          <Trans i18nKey="dirigeant.adresse" />
        </label>

        <div className="grid-x grid-margin-x">
          <div className="cell medium-12">
            <Field
              component={YesInput}
              type="text"
              name={IDENTITE_FIELDS_ASSURANCE.RUE}
              labeltext="Numero et Rue"
              validate={[Required, MaxLength100]}
              maxLength="100"
            />
          </div>

          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={IDENTITE_FIELDS_ASSURANCE.CODE_POSTAL}
              labeltext="Code postal"
              validate={[Required, CodePostal]}
              maxLength="5"
            />
          </div>

          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={IDENTITE_FIELDS_ASSURANCE.VILLE}
              labeltext="Ville"
              validate={[Required, MaxLength50]}
              maxLength="50"
            />
          </div>

          <div className="cell medium-6">
            <Field name={IDENTITE_FIELDS_ASSURANCE.PAYS} labeltext="Pays" component={SelectCountries} validate={Required} />
          </div>
        </div>
    
        <div className="buttonCreationContainer">
          <YesButton
            text={<Trans i18nKey="dirigeant.button.envoi.doc" />}
            disabled={this.state.submitDisabled || emailSentPEROB}
          />
          <YesButton
            text={<Trans i18nKey="dirigeant.button.continuer" />}
            disabled={this.state.submitDisabled || !emailSentPEROB}
          />
        </div>
      </form>
    );
  }
}

IdentiteForm.propTypes = {
  change: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  dirigeant: PropTypes.object.isRequired
};

// REDUX + REDUX FORM
const mapStateToProps = (state) => ({
  dirigeant: state.tunnel.utilisateur
});

export default connect(mapStateToProps)(reduxForm({ form: 'informationsIdentiteForm' })(IdentiteForm));

/* Built in imports */
import React from 'react';

/* Third party imports */
import { Field } from 'redux-form';
import { Trans } from 'react-i18next';

/* Internal imports */
import TooltipInfo from '../../../../../utils/US/components/TooltipInfo';
import Typography from '../../../../DS/data-display/Typography';
import YesInput from '../../../../form/Input';
import { NormalizeNumber } from '../../../../../common/Normalizers';
import { fraisDeDossierEntre0et500 } from '../../../../../common/Validators';

const AdministrationFees = () => (
  <div className='administrationFees'>
    <br />
    <label className='titreFormulaire titreTarificationGes'>
      <Trans i18nKey='ges-tarification.titre.2' />
    </label>

    <TooltipInfo
      tooltipContent={
        <Typography info>
          <Trans i18nKey='ges-tarification.titre.2.tooltip' />
        </Typography>
      }
      placement={'right-start'}
    />
    <div className='tarification-input frais-dossier-input'>
      <Field
        euroField={true}
        component={YesInput}
        type='text'
        inputMode='numeric'
        name='fraisDossier'
        labeltext=''
        validate={[fraisDeDossierEntre0et500]}
        numberfield={'true'}
        normalize={NormalizeNumber}
      />
    </div>
  </div>
);

export default AdministrationFees;

export const COLLEGE_CATEGORIES = {
  ENTIRE_STAFF: 'ALL',
  EMPLOYEE_CATEGORIES: 'ARTICLE',
  EMPLOYEE_SEUIL: 'THRESHOLD',
  OTHER: 'OTHER'
};
export const COLLEGE_OPTIONS = {
  EMPLOYEE_CATEGORIES: {
    ART21: 'art21',
    ART2122: 'art2122',
    ART2122BR: 'art2122br',
    ART21_NOCADRE: 'no-cadre.art21',
    ART2122_NOCADRE: 'no-cadre.art2122',
    ART2122BR_NOCADRE: 'no-cadre.art2122br'
  },
  EMPLOYEE_SEUIL: [
    {
      FACTEUR: 1,
      NOM: '1pass'
    },
    {
      FACTEUR: 2,
      NOM: '2pass'
    },
    {
      FACTEUR: 3,
      NOM: '3pass'
    },
    {
      FACTEUR: 4,
      NOM: '4pass'
    },
    {
      FACTEUR: 8,
      NOM: '8pass'
    }
  ]
};
const URL_ENTREPRISE =  "/entreprise";

export const URL_SUBSCRIPTION_TYPE = {
  EPARGNE: "/epargne-salariale" + URL_ENTREPRISE,
  ASSURANCE: "/assurance" + URL_ENTREPRISE
}

export const FOLDER_TYPES = {
  ASSURANCE: "ASSURANCE",
  EPARGNE: "EPARGNE"
}
export const ACTIVITY_DOMAINS = {
  DEFAULT: {
    NAME: '',
    URL: ''
  },
  MAROQUINERIE: {
    NAME: 'MAROQUINERIE',
    URL: '/maroquinerie'
  }
};
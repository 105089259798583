/* Built in imports */
import React from 'react';

/* Third party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

/* Internal imports */
import AbondementToggler from '../../../forms/dispositifAbondement/AbondementToggler';
import ContributionLine from '../../../forms/dispositifAbondement/ContributionLine';
import {
    LISTE_TAUX_ABONDEMENT_MAROQUINERIE,
    MODALITES
} from '../../../../../../utils/constantes/US/maroquinerie/MAROQUINERIEABONDEMENT';
import { TransHtmlText } from '../../../../../yes/utils/TransHtmlText';
import { YesCheckboxV2 } from '../../../../../form/CheckboxV2';
import { frenchDecimalNumber, nombreAvecEspaces } from '../../../../../../common/FunctionsUtils';
import { isPeiPercoiIndependantEnabled } from '../../../../../../services/TenantService';
import {
  TAUX_MAX_ABONDEMENT_PEE
} from '../../../../../../modules/simulateur/configuration';
import YesInput from "../../../../../form/Input";
import {DateContratRequired, DateFr, DateDuJourOuFutur} from "../../../../../../common/Validators";

const MaroquinerieAbondementPEI = ({
                         config,
                         changerTauxPei,
                         modeAnciennete,
                         checkPlafondPei,
                         palierPeiActive,
                         classDisablePei,
                         checkTauxDegressif,
                         palierAbondementsPei,
                         changerValeurMaxPalier,
                         changerDateEffet,
                         classDisableOuverturePei,
                         miseEnPlaceAbondementPei,
                         getPalierAbondementMaxPei,
                         checkMaxPalierSuperieurAMin,
                         desactiverMiseEnPlaceAbondementPei,
                         PASS
                       }) => (
  <div className="cell">
    <div className="yes-rectangle">
      <div className="premierNiveauAbondement">
        <Field
          component={YesCheckboxV2}
          type="checkbox"
          name="ouverturePei"
          id="ouverturePei"
          labeltext={<TransHtmlText i18nKey="dispositif.ouverture.pei.titre" params={{ config: config }} />}
          disabled={!isPeiPercoiIndependantEnabled()}
          onChange={desactiverMiseEnPlaceAbondementPei}
        />
      </div>

      <div className="contentAbondementActive">
        <AbondementToggler
          classDisableOuverture={classDisableOuverturePei}
          config={{ config: config }}
          name="miseEnPlaceAbondementPei"
        />

        <div className={`alignContentInColumn ${classDisablePei} ${miseEnPlaceAbondementPei ? '' : 'hide'}`}>
          <div className="parametrageAbondement">Paramétrage de l'abondement</div>
          <ContributionLine
            contributionType="palierAbondementsPei"
            index={0}
            key={'palierAbondementsPei-0'}
            deletable={false}
            changeRate={(e) => changerTauxPei(e, 0, false)}
            isActive={palierPeiActive}
            checkMaxValue={checkPlafondPei}
            checkMaxHigherThanMin={checkMaxPalierSuperieurAMin}
            changeMaxValue={(e) => changerValeurMaxPalier(e, 0, 'Pei')}
            maxStepValue={getPalierAbondementMaxPei}
            checkDecliningRate={() => checkTauxDegressif(palierAbondementsPei)}
            modeAnciennete={modeAnciennete}
            doValidation={miseEnPlaceAbondementPei}
            listTauxAbondement={LISTE_TAUX_ABONDEMENT_MAROQUINERIE}
          />
          <div className="alignContentInRow">
            <div className="yes-label">
              <TransHtmlText
                i18nKey="maroquinerie.dispositif.info.abondement.plafond"
                params={{
                  config: config,
                  plafondPeePass: nombreAvecEspaces(frenchDecimalNumber(PASS * TAUX_MAX_ABONDEMENT_PEE))
                }}
              />
            </div>
          </div>
          <div className="alignContentInRow">
            <div className="yes-label">
              <TransHtmlText i18nKey="maroquinerie.dispositif.info.abondement.sourcesVersement" />
            </div>
          </div>
        </div>
      </div>
    </div>
      <div className="cell medium-6" style={{paddingTop: '20px'}} >
          <Field
              component={YesInput}
              type="text"
              inputMode="numeric"
              id={MODALITES.DATE_EFFET}
              name={MODALITES.DATE_EFFET}
              labeltext="Date d'effet"
              validate={[DateContratRequired, DateFr, DateDuJourOuFutur]}
              onChange={changerDateEffet}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              placeholder="jj/mm/aaaa"
          />
      </div>
  </div>
);

MaroquinerieAbondementPEI.propTypes = {
  config: PropTypes.bool,
  anciennete: PropTypes.object,
  plafondPei: PropTypes.number,
  changerTauxPei: PropTypes.func,
  checkPlafondPei: PropTypes.func,
  palierPeiActive: PropTypes.bool,
  classDisablePei: PropTypes.string,
  checkTauxDegressif: PropTypes.func,
  palierAbondementsPei: PropTypes.array,
  changerValeurMaxPalier: PropTypes.func,
  changerDateEffet: PropTypes.func,
  miseEnPlaceAbondementPei: PropTypes.bool,
  getPalierAbondementMaxPei: PropTypes.func,
  modeAnciennete: PropTypes.func.isRequired,
  classDisableOuverturePei: PropTypes.string,
  checkMaxPalierSuperieurAMin: PropTypes.func,
  desactiverMiseEnPlaceAbondementPei: PropTypes.func,
  PASS: PropTypes.number
};

export default MaroquinerieAbondementPEI;
import FormLabel from '@mui/material/FormLabel';
import { styled } from '@mui/material/styles';

const FormLabelRoot = styled(FormLabel, { name: 'FormLabel' })(() => ({
  fontFamily: 'var(--cn-font-family-source-pro-sans-serif)',
  fontSize: '18px',
  '&.Mui-focused': {
    color: 'var(--cn-typo-default-color)'
  }
}))

export default FormLabelRoot
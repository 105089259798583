/* Built-in imports */
import React, { useEffect } from "react";

/* Third-party imports */
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import PropTypes from "prop-types";

/* Internal imports */
import MaroquinerieRemunerationSection from "./MaroquinerieRemunerationSection";
import MaroquinerieTarificationSection from "./MaroquinerieTarificationSection";
import { MOYENS_PAIEMENT } from '../../../../../../utils/constantes/US/MOYENSPAIEMENT';
import { NormalizeIban } from "../../../../../../common/Normalizers";
import { STATUT_LIASSE } from "../../../../../../common/Configuration";
import { URLS_MAROQUINERIE } from "../../../../../../utils/constantes/ROUTES/MAROQUINERIE";
import { YesButton } from "../../../../../form/Button";


const MaroquinerieTarificationForm = ({ dossier, liasse, change, handleSubmit, pristine, invalid, submitting, customErrorIban }) => {

  useEffect(() => {
    const { tarification, compteDePrelevement } = dossier;
    if (compteDePrelevement) {
      change(MOYENS_PAIEMENT.IBAN, NormalizeIban(compteDePrelevement.iban));
      compteDePrelevement.bic && change(MOYENS_PAIEMENT.BIC, compteDePrelevement.bic);
    }
    if (tarification) {
      change('fraisDossier', tarification.fraisDossier);
      change('fraisEntree', tarification.fraisEntree + ' %');
      change('optionChargeFrais', tarification.optionChargeFrais.toString());
      change('ciblePrelevements', tarification.ciblePrelevements);
    }
    else {
      change('fraisDossier', 0);
      change('fraisEntree', '1 %');
      change('optionChargeFrais', 1);
    }
  }, [dossier]);

  return (
    <form onSubmit={handleSubmit} className="formTunnel formTarification">
      <MaroquinerieTarificationSection />
      <MaroquinerieRemunerationSection customErrorIban={customErrorIban} />

      <div className="buttonCreationContainer">
        <YesButton text="Signer le contrat" disabled={invalid || submitting || pristine} />
        {liasse?.statut === STATUT_LIASSE.SIGNEE && (
          <p>
            J’ai déjà signé mon contrat et je n’en ai pas modifié les paramètres :
            <Link to={URLS_MAROQUINERIE.FINALISATION}>{" j'envoie mes pièces justificatives."}</Link>
          </p>
        )}
      </div>
    </form>
  );
}

MaroquinerieTarificationForm.propTypes = {
  dossier: PropTypes.object.isRequired,
  liasse: PropTypes.object,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  customErrorIban: PropTypes.string
}

// REDUX + REDUX FORM
const mapStateToProps = ({ tunnel }) => ({
  dossier: tunnel.dossier,
  liasse: tunnel.liasse
});

export default connect(mapStateToProps)(reduxForm({ form: 'MaroquinerieTarificationForm' })(MaroquinerieTarificationForm));

/* Built-in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Internal imports */
import TextWithTitle from './TextWithTitle';
import RecapitulatifBloc from './RecapitulatifBloc';
import { style } from './recapitulatif.style';

const getGaranties = (t, garanties) => {
  const garantiesRetenus = [];
  if (garanties.garantiePlancher) {
    garantiesRetenus.push(t('recap.modalites.plancher'));
  }

  if (garanties.garantieDeBonneFin) {
    garantiesRetenus.push(t('recap.modalites.serenite'));
  }
  return `${t('recap.modalites.garanties')} ${garantiesRetenus.join(' et ')}`;
};

const RecapitulatifModalites = ({ t, modalitesPero, garantiesPrevoyance }) => (
  <RecapitulatifBloc title={t('recap.modalites.titre')}>
    <TextWithTitle title={t('recap.modalites.date')} content={modalitesPero.dateEffet} />
    <TextWithTitle title={t('recap.modalites.periodicite')} content={modalitesPero.periodicite} />
    <br />
    <div style={style.mentionBloc}>
      <p style={style.mention}>
        {garantiesPrevoyance.garantiePlancher || garantiesPrevoyance.garantieDeBonneFin
          ? getGaranties(t, garantiesPrevoyance)
          : t('recap.modalites.sansGaranties')}
      </p>
    </div>
  </RecapitulatifBloc>
);

RecapitulatifModalites.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({
  tunnel: {
    dossier: { modalitesPero, garantiesPrevoyance }
  }
}) => ({
  modalitesPero,
  garantiesPrevoyance
});

export default connect(mapStateToProps)(withTranslation()(RecapitulatifModalites));

import * as React from 'react';

/* Internal imports */
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" ref={ref} {...props} />
  );
});

export default Transition
import React, { useEffect } from 'react';

/* third-party imports */
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

/* Internal imports */
import Alert from '../../../DS/feedback/Alert';
import FormLabel from '../../../DS/inputs/form/FormLabel';
import Switch from '../../../DS/inputs/Switch';
import TextField from '../../../DS/inputs/TextField';
import Typography from '../../../DS/data-display/Typography';
import { Field, reduxForm } from 'redux-form';
import {
  isContractorInfosErrors
} from '../../../../utils/US/components/workAdvicesSheetsModal/workAdvicesSheetsEmployeesForm/contractorSubForm';
import INPUTS from '../../../../utils/constantes/DS/INPUTS';

const renderSwitch = ( { checked, input } ) => (
  <Switch
    inputProps={{ role: 'switch' }}
    checked={checked}
    {...input}
  />
)

const renderTextField = ({ input, label, placeholder, ...custom }) => (
  <TextField
    sx={{ display: 'flex'}}
    label={label}
    placeholder={placeholder}
    variant="filled"
    {...input}
    {...custom}
  />
)

const ContractorSubForm = function(props) {
  const tradKey = 'components.US.form.work-advices-sheets-employees.contractor-sub-form.representative',
        { t }   = useTranslation(),
        {
          isContractor,
          contractorInfos,
          handleIsContractorChange,
          handleContractorInfosChange,
          handleIsContractorInfosErrorChange,
          showErrors,
        } = props;

  const { firstname, lastname, job } = contractorInfos;
  const isContractorInfosError = () => {
    const isContractInfosError = isContractorInfosErrors(contractorInfos);

    handleIsContractorInfosErrorChange(isContractInfosError)
    return isContractInfosError
  }

  useEffect(() => {
    props.change("contractor-sub-form-firstname", firstname);
    props.change("contractor-sub-form-lastname", lastname);
    props.change("contractor-sub-form-job", job);
  });

  return (
      <Box
        component="div"
        sx={{ display: 'flex' }}
      >
        <Grid container>
          <FormGroup
            sx={{
              flexDirection: 'row',
              flexGrow: 1,
              alignItems: 'center',
              flexWrap: 'nowrap',
            }}
          >
            <FormLabel sx={{ flexGrow: 1 }}>
              {t(`${tradKey}.label`)}
            </FormLabel>
            <Stack direction="row" alignItems="center">
              <Typography>{t('utils.form.radio.no')}</Typography>
              <Field
                name="retirement-contract"
                component={renderSwitch}
                onChange={handleIsContractorChange}
                checked={isContractor}
              />
              <Typography>{t('utils.form.radio.yes')}</Typography>
            </Stack>
          </FormGroup>
          <Grid container spacing={2} sx={{
            paddingTop: '1em'
          }}>
            <Grid item xs={12} sm={6} md={4}>
              <Field
                name="contractor-sub-form-firstname"
                label={t(`${tradKey}.firstname.label`)}
                required
                component={renderTextField}
                onBlur={(event) => {
                  event.target.value = event.target.value.trim();
                  handleContractorInfosChange(event, 'firstname')
                }}
                onChange={(event) => handleContractorInfosChange(event, 'firstname')}
                inputProps={{ maxLength: INPUTS.TEXTLENGTH.SMALL }}
                value={firstname}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field
                name="contractor-sub-form-lastname"
                label={t(`${tradKey}.lastname.label`)}
                required
                component={renderTextField}
                onBlur={(event) => {
                  event.target.value = event.target.value.trim();
                  handleContractorInfosChange(event, 'lastname')
                }}
                onChange={(event) => handleContractorInfosChange(event, 'lastname')}
                inputProps={{ maxLength: INPUTS.TEXTLENGTH.SMALL }}
                value={lastname}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field
                name="contractor-sub-form-job"
                label={t(`${tradKey}.job.label`)}
                required
                component={renderTextField}
                onBlur={(event) => {
                  event.target.value = event.target.value.trim();
                  handleContractorInfosChange(event, 'job')
                }}
                onChange={(event) => handleContractorInfosChange(event, 'job')}
                inputProps={{ maxLength: INPUTS.TEXTLENGTH.SMALL }}
                value={job}
              />
            </Grid>
            { isContractorInfosError() && showErrors &&
              <Grid item xs={12} sx={{ paddingTop: '.5em !important' }}>
                <Alert sx={{ alignItems: 'center' }} severity="error">{t('utils.form.errors.asterisk.required')}</Alert>
              </Grid>
            }
          </Grid>
        </Grid>
      </Box>
    );
}

ContractorSubForm.propTypes = {
  isContractor: PropTypes.bool.isRequired,
  contractorInfos: PropTypes.object.isRequired,
  handleIsContractorChange: PropTypes.func.isRequired,
  handleContractorInfosChange: PropTypes.func.isRequired,
  handleIsContractorInfosErrorChange: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: 'ContractorSubForm'
})(ContractorSubForm)
/* Internal imports */
import countries from "../../data/countries";

const defaultCountryCodeA2 = "FR";

const getCountryByCodeA2 = (countryCodeA2) => countries.find((country) => country.countryCodeA2 === countryCodeA2);

const getAppDefaultCountry = () => getCountryByCodeA2(defaultCountryCodeA2);

const getDrom = () => {
  return countries
    .filter((country) => country.countryCodeA2 !== defaultCountryCodeA2)
    .sort((firstDrom, secondDrom) => {
      return firstDrom.name < secondDrom.name ? 1 : (firstDrom.name < secondDrom.name ? -1 : 0);
    });
};

const formatOption = (country) => country.name + " (" + country.iddCode + ")";

export {
  formatOption,
  getCountryByCodeA2,
  getAppDefaultCountry,
  getDrom
};
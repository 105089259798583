/* Built in imports */
import React from 'react';

/* Third party imports */
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

/* Internal imports */
import MoyensPaiementForm from '../../../../components/US/assurance/forms/MoyensPaiement/MoyensPaiementForm';
import { MOYENS_PAIEMENT } from '../../../../utils/constantes/US/MOYENSPAIEMENT';
import { addNotification } from '../../../../modules/notification/redux/notificationActions';
import { mettreAjourMoyensPaiement } from '../../../../services/PersonneMoraleService';
import { stringToBoolean } from '../../../../common/FunctionsUtils';
import {
  setMoyensPaiement,
  setRetourRoute
} from '../../../../modules/tunnel/redux/tunnelActions';
import { URLS_ASSURANCE } from '../../../../utils/constantes/ROUTES/ASSURANCE';

class AssuranceMoyensPaiementPage extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    // On modifie la route lorsque l'utilisateur clique le bouton Retour
    this.props.setRetourRoute(URLS_ASSURANCE.KYC.substring(1));

    this.state = {
      redirect: false
    };
  }

  async submit(values) {
    try {

      const moyensPaiement = await mettreAjourMoyensPaiement(this.props.uid, this.mapMoyensPaiement(values));
      this.props.setMoyensPaiement(moyensPaiement);

      this.redirect();
    } catch (error) {
      if (error && error.message) {
        this.props.addNotification(error.message);
      }
    }
  }

  redirect() {
    this.setState({
      redirect: true
    });
  }

  mapMoyensPaiement(values) {
    let moyensPaiements = {
      debitCompteSouscripteur: stringToBoolean(values[MOYENS_PAIEMENT.IS_COMPTE_SOUSCRIPTEUR])
    };
    if (!moyensPaiements.debitCompteSouscripteur) {
      moyensPaiements = {
        ...moyensPaiements,
        nature: values[MOYENS_PAIEMENT.NATURE_PAYEUR],
        nom: values[MOYENS_PAIEMENT.NOM],
        prenom: values[MOYENS_PAIEMENT.PRENOM],
        motif: values[MOYENS_PAIEMENT.MOTIF_TIERS],
        lien: values[MOYENS_PAIEMENT.LIEN],
        adresse: {
          rue: values[MOYENS_PAIEMENT.ADRESSE],
          codePostal: values[MOYENS_PAIEMENT.CODE_POSTAL],
          ville: values[MOYENS_PAIEMENT.VILLE],
          pays: JSON.parse(values[MOYENS_PAIEMENT.PAYS])
        }
      };
    }
    if (stringToBoolean(values[MOYENS_PAIEMENT.ETABLISSEMENT_HORS_FRANCE])) {
      moyensPaiements = {
        ...moyensPaiements,
        etablissementHorsFrance: {
          pays: JSON.parse(values[MOYENS_PAIEMENT.PAYS_FONDS]),
          motif: values[MOYENS_PAIEMENT.MOTIF_ETABLISSEMENT]
        }
      };
    }
    return moyensPaiements;
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={URLS_ASSURANCE.TARIFICATION} />;
    }

    return <MoyensPaiementForm onSubmit={this.submit} />;
  }
}

const mapStateToProps = ({
                           tunnel: {
                             dossier: { uid },
                             fichePersonneMorale: { moyensPaiement }
                           }
                         }) => ({
  uid,
  moyensPaiement
});

const mapDispatchToProps = {
  setMoyensPaiement,
  setRetourRoute,
  addNotification
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssuranceMoyensPaiementPage);

/* built-in imports */
import * as React from 'react';

/* third-party imports */
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

/* Internal imports */
import { MAXWIDTH } from '../../../utils/constantes/DS/TOOLTIP';

const TooltipRoot = styled(({className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--cn-color-grey-100)',
    border: '1px solid var(--cn-color-grey-200)'
  },
}));

const TooltipDS = ({maxWidth = MAXWIDTH.MEDIUM, children, sx, ...other}) => {
  const getStyle = () =>{
    let style = {
    };

    style[`& .${tooltipClasses.tooltip}`] = {
      maxWidth: maxWidth
    };

    if(sx && Object.keys(sx).length > 0) {
      style = Object.assign(sx, style);
    }

    return style;
  }

  return (
    <TooltipRoot {...other} sx={getStyle}>
      {children}
    </TooltipRoot>
  );
}

TooltipDS.propTypes = {
  maxWidth: PropTypes.string
}

export default TooltipDS
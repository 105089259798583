/* Built in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

/* Internal imports */
import SelectCountries from '../../../../form/SelectCountries';
import Typography from '../../../../DS/data-display/Typography';
import YesInput from '../../../../form/Input';
import renderCheckbox from '../../../../DS/inputs/reduxForm/RenderCheckbox';
import { BOOLEAN } from '../../../../../common/constants';
import { CHECKBOXSHAPE } from '../../../../../utils/constantes/DS/CHECKBOX';
import { DateAuPasse, DateFr, DateInLastYear, MaxLength100, Required } from '../../../../../common/Validators';
import { PPE_FORM_FIELD } from '../../../../../utils/constantes/US/PPEFORM';
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';
import { hasPersonne } from '../../../../../utils/US/components/ppeFieldsUtils';

export const PpeFields = ({ t, personne, isPpe = false, isProchePpe = false, trad = 'ppe', change }) => (
  <>
    <Typography>
      <TransHtmlText i18nKey={t(`${trad}.exerciceFonctionPpe`)}/>
    </Typography>
    <Field
      name={`${hasPersonne(personne)}${PPE_FORM_FIELD.IS_PPE}-oui`}
      component={renderCheckbox}
      shape={CHECKBOXSHAPE.ROUND}
      label={t(`utils.form.radio.yes`)}
      value={BOOLEAN.YES}
      onChange={() => {
        change(`${hasPersonne(personne)}${PPE_FORM_FIELD.IS_PPE}`, BOOLEAN.YES)
      }}
      checked={isPpe}
    />
    <Field
      name={`${hasPersonne(personne)}${PPE_FORM_FIELD.IS_PPE}-non`}
      component={renderCheckbox}
      shape={CHECKBOXSHAPE.ROUND}
      label={t(`utils.form.radio.no`)}
      value={BOOLEAN.NO}
      onChange={() => {
        change(`${hasPersonne(personne)}${PPE_FORM_FIELD.IS_PPE}`, BOOLEAN.NO)
      }}
      checked={!isPpe}
    />

    <div className="grid-x grid-margin-x separateur">
      {isPpe && (
        <>
          <div className="cell medium-6">
            <Field
              name={`${hasPersonne(personne)}${PPE_FORM_FIELD.PAYS}`}
              labeltext="Pays"
              component={SelectCountries}
              validate={Required}
            />
          </div>
          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={`${hasPersonne(personne)}${PPE_FORM_FIELD.FONCTION}`}
              labeltext="Fonction"
              validate={[Required, MaxLength100]}
              maxLength="100"
            />
          </div>
          <div className="cell medium-6 separateur">
            <Field
              component={YesInput}
              type="text"
              name={`${hasPersonne(personne)}${PPE_FORM_FIELD.FIN_FONCTION}`}
              labeltext="Date fin fonction si < 1 an"
              validate={[DateFr, DateAuPasse, DateInLastYear]}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              placeholder="jj/mm/aaaa"
            />
          </div>
        </>
      )}
    </div>

    <Typography>
      <TransHtmlText i18nKey={t(`${trad}.associePpe`)}/>
    </Typography>
    <Field
      name={`${hasPersonne(personne)}${PPE_FORM_FIELD.IS_PROCHE_PPE}-oui`}
      component={renderCheckbox}
      shape={CHECKBOXSHAPE.ROUND}
      label={t(`utils.form.radio.yes`)}
      value={BOOLEAN.YES}
      onChange={() => {
        change(`${hasPersonne(personne)}${PPE_FORM_FIELD.IS_PROCHE_PPE}`, BOOLEAN.YES)
      }}
      checked={isProchePpe}
    />
    <Field
      name={`${hasPersonne(personne)}${PPE_FORM_FIELD.IS_PROCHE_PPE}-non`}
      component={renderCheckbox}
      shape={CHECKBOXSHAPE.ROUND}
      label={t(`utils.form.radio.no`)}
      value={BOOLEAN.NO}
      onChange={() => {
        change(`${hasPersonne(personne)}${PPE_FORM_FIELD.IS_PROCHE_PPE}`, BOOLEAN.NO)
      }}
      checked={!isProchePpe}
    />

    <div className="grid-x grid-margin-x">
      {isProchePpe && (
        <>
          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={`${hasPersonne(personne)}${PPE_FORM_FIELD.NOM_PPE}`}
              labeltext="Nom de la PPE"
              validate={[Required, MaxLength100]}
              maxLength="100"
            />
          </div>
          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={`${hasPersonne(personne)}${PPE_FORM_FIELD.PRENOM_PPE}`}
              labeltext="Prénom de la PPE"
              validate={[Required, MaxLength100]}
              maxLength="100"
            />
          </div>
          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={`${hasPersonne(personne)}${PPE_FORM_FIELD.LIEN_PPE}`}
              labeltext="Lien de la PPE avec le représentant"
              validate={[Required, MaxLength100]}
              maxLength="100"
            />
          </div>
          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={`${hasPersonne(personne)}${PPE_FORM_FIELD.FONCTION_PPE}`}
              labeltext="Fonction de la PPE"
              validate={[Required, MaxLength100]}
              maxLength="100"
            />
          </div>
          <div className="cell medium-6">
            <Field
              name={`${hasPersonne(personne)}${PPE_FORM_FIELD.PAYS_PPE}`}
              labeltext="Pays d'exercice de la PPE"
              component={SelectCountries}
              validate={Required}
            />
          </div>
          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={`${hasPersonne(personne)}${PPE_FORM_FIELD.FIN_FONCTION_PPE}`}
              labeltext="Date fin fonction si < 1 an"
              validate={[DateFr, DateAuPasse, DateInLastYear]}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              placeholder="jj/mm/aaaa"
            />
          </div>
        </>
      )}
    </div>
  </>
);

PpeFields.propTypes = {
  isPpe: PropTypes.bool,
  isProchePpe: PropTypes.bool
};

import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { isLoggedIn } from '../../services/ResourcesService';
import { isDeconnexionVersPortailEnabled } from '../../services/TenantService';
import { ROUTE_CONNEXION, ROUTE_ACCUEIL_GALAXY } from '../../common/Configuration';

export const PrivateAppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return(
    <Route
      {...rest}
      render={(props) => {
        const ref = React.createRef();
        if (isLoggedIn()) {
          return (
            <Layout child={ref}>
              <Component ref={ref} {...props} />
            </Layout>
          );
        }
        if (isDeconnexionVersPortailEnabled()) {
          return <Redirect to={ROUTE_ACCUEIL_GALAXY} />
        }
        return <Redirect to={ROUTE_CONNEXION} />;
      }}
    />
  );
}
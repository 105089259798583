/* Built in imports */
import React, {useCallback, useMemo} from 'react';

/* Third party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { withTranslation } from 'react-i18next';

/* Internal imports */
import Duree from '../Duree';
import Formule from './Formule';
import Repartition from './Repartition';
import { BOOLEAN } from '../../../../../../common/constants';
import { TransHtmlText } from '../../../../../yes/utils/TransHtmlText';
import { YesChoix } from '../../../../../form/Choix';
import {
  dateToString,
  isIn6FirstMonths,
  stringToBoolean
} from '../../../../../../common/FunctionsUtils';
import ApplicationFiscale from "./ApplicationFiscale";

const EMPTY_OPTION = 'Faites votre choix';

/* A supprimer si on bascule sur la nouvelle option */
const isLessThan6Monthes = (date) => {
  const lower = date > new Date() ? new Date() : date;
  const higher = date > new Date() ? date : new Date();
  const d = lower.getDate();
  lower.setMonth(lower.getMonth() + +6);
  if (lower.getDate() !== d) {
    lower.setDate(0);
  }
  return lower < higher;
};

const getYearFinExercice = (dateCloture, today = new Date()) => {
  return today < new Date(dateCloture) ? today.getFullYear() : today.getFullYear() + 1;
}

const tomorrow = (date) => {
  return new Date(new Date(date).setDate(date.getDate() + 1));
}

const generateOptions = (length, name, t) =>
  new Array(length + 1).fill(1).map((x, i) => ({
    value: i,
    label: i === 0 ? EMPTY_OPTION : t(`participation.participationIntegre.${name}${i}`)
  }));

const ParticipationIntegre = ({ t, optionParticipation, cloture, pourcentRepartition }) => {
  /* A supprimer si on bascule sur la nouvelle option */
  // const isNextExercise = useMemo(() => isLessThan6Monthes(new Date(cloture)), [cloture]);
  /* A décommenter pour la nouvelle option */
  // const isNextExercise = useMemo(() => !isIn6FirstMonthes(new Date(cloture)), [cloture]);

  const isPreviousExercise = useMemo(() => new Date() < new Date(tomorrow(new Date(cloture)).setFullYear(new Date().getFullYear())),[cloture]);

  // si on est dans les 6 premiers mois de l'exercice, l'application fiscale se fait directement à la fin de l'exercice
  // sinon il se fait un an plus tard
  const fiscalApplicationYear = useMemo(() => {
    return isIn6FirstMonths(tomorrow(cloture))
      ? getYearFinExercice(tomorrow(cloture))
      : getYearFinExercice(tomorrow(cloture)) + 1;
  } , [cloture]);

  const FORMULES = generateOptions(5, 'formule', t);

  const REPARTITIONS = generateOptions(4, 'repartition', t);

  const MEP = generateOptions(2, 'mep', t);

  const isDuree1An = useMemo(() => (
      optionParticipation.duree
    && optionParticipation.duree.type
    && optionParticipation.duree.type === '2'
    && optionParticipation.duree.duree === '1'
    && optionParticipation.formule
    && optionParticipation.formule.type
    && optionParticipation.formule.type !== '1' //Toujours le choix N ou N+1 avec la formule n°1
    ),
    [optionParticipation.duree, optionParticipation.formule]);

  const ficalApplicationSentence = useCallback(() => {
    if(optionParticipation.duree && optionParticipation.duree.type) {
      if(isDuree1An && !isIn6FirstMonths(cloture)) {
        const anneeApplicationFiscale = dateToString(new Date(new Date(new Date(cloture).setFullYear(fiscalApplicationYear))));
        optionParticipation.applicationFiscale = anneeApplicationFiscale;
        return (t('participation.participationIntegre.applicationFiscale',
          { date: anneeApplicationFiscale }
        ))
      }
      const dates = [
        {
          value: new Date(new Date(cloture).setFullYear(getYearFinExercice(tomorrow(cloture)))).toLocaleDateString('fr-FR'),
          label: new Date(new Date(cloture).setFullYear(getYearFinExercice(tomorrow(cloture)))).toLocaleDateString('fr-FR'),
        },
        {
          value: new Date(new Date(cloture).setFullYear(getYearFinExercice(tomorrow(cloture)) + 1)).toLocaleDateString('fr-FR'),
          label: new Date(new Date(cloture).setFullYear(getYearFinExercice(tomorrow(cloture)) + 1)).toLocaleDateString('fr-FR'),
        }
      ]
      return (<ApplicationFiscale dates={dates} />)
    }
    return;
  }, [optionParticipation.duree, optionParticipation.formule, cloture])

  return (
    <>
      <div className="cell medium-12">
        <h3>
          <TransHtmlText i18nKey="participation.participationIntegre.question" />
        </h3>
      </div>
      <div className="cell medium-12">
        <div className="tunnelRadios">
          <div className="tunnelRadio">
            <Field
              name="optionParticipation.enabled"
              component={YesChoix}
              type="radio"
              value={BOOLEAN.YES}
              label="Oui"
              id={BOOLEAN.YES}
            />
          </div>
          <div className="tunnelRadio">
            <Field
              name="optionParticipation.enabled"
              component={YesChoix}
              type="radio"
              value={BOOLEAN.NO}
              label="Non"
              id={BOOLEAN.NO}
            />
          </div>
        </div>
      </div>

      {optionParticipation && stringToBoolean(optionParticipation.enabled) && (
        <>
          <Formule formules={FORMULES} />

          {optionParticipation.formule && optionParticipation.formule.type > 0 && (
            <>
              <Repartition
                t={t}
                repartitions={REPARTITIONS}
                selected={optionParticipation && optionParticipation.repartition && optionParticipation.repartition.type}
                pourcentRepartition={pourcentRepartition}
              />
              {optionParticipation.repartition && optionParticipation.repartition.type > 0 && (
                <>
                  <Duree mep={MEP} optionParticipation={optionParticipation} t={t} />
                  <div className="cell medium-12">
                    <h3>
                      {ficalApplicationSentence()}
                    </h3>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

// ancienne RG.
// { currentYear: isPreviousExercise ? (new Date().getFullYear() - 1).toString() : (new Date().getFullYear()).toString(),
//   closureDate: dateToString(new Date(new Date(new Date(cloture).setFullYear(fiscalApplicationYear))))
// }


ParticipationIntegre.propTypes = {
  t: PropTypes.func.isRequired,
  cloture: PropTypes.object.isRequired,
  optionParticipation: PropTypes.object,
  pourcentRepartition: PropTypes.object.isRequired
};

export default withTranslation()(ParticipationIntegre);

import auth from '../modules/auth';
import countries from '../modules/countries';
import workAdvicesSheetsReducer from './work_advices_sheets/workAdvicesSheetsReducer';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as notificationReducer } from '../modules/notification';
import { reducer as simulateurReducer } from '../modules/simulateur';
import { reducer as tunnelReducer } from '../modules/tunnel';
import { routerReducer } from 'react-router-redux';
import { citraDataReducer } from './citraData/citraDataReducer';

export default combineReducers({
  routing: routerReducer,
  auth,
  form: formReducer,
  simulateur: simulateurReducer,
  tunnel: tunnelReducer,
  countries,
  notification: notificationReducer,
  workAdvicesSheets: workAdvicesSheetsReducer,
  citraData: citraDataReducer
});

import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

const SelectRoot = styled(Select, { name: 'Select' })(() => ({
  '&::after': {
    borderBottomColor: 'var(--cn-color-primary)'
  },
  // '& .MuiSelect-select': {
  //   color: 'var(--cn-typo-in-filled-input)',
  //   backgroundColor: 'var(--cn-ges-input-bg-color)'
  // }
}))

// const SelectCustom = ({ sx }) => {
//   let style = {};
// }

export default SelectRoot
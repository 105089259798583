import {
  OBJECTIF_FIDELISER,
  OBJECTIF_MOTIVER,
  OBJECTIF_CONSTITUER_CAPITALE
} from "../modules/simulateur/redux/simulateurActions";
import React from "react";

export const OBJECTIF_FIDELISER_0 = "fideliser_0_";
export const OBJECTIF_MOTIVER_0 = "motiver_0_";
export const OBJECTIF_CONSTITUER_CAPITALE_0 = "constituer_capital_0_";

export const OBJECTIF_FIDELISER_50 = "fideliser_50_";
export const OBJECTIF_MOTIVER_50 = "motiver_50_";

export const OBJECTIF_FIDELISER_50_SANS_BENEFICE = "fideliser_50_sans_benefice";
export const OBJECTIF_MOTIVER_50_SANS_BENEFICE = "motiver_50_sans_benefice";

export const listeObjectifs = () => {
  return [
    {
      type: OBJECTIF_MOTIVER,
      titre: "Motiver mes salariés",
      description:
        "Associer collectivement vos salariés aux performances de l’entreprise selon des critères mesurables, grâce à une prime variable valorisable dans les packages de rémunération."
    },
    {
      type: OBJECTIF_FIDELISER,
      titre: "Fidéliser mes salariés",
      description:
        "Dynamiser votre politique sociale en accordant un complément de revenu exceptionnel, et en accompagnant vos salariés dans leur effort d’épargne."
    },
    {
      type: OBJECTIF_CONSTITUER_CAPITALE,
      titre: "Me constituer un capital en tant que chef d’entreprise",
      description:
        "En tant que dirigeant, optimisez votre stratégie patrimoniale individuelle en maximisant les avantages des dispositifs d’épargne salariale."
    }
  ];
};

export const obtenirTexteObjectif = (objectif, effectif, benefice) => {
  // Le texte sera différent pour un effectif :
  // compris entre 0 et 49
  // supérieur à 50
  let trancheEffectif = 0;
  let sansBenefice = "";

  if (effectif >= 50) {
    trancheEffectif = 50;
    if (!benefice) {
      sansBenefice = "sans_benefice";
    }
  }

  switch (objectif) {
    case OBJECTIF_MOTIVER:
      return obtenirTexteMotiver(trancheEffectif, sansBenefice);
    case OBJECTIF_FIDELISER:
      return obtenirTexteFideliser(trancheEffectif, sansBenefice);
    case OBJECTIF_CONSTITUER_CAPITALE:
      return (
        <div>
          <p className="sous-titre-gris">
            L’épargne salariale vous permet en tant que dirigeant de vous
            constituer un patrimoine.
          </p>

          <p className="sous-titre-gris">
            Nous vous conseillons d’optimiser tous les dispositifs d’épargne
            salariale dans votre entreprise afin de bénéficier des exonérations
            de charges et d’impôts par rapport à une prime classique.
          </p>
        </div>
      );
    default:
      break;
  }
};

const obtenirTexteMotiver = (trancheEffectif, sansBenefice) => {
  switch (OBJECTIF_MOTIVER + "_" + trancheEffectif + "_" + sansBenefice) {
    case OBJECTIF_MOTIVER_0:
      return (
        <div>
          <p className="sous-titre-gris">
            Nous vous recommandons de prioriser la mise en place d’une prime à
            la performance : l’intéressement.
          </p>

          <p className="sous-titre-gris">
            Cette prime permet de récompenser les salariés en fonction de la
            performance collective selon des critères définis à l’avance. Elle
            peut être valorisée dans les packages de rémunération.
          </p>

          <p className="sous-titre-gris">
            Elle peut être complétée par une prime à l’épargne, l’abondement.
          </p>
        </div>
      );
    case OBJECTIF_MOTIVER_50:
      return (
        <div>
          <p className="sous-titre-gris">
            Vous comptez plus de 50 salariés, vous avez donc l’obligation de
            conclure un accord de participation.
          </p>

          <p className="sous-titre-gris">
            La participation est toujours adossée à un plan d’épargne salariale
            et consiste à attribuer aux salariés une part des bénéfices annuels
            de l’entreprise.
          </p>

          <p className="sous-titre-gris">
            Vous pouvez également mettre en place l’intéressement, vous
            permettant d’associer les salariés au développement de l’entreprise.
          </p>
        </div>
      );
    case OBJECTIF_MOTIVER_50_SANS_BENEFICE:
      return (
        <div>
          <p className="sous-titre-gris">
            Vous comptez plus de 50 salariés, il est nécessaire de conclure un
            accord de participation.
          </p>

          <p className="sous-titre-gris">
            La participation consiste à attribuer aux salariés une part des
            bénéfices annuels de l’entreprise. Aujourd’hui votre entreprise ne
            dégage pas de bénéfice, vous ne versez donc pas de participation. Si
            à la clôture de votre exercice comptable, votre résultat est
            positif, vous devrez verser de la participation à vos salariés.
          </p>

          <p className="sous-titre-gris">
            Vous pouvez également mettre en place l’intéressement, vous
            permettant d’associer les salariés au développement de l’entreprise.
          </p>
        </div>
      );
    default:
      break;
  }
};

const obtenirTexteFideliser = (trancheEffectif, sansBenefice) => {
  switch (OBJECTIF_FIDELISER + "_" + trancheEffectif + "_" + sansBenefice) {
    case OBJECTIF_FIDELISER_0:
      return (
        <div>
          <p className="sous-titre-gris">
            Nous vous recommandons de prioriser la mise en place d’une prime à
            l‘épargne : l’abondement.
          </p>

          <p className="sous-titre-gris">
            C’est une prime exceptionnelle liée au versement du salarié. Elle
            est librement définie, maîtrisable, révisable tous les ans et non
            soumise à des objectifs.
          </p>

          <p className="sous-titre-gris">
            Elle peut être complétée par une prime à la performance,
            l’intéressement, afin d’associer vos salariés au développement de
            l’entreprise.
          </p>
        </div>
      );
    case OBJECTIF_FIDELISER_50:
      return (
        <div>
          <p className="sous-titre-gris">
            Vous comptez plus de 50 salariés, vous avez donc l’obligation de
            conclure un accord de participation.
          </p>

          <p className="sous-titre-gris">
            Elle est toujours adossée à un plan d’épargne salariale et consiste
            à attribuer aux salariés une part des bénéfices annuels de
            l’entreprise.
          </p>

          <p className="sous-titre-gris">
            Vous pouvez également mettre en place une prime à l’épargne,
            l’abondement, liée au versement du salarié.
          </p>
        </div>
      );
    case OBJECTIF_FIDELISER_50_SANS_BENEFICE:
      return (
        <div>
          <p className="sous-titre-gris">
            Vous comptez plus de 50 salariés, il est nécessaire de conclure un
            accord de participation.
          </p>

          <p className="sous-titre-gris">
            La participation consiste à attribuer aux salariés une part des
            bénéfices annuels de l’entreprise. Aujourd’hui votre entreprise ne
            dégage pas de bénéfice, vous ne versez donc pas de participation. Si
            à la clôture de votre exercice comptable, votre résultat est
            positif, vous devrez verser de la participation à vos salariés.
          </p>

          <p className="sous-titre-gris">
            Vous pouvez également mettre en place une prime à l’épargne,
            l’abondement, liée au versement du salarié.
          </p>
        </div>
      );
    default:
      break;
  }
};

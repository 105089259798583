/* Built-in imports */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

/* Third-party imports */
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/* Internal imports */
import API from '../../../../../utils/constantes/API';
import Button from '../../../../DS/inputs/Button';
import CircularProgress from '../../../../DS/feedback/Progress';
import DIVIDER from '../../../../../utils/constantes/DS/DIVIDER';
import Divider from '../../../../DS/data-display/Divider';
import File from '../../../../DS/inputs/File/File';
import Typography from '../../../../DS/data-display/Typography';
import componentUtils from '../../../../../utils/US/components/assurance/forms/finalisationAttachmentsFormUtils';
import inputUtils from '../../../../../utils/inputs';
import styles from './finalisationAttachmentsFormStyles';
import { ROUTE_DOSSIERS_GALAXY, TYPE_DOCUMENT } from '../../../../../common/Configuration';
import {
  setFichesPero,
  setInformationsDossier,
  setInformationsEntreprise,
  setInformationsUtilisateur, setLoader, setLoaderMessage
} from '../../../../../modules/tunnel/redux/tunnelActions';

const AssuranceFinalisationAttachmentsForm = (
  {
    annexeKyc,
    delegationPouvoir,
    enterpriseId,
    entrepriseLegalStatus,
    folderId,
    folderType,
    justificatifIdentiteEntreprise,
    justificatifIdentiteRecto,
    justificatifIdentiteVerso,
    liasse,
    liasseFiscale,
    passeport,
    planExistant,
    redirectToVerificationWLF,
    setInformationsDossier,
    setInformationsEntreprise,
    setInformationsUtilisateur,
    setLoader,
    setLoaderMessage,
    specialsCase,
    userId
  }) => {
  const tradKey = "components.US.assurance.forms.informationsAttachmentsForm";
  const isFolderSigned = (liasse && liasse.statut === 'SIGNEE') || (window.location.search.split('?event=').length > 0 && window.location.search.split('?event=')[1] === 'signing_complete');
  const documents = componentUtils.getListeDocuments(planExistant, folderType);
  const classes = styles();
  const { t } = useTranslation();
  const submitButtonRef = useRef(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [files, setFiles] = useState({});
  const [submitData, setSubmitData] = useState({ isError: false, errorMessage: null });
  const [isLoading, setIsLoading] = useState(false);
  const [isDirectorIdentityDocuments, setIsDirectorIdentityDocuments] = useState(false);

  const getDefaultFilesData = () => {
    const filesData = {
      [componentUtils.FILESKEYS.PASSPORT]: [
        {
          file: componentUtils.getFile(passeport),
          networkError: false,
          networkErrorMessage: null,
          isLoading: false
        }
      ],
      [componentUtils.FILESKEYS.DIRECTORIDENTITY]: [
        {
          file: componentUtils.getFile(justificatifIdentiteRecto),
          networkError: false,
          networkErrorMessage: null,
          isLoading: false
        },
        {
          file: componentUtils.getFile(justificatifIdentiteVerso),
          networkError: false,
          networkErrorMessage: null,
          isLoading: false
        }
      ],
      [componentUtils.FILESKEYS.SPECIALCASES]: getMultipleFilesData(specialsCase)
    };

    for (let i = 0, documentsLength = documents.length; i < documentsLength; i++) {
      const document = documents[i];
      switch (document.type) {
        case TYPE_DOCUMENT.DELEGATION_POUVOIR:
          filesData[componentUtils.FILESKEYS.POWERDELEGATION] = [
            {
              file: componentUtils.getFile(delegationPouvoir),
              networkError: false,
              networkErrorMessage: null,
              isLoading: false
            }
          ];
          break;
        case TYPE_DOCUMENT.STATUTS_ENTREPRISE:
          filesData[componentUtils.FILESKEYS.ENTERPRISELEGALESTATUS] = [
            {
              file: componentUtils.getFile(entrepriseLegalStatus),
              networkError: false,
              networkErrorMessage: null,
              isLoading: false
            }
          ];
          break;
        case TYPE_DOCUMENT.JUSTIFICATIF_IDENTITE_ENTREPRISE:
          filesData[componentUtils.FILESKEYS.KBIS] = [
            {
              file: componentUtils.getFile(justificatifIdentiteEntreprise),
              networkError: false,
              networkErrorMessage: null,
              isLoading: false
            }
          ];
          break;
        case TYPE_DOCUMENT.LIASSE_FISCALE:
          filesData[componentUtils.FILESKEYS.TAXRETURN] = [
            {
              file: componentUtils.getFile(liasseFiscale),
              networkError: false,
              networkErrorMessage: null,
              isLoading: false
            }
          ];
          break;
        case TYPE_DOCUMENT.ANNEXE_KYC_PERSON: {
          const kycData = getMultipleFilesData(annexeKyc);
          if (kycData.length === 0) {
            kycData.push({
              file: null,
              networkError: false,
              networkErrorMessage: null,
              isLoading: false
            });
          }
          filesData[componentUtils.FILESKEYS.KYC] = kycData;
          break;
        }
      }
    }

    return filesData;
  };

  const getMultipleFilesData = (filesName) => {
    const multipleFiles = [];

    if (!filesName) {
      return multipleFiles;
    }

    for (let i = 0, filesNameLength = filesName.length; i < filesNameLength; i++) {
      multipleFiles.push({
        file: { name: filesName[i] },
        networkError: false,
        networkErrorMessage: null,
        isLoading: false
      });
    }

    return multipleFiles;
  };

  const onAddFile = (key, index, file) => {
    const newFiles = files[key];
    newFiles[index].file = file;
    setFiles({
      ...files,
      [key]: newFiles
    });
  };

  const updateFiles = (key, index) => {
    const currentFiles = files[key];
    currentFiles[index].file = null;
    setFiles({
      ...files,
      [key]: currentFiles
    });
  };

  const onAddMultipleFiles = (key) => {
    const currentFiles = files[key];
    currentFiles.push({
      file: null,
      networkError: false,
      networkErrorMessage: null,
      isLoading: false
    });

    setFiles({
      ...files,
      [key]: currentFiles
    });
  };

  const onRemoveMultipleFiles = (key, index) => {
    const newFiles = files[key];
    newFiles.splice(index, 1);
    setFiles({
      ...files,
      [key]: newFiles
    });
  };

  const scrollView = () => {
    if (componentUtils.isNoRequired(files)) {
      submitButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const activeAppLoader = (msg) => {
    setLoaderMessage(msg);
    setLoader(true);
  };

  const disableAppLoader = () => {
    setLoaderMessage(null);
    setLoader(false);
  };

  const uploadFile = async (key, fileUrl, thenMethod, index, file) => {
    let response = null;

    setIsLoading(true);
    componentUtils.setCurrentFile(files, setFiles, index, key, true, false, null);

    try {
      response = await componentUtils.uploadFile(fileUrl, file);
    } catch(error) {
      componentUtils.setCurrentFile(files, setFiles, index, key, false, true, error.response.data.message);
      setIsLoading(false);
      return;
    }

    onAddFile(key, index, file);
    thenMethod(response.data);
    componentUtils.setCurrentFile(files, setFiles, index, key, false, false, null);
    setIsLoading(false);
  };

  const removeFile = async (key, fileUrl, fileParam, thenMethod, index, fileName, thenFileMethod) => {
    let response = null;

    setIsLoading(true);
    componentUtils.setCurrentFile(files, setFiles, index, key, true, false, null);

    try{
      response = await componentUtils.deleteFile(fileUrl, fileName);
    } catch(error) {
      componentUtils.setCurrentFile(files, setFiles, index, key, false, true, error.response.data.message);
      setIsLoading(false);
      return;
    }

    thenMethod(response.data);
    componentUtils.setCurrentFile(files, setFiles, index, key, false, false, null);
    thenFileMethod(key, index);
    setIsLoading(false);
  };

  const disabledManager = () => {
    setIsSubmitDisabled(!componentUtils.isNoRequired(files));
  };

  const sendFolder = async () => {
    let response = null;

    setSubmitData({
      ...submitData,
      isLoading: true
    });
    activeAppLoader(t("loader.transmission_message"));

    try {
      response = await componentUtils.sendFolder(`${API.FOLDERS.SEND.replace(API.PARAMS.FOLDERID, folderId).replace(API.PARAMS.FOLDERTYPE, 'assurance')}`);
    } catch(error) {
      disableAppLoader();
      setSubmitData({
        isLoading: false,
        errorMessage: error.response.data.message
      });
      return;
    }

    setInformationsDossier(response.data);
    setSubmitData({
      ...submitData,
      isLoading: false
    });
    disableAppLoader();
    window.location = process.env.REACT_APP_STATIC_URL + ROUTE_DOSSIERS_GALAXY;
  };

  const isFiles = () => {
    return Object.entries(files).length > 0;
  };

  useEffect(() => {
    setFiles(getDefaultFilesData());
  }, []);

  useEffect(() => {
    if (!isFiles() || isLoading) {
      setIsDirectorIdentityDocuments(false);
      return;
    }

    setIsDirectorIdentityDocuments(
      componentUtils.isDirectorIdentityDocuments(
        files[componentUtils.FILESKEYS.PASSPORT],
        files[componentUtils.FILESKEYS.DIRECTORIDENTITY]
      )
    );

    console.log(files);
    console.log(files[componentUtils.FILESKEYS.TAXRETURN]);

    disabledManager();
    scrollView();
  }, [files, isLoading]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          title
          className="margin_bottom_1 titreFormulaire"
        >
          {t(`${tradKey}.title`)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          {t(`${tradKey}.description`)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider color={DIVIDER.COLOR.PRIMARY}>{t(`${tradKey}.divider`)}</Divider>
      </Grid>
      {isFiles() && (
        <>
          {!files[componentUtils.FILESKEYS.PASSPORT][0].file && (
            <Grid item xs={12}>
              <File
                label={t(`${tradKey}.directorIdentity.label`)}
                files={files[componentUtils.FILESKEYS.DIRECTORIDENTITY]}
                filetypes={inputUtils.getPdfFileType()}
                disabled={submitData.isLoading}
                required={!isDirectorIdentityDocuments}
                onChange={async (index, file) => {
                  const citraUrl = `${API.USERS.JUSTIFICATIFIDENTITY.replace(API.PARAMS.USERID, userId)}/${
                    index === 0 ? 'true' : 'false'
                  }/`;
                  await uploadFile(componentUtils.FILESKEYS.DIRECTORIDENTITY, citraUrl, setInformationsUtilisateur, index, file);
                }}
                onRemove={async (index, file) => {
                  const citraUrl = `${API.USERS.JUSTIFICATIFIDENTITY.replace(API.PARAMS.USERID, userId)}/${
                    index === 0 ? 'true' : 'false'
                  }/`;
                  await removeFile(
                    componentUtils.FILESKEYS.DIRECTORIDENTITY,
                    citraUrl,
                    userId,
                    setInformationsUtilisateur,
                    index,
                    file.name,
                    updateFiles
                  );
                }}
              />
            </Grid>
          )}
          {!files[componentUtils.FILESKEYS.DIRECTORIDENTITY][0].file && !files[componentUtils.FILESKEYS.DIRECTORIDENTITY][1].file && (
            <Grid item xs={12}>
              <File
                label={t(`${tradKey}.passeport.label`)}
                files={files[componentUtils.FILESKEYS.PASSPORT]}
                filetypes={inputUtils.getPdfFileType()}
                disabled={submitData.isLoading}
                required={!isDirectorIdentityDocuments}
                onChange={(index, file) => uploadFile(
                  componentUtils.FILESKEYS.PASSPORT,
                  `${API.USERS.PASSPORT.replace(API.PARAMS.USERID, userId)}`,
                  setInformationsUtilisateur,
                  index,
                  file
                )}
                onRemove={(index, file) => removeFile(
                  componentUtils.FILESKEYS.PASSPORT,
                  `${API.USERS.PASSPORT.replace(API.PARAMS.USERID, userId)}`,
                  userId,
                  setInformationsUtilisateur,
                  index,
                  file.name,
                  updateFiles
                )}
              />
            </Grid>
          )}
          {files[componentUtils.FILESKEYS.TAXRETURN] && (
            <Grid item xs={12}>
              <File
                label={t(`${tradKey}.taxReturn.label`)}
                files={files[componentUtils.FILESKEYS.TAXRETURN]}
                filetypes={inputUtils.getPdfFileType()}
                disabled={submitData.isLoading}
                required
                onChange={(index, file) => uploadFile(
                  componentUtils.FILESKEYS.TAXRETURN,
                  `${API.FICHES.LIASSEFISCALE.replace(API.PARAMS.FOLDERID, folderId)}`,
                  setFichesPero,
                  index,
                  file
                )}
                onRemove={(index, file) => removeFile(
                  componentUtils.FILESKEYS.TAXRETURN,
                  `${API.FICHES.LIASSEFISCALE.replace(API.PARAMS.FOLDERID, folderId)}`,
                  folderId,
                  setFichesPero,
                  index,
                  file.name,
                  updateFiles
                )}
              />
            </Grid>
          )}
          {files[componentUtils.FILESKEYS.KBIS] && (
            <Grid item lg={6} xs={12}>
              <File
                label={t(`${tradKey}.kbis.label`)}
                files={files[componentUtils.FILESKEYS.KBIS]}
                filetypes={inputUtils.getPdfFileType()}
                disabled={submitData.isLoading}
                required
                onChange={(index, file) => uploadFile(
                  componentUtils.FILESKEYS.KBIS,
                  `${API.ENTERPRISE.JUSTIFICATIFIDENTITY.replace(API.PARAMS.ENTERPRISEID, enterpriseId)}`,
                  setInformationsEntreprise,
                  index,
                  file
                )}
                onRemove={(index, file) => removeFile(
                  componentUtils.FILESKEYS.KBIS,
                  `${API.ENTERPRISE.JUSTIFICATIFIDENTITY.replace(API.PARAMS.ENTERPRISEID, enterpriseId)}`,
                  enterpriseId,
                  setInformationsEntreprise,
                  index,
                  file.name,
                  updateFiles
                )}
              />
            </Grid>
          )}
          {files[componentUtils.FILESKEYS.ENTERPRISELEGALESTATUS] && (
            <Grid item lg={6} xs={12}>
              <File
                label={t(`${tradKey}.entrepriseLegalStatus.label`)}
                files={files[componentUtils.FILESKEYS.ENTERPRISELEGALESTATUS]}
                filetypes={inputUtils.getPdfFileType()}
                disabled={submitData.isLoading}
                required
                onChange={(index, file) => uploadFile(
                  componentUtils.FILESKEYS.ENTERPRISELEGALESTATUS,
                  `${API.ENTERPRISE.STATUTS.replace(API.PARAMS.ENTERPRISEID, enterpriseId)}`,
                  setInformationsEntreprise,
                  index,
                  file
                )}
                onRemove={(index, file) => removeFile(
                  componentUtils.FILESKEYS.ENTERPRISELEGALESTATUS,
                  `${API.ENTERPRISE.STATUTS.replace(API.PARAMS.ENTERPRISEID, enterpriseId)}`,
                  enterpriseId,
                  setInformationsEntreprise,
                  index,
                  file.name,
                  updateFiles
                )}
              />
            </Grid>
          )}
          {files[componentUtils.FILESKEYS.KYC] && (
            <Grid item xs={12}>
              <File
                optional
                label={t(`${tradKey}.kycIdentities.label`)}
                files={files[componentUtils.FILESKEYS.KYC]}
                filetypes={inputUtils.getPdfFileType()}
                disabled={submitData.isLoading}
                onFileAdd={() => onAddMultipleFiles(componentUtils.FILESKEYS.KYC)}
                required
                onChange={(index, file) => uploadFile(
                  componentUtils.FILESKEYS.KYC,
                  `${API.FOLDERS.KYC_PERSON.replace(API.PARAMS.FOLDERID, folderId)}`,
                  setInformationsDossier,
                  index,
                  file
                )}
                onRemove={async (index, file) => {
                  if (file) {
                    await removeFile(
                      componentUtils.FILESKEYS.KYC,
                      `${API.FOLDERS.KYC_PERSON.replace(API.PARAMS.FOLDERID, folderId)}`,
                      folderId,
                      setInformationsDossier,
                      index,
                      file.name,
                      updateFiles
                    );
                    return;
                  }
                  onRemoveMultipleFiles(componentUtils.FILESKEYS.KYC, index);
                }}
              />
            </Grid>
          )}
          {files[componentUtils.FILESKEYS.POWERDELEGATION] && (
            <Grid item xs={12}>
              <File
                label={t(`${tradKey}.delegationPower.label`)}
                files={files[componentUtils.FILESKEYS.POWERDELEGATION]}
                filetypes={inputUtils.getPdfFileType()}
                disabled={submitData.isLoading}
                onChange={(index, file) => uploadFile(
                  componentUtils.FILESKEYS.POWERDELEGATION,
                  `${API.ENTERPRISE.POWERDELEGATION.replace(API.PARAMS.ENTERPRISEID, enterpriseId)}`,
                  setInformationsEntreprise,
                  index,
                  file
                )}
                onRemove={(index, file) => removeFile(
                  componentUtils.FILESKEYS.POWERDELEGATION,
                  `${API.ENTERPRISE.POWERDELEGATION.replace(API.PARAMS.ENTERPRISEID, enterpriseId)}`,
                  enterpriseId,
                  setInformationsEntreprise,
                  index,
                  file.name,
                  updateFiles
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <File
              optional
              label={t(`${tradKey}.specialCases.label`)}
              files={files[componentUtils.FILESKEYS.SPECIALCASES]}
              filetypes={inputUtils.getPdfFileType()}
              disabled={submitData.isLoading}
              onFileAdd={() => onAddMultipleFiles(componentUtils.FILESKEYS.SPECIALCASES)}
              onChange={(index, file) => uploadFile(
                componentUtils.FILESKEYS.SPECIALCASES,
                `${API.FOLDERS.PARTICULARCASES.replace(API.PARAMS.FOLDERID, folderId)}`,
                setInformationsDossier,
                index,
                file
              )}
              onRemove={async (index, file) => {
                if (file) {
                  await removeFile(
                    componentUtils.FILESKEYS.SPECIALCASES,
                    `${API.FOLDERS.PARTICULARCASES.replace(API.PARAMS.FOLDERID, folderId)}`,
                    folderId,
                    setInformationsDossier,
                    index,
                    file.name,
                    updateFiles
                  );
                  return;
                }
                onRemoveMultipleFiles(componentUtils.FILESKEYS.SPECIALCASES, index);
              }}
            />
          </Grid>
        </>
      )}
      {submitData.errorMessage && (
        <Grid item xs={12}>
          <Typography
            warning
            indicator
          >
            {submitData.errorMessage}
          </Typography>
        </Grid>
      )}
      <Grid sx={{ display: 'flex', justifyContent: 'center' }} item xs={12}>
        <Button
          ref={submitButtonRef}
          className={(!isSubmitDisabled && !submitData.isLoading) ? classes.elementFlash : ''}
          disabled={isSubmitDisabled || submitData.isLoading}
          onClick={async () => {
            if (!isFolderSigned) {
              redirectToVerificationWLF()
              return;
            }
            await sendFolder();
          }}
          variant="contained">
          {!submitData.isLoading ? t(`${tradKey}.buttons.${isFolderSigned ? 'send' : 'signed' }.validate`) :  <CircularProgress className='margin_O' />}
        </Button>
      </Grid>
    </Grid>
  )
};

AssuranceFinalisationAttachmentsForm.propTypes = {
  annexeKyc: PropTypes.arrayOf(PropTypes.string),
  delegationPouvoir: PropTypes.arrayOf(PropTypes.string),
  enterpriseId: PropTypes.string,
  entrepriseLegalStatus: PropTypes.arrayOf(PropTypes.string),
  folderId: PropTypes.string,
  folderType: PropTypes.string,
  justificatifIdentiteEntreprise: PropTypes.arrayOf(PropTypes.string),
  justificatifIdentiteRecto: PropTypes.arrayOf(PropTypes.string),
  justificatifIdentiteVerso: PropTypes.arrayOf(PropTypes.string),
  liasse: PropTypes.object,
  liasseFiscale: PropTypes.arrayOf(PropTypes.string),
  passeport: PropTypes.arrayOf(PropTypes.string),
  planExistant: PropTypes.object,
  redirectToVerificationWLF: PropTypes.func.isRequired,
  setInformationsDossier: PropTypes.func.isRequired,
  setInformationsEntreprise: PropTypes.func.isRequired,
  setInformationsUtilisateur: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  setLoaderMessage: PropTypes.func.isRequired,
  specialsCase: PropTypes.arrayOf(PropTypes.string),
  userId: PropTypes.string
};

// REDUX + REDUX FORM
const mapStateToProps = (state) => ({
  annexeKyc: state.tunnel.dossier.justificatifsCNIAnnexeKYC,
  delegationPouvoir: state.tunnel.entreprise.delegationPouvoir,
  enterpriseId: state.tunnel.entreprise.uid,
  entrepriseLegalStatus: state.tunnel.entreprise.statuts,
  folderId: state.tunnel.dossier.uid,
  folderType: state.tunnel.dossier.type,
  justificatifIdentiteEntreprise: state.tunnel.entreprise.justificatifIdentiteEntreprise,
  justificatifIdentiteRecto: state.tunnel.utilisateur.justificatifIdentiteRecto,
  justificatifIdentiteVerso: state.tunnel.utilisateur.justificatifIdentiteVerso,
  liasse: state.tunnel.liasse,
  liasseFiscale: state.tunnel.fichesPero ? state.tunnel.fichesPero.liasseFiscale : null,
  passeport: state.tunnel.utilisateur.passeport,
  planExistant: state.tunnel.dossier.planExistant,
  specialsCase: state.tunnel.dossier.justificatifsCasParticuliers,
  userId: state.tunnel.utilisateur.uid
});

const mapDispatchToProps = (dispatch) => ({
  setFichesPero: (fichesPero) => dispatch(setFichesPero(fichesPero)),
  setInformationsDossier: (dossier) => dispatch(setInformationsDossier(dossier)),
  setInformationsEntreprise: (entreprise) => dispatch(setInformationsEntreprise(entreprise)),
  setInformationsUtilisateur: (utilisateur) => dispatch(setInformationsUtilisateur(utilisateur)),
  setLoader: (loader) => dispatch(setLoader(loader)),
  setLoaderMessage: (loaderMessage) => dispatch(setLoaderMessage(loaderMessage))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssuranceFinalisationAttachmentsForm);

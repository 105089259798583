/* Built in imports */
import React, { Component } from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

/* Internal imports */
import { PPE_FORM_FIELD } from '../../../../../utils/constantes/US/PPEFORM';
import { PpeFields } from './PpeFields';
import { YesButton } from '../../../../form/Button';
import { changePpeFields, changeProchePpeFields } from '../../../../../utils/US/components/ppeFieldsUtils';
import { stringToBoolean } from '../../../../../common/FunctionsUtils';

class PpeForm extends Component {
  componentDidMount() {
    const {
      change,
      tunnel: {
        fichePersonneMorale: { ppe, prochePpe }
      }
    } = this.props;

    changePpeFields(ppe, change);
    changeProchePpeFields(prochePpe, change);
  }

  render() {
    const { isPpe, isProchePpe, handleSubmit, invalid, pristine, submitting, t, change } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <label className="titreFormulaire separateur">
          <Trans i18nKey={t('ppe.titre')} />
        </label>
        <div className="separateur" />

        <PpeFields isPpe={stringToBoolean(isPpe)} isProchePpe={stringToBoolean(isProchePpe)} t={t} change={change}/>

        <div className="buttonCreationContainer">
          <YesButton text="Confirmer" disabled={invalid || submitting || pristine} />
        </div>
      </form>
    );
  }
}

PpeForm.propTypes = {
  isPpe: PropTypes.string.isRequired,
  isProchePpe: PropTypes.string.isRequired
};

// REDUX + REDUX FORM
const selectPpeForm = formValueSelector('informationsPpeForm');
const mapStateToProps = (state) => ({
  tunnel: state.tunnel,
  isPpe: selectPpeForm(state, PPE_FORM_FIELD.IS_PPE),
  isProchePpe: selectPpeForm(state, PPE_FORM_FIELD.IS_PROCHE_PPE)
});

export default connect(
  mapStateToProps
)(reduxForm({ form: 'informationsPpeForm' })(withTranslation()(PpeForm)));

/* Build-in imports */
import React, { useEffect, useState } from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import AssuranceRouteUtils from '../../utils/routes/assurance/AssuranceRouteUtils';
import EpargneRouteUtils from '../../utils/routes/epargne/EpargneRouteUtils';
import MaroquinerieRoutesUtils from '../../utils/routes/epargne/MaroquinerieRoutesUtils';
import NotificationContainer from '../../components/yes/notification/NotificationContainer';
import PopInInactivite from '../../components/yes/utils/PopInInactivite';
import YesContact from '../../components/yes/utils/Contact';
import YesLoader from '../../components/yes/utils/Loader';
import config from '../../common/StaticSiteConfig';
import { ACTIVITY_DOMAINS, FOLDER_TYPES } from '../../utils/constantes/ROUTES/ROUTES';
import { SECTIONS_MAROQUINERIE, URLS_MAROQUINERIE } from '../../utils/constantes/ROUTES/MAROQUINERIE';
import { TransHtmlText } from '../../components/yes/utils/TransHtmlText';
import { URLS_ASSURANCE, SECTIONS_ASSURANCE } from '../../utils/constantes/ROUTES/ASSURANCE';
import { URLS_EPARGNE, SECTIONS_EPARGNE} from '../../utils/constantes/ROUTES/EPARGNE';
import { deconnecter } from '../../services/CompteService';
import { deleteToken, getAttribute, getCountries, getToken } from '../../services/ResourcesService';
import { getDossierByUidEntreprise, isAssurance } from '../../services/DossierService';
import { getPass } from '../../reducers/citraData/citraDataActions';
import { getSvgEnFonctionDuTenant } from '../../services/TenantService';
import { obtenirTexteInfo } from '../../services/AideService';
import { obtenirTexteInfoMaroquinerie } from '../../helpers/maroquinerie/InfosHelper';
import { setCountries } from '../../modules/countries';
import { setWorkAdvicesSheets } from '../../reducers/work_advices_sheets/workAdvicesSheetsActions';
import {
  FLAG_REDIRECT,
  ROUTE_CONTINUER_SUR_ORDINATEUR,
  ROUTE_DOSSIERS_GALAXY,
  SEUIL_MOBILE
} from '../../common/Configuration';
import {
  setFichePersonneMorale,
  setFichesPero,
  setInformationsDossier,
  setInformationsEntreprise,
  setInformationsUtilisateur,
  setLiasse,
  toggleRedirectRouteEtape
} from '../../modules/tunnel/redux/tunnelActions';


/* TODO: Déplacer dans un utils */
const getFolderTypeConsts = (dossier) => {
  let folderType = dossier.type + dossier.activityDomain;
  switch (folderType) {
    case FOLDER_TYPES.EPARGNE + ACTIVITY_DOMAINS.DEFAULT.NAME:
      return {
        URLS: URLS_EPARGNE,
        SECTIONS: SECTIONS_EPARGNE,
        UTILS: EpargneRouteUtils,
        INFOS_HELPER: obtenirTexteInfo
      };
    case FOLDER_TYPES.ASSURANCE + ACTIVITY_DOMAINS.DEFAULT.NAME:
      return {
        URLS: URLS_ASSURANCE,
        SECTIONS: SECTIONS_ASSURANCE,
        UTILS: AssuranceRouteUtils,
        INFOS_HELPER: obtenirTexteInfo
      };
    case FOLDER_TYPES.EPARGNE + ACTIVITY_DOMAINS.MAROQUINERIE.NAME:
      return {
        URLS: URLS_MAROQUINERIE,
        SECTIONS: SECTIONS_MAROQUINERIE,
        UTILS: MaroquinerieRoutesUtils,
        INFOS_HELPER: obtenirTexteInfoMaroquinerie
      };
  }
}


const TunnelLayout = ({
                        getPass,
                        setInformationsUtilisateur,
                        setInformationsEntreprise,
                        setInformationsDossier,
                        setFichePersonneMorale,
                        setFichesPero,
                        setWorkAdvicesSheets,
                        setLiasse,
                        toggleRedirectRouteEtape,
                        setCountries,
                        children,
                        child,
                        tunnel
                      }) => {

  /* State Variables Depending on Folder Type */
  const [FOLDER_TYPE_CONSTS, setFolderTypeConsts] = useState({
    URLS: URLS_EPARGNE,
    SECTIONS: SECTIONS_EPARGNE,
    UTILS: EpargneRouteUtils,
    INFOS_HELPER: obtenirTexteInfo
  });

  /* State Variables needed to update the layout */
  const [currentSection, setCurrentSection] = useState(SECTIONS_EPARGNE.ENTREPRISE.INDEX);
  const [currentUrl, setCurrentUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [redirectRetour, setRedirectRetour] = useState(false);

  useEffect(() => {
    const entrepriseUid = getAttribute('entrepriseUid');
    // Le token JWT contient un id de dossier => On reconstitue le contexte du tunnel à partir du dossier
    if (entrepriseUid) {
      getDossierByUidEntreprise(entrepriseUid).then((dossiers) => {
        const {
          fichesPero,
          utilisateur,
          entreprise,
          dossier,
          liasse
        } = dossiers[0];
        // on sauvegarde dans Redux les informations de l'entreprise et du dossier

        setFolderTypeConsts(getFolderTypeConsts(dossier));

        if(isAssurance(dossier.type)) {
          if(fichesPero){
            setFichesPero(fichesPero);
            setFichePersonneMorale(fichesPero.fichePersonneMorale ? fichesPero.fichePersonneMorale : null);
          }

          if(fichesPero && fichesPero.ficheDevoirConseil){
            setWorkAdvicesSheets(fichesPero.ficheDevoirConseil);
          }
        }

        setInformationsUtilisateur(utilisateur);
        setInformationsEntreprise(entreprise);
        setInformationsDossier(dossier);
        setLiasse(liasse);
        toggleRedirectRouteEtape(true);
        setLoading(false);
      });
    } else {
      toggleRedirectRouteEtape(true);
      setLoading(false);
    }
    // appel API countries
    getCountries()
      .then((countries) => setCountries(countries))
      .catch((error) => console.error(error));

    getPass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    updateRouteStates(children.props.location.pathname);
  }, [ children.props.location.pathname, FOLDER_TYPE_CONSTS ]);

  //Callback pour le repasser à false après redirection
  useEffect(() => {
    setRedirectRetour(false);
  }, [redirectRetour])

  const updateRouteStates = (currentUrlParam) => {
    setCurrentUrl(currentUrlParam);
    setCurrentSection(FOLDER_TYPE_CONSTS.UTILS.getSectionIndexFromUrl(currentUrlParam));
  }

  const getChildWrappedInstance = () => {
    try {
      return child?.current?.getWrappedInstance();
      // eslint-disable-next-line no-empty
    } catch {}
  }

  const handleOnBack = async (wrappedInstance) => {
    try {
      if (typeof wrappedInstance?.onBack === 'function') {
        await wrappedInstance.onBack();
      }
      setRedirectRetour(true);
    } catch (e) {
      console.error("Erreur lors du retour à l'étape précédente : ", e);
    }
  }

  const retourEtapePrecedente = async () => {
    const wrappedInstance = getChildWrappedInstance();
    if(!wrappedInstance) {
      setRedirectRetour(true);
      return;
    }
    await handleOnBack(wrappedInstance);

  };

  const deconnexion = async () => {
    await deconnecter({
      jwt: getToken(),
      dirigeantUid: tunnel.utilisateur.uid
    }).finally(() => {
      deleteToken();
      window.location.replace(process.env.REACT_APP_STATIC_URL + ROUTE_DOSSIERS_GALAXY);
    });
  };

  const disableLayout = () => {
    return children.props.location.pathname === URLS_ASSURANCE.VERIFICATION_WLF;
  };

  const disableRedirectRouteEtape = () => {
    toggleRedirectRouteEtape(false);
  };

  const activerItemFrise = () => {
    switch (currentSection) {
      case FOLDER_TYPE_CONSTS.SECTIONS.ENTREPRISE.INDEX:
        return 'frise-entreprise-enabled';
      case FOLDER_TYPE_CONSTS.SECTIONS.BESOIN.INDEX:
        return 'frise-besoin-enabled';
      case FOLDER_TYPE_CONSTS.SECTIONS.ADHESION.INDEX:
        return 'frise-adhesion-enabled';
      default:
        return 'frise-unknown-enabled';
    }
  }

  const getTexteLibelleRetour = () => {
    if (FOLDER_TYPE_CONSTS.UTILS.isLastStepTunnel(currentUrl)) {
      return 'Modifier';
    }
    return 'Retour';
  }

  // On active le lien sur le logo seulement à la derniere etape du tunnel (Finalisation)
  const activerLienLogo = () => {
    if (FOLDER_TYPE_CONSTS.UTILS.isLastStepTunnel(currentUrl)) {
      return 'activerLienLogoTunnel';
    }
    return '';
  }

    if (loading) {
      // if your component doesn't have to wait for async data, remove this block
      return null; // render null when app is not ready
    }

    if (tunnel.redirectEtape) {
      disableRedirectRouteEtape();
      if (!tunnel.dossier) {
        return <Redirect to={ FOLDER_TYPE_CONSTS.SECTIONS.ENTREPRISE.URL } />;
      } else {
        // Pour les mobiles, on redirige vers la page informant de continuer sur un desktop
        if (window.innerWidth <= SEUIL_MOBILE) {
          return <Redirect to={ROUTE_CONTINUER_SUR_ORDINATEUR } />;
        }

        //Cas ou l'utilisateur à annuler sa liasse, lors de la reconnexion, il doit être rediriger
        //vers la page précédente(tarification)
        if (
          currentUrl ===  FOLDER_TYPE_CONSTS.URLS.FINALISATION &&
          tunnel.dossier.etapeDossier === 'EN_ATTENTE_DE_SIGNATURE' &&
          tunnel.liasse === null
        ) {
          return <Redirect to={ FOLDER_TYPE_CONSTS.URLS.TARIFICATION + window.location.search } />;
        }

        // CAS PARTICULIER POUR LA SIGNATURE : si on est à l'état "En attente de signature" avec le parametre
        // de retour envoyé par Docusign (?event=signing_complete), alors on redirige vers la page "Information"
        // Cela permet juste de rediriger vers la page Information, le temps que le WEBHOOK passe pour changer l'étape du dossier
        // à "Informations", sinon on resterai sur l'étape "En attente de signature", en on serait alors redirigé vers l'écran
        // Récapitulatif.
        if (
          (currentUrl ===  FOLDER_TYPE_CONSTS.URLS.FINALISATION)
          &&
          (tunnel.dossier.etapeDossier === 'EN_ATTENTE_DE_SIGNATURE' ||
            tunnel.dossier.etapeDossier === 'TRANSMIS')
        ) {
          return <Redirect to={ FOLDER_TYPE_CONSTS.URLS.FINALISATION + window.location.search } />;
        }

        // On redirige seulement dans deux cas : si on vient de la page de connexion / creation compte / mdp oublié
        // OU lorsque la route de l'étape que l'on souhaite atteindre est supérieur a l'étape courant du dossier
        if (
          children.props.location.search === FLAG_REDIRECT ||
          FOLDER_TYPE_CONSTS.UTILS.getEtapeIndexFromUrl(currentUrl) >
          FOLDER_TYPE_CONSTS.UTILS.getEtapeIndexFromEtapeDossier(tunnel.dossier.etapeDossier)
        ) {
          const paramsUrl = FLAG_REDIRECT !== window.location.search ? window.location.search : '';
          return <Redirect to={ FOLDER_TYPE_CONSTS.UTILS.getUrlFromEtapeDossier(tunnel.dossier.etapeDossier) + paramsUrl } />;
        }
      }
    }


  if (redirectRetour) {
      return <Redirect to={'/' + tunnel.routeRetour} />;
    }

    const nomFriseAactiver = activerItemFrise();
    const disabledfrise = FOLDER_TYPE_CONSTS.UTILS.isLastStepTunnel(currentUrl) ? 'hidden-frise' : '';
    const cssClassContentInfo = FOLDER_TYPE_CONSTS.UTILS.isLastStepTunnel(currentUrl) ? 'content-dossier-info' : '';


    // Si le loader est activé, on active la transparence sur toute la page
    let classLayout = 'App-tunnel grid-x';
    if (tunnel.loader) {
      classLayout = classLayout + ' layoutDisable';
    }

    if(disableLayout()){
      classLayout = classLayout + ' hideLayoutTunnel';
    }

    return (
      <React.Fragment>
        <YesLoader />
        <NotificationContainer />
        <PopInInactivite />
        <div className={classLayout}>
          <div className="cell medium-4 cell-aide show-for-medium">
            <div className="aide-container">
              <div className="yes-logo-dossier">
                <a className={'lienLogoTunnel ' + activerLienLogo()} href={config.siteUrl}>
                  <img
                    className="logoDossierYomoni"
                    src={getSvgEnFonctionDuTenant('/assets/img/logos/logo-', '-tunnel.svg')}
                    alt="Yomoni"
                  />
                </a>
              </div>

              <div className="aide-info">
                {FOLDER_TYPE_CONSTS.INFOS_HELPER(currentUrl, tunnel)}
              </div>

              <div className="retourAndTelephone">
                {tunnel.routeRetour !== 'none' ? (
                  <div className="itemTelRetour" onClick={retourEtapePrecedente}>
                    <span className="libelleRetour">{getTexteLibelleRetour()}</span>
                    <img
                      className="logoRetourTunnel"
                      src={getSvgEnFonctionDuTenant('/assets/img/icons/', '/back.svg')}
                      alt="Retour tunnel"
                    />
                  </div>
                ) : (
                  <div />
                )}
                <div className="itemTelRetour">
                  <YesContact />
                </div>
              </div>
            </div>
          </div>

          <div className="cell medium-8 cell-content background-ges">
            <div className="show-for-small-only menu-mobile-tunnel">
              <img width="10%" src="/assets/img/icons/yes/blason.svg" alt="epargne salariale mobile" />
            </div>

            <div className={'show-for-medium frise-dossier ' + nomFriseAactiver}>
              <div className={'inlineBloc ' + disabledfrise}>
                <div className="frise-item frise-votreEntreprise">
                  <img
                    className="icon-etape-une"
                    src={getSvgEnFonctionDuTenant('/assets/img/icons/', '/box-1.svg')}
                    alt="votre entreprise"
                  />
                  <img
                    className="icon-etape-une-done"
                    src={getSvgEnFonctionDuTenant('/assets/img/icons/', '/box-done.svg')}
                    alt="etapeDone"
                  />
                  <span className="frise-txt">
                    <Link to={FOLDER_TYPE_CONSTS.SECTIONS.ENTREPRISE.URL}><TransHtmlText i18nKey="metaEtape.entreprise.title" /></Link>
                  </span>
                </div>
                <div className="frise-item frise-votreBesoin">
                  <img
                    className="icon-etape-deux"
                    src={getSvgEnFonctionDuTenant('/assets/img/icons/', '/box-2.svg')}
                    alt="votre besoin"
                  />
                  <img
                    className="icon-etape-deux-done"
                    src={getSvgEnFonctionDuTenant('/assets/img/icons/', '/box-done.svg')}
                    alt="etapeDone"
                  />
                  <span className="frise-txt">
                    { currentSection < FOLDER_TYPE_CONSTS.SECTIONS.BESOIN.INDEX ?
                      <TransHtmlText i18nKey="metaEtape.besoin.title" />
                      : <Link to={FOLDER_TYPE_CONSTS.SECTIONS.BESOIN.URL}><TransHtmlText i18nKey="metaEtape.besoin.title" /></Link>
                    }
                  </span>
                </div>
                <div className="frise-item frise-votreAdhesion">
                  <img src={getSvgEnFonctionDuTenant('/assets/img/icons/', '/box-3.svg')} alt="votre adhesion" />
                  <span className="frise-txt">
                    { currentSection < FOLDER_TYPE_CONSTS.SECTIONS.ADHESION.INDEX ?
                      <TransHtmlText i18nKey="metaEtape.adhesion.title" />
                      : <Link to={FOLDER_TYPE_CONSTS.SECTIONS.ADHESION.URL}><TransHtmlText i18nKey="metaEtape.adhesion.title" /></Link>
                    }
                  </span>
                </div>
              </div>
              <span className="yes-right-part">
                {tunnel.entreprise && <span className="yes-info">{tunnel.entreprise.raisonSociale}</span>}
                <button onClick={deconnexion}>
                  <img
                    src={getSvgEnFonctionDuTenant('/assets/img/icons/', '/logout.svg')}
                    alt="déconnexion"
                  />
                </button>
              </span>
            </div>
            <div className={'content-dossier ' + cssClassContentInfo}>{children}</div>
          </div>
        </div>
      </React.Fragment>
    );
  };

TunnelLayout.propTypes = {
  setInformationsUtilisateur: PropTypes.func.isRequired,
  setInformationsEntreprise: PropTypes.func.isRequired,
  setInformationsDossier: PropTypes.func.isRequired,
  setLiasse: PropTypes.func.isRequired,
  setFichePersonneMorale: PropTypes.func.isRequired,
  setWorkAdvicesSheets: PropTypes.func.isRequired,
  toggleRedirectRouteEtape: PropTypes.func.isRequired,
  setCountries: PropTypes.func.isRequired,
  children: PropTypes.object,
  tunnel: PropTypes.object
}

const mapStateToProps = ({ tunnel }) => ({
  tunnel
});

const mapDispatchToProps = {
  getPass,
  setInformationsUtilisateur,
  setInformationsEntreprise,
  toggleRedirectRouteEtape,
  setFichePersonneMorale,
  setFichesPero,
  setInformationsDossier,
  setCountries,
  setLiasse,
  setWorkAdvicesSheets
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TunnelLayout);
/* Built in imports */
import React from 'react';

/* Third party imports */
import { Field } from 'redux-form';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';

/* Internal imports */
import SelectCountries from '../../../../form/SelectCountries';
import YesInput from '../../../../form/Input';
import { MOYENS_PAIEMENT } from '../../../../../utils/constantes/US/MOYENSPAIEMENT';
import { requiredValidator } from '../../../../../common/Validators';

const EtablissementEtranger = ({ t }) => (
  <div className="grid-x grid-margin-x">
    <div className="cell medium-12 grid-x grid-margin-x align-middle">
      <div className="cell medium-12">
        <div className="grid-x grid-margin-x fields">
          <div className="cell medium-5 text-field  ">
            <Field
              id={MOYENS_PAIEMENT.PAYS_FONDS}
              name={MOYENS_PAIEMENT.PAYS_FONDS}
              component={SelectCountries}
              labeltext={t('moyenPaiement.etablissementFrancais.pays')}
              validate={requiredValidator}
            />
          </div>
          <div className="cell medium-7 text-field  ">
            <Field
              id={MOYENS_PAIEMENT.MOTIF_ETABLISSEMENT}
              name={MOYENS_PAIEMENT.MOTIF_ETABLISSEMENT}
              component={YesInput}
              labeltext={t('moyenPaiement.etablissementFrancais.motif')}
              validate={requiredValidator}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

EtablissementEtranger.proptype = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(EtablissementEtranger);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const renderSelectOptions = (item) => (
  <option key={item.nom} value={JSON.stringify(item)}>
    {item.nom}
  </option>
);



const SelectCountries = ({ countries, labeltext, input, customError }) => {

  const [country, setCountry] = useState("");

  useEffect(() => {
    if(country === "" && !input.value){
      setCountry(JSON.stringify(countries.france))
    }
    else if(JSON.stringify(countries.france) === input.value){
      setCountry(JSON.stringify(countries.france));
    }
    else{
      let newCountry = countries.countryList.find(option => option.nom === JSON.parse(input.value).nom);
      if(newCountry){
        setCountry(JSON.stringify(newCountry))
      }
    }
  },[countries, input.value])

  const handleChange = (value) => {
    setCountry(value);
  }

  return(
    <div className="divDropdown">
      <label className="labelDropDown">{labeltext}</label>
      <select className="dropdown"
              {...input}
              onChange={(event) => handleChange(event.target.value)}
              value={country}
      >
        <optgroup label="France">
          <option key={countries.france.nom} value={JSON.stringify(countries.france)}>
            {countries.france.nom}
          </option>
        </optgroup>
        <optgroup label="Autres Pays">{countries.countryList.map(renderSelectOptions)}</optgroup>
      </select>
      {customError && <div className="errorMsgInput">{customError}</div>}
    </div>
  )
};

const mapStateToProps = (state) => ({
  countries: state.countries
});

export default connect(mapStateToProps)(SelectCountries);

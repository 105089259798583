/* Built-in imports */
import React, { useEffect, useState } from 'react';

/* Third-party imports */
import { connect } from "react-redux";

/* Internal imports */
import { formatOption, getAppDefaultCountry, getDrom } from "../../../../utils/data/countriesUtils";

const SelectDialingCode = ({ indicatifCode, labeltext, input, customError }) => {
  const defaultCountry = getAppDefaultCountry();
  const [selectedDialingCode, setSelectedDialingCode] = useState(defaultCountry.countryCodeA2);
  const drom = getDrom();

  useEffect(() => {
    if(indicatifCode) {
      setSelectedDialingCode(indicatifCode);
    }
  },[indicatifCode]);

  return(
    <div className="divDropdown">
      <label className="labelDropDown">{labeltext}</label>
      <select
        className="dropdown"
        {...input}
        onChange={(e) => {
          setSelectedDialingCode(e.target.value);
          e.target.blur();
        }}
        value={selectedDialingCode}
      >
        <optgroup label="Métropole">
          <option key={defaultCountry.countryCodeA2} value={defaultCountry.countryCodeA2}>
            {formatOption(defaultCountry)}
          </option>
        </optgroup>
        <optgroup label="DROM">
          {drom.map((drom) => (
            <option key={drom.countryCodeA2} value={drom.countryCodeA2}>
              {formatOption(drom)}
            </option>
          ))}
        </optgroup>
      </select>
      {customError && <div className="errorMsgInput">{customError}</div>}
    </div>
  )
};

const mapStateToProps = (state) => ({
  indicatifCode: state.tunnel.utilisateur.indicatifCode
})

export default connect(mapStateToProps)(SelectDialingCode);

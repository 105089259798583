/* Built-in imports */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

/* Third-party imports */
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
/* Internal imports */
import IdentiteForm from '../../../../components/US/epargne_salariale/forms/IdentiteForm';
import { URLS_MAROQUINERIE } from '../../../../utils/constantes/ROUTES/MAROQUINERIE';
import { getEpargneIdentiteFormSubmit } from '../../../../utils/US/components/epargne_salariale/forms/identiteUtils';
import { mettreAjourFonctionDirigeant, mettreAjourIdentiteDirigeant } from '../../../../services/UtilisateurService';
import {
  setDonneesDirigeant,
  setInformationsUtilisateur,
  setRetourRoute
} from '../../../../modules/tunnel/redux/tunnelActions';

const MaroquinerieIdentitePage = ({
                                    tunnel,
                                    setRetourRoute,
                                    setInformationsUtilisateur,
                                    setDonneesDirigeant
                                  }) => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setRetourRoute(URLS_MAROQUINERIE.ENTREPRISE_SALARIES.substring(1));
  }, []);

  const submit = async (values) => {
    const utilisateurUid = tunnel.utilisateur.uid;
    const valuesAenvoye = getEpargneIdentiteFormSubmit(values, tunnel.entreprise.uid);

    try {
      await mettreAjourIdentiteDirigeant(utilisateurUid, valuesAenvoye);
      const infosFonctionDirigeant = await mettreAjourFonctionDirigeant(utilisateurUid, valuesAenvoye);
      setInformationsUtilisateur(infosFonctionDirigeant);
      setDonneesDirigeant(infosFonctionDirigeant);
      setRedirect(true);
    } catch (error) {
      console.log(error);
    }
  }

  if (redirect) {
    return <Redirect to={URLS_MAROQUINERIE.DISPOSITIF_ACTUEL} />;
  }

  return (
    <div>
      <IdentiteForm onSubmit={submit} />
    </div>
  );
}

MaroquinerieIdentitePage.propTypes = {
  tunnel: PropTypes.object.isRequired,
  setRetourRoute: PropTypes.func.isRequired,
  setInformationsUtilisateur: PropTypes.func.isRequired,
  setDonneesDirigeant: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setInformationsUtilisateur: (utilisateur) => dispatch(setInformationsUtilisateur(utilisateur)),
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setDonneesDirigeant: (donneesDirigeant) => dispatch(setDonneesDirigeant(donneesDirigeant))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaroquinerieIdentitePage);

import React from "react";

const COOKIE_NAME = "yomoni_audience";

const message = `En poursuivant votre navigation sur ce site, vous acceptez l’utilisation
de cookies afin de réaliser des statistiques d'audiences, et vous
proposer des contenus personnalisés.`;

// Copié de https://stackoverflow.com/questions/10730362/get-cookie-by-name
const getCookie = name => {
  let value = "; " + document.cookie;
  let parts = value.split("; " + name + "=");
  if (parts.length === 2) {
    return parts
      .pop()
      .split(";")
      .shift();
  }
  return undefined;
};

// Copié de https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript
const createCookie = (name, value, days, domain, path = "/") => {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }

  let cookieString = name + "=" + value + expires;
  if (domain) {
    cookieString += "; domain=" + domain;
  }
  cookieString += "; path=" + path;
  document.cookie = cookieString;
};

class CookieBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cookie: getCookie(COOKIE_NAME) };
  }

  handleClick = () => {
    createCookie(COOKIE_NAME, "Yomoni", 365);
    this.setState({ cookie: true });
  };

  render() {
    const { cookie } = this.state;
    if (cookie) {
      return null;
    }
    return (
      <div className="cookiebanner">
        {message}
        <button onClick={this.handleClick}>Accepter</button>
      </div>
    );
  }
}

export default CookieBanner;

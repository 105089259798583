/* built-in imports */
import React, { Component } from 'react';

/* third-party imports */
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

/* Internal imports */
import TooltipInfo from '../../utils/US/components/TooltipInfo';
import { COLOR as TYPOGRAPHY_COLOR } from '../../utils/constantes/DS/TYPOGRAPHY';

const numberMask = createNumberMask({
  prefix: '',
  suffix: '',
  thousandsSeparatorSymbol: ' '
});

const euroMask = createNumberMask({
  prefix: '',
  suffix: ' €',
  thousandsSeparatorSymbol: ' '
});

const pourcentMask = createNumberMask({
  prefix: '',
  suffix: ' %',
  allowDecimal: true,
  decimalLimit: 1,
  integerLimit: 1,
  decimalSymbol: ','
});

const pourcentDecimalMask = createNumberMask({
  prefix: '',
  suffix: ' %',
  allowDecimal: true,
  decimalLimit: 2,
  integerLimit: 3,
  decimalSymbol: ','
});

/*
Utilisation du composant Input :

    <Field  component={YesInput} type="text"

    puis :

    name : nom du champ texte
    id : peut être la même valeur que le champ name
    labeltext : valeur du label qui sera affiché dans le champ, et qui remontra lors que l'on focus le champ
    placeholder : valeur du champ lorsque l'on focus le champ, sans le remplir
    validate : choisir un validateur de champ (dans Validators.js) ou en créé un si besoin
    customError : affiche une erreur remontant du back au niveau du champ
    tooltip : texte du tooltip s'affichant au survol d'une icone avec un point d'interrogation (icone d'aide)
    numberfield : true si c'est un champ numérique, cela permettra d'avoir un nombre bien formaté. Exemple : 1 000 000 au lieu de 1000000
    euroField : true si c'est un champ monétaire. Cela afficherai le sympbole euro héritera de l'option "numberfield". Exemple : 1 000 000 € au lieu de 1000000
    pourcentField : true si c'est un champ monétaire. Cela afficherai le sympbole pourcent héritera de l'option "numberfield". Exemple : 1 000 000 % au lieu de 1000000
    mask : permet de définir un masque personnalisé, qui s'applique à la saisie. Par exemple pour un mask de téléphone :  01 11 11 11 11
    Pour faire cela : {[/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]}
    disabled : champ désactivé


*/
export default class YesInput extends Component {
  constructor(props) {
    super(props);
    this.state = { showCustomError: true, mask: this.props.mask };
  }

  componentDidMount() {
    if (this.props.numberfield) {
      this.setState({
        mask: numberMask
      });
    }
  }

  render() {
    const {
      input,
      labeltext,
      euroField,
      pourcentField,
      pourcentDecimalField,
      customError,
      tooltip,
      disabled,
      mask,
      meta: { touched, error, warning, active, valid },
      required,
      ...rest
    } = this.props;

    let customErrorMsg = this.props.customError;
    let classnameInput = input.value ? 'inputText hasValue' : 'inputText';
    const classnameDivInput =
      touched && (error || (this.state.showCustomError && customErrorMsg)) ? 'divInput errorInput' : 'divInput';

    let classnameDisabled = '';

    if (disabled) {
      classnameDisabled = 'inputDisabled';
    } else {
      classnameDisabled = 'inputEnabled';
    }

    let classnameValid = '';
    if (valid) {
      classnameValid = ' inputValid';
    }

    let activePlaceholder = active ? ' activePlaceholder' : '';

    let classnameLabel = 'label-input';
    if (this.props.input.value.length !== 0 || this.props.meta.active) {
      classnameLabel = 'label-input label-color';
    }

    return (
      <div className={classnameDisabled}>
        {tooltip && <TooltipInfo tooltipContent={tooltip} />
        }

        <div className={classnameDivInput + activePlaceholder + classnameValid}>
          {!euroField && !pourcentField && !pourcentDecimalField && (
            <React.Fragment>
              {!this.state.mask && <input className={classnameInput} value={this.props.input.value} {...input} {...rest} />}
              {this.state.mask && (
                <MaskedInput
                  className={classnameInput}
                  value={this.props.input.value}
                  {...input}
                  {...rest}
                  mask={this.state.mask}
                />
              )}
            </React.Fragment>
          )}
          {euroField && (
            <MaskedInput className={classnameInput} value={this.props.input.value} {...input} {...rest} mask={euroMask} />
          )}
          {pourcentField && (
            <MaskedInput className={classnameInput} value={this.props.input.value} {...input} {...rest} mask={pourcentMask} />
          )}
          {pourcentDecimalField && (
            <MaskedInput
              className={classnameInput}
              value={this.props.input.value}
              {...input}
              {...rest}
              mask={pourcentDecimalMask}
            />
          )}

          <label className={classnameLabel}>
            {labeltext} {required ? <span style={{color: TYPOGRAPHY_COLOR.PRIMARY, fontWeight: "bold"}}> * </span> : null}
          </label>
        </div>

        {touched &&
          ((error && <span className="errorMsgInput">{error}</span>) ||
            (this.state.showCustomError && customErrorMsg && (
              <span className="customErrorMsg errorMsgInput" dangerouslySetInnerHTML={{ __html: customErrorMsg }} />
            )) ||
            (warning && <span className="warningInput">{warning}</span>))}
      </div>
    );
  }
}

YesInput.defaultProps = {
  disabled: false
};

import axios from 'axios';
import { PREFIX_BACK } from '../common/Configuration';
import { getToken } from '../services/ResourcesService';

export default function() {
  return axios.create({
    baseURL: PREFIX_BACK,
    headers: {
      'Authorization': `Bearer ${getToken()}`,
    }
  })
}
/* built-in imports */
import React from 'react';
import { connect } from 'react-redux';

/* third-party imports */
import { useTranslation, withTranslation } from 'react-i18next';

/* Internal imports */
import './workAdvicesSheetsSummaryStyle.css';
import RecapitulatifBloc from '../../assurance/recapitulatif/RecapitulatifBloc';
import TextWithTitle from '../../assurance/recapitulatif/TextWithTitle';

const WorkAdvicesSheetsSummary = ({ workAdvicesSheets }) => {
  const {
    isFirstWorkAdvicesSheets,
    isRetirementContract,
    subscriptionReasons,
    isContractor,
    contractorInfos,
    isEmployeesRegiment,
    regimentInfos,
    isSignatory,
    legalObligationSelected,
    particularObservations,
    particularObservationsPensionGuarantees,
    isAllEmployeesSubscribe,
    contractCategorySelected
  } = workAdvicesSheets;
  const { t } = useTranslation(),
    tradKey = "work_advices_sheets_summary";

  return (
    <RecapitulatifBloc
      title={t(`${tradKey}.title`)}
    >
      <TextWithTitle
        title={isFirstWorkAdvicesSheets.label}
        content={isFirstWorkAdvicesSheets.checked ? t(`${tradKey}.response.yes`) : t(`${tradKey}.response.no`)}
      />
      <TextWithTitle
        title={isRetirementContract.label}
        content={isRetirementContract.checked ? t(`${tradKey}.response.yes`) : t(`${tradKey}.response.no`)}
      />
      {isRetirementContract.checked && (
        <ul className="work_advices_sheets-ul--contrasted-color work_advices_sheets-ul--liststyle-none">
          {isRetirementContract.values.isRetirementCollectivePerco.checked && (
            <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">{isRetirementContract.values.isRetirementCollectivePerco.label}</li>
          )}
          {isRetirementContract.values.isRetirementCollectiveRequiredPerco.checked && (
            <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">{isRetirementContract.values.isRetirementCollectiveRequiredPerco.label}</li>
          )}
          {isRetirementContract.values.isRetirementCollectivePercol.checked && (
            <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">{isRetirementContract.values.isRetirementCollectivePercol.label}</li>
          )}
          {isRetirementContract.values.isRetirementArt83.checked && (
            <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">{isRetirementContract.values.isRetirementArt83.label}</li>
          )}
          {isRetirementContract.values.isRetirementArt39.checked && (
            <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">{isRetirementContract.values.isRetirementArt39.label}</li>
          )}
          {isRetirementContract.values.other.value.length > 0 && (
            <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">
              <TextWithTitle
                title={isRetirementContract.values.other.label}
                content={isRetirementContract.values.other.value}
              />
            </li>
          )}
        </ul>
      )}
      <TextWithTitle
        title={t('components.US.form.work-advices-sheets-contract-form.radio.treat.subscription')}
        content=''
      />
      <ul className="work_advices_sheets-ul--contrasted-color work_advices_sheets-ul--liststyle-none">
        {subscriptionReasons.isPerobToPacte.checked && (
          <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">{subscriptionReasons.isPerobToPacte.label}</li>
        )}
        {subscriptionReasons.isCapitalToSavings.checked && (
          <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">{subscriptionReasons.isCapitalToSavings.label}</li>
        )}
        {subscriptionReasons.isPaymentsVoluntaryPunctual.checked && (
          <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">{subscriptionReasons.isPaymentsVoluntaryPunctual.label}</li>
        )}
        {subscriptionReasons.isPaymentsSavings.checked && (
          <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">{subscriptionReasons.isPaymentsSavings.label}</li>
        )}
        {subscriptionReasons.isInvestments.checked && (
          <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">{subscriptionReasons.isInvestments.label}</li>
        )}
        {subscriptionReasons.isManagementChoice.checked && (
          <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">{subscriptionReasons.isManagementChoice.label}</li>
        )}
      </ul>
      <TextWithTitle
        title={isContractor.label}
        content={isContractor.checked ? t(`${tradKey}.response.yes`) : t(`${tradKey}.response.no`)}
      />
      <TextWithTitle
        title={t('components.US.form.work-advices-sheets-employees.contractor-sub-form.representative.firstname.label')}
        content={contractorInfos.firstname}
      />
      <TextWithTitle
        title={t('components.US.form.work-advices-sheets-employees.contractor-sub-form.representative.lastname.label')}
        content={contractorInfos.lastname}
      />
      <TextWithTitle
        title={t('components.US.form.work-advices-sheets-employees.contractor-sub-form.representative.job.label')}
        content={contractorInfos.job}
      />
      <TextWithTitle
        title={isEmployeesRegiment.label}
        content={isEmployeesRegiment.checked ? t(`${tradKey}.response.yes`) : t(`${tradKey}.response.no`)}
      />
      {isEmployeesRegiment.checked && (
        <TextWithTitle
          title={t('components.US.form.work-advices-sheets-employees.employees-sub-form.retirement.regime.label')}
          content={regimentInfos.regimentSelected.dues.label}
        />
      )}
      {regimentInfos.employeesAverageAge.value > 0 && (
        <TextWithTitle
          title={regimentInfos.employeesAverageAge.label}
          content={`${regimentInfos.employeesAverageAge.value} ${t('work_advices_sheets_summary.suffix.years')}`}
        />
      )}
      {regimentInfos.employeesCount.value > 0 && (
        <TextWithTitle
          title={regimentInfos.employeesCount.label}
          content={regimentInfos.employeesCount.value}
        />
      )}
      {regimentInfos.iddc.value > 0 && (
        <TextWithTitle
          title={regimentInfos.iddc.label}
          content={regimentInfos.iddc.value}
        />
      )}
      {regimentInfos.conventionName.value.length > 0 && (
        <TextWithTitle
          title={regimentInfos.conventionName.label}
          content={regimentInfos.conventionName.value}
        />
      )}
      <TextWithTitle
        title={isAllEmployeesSubscribe.label}
        content={isAllEmployeesSubscribe.checked ? t(`${tradKey}.response.yes`) : t(`${tradKey}.response.no`)}
      />

      {isAllEmployeesSubscribe && (
        <TextWithTitle
            title={t(`${tradKey}.subtitle.cadre`)}
        />
      )}
      <ul className="work_advices_sheets-ul--liststyle-none">
        {contractCategorySelected.cadre.values.art21.checked && (
          <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">
            <TextWithTitle
              title={contractCategorySelected.cadre.values.art21.label}
              content={'Effectif:  ' + contractCategorySelected.cadre.values.art21.employeesCount}
            />
          </li>
        )}
        {contractCategorySelected.cadre.values.art2122.checked && (
          <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">
            <TextWithTitle
              title={contractCategorySelected.cadre.values.art2122.label}
              content={'Effectif:  ' + contractCategorySelected.cadre.values.art2122.employeesCount}
            />
          </li>
        )}
        {contractCategorySelected.cadre.values.art2122Br.checked && (
          <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">
            <TextWithTitle
              title={contractCategorySelected.cadre.values.art2122Br.label}
              content={'Effectif:  ' + contractCategorySelected.cadre.values.art2122Br.employeesCount}
            />
          </li>
        )}
      </ul>

      {isAllEmployeesSubscribe && (
        <TextWithTitle
          title={t(`${tradKey}.subtitle.noCadre`)}
          customStyle={{marginTop: '-18px'}}
        />
      )}
      <ul className="work_advices_sheets-ul--liststyle-none">
        {contractCategorySelected.noCadre.values.art21.checked && (
          <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">
            <TextWithTitle
              title={contractCategorySelected.noCadre.values.art21.label}
              content={'Effectif:  ' + contractCategorySelected.noCadre.values.art21.employeesCount}
            />
          </li>
        )}
        {contractCategorySelected.noCadre.values.art2122.checked && (
          <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">
            <TextWithTitle
              title={contractCategorySelected.noCadre.values.art2122.label}
              content={'Effectif:  ' + contractCategorySelected.noCadre.values.art2122.employeesCount}
            />
          </li>
        )}
        {contractCategorySelected.noCadre.values.art2122Br.checked && (
          <li className="work_advices_sheets-ul--li-liststyle-contrasted-color">
            <TextWithTitle
              title={contractCategorySelected.noCadre.values.art2122Br.label}
              content={'Effectif:  ' + contractCategorySelected.noCadre.values.art2122Br.employeesCount}
            />
          </li>
        )}
      </ul>
      <TextWithTitle
        title={isSignatory.label}
        content={isSignatory.checked ? t(`${tradKey}.response.yes`) : t(`${tradKey}.response.no`)}
      />
      <TextWithTitle
        title={t('components.US.form.work-advices-sheets-observations-form.wish.label')}
        content={legalObligationSelected.legalObligations.checked ? legalObligationSelected.legalObligations.label : legalObligationSelected.beyondLegalObligations.label}
        customStyle={{marginTop: '18px'}}
      />
      {particularObservations.value.length > 0 && (
        <TextWithTitle
          title={particularObservations.label}
          content={particularObservations.value}
        />
      )}
      {particularObservationsPensionGuarantees.value.length > 0 && (
        <TextWithTitle
          title={particularObservationsPensionGuarantees.label}
          content={particularObservationsPensionGuarantees.value}
        />
      )}
    </RecapitulatifBloc>
  )
}

export default connect(({ workAdvicesSheets }) => ({
  workAdvicesSheets,
}))(withTranslation()(WorkAdvicesSheetsSummary));
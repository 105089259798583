import React, { useEffect } from 'react';

/* third-party imports */
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import RadioGroup from '@mui/material/RadioGroup';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

/* Internal imports */
import Alert from '../../DS/feedback/Alert';
import FormControlLabel from '../../DS/inputs/form/FormControlLabel';
import FormLabel from '../../DS/inputs/form/FormLabel';
import Radio from '../../DS/inputs/Radio';
import TextField from '../../DS/inputs/TextField';
import { LEGALOBLIGATIONS } from '../../../utils/constantes/US/WORKADVICESSHEETSMODAL';

const renderRadioGroup = ({ input, children, ...rest }) => (
  <RadioGroup
    {...input}
    {...rest}
  >
    {children}
  </RadioGroup>
)

const renderTextField = ({ input, label, placeholder, ...custom }) => (
  <TextField
    sx={{ display: 'flex'}}
    label={label}
    placeholder={placeholder}
    variant="filled"
    {...input}
    {...custom}
  />
)

const WorkAdvicesSheetsObservations =  function(props) {
  const tradKey = 'components.US.form.work-advices-sheets-observations-form',
    { t }   = useTranslation(),
    {
      legalObligationSelected,
      particularObservations,
      particularObservationsPensionGuarantees,
      showErrors,
      handleLegalObligationSelected,
      handleParticularObservationsChange,
    } = props;

  useEffect(() => {
    props.change("legal-obligations-radio-buttons-group", legalObligationSelected);
    props.change("particular-observation", particularObservations);
    props.change("particular-observation-pension-guarantees", particularObservationsPensionGuarantees);
  });

  return(
    <Box sx={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Grid container>
        <Grid item>
          <FormControl>
            <FormLabel required id="legal-obligations">{`${t(`${tradKey}.wish.label`)} :`}</FormLabel>
            <Field
              aria-labelledby="legal-obligations"
              name="legal-obligations-radio-buttons-group"
              value={legalObligationSelected}
              onChange={(event) => handleLegalObligationSelected(event)}
              component={renderRadioGroup}
            >
              <FormControlLabel value={LEGALOBLIGATIONS.LEGALOBLIGATIONS} control={<Radio />} label={t(`${tradKey}.wish.legal-obligations`)} />
              <FormControlLabel value={LEGALOBLIGATIONS.BEYONDLEGALOBLIGATIONS} control={<Radio />} label={t(`${tradKey}.wish.beyond-legal-obligations`)} />
            </Field>
          </FormControl>
          { !legalObligationSelected && showErrors &&
            <Grid item xs={12} sx={{ marginTop: '.5em' }}>
              <Alert sx={{ alignItems: 'center' }} severity="error">{t(`${tradKey}.error`)}</Alert>
            </Grid>
          }
        </Grid>
        <Divider sx={{
          marginTop: '2em',
          marginBottom: '1em',
          flex: '0 0 100%',
          borderColor: 'var(--cn-color-primary)'
        }}/>
        <Grid
          item
          xs={12}
          sx={{
            paddingTop: '1em',
          }}
        >
          <Field
            component={renderTextField}
            name="particular-observation"
            label={`${t(`${tradKey}.particular-observations.label`)} :`}
            placeholder={t(`${tradKey}.particular-observations.placeholder`)}
            value={particularObservations}
            onChange={(event) => handleParticularObservationsChange(event, 'particularObservations')}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            paddingTop: '1em',
          }}
        >
          <Field
            component={renderTextField}
            name="particular-observation-pension-guarantees"
            label={`${t(`${tradKey}.particular-observations-pension-guarantees.label`)} :`}
            placeholder={t(`${tradKey}.particular-observations-pension-guarantees.placeholder`)}
            value={particularObservationsPensionGuarantees}
            onChange={(event) => handleParticularObservationsChange(event, 'particularObservationsPensionGuarantees')}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

WorkAdvicesSheetsObservations.propTypes = {
  legalObligationSelected: PropTypes.string.isRequired,
  particularObservations: PropTypes.string.isRequired,
  particularObservationsPensionGuarantees: PropTypes.string.isRequired,
  handleLegalObligationSelected: PropTypes.func.isRequired,
  handleParticularObservationsChange: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: 'WorkAdvicesSheetsObservations'
})(WorkAdvicesSheetsObservations)

import React, { Component } from "react";
import { connect } from "react-redux";
import Graphiques from "./Graphiques";
import DetailEntreprise from "./DetailEntreprise";
import DetailSalarie from "./DetailSalarie";

class Economies extends Component {
  render() {
    const { simulateur } = this.props;

    return (
      <div>
        {simulateur.ecran.synthese && <Graphiques />}
        {simulateur.ecran.detailEntreprise && <DetailEntreprise />}
        {simulateur.ecran.detailSalarie && <DetailSalarie />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  simulateur: state.simulateur
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Economies);

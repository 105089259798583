/* Built-in imports */
import React, { Component } from "react";

/* Third-party imports */
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

/* Internal imports */
import EntrepriseComplementForm from "../../../../components/US/epargne_salariale/forms/EntrepriseComplementForm";
import { URLS_EPARGNE } from "../../../../utils/constantes/ROUTES/EPARGNE";
import { getDossierByUidEntreprise } from "../../../../services/DossierService";
import { mettreAjourInformationsComplementairesEntreprise } from "../../../../services/EntrepriseService";
import {
  cloneValues,
  nombreSansEspaces
} from "../../../../common/FunctionsUtils";
import {
  setInformationsComplementairesEntreprise,
  setInformationsDossier,
  setInformationsEntreprise,
  setInformationsUtilisateur,
  setLiasse,
  setRetourRoute
} from "../../../../modules/tunnel/redux/tunnelActions";

class EpargneEntrepriseComplementPage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    // On modifie la route lorsque l'utilisateur clique le bouton Retour
    this.props.setRetourRoute(URLS_EPARGNE.ENTREPRISE_INFORMATION.substring(1));
    this.state = {
      redirect: false
    };
  }

  submit(values) {
    let valuesAenvoye = Object.assign(cloneValues(values), {
      nombreSalaries: nombreSansEspaces(values.nombreSalaries)
    });

    mettreAjourInformationsComplementairesEntreprise(
      this.props.tunnel.entreprise.uid,
      { valuesAenvoye }
    )
      .then(infosComplementaires => {
        this.props.setInformationsComplementairesEntreprise(
          infosComplementaires.complement
        );

        // On update la date de cloture comptable, ce qui va impacter la participation volontaire si elle existe
        if (
          (this.props.tunnel.dossier.participationVolontaire &&
            this.props.tunnel.dossier.participationVolontaire
              .participationActive) ||
          (this.props.tunnel.dossier.participation &&
            this.props.tunnel.dossier.participation.participationActive)
        ) {
          getDossierByUidEntreprise(this.props.tunnel.entreprise.uid).then(
            dossiers => {
              let dossierAvecEntreprise = dossiers[0];
              this.props.setInformationsEntreprise(
                dossierAvecEntreprise.entreprise
              );
              this.props.setInformationsDossier(dossierAvecEntreprise.dossier);
              this.props.setInformationsUtilisateur(
                dossierAvecEntreprise.utilisateur
              );
              this.props.setLiasse(dossierAvecEntreprise.liasse);
            }
          );
        }
        this.setState({
          redirect: true
        });
      })
      .catch(error => {
        if (error && error.message) {
          this.setState({
            errorMsg: error.message
          });
        }
      });
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={URLS_EPARGNE.IDENTITE} />;
    }

    return (
      <div>
        <EntrepriseComplementForm onSubmit={this.submit} />
      </div>
    );
  }
}

EpargneEntrepriseComplementPage.propTypes = {
  setInformationsDossier: PropTypes.func.isRequired,
  setInformationsEntreprise: PropTypes.func.isRequired,
  setInformationsUtilisateur: PropTypes.func.isRequired,
  setInformationsComplementairesEntreprise: PropTypes.func.isRequired,
  setLiasse: PropTypes.func.isRequired,
  setRetourRoute: PropTypes.func.isRequired,
  tunnel: PropTypes.shape({
    dossier: PropTypes.object,
    entreprise: PropTypes.object
  }).isRequired
};

// REDUX + REDUX FORM
const mapStateToProps = state => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = dispatch => ({
  setRetourRoute: route => dispatch(setRetourRoute(route)),
  setInformationsEntreprise: entreprise =>
    dispatch(setInformationsEntreprise(entreprise)),
  setInformationsComplementairesEntreprise: complement =>
    dispatch(setInformationsComplementairesEntreprise(complement)),
  setInformationsDossier: dossier => dispatch(setInformationsDossier(dossier)),
  setInformationsUtilisateur: utilisateur =>
    dispatch(setInformationsUtilisateur(utilisateur)),
  setLiasse: liasse => dispatch(setLiasse(liasse))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EpargneEntrepriseComplementPage);

/* Built in imports */
import React, { Component } from 'react';

/* Third party imports */
import { Field, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

/* Internal imports */
import config from '../../../../common/StaticSiteConfig';
import { TransHtmlText } from '../../../yes/utils/TransHtmlText';
import { YesButton } from '../../../form/Button';
import { YesChoix } from '../../../form/Choix';
import { isMessagesAccompagnementAccordsEnabled } from '../../../../services/TenantService';
import { stringToBoolean } from '../../../../common/FunctionsUtils';

class InteressementForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      interessementChecked: false
    };
  }

  componentDidMount() {
    if (this.props.tunnel.dossier.interessement !== null) {
      if (this.props.tunnel.dossier.interessement.interessementActive) {
        this.props.change('interessement', 'oui');
      } else {
        this.props.change('interessement', 'non');
      }
      this.setState({
        interessementChecked: this.props.tunnel.dossier.interessement.interessementActive
      });
    }
  }

  toggleInteressement = (value) => {
    this.setState({
      interessementChecked: stringToBoolean(value.target.value)
    });
  };

  render() {
    const { handleSubmit, pristine, invalid, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} className="formTunnel formInteressement">
        <label className="titreFormulaire">
          <Trans i18nKey="interessement.titre" />
        </label>

        <div className="grid-x grid-margin-x">
          <div className="cell medium-12">
            <h3>
              <TransHtmlText i18nKey="interessement.question.miseEnPlace" params={{ config: config }} />
            </h3>

            <div className="tunnelRadios">
              <div className="tunnelRadio">
                <Field
                  name="interessement"
                  component={YesChoix}
                  type="radio"
                  value={'oui'}
                  label="Oui"
                  id="oui"
                  onChange={this.toggleInteressement}
                />
              </div>

              <div className="tunnelRadio">
                <Field
                  name="interessement"
                  component={YesChoix}
                  type="radio"
                  value={'non'}
                  label="Non"
                  id="non"
                  onChange={this.toggleInteressement}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.interessementChecked && isMessagesAccompagnementAccordsEnabled() && (
          <div className="cell medium-12 messageAccompagnement">
            <TransHtmlText
              i18nKey="interessement.message.accompagnement.accords"
              params={{
                config: config
              }}
            />
          </div>
        )}
        <div className="buttonCreationContainer">
          <YesButton text="Confirmer" disabled={invalid || submitting || pristine} />
        </div>
      </form>
    );
  }
}

// REDUX + REDUX FORM
const mapStateToProps = (state) => ({
  tunnel: state.tunnel
})

export default connect(mapStateToProps)(reduxForm({ form: 'interessementForm' })(InteressementForm));

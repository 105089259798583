import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

const AlertRoot = styled(Alert, { name: 'Alert' })(() => ({
  '&.MuiAlert-root': {
    '& .MuiAlert-message': {
      fontFamily: 'var(--cn-font-family-source-pro-sans-serif)',
    },
  },
}))

export default AlertRoot
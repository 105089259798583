import React, { Component } from "react";
import { connect } from "react-redux";
import { voirEcranSynthese } from "../../../../modules/simulateur/redux/simulateurActions";
import { nombreAvecEspaces } from "../../../../common/FunctionsUtils";

class Graphiques extends Component {
  render() {
    const { simulateur } = this.props;

    return (
      <div className="details-restitution">
        <div className="grid-x grid-margin-x">
          <div className="cell medium-12 yes-titre-rectangle">
            Économies réalisées - Détails
          </div>

          <div className="cell medium-12 yes-titre-rectangle">
            <div className="titreGraph">Côté salarié</div>
            <div className="enDetails" onClick={this.props.voirEcranSynthese}>
              Retour à la synthèse
              <img
                src="/assets/img/icons/yes/minus-circular-button.svg"
                alt="detail entreprise"
              />
            </div>
          </div>

          <div className="cell medium-12">
            <table className="yes-table">
              <thead>
                <tr>
                  <th />
                  <th>Prime classique</th>
                  <th>Épargne salariale</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Montant Brut</td>
                  <td>
                    {nombreAvecEspaces(
                      simulateur.primeClassique.primeAttribuee
                    )}{" "}
                    €
                  </td>
                  <td>
                    {nombreAvecEspaces(
                      simulateur.epargneSalariale.dispositif
                        .enveloppeIndividuelle
                    )}{" "}
                    €
                  </td>
                </tr>
                <tr>
                  <td>Charges sociales salariales</td>
                  <td>
                    {nombreAvecEspaces(
                      simulateur.primeClassique.sommePercueSalarie
                        .chargesSocialesSalarie
                    )}{" "}
                    €
                  </td>
                  <td>exonéré</td>
                </tr>
                <tr>
                  <td>CSG-CRDS</td>
                  <td>
                    {nombreAvecEspaces(
                      simulateur.primeClassique.sommePercueSalarie.csgCrds
                    )}{" "}
                    €
                  </td>
                  <td>
                    {nombreAvecEspaces(
                      simulateur.epargneSalariale.sommePercueSalarie.csgCrds
                    )}{" "}
                    €
                  </td>
                </tr>
                <tr>
                  <td>Impôt sur le revenu</td>
                  <td>
                    {nombreAvecEspaces(
                      simulateur.primeClassique.sommePercueSalarie
                        .impotSurRevenu
                    )}{" "}
                    €
                  </td>
                  <td>exonéré</td>
                </tr>
                <tr className="background-green-white-text">
                  <td>Montant perçu par le salarié</td>
                  <td>
                    {nombreAvecEspaces(
                      simulateur.primeClassique.sommePercueSalarie
                        .sommePercueSalarie
                    )}{" "}
                    €
                  </td>
                  <td className="yes-gras">
                    {nombreAvecEspaces(
                      simulateur.epargneSalariale.sommePercueSalarie
                        .sommePercueSalarie
                    )}{" "}
                    €
                  </td>
                </tr>
              </tbody>
            </table>

            <p className="detail-explication">
              <span>Le gain pour le salarié est de </span>
              <span className="yes-green yes-gras">
                {nombreAvecEspaces(
                  simulateur.epargneSalariale.sommePercueSalarie
                    .sommePercueSalarie -
                    simulateur.primeClassique.sommePercueSalarie
                      .sommePercueSalarie
                )}
              </span>{" "}
              <span className="yes-green">€</span>
            </p>
            <p className="detail-explication">
              Les sommes perçues par le bénéficiaire correspondant à la
              participation, l’intéressement et l’abondement sont exonérées :
            </p>
            <p className="detail-explication-enum">
              <span>
                • d’impôt sur le revenu, à condition d’être versées dans un plan
                d'épargne (PEE, PERCO),
              </span>
              <span>• de charges sociales salariales.</span>
            </p>
            <p className="detail-explication">
              Ces sommes sont uniquement assujetties à la CSG-CRDS.
            </p>
            <p className="detail-explication">
              Les sommes récupérées à l’échéance ou lors d’un cas de déblocage
              anticipé sont exonérées d’impôt sur le revenu*.
            </p>
            <p className="detail-explication detail-explication-indication">
              *hors CSG/CRDS, prélèvements sociaux et contributions
              additionnelles
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  simulateur: state.simulateur
});

const mapDispatchToProps = dispatch => ({
  voirEcranSynthese: () => dispatch(voirEcranSynthese())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Graphiques);

export function isRetirementContractOptionsError(retirementContractOptions) {
  const {
    isRetirementCollectivePerco,
    isRetirementCollectiveRequiredPerco,
    isRetirementCollectivePercol,
    isRetirementArt83,
    isRetirementArt39,
    otherContract
  } = retirementContractOptions;

  return !(
    isRetirementCollectivePerco
    || isRetirementCollectiveRequiredPerco
    || isRetirementCollectivePercol
    || isRetirementArt83
    || isRetirementArt39
    || (otherContract && otherContract.length > 0)
  )
}

export function isSubscriptionReasonsError(subscriptionReasons) {
  const {
    isPerobToPacte,
    isCapitalToSavings,
    isPaymentsVoluntaryPunctual,
    isPaymentsSavings,
    isInvestments,
    isManagementChoice
  } = subscriptionReasons;

  return !(
    isPerobToPacte
    || isCapitalToSavings
    || isPaymentsVoluntaryPunctual
    || isPaymentsSavings
    || isInvestments
    || isManagementChoice
  )
}

export function isContractFormErrors(retirementContractOptions, subscriptionReasons) {
  return isRetirementContractOptionsError(retirementContractOptions) || isSubscriptionReasonsError(subscriptionReasons)

}
import { ACTIVITY_DOMAINS, URL_SUBSCRIPTION_TYPE } from './ROUTES';

const MAROQUINERIE_URL_PREFIX = URL_SUBSCRIPTION_TYPE.EPARGNE + ACTIVITY_DOMAINS.MAROQUINERIE.URL;

export const URLS_MAROQUINERIE = {
  ENTREPRISE_INFORMATION: MAROQUINERIE_URL_PREFIX + '/informations',
  ENTREPRISE_SALARIES: MAROQUINERIE_URL_PREFIX + '/salaries',
  IDENTITE: MAROQUINERIE_URL_PREFIX + '/representant',
  DISPOSITIF_ACTUEL: MAROQUINERIE_URL_PREFIX + '/dispositif-actuel',
  DISPOSITIF_ABONDEMENT: MAROQUINERIE_URL_PREFIX + '/abondement',
  KYC: MAROQUINERIE_URL_PREFIX + '/kyc',
  INTERESSEMENT: MAROQUINERIE_URL_PREFIX + '/interessement',
  PARTICIPATION: MAROQUINERIE_URL_PREFIX + '/participation',
  TARIFICATION: MAROQUINERIE_URL_PREFIX + '/tarification',
  FINALISATION: MAROQUINERIE_URL_PREFIX + '/finalisation'
};

export const ETAPES_DOSSIER_MAROQUINERIE = {
  ENTREPRISE_INFORMATION: 0,
  ENTREPRISE_COMPLEMENT: 1,
  IDENTITE: 2,
  DISPOSITIF_ACTUEL: 3,
  DISPOSITIF_ABONDEMENT: 4,
  INTERESSEMENT: 6,
  PARTICIPATION: 7,
  KYC: 8,
  TARIFICATION: 9,
  EN_ATTENTE_DE_SIGNATURE: 9,
  INFORMATIONS: 10,
  TRANSMIS: 11
};

export const SECTIONS_MAROQUINERIE = {
  ENTREPRISE: {
    URL: URLS_MAROQUINERIE.ENTREPRISE_INFORMATION,
    INDEX: 0,
    ETAPE: ETAPES_DOSSIER_MAROQUINERIE.ENTREPRISE_INFORMATION
  },
  BESOIN: {
    URL: URLS_MAROQUINERIE.DISPOSITIF_ACTUEL,
    INDEX: 1,
    ETAPE: ETAPES_DOSSIER_MAROQUINERIE.DISPOSITIF_ACTUEL
  },
  ADHESION: {
    URL: URLS_MAROQUINERIE.TARIFICATION,
    INDEX: 2,
    ETAPE: ETAPES_DOSSIER_MAROQUINERIE.TARIFICATION
  }
};
import "./polyfill";
import "./styles/_styles.scss";
import "react-app-polyfill/ie9";
import App from "./components/yes/app/App";
import React from "react";
import store, { history, persistor } from "./store";
import { ConnectedRouter } from "react-router-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { render } from "react-dom";
import { unregister } from "./registerServiceWorker";

unregister();

const target = document.querySelector("#root");

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {" "}
      {/* synchronise l'affichage de l'app au chargement de redux-persist */}
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  target
);

/* Built in imports */
import React from 'react';

/* Internal imports */
import Checkbox from '../Checkbox';
import FormControlLabel from '../form/FormControlLabel';

const renderCheckbox = ( { label, input, ...other } ) =>{
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...input}
          {...other}
        />
      }
      label={label}
    />
  )
}

export default renderCheckbox;
/* Built in imports */
import React from 'react';

/* Third party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

/* Internal imports */
import { YesDropdown } from '../../../../../form/Dropdown';
import { TransHtmlText } from '../../../../../yes/utils/TransHtmlText';

const Formule = ({ formules }) => (
  <div className="cell medium-12">
    <h3>
      <TransHtmlText i18nKey="participation.participationIntegre.formule" />
    </h3>
    <Field name="optionParticipation.formule.type" component={YesDropdown} item={formules} />
  </div>
);

Formule.propTypes = {
  formules: PropTypes.array.isRequired
};

export default Formule;

/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import AssuranceFinalisationAttachmentsForm from '../../../../components/US/assurance/forms/finalisation/FinalisationAttachmentsForm';
import { DOCUMENTS, TYPE_DOCUMENT } from '../../../../common/Configuration';
import { URLS_ASSURANCE } from '../../../../utils/constantes/ROUTES/ASSURANCE';
import { annulerLiasseSouscription } from '../../../../services/LiasseService';
import { getDocumentsAUploader } from '../../../../services/TenantService';
import {
  removeLiasse,
  setFichesPero,
  setInformations,
  setInformationsDossier,
  setLoader,
  setLoaderMessage,
  setRetourRoute
} from '../../../../modules/tunnel/redux/tunnelActions';

class AssuranceFinalisationPage extends Component {
  constructor(props) {
    super(props);
    const isDossierSigne =
      (this.props.tunnel.liasse
        && this.props.tunnel.liasse.statut === 'SIGNEE')
      || (window.location.search.includes('?event=')
        && window.location.search.split('?event=')[1] === 'signing_complete');

    // On modifie la route lorsque l'utilisateur clique sur le bouton Retour
    this.props.setRetourRoute(URLS_ASSURANCE.TARIFICATION.substring(1));
    this.state = {
      redirectToTarification: false,
      redirectToVerificationWLF: false,
      isDossierSigne: isDossierSigne
    };

    this.redirectToVerificationWLF = this.redirectToVerificationWLF.bind(this);
  }

  componentDidMount() {
    this.state.isDossierSigne && this.props.setInformations(3);
  }

  onBack = () => {
    return new Promise((resolve, reject) => {
      if (
        window.confirm(
          'Toute modification du formulaire invalidera le présent contrat ! '
          + 'Êtes-vous certain de vouloir retourner à l’étape précédente ?'
        )
      ) {
        if (this.props.tunnel.liasse) {
          annulerLiasseSouscription(this.props.tunnel.liasse.uid)
            .then(() => {
              this.props.removeLiasse();
              resolve();
            })
            .catch((/*error*/) => {
              reject("Erreur lors de l'annulation de la liasse.");
              this.setState({
                redirectToTarification: true
              });
            });
        } else {
          resolve();
        }
      } else {
        reject("Popup de retour déclinée.");
      }
    });
  };

  redirectToVerificationWLF() {
    this.setState({ redirectToVerificationWLF: true });
  }

  render() {
    if (this.state.redirectToTarification) {
      return <Redirect to={URLS_ASSURANCE.TARIFICATION} />;
    }

    if (this.state.redirectToVerificationWLF) {
      return <Redirect to={URLS_ASSURANCE.VERIFICATION_WLF} />;
    }

    return (
      <div className="tunnel-infos-container">
        <AssuranceFinalisationAttachmentsForm redirectToVerificationWLF={this.redirectToVerificationWLF} />
      </div>
    );
  }
}

AssuranceFinalisationPage.propTypes = {
  removeLiasse: PropTypes.func,
  setInformations: PropTypes.func.isRequired,
  setRetourRoute: PropTypes.func.isRequired,
  tunnel: PropTypes.object
}

// REDUX + REDUX FORM
const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setInformations: (information) => dispatch(setInformations(information)),
  removeLiasse: () => dispatch(removeLiasse()),
  setLoader: (loader) => dispatch(setLoader(loader))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true }
)(AssuranceFinalisationPage);

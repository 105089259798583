/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import { Button } from 'react-foundation';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import SiretForm from '../../../../components/US/common/forms/SiretForm';
import { CITRA_ERROR_RESPONSE_CODES } from '../../../../utils/constantes/US/SIRET_FORM';
import { URLS_EPARGNE } from '../../../../utils/constantes/ROUTES/EPARGNE';
import { rechercherEntrepriseDansSirene } from '../../../../services/EntrepriseService';
import { setInformationsSirene, setRetourRoute, setServerErrorForm } from '../../../../modules/tunnel/redux/tunnelActions';
import { stringSansEspaces } from '../../../../common/FunctionsUtils';

class EpargneSiretPage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.props.setRetourRoute('none');
    this.state = {
      redirect: false,
      forceSiret: false
    };
  }

  submit(values) {
    this.setState({
      errorMsg: undefined
    });

    const siret = stringSansEspaces(values.siret);
    this.props.setErrorServeurForm(false);

    rechercherEntrepriseDansSirene(siret)
      .then((entreprise) => {
        // on sauvegarde dans Redux les informations remontés depuis SIRENE
        this.props.setInformationsSirene(entreprise);

        // Redirection vers la page de l'étape une du tunnel
        this.setState({
          redirect: true
        });
      })
      .catch((error) => {
        if (error && error.message) {
          this.setState({
            errorMsg: error.message,
            forceSiret: error.code === CITRA_ERROR_RESPONSE_CODES.SIRET.NOT_FOUND
          });
        }
      });
  }

  overrideSiret = () => {
    this.props.setInformationsSirene({
      siret: stringSansEspaces(this.props.siret),
      raisonSociale: '',
      dateCreation: '01/01/1970',
      formeJuridique: 0,
      siren: '000000000',
      codeNaf: '0000A',
      adresse: {
        rue: '',
        codePostal: '00000',
        ville: '',
        pays: null
      }
    });
    this.setState({
      redirect: true
    });
  };

  render() {
    const { redirect, errorMsg } = this.state;

    if (redirect) {
      return <Redirect to={URLS_EPARGNE.ENTREPRISE_INFORMATION} />;
    }

    return (
      <div className={this.props.tunnel.errorForm ? 'errorServerFormHide' : ''}>
        <SiretForm onSubmit={this.submit} customError={errorMsg} />
        {this.state.forceSiret && (
          <div className="force-siret">
            <p>Ce SIRET n'a pas été trouvé, voulez-vous l'utiliser malgré tout ?</p>
            <Button onClick={this.overrideSiret}>Valider</Button>
          </div>
        )}
      </div>
    );
  }
}

// REDUX + REDUX FORM
const mapStateToProps = (state) => ({
  tunnel: state.tunnel,
  siret: state.form?.siretForm?.values?.siret
});

const mapDispatchToProps = (dispatch) => ({
  setInformationsSirene: (sireneInfos) => dispatch(setInformationsSirene(sireneInfos)),
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setErrorServeurForm: (errorForm) => dispatch(setServerErrorForm(errorForm))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EpargneSiretPage);

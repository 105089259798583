import React, { Component } from "react";
import { connect } from "react-redux";
import InformationsForm from "./InformationsForm";
import ChoixObjectif from "./ChoixObjectif";
import Participation from "./Participation";
import Restitution from "./restitution/Restitution";
import ResultatGlobalMailForm from "./restitution/ResultatGlobalMailForm";
import ResultatFinal from "./restitution/ResultatFinal";
import Conseiller from "./restitution/Conseiller";
import {
  setMasseSalarialeAndEffectif,
  choisirObjectifConstituerCapitale,
  choisirObjectifMotiver,
  choisirObjectifFideliser,
  setFaireBenefice,
  setParticipation,
  voirInformationPlafondInteressementAtteint,
  OBJECTIF_FIDELISER,
  OBJECTIF_MOTIVER,
  OBJECTIF_CONSTITUER_CAPITALE
} from "../../../modules/simulateur/redux/simulateurActions";
import {
  nombreSansEspaces,
  nombreAvecEspaces,
  construireChaineParametresFormulaire
} from "../../../common/FunctionsUtils";
import { change } from "redux-form";
import { listeObjectifs } from "../../../services/ObjectifService";
import { YesButton } from "../../../components/form/Button";

const formObjectifName = "choixObjectifs";

class SimulateurPage extends Component {
  constructor(props) {
    super(props);

    this.submitEffectifsEtMasseSalariale = this.submitEffectifsEtMasseSalariale.bind(
      this
    );
    this.submitObjectif = this.submitObjectif.bind(this);
    this.submitParticipation = this.submitParticipation.bind(this);
    this.submitRestitutionMail = this.submitRestitutionMail.bind(this);
    this.delclencherMoteurDeCalcul = this.delclencherMoteurDeCalcul.bind(this);
    this.afficherResultatFinal = this.afficherResultatFinal.bind(this);

    this.state = {
      voirObjectif: false,
      voirParticipation: false,
      voirRestitution: false,
      benefice: false,
      montantConnu: false,
      montantParticipationConnu: 0,
      montantParticipation: null,
      mailEnvoye: false,
      voirResultatFinal: false
    };

    this.refObjectif = React.createRef();
    this.refParticipation = React.createRef();
    this.refRestitution = React.createRef();
  }

  componentDidMount() {
    if (localStorage.getItem("mailSimulationEnvoye") === "true") {
      this.setState({
        mailEnvoye: true,
        voirResultatFinal: false
      });
    }
  }

  submitEffectifsEtMasseSalariale(values) {
    let effectif = nombreSansEspaces(values.effectif);
    let masseSalariale = nombreSansEspaces(values.masseSalariale);

    this.props.setMasseSalarialeAndEffectif(masseSalariale, effectif);

    if (
      effectif >= 10 &&
      this.props.simulateur.objectif === OBJECTIF_CONSTITUER_CAPITALE
    ) {
      this.props.changeFieldValueObjectif("objectif", OBJECTIF_MOTIVER);
      this.props.choisirObjectifMotiver();
    }

    if (effectif >= 50) {
      this.setState(
        {
          voirParticipation: true
        },
        () => {
          // if (this.props.participation) this.submitParticipation(this.props.participation.values)
        }
      );
      this.scroller(this.refParticipation);
    } else {
      this.setState(
        {
          voirObjectif: true,
          voirParticipation: false,
          montantParticipation: null
        },
        () => this.delclencherMoteurDeCalcul(this.props.simulateur.objectif)
      );
      this.scroller(this.refObjectif);
    }
  }

  submitObjectif(values) {
    // this.delclencherMoteurDeCalcul(values.objectif)
    this.setState({
      voirRestitution: true
    });
    this.scroller(this.refRestitution);
  }

  submitParticipation(values) {
    if (values) {
      if (values.benefice === "oui") {
        this.props.setFaireBenefice(true);
        if (values.connaitreMontant === "non") {
          this.setState(
            {
              montantParticipation: Math.floor(
                0.05 * this.props.simulateur.masseSalariale
              )
            },
            () => this.delclencherMoteurDeCalcul(this.props.simulateur.objectif)
          );
        } else {
          let montantPart = 0;
          if (values.montantParticipation) {
            montantPart = nombreSansEspaces(values.montantParticipation);
          }

          this.setState(
            {
              montantParticipation: montantPart
            },
            () => this.delclencherMoteurDeCalcul(this.props.simulateur.objectif)
          );
        }
      } else {
        this.props.setFaireBenefice(false);
        this.setState(
          {
            montantParticipation: 0
          },
          () => this.delclencherMoteurDeCalcul(this.props.simulateur.objectif)
        );
      }
      this.setState({
        voirObjectif: true
      });
      this.scroller(this.refObjectif);
    }
  }

  submitRestitutionMail(values) {
    const { simulateur } = this.props;

    let formBody = construireChaineParametresFormulaire({
      EMAIL: values.adresseEmail,
      b_00df9bda0c07d4c06f76586d9_f8a8285559: "",
      MONTPEE: nombreAvecEspaces(
        simulateur.epargneSalariale.dispositif.abondementPEE
      ),
      MONTPERCO: nombreAvecEspaces(
        simulateur.epargneSalariale.dispositif.abondementPERCO
      ),
      MONTINTER: nombreAvecEspaces(
        simulateur.epargneSalariale.dispositif.interessement *
          simulateur.effectif
      ),
      MONTPART: nombreAvecEspaces(
        simulateur.epargneSalariale.dispositif.participation *
          simulateur.effectif
      ),
      ECOGLOB: nombreAvecEspaces(
        (simulateur.primeClassique.coutEntreprise.coutTotalEntreprise -
          simulateur.epargneSalariale.coutEntreprise.coutTotalEntreprise) *
          simulateur.effectif
      ),
      COUTOTALES: nombreAvecEspaces(
        simulateur.epargneSalariale.coutEntreprise.coutTotalEntreprise *
          simulateur.effectif
      ),
      COUTOTALPC: nombreAvecEspaces(
        simulateur.primeClassique.coutEntreprise.coutTotalEntreprise *
          simulateur.effectif
      ),
      EFFECTIF: nombreAvecEspaces(simulateur.effectif),
      MASSAL: nombreAvecEspaces(simulateur.masseSalariale),
      OBJECT: listeObjectifs()
        .filter(o => o.type === simulateur.objectif)[0]
        .titre.toLowerCase(),
      BUDGLOB: nombreAvecEspaces(
        simulateur.epargneSalariale.dispositif.enveloppeIndividuelle *
          simulateur.effectif
      )
    });

    fetch(
      "https://yomoni.us11.list-manage.com/subscribe/post?u=00df9bda0c07d4c06f76586d9&amp;id=f8a8285559",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formBody
      }
    );
    this.setState({
      mailEnvoye: true,
      voirResultatFinal: true
    });
    localStorage.setItem("mailSimulationEnvoye", true);
  }

  scroller(ref) {
    setTimeout(() => {
      if (ref.current) {
        window.scroll({
          top: ref.current.offsetTop - 15,
          left: 0,
          behavior: "smooth"
        });
      }
    }, 100);
  }

  delclencherMoteurDeCalcul(objectif) {
    this.props.voirInformationPlafondInteressementAtteint(false);
    switch (objectif) {
      case OBJECTIF_MOTIVER:
        this.props.choisirObjectifMotiver(
          Math.floor(this.state.montantParticipation)
        );
        break;

      case OBJECTIF_FIDELISER:
        this.props.choisirObjectifFideliser(
          Math.floor(this.state.montantParticipation)
        );
        break;

      case OBJECTIF_CONSTITUER_CAPITALE:
        this.props.choisirObjectifConstituerCapitale(
          Math.floor(this.state.montantParticipation)
        );
        break;
      default:
        break;
    }
  }

  afficherResultatFinal() {
    this.setState({
      voirResultatFinal: true
    });
  }

  render() {
    const {
      voirObjectif,
      voirParticipation,
      voirRestitution,
      mailEnvoye,
      voirResultatFinal
    } = this.state;

    return (
      <div className="simulateur-container">
        <div className="item-simulateur-container effectif-container">
          <InformationsForm onSubmit={this.submitEffectifsEtMasseSalariale} />
        </div>

        {voirParticipation && (
          <div
            className="item-simulateur-container participation-container"
            ref={this.refParticipation}
          >
            <Participation onSubmit={this.submitParticipation} />
          </div>
        )}

        {voirObjectif && (
          <div
            className="item-simulateur-container objectif-container"
            ref={this.refObjectif}
          >
            <ChoixObjectif
              moteurCalcul={this.delclencherMoteurDeCalcul.bind(this)}
              onSubmit={this.submitObjectif}
            />
          </div>
        )}

        {voirRestitution && (
          <div
            className="item-simulateur-container restitution-container"
            ref={this.refRestitution}
          >
            <Restitution />

            {!voirResultatFinal && mailEnvoye && (
              <div className="item-simulateur-container button-resultat-container">
                <YesButton
                  text="Voir le résultat global"
                  onClick={this.afficherResultatFinal}
                />
              </div>
            )}

            {mailEnvoye && voirResultatFinal && (
              <div>
                <ResultatFinal />
              </div>
            )}
          </div>
        )}

        {voirRestitution && !voirResultatFinal && !mailEnvoye && (
          <div className="item-simulateur-container email-container">
            <ResultatGlobalMailForm onSubmit={this.submitRestitutionMail} />
          </div>
        )}

        {voirRestitution && voirResultatFinal && (
          <div className="item-simulateur-container email-container">
            <Conseiller />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  simulateur: state.simulateur,
  participation: state.form.participation,
  objectif: state.form.choixObjectifs
});

const mapDispatchToProps = dispatch => ({
  setMasseSalarialeAndEffectif: (masseSalariale, effectif) =>
    dispatch(setMasseSalarialeAndEffectif(masseSalariale, effectif)),
  choisirObjectifMotiver: participation =>
    dispatch(choisirObjectifMotiver(participation)),
  choisirObjectifFideliser: participation =>
    dispatch(choisirObjectifFideliser(participation)),
  choisirObjectifConstituerCapitale: participation =>
    dispatch(choisirObjectifConstituerCapitale(participation)),
  setParticipation: participation => dispatch(setParticipation(participation)),
  setFaireBenefice: faireBenefice => dispatch(setFaireBenefice(faireBenefice)),
  voirInformationPlafondInteressementAtteint: voir =>
    dispatch(voirInformationPlafondInteressementAtteint(voir)),
  changeFieldValueObjectif: function(field, value) {
    dispatch(change(formObjectifName, field, value));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(SimulateurPage);

import React from "react";
import config from "../../../common/StaticSiteConfig";
import provideScrollPosition from "react-provide-scroll-position";

import MyAccountButton from "./MyAccountButton";

const menuItems = config.navbar.leftItems;
const { tunnelUrl } = config;

const desktopTopnavClass = "desktop-topnav__topbar-container show-for-large";
const mobileTopnavClass = "mobile-topnav__topbar-stickymenu hide-for-large";

const mobileTopnavSlidingMenuClass =
  "mobile-topnav__slidingmenu hide-for-large";
const mobileTopnavHamburgerClass = "mobile-topnav__hamburger hide-for-large";

const mobileTopnavMaskClass = "mobile-topnav__mask hide-for-large";

// Genere le className des liens du topbar
const getMenuLinkClass = url =>
  url === window.location.pathname
    ? "topbar-menu__link topbar-menu__link--currentpage"
    : "topbar-menu__link";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileTopnavClass,
      desktopTopnavClass,
      mobileTopnavSlidingMenuClass,
      mobileTopnavHamburgerClass,
      mobileTopnavMaskClass
    };
  }

  handleSlidingMenuClick = () => {
    const switchedMenu =
      this.state.mobileTopnavSlidingMenuClass === mobileTopnavSlidingMenuClass
        ? `${mobileTopnavSlidingMenuClass} mobile-topnav__slidingmenu--is-active`
        : mobileTopnavSlidingMenuClass;

    const switchedBurger =
      this.state.mobileTopnavHamburgerClass === mobileTopnavHamburgerClass
        ? `${mobileTopnavHamburgerClass} mobile-topnav__hamburger--is-active`
        : mobileTopnavHamburgerClass;

    const switchedMask =
      this.state.mobileTopnavMaskClass === mobileTopnavMaskClass
        ? `${mobileTopnavMaskClass} mobile-topnav__mask--is-active hide-for-large`
        : mobileTopnavMaskClass;

    this.setState({
      mobileTopnavSlidingMenuClass: switchedMenu,
      mobileTopnavHamburgerClass: switchedBurger,
      mobileTopnavMaskClass: switchedMask
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.scrollTop !== this.props.scrollTop) {
      const { scrollTop } = nextProps;
      // Mise à jour du style de la barre de menu au scroll
      if (scrollTop > 45) {
        this.setState({
          desktopTopnavClass: `${desktopTopnavClass} desktop-topnav__topbar-container--scrolled`,
          mobileTopnavClass: `${mobileTopnavClass} mobile-topnav__topbar-stickymenu--scrolled`
        });
      } else {
        this.setState({
          desktopTopnavClass,
          mobileTopnavClass
        });
      }
    }
  }

  render() {
    const {
      desktopTopnavClass,
      mobileTopnavClass,
      mobileTopnavSlidingMenuClass,
      mobileTopnavHamburgerClass,
      mobileTopnavMaskClass
    } = this.state;
    return (
      <header>
        <div className={desktopTopnavClass}>
          <div className="grid-container">
            <nav className="top-bar">
              <div>
                <ul className="menu">
                  <li>
                    <a
                      href={process.env.REACT_APP_STATIC_URL}
                      className="topbar-menu__logolink"
                    >
                      <img
                        width="137"
                        src="/assets/img/logos/logo-blason-green.svg"
                        alt="Yomoni"
                        className="topbar-menu__logo"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="top-bar-left">
                <ul className="menu">
                  {menuItems.map((item, key) => (
                    <li key={key}>
                      <a className={getMenuLinkClass(item.url)} href={item.url}>
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="top-bar-right">
                <ul className="menu">
                  <li>
                    <MyAccountButton />
                  </li>
                  <li>
                    <a
                      href={tunnelUrl}
                      className="button secondary large topbar-menu__button"
                      data-ga-event="topnav_cta"
                    >
                      {"s'inscrire"}
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        {
          // <!-- Pousse le haut de la page vers le bas pour compenser le position:fixed de la nav -->
        }
        <div className="desktop-topnav__inlay show-for-large" />

        {
          // <!-- === Barre de navigation sur mobile === -->
          // <!-- Bandeau fixe en haut de l'écran -->
        }
        <div className={mobileTopnavClass}>
          <nav className="top-bar">
            <a href={"/simulateur"}>
              <img
                width="137"
                src="/assets/img/logos/logo-blason-green.svg"
                alt="Yomoni"
                className="topbar-menu__logo"
              />
            </a>
            <button
              className={mobileTopnavHamburgerClass}
              onClick={this.handleSlidingMenuClick}
            >
              <span>Menu</span>
            </button>
          </nav>
        </div>

        {
          // <!-- Masque pour assombrir la page quand le menu est ouvert -->
        }
        <div
          className={mobileTopnavMaskClass}
          onClick={this.handleSlidingMenuClick}
        />

        {
          //<!-- Bandeau glissant apparaissant à droite de l'écran -->
        }
        <div className={mobileTopnavSlidingMenuClass}>
          <nav>
            <ul>
              {menuItems.map((item, key) => (
                <li key={key}>
                  <a href={item.url}>{item.title}</a>
                </li>
              ))}
              <li>
                <a
                  href="https://www.epargne-entreprise.federal-finance.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Espace sécurisé Entreprise
                </a>
              </li>
              <li>
                <a
                  href="https://www.epargne-salariale.federal-finance.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Espace sécurisé Salarié
                </a>
              </li>
            </ul>

            <div className="mobile-topnav__bottom-actions">
              <a
                href={tunnelUrl}
                className="button secondary small back background-bleugris"
                data-ga-event="topnav_cta"
              >
                {"S'inscrire"}
              </a>
            </div>
          </nav>
        </div>

        {
          //<!-- Pousse le haut de la page vers le bas pour compenser le position:fixed de la nav -->
        }
      </header>
    );
  }
}

export default provideScrollPosition(Header);

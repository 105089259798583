import React, { Component } from "react";
import CreationCompteForm from "./CreationCompteForm";
import { connect } from "react-redux";
import { creerCompteUtilisateur } from "../../../../services/CompteService";
import { setToken } from "../../../../services/ResourcesService";
import { Redirect } from "react-router-dom";
import PageSlider from "react-page-slider";
import {
  setInformationsUtilisateur,
  setLoader,
  setEmailDejaExistant
} from "../../../../modules/tunnel/redux/tunnelActions";
import { ROUTE_SIRET, FLAG_REDIRECT } from "../../../../common/Configuration";
import { UTILISATEUR_EXISTE_DEJA } from "../../../../common/ApiError";
import { getUtilisateur } from "../../../../services/UtilisateurService";
import { getTenantUppercase } from "../../../../services/TenantService";

class CreationComptePage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      redirect: false,
      sliderActivated: false
    };
    this._handleClickMobile = this._handleClickMobile.bind(this);
    this.props.handlerIllustration("illu-creation-compte");
  }

  submit(values) {
    let valuesWithTenantId = {
      ...values,
      motDePasse: values.motDePasse.trim(),
      tenant: getTenantUppercase()
    };
    this.setState({
      errorMsg: ""
    });

    this.props.setLoader(true);

    creerCompteUtilisateur({ values: valuesWithTenantId })
      .then(token => {
        // Ajout du JWT dans le local storage
        setToken(token.jwt);
        // On va ensuite récupérer les informations de l'utilisateur pour les insérer dans le state Redux
        getUtilisateur(token.dirigeantUid).then(utilisateur => {
          this.props.setInformationsUtilisateur(utilisateur);
          this.setState({
            redirect: true
          });
        });
      })
      .catch(error => {
        if (error && error.message) {
          if (error.code === UTILISATEUR_EXISTE_DEJA) {
            this.props.setEmailDejaExistant(values.adresseEmail);
          }

          this.setState({
            errorCode: error.code,
            errorMsg: error.message
          });
        }
      })
      .then(() => this.props.setLoader(false));
  }

  _handleClickMobile(e) {
    e.preventDefault();
    this.setState({ sliderActivated: true });
  }

  render() {
    const { redirect, errorMsg, errorCode } = this.state;

    if (redirect) {
      return <Redirect to={ROUTE_SIRET + FLAG_REDIRECT} />;
    }

    return (
      <div className="grid-x grid-margin-x">
        <div className="cell medium-12 formCreation">
          <h2>Bienvenue !</h2>
          <h3 className="yes-gras">
            Nous sommes là pour vous guider dans l’ouverture de votre plan
            d’épargne salariale.
          </h3>

          <p>Tout d’abord, créons votre profil utilisateur.</p>
          <p>
            Ces informations vont nous permettre de sauvegarder vos données.
            Vous pourrez ainsi reprendre votre parcours de souscription quand
            vous le souhaitez.
          </p>

          {/* VERSION DESKTOP / TABLETTE */}
          <div className="show-for-medium">
            <CreationCompteForm
              onSubmit={this.submit}
              customError={errorMsg}
              errorCode={errorCode}
            />
          </div>

          {/* VERSION MOBILE */}
          <div className="show-for-small-only yes-arrow-mobile">
            <img
              onClick={this._handleClickMobile}
              width="10%"
              src="/assets/img/icons/yes/arrow.svg"
              alt="epargne salariale mobile"
            />
          </div>

          <div className="show-for-small-only pageSlider">
            <PageSlider show={this.state.sliderActivated} slideFrom={"right"}>
              <div>
                <div className="yes-header-mobile">
                  <img
                    width="10%"
                    src="/assets/img/icons/yes/blason.svg"
                    alt="epargne salariale mobile"
                  />
                </div>

                <CreationCompteForm
                  onSubmit={this.submit}
                  customError={errorMsg}
                />
              </div>
            </PageSlider>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  setInformationsUtilisateur: utilisateur =>
    dispatch(setInformationsUtilisateur(utilisateur)),
  setLoader: actif => dispatch(setLoader(actif)),
  setEmailDejaExistant: email => dispatch(setEmailDejaExistant(email))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreationComptePage);

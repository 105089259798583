/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import { Translation, Trans } from 'react-i18next';
import { connect } from 'react-redux';

/* Internal imports */
import { CONTACT_EMAIL, CONTACT_EMAIL_ASSURANCE } from '../../../common/Configuration';
import { getSvgEnFonctionDuTenant, isEmailDeContactDisplayed } from '../../../services/TenantService';
import { isAssurance } from '../../../services/DossierService';

class YesContact extends Component {
  getPhoneNumber = (phoneNumber) => {
    return '+33' + phoneNumber.substring(1).replace(/\s/gi, '');
  };

  getMail = () => {
    return isAssurance(this.props.tunnel.dossier.type) ? CONTACT_EMAIL_ASSURANCE : CONTACT_EMAIL;
  };

  render() {
    return (
      <div className="infosContactTunnel">
        <Translation>
          {(t) => (
            <a href={'tel:' + this.getPhoneNumber(t('contact.telephone'))}>
              <img src={getSvgEnFonctionDuTenant('/assets/img/icons/', '/phone.svg')} alt="telephone" />
              <span>
                <Trans i18nKey="contact.telephone" />
              </span>
            </a>
          )}
        </Translation>
        {isEmailDeContactDisplayed() && (
          <div>
            <a href={'mailto:' + this.getMail()}>
              <img src={getSvgEnFonctionDuTenant('/assets/img/icons/', '/email.svg')} alt="e-mail" />
              <span>{this.getMail()}</span>
            </a>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({});
YesContact = connect(
  (state) => {
    const tunnel = state.tunnel;
    return { tunnel };
  },
  mapDispatchToProps()
)(YesContact);

export default YesContact;

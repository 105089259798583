/* Built-in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';

/* Internal imports */
import { style } from './textWithTitle.style';

const TextWithTitle = ({ title, content, customStyle }) => {
  const twtStyle = { ...style, ...customStyle };
  return (
    <p className="review-field" style={customStyle}>
      <span className="review-title" style={twtStyle.title}>{`${title} : `}</span>
      <span className="review-value" style={twtStyle.value}>
        {content}
      </span>
    </p>
  );
};

TextWithTitle.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  customStyle: PropTypes.object
};

export default TextWithTitle;

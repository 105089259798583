/* Built in imports */
import React from 'react';

/* Third-party imports */
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

/* Internal imports */
import SelectCountries from '../../../../form/SelectCountries';
import YesInput from '../../../../form/Input';
import { COUNTRY } from '../../../../../common/constants';
import { QUALITE_ACTIONNNAIRE, QUALITES } from '../../../../../utils/constantes/US/KYCFORM';
import { YesCheckboxV2 } from '../../../../form/CheckboxV2';
import { YesChoix } from '../../../../form/Choix';
import { YesDropdown } from '../../../../form/Dropdown';

const getActionnaireIndexString = (index) => `${index + 1}`;

const Stockholders = ({ fields, meta: { error, submitFailed }, stockholders, entrepriseAddress, change }) => {

  const setAddressPersistedValues = (isChecked, stockholder) => {
    change(`${stockholder}.adressePostaleRue`, isChecked ? null : entrepriseAddress.rue);
    change(`${stockholder}.adressePostaleCode`, isChecked ? null : entrepriseAddress.codePostal);
    change(`${stockholder}.adressePostaleVille`, isChecked ? null : entrepriseAddress.ville);
  }

  const setPersonneMorale = (isChecked, stockholder) => {
    if(isChecked) {
      change(`${stockholder}.qualite`, QUALITE_ACTIONNNAIRE.PRINCIPAL);
      change(`${stockholder}.prenom`, null);
      change(`${stockholder}.fonctionDirigeant`, null);
      change(`${stockholder}.activiteBeneficiaire`, null);
    }
  }

  return (
    <>
      <ul className="grid-container kyc-class">
        {fields &&
        fields.map((stockholder, index) => (
          <li key={`${stockholder}-${index}`}>
            <div className="grid-x grid-margin-x grid-margin-y fields-area">
              <div className="cell text-field medium-10">
                <h4>Actionnaire N° {getActionnaireIndexString(index)}</h4>
              </div>
              <div className="cell text-field medium-2">
                <button type="button" onClick={() => fields.remove(index)} className="suppression-actionnaire">
                  Supprimer
                  <img src="/assets/img/icons/ges/red-cross.svg" alt="information tooltip" />
                </button>
              </div>
            </div>
            <div className="grid-x grid-margin-x grid-margin-y fields-area">
              <div className="cell text-field medium-4">
                <h4>L'actionnaire est une personne morale :</h4>
              </div>
              <div className="tunnelRadios cell medium-2">
                <div className="tunnelRadio">
                  <Field
                    name={`${stockholder}.beneficiaireIsPersonneMorale`}
                    component={YesChoix}
                    type="radio"
                    value={'oui'}
                    label="Oui"
                    id={`${stockholder}.beneficiaireIsPersonneMorale-oui`}
                    onChange={(event) => setPersonneMorale(event.target.checked, stockholder)}
                  />
                </div>
                <div className="tunnelRadio">
                  <Field
                    name={`${stockholder}.beneficiaireIsPersonneMorale`}
                    component={YesChoix}
                    type="radio"
                    value={'non'}
                    label="Non"
                    id={`${stockholder}.beneficiaireIsPersonneMorale-non`}
                  />
                </div>
              </div>
              {stockholders[index].beneficiaireIsPersonneMorale !== 'oui' && (
                <div className="cell text-field medium-6 no-margin-top-dropdown text-field">
                  <Field name={`${stockholder}.qualite`} type="text" component={YesDropdown} item={QUALITES} labeltext="Qualité(s)" />
                </div>
              )}
              <div className="cell text-field medium-6 text-field">
                <Field
                  name={`${stockholder}.nom`}
                  type="text"
                  component={YesInput}
                  labeltext={
                    stockholders[index].beneficiaireIsPersonneMorale !== 'oui'
                      ? 'Nom'
                      : 'Raison Sociale'
                  }
                />
              </div>
              {stockholders[index].beneficiaireIsPersonneMorale !== 'oui' && (
                <div className="cell text-field medium-6 text-field">
                  <Field name={`${stockholder}.prenom`} type="text" component={YesInput} labeltext="Prénom" />
                </div>
              )}
              {stockholders[index].qualite === 'D' && (
                <div className="cell text-field medium-4 text-field">
                  <Field
                    name={`${stockholder}.fonctionDirigeant`}
                    type="text"
                    component={YesInput}
                    labeltext="Fonction dans l'entreprise"
                  />
                </div>
              )}
              {(stockholders[index].qualite === QUALITE_ACTIONNNAIRE.BENEFICIAIRE ||
                stockholders[index].qualite === QUALITE_ACTIONNNAIRE.PROCURATION) && (
                <div className="cell text-field medium-6 text-field">
                  <Field
                    name={`${stockholder}.activiteBeneficiaire`}
                    type="text"
                    component={YesInput}
                    labeltext="Profession pour le bénéficiaire"
                  />
                </div>
              )}
            </div>
            <div className="yes-label grid-x grid-margin-x grid-margin-y fields-area">
              Adresse permanente de l'actionnaire N° {getActionnaireIndexString(index)} :
            </div>
            <div className="grid-x grid-margin-x grid-margin-y fields-area">
              <div className="cell text-field medium-4 text-field">
                <Field name={`${stockholder}.adressePermanenteRue`} type="text" component={YesInput} labeltext="Numéro et rue" />
              </div>
              <div className="cell text-field medium-4 text-field">
                <Field name={`${stockholder}.adressePermanenteCode`} type="text" component={YesInput} labeltext="code postal" />
              </div>
              <div className="cell text-field medium-4 text-field">
                <Field name={`${stockholder}.adressePermanenteVille`} type="text" component={YesInput} labeltext="Ville" />
              </div>
            </div>
            <div className="grid-x grid-margin-x grid-margin-y fields-area">
              <div className="yes-label cell text-field medium-6">Adresse postale de l'entreprise :</div>
              <div className="cel medium-6 text-field">
                <Field
                  type="checkbox"
                  component={YesCheckboxV2}
                  id={`${stockholder}.isSameAddress`}
                  name={`${stockholder}.isSameAddress`}
                  onChange={(event) => setAddressPersistedValues(event.target.checked, stockholder)}
                  labeltext={`Identique à l'adresse permanente de l'actionnaire N° ${getActionnaireIndexString(index)}`}
                />
              </div>
            </div>
            {!stockholders[index].isSameAddress && (
              <div className="grid-x grid-margin-x grid-margin-y fields-area">
                <div className="cell text-field medium-4 text-field">
                  <Field name={`${stockholder}.adressePostaleRue`} type="text" component={YesInput} labeltext="Numéro et rue" />
                </div>
                <div className="cell text-field medium-4 text-field">
                  <Field name={`${stockholder}.adressePostaleCode`} type="text" component={YesInput} labeltext="code postal" />
                </div>
                <div className="cell text-field medium-4 text-field">
                  <Field name={`${stockholder}.adressePostaleVille`} type="text" component={YesInput} labeltext="Ville" />
                </div>
              </div>
            )}
            <div className="grid-x grid-margin-x grid-margin-y fields-area">
              <div className="cell text-field medium-6 text-field formCountries">
                <Field name={`${stockholder}.paysFiscal`} type="text" component={SelectCountries} labeltext="Pays Fiscal" />
              </div>
              <div className="cell text-field medium-6 text-field">
                <Field
                  name={`${stockholder}.parts`}
                  type="text"
                  component={YesInput}
                  labeltext="Pourcentage de détention de parts"
                  inputMode="numeric"
                  numberfield={'true'}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
      {fields && fields.length < 6 && (
        <button
          type="button"
          onClick={() =>
            fields.push({
              paysFiscal: JSON.stringify(COUNTRY.FRANCE),
              isSameAddress: true,
              qualite: QUALITE_ACTIONNNAIRE.PRINCIPAL,
              beneficiaireIsPersonneMorale: 'non'
            })
          }
          className="ajout-actionnaire"
        >
          <img src="/assets/img/icons/ges/red-add.svg" alt="information tooltip"/>
          Dirigeants / bénéficiaires effectifs / procuration / principaux actionnaires <sup>(1) (2) (3)</sup>
        </button>
      )}
      {submitFailed && error && <span>{error}</span>}
    </>
  );
}

const selectorForm = formValueSelector('annexeKyc');

const mapStateToProps = (state) => ({
  stockholders: selectorForm(state, 'actionnaires'),
  entrepriseAddress: state.tunnel.entreprise.adresse
});

export default connect(mapStateToProps)(reduxForm({ form: 'annexeKyc' })(Stockholders));

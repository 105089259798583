/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

/* Internal imports */
import DispositifAbondementForm from '../../../../components/US/epargne_salariale/forms/dispositifAbondement/DispositifAbondementForm';
import { URLS_EPARGNE } from '../../../../utils/constantes/ROUTES/EPARGNE';
import { TYPE_ABONDEMENT } from '../../../../utils/constantes/US/DISPOSITIFABONDEMENT';
import { isSourcesVersementAbondeesEnabled } from '../../../../services/TenantService';
import { mettreAjourAbondements } from '../../../../services/DossierService';
import { sourcesVersementsPei, sourcesVersementsPercoi } from '../../../../services/SourcesVersementsService';
import {
  setAbondements,
  setAbondementUnilateral,
  setInformationsUtilisateur,
  setRetourRoute
} from '../../../../modules/tunnel/redux/tunnelActions';
import {
  plafondAbondementPEE,
  plafondAbondementPERCO,
  plafondAbondementUnilateral
} from '../../../../modules/simulateur/fonctions';

class EpargneDispositifAbondementPage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    // On modifie la route lorsque l'utilisateur clique le bouton Retour
    this.props.setRetourRoute(URLS_EPARGNE.DISPOSITIF_ACTUEL.substring(1));

    this.state = {
      redirect: false,
      dispositifAnciennete: {
        pei:
          this.props.tunnel.dossier.dispositifs.dispositifPei.abondement &&
          this.props.tunnel.dossier.dispositifs.dispositifPei.abondement.typePalier === TYPE_ABONDEMENT.ANCIENNETE,
        percol:
          this.props.tunnel.dossier.dispositifs.dispositifPercoi.abondement &&
          this.props.tunnel.dossier.dispositifs.dispositifPercoi.abondement.typePalier === TYPE_ABONDEMENT.ANCIENNETE
      },
      planExistant: {
        accordInteressementEnPlace: this.props.tunnel.dossier.planExistant.accordInteressementEnPlace,
        accordParticipationEnPlace: this.props.tunnel.dossier.planExistant.accordParticipationEnPlace
      }
    };
  }

  submit(values) {
    const dispositifs = {
      dispositifPei: {
        ouverture: values.ouverturePei,
        abondement: values.miseEnPlaceAbondementPei
          ? {
              palierAbondements:
                !this.state.dispositifAnciennete.pei && values.palierAbondementsPei.length >= 1
                  ? this.mapPaliersAsNumber(values.palierAbondementsPei)
                  : null,
              palierAncienneteAbondements:
                this.state.dispositifAnciennete.pei && values.anciennete.pei
                  ? this.mapAncienneteAsNumber(values.anciennete.pei)
                  : null,
              plafondPass: values.plafondPei >= plafondAbondementPEE(),
              sourceVersements: this.getSourcesVersementsPei(values.sourcesVersementsPei),
              typePalier: this.state.dispositifAnciennete.pei ? TYPE_ABONDEMENT.ANCIENNETE : TYPE_ABONDEMENT.VERSEMENT
            }
          : null
      },
      dispositifPercoi: {
        ouverture: values.ouverturePercoi,
        abondement: values.miseEnPlaceAbondementPercoi
          ? {
              plafondPass: values.plafondPercoi >= plafondAbondementPERCO(),
              palierAbondements:
                !this.state.dispositifAnciennete.percol && values.palierAbondementsPercoi.length >= 1
                  ? this.mapPaliersAsNumber(values.palierAbondementsPercoi)
                  : null,
              palierAncienneteAbondements:
                this.state.dispositifAnciennete.percol && values.anciennete.percol
                  ? this.mapAncienneteAsNumber(values.anciennete.percol)
                  : null,
              sourceVersements: this.getSourcesVersementsPercoi(values.sourcesVersementsPercoi),
              typePalier: this.state.dispositifAnciennete.percol ? TYPE_ABONDEMENT.ANCIENNETE : TYPE_ABONDEMENT.VERSEMENT
            }
          : null,
        abondementUnilateral: {
          miseEnPlace: values.abnUnilateral,
          plafond: values.plafondAbnUnilateral,
          plafondPass: values.plafondAbnUnilateral >= plafondAbondementUnilateral()
        }
      },
      anciennete: +values.ancienneteMinimum,
      periodicite: {
        option: +values.periodicite.option,
        trimestre: values.periodicite.option === '2' ? +values.periodicite.trimestre : null
      }
    };

    // Si plus de percoi, on delete l'abondement unilateral dans redux
    if (!dispositifs.dispositifPercoi) {
      this.props.setAbondementUnilateral(null);
    }

    mettreAjourAbondements(this.props.tunnel.dossier.uid, { dispositifs })
      .then((dossier) => {
        this.props.setAbondements(dossier.dispositifs);
        this.setState({
          redirect: true
        });
      })
      .catch((error) => {
        if (error && error.message) {
          this.setState({
            errorMsg: error.message
          });
        }
      });
  }

  getSourcesVersementsPei = (sources) => {
    if (isSourcesVersementAbondeesEnabled()) {
      return this.filtrerSources(sources);
    }
    return sourcesVersementsPei.map((source) => source.name);
  };

  getSourcesVersementsPercoi = (sources) => {
    if (isSourcesVersementAbondeesEnabled()) {
      return this.filtrerSources(sources);
    }
    return sourcesVersementsPercoi.map((source) => source.name);
  };

  filtrerSources = (sources) => {
    const sourcesFiltrees = [];
    for (const source in sources) {
      if (sources[source]) {
        sourcesFiltrees.push(source);
      }
    }
    return sourcesFiltrees;
  };

  modeAnciennete = (type, bool) =>
    this.setState({
      dispositifAnciennete: { ...this.state.dispositifAnciennete, [type]: bool }
    });

  mapPaliersAsNumber = (arr) =>
    arr.map((elt) => ({
      taux: +elt.taux,
      versementMin: +elt.versementMin,
      versementMax: +elt.versementMax
    }));

  mapAncienneteAsNumber = (obj) => ({
    taux: +obj.taux,
    plafond1an: +obj.plafond1an,
    plafond3an: +obj.plafond3an,
    plafond5an: +obj.plafond5an,
    plafondMax: +obj.plafondMax
  });

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={URLS_EPARGNE.INTERESSEMENT} />;
    }

    return (
      <div>
        <DispositifAbondementForm
          onSubmit={this.submit}
          modeAnciennete={this.modeAnciennete}
          dispositifAnciennete={this.state.dispositifAnciennete}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setInformationsUtilisateur: (utilisateur) => dispatch(setInformationsUtilisateur(utilisateur)),
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setAbondements: (dispositifs) => dispatch(setAbondements(dispositifs)),
  setAbondementUnilateral: (abondementUnilateral) => dispatch(setAbondementUnilateral(abondementUnilateral))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EpargneDispositifAbondementPage);

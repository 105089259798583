/* built-in imports */
import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';

/* third-party imports */
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArticleIcon from '@mui/icons-material/Article';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

/* Internal imports */
import CircularProgress from '../../../DS/feedback/Progress';
import INPUTS from '../../../../utils/constantes/DS/INPUTS';
import TooltipInfo, { MAXWIDTH } from '../../../../utils/US/components/TooltipInfo';
import Typography from '../../data-display/Typography';
import styles from './FileStyles';

const FileInput = (
  {
    files,
    filetypes,
    label,
    onFileAdd,
    onChange,
    onRemove,
    optional,
    required,
    justOne,
    tooltipContent,
    size = INPUTS.FILESIZE.LARGE
  }) => {
  const tradKey = 'components.DS.inputs.file';
  const { t } = useTranslation();
  const inputRefs = useRef([]);
  const iconButtonRef = useRef(null);
  const [deleteIconButtonElemWidth, setDeleteIconButtonElemWidth] = useState(null);
  const [errorsFilesMessages, setErrorsFilesMessages] = useState(Array(files.length).fill({ errorMessages: [] }));

  const onFileChange = (index, file) => {
    const errors = errorsManager(file, index);
    if (errors === 0) {
      onChange(index, file);
    }
  };

  const onRemoveChange = (index, file) => {
    inputRefs.current[index].value = null;
    onRemove(index, file);
  };

  const isComplete = () => {
    if (optional) {
      return files[0]?.file?.name;
    }

    if(justOne) {
       return !!files.find((datum) => datum.file);
    }
    return !files.find((datum) => !datum.file);
  };

  const errorsManager = (file, index) => {
    const errorsFilesMessagesCopy = [...errorsFilesMessages];
    const currentErrorMessages = { ...errorsFilesMessagesCopy[index] };
    const newErrors = [];

    if (!isSizeValid(file.size)) {
      newErrors.push(t(`${tradKey}.errors.filesize`));
    }

    if (!filetypes.find((filetype) => filetype.FILETYPE === file.type)) {
      newErrors.push(t(`${tradKey}.errors.filetype`));
    }

    currentErrorMessages.errorMessages = newErrors;
    errorsFilesMessagesCopy[index] = currentErrorMessages;
    setErrorsFilesMessages(errorsFilesMessagesCopy);

    return newErrors.length;
  };

  const showDeleteButton = (isFile) => {
    return isFile || optional;
  };

  const isSizeValid = (fileSize) => {
    return fileSize <= size;
  };

  const getFileTypes = () => {
    return filetypes.map((filetype) => filetype.ACCEPT).join(', ');
  };

  const getCardStyle = () => {
    let style = styles.card.default;

    if (required) {
      style = styles.card.required;
    }

    if (isComplete()) {
      style = styles.card.active;
    }

    return style;
  };

  const isRequired = () => {
    return required && !isComplete();
  };

  useEffect(() => {
    if (!deleteIconButtonElemWidth && iconButtonRef.current) {
      setDeleteIconButtonElemWidth(iconButtonRef.current.clientWidth);
    }
  });

  return (
    <Card sx={getCardStyle()} variant='outlined'>
      <CardContent>
        <Typography className='margin_bottom_1' sx={styles.labelTypo}>
          {label} :
          {tooltipContent && (
            <TooltipInfo placement='right-end' maxWidth={MAXWIDTH.SMALL} tooltipContent={
              <Typography info>
                {tooltipContent}
              </Typography>
            } />
          )}
        </Typography>

        {files.map((data, index) => (
          <React.Fragment key={index}>
            <Box sx={styles.cardContentContainer}>
              {!data.isLoading && (
                <>
                  <Box
                    maxWidth={styles.utils.getTypographyMaxWidth(!!data.file, deleteIconButtonElemWidth, optional)}
                    sx={styles.cardContentInputContainer}
                  >
                    <ArticleIcon sx={data.file ? styles.defaultIconsActive : styles.defaultIcons} />
                    <Typography sx={styles.cardContentTypography}>
                      {data.file ? data.file.name : t(`${tradKey}.placeholder`)}
                    </Typography>
                    {data.file && <DoneIcon sx={styles.defaultIconsActive} />}
                    <ButtonBase
                      sx={styles.buttonBaseInputMask}
                      component='label'
                      onKeyDown={(e) => e.keyCode === 32 && inputRefs.current[index]?.click()}
                      disabled={!!data.file || data.isLoading}
                    >
                      <input
                        ref={(el) => (inputRefs.current[index] = el)}
                        type='file'
                        accept={getFileTypes()}
                        hidden
                        onChange={(event) => {
                          const file = event.target.files[0];
                          if (!file) {
                            return;
                          }
                          onFileChange(index, file);
                        }}
                      />
                    </ButtonBase>
                  </Box>
                  {showDeleteButton(!!data.file) && (
                    <IconButton
                      sx={styles.deleteIcon}
                      ref={iconButtonRef}
                      className='padding_0'
                      aria-label='delete document'
                      onClick={() => onRemoveChange(index, data.file)}
                    >
                      <DeleteIcon sx={styles.defaultIcons} />
                    </IconButton>
                  )}
                </>
              )}
              {data.isLoading && (
                <Box sx={styles.loaderContainer}>
                  <CircularProgress className='margin_O' />
                </Box>
              )}
            </Box>
            {data.networkError && (
              <Box>
                <Typography warning indicator>
                  {data.networkErrorMessage ? data.networkErrorMessage : t(`${tradKey}.errors.network`)}
                </Typography>
              </Box>
            )}
            {errorsFilesMessages[index]?.errorMessages.map((message, index) => (
              <Typography key={index} warning indicator>
                {message}
              </Typography>
            ))}
          </React.Fragment>
        ))}
        {optional && onFileAdd && (
          <Box sx={styles.addContainer}>
            <IconButton sx={styles.addButton} className='margin_O' aria-label='add attachments' onClick={onFileAdd}>
              <AddCircleOutlineIcon sx={styles.addIcon} />
            </IconButton>
          </Box>
        )}
        {isRequired() && (
          <Typography warning indicator>
            {t(`${tradKey}.required.label`)}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

FileInput.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.object,
      networkError: PropTypes.bool,
      networkErrorMessage: PropTypes.string,
      isLoading: PropTypes.bool
    })
  ).isRequired,
  filetypes: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  onFileAdd: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  optional: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.number,
  justOne: PropTypes.bool
};

export default FileInput;

export const bicRegex = /^[A-Z]{4}FR[A-Z0-9]{2}([A-Z0-9]{3})?$/;

export const codePostalRegex = /^[0-9]{5}$/i;

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Za-z]{2,}$/i;

export const ibanRegex = /^FR[0-9A-Z]{25}$/i;

export const nafRegex = /^\d{3,4}[a-zA-Z]$/i;

export const nirRegex = /^[0-9]{6}[0-9AB][0-9]{6}$/i;

export const rnaRegex = /^[W|w][\d]{9}$/i;

export const sirenRegex = /^[\d]{9}$/i;

export const siretRegex = /^[\d]{14}$/i;

export const telephoneFrRegex = /^(0)[6,7][0-9]{8}$/i;
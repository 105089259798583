/* Built in imports */
import React, { useEffect, useState } from 'react';

/* Third party imports */
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

/* Internal imports */
import EntrepriseComplementForm from '../../../../components/US/epargne_salariale/forms/EntrepriseComplementForm';
import { URLS_MAROQUINERIE } from '../../../../utils/constantes/ROUTES/MAROQUINERIE';
import { cloneValues, nombreSansEspaces } from '../../../../common/FunctionsUtils';
import { getDossierByUidEntreprise } from '../../../../services/DossierService';
import { mettreAjourInformationsComplementairesEntreprise } from '../../../../services/EntrepriseService';
import {
  setInformationsComplementairesEntreprise, setInformationsDossier,
  setInformationsEntreprise, setInformationsUtilisateur, setLiasse,
  setRetourRoute
} from '../../../../modules/tunnel/redux/tunnelActions';


const MaroquinerieEntrepriseComplementPage = ({
                                                tunnel,
                                                setRetourRoute,
                                                setInformationsEntreprise,
                                                setInformationsComplementairesEntreprise,
                                                setInformationsDossier,
                                                setInformationsUtilisateur,
                                                setLiasse
                                              }) => {

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setRetourRoute(URLS_MAROQUINERIE.ENTREPRISE_INFORMATION.substring(1));
  }, []);

  const submit = (values) => {
    let valuesAenvoye = Object.assign(cloneValues(values), {
      nombreSalaries: nombreSansEspaces(values.nombreSalaries)
    });

    mettreAjourInformationsComplementairesEntreprise(
      tunnel.entreprise.uid,
      { valuesAenvoye }
    )
      .then(infosComplementaires => {
        setInformationsComplementairesEntreprise(
          infosComplementaires.complement
        );

        // On update la date de cloture comptable, ce qui va impacter la participation volontaire si elle existe
        if (
          (tunnel.dossier.participationVolontaire &&
            tunnel.dossier.participationVolontaire
              .participationActive) ||
          (tunnel.dossier.participation &&
            tunnel.dossier.participation.participationActive)
        ) {
          getDossierByUidEntreprise(tunnel.entreprise.uid).then(
            dossiers => {
              let dossierAvecEntreprise = dossiers[0];
              setInformationsEntreprise(
                dossierAvecEntreprise.entreprise
              );
              setInformationsDossier(dossierAvecEntreprise.dossier);
              setInformationsUtilisateur(
                dossierAvecEntreprise.utilisateur
              );
              setLiasse(dossierAvecEntreprise.liasse);
            }
          );
        }
        setRedirect(true);
      })
      .catch(error => {
        if (error && error.message) {
          error.log("error: ", error.message);
        }
      });
  }

  if (redirect) {
    return <Redirect to={ URLS_MAROQUINERIE.IDENTITE} />;
  }

  return (
    <div>
      <EntrepriseComplementForm onSubmit={submit} />
    </div>
  );

}

MaroquinerieEntrepriseComplementPage.propTypes = {
  tunnel: PropTypes.object.isRequired,
  setRetourRoute: PropTypes.func.isRequired,
  setInformationsEntreprise: PropTypes.func.isRequired,
  setInformationsComplementairesEntreprise: PropTypes.func.isRequired,
  setInformationsDossier: PropTypes.func.isRequired,
  setInformationsUtilisateur: PropTypes.func.isRequired,
  setLiasse: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = dispatch => ({
  setRetourRoute: route => dispatch(setRetourRoute(route)),
  setInformationsEntreprise: entreprise =>
    dispatch(setInformationsEntreprise(entreprise)),
  setInformationsComplementairesEntreprise: complement =>
    dispatch(setInformationsComplementairesEntreprise(complement)),
  setInformationsDossier: dossier => dispatch(setInformationsDossier(dossier)),
  setInformationsUtilisateur: utilisateur =>
    dispatch(setInformationsUtilisateur(utilisateur)),
  setLiasse: liasse => dispatch(setLiasse(liasse))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaroquinerieEntrepriseComplementPage);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

let _timer;

const Notification = ({ message }) => {
  const [visible, setVisible] = useState(message);

  useEffect(() => {
    _timer = setTimeout(() => {
      setVisible(false);
    }, 4000);

    return () => {
      clearTimeout(_timer);
    };
  }, [visible]);

  return <p className={`notification${visible && ' show'}`}>{message}</p>;
};

Notification.propTypes = {
  message: PropTypes.string
};

export default Notification;

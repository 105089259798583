/* Built-in imports */
import React from "react";

/* Interal imports */
import { Trans } from "react-i18next";
import { TransHtmlText } from "../../../../../yes/utils/TransHtmlText";
import { getSvgEnFonctionDuTenant } from "../../../../../../services/TenantService";
import {
  TARIFICATION_BENEFICIAIRES,
  TARIFICATION_ENTREPRISE
} from "../../../../../../utils/constantes/US/maroquinerie/MAROQUINERIETARIFICATION";

const MaroquinerieTarificationSection = () => {
  return (
    <div className="tarificationSection">
      <label className="titreFormulaireGes" htmlFor="tarificationSection">
        <TransHtmlText i18nKey="ges-tarification.titre.principal" />
        <Trans i18nKey="ges-tarification.HT" />
      </label>

      <p>
        <label className="titreFormulaire titreTarificationGes" htmlFor="tarificationSection">
          <Trans i18nKey={'ges-tarification.titre.1.pei'} />
        </label>
      </p>

      <div className="ges-tarification-inline-display">
        <div className="ges-tarification-rectangle">
          <div className="logo-container-tarification">
            <img className="logoUsersSmall" src={getSvgEnFonctionDuTenant('/assets/img/icons/', '/entreprise.svg')} alt="entreprise" />
          </div>
          <div className="prix-tarif-rectangle">{`${TARIFICATION_ENTREPRISE} €`}</div>
          <div className="txt-tarif-rectangle">
            <Trans i18nKey={'ges-tarification.tarification.entreprise'} />
          </div>
        </div>

        <div className="separateur-forfaits"> + </div>

        <div className="ges-tarification-rectangle">
          <div className="logo-container-tarification">
            <img className="logoUsersSmall" src={getSvgEnFonctionDuTenant('/assets/img/icons/', '/users-wm.svg')} alt="users" />
          </div>
          <div className="prix-tarif-rectangle">{`${TARIFICATION_BENEFICIAIRES} €`}</div>
          <div className="txt-tarif-rectangle">
            <Trans i18nKey={'ges-tarification.tarification.beneficiaire'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaroquinerieTarificationSection;
import { COUNTRY } from '../../../../../common/constants';
import { ENTREPRISE_FORM_FIELDS_EPARGNE } from '../../../../constantes/US/ENTREPRISEFORM';
import { sirenAvecEspace, siretAvecEspace, stringSansEspaces } from '../../../../../common/FunctionsUtils';

export const changeFieldsValues = (values, change) => {
  const {
    raisonSociale,
    dateCreation,
    formeJuridique,
    siret,
    siren,
    codeNaf,
    adresse: { rue, codePostal, ville, pays }
  } = values;

  raisonSociale && change(ENTREPRISE_FORM_FIELDS_EPARGNE.RAISON_SOCIALE, raisonSociale);
  dateCreation && change(ENTREPRISE_FORM_FIELDS_EPARGNE.DATE_CREATION, dateCreation);
  change(ENTREPRISE_FORM_FIELDS_EPARGNE.FORME_JURIDIQUE, formeJuridique);
  siret && change(ENTREPRISE_FORM_FIELDS_EPARGNE.SIRET, siretAvecEspace(siret));
  siren && change(ENTREPRISE_FORM_FIELDS_EPARGNE.SIREN, sirenAvecEspace(siren));
  codeNaf && change(ENTREPRISE_FORM_FIELDS_EPARGNE.CODE_NAF, codeNaf);
  rue && change(ENTREPRISE_FORM_FIELDS_EPARGNE.RUE, rue);
  codePostal && change(ENTREPRISE_FORM_FIELDS_EPARGNE.CODE_POSTAL, codePostal);
  ville && change(ENTREPRISE_FORM_FIELDS_EPARGNE.VILLE, ville);
  change(ENTREPRISE_FORM_FIELDS_EPARGNE.PAYS, JSON.stringify(pays ? pays : COUNTRY.FRANCE));
};

export const getEpargneEntrepriseFormSubmit = (values, uidUtilisateur) => {
  const {
    raisonSociale,
    dateCreation,
    siret,
    codeNaf,
    formeJuridique,
    rue,
    codePostal,
    ville,
  } = values;

  const valuesAenvoye = {
    raisonSociale,
    dateCreation,
    siret: stringSansEspaces(siret),
    codeNaf: codeNaf.toUpperCase(),
    formeJuridique,
    adresse: { ville, codePostal, rue },
    uidDirigeant: uidUtilisateur
  };

    return { valuesAenvoye };
};

/* built-in imports */
import React from 'react';

/* Internal imports */
import PropTypes from "prop-types";
import Tooltip from '../../../components/DS/data-display/Tooltip';
import TooltipInfoIcon from '../../../assets/icons/components/tooltipInfoIcon';
import TooltipQuestion from "../../../assets/icons/components/tooltipQuestion";
import Typography from '../../../components/DS/data-display/Typography';
import ICON from '../../constantes/DS/ICON';
import { TYPE, MAXWIDTH } from '../../constantes/DS/TOOLTIP';

const TooltipInfo = ({
    tooltipContent,
    type = TYPE.DEFAULT,
    maxWidth = MAXWIDTH.MEDIUM,
    iconSize = ICON.SIZE.MEDIUM,
    ...other
}) => {
  const getIcon = () => {
    const style = {
      fontSize: iconSize,
      cursor: "pointer",
      display:"inline-flex",
      flexWrap: 'wrap',
      verticalAlign: "bottom",
      margin: "0 0 -1px 4px",
      ':hover': {
        backgroundColor: "rgba(0,0,0,0.2)",
        borderRadius: '50%',
      }
    }

    switch (type) {
      case TYPE.HELP:
        return (<TooltipQuestion sx={style} inheritViewBox />);
      default:
        return (<TooltipInfoIcon sx={style} inheritViewBox />);
    }
  }
  /*
  * Si le tooltipContent passé en props est uniquement du texte (pas de balises HTML) => on applique le Typography
  * Sinon on render du HTML comme spécifié
   */
  const getContent = () => {
    if(typeof tooltipContent === 'string' && !tooltipContent.substr(0,2).includes("<")){
      return(
        <Typography info>
          {tooltipContent}
        </Typography>
      );
    }else{
      return tooltipContent;
    }
  }

  return(
    <Tooltip
      {...other}
      title={getContent()}
      maxWidth={maxWidth}
    >
      {getIcon()}
    </Tooltip>
  );
}

TooltipInfo.propTypes = {
  maxWidth: PropTypes.string,
  type: PropTypes.string,
  iconSize: PropTypes.string
}

export { TYPE, MAXWIDTH };
export default TooltipInfo;

/* built-in imports */
import React from 'react';

/* third-party imports */
import { createSvgIcon } from "@mui/material";

/* Internal imports */
import { ReactComponent as TooltipInfoIconSvg } from '../svg/tooltipInfo.svg';

export default createSvgIcon(
    <TooltipInfoIconSvg />,
    'TooltipInfoIcon'
);

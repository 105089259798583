/* built-in imports */
import React, { Component } from 'react';

/* third-party imports */
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

/* internal imports */
import GarantiesPrevoyanceForm from '../../../../components/US/assurance/forms/GarantiesPrevoyanceForm';
import { mettreAjourModalitesPero, mettreAjourGarantiesPrevoyance } from '../../../../services/DossierService';
import { ROUTE_CONTINUER_SUR_ORDINATEUR, SEUIL_MOBILE } from '../../../../common/Configuration';
import { setRetourRoute, setModalitesPero, setGarantiesPrevoyance } from '../../../../modules/tunnel/redux/tunnelActions';
import { URLS_ASSURANCE } from '../../../../utils/constantes/ROUTES/ASSURANCE';

class AssuranceGarantiesPrevoyancePage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    // On modifie la route lorsque l'utilisateur clique le bouton Retour
    this.props.setRetourRoute(URLS_ASSURANCE.PPE.substring(1));
    this.state = { redirect: false };
  }

  async submit(values) {
    let valuesModalites = {
      dateEffet: values.dateEffet,
      periodicite: values.periodicite
    };

    let valuesGaranties = {
      auMoinsCinqSalaries: values.auMoinsCinqSalaries,
      garantiePlancher: values.garantiePlancher,
      garantieDeBonneFin: values.garantieDeBonneFin
    };

    try {
      const modalitesPero = await mettreAjourModalitesPero(this.props.tunnel.dossier.uid, { valuesAenvoyer: valuesModalites });
      this.props.setModalitesPero(modalitesPero);
      const garantiesPrevoyance = await mettreAjourGarantiesPrevoyance(this.props.tunnel.dossier.uid, { valuesAenvoyer: valuesGaranties });
      this.props.setGarantiesPrevoyance(garantiesPrevoyance);

      this.redirect();
    } catch (error) {
      if (error && error.message) {
        this.setState({ errorMsg: error.message });
      }
    }
  }

  redirect() {
    this.setState({
      redirect: true
    });
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      // Pour les mobiles, on redirige vers la page informant de continuer sur un desktop
      if (window.innerWidth <= SEUIL_MOBILE) {
        return <Redirect to={ROUTE_CONTINUER_SUR_ORDINATEUR} />;
      }
      return <Redirect to={URLS_ASSURANCE.CATEGORIES_SALARIES} />;
    }

    return (
      <div>
        <GarantiesPrevoyanceForm onSubmit={this.submit} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setModalitesPero: (modalitesPero) => dispatch(setModalitesPero(modalitesPero)),
  setGarantiesPrevoyance: (garantiesPrevoyance) => dispatch(setGarantiesPrevoyance(garantiesPrevoyance))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssuranceGarantiesPrevoyancePage);

/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import TarificationForm from '../../../../components/US/assurance/forms/tarification/TarificationForm';
import { FORMAT_INVALIDE } from '../../../../common/ApiError';
import { URLS_ASSURANCE } from '../../../../utils/constantes/ROUTES/ASSURANCE';
import { mettreAjourFraisContrat } from '../../../../services/DossierService';
import { nombreAvirgule } from '../../../../common/FunctionsUtils';
import {
  setRetourRoute,
  setFraisContrat
} from '../../../../modules/tunnel/redux/tunnelActions';

class AssuranceTarificationPage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    // On modifie la route lorsque l'utilisateur clique le bouton Retour
    this.props.setRetourRoute(URLS_ASSURANCE.MOYENS_PAIEMENT.substring(1));

    this.state = {
      redirect: false
    };
  }

  mapValues = (values) => {
    return Object.assign({
      tauxFraisCotisationsObligatoires: values.tauxFraisCotisationsObligatoires,
      tauxFraisVersementsVolontaires: values.tauxFraisVersementsVolontaires,
      tauxDerogationFraisEuros: values.tauxDerogationFraisEuros,
      tauxDerogationFraisUC: values.tauxDerogationFraisUC,
      fraisDossier: nombreAvirgule(values.fraisDossier),
      commisionnementEscompte: values['commisionnementEscompte-oui']
    });
  };

  // SUBMIT
  async submit(values) {
    const valuesAenvoye = this.mapValues(values);

    try {
      const dossier = await mettreAjourFraisContrat(this.props.uid, { valuesAenvoye });
      this.props.setFraisContrat(dossier);
      this.setState({ redirect: true });
    } catch (error) {
      if (error && error.message) {
        console.log(error.message);
        switch (error.code) {
          case FORMAT_INVALIDE:
            this.setState({
              errorMsg: error.message
            });
            break;
          default:
        }
      }
    }
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={URLS_ASSURANCE.FINALISATION} />;
    }

    return (
      <div>
        <TarificationForm onSubmit={this.submit} />
      </div>
    );
  }
}

AssuranceTarificationPage.propTypes = {
  setFraisContrat: PropTypes.func,
  setRetourRoute: PropTypes.func,
  tunnel: PropTypes.object
};

// REDUX + REDUX FORM
const mapStateToProps = ({
  tunnel: {
    dossier: { uid, type }
  }
}) => ({
  uid,
  type
});

const mapDispatchToProps = (dispatch) => ({
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setFraisContrat: (fraisContrat) => dispatch(setFraisContrat(fraisContrat))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssuranceTarificationPage);

/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import IdentiteForm from '../../../../components/US/assurance/forms/IdentiteForm';
import { URLS_ASSURANCE } from "../../../../utils/constantes/ROUTES/ASSURANCE";
import { envoiEmailDocumentationPEROB } from '../../../../services/DossierService';
import { getAssuranceIdentiteFormSubmit } from '../../../../utils/US/components/assurance/forms/identiteUtils';
import { mettreAjourFonctionDirigeant, mettreAjourIdentiteDirigeant } from '../../../../services/UtilisateurService';
import { setDonneesDirigeant, setInformationsUtilisateur, setRetourRoute } from '../../../../modules/tunnel/redux/tunnelActions';

class AssuranceIdentitePage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.emailAdressChanged = this.emailAdressChanged.bind(this);
    this.props.setRetourRoute(URLS_ASSURANCE.ENTREPRISE_INFORMATION.substring(1));
    this.state = {
      redirect: false,
      emailSentPEROB: typeof(this.props.tunnel.utilisateur?.adresseEmail) === "string"
    };
  }

  async submit(values) {
    const utilisateurUid = this.props.tunnel.utilisateur.uid;
    const dossierUid = this.props.tunnel.dossier.uid;
    const valuesAenvoye = getAssuranceIdentiteFormSubmit(values, this.props.tunnel.entreprise.uid);
    try {
      await mettreAjourIdentiteDirigeant(utilisateurUid, valuesAenvoye);
      const infosDirigeant = await mettreAjourFonctionDirigeant(utilisateurUid, valuesAenvoye);
      this.props.setInformationsUtilisateur(infosDirigeant);
      this.props.setDonneesDirigeant(infosDirigeant);
      //Envoi de la doc-précontractuelle
      if (!this.state.emailSentPEROB) {
        await envoiEmailDocumentationPEROB(dossierUid);
        this.setState({
          emailSentPEROB: true
        })
        return;
      }

      this.redirect();

    } catch (error) {
      this.setMessageErreur(error);
    }
  }

  emailAdressChanged(value) {
    if (value !== this.props.tunnel.utilisateur?.adresseEmail) {
      this.setState({
        emailSentPEROB: false
      })
    }
  }

  redirect() {
    this.setState({
      redirect: true
    });
  }

  setMessageErreur(error) {
    if (error && error.message) {
      this.setState({
        errorMsg: error.message
      });
    }
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={URLS_ASSURANCE.PPE} />;
    }

    return (
      <div>
        <IdentiteForm onSubmit={this.submit} emailSentPEROB={this.state.emailSentPEROB} emailAdressChanged={this.emailAdressChanged}/>
      </div>
    );
  }
}

AssuranceIdentitePage.propTypes = {
  setDonneesDirigeant: PropTypes.func.isRequired,
  setInformationsUtilisateur: PropTypes.func.isRequired,
  setRetourRoute: PropTypes.func.isRequired,
  tunnel: PropTypes.object.isRequired
};

// REDUX + REDUX FORM
const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setInformationsUtilisateur: (utilisateur) => dispatch(setInformationsUtilisateur(utilisateur)),
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setDonneesDirigeant: (donneesDirigeant) => dispatch(setDonneesDirigeant(donneesDirigeant))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssuranceIdentitePage);

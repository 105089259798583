import React from "react";
import config from "../../../common/StaticSiteConfig";

const besoinsItems = config.footerLinks.besoins;
const solutionItems = config.footerLinks.solution;
const guideItems = config.footerLinks.guide;
const juridiqueItems = config.footerLinks.juridique;
const aproposItems = config.footerLinks.apropos;
const socialLinks = config.socialLinks;
const { contact, copyright, disclaimer } = config;

export const Footer = () => (
  <footer>
    <div className="footer__container">
      <div className="grid-container">
        <div className="grid-x grid-padding-x">
          <div className="cell large-8 small-12">
            <div className="grid-x footer__links">
              <div className="column">
                <h3>Vos besoins</h3>
                <ul>
                  {besoinsItems.map((item, key) => (
                    <li key={key}>
                      <a href={item.url}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="column">
                <h3>Notre solution</h3>
                <ul>
                  {solutionItems.map((item, key) => (
                    <li key={key}>
                      <a href={item.url}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="column">
                <h3>Le guide</h3>
                <ul>
                  {guideItems.map((item, key) => (
                    <li key={key}>
                      <a href={item.url}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="column">
                <h3>Prose juridique</h3>
                <ul>
                  {juridiqueItems.map((item, key) => (
                    <li key={key}>
                      <a href={item.url}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="column">
                <h3>À propos</h3>
                <ul>
                  {aproposItems.map((item, key) => (
                    <li key={key}>
                      <a href={item.url}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="cell large-4 small-12">
            <div className="grid-x">
              <div className="cell large-12 medium-6 small-12 footer__sociallinks">
                <h3>Suivez-nous</h3>
                <form
                  method="post"
                  className="footer__form"
                  action="{{mailChimp.subscribeNewsletter}}&SOURCE=Homepage"
                  target="_blank"
                >
                  <input
                    className="footer__input"
                    name="EMAIL"
                    type="email"
                    placeholder="prenom@exemple.com"
                  />
                  <button className="footer__submit" type="submit">
                    <img
                      alt="Soumettre"
                      src="/assets/img/icons/yes/arrow-right.svg"
                    />
                  </button>
                </form>
              </div>
              <div className="cell large-12 medium-6 small-12">
                <div className="footer__contact">
                  <address>
                    <img
                      src="/assets/img/logos/logo-yes-yomoni-white.svg"
                      className="footer__contact-image"
                      alt="Yomoni Épargne Salariale"
                      width="164"
                    />
                    <p>
                      {contact.address.street} {contact.address.city}
                    </p>
                    <p>
                      <a
                        className="footer__contact--telNumber"
                        href={"tel:" + contact.telNumber.label}
                      >
                        {contact.telNumber.label}
                      </a>
                    </p>
                    <p>
                      <a href={"mailto:" + contact.emailAddress}>
                        {contact.emailAddress}
                      </a>
                    </p>
                  </address>
                  <div className="grid-x align-middle">
                    <div className="cell small-3">
                      <a
                        href={socialLinks.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="footer__sociallinks-logo"
                          src="/assets/img/logos/facebook.svg"
                          alt="Facebook"
                        />
                      </a>
                    </div>
                    <div className="cell small-3">
                      <a
                        href={socialLinks.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="footer__sociallinks-logo"
                          src="/assets/img/logos/twitter.svg"
                          alt="Twitter"
                        />
                      </a>
                    </div>
                    <div className="cell small-3">
                      <a
                        href={socialLinks.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="footer__sociallinks-logo"
                          src="/assets/img/logos/instagram.svg"
                          alt="Google Plus"
                        />
                      </a>
                    </div>
                    <div className="cell small-3">
                      <a
                        href={socialLinks.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="footer__sociallinks-logo"
                          src="/assets/img/logos/linkedin.svg"
                          alt="Linkedin"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="footer__container footer__container--small">
      <div className="grid-container">
        <div className="grid-x grid-padding-x align-center-middle text-center">
          <div className="call small-12">
            <p>{copyright}</p>
            <p className="footer--smaller">{disclaimer}</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;

import { COUNTRY, DEFAULT_CIVILITE, NATIONALITE } from '../../../../../common/constants';
import { DIRIGEANT } from '../../../../../common/Configuration';
import { IDENTITE_FIELDS_ASSURANCE, IDENTITE_FIELDS_EPARGNE } from '../../../../constantes/US/IDENTITEFORM';
import { NormalizeTelephoneSpace } from '../../../../../common/Normalizers';
import { assignNonNullValues } from '../../../../jsObjectUtils';
import { getAppDefaultCountry } from '../../../../data/countriesUtils';
import { stringSansEspaces } from '../../../../../common/FunctionsUtils';

const DEFAULT_VALUES = {
  indicatifCode: getAppDefaultCountry().countryCodeA2,
  civilite: DEFAULT_CIVILITE,
  nationalites: NATIONALITE.DEFAULT,
  paysResidence: COUNTRY.FRANCE,
  adresse: {
    pays: COUNTRY.FRANCE
  },
  adresseNaissance: {
    pays: COUNTRY.FRANCE
  }
};

const changeAdresseFields = (adresse, change) => {
  const { rue, codePostal, ville, pays } = adresse;

  rue && change(IDENTITE_FIELDS_ASSURANCE.RUE, rue);
  ville && change(IDENTITE_FIELDS_ASSURANCE.VILLE, ville);
  codePostal && change(IDENTITE_FIELDS_ASSURANCE.CODE_POSTAL, codePostal);
  change(IDENTITE_FIELDS_ASSURANCE.PAYS, JSON.stringify(pays));
};

const changeAdresseNaissanceFields = (adresseNaissance, change) => {
  const { codePostal: codePostalNaissance, ville: villeNaissance, pays: paysNaissance } = adresseNaissance;

  villeNaissance && change(IDENTITE_FIELDS_ASSURANCE.LIEU_NAIISANCE, villeNaissance);
  codePostalNaissance && change(IDENTITE_FIELDS_ASSURANCE.CODE_POSTAL_NAISSANCE, codePostalNaissance);
  change(IDENTITE_FIELDS_ASSURANCE.PAYS_NAISSANCE, JSON.stringify(paysNaissance));
};

export const initFieldsValuesAssurance = (dirigeant, change) => {
  const {
    nom,
    prenom,
    indicatifCode,
    telephone,
    civilite,
    nomNaissance,
    dateNaissance,
    nationalites,
    nir,
    paysResidence,
    adresse,
    adresseNaissance,
    roleDansEntreprise
  } = assignNonNullValues(DEFAULT_VALUES, dirigeant);

  change(IDENTITE_FIELDS_EPARGNE.INDICATIF, indicatifCode);
  change(IDENTITE_FIELDS_ASSURANCE.CIVILITE, civilite);
  change(IDENTITE_FIELDS_ASSURANCE.NATIONALITES, nationalites);
  change(IDENTITE_FIELDS_ASSURANCE.PAYS_RESIDENCE_FISCALE, JSON.stringify(paysResidence));

  nom && change(IDENTITE_FIELDS_ASSURANCE.NOM, nom);
  prenom && change(IDENTITE_FIELDS_ASSURANCE.PRENOM, prenom);
  telephone && change(IDENTITE_FIELDS_ASSURANCE.TELEPHONE, NormalizeTelephoneSpace(telephone));

  nir && change(IDENTITE_FIELDS_ASSURANCE.NIR, nir);
  (nomNaissance || nom) && change(IDENTITE_FIELDS_ASSURANCE.NOM_NAISSANCE, nomNaissance || nom);
  dateNaissance && change(IDENTITE_FIELDS_ASSURANCE.DATE_NAISSANCE, dateNaissance);

  changeAdresseNaissanceFields(adresseNaissance, change);
  changeAdresseFields(adresse, change);


  if(roleDansEntreprise?.length > 0) {
    roleDansEntreprise.map((roleDansEntreprise) => {
      if (roleDansEntreprise.role === DIRIGEANT) {
        change(IDENTITE_FIELDS_ASSURANCE.FONCTION, roleDansEntreprise.fonction);
      }
      return null;
    });
  }
};

export const getAssuranceIdentiteFormSubmit = (values, uidEntreprise) => {
  const {
    civilite,
    prenom,
    nom,
    fonction,
    adresseEmail,
    indicatifCode,
    telephone,
    nomNaissance,
    lieuNaissance,
    codePostalNaissance,
    dateNaissance,
    paysNaissance,
    paysResidence,
    nationalites,
    nir,
    rue,
    codePostal,
    ville,
    pays
  } = values;

  const valuesAenvoye = {
    prenom,
    nom,
    adresseEmail,
    fonction,
    uidEntreprise,
    indicatifCode,
    telephone: stringSansEspaces(telephone),
    civilite,
    nomNaissance,
    adresseNaissance: {
      codePostal: codePostalNaissance,
      ville: lieuNaissance,
      pays: JSON.parse(paysNaissance)
    },
    dateNaissance,
    paysResidence: JSON.parse(paysResidence),
    paysNaissance: JSON.parse(paysNaissance),
    nationalites,
    nir,
    adresse: {
      rue,
      codePostal,
      ville,
      pays: JSON.parse(pays)
    }
  };

  return { valuesAenvoye };
};

/* Built in imports */
import React from 'react';

/* Third Party Imports */
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Slider from '@appigram/react-rangeslider';

/* Internal imports */
import AbondementToggler from './AbondementToggler';
import AddContributionLevel from './AddContributionLevel';
import Anciennete from './Anciennete';
import ContributionLine from './ContributionLine';
import YesInputWithoutReduxForm from '../../../../form/InputWithoutReduxForm';
import { LISTE_TAUX_ABONDEMENT } from '../../../../../services/TauxService';
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';
import { YesCheckboxV2 } from '../../../../form/CheckboxV2';
import { isPeiPercoiIndependantEnabled, isSourcesVersementAbondeesEnabled } from '../../../../../services/TenantService';
import { plafondAbondementPEE } from '../../../../../modules/simulateur/fonctions';
import { sourcesVersementsPei } from '../../../../../services/SourcesVersementsService';

const AbondementPEI = ({
  config,
  plafondPei,
  anciennete,
  nbMaxPaliers,
  addPalierPei,
  changerTauxPei,
  modeAnciennete,
  checkPlafondPei,
  palierPeiActive,
  classDisablePei,
  deletePalierPei,
  plafondEmptyError,
  showPlafondPassPei,
  checkTauxDegressif,
  dispositifAnciennete,
  palierAbondementsPei,
  classDisablePeiSlider,
  getPeiOuPercoiChecked,
  changerValeurMaxPalier,
  changerValeurPlafondPei,
  classDisableOuverturePei,
  miseEnPlaceAbondementPei,
  getPalierAbondementMaxPei,
  checkMaxPalierSuperieurAMin,
  errorMaxPalierSuperieurAMin,
  changerValeurSliderPlafondPei,
  desactiverMiseEnPlaceAbondementPei,
  checkSourceVersementPeiSelectionnee,
  checkVersementVolontaireSelectionnee,
  validateSourceVersementPeiSelectionnee,
  PASS
}) => (
  <div className="cell">
    <div className="yes-rectangle">
      <div className="premierNiveauAbondement">
        <Field
          component={YesCheckboxV2}
          type="checkbox"
          name="ouverturePei"
          id="ouverturePei"
          validate={[getPeiOuPercoiChecked]}
          labeltext={<TransHtmlText i18nKey="dispositif.ouverture.pei.titre" />}
          disabled={!isPeiPercoiIndependantEnabled()}
          onChange={desactiverMiseEnPlaceAbondementPei}
        />
      </div>

      <div className="contentAbondementActive">
        <AbondementToggler
          classDisableOuverture={classDisableOuverturePei}
          config={config}
          name="miseEnPlaceAbondementPei"
        />

        <div className={`alignContentInColumn ${classDisablePei} ${miseEnPlaceAbondementPei ? '' : 'hide'}`}>
          <div className="parametrageAbondement">Paramétrage de l'abondement</div>
          {dispositifAnciennete.pei ? (
            <>
              <Anciennete
                type="pei"
                anciennete={anciennete}
                maxValue={plafondAbondementPEE()}
                onCancel={() => modeAnciennete('pei', false)}
                PASS={PASS}
              />
            </>
          ) : palierAbondementsPei && palierAbondementsPei.length > 1 ? (
            palierAbondementsPei.map((_, i) => (
              <ContributionLine
                index={i}
                key={`palierAbondementsPei-${i}`}
                contributionType="palierAbondementsPei"
                deletable={palierAbondementsPei.length - 1 === i && palierPeiActive}
                deleteLine={() => deletePalierPei(false)}
                addLine={addPalierPei}
                changeRate={(e) => changerTauxPei(e, i, false)}
                isActive={palierPeiActive}
                checkMaxValue={checkPlafondPei}
                checkMaxHigherThanMin={checkMaxPalierSuperieurAMin}
                changeMaxValue={(e) => changerValeurMaxPalier(e, i, 'Pei')}
                maxStepValue={getPalierAbondementMaxPei}
                checkDecliningRate={() => checkTauxDegressif(palierAbondementsPei)}
                doValidation={miseEnPlaceAbondementPei}
                listTauxAbondement={LISTE_TAUX_ABONDEMENT}
              />
            ))
          ) : (
            <ContributionLine
              contributionType="palierAbondementsPei"
              index={0}
              key={'palierAbondementsPei-0'}
              deletable={false}
              addLine={addPalierPei}
              changeRate={(e) => changerTauxPei(e, 0, false)}
              isActive={palierPeiActive}
              checkMaxValue={checkPlafondPei}
              checkMaxHigherThanMin={checkMaxPalierSuperieurAMin}
              changeMaxValue={(e) => changerValeurMaxPalier(e, 0, 'Pei')}
              maxStepValue={getPalierAbondementMaxPei}
              checkDecliningRate={() => checkTauxDegressif(palierAbondementsPei)}
              modeAnciennete={modeAnciennete}
              doValidation={miseEnPlaceAbondementPei}
              listTauxAbondement={LISTE_TAUX_ABONDEMENT}
            />
          )}
          {plafondPei >= plafondAbondementPEE() && palierPeiActive && (
            <span className="errorMsgTauxDegressif">
              Veuillez modifier vos paliers de versement, l’abondement ne peut pas excéder 8% du PASS
            </span>
          )}
          {checkTauxDegressif(palierAbondementsPei) && (
            <span className="errorMsgTauxDegressif">Attention, le pourcentage du taux d’abondement doit être dégressif.</span>
          )}
          {palierPeiActive && <span className="errorMsgTauxDegressif">{errorMaxPalierSuperieurAMin(palierAbondementsPei)}</span>}
          {palierPeiActive &&
            palierAbondementsPei &&
            palierAbondementsPei.length > 1 &&
            palierAbondementsPei.length < nbMaxPaliers && (
              <AddContributionLevel
                handleAdd={() => addPalierPei()}
                additionnalClass="boutonAjouterPalierADroite"
                contributionTypeLabel="abondement"
              />
            )}
          {!dispositifAnciennete.pei && (
            <>
              <div className="alignContentInRow">
                <div className="yes-label">Plafond d’abondement (max 8% du PASS)</div>
                <div className={`cell sliderDispositifAbondement ${classDisablePeiSlider}`}>
                  <Slider
                    value={plafondPei}
                    orientation="horizontal"
                    onChange={(value) => !palierPeiActive && changerValeurSliderPlafondPei(value)}
                    tooltip={false}
                    max={plafondAbondementPEE()}
                  />
                  {`${(plafondPei*100/PASS).toFixed(2)} %`}
                </div>
                <div className={`plafondAbondementInput ${classDisablePei}`}>
                  <YesInputWithoutReduxForm
                    disabled={palierPeiActive}
                    value={plafondPei}
                    onChange={changerValeurPlafondPei}
                    euroField={true}
                  />
                  {!palierPeiActive && showPlafondPassPei && <span className="infosAbondementPass">Plafond atteint</span>}
                </div>
              </div>
              <div className="errorMsg">{plafondEmptyError}</div>
            </>
          )}
          {miseEnPlaceAbondementPei && isSourcesVersementAbondeesEnabled() && (
            <div className="alignContentInColumn">
              <div className="parametrageAbondement">Sélectionnez les sources de versement abondées</div>
              <div className="alignContentInRow">
                {sourcesVersementsPei.map((source, i) => (
                  <div
                    key={`pei.${source.name}`}
                    className={`${dispositifAnciennete.pei && i !== 0 ? 'disabled ' : ''}sourcesVersementAbondees`}
                  >
                    <Field
                      disabled={dispositifAnciennete.pei && i !== 0}
                      component={YesCheckboxV2}
                      type="checkbox"
                      name={`sourcesVersementsPei.${source.name}`}
                      id={`sourcesVersementsPei.${source.name}`}
                      validate={validateSourceVersementPeiSelectionnee}
                      labeltext={source.label}
                    />
                  </div>
                ))}
              </div>
              <div className="errorMsgTauxDegressif">{checkSourceVersementPeiSelectionnee()}</div>
              {dispositifAnciennete.pei && <div className="warningMsg">{checkVersementVolontaireSelectionnee('Pei')}</div>}
            </div>
          )}
        </div>
      </div>
      {miseEnPlaceAbondementPei && !isSourcesVersementAbondeesEnabled() && (
        <div className={`cell medium-12 infosAbondementSources ${classDisablePei}`}>
          Toutes les sources de versement seront abondées
        </div>
      )}
    </div>
  </div>
);

AbondementPEI.propTypes = {
  config: PropTypes.object,
  anciennete: PropTypes.object,
  plafondPei: PropTypes.number,
  addPalierPei: PropTypes.func,
  nbMaxPaliers: PropTypes.number,
  changerTauxPei: PropTypes.func,
  deletePalierPei: PropTypes.func,
  checkPlafondPei: PropTypes.func,
  palierPeiActive: PropTypes.bool,
  classDisablePei: PropTypes.string,
  showPlafondPassPei: PropTypes.bool,
  checkTauxDegressif: PropTypes.func,
  palierAbondementsPei: PropTypes.array,
  changerValeurMaxPalier: PropTypes.func,
  dispositifAnciennete: PropTypes.object,
  classDisablePeiSlider: PropTypes.string,
  changerValeurPlafondPei: PropTypes.func,
  miseEnPlaceAbondementPei: PropTypes.bool,
  getPalierAbondementMaxPei: PropTypes.func,
  modeAnciennete: PropTypes.func.isRequired,
  classDisableOuverturePei: PropTypes.string,
  checkMaxPalierSuperieurAMin: PropTypes.func,
  errorMaxPalierSuperieurAMin: PropTypes.func,
  changerValeurSliderPlafondPei: PropTypes.func,
  desactiverMiseEnPlaceAbondementPei: PropTypes.func,
  checkSourceVersementPeiSelectionnee: PropTypes.func,
  checkVersementVolontaireSelectionnee: PropTypes.func,
  validateSourceVersementPeiSelectionnee: PropTypes.func,
  PASS: PropTypes.number
};

export default AbondementPEI;

import React, { Component } from "react";
import ConnexionForm from "./ConnexionForm";
import { connect } from "react-redux";
import { connexion } from "../../../../services/CompteService";
import { setToken } from "../../../../services/ResourcesService";
import { Redirect } from "react-router-dom";
import { ROUTE_SIRET, FLAG_REDIRECT } from "../../../../common/Configuration";
import {
  setInformationsUtilisateur,
  setLoader,
  setEmailDejaExistant
} from "../../../../modules/tunnel/redux/tunnelActions";
import { COMBINAISON_UTILISATEUR_MOTDEPASSE_INCORRECTE } from "../../../../common/ApiError";
import { getUtilisateur } from "../../../../services/UtilisateurService";
import { getTenantUppercase } from "../../../../services/TenantService";

class ConnexionPage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      redirect: false
    };
    this.props.handlerIllustration("illu-authentification");
  }

  submit(values) {
    let valuesWithTenantId = {
      ...values,
      tenant: getTenantUppercase()
    };
    this.setState({
      error: ""
    });

    this.props.setLoader(true);

    connexion({ values: valuesWithTenantId })
      .then(token => {
        // Ajout du JWT dans le local storage
        setToken(token.jwt);
        // On va ensuite récupérer les informations de l'utilisateur pour les insérer dans le state Redux
        getUtilisateur(token.dirigeantUid).then(utilisateur => {
          this.props.setInformationsUtilisateur(utilisateur);
          this.setState({
            redirect: true
          });
        });
      })
      .catch(error => {
        if (error && error.code) {
          switch (error.code) {
            case COMBINAISON_UTILISATEUR_MOTDEPASSE_INCORRECTE:
              this.props.setEmailDejaExistant(values.adresseEmail);
              this.setState({
                errorCode: error.code,
                error: error.message
              });
              break;
            default:
              this.setState({
                error: error.message
              });
          }
        }
      })
      .then(() => this.props.setLoader(false));
  }

  render() {
    const { redirect, error, errorCode } = this.state;

    if (redirect) {
      return <Redirect to={ROUTE_SIRET + FLAG_REDIRECT} />;
    }
    return (
      <div className="grid-x grid-margin-x">
        <div className="cell medium-12 formConnexion">
          <h2>Content de vous revoir !</h2>
          <p>
            Entrez les informations de votre compte afin de vous connecter et
            poursuivre votre souscription.
          </p>

          {/* {errorMsg && <div className="globalErrorForm">{errorMsg}</div>} */}

          <ConnexionForm
            onSubmit={this.submit}
            customError={error}
            errorCode={errorCode}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  setInformationsUtilisateur: utilisateur =>
    dispatch(setInformationsUtilisateur(utilisateur)),
  setLoader: actif => dispatch(setLoader(actif)),
  setEmailDejaExistant: email => dispatch(setEmailDejaExistant(email))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnexionPage);

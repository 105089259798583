import React, { Component } from "react";
import { connect } from "react-redux";
import { YesButton } from "../../../form/Button";
import { getSvgEnFonctionDuTenant } from "../../../../services/TenantService";

class Conseiller extends Component {
  // constructor(props) {
  //   super(props)
  // }

  render() {
    return (
      <div className="grid-x center-item-simu-container conseiller-simulation">
        <div className="cell medium-12">
          <div className="grid-x grid-margin-x">
            <div className="cell medium-8">
              <h2 className="titre-mail-simulation">Envie d'aller plus loin</h2>
              <p>
                Contactez un de nos experts pour ouvrir votre plan d'épargne
                salariale.
              </p>
              <YesButton
                cssClass="background-bleugris"
                text="Prendre un RDV téléphonique"
                onClick={() =>
                  window.open(
                    "https://yomoni.appointlet.com/s/yomoni-rendez-vous-avec-notre",
                    "_blank"
                  )
                }
              />
            </div>
            <div className="cell medium-4">
              <div className="img-conseiller-simulateur">
                <img
                  src={getSvgEnFonctionDuTenant(
                    "/assets/img/illustrations/",
                    "/illu-contact.svg"
                  )}
                  alt="conseiller"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  simulateur: state.simulateur
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(Conseiller);


/* built-in imports */
import React from 'react';

/* third-party imports */
import Box from '@mui/material/Box';
import Typography from '../../../components/DS/data-display/Typography';
import { Trans } from 'react-i18next';

/* Internal imports */
import packageJson from '../../../../package.json';
import styles from './versionViewerStyles';

const VersionViewer = () => {
  const chinookVersion = packageJson.version;
  const tradKey = 'page-status-infos';

  return (
    <Box sx={styles.boxContainer}>
      <Typography>
        <Trans i18nKey={`${tradKey}-version`} /> : {chinookVersion}
      </Typography>
    </Box>
  );
};

export default VersionViewer;
/* Built-in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

/* Internal imports */
import YesInput from '../../../../form/Input';

const AssuranceFixedFees = ({ label, inputValue, inputName }) => (
  <div className="administrationFees">
    <label className="titreFormulaire titreTarificationGes">
      {label}
    </label>
    <div className="tarification-input frais-dossier-input" style={{marginLeft: "10px"}}>
      <Field
        disabled
        component={YesInput}
        type="text"
        name={inputName}
        labeltext={inputValue}
        tabIndex="-1"
      />
    </div>
  </div>
);

AssuranceFixedFees.propTypes = {
  label: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired
};

export default AssuranceFixedFees;

/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

/* Internal imports */
import YesInput from '../../../form/Input';
import { NormalizeSiretSpace } from '../../../../common/Normalizers';
import { Required, Siret } from '../../../../common/Validators';
import { Trans } from 'react-i18next';
import { YesButton } from '../../../form/Button';
import { connect } from 'react-redux';
import { setServerErrorForm } from '../../../../modules/tunnel/redux/tunnelActions';
import { siretAvecEspace } from '../../../../common/FunctionsUtils';

class SiretForm extends Component {
  constructor(props) {
    super(props);
    this.setErrorForm = this.setErrorForm.bind(this);
  }

  componentDidMount() {
    // CAS 1 : SIRENE RENSEIGNE SUR LE PREMIER ECRAN SANS ENTREPRISE PERSISTEE AUPARAVANT
    // CAS 2 : ENTREPRISE PERSISTEE ET SIRENE RENSEIGNE A L'ETAPE UNE (le bouton retour a été cliqué et l'utilisateur a renseigné un autre SIRENE)
    // dans ce cas, le formulaire sera renseigné par les informations remontées par SIRENE
    if (
      (this.props.tunnel.sirene && !this.props.tunnel.entreprise) ||
      (this.props.tunnel.sirene && this.props.tunnel.entreprise)
    ) {
      this.props.change(
        "siret",
        siretAvecEspace(this.props.tunnel.sirene.siret)
      );
    }

    // CAS 3 : ENTREPRISE PERSISTEE, MAIS PAS DE SIRENE RENSEIGNE A LA PREMIERE ETAPE
    // dans ce cas, le formulaire serai renseigné par les informations entreprises persistées et potentiellement modifiés par l'utilisateur (sauf le SIRET)
    if (this.props.tunnel.entreprise && !this.props.tunnel.sirene) {
      this.props.change(
        "siret",
        siretAvecEspace(this.props.tunnel.entreprise.siret)
      );
    }
  }

  setErrorForm = () => {
    if (this.props.customError) {
      this.props.setErrorServeurForm(true);
    }
  };

  render() {
    const {
      handleSubmit,
      pristine,
      invalid,
      submitting,
      customError
    } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <label className="titreFormulaire">
          <Trans i18nKey="siret.titre" />
        </label>

        <div className="grid-x grid-margin-x">
          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              inputMode="numeric"
              pattern="\d*"
              name="siret"
              labeltext="Siret"
              validate={[Required, Siret]}
              normalize={NormalizeSiretSpace}
              customError={customError}
              onChange={this.setErrorForm}
              mask={[
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/
              ]}
              guide={false}
            />
          </div>
          <div className="cell medium-6" />
        </div>
        <div className="buttonCreationContainer">
          <YesButton
            text="C'est parti !"
            disabled={invalid || submitting || pristine}
          />
        </div>
      </form>
    );
  }
}

SiretForm.propTypes = {
  customError: PropTypes.bool,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  tunnel: PropTypes.object.isRequired
};

// REDUX + REDUX FORM
const mapStateToProps = (state) => {
  const tunnel = state.tunnel;
  return { tunnel };
};

const mapDispatchToProps = dispatch => ({
  setErrorServeurForm: errorForm => dispatch(setServerErrorForm(errorForm))
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'siretForm' })(SiretForm));

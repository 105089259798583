/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import Slider from '@appigram/react-rangeslider';
import { Button } from 'react-foundation';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

/* Internal imports */
import AdministrationFees from '../../../common/forms/tarification/AdministrationFees';
import FixedFees from './FixedFees';
import Recapitulatifs from '../../recapitulatif/Recapitulatifs';
import YesInputWithoutReduxForm from '../../../../form/InputWithoutReduxForm';
import renderCheckbox from '../../../../DS/inputs/reduxForm/RenderCheckbox';
import { BOOLEAN, TAUX_FRAIS_PERDOB } from '../../../../../common/constants';
import { CHECKBOXSHAPE } from '../../../../../utils/constantes/DS/CHECKBOX';
import { YesButton } from '../../../../form/Button';
import { style } from './modalRevoirInformations.style';

class AssuranceTarificationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReviewInfos: false,
      summarySeen: false,
      tauxFraisCotisationsObligatoires: TAUX_FRAIS_PERDOB.cotisations_obligatoires.fixe,
      tauxFraisVersementsVolontaires: TAUX_FRAIS_PERDOB.versements_volontaires.fixe,
      tauxDerogationFraisEuros: TAUX_FRAIS_PERDOB.tauxDerogationFraisEuros.fixe,
      tauxDerogationFraisUC: TAUX_FRAIS_PERDOB.tauxDerogationFraisUC.fixe,
      isEscompte: props.tunnel.dossier.fraisContrat.commisionnementEscompte || false,
      isLFAC: props.tunnel.dossier.apporteurAffaire.reseau === 'LFAC'
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    if (this.props.tunnel.dossier && this.props.tunnel.dossier.fraisContrat) {
      /* Frais Cotisations Obligatoires */
      this.changerTauxFraisCotisationsObligatoires(
        this.props.tunnel.dossier.fraisContrat.tauxFraisCotisationsObligatoires
      );
      this.props.change(
        'tauxFraisCotisationsObligatoires',
        this.props.tunnel.dossier.fraisContrat.tauxFraisCotisationsObligatoires || TAUX_FRAIS_PERDOB.cotisations_obligatoires.fixe
      );
      /* Frais Versements Volontaires */
      this.changerTauxVersementsVolontaires(
        this.props.tunnel.dossier.fraisContrat.tauxFraisVersementsVolontaires
      );
      this.props.change(
        'tauxFraisVersementsVolontaires',
        this.props.tunnel.dossier.fraisContrat.tauxFraisVersementsVolontaires || TAUX_FRAIS_PERDOB.versements_volontaires.fixe
      );
      /* Frais Euros Volontaires */
      this.changerTauxDerogationFraisEuros(
        this.props.tunnel.dossier.fraisContrat.tauxDerogationFraisEuros
      );
      this.props.change(
        'tauxDerogationFraisEuros',
        this.getTauxFraisPerob()
      );
      /* Frais encours UC */
      this.changerTauxDerogationFraisUC(
        this.props.tunnel.dossier.fraisContrat.tauxDerogationFraisUC
      );
      this.props.change(
        'tauxDerogationFraisUC',
        this.props.tunnel.dossier.fraisContrat.tauxDerogationFraisUC || TAUX_FRAIS_PERDOB.tauxDerogationFraisUC.fixe
      );
      /* Type commissionnement */
      this.props.change(
        'commisionnementEscompte-non',
        !this.state.isEscompte
      );
      this.props.change(
        'commisionnementEscompte-oui',
        this.state.isEscompte
      );

      this.props.change(
        'fraisDossier',
        (this.props.tunnel.dossier.fraisContrat.fraisDossier && this.props.tunnel.dossier.fraisContrat.fraisDossier.toString()) ||
        '500'
      );
    } else {
      this.props.change('fraisDossier', '500');
      this.props.change('tauxDerogationFraisEuros', TAUX_FRAIS_PERDOB.tauxDerogationFraisEuros.fixe);
      this.props.change('tauxDerogationFraisUC', TAUX_FRAIS_PERDOB.tauxDerogationFraisUC.fixe);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isEscompte && !prevState.isEscompte) {
      this.setState({ tauxDerogationFraisEuros: TAUX_FRAIS_PERDOB.tauxDerogationFraisEuros.fixe });
      this.setState({ tauxDerogationFraisUC: TAUX_FRAIS_PERDOB.tauxDerogationFraisUC.fixe });
      this.setState({ tauxFraisCotisationsObligatoires: TAUX_FRAIS_PERDOB.cotisations_obligatoires.fixe });
      this.setState({ tauxFraisVersementsVolontaires: TAUX_FRAIS_PERDOB.versements_volontaires.fixe });
    }
    if (prevState.tauxFraisCotisationsObligatoires !== this.state.tauxFraisCotisationsObligatoires) {
      this.props.change('tauxFraisCotisationsObligatoires', this.state.tauxFraisCotisationsObligatoires);
    }
    if (prevState.tauxDerogationFraisEuros !== this.state.tauxDerogationFraisEuros) {
      this.props.change('tauxDerogationFraisEuros', this.state.tauxDerogationFraisEuros);
    }
    if (prevState.tauxFraisVersementsVolontaires !== this.state.tauxFraisVersementsVolontaires) {
      this.props.change('tauxFraisVersementsVolontaires', this.state.tauxFraisVersementsVolontaires);
    }
    if (prevState.tauxDerogationFraisUC !== this.state.tauxDerogationFraisUC) {
      this.props.change('tauxDerogationFraisUC', this.state.tauxDerogationFraisUC);
    }
  }

  changerTaux = (typeTaux, initialValue, tunnelValue, sliderValue, disabled) => {
    let updatedTaux = initialValue;

    if (!disabled
      && sliderValue
      && !isNaN(sliderValue)) {
      updatedTaux = sliderValue.toFixed(2);
    } else if (!disabled && tunnelValue) {
      updatedTaux = tunnelValue;
    }

    this.setState({
      [typeTaux]: updatedTaux
    })
  };

  changerTauxFraisCotisationsObligatoires = (tunnelValue, value) => {
    this.changerTaux(
      "tauxFraisCotisationsObligatoires",
      TAUX_FRAIS_PERDOB.cotisations_obligatoires.fixe,
      tunnelValue,
      value,
      this.state.isEscompte);
  };

  changerTauxVersementsVolontaires = (tunnelValue, value) => {
    this.changerTaux(
      "tauxFraisVersementsVolontaires",
      TAUX_FRAIS_PERDOB.versements_volontaires.fixe,
      tunnelValue,
      value,
      this.state.isLFAC);
  };

  changerTauxDerogationFraisEuros = (tunnelValue, value) => {
    this.changerTaux(
      "tauxDerogationFraisEuros",
      TAUX_FRAIS_PERDOB.tauxDerogationFraisEuros.fixe,
      tunnelValue,
      value,
      this.state.isEscompte || this.state.isLFAC);
  };

  changerTauxDerogationFraisUC = (tunnelValue, value) => {
    this.changerTaux(
      "tauxDerogationFraisUC",
      TAUX_FRAIS_PERDOB.tauxDerogationFraisUC.fixe,
      tunnelValue,
      value,
      this.state.isEscompte || this.state.isLFAC);
  };

  showReviewInfos = () => this.setState(() => ({ showReviewInfos: true, summarySeen: true }));

  toggleShowReviewInfos = () => this.setState((prevState) => ({ showReviewInfos: !prevState.showReviewInfos }));

  updateCommissionnementPropsToSubmit = ( commissionnementOui, commissionnementNon ) => {
    this.props.change(
      'commisionnementEscompte-oui',
      commissionnementOui
    );
    this.props.change(
      'commisionnementEscompte-non',
      commissionnementNon
    );
  }

  getTauxFraisPerob() {
    return TAUX_FRAIS_PERDOB.tauxDerogationFraisEuros.fixe;
  }
  
  render() {
    const { handleSubmit, tunnel } = this.props;
    const {
      tauxFraisCotisationsObligatoires,
      tauxFraisVersementsVolontaires,
      tauxDerogationFraisUC,
      isEscompte
    } = this.state;

    return (
      <form onSubmit={handleSubmit} noValidate className="fraisContrat">
        <div className="fraisContratCommisionnementBloc">
          <label className="titreFormulaire">
            <Trans i18nKey="fraisContrat.titre" />
          </label>
          <br />
          <label className="titreFormulaire">
            <Trans i18nKey="fraisContrat.titreFormulaire" />
          </label>

          <div className="tunnelRadioBloc">
            <div className="tunnelRadio">
              <Field
                name="commisionnementEscompte-non"
                component={renderCheckbox}
                label={<Trans i18nKey={"fraisContrat.choix.epargne.label"}/>}
                id="linéaire"
                disabled={this.state.isLFAC}
                shape={CHECKBOXSHAPE.ROUND}
                onChange={() => {
                  if (isEscompte) {
                    this.setState({
                      ...this.state,
                      isEscompte: false
                    });
                    this.updateCommissionnementPropsToSubmit(false, true);
                  }
                }}
                checked={!isEscompte}
              />
            </div>
            <div className="tunnelRadio">
              <Field
                name="commisionnementEscompte-oui"
                component={renderCheckbox}
                label={<Trans i18nKey={"fraisContrat.choix.escompte.label"}/>}
                id="escompté"
                disabled={this.state.isLFAC}
                shape={CHECKBOXSHAPE.ROUND}
                onChange={()=>{
                  if (!isEscompte) {
                    this.setState({
                      ...this.state,
                      isEscompte: true
                    })
                    this.updateCommissionnementPropsToSubmit(true,  false);
                  }
                }}
                checked={isEscompte}
              />
            </div>
          </div>
        </div>
          <>
            <div className="fraisContratSliders">
              <div className={isEscompte ? 'fraisContratSliderBlocInactive' : 'fraisContratSliderBloc'}>
                <div>
                  <div className="yes-label">
                    <Trans i18nKey="fraisContrat.slider.cotisationsObligatoires" />
                  </div>
                  <div className="cell sliderFraisContrat">
                    <Slider
                      step={0.05}
                      value={tauxFraisCotisationsObligatoires}
                      orientation="horizontal"
                      onChange={(value) => this.changerTauxFraisCotisationsObligatoires(this.state.tauxFraisCotisationsObligatoires, value)}
                      tooltip={false}
                      min={TAUX_FRAIS_PERDOB.cotisations_obligatoires.plancher}
                      max={TAUX_FRAIS_PERDOB.cotisations_obligatoires.plafond}
                    />
                  </div>
                </div>
                <div>
                  <YesInputWithoutReduxForm
                    disabled
                    className="sliderInput"
                    value={tauxFraisCotisationsObligatoires}
                    euroField={false}
                    onChange={() => {}}
                  />
                  %
                </div>
              </div>

              <div className={this.state.isLFAC ? 'fraisContratSliderBlocInactive' : 'fraisContratSliderBloc'}>
                <div>
                  <div className="yes-label">
                    <Trans i18nKey="fraisContrat.slider.versementsVolontaires" />
                  </div>
                  <div className="cell sliderDispositifAbondement">
                    <Slider
                      step={0.05}
                      value={tauxFraisVersementsVolontaires}
                      orientation="horizontal"
                      onChange={(value) => this.changerTauxVersementsVolontaires(this.state.tauxFraisVersementsVolontaires, value)}
                      tooltip={false}
                      min={TAUX_FRAIS_PERDOB.versements_volontaires.plancher}
                      max={TAUX_FRAIS_PERDOB.versements_volontaires.plafond}
                    />
                  </div>
                </div>
                <div>
                  <YesInputWithoutReduxForm
                    disabled
                    className="sliderInput"
                    value={tauxFraisVersementsVolontaires}
                    euroField={false}
                    onChange={() => {}}
                  />
                  %
                </div>
              </div>
            </div>

            {!isEscompte && <div className="fraisContratSliders">
              <div className={isEscompte || this.state.isLFAC ? 'fraisContratSliderBlocInactive' : 'fraisContratSliderBloc'}>
                <div>
                  <div className="yes-label">
                    <Trans i18nKey="fraisContrat.slider.fraisUC" />
                  </div>
                  <div className="cell sliderTauxFraisUC">
                    <Slider
                      step={0.001}
                      value={tauxDerogationFraisUC}
                      orientation="horizontal"
                      onChange={(value) => this.changerTauxDerogationFraisUC(this.state.tauxDerogationFraisUC, value)}
                      tooltip={false}
                      min={TAUX_FRAIS_PERDOB.tauxDerogationFraisUC.plancher}
                      max={TAUX_FRAIS_PERDOB.tauxDerogationFraisUC.plafond}
                    />
                  </div>
                </div>
                <div>
                  <YesInputWithoutReduxForm
                    disabled
                    className="sliderInput"
                    value={tauxDerogationFraisUC}
                    euroField={false}
                    onChange={() => { }}
                  />
                  %
                </div>
              </div>
            </div>}

            <AdministrationFees />
            <FixedFees
              label={<Trans i18nKey="fraisContrat.fixedFees.fraisEncoursEuro" />}
              inputValue={this.getTauxFraisPerob()+" %"}
              inputName={"fraisEncoursEuro"}
            />
            <FixedFees
              label={<Trans i18nKey="fraisContrat.fixedFees.fraisTransfert" />}
              inputValue={" 1 %"}
              inputName={"fraisTransfert"}
            />
            <FixedFees
              label={<Trans i18nKey="fraisContrat.fixedFees.fraisArrerage" />}
              inputValue={" 2 %"}
              inputName={"fraisArrerage"}
            />
            <FixedFees
              label={<Trans i18nKey="fraisContrat.fixedFees.fraisGestionFondsRente" />}
              inputValue={" 1 % par an"}
              inputName={"fraisGestionFondsRente"}
            />
            <div className="verifierVosInformations">
              <Button className="buttonCreationContainer yes-button" onClick={this.showReviewInfos} type="button">
                <Trans i18nKey="fraisContrat.syntheseSaisie"/>
              </Button>
              <Modal
                isOpen={this.state.showReviewInfos}
                aria={{
                  labelledby: 'informations',
                  describedby: 'Revoir les informations du dossier'
                }}
                shouldCloseOnOverlayClick={true}
                style={style}
              >
                <Recapitulatifs typeDossier={tunnel.dossier.type} />
                <div className="text-center">
                  <YesButton onClick={this.toggleShowReviewInfos} text="VALIDER ET FERMER" />
                </div>
              </Modal>
              {this.state.summarySeen && (
                <Button className="buttonCreationContainer yes-button" type="submit">
                  <Trans i18nKey="Confirmer"/>
                </Button>
              )}
            </div>
          </>
      </form>
    );
  }
}

AssuranceTarificationForm.propTypes = {
  change: PropTypes.func.isRequired,
  tunnel: PropTypes.object
}

// REDUX + REDUX FORM
const selectContratForm = formValueSelector('fraisContratForm');
const mapStateToProps = (state) => ({
  tunnel: state.tunnel,
  fraisDossier: selectContratForm(state, 'fraisDossier'),
  escompteMode: selectContratForm(state, 'commisionnementEscompte') || BOOLEAN.NO
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'fraisContratForm' })(AssuranceTarificationForm));

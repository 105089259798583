export const CONTRACTCATEGORIES = {
  CADRE: "cadre",
  NOCADRE: "noCadre"
}

export const LEGALOBLIGATIONS = {
  LEGALOBLIGATIONS: "legalObligations",
  BEYONDLEGALOBLIGATIONS: "beyondLegalObligations"
}

export const REGIMENTS = {
  DUES: "dues"
}
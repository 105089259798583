/* Built-in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

/* Internal imports */
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';
import { YesDropdown } from '../../../../form/Dropdown';
import { withTranslation } from 'react-i18next';
import { PERIODICITE_OPTIONS, PERIODICITE_TRIMESTRES } from '../../../../../utils/constantes/US/DISPOSITIFABONDEMENT';

const Periodicite = ({ periodicite }) => {
  return (
    <div className="grid-x periodicite">
      <label className="label-checkbox-v2 anciennete-mini-selector">
        <TransHtmlText i18nKey="dispositif.info.periodicite" />
      </label>
      <div className="cell small-8">
        <Field id="periodicite.option" name="periodicite.option" component={YesDropdown} item={PERIODICITE_OPTIONS} />
      </div>

      <div className="cell small-3 small-offset-1">
        {periodicite && periodicite.option === '2' && (
          <Field id="periodicite.trimestre" name="periodicite.trimestre" label="" component={YesDropdown} item={PERIODICITE_TRIMESTRES} />
        )}
      </div>
      {(!periodicite || !periodicite.option || periodicite.option === '0') && (
        <p className="errorMsgTauxDegressif">Vous devez fixer la périodicité.</p>
      )}
      {periodicite && periodicite.option === '2 && !periodicite.trimestre' && (
        <p className="errorMsgTauxDegressif">Vous devez sélectionner le trimestre.</p>
      )}
    </div>
  );
};

Periodicite.propTypes = {
  periodicite: PropTypes.object
};

export default withTranslation()(Periodicite);

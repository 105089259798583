/* Built-in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Trans } from "react-i18next";

/* Interal imports */
import { getSvgEnFonctionDuTenant } from '../../../../../services/TenantService';
import { TARIFS_ANNUEL } from '../../../../../utils/constantes/US/TARIFICATION';

const Tarifications = ({ dispositifPercoi, dispositifPei }) => {

  const getTarificationEntreprise = () =>
      (dispositifPercoi.ouverture ^ dispositifPei.ouverture ? TARIFS_ANNUEL.PEI_OR_PERCOLI.ENTREPRISE : TARIFS_ANNUEL.PEI_AND_PERCOLI.ENTREPRISE);
  const getTarificationBeneficiaires = () =>
      (dispositifPercoi.ouverture ^ dispositifPei.ouverture ? TARIFS_ANNUEL.PEI_OR_PERCOLI.BENEFICIAIRE : TARIFS_ANNUEL.PEI_AND_PERCOLI.BENEFICIAIRE);

  return (
    <div className="ges-tarification-inline-display">
      <div className="ges-tarification-rectangle">
        <div className="logo-container-tarification">
          <img className="logoUsersSmall" src={getSvgEnFonctionDuTenant('/assets/img/icons/', '/entreprise.svg')} alt="entreprise" />
        </div>
        <div className="prix-tarif-rectangle">{getTarificationEntreprise()} €</div>
        <div className="txt-tarif-rectangle">
          <Trans i18nKey={'ges-tarification.tarification.entreprise'} />
        </div>
      </div>

      <div className="separateur-forfaits"> + </div>

      <div className="ges-tarification-rectangle">
        <div className="logo-container-tarification">
          <img className="logoUsersSmall" src={getSvgEnFonctionDuTenant('/assets/img/icons/', '/users-wm.svg')} alt="users" />
        </div>
        <div className="prix-tarif-rectangle">{getTarificationBeneficiaires()} €</div>
        <div className="txt-tarif-rectangle">
          <Trans i18nKey={'ges-tarification.tarification.beneficiaire'} />
        </div>
      </div>
    </div>
  );
};

Tarifications.propTypes = {
  dispositifPei: PropTypes.object.isRequired,
  dispositifPercoi: PropTypes.object.isRequired
};

export default Tarifications;

export const TYPE_ABONDEMENT = {
  ANCIENNETE: 'anciennete',
  VERSEMENT: 'versement'
};
export const ANCIENNETE_MINIMUM = [
  {
    value: '0',
    label: 'Aucune'
  },
  {
    value: '1',
    label: '1 mois'
  },
  {
    value: '2',
    label: '2 mois'
  },
  {
    value: '3',
    label: '3 mois'
  }
];
export const PERIODICITE_OPTIONS = [
  {
    value: 0,
    label: 'Sélectionnez une périodicité'
  },
  {
    value: 1,
    label: 'Les versements volontaires seront abondés toute l\'année'
  },
  {
    value: 2,
    label: 'Les versements volontaires ne seront abondés que sur le trimestre civil suivant'
  }
];
export const PERIODICITE_TRIMESTRES = [
  {
    value: 0,
    label: 'Sélectionnez le trimestre'
  },
  {
    value: 1,
    label: '1er trimestre'
  },
  {
    value: 2,
    label: '2ème trimestre'
  },
  {
    value: 3,
    label: '3ème trimestre'
  },
  {
    value: 4,
    label: '4ème trimestre'
  }
];
export const SENIORITY_STEPS = [
  { label: 'Moins de 1 an', field: 'plafond1an' },
  { label: 'Entre 1 ans et 3 ans', field: 'plafond3an' },
  { label: 'Entre 3 ans et 5 ans', field: 'plafond5an' },
  { label: 'Supérieur à 5 ans', field: 'plafondMax' }
];
/**
 * Dans ce fichier nous envoyons les noms en majuscule pour qu'ils
 * correspondent aux enums dans CITRA
 * @type {*[]}
 */
export const sourcesVersementsPei = [
  {
    label: 'Versements volontaires',
    name: 'VERSEMENT_VOLONTAIRE'
  },
  {
    label: 'Intéressement',
    name: 'INTERESSEMENT'
  },
  {
    label: 'Participation',
    name: 'PARTICIPATION'
  }
];

/**
 * Dans ce fichier nous envoyons les noms en majuscule pour qu'ils
 * correspondent aux enums dans CITRA
 * @type {*[]}
 */
export const sourcesVersementsPercoi = [
  ...sourcesVersementsPei,
  {
    label: 'Jours CET',
    name: 'JOURS_CET'
  }
];

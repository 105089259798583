import { PREFIX_BACK } from '../common/Configuration';
import { fetch } from './ResourcesService';

export const mettreAjourFichePersonneMorale = ({ valuesAenvoye }) =>
  fetch(true, `${PREFIX_BACK}fiches/fichePersonneMorale`, {
    method: 'PUT',
    body: JSON.stringify(valuesAenvoye)
  });

export const mettreAjourMoyensPaiement = (uid, values) =>
  fetch(true, `${PREFIX_BACK}fiches/fichePersonneMorale/${uid}/moyensPaiement`, {
    method: 'PUT',
    body: JSON.stringify(values)
  });

export const NormalizeNumber = (value, previousValue) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, '');
};

export const NormalizeNumberSpace = (value, previousValue) => {
  return value
    .replace(/[^\d]/g, '')
    .replace(/ /g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const NormalizeTelephoneSpace = (value) => {
  const telephone = value.replace(/[^\d]/g, '').replace(/ /g, '');

  if (telephone.length >= 10) {
    return `${telephone.slice(0, 2)} ${telephone.slice(2, 4)} ${telephone.slice(4, 6)} ${telephone.slice(6, 8)} ${telephone.slice(
      8,
      10
    )}`;
  }
  return telephone.replace(/(\d{2})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
};

export const NormalizeIban = (value) => {
  if (!value) {
    return value.toUpperCase();
  }

  const onlyNums = value.replace(/[^[A-Za-z0-9]/g, '').toUpperCase();

  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length < 5) {
    return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 4)}`;
  }

  if (onlyNums.length >= 5 && onlyNums.length < 10) {
    return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 4)} ${onlyNums.slice(4)}`;
  }

  if (onlyNums.length >= 10 && onlyNums.length < 15) {
    return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 4)} ${onlyNums.slice(4, 9)} ${onlyNums.slice(9)}`;
  }

  if (onlyNums.length >= 15 && onlyNums.length < 26) {
    return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 4)} ${onlyNums.slice(4, 9)} ${onlyNums.slice(9, 14)} ${onlyNums.slice(14)}`;
  }

  return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 4)} ${onlyNums.slice(4, 9)} ${onlyNums.slice(9, 14)} ${onlyNums.slice(
    14,
    25
  )} ${onlyNums.slice(25, 27)}`;
};

export const NormalizeBic = (value) => {
  if (!value) {
    return value.toUpperCase();
  }

  const onlyNums = value.replace(/[^[A-Za-z0-9]/g, '').toUpperCase();

  if (onlyNums.length <= 4) {
    return onlyNums;
  }
  if (onlyNums.length < 7) {
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 6)}`;
  }
  return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 6)} ${onlyNums.slice(6, 8)}`;
};

export const NormalizeSiretSpace = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length < 7) {
    return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)}`;
  }

  if (onlyNums.length >= 7 && onlyNums.length < 10) {
    return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)} ${onlyNums.slice(6, 9)}`;
  }

  return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)} ${onlyNums.slice(6, 9)} ${onlyNums.slice(9, 14)}`;
};

export const NormalizeSirenSpace = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length < 7) {
    return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)}`;
  }

  if (onlyNums.length >= 7 && onlyNums.length < 10) {
    return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)} ${onlyNums.slice(6, 9)}`;
  }

  return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)} ${onlyNums.slice(6, 9)}`;
};

export const NormalizeDate = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length < 2) {
    return `${onlyNums}/__/____`;
  }

  if (onlyNums.length === 2) {
    return `${onlyNums.slice(0, 2)}/__/____`;
  }

  if (onlyNums.length >= 2 && onlyNums.length < 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
  }

  if (onlyNums.length === 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/`;
  }

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4)}`;
};

export function isValidTextLength(limitTextLength, text) {
  return limitTextLength >= text.length
}

export function isValidPostalCode(postalCode) {
  return postalCode && /^(([0-8]\d)|(9[0-5]))\d{3}$/.test(postalCode)
}

export function isValidPercentage(percentage) {
  return percentage && (percentage <= 100);
}
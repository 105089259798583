/* built-in imports */
import React from 'react';

/* third-party imports */
import { createSvgIcon } from "@mui/material";

/* Internal imports */
import { ReactComponent as TooltipQuestionMarkIconSvg } from '../svg/tooltipQuestion.svg';

export default createSvgIcon(
    <TooltipQuestionMarkIconSvg />,
    'TooltipQuestionMarkIcon'
);
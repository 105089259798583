import {
  UPDATE_MASSE_SALARIALE_AND_EFFECTIF,
  UPDATE_MASSE_SALARIALE,
  UPDATE_EFFECTIF,
  UPDATE_OBJECTIF,
  UPDATE_ABONDEMENT_PEE,
  UPDATE_ABONDEMENT_PERCO,
  UPDATE_INTERESSEMENT,
  UPDATE_PARTICIPATION,
  UPDATE_BUDGET,
  UPDATE_EPARGNE_SALARIALE_DISPOSITIF,
  UPDATE_EPARGNE_SALARIALE_COUT_ENTREPRISE,
  UPDATE_EPARGNE_SALARIALE_SOMME_PERCUE_SALARIE,
  UPDATE_PRIME_ATTRIBUEE,
  UPDATE_PRIME_CLASSIQUE_COUT_ENTREPRISE,
  UPDATE_PRIME_CLASSIQUE_SOMME_PERCUE_SALARIE,
  UPDATE_TAUX_CHARGES_PATRONALES,
  UPDATE_TAUX_CHARGES_SALARIALES,
  UPDATE_TAUX_MARGINAL_DIMPOSITION,
  OBJECTIF_MOTIVER,
  GET_ECRAN_SYNTHESE,
  GET_ECRAN_DETAIL_ENTREPRISE,
  GET_ECRAN_DETAIL_SALARIE,
  UPDATE_ANIMER_GRAPHIQUES,
  UPDATE_FAIRE_BENEFICE,
  UPDATE_VOIR_INFO_PLAFOND_INT_ATTEINT
} from "./simulateurActions";
import {
  TAUX_CHARGES_SALARIALES_DEFAUT,
  TAUX_CHARGES_PATRONALES_DEFAUT,
  TAUX_MARGINAL_DIMPOSITION_DEFAULT
} from "../configuration";

/**
 * Initial state, à mettre dans le champ 'simulateur' du state global
 */

export const initialState = {
  masseSalariale: 0,
  effectif: 1,
  objectif: OBJECTIF_MOTIVER,
  budget: {
    maxPossible: 0,
    parPersonne: 0
  },
  epargneSalariale: {
    dispositif: {
      abondementPEE: 0,
      abondementPERCO: 0,
      interessement: 0,
      participation: 0,
      resteAffecter: 0,
      enveloppeIndividuelle: 0
    },
    coutEntreprise: {
      forfaitSocial: {
        abondementPEE: 0,
        abondementPERCO: 0,
        interessement: 0,
        participation: 0,
        total: 0
      },
      abondementPEE: 0,
      abondementPERCO: 0,
      interessement: 0,
      participation: 0,
      coutTotalEntreprise: 0
    },
    sommePercueSalarie: {
      csgCrds: 0,
      sommePercueSalarie: 0
    }
  },
  primeClassique: {
    primeAttribuee: 0,
    coutEntreprise: {
      chargesPatronales: 0,
      coutTotalEntreprise: 0
    },
    sommePercueSalarie: {
      chargesSocialesSalarie: 0,
      csgCrds: 0,
      impotSurRevenu: 0,
      sommePercueSalarie: 0
    }
  },
  taux: {
    tauxChargesSalariales: TAUX_CHARGES_SALARIALES_DEFAUT,
    tauxChargesPatronales: TAUX_CHARGES_PATRONALES_DEFAUT,
    tauxMarginalDImposition: TAUX_MARGINAL_DIMPOSITION_DEFAULT
  },
  ecran: {
    synthese: true,
    detailEntreprise: false,
    detailSalarie: false,
    voirInformationPlafondInteressementAtteint: false,
    animerGraphique: false
  },
  benefice: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MASSE_SALARIALE_AND_EFFECTIF: {
      return {
        ...state,
        masseSalariale: action.masseSalariale,
        effectif: action.effectif
      };
    }

    case UPDATE_MASSE_SALARIALE: {
      return {
        ...state,
        masseSalariale: action.masseSalariale
      };
    }

    case UPDATE_EFFECTIF: {
      return {
        ...state,
        effectif: action.effectif
      };
    }

    case UPDATE_OBJECTIF: {
      return {
        ...state,
        objectif: action.objectif
      };
    }

    case UPDATE_ABONDEMENT_PEE: {
      let epargneSalariale = {
        ...state.epargneSalariale,
        dispositif: {
          ...state.epargneSalariale.dispositif,
          abondementPEE: action.abondementPEE,
          enveloppeIndividuelle: action.enveloppeIndividuelle
        }
      };
      return {
        ...state,
        epargneSalariale
      };
    }

    case UPDATE_ABONDEMENT_PERCO: {
      let epargneSalariale = {
        ...state.epargneSalariale,
        dispositif: {
          ...state.epargneSalariale.dispositif,
          abondementPERCO: action.abondementPERCO,
          enveloppeIndividuelle: action.enveloppeIndividuelle
        }
      };
      return {
        ...state,
        epargneSalariale
      };
    }

    case UPDATE_INTERESSEMENT: {
      let epargneSalariale = {
        ...state.epargneSalariale,
        dispositif: {
          ...state.epargneSalariale.dispositif,
          interessement: action.interessement,
          enveloppeIndividuelle: action.enveloppeIndividuelle,
          depassement20pourcentMasseSalariale:
            action.depassement20pourcentMasseSalariale
        }
      };
      return {
        ...state,
        epargneSalariale
      };
    }

    case UPDATE_PARTICIPATION: {
      let epargneSalariale = {
        ...state.epargneSalariale,
        dispositif: {
          ...state.epargneSalariale.dispositif,
          participation: action.participation,
          enveloppeIndividuelle: action.enveloppeIndividuelle
        }
      };
      return {
        ...state,
        epargneSalariale
      };
    }

    case UPDATE_BUDGET: {
      return {
        ...state,
        budget: action.budget
      };
    }

    case UPDATE_EPARGNE_SALARIALE_DISPOSITIF: {
      let epargneSalariale = {
        ...state.epargneSalariale,
        dispositif: action.dispositifEpargneSalariale
      };

      return {
        ...state,
        epargneSalariale
      };
    }

    case UPDATE_EPARGNE_SALARIALE_COUT_ENTREPRISE: {
      let epargneSalariale = {
        ...state.epargneSalariale,
        coutEntreprise: action.coutEntrepriseEpargneSalariale
      };
      return {
        ...state,
        epargneSalariale
      };
    }

    case UPDATE_EPARGNE_SALARIALE_SOMME_PERCUE_SALARIE: {
      let epargneSalariale = {
        ...state.epargneSalariale,
        sommePercueSalarie: action.sommePercueSalarie
      };
      return {
        ...state,
        epargneSalariale
      };
    }

    case UPDATE_PRIME_ATTRIBUEE: {
      let primeClassique = {
        ...state.primeClassique,
        primeAttribuee: action.primeAttribuee
      };
      return {
        ...state,
        primeClassique
      };
    }

    case UPDATE_PRIME_CLASSIQUE_COUT_ENTREPRISE: {
      let primeClassique = {
        ...state.primeClassique,
        coutEntreprise: action.coutEntreprise
      };
      return {
        ...state,
        primeClassique
      };
    }

    case UPDATE_PRIME_CLASSIQUE_SOMME_PERCUE_SALARIE: {
      let primeClassique = {
        ...state.primeClassique,
        sommePercueSalarie: action.sommePercueSalarie
      };
      return {
        ...state,
        primeClassique
      };
    }

    case UPDATE_TAUX_CHARGES_PATRONALES: {
      let taux = {
        ...state.taux,
        tauxChargesPatronales: action.tauxChargesPatronales
      };

      return {
        ...state,
        taux
      };
    }

    case UPDATE_TAUX_CHARGES_SALARIALES: {
      let taux = {
        ...state.taux,
        tauxChargesSalariales: action.tauxChargesSalariales
      };

      return {
        ...state,
        taux
      };
    }

    case UPDATE_TAUX_MARGINAL_DIMPOSITION: {
      let taux = {
        ...state.taux,
        tauxMarginalDImposition: action.tauxMarginalDImposition
      };

      return {
        ...state,
        taux
      };
    }

    case GET_ECRAN_SYNTHESE: {
      return {
        ...state,
        ecran: action.ecran
      };
    }
    case GET_ECRAN_DETAIL_ENTREPRISE: {
      return {
        ...state,
        ecran: action.ecran
      };
    }
    case GET_ECRAN_DETAIL_SALARIE: {
      return {
        ...state,
        ecran: action.ecran
      };
    }
    case UPDATE_ANIMER_GRAPHIQUES: {
      let ecran = {
        ...state.ecran,
        animerGraphique: action.animerGraphique
      };
      return {
        ...state,
        ecran: ecran
      };
    }
    case UPDATE_VOIR_INFO_PLAFOND_INT_ATTEINT: {
      let ecran = {
        ...state.ecran,
        voirInformationPlafondInteressementAtteint:
          action.voirInformationPlafondInteressementAtteint
      };
      return {
        ...state,
        ecran: ecran
      };
    }
    case UPDATE_FAIRE_BENEFICE: {
      return {
        ...state,
        benefice: action.benefice
      };
    }
    default:
      return state;
  }
};

/* Built in import */
import React from 'react';

/* Internal imports */
import RoutesUtils from '../../../../utils/routes/RoutesUtils';
import { ACTIVITY_DOMAINS, FOLDER_TYPES } from '../../../../utils/constantes/ROUTES/ROUTES';
import { PrivateAppRoute } from '../../../../helpers/routes/PrivateAppRoute';
import { getMaroquinerieArborescence } from './MaroquinerieArborescence';
import TunnelLayout from '../../../../layouts/default/TunnelLayout';

const buildRoute = (path, component, activityDomain, folderType) => {
  const routeProps = RoutesUtils.getRoutePropsByDomainAndType(component, TunnelLayout, activityDomain, ACTIVITY_DOMAINS.MAROQUINERIE.NAME, folderType, FOLDER_TYPES.EPARGNE);

  return (
    <PrivateAppRoute
      exact
      path={path}
      layout={routeProps.layout}
      component={routeProps.component}
    />);
};

const MaroquineriePrivateRoutes = (activityDomain, folderType) => {
  let routesArray = getMaroquinerieArborescence();
  return (
    [
      routesArray.map(route => {
        return(buildRoute(route.path, route.component, activityDomain, folderType))
      })
    ]
  )
};

export default MaroquineriePrivateRoutes;
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const SwitchRoot =  styled(Switch, { name: 'Switch' })(() => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: 'var(--cn-color-primary)',
      '& + .MuiSwitch-track': {
        backgroundColor: 'var(--cn-color-primary)'
      }
    }
  }
}));

export default SwitchRoot
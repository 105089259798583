import {
  addMonth,
  dateToString,
  addDay,
  addYear
} from "../common/FunctionsUtils";

// Un exercice non connu = cas où la dernière clôture comptable remonte à moins de 6 mois (i.e. Date du jour - JJ/MM/AAAA < 6 mois).
export const isExerciceNonConnu = dateCloture => {
  let dateDuJourMoins6mois = addMonth(new Date(), -6);

  if (dateCloture >= dateDuJourMoins6mois) {
    return true;
  }
  return false;
};

// Option A ) du (JJ/MM/AAAA) -1 an + 1 jour au JJ/MM/AAAA (n’afficher que si : date du jour - JJ/MM/AAAA est inférieure à 6 mois)
export const getOptionA = dateCloture => {
  let dateMoins1anPlusUnJour = addDay(dateCloture, 1);
  dateMoins1anPlusUnJour = addYear(dateMoins1anPlusUnJour, -1);

  return {
    dateDebut: dateToString(dateMoins1anPlusUnJour),
    dateFin: dateToString(dateCloture)
  };
};

// Option B ) du (JJ/MM/AAAA)+1 jour au JJ/MM/AAAA+1 an
export const getOptionB = dateCloture => {
  let dateCloturePlusUnJour = addDay(dateCloture, 1);
  let dateCloturePlusUnAn = addYear(dateCloture, 1);

  return {
    dateDebut: dateToString(dateCloturePlusUnJour),
    dateFin: dateToString(dateCloturePlusUnAn)
  };
};

// Option C ) du (JJ/MM/AAAA)+ 1 jour + 1 an au JJ/MM/AAAA+2 ans
export const getOptionC = dateCloture => {
  let dateCloturePlusUnJourPlusUnAn = addDay(dateCloture, 1);
  dateCloturePlusUnJourPlusUnAn = addYear(dateCloturePlusUnJourPlusUnAn, 1);

  let dateCloturePlusDeuxAns = addYear(dateCloture, 2);

  return {
    dateDebut: dateToString(dateCloturePlusUnJourPlusUnAn),
    dateFin: dateToString(dateCloturePlusDeuxAns)
  };
};

// Récupration des items pour le dropdown et d'une map ayant pour clé : la date de début valable (pour la date de cloture comptable)
// et pour valeur l'option (A, B ou C)
export const getOptions = dateCloture => {
  let optionsDropDown = [];
  let mapOptions = new Map();

  if (isExerciceNonConnu(dateCloture)) {
    optionsDropDown.push({
      label: getLabelDates(getOptionA(dateCloture)),
      value: "A"
    });
    mapOptions.set(getOptionA(dateCloture).dateDebut, "A");
  }

  optionsDropDown.push({
    label: getLabelDates(getOptionB(dateCloture)),
    value: "B"
  });
  mapOptions.set(getOptionB(dateCloture).dateDebut, "B");

  optionsDropDown.push({
    label: getLabelDates(getOptionC(dateCloture)),
    value: "C"
  });
  mapOptions.set(getOptionC(dateCloture).dateDebut, "C");

  return { optionsDropDown, mapOptions };
};

export const getLabelDates = dates => {
  return "du " + dates.dateDebut + " au " + dates.dateFin;
};

export const getDatesDebutFinOption = (dateCloture, option) => {
  switch (option) {
    case "B":
      return getOptionB(dateCloture);
    case "C":
      return getOptionC(dateCloture);
    default:
      return getOptionA(dateCloture);
  }
};

// construire liste mode de répartition
export const construireChoixModesDeRepartition = () => {
  return [
    {
      label: "proportionnellement aux salaires perçus",
      value: "PROPORTIONNEL_SALAIRE"
    },
    { label: "de manière uniforme", value: "UNIFORME" },
    {
      label: "proportionnellement à la durée de présence dans l’entreprise",
      value: "PROPORTIONNEL_PRESENCE"
    }
  ];
};

// construire liste mode de répartition
export const construireFormulesDerogatoires = () => {
  return [
    {
      label: "50 % du bénéfice net comptable",
      value: "FORMULE_DEROGATOIRE_50_POURCENT_BENEFICE_NET_COMPTABLE"
    },
    {
      label: "Bénéfice net comptable diminué de 5 % des capitaux propres",
      value: "FORMULE_DEROGATOIRE_BENEFICE_NET_COMPTABLE_DIM_5_POURCENT_CP"
    },
    {
      label: "Bénéfice net fiscal diminué de 5 % des capitaux propres",
      value: "FORMULE_DEROGATOIRE_BENEFICE_NET_FISCAL_DIM_5_POURCENT_CP"
    },
    {
      label: "50 % du bénéfice net fiscal",
      value: "FORMULE_DEROGATOIRE_50_POURCENT_BENEFICE_NET_FISCAL"
    }
  ];
};

// Construction de la map des duréees
export const construireChoixDurees = () => {
  let valuesOptionsDureeWithout1year = [
    { label: "Indéterminé", value: 0 },
    { label: "3 ans", value: 3 },
    { label: "2 ans", value: 2 }
  ];
  let valuesOptionsDuree = [
    { label: "Indéterminé", value: 0 },
    { label: "3 ans", value: 3 },
    { label: "2 ans", value: 2 },
    { label: "1 ans", value: 1 }
  ];

  let mapDurees = new Map();

  // Option A
  let optionsAduree = new Map();

  // Une Map PAR Option. true : exercice non connu, false : exercice  connu
  optionsAduree.set(true, valuesOptionsDureeWithout1year);
  optionsAduree.set(false, null);

  // Option B
  let optionsBduree = new Map();
  optionsBduree.set(true, valuesOptionsDuree);
  optionsBduree.set(false, valuesOptionsDureeWithout1year);

  // Option C
  let optionsCduree = new Map();
  optionsCduree.set(true, valuesOptionsDuree);
  optionsCduree.set(false, valuesOptionsDuree);

  mapDurees.set("A", optionsAduree);
  mapDurees.set("B", optionsBduree);
  mapDurees.set("C", optionsCduree);

  return mapDurees;
};

// Construction de la map des formules
export const construireChoixFormules = () => {
  // Une Map PAR Option. CLE -> true : exercice non connu, false : exercice connu
  // valeur -> true : formule dérogatoire possible, sinon false
  let mapFormules = new Map();

  let optionsAFormules = new Map();
  optionsAFormules.set(true, false);
  optionsAFormules.set(false, null);

  let optionsBFormules = new Map();
  optionsBFormules.set(true, true);
  optionsBFormules.set(false, false);

  let optionsCFormules = new Map();
  optionsCFormules.set(true, true);
  optionsCFormules.set(false, true);

  mapFormules.set("A", optionsAFormules);
  mapFormules.set("B", optionsBFormules);
  mapFormules.set("C", optionsCFormules);

  return mapFormules;
};

import IBAN from 'iban';
import { MSG_FORMAT_INVALIDE, TAUX_FRAIS_PERDOB } from './constants';
import { SENIORITY_STEPS } from '../utils/constantes/US/DISPOSITIFABONDEMENT';
import { isValidPostalCode } from '../utils/validators/inputs';
import { dateFromString, nombre, nombreAvirgule, nombreSansEspaces, setEffectDate } from './FunctionsUtils';
import { plafondAbondementPEE, plafondAbondementPERCO } from '../modules/simulateur/fonctions';
import {
  bicRegex,
  codePostalRegex,
  emailRegex,
  ibanRegex,
  nafRegex,
  nirRegex,
  rnaRegex,
  sirenRegex,
  siretRegex,
  telephoneFrRegex
} from '../utils/regexUtils';

/* A décommenter pour la nouvelle option */
// import IBAN from 'iban';

// import { nombre, nombreSansEspaces } from './FunctionsUtils';
// import { SENIORITY_STEPS, MSG_FORMAT_INVALIDE } from './constants';
// import { plafondAbondementPEE, plafondAbondementPERCO } from '../modules/simulateur/fonctions';

export const Required = (value) => (value ? undefined : 'Ce champ est obligatoire');

export const DateContratRequired = (value) => (value ? undefined : 'Vous devez fixer la date de prise d’effet du contrat');

export const RequiredOr0 = (value) => (value || value === 0 ? undefined : 'Ce champ est obligatoire');

const maxLength = (max) => (value) => value && value.length > max ? `Ce champ doit être inférieur à ${max}` : undefined;

export const MaxLength50 = maxLength(50);
export const MaxLength40 = maxLength(40);
export const MaxLength100 = maxLength(100);

export const Email = (value) => (value && !emailRegex.test(value) ? 'Adresse e-mail invalide' : undefined);

export const PasswordSixCaracteres = (value) =>
  value && !/^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/i.test(value)
    ? 'Le mot de passe doit contenir au minimum 6 caractères mélangeant chiffres et lettres'
    : undefined;

export const Password = (value) =>
  value && !matchPasswordPattern(value)
    ? '10 caractères minimum, dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial'
    : undefined;

//Message d'erreur différent pour changement de mot de passe
export const ResetPassword = (value) =>
  value && !matchPasswordPattern(value)
    ? 'Le mot de passe doit contenir 10 caractères minimum, dont au moins une minuscule, une majuscule et un caractère spécial.' +
      'Il doit aussi être différent du précédent mot de passe.'
    : undefined;

//Vérification de la présence du caratère spécial parmi la liste indiquée
export const SpecialChar = (value) =>
  value && !/^(?=.*?[!@#$%^&*()\-_=+{};:,<.>])[A-Z\u00C0-\u00DCa-z\u00E0-\u00FC\d!@#$%^&*()\-_=+{};:,<.>]{10,}$/.test(value)
    ? 'Votre mot de passe doit contenir un caratère spécial parmi cette liste : !@#$%^&*()-_=+{};:,<.>'
    : undefined;

export const Telephone = (value) =>
  value && !telephoneFrRegex.test(value.replace(/[^\d]/g, '')) ? 'Merci de saisir un numéro de téléphone portable' : undefined;

export const PasswordsMatch = (value, allValues) =>
  value !== allValues.motDePasse ? 'Les deux mots de passe doivent être identiques' : undefined;

export const Effectif = (value) => {
  if (nombre(value.replace(/[^\d]/g, '')) <= 1) {
    return 'La mise en place d’un dispositif d’épargne salariale est possible dès 1 salarié en plus du chef d’entreprise.';
  }
  return undefined;
};

export const InferieurAMasseSalariale = (value, allValues, props) => {
  if (value && nombre(value.replace(/[^\d]/g, '')) > allValues.masseSalariale) {
    return 'Le budget de participation ne peut être supérieur ou égal à votre masse salariale.';
  }
  return undefined;
};

export const MasseSalariale = (value) => {
  if (nombre(value.replace(/[^\d]/g, '')) <= 1000) {
    return 'Vous devez saisir un chiffre supérieur à 1000€ pour permettre la simulation.';
  }
  return undefined;
};

export const NbSalaries = (value) => {
  let nbSalaries = value;
  if (typeof value !== 'number') {
    nbSalaries = nombre(value.replace(/[^\d]/g, ''));
  }

  if (nbSalaries < 1 || nbSalaries >= 100000) {
    return 'Le nombre de salariés doit être compris entre 1 et 100 000.';
  }
  return undefined;
};

export const Siret = (value) =>
  value && !siretRegex.test(value.replace(/[^\d]/g, '')) ? 'Le format est invalide (14 chiffres)' : undefined;

export const Siren = (value) =>
  value && !sirenRegex.test(value.replace(/[^\d]/g, '')) ? 'Le format est invalide (9 chiffres)' : undefined;

export const Rna = (value) =>
  value && !rnaRegex.test(value.replace(/[^[W|w][\d]{9}$/g, '')) ? "Le format est invalide ('W' suivi de 9 chiffres)" : undefined;

export const Iban = (value) => {
  if (value) {
    const trimValue = value.replace(/[^[A-Za-z0-9]/g, '');
    if (trimValue.length !== 27) {
      return 'Merci de compléter votre IBAN.';
    } else if (!ibanRegex.test(trimValue) || !IBAN.isValid(trimValue)) {
      return 'Cet IBAN ne nous semble pas correct. Merci de le vérifier.';
    }
  }
  return undefined;
};

export const Bic = (value) => {
  if (value) {
    if (value.length !== 11 && value.length !== 8) {
      return 'Merci de compléter votre BIC.';
    }
    if (!bicRegex.test(value)) {
      return 'Ce BIC ne nous semble pas correct. Merci de le vérifier.';
    }
  }
  return undefined;
};

export const IbanUppercase = (value) => {
  if (!isUpperCase(value)) {
    return 'Merci de mettre tous les caractères en majuscule';
  }
  return undefined;
};

export const NIR = (value) => (value && !nirRegex.test(value) ? MSG_FORMAT_INVALIDE : undefined);

export const CodePostal = (value) => (value && !codePostalRegex.test(value) ? MSG_FORMAT_INVALIDE : undefined);

export const CodeNaf = (value) => (value && !nafRegex.test(value) ? MSG_FORMAT_INVALIDE : undefined);

export const identifiantRNA = (value) => (value && !rnaRegex.test(value) ? MSG_FORMAT_INVALIDE : undefined);

export const DateFr = (value) => (value && !isValidDate(value) ? MSG_FORMAT_INVALIDE : undefined);

export const DateJourMois = (value) => (value && !isValidDateMois(value) ? MSG_FORMAT_INVALIDE : undefined);

export const DateAuFutur = (value) =>
  value && !isFutureDate(value) ? 'La date ne peut pas être antérieure à la date du jour' : undefined;

export const DateDuJourOuFutur = (value) =>
    value && !isFutureDateOrPresentDate(value) ? 'La date ne peut pas être antérieure à la date du jour' : undefined;

export const DateAuPasse = (value) => (value && isFutureDate(value) ? 'La date doit être antérieure à la date du jour' : undefined);

export const DateDansLes400Jours = (value) =>
  value && !isDansLes400Jours(value) ? "Votre dernière date de clôture fiscale doit remonter à moins d'un an" : undefined;

export const DateInLastYear = (value) =>
  value && !isOlderThanAYearDate(value) ? 'La date doit être comprise dans les 12 mois précédents' : undefined;

export const fraisDeDossierEntre0et500 = (value) => {
  const formattedValue = typeof value === 'string' ? nombreSansEspaces(value) : value;
  return formattedValue <= 500 && formattedValue >= 0
    ? undefined
    : 'La valeur des frais de dossier doit être comprise entre 0 et 500€';
};

function isValidDateMois(dateString) {
  // Exclude date
  var excludedDates = ['31/02', '30/02', '31/04', '31/06', '31/09', '31/11'];

  // First check for the pattern
  if (!/^\d{2}\/\d{2}$/.test(dateString) || excludedDates.indexOf(dateString) !== -1) {
    return false;
  }

  // Parse the date parts to integers
  var parts = dateString.split('/');
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);

  if (day < 1 || day > 31) {
    return false;
  }

  if (month < 1 || month > 12) {
    return false;
  }

  return true;
}

function isDansLes400Jours(dateString) {
  var dateParts = dateString.split('/');
  var dateAtester = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

  var dateDuJour = new Date();
  var dateDuJourMoins400jours = new Date();
  dateDuJourMoins400jours.setDate(dateDuJourMoins400jours.getDate() - 400);

  return dateAtester >= dateDuJourMoins400jours && dateAtester <= dateDuJour;
}

function isValidDate(dateString) {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  var parts = dateString.split('/');
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return false;
  }

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

function isFutureDate(dateString) {
  // Get current date
  var today = new Date();
  // Parse the date parts
  var parts = dateString.split('/');
  var givenDate = new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
  return givenDate.valueOf() >= today.valueOf();
}

function isFutureDateOrPresentDate(dateString) {
  const today = new Date();
  const parts = dateString.split('/');
  const givenDate = new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
  const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  return givenDate.getTime() >= todayWithoutTime.getTime();
}

function isOlderThanAYearDate(dateString) {
  var dateParts = dateString.split('/');
  var dateAtester = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

  var dateAYearBefore = new Date();
  dateAYearBefore.setFullYear(dateAYearBefore.getFullYear() - 1);

  return dateAtester >= dateAYearBefore;
}

function isUpperCase(str) {
  return str === str.toUpperCase();
}

//Cette regex vérifie la présence d'au moins un chiffre, une lettre minuscule (avec accent ou non)
//une lettre majuscule (avec accent ou non) et autorise les caractères spéciaux parmi la liste suivante
function matchPasswordPattern(password) {
  return /^(?=.*[A-Z\u00C0-\u00DC])(?=.*[a-z\u00E0-\u00FC])(?=.*\d)[A-Z\u00C0-\u00DCa-z\u00E0-\u00FC\d!@#$%^&*()\-_=+{};:,<.>]{10,}$/.test(
    password
  );
}

export const isValidNumberString = (string) =>
  !/^[0-9]*$/.test(string) ? 'Ce champ ne doit être composé que de chiffres' : undefined;

export const isValidDecimalString = (string) =>
  !/^[0-9.,]*$/.test(string) ? 'Ce champ ne doit être composé que de chiffres' : undefined;

export const isValidNoNumberString = (string) =>
  !/^[^0-9]*$/.test(string) ? 'Ce champ ne doit pas comporter de chiffres' : undefined;

export const isMaxOneHundred = (string) => (+string <= 100 ? undefined : 'Le pourcentage ne peut dépasser 100');

export const isPercentageValue = (value) => {
  let returnedValue = +value.replace(',', '.').replace(' %', '');
  return returnedValue <= 100 ? undefined : 'Le pourcentage ne peut dépasser 100';
};

export const isBelowGivenDecimal = (string) =>
  string &&
  (nombreAvirgule(string) <= TAUX_FRAIS_PERDOB.tauxDerogationFraisEuros.plafond
    ? undefined
    : `Le pourcentage ne peux dépasser ${TAUX_FRAIS_PERDOB.tauxDerogationFraisEuros.plafond}`);

export const isBicValid = (value) =>
  !value || value.match(/^[0-9A-Za-z]{8,11}$/) ? undefined : 'Le BIC doit contenir entre 8 et 11 caractères';

export const lowerThanPass = (value, allValues, _, name) => {
  const maxValue = name.match(/.*percol.*/)
    ? plafondAbondementPERCO(allValues.abnUnilateral ? allValues.plafondAbnUnilateral : undefined)
    : plafondAbondementPEE();
  return value <= maxValue ? undefined : `Ce montant est supérieur au maximum de ${maxValue}`;
};

export const isHigher = (value, allValues, _, name) => {
  const index = SENIORITY_STEPS.map((s) => s.field).indexOf(name.split('.')[1]);
  const type = name.split('[')[1].split(']')[0];
  if (index > 0) {
    const label = SENIORITY_STEPS[index - 1].field;
    return parseInt(value) > parseInt(allValues.anciennete[type][label])
      ? undefined
      : `Doit être supérieur à ${parseInt(allValues.anciennete[type][label])} €`;
  }
};

export const isNotNull = (value) => (+value > 0 ? undefined : 'Le montant ne peux pas être nul');
export const isCodePostal = (value) => (isValidPostalCode(value) ? undefined : "Le code postal saisie n'est pas valide");
export const iPercentage = (value) => {
  return value <= 100 ? undefined : `La valeur saisie est supérieur à 100%`;
};
export const isGreaterThanAccountingClosingDate = (value, allValues, props) => {
  return dateFromString(value) >= dateFromString(setEffectDate(props.tunnel.entreprise.complement.dateClotureComptableWithYear))
    ? undefined
    : 'La date saisie est antérieure à la date pré-remplie';
};
export const requiredValidator = [Required];

import React, { Component } from "react";
import ChangementMotDePasseOublieForm from "./ChangementMotDePasseOublieForm";
import { connect } from "react-redux";
import { changerMotDePasseOublie } from "../../../../services/CompteService";
import { Redirect } from "react-router-dom";
import { ROUTE_SIRET, FLAG_REDIRECT } from "../../../../common/Configuration";
import { setLoader } from "../../../../modules/tunnel/redux/tunnelActions";
import { setToken } from "../../../../services/ResourcesService";
import { getTenantUppercase } from "../../../../services/TenantService";

class ChangementMotDePasseOubliePage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      redirect: false,
      disabledForm: true,
      token: window.location.search.split("token=")[1]
    };
    this.props.handlerIllustration("illu_mdp-oublie");
  }

  submit(values) {
    this.setState({
      errorMsg: ""
    });

    this.props.setLoader(true);

    if (values.nouveauMotDePasse !== values.nouveauMotDePasseConfirme) {
      this.setState({
        errorMsg: "Les deux mots de passe doivent être identiques"
      });
    } else {
      values.token = this.state.token;
      values.tenant = getTenantUppercase();

      changerMotDePasseOublie({ values })
        .then(token => {
          setToken(token.jwt);
          this.setState({
            redirect: true
          });
        })
        .catch(error => {
          if (error && error.message) {
            this.setState({
              errorMsg: error.message
            });
          }
        })
        .then(() => this.props.setLoader(false));
    }
  }

  render() {
    const { redirect, errorMsg } = this.state;

    if (redirect) {
      return <Redirect to={ROUTE_SIRET + FLAG_REDIRECT} />;
    }

    return (
      <div className="grid-x grid-margin-x">
        <div className="cell medium-12">
          <h2>Réinitialisation de votre mot de passe</h2>

          <ChangementMotDePasseOublieForm
            onSubmit={this.submit}
            customError={errorMsg}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  setLoader: actif => dispatch(setLoader(actif))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangementMotDePasseOubliePage);

import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Required, Email } from "../../../../common/Validators";
import YesInput from "../../../form/Input";
import { YesButton } from "../../../form/Button";
import { setEmailDejaExistant } from "../../../../modules/tunnel/redux/tunnelActions";
import { connect } from "react-redux";

class DemandeChangementMotDePasseForm extends Component {
  componentDidMount() {
    if (this.props.tunnel.emailDejaExistant) {
      this.props.change("adresseEmail", this.props.tunnel.emailDejaExistant);
      this.props.setEmailDejaExistant(null);
    }
  }

  render() {
    const { handleSubmit, pristine, invalid, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="grid-x grid-margin-x">
          <div className="cell medium-12">
            <Field
              component={YesInput}
              type="text"
              name="adresseEmail"
              labeltext="Adresse e-mail"
              validate={[Required, Email]}
            />
          </div>
        </div>
        <div className="buttonCreationContainer">
          <YesButton
            text="Réinitialiser mon mot de passe"
            disabled={invalid || submitting || pristine}
          />
        </div>
      </form>
    );
  }
}

DemandeChangementMotDePasseForm = reduxForm({
  form: "demandeChangementMotDePasseForm"
})(DemandeChangementMotDePasseForm);

const mapDispatchToProps = dispatch => ({
  setEmailDejaExistant: email => dispatch(setEmailDejaExistant(email))
});

DemandeChangementMotDePasseForm = connect(
  state => {
    const tunnel = state.tunnel;
    return { tunnel };
  },
  mapDispatchToProps
)(DemandeChangementMotDePasseForm);

export default DemandeChangementMotDePasseForm;

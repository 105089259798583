/* built-in imports */
import React, { useEffect } from 'react';

/* external imports */
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions';
import DoneIcon from '@mui/icons-material/Done';
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'
import Slide from '@mui/material/Slide';
import Step from '@mui/material/Step'
import Stepper from '@mui/material/Stepper'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/* Internal imports */
import Button from '../../DS/inputs/Button';
import DialogTitle from '../../DS/feedback/dialogs/DialogTitle'
import Grid from '@mui/material/Grid';
import MuiDialogContent from '../../DS/feedback/dialogs/DialogContent';
import QontoConnector from '../../DS/navigation/stepper/connectors/QontoConnector'
import QontoStepIcons from '../../DS/navigation/stepper/step_icons/QontoStepIcons'
import StepLabel from '../../DS/navigation/stepper/StepLabel'
import Transition from '../../DS/utils/transition/slide';
import Typography from '../../DS/data-display/Typography';
import WorkAdvicesSheetsCguForm from '../form/WorkAdvicesSheetsCguForm';
import WorkAdvicesSheetsContractForm from '../form/WorkAdvicesSheetsContractForm';
import WorkAdvicesSheetsEmployeesForm from '../form/WorkAdvicesSheetsEmployeesForm';
import WorkAdvicesSheetsModel from '../../../models/WorkAdvicesSheetsModel';
import WorkAdvicesSheetsObservations from '../form/WorkAdvicesSheetsObservationsForm';
import { getInitialState } from '../../../utils/US/components/workAdvicesSheetsModal/workAdvicesSheetsModal';
import { isContractFormErrors, isSubscriptionReasonsError } from '../../../utils/US/components/workAdvicesSheetsModal/workAdvicesSheetsContractForm';
import { isRegimentSelectedError } from '../../../utils/US/components/workAdvicesSheetsModal/workAdvicesSheetsEmployeesForm/employeesSubForm';
import { updateWorkAdvicesSheets } from '../../../reducers/work_advices_sheets/workAdvicesSheetsActions';
import {
  isContractorInfosErrors
} from '../../../utils/US/components/workAdvicesSheetsModal/workAdvicesSheetsEmployeesForm/contractorSubForm';
import {
  isEmployeesSubscriptionErrors
} from '../../../utils/US/components/workAdvicesSheetsModal/workAdvicesSheetsEmployeesForm/employeesSubscriptionSubForm';

const submitTrad = (t, tradKey, buttonKey) => t(`${tradKey}.${buttonKey}`);
let slideDirection = 'right',
    submitKey      = 'buttons.next';

const WorkAdvicesSheetsModal = (
  {
    onClose,
    open,
    handleSubmit,
    workAdvicesSheets,
    updateWorkAdvicesSheets,
    dossier,
    ...other
  }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  let isUpdateFormLoading = false,
    initialStateCleared = getInitialState(new WorkAdvicesSheetsModel()),
    initialState = initialStateCleared;

  if(workAdvicesSheets){
    isUpdateFormLoading = workAdvicesSheets.loading;
    initialState = getInitialState(workAdvicesSheets);
  }

  const { t } = useTranslation(),
    tradKey = 'components.US.modals.work-advices-sheets',
    muiDialogContentRef = React.createRef(),
    [showErrors, setShowErrors] = React.useState(false),
    [isFirstWorkAdvicesSheets, setIsFirstWorkAdvicesSheets] = React.useState(initialState.isFirstWorkAdvicesSheets),
    [workAdvicesContract, setWorkAdvicesContract] = React.useState(initialState.workAdvicesContract),
    [employeesInfos, setEmployeesInfos] = React.useState(initialState.employeesInfos),
    [observationsInfos, setObservationsInfos] = React.useState(initialState.observationsInfos),
    [modalTimer, setModalTimer] = React.useState(null);

  const {
      isRetirementContract,
      retirementContractOptions,
      subscriptionReasons,
    } = workAdvicesContract,
    {
      isContractor,
      isEmployeesRegiment,
      isSignatory,
      isAllEmployeesSubscribe,
      contractCategorySelected,
      contractSubscriptionInfos,
      contractorInfos,
      regimentInfos,
    } = employeesInfos,
    {
      legalObligationSelected,
      particularObservations,
      particularObservationsPensionGuarantees,
    } = observationsInfos,
    formErrors = {
      isEmployeesInfosErrors: false,
    },
    steps = [
      t(`${tradKey}.steps.cgu`),
      t(`${tradKey}.steps.contract`),
      t(`${tradKey}.steps.employees`),
      t(`${tradKey}.steps.observations`),
      t(`${tradKey}.steps.finish`)
    ];

  const handleBack = () => {
    slideDirection = 'right';
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    muiDialogContentScrollTop();
  };

  const handleSkip = async () => {
    slideDirection = 'left';
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    muiDialogContentScrollTop();
    setShowErrors(false);
    if(activeStep === 3) {
      updateWorkAdvicesSheets(
        {isFirstWorkAdvicesSheets, workAdvicesContract, employeesInfos, observationsInfos},
        dossier.uid,
        t,
        submitSuccess
      )
    }
  };

  const handleCguFormChange = (event) => {
    setIsFirstWorkAdvicesSheets(event.target.checked);
  }

  const handleIsRetirementContractChange = (event) => {
    const checked = event.target.checked;
    setWorkAdvicesContract({
      ...workAdvicesContract,
      retirementContractOptions: checked ? workAdvicesContract.retirementContractOptions : initialStateCleared.workAdvicesContract.retirementContractOptions,
      isRetirementContract: checked
    })
  }

  const handleRetirementContractOptionsChange = (event, key) => {
    setWorkAdvicesContract({
      ...workAdvicesContract,
      retirementContractOptions: {
        ...retirementContractOptions,
        [key]: key === 'otherContract' ? event.target.value : event.target.checked,
      }
    });
  }

  const handleSubscriptionsReasonsChange = (event, key) => {
    setWorkAdvicesContract({
      ...workAdvicesContract,
      subscriptionReasons: {
        ...subscriptionReasons,
        [key]: event.target.checked,
      }
    });
  }

  const handleIsContractorChange = (event) => {
    setEmployeesInfos({
      ...employeesInfos,
      isContractor: event.target.checked
    });
  }

  const handleContractorInfosChange = (event, key) => {
    setEmployeesInfos({
      ...employeesInfos,
      contractorInfos: {
        ...contractorInfos,
        [key]: event.target.value,
      },
    });
  }

  const handleIsEmployeesRetirementChange = (event) => {
    const checked = event.target.checked;
    setEmployeesInfos({
      ...employeesInfos,
      regimentInfos: {
        ...employeesInfos.regimentInfos,
        regimentSelected: checked ? employeesInfos.regimentInfos.regimentSelected : initialStateCleared.employeesInfos.regimentInfos.regimentSelected
      },
      isEmployeesRegiment: checked
    });
  }

  const handleRegimentInfosChange = (event, key) => {
    setEmployeesInfos({
      ...employeesInfos,
      regimentInfos: {
        ...regimentInfos,
        [key]: event.target.value,
      },
    });
  }

  const handleIsAllEmployeesSubscribe = (event) => {
    const checked = event.target.checked;
    setEmployeesInfos({
      ...employeesInfos,
      contractCategorySelected: initialStateCleared.employeesInfos.contractCategorySelected,
      contractSubscriptionInfos: initialStateCleared.employeesInfos.contractSubscriptionInfos,
      isAllEmployeesSubscribe: checked,
    });
  }

  const handleContractSubscriptionCadreChange = (event, cadreKey, artKey, valueKey) => {
    let valToUpdate = valueKey === 'employeesCount'
      ? {
        employeesCount: event.target.value
      }
      : {
        checked: event.target.checked,
        employeesCount: 0
      };

    setEmployeesInfos({
      ...employeesInfos,
      contractSubscriptionInfos: {
        ...contractSubscriptionInfos,
        [cadreKey]: {
          ...contractSubscriptionInfos[cadreKey],
          [artKey]: {
            ...contractSubscriptionInfos[cadreKey][artKey],
            ...valToUpdate
          }
        }
      }
    });
  }

  const handleContractSubscriptionNoCadreChange = (event, noCadreKey, artKey, valueKey) => {
    let valToUpdate = valueKey === 'employeesCount'
      ? {
        employeesCount: event.target.value
      }
      : {
        checked: event.target.checked,
        employeesCount: 0
      };

    setEmployeesInfos({
      ...employeesInfos,
      contractSubscriptionInfos: {
        ...contractSubscriptionInfos,
        [noCadreKey]: {
          ...contractSubscriptionInfos[noCadreKey],
          [artKey]: {
            ...contractSubscriptionInfos[noCadreKey][artKey],
            ...valToUpdate
          }
        }
      }
    });
  }

  const handleIsSignatoryChange = (event) => {
    setEmployeesInfos({
      ...employeesInfos,
      isSignatory: event.target.checked
    });
  }

  const handleOtherContractChange = (event, cadreKey) => {
    setEmployeesInfos({
      ...employeesInfos,
      contractSubscriptionInfos: {
        ...contractSubscriptionInfos,
        [cadreKey]: {
          ...contractSubscriptionInfos[cadreKey],
          other: event.target.value
        }
      }
    });
  }

  const handleContractCategorySelectedChange = (event) => {
    setEmployeesInfos({
      ...employeesInfos,
      contractCategorySelected: event.target.value,
    });
  }

  const handleLegalObligationSelected = (event) => {
    setObservationsInfos({
      ...observationsInfos,
      legalObligationSelected: event.target.value,
    })
  }

  const handleParticularObservationsChange = (event, observationKey) => {
    setObservationsInfos({
      ...observationsInfos,
      [observationKey]: event.target.value,
    })
  }

  const reinitHandleSubmit = () => {
    handleSubmit();
    reinitModal();
  }

  const onCloseModal = () => {
    if(activeStep === 4 && !isUpdateFormLoading) {
      reinitHandleSubmit();
      return;
    }
    onClose();
  }

  const reinitModal = () => {
    submitKey = 'buttons.next';
    setActiveStep(0);
  }

  const submitSuccess = () => {
    setModalTimer(setTimeout(() => {
      reinitHandleSubmit();
    }, 3000));
  }

  const isErrors = function() {
    switch (activeStep) {
      case 1:
        submitKey = 'buttons.next';
        if (isRetirementContract) {
          return isContractFormErrors(retirementContractOptions, subscriptionReasons)
        }
        return isSubscriptionReasonsError(subscriptionReasons)
      case 2:
        submitKey = 'buttons.next';
        return isRegimentSelectedError(isEmployeesRegiment, regimentInfos.regimentSelected)
          || isContractorInfosErrors(contractorInfos)
          || isEmployeesSubscriptionErrors(isAllEmployeesSubscribe, contractSubscriptionInfos)
          || !isSignatory;
      case 3:
        submitKey = 'buttons.validate';
        return !observationsInfos.legalObligationSelected
      default:
        return false
    }
  }

  const handleEmployeesInfosErrors = (value) => {
    formErrors.isEmployeesInfosErrors = value
  }

  const muiDialogContentScrollTop = () => {
    if(muiDialogContentRef.current) {
      muiDialogContentRef.current.scrollTo(0, 0);
    }
  }

  useEffect(() => {
    return () => clearTimeout(modalTimer);
  })

  return (
    <Dialog
      sx={{ bgColor: 'var(--cn-color-mask-contrasted)' }}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth='md'
      onClose={onCloseModal}
      open={open}
    >
      <DialogTitle sx={{ textAlign: 'center', m: 0, p: 2 }} {...other}>
        {t(`${tradKey}.title`)}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 1,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <MuiDialogContent
        sx={{ overflowY: 'unset' }}
      >
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcons}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </MuiDialogContent>
      <MuiDialogContent sx={{ overflowX: 'hidden' }} dividers ref={muiDialogContentRef}>
        {activeStep === 0 &&
          <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit timeout={500}>
            <Box component="div">
              <WorkAdvicesSheetsCguForm
                isFirstWorkAdvicesSheets={isFirstWorkAdvicesSheets}
                isFirstWorkAdvicesSheetsChange={handleCguFormChange}
              />
            </Box>
          </Slide>
        }
        {activeStep === 1 &&
          <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit timeout={500}>
            <Box component="div">
              <WorkAdvicesSheetsContractForm
                isRetirementContract={isRetirementContract}
                retirementContractOptions={retirementContractOptions}
                subscriptionReasons={subscriptionReasons}
                showErrors={showErrors}
                handleIsRetirementContractChange={handleIsRetirementContractChange}
                handleRetirementContractOptionsChange={handleRetirementContractOptionsChange}
                handleSubscriptionsReasonsChange={handleSubscriptionsReasonsChange}
              />
            </Box>
          </Slide>
        }
        {activeStep === 2 &&
          <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit timeout={500}>
            <Box component="div">
              <WorkAdvicesSheetsEmployeesForm
                isContractor={isContractor}
                isSignatory={isSignatory}
                isAllEmployeesSubscribe={isAllEmployeesSubscribe}
                contractCategorySelected={contractCategorySelected}
                contractSubscriptionInfos={contractSubscriptionInfos}
                contractorInfos={contractorInfos}
                isEmployeesRegiment={isEmployeesRegiment}
                regimentInfos={regimentInfos}
                showErrors={showErrors}
                handleIsContractorChange={handleIsContractorChange}
                handleContractorInfosChange={handleContractorInfosChange}
                handleIsEmployeesRetirementChange={handleIsEmployeesRetirementChange}
                handleRegimentInfosChange={handleRegimentInfosChange}
                handleEmployeesInfosErrors={handleEmployeesInfosErrors}
                handleContractSubscriptionCadreChange={handleContractSubscriptionCadreChange}
                handleContractSubscriptionNoCadreChange={handleContractSubscriptionNoCadreChange}
                handleIsAllEmployeesSubscribe={handleIsAllEmployeesSubscribe}
                handleIsSignatoryChange={handleIsSignatoryChange}
                handleOtherContractChange={handleOtherContractChange}
                handleContractCategorySelectedChange={handleContractCategorySelectedChange}
                isEmployeesSubscriptionErrors = {() => isEmployeesSubscriptionErrors(isAllEmployeesSubscribe, contractSubscriptionInfos)}
              />
            </Box>
          </Slide>
        }
        {activeStep === 3 &&
          <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit timeout={500}>
            <Box component="div">
              <WorkAdvicesSheetsObservations
                legalObligationSelected={legalObligationSelected}
                particularObservations={particularObservations}
                showErrors={showErrors}
                particularObservationsPensionGuarantees={particularObservationsPensionGuarantees}
                handleLegalObligationSelected={handleLegalObligationSelected}
                handleParticularObservationsChange={handleParticularObservationsChange}
              />
            </Box>
          </Slide>
        }
        {activeStep === 4 &&
          <Grid container>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              {isUpdateFormLoading
                ? <CircularProgress color="inherit" size="3rem" />
                : <DoneIcon color="success" fontSize="large" />
              }
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '1em' }}>
              <Typography align='center'>{t(`${tradKey}.validation-${isUpdateFormLoading ? 'loading' : 'msg'}`)}</Typography>
            </Grid>
          </Grid>
        }
      </MuiDialogContent>
      {activeStep !== 4 &&
        <DialogActions>
          <Box
            component="div"
            sx={{
              display: 'inline',
              flexGrow: 1,
              justifyContent: 'flex-start'
            }}
          >
            {activeStep > 0 &&
              <Button
                onClick={handleBack}
                sx={{
                  color: 'var(--cn-color-medium-emphasis)'
                }}
              >
                {t(`${tradKey}.buttons.back`)}
              </Button>
            }
          </Box>
          <Box
            component="div"
            sx={{
              display: 'inline',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              onClick={async () => {
                if(isErrors()) {
                  setShowErrors(true);
                  return
                }
                await handleSkip()
              }}
              sx={{
                color: 'var(--cn-color-high-emphasis)'
              }}
            >
              {submitTrad(t, tradKey, submitKey)}
            </Button>
          </Box>
        </DialogActions>
      }
    </Dialog>
  )
}

WorkAdvicesSheetsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dossier: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,

}

export default connect(
  ({ workAdvicesSheets }) => ({
    workAdvicesSheets,
  }),
  { updateWorkAdvicesSheets }
)(WorkAdvicesSheetsModal);
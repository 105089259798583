/* Built in imports */
import React from 'react';

/* Internal imports */
import AccesApporteurPage from '../../pages/public_pages/AccesApporteurPage';
import AppRoute from '../../helpers/routes/AppRoute';
import ChangementMotDePasseOubliePage from '../../components/yes/compte/changementMotDePasseOublie/ChangementMotDePasseOubliePage';
import CompteLayout from '../../layouts/default/CompteLayout';
import ConnexionPage from '../../components/yes/compte/connexion/ConnexionPage';
import CreationComptePage from '../../components/yes/compte/creationCompte/CreationComptePage';
import DemandeChangementMotDePassePage from '../../components/yes/compte/demandeChangementMotDePasse/DemandeChangementMotDePassePage';
import Home from '../../components/yes/home/Home';
import SimulateurPage from '../../components/yes/simulateur/SimulateurPage';
import StaticSiteLayout from '../../layouts/default/StaticSiteLayout';
import VersionViewer from '../../pages/public_pages/versionViewer/VersionViewer';
import { isRoutesAuthentificationEnabled } from '../../services/TenantService';
import {
  ROUTE_ACCES_CONTRIBUTEUR,
  ROUTE_CHANGEMENT_MDP,
  ROUTE_CONNEXION,
  ROUTE_CREATION_COMPTE,
  ROUTE_ACCUEIL_GALAXY,
  ROUTE_DEMANDE_CHANGEMENT_MDP,
  ROUTE_HOME,
  ROUTE_SIMULATEUR,
  ROUTE_DOSSIERS_GALAXY,
  ROUTE_VERSION_VIEWER
} from '../../common/Configuration';

const getRoutesAuthentification = () => {
  if (isRoutesAuthentificationEnabled()) {
    return [
      <AppRoute exact path={ROUTE_CONNEXION} layout={CompteLayout} component={ConnexionPage} />,
      <AppRoute exact path={ROUTE_CREATION_COMPTE} layout={CompteLayout} component={CreationComptePage} />,
      <AppRoute exact path={ROUTE_DEMANDE_CHANGEMENT_MDP} layout={CompteLayout} component={DemandeChangementMotDePassePage} />,
      <AppRoute exact path={ROUTE_CHANGEMENT_MDP} layout={CompteLayout} component={ChangementMotDePasseOubliePage} />
    ];
  }
  return [
    <AppRoute exact path={ROUTE_ACCES_CONTRIBUTEUR} component={AccesApporteurPage} />,
    <AppRoute exact path={ROUTE_VERSION_VIEWER} component={VersionViewer} />
  ];
};

const PublicRoutesDefault = () => {
  return[
      <AppRoute exact path={ROUTE_HOME} layout={StaticSiteLayout} component={Home} />,
      <AppRoute exact path={ROUTE_SIMULATEUR} layout={StaticSiteLayout} component={SimulateurPage} />,
      <AppRoute exact path={ROUTE_ACCUEIL_GALAXY} component={() => {
          window.location.replace(process.env.REACT_APP_STATIC_URL + ROUTE_ACCUEIL_GALAXY);
          return null;
        }
      } />,
      <AppRoute exact path={ROUTE_DOSSIERS_GALAXY} component={() => {
          window.location.replace(process.env.REACT_APP_STATIC_URL + ROUTE_DOSSIERS_GALAXY);
          return null;
        }
      } />
    ].concat(getRoutesAuthentification());
}

export default PublicRoutesDefault();
import { LOCATION_CHANGE } from "react-router-redux";
import { createMiddleware } from "redux-beacon";
import GoogleAnalytics, { trackPageView } from "@redux-beacon/google-analytics";

const eventsMap = {
  [LOCATION_CHANGE]: trackPageView(action => ({
    page: action.payload.pathname
  }))
};

const gaMiddleware = createMiddleware(eventsMap, GoogleAnalytics());

export { gaMiddleware };

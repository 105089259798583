const OutlinedSelectFormControl = {
  m: 1,
  '& .MuiInputBase-root': {
    color: 'var(--cn-typo-in-filled-input)',
    backgroundColor: 'var(--cn-ges-input-bg-color)',
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--cn-color-black)'
      }
    },
    '& .MuiSvgIcon-root': {
      margin: 0
    }
  }
};

const OutlinedSelectMenuItem = {
  '&.Mui-selected': {
    backgroundColor: 'var(--cn-color-primary-opacity-08)',
    '&:focus': {
      backgroundColor: 'var(--cn-color-primary-opacity-1)'
    },
    '&:hover': {
      backgroundColor: 'var(--cn-color-primary-opacity-14)'
    }
  }
};

const styles = {
  OutlinedSelectFormControl,
  OutlinedSelectMenuItem
}

export default styles;
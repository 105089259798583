import React, { Component } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { YesChoix } from "../../../components/form/Choix";
import YesInput from "../../../components/form/Input";
import { connect } from "react-redux";
import { NormalizeNumberSpace } from "../../../common/Normalizers";
import { nombreAvecEspaces, nombre } from "../../../common/FunctionsUtils";
import { InferieurAMasseSalariale } from "../../../common/Validators";
import {
  TAUX_MAX_PARTICIPATION
} from "../../../modules/simulateur/configuration";
import { getSvgEnFonctionDuTenant } from "../../../services/TenantService";

class Participation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false
    };
  }

  isDisabled = () => {
    const { montantConnu, benefice, montantParticipation } = this.props;
    if (
      !benefice ||
      (benefice === "oui" && !montantConnu) ||
      (benefice === "oui" && montantConnu === "oui" && !montantParticipation)
    ) {
      return true;
    }
    return false;
  };

  isClicked = () => {
    this.setState({
      submitted: true
    });
  };

  render() {
    const {
      handleSubmit,
      montantConnu,
      benefice,
      pristine,
      invalid,
      submitting
    } = this.props;
    const { submitted } = this.state;

    this.props.change(
      "masseSalariale",
      nombre(Math.floor(this.props.simulateur.masseSalariale))
    );

    return (
      <div className="grid-x grid-margin-x center-item-simu-container">
        <div className="cell medium-12 titres-simulateur">
          <h2>
            Votre entreprise compte{" "}
            <span className="yes-green">{this.props.simulateur.effectif}</span>{" "}
            salariés
          </h2>
          <h3>
            Se pose alors la question de la mise en place d’un accord de
            participation.
          </h3>
          <h3 className="h3-with-bottom-marge">
            Une entreprise qui emploie plus de 50 salariés, sur 12 mois
            consécutifs ou non, lors des 3 derniers exercices, se doit de mettre
            en place un accord de participation.
          </h3>
        </div>

        <div className="cell medium-4 illuSimulateurContainer">
          <img
            className="illu-participation"
            src={getSvgEnFonctionDuTenant(
              "/assets/img/illustrations/",
              "/illu-participation.svg"
            )}
            alt="objectif epargne salariale"
          />
        </div>

        <div className="cell medium-8">
          <form
            autoComplete="off"
            className="formParticipation"
            onSubmit={handleSubmit}
            noValidate
          >
            <label className="titreFormulaire">
              Est-ce que votre entreprise réalise du bénéfice ?
            </label>

            <div className="grid-x grid-margin-x">
              <div className="cell medium-12 choixBenefice">
                <Field
                  id="benefice"
                  name="benefice"
                  component={YesChoix}
                  type="radio"
                  value="oui"
                  label="Oui"
                />
                <Field
                  id="noBenefice"
                  name="benefice"
                  component={YesChoix}
                  type="radio"
                  value="non"
                  label="Non"
                />
              </div>

              {benefice === "oui" && (
                <div className="cell medium-12 yes-rectangle">
                  <label className="titreFormulaire">
                    Connaissez-vous le montant approximatif de votre enveloppe
                    de participation ?
                  </label>

                  <div className="formule-participation-card">
                    <p>
                      Pour information, la formule de calcul de la participation
                      :
                    </p>
                    <p>
                      <img
                        src="/assets/img/illustrations/formuleParticipation.svg"
                        className="formule-participation"
                        alt="formule participation"
                      />
                    </p>
                  </div>

                  <div className="cell medium-12 connaitreMontantParticipation">
                    <div className="montantParticipation">
                      <Field
                        id="connaitreMontant"
                        name="connaitreMontant"
                        component={YesChoix}
                        type="radio"
                        value="oui"
                        label="Oui"
                      />

                      {montantConnu === "oui" && (
                        <div className="divInputMontantParticipation">
                          <Field
                            id="montantParticipation"
                            component={YesInput}
                            type="text"
                            inputMode="numeric"
                            pattern="\d*"
                            name="montantParticipation"
                            validate={[InferieurAMasseSalariale]}
                            labeltext=""
                            placeholder=""
                            normalize={NormalizeNumberSpace}
                            euroField={true}
                          />
                        </div>
                      )}
                    </div>
                    <div className="montantParticipation">
                      <Field
                        name="connaitreMontant"
                        component={YesChoix}
                        type="radio"
                        value="non"
                        id="hypotheseMontant"
                        label="Non, prenons comme hypothèse 5% de votre masse salariale : "
                      />
                      {montantConnu === "non" && (
                        <span className="yes-gras yes-green yes-montant-participation-calcule">
                          {nombreAvecEspaces(
                            Math.floor(
                              0.05 * this.props.simulateur.masseSalariale
                            )
                          )}{" "}
                          €
                        </span>
                      )}
                    </div>
                  </div>
                  <p className="indication-plafond-participation">
                    Le plafond individuel de la participation est de{" "}
                    {nombreAvecEspaces(
                      Math.floor(this.props.PASS * TAUX_MAX_PARTICIPATION)
                    )}
                    €/an, si le résultat de votre formule est supérieur à ce
                    montant multiplié par votre effectif, le montant
                    excédentaire non distribué sera reporté sur les exercices
                    suivants.
                  </p>
                </div>
              )}

              {benefice === "non" && (
                <div className="cell medium-12 yes-rectangle">
                  <p>
                    Le montant de la participation étant fonction du bénéfice de
                    l’entreprise, vous ne distribuerez pas de participation
                    cette année.
                  </p>
                </div>
              )}
            </div>

            <div className="buttonContainer buttonDecouvrirRecommandation">
              <button
                className="yes-button"
                type="submit"
                disabled={
                  invalid || submitting || pristine || this.isDisabled()
                }
                onClick={this.isClicked}
              >
                Continuer
              </button>

              {submitted && (
                <p className="yes-indication indication-maj-donnees">
                  Il vous est possible de modifier vos données à tout moment de
                  la simulation. Pour que nous prenions bien en compte vos
                  nouvelles données, n'oubliez pas de valider vos informations.
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

// C'est partie va injecter dans les props la valeur d'un champ du formulaire récupéré depuis Redux
// Si le montant connu (true), alors on affiche un champ input

const selectorParticipationForm = formValueSelector("participation");

const mapStateToProps = (state) => ({
  montantConnu: selectorParticipationForm(state, "connaitreMontant"),
  benefice: selectorParticipationForm(state, "benefice"),
  montantParticipation: selectorParticipationForm(state, "montantParticipation"),
  montantParticipationEstimee: selectorParticipationForm(state, "montantParticipationEstimee"),
  simulateur: state.simulateur,
  PASS: state.citraData?.pass?.currentPass
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "participation" })(Participation));

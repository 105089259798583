export const MOYENS_PAIEMENT = {
  NOM: 'NOM',
  LIEN: 'LIEN',
  PAYS: 'PAYS',
  VILLE: 'VILLE',
  PRENOM: 'PRENOM',
  ADRESSE: 'ADRESSE',
  PAYS_FONDS: 'PAYS_FONDS',
  CODE_POSTAL: 'CODE_POSTAL',
  MOTIF_TIERS: 'MOTIF_TIERS',
  NATURE_PAYEUR: 'NATURE_PAYEUR',
  MOTIF_ETABLISSEMENT: 'MOTIF_ETABLISSEMENT',
  IS_COMPTE_SOUSCRIPTEUR: 'IS_COMPTE_SOUSCRIPTEUR',
  ETABLISSEMENT_HORS_FRANCE: 'ETABLISSEMENT_HORS_FRANCE',
  IBAN: 'iban',
  BIC: 'bic'
};

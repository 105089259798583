/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import ParticipationForm from '../../../../components/US/epargne_salariale/forms/participation/ParticipationForm';
import { URLS_EPARGNE } from '../../../../utils/constantes/ROUTES/EPARGNE';
import { isParticipationVolontaireEnabled } from '../../../../services/TenantService';
import { mettreAjourParticipation } from '../../../../services/DossierService';
import { stringToBoolean, cloneValues } from '../../../../common/FunctionsUtils';
import {
  setRetourRoute,
  setInformationsUtilisateur,
  setParticipation,
  setParticipationVolontaire
} from '../../../../modules/tunnel/redux/tunnelActions';

class EpargneParticipationPage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    // On modifie la route lorsque l'utilisateur clique le bouton Retour
    if (!this.props.tunnel.dossier.planExistant.accordInteressementEnPlace) {
      this.props.setRetourRoute(URLS_EPARGNE.INTERESSEMENT.substring(1));
    }
    else {
      this.props.setRetourRoute(URLS_EPARGNE.DISPOSITIF_ABONDEMENT.substring(1));
    }

    this.state = {
      redirect: false || this.props.tunnel.dossier.planExistant.accordParticipationEnPlace
    };
  }

  submit(values) {
    let valuesAenvoye = Object.assign(cloneValues(values), {
      participationActive: stringToBoolean(values.participation),
      optionParticipation: {
        enabled: stringToBoolean(values.optionParticipation.enabled),
        type: values.optionParticipation.formule && +values.optionParticipation.formule.type,
        repartition: values.optionParticipation.repartition
          ? {
              type: +values.optionParticipation.repartition.type,
              pourcent: values.optionParticipation.repartition.pourcent
                ? {
                    uniforme: +values.optionParticipation.repartition.pourcent.uniforme || 0,
                    salaire: +values.optionParticipation.repartition.pourcent.salaire || 0,
                    temps: +values.optionParticipation.repartition.pourcent.temps || 0
                  }
                : {}
            }
          : {},
        duree: values.optionParticipation.duree
          ? {
              type: +values.optionParticipation.duree.type,
              duree: +values.optionParticipation.duree.duree
            }
          : {},
        applicationFiscale: values.optionParticipation.applicationFiscale
      }
    });

    if (this.props.tunnel.entreprise.complement.nombreSalaries < 50 && isParticipationVolontaireEnabled()) {
      valuesAenvoye = Object.assign(valuesAenvoye, {
        dateDebut: this.props.tunnel.participationVolontaire.dates.dateDebut,
        dateFin: this.props.tunnel.participationVolontaire.dates.dateFin
      });
    }

    mettreAjourParticipation(this.props.tunnel.entreprise.complement.nombreSalaries, this.props.tunnel.dossier.uid, {
      valuesAenvoye
    })
      .then((dossier) => {
        if (this.props.tunnel.entreprise.complement.nombreSalaries < 50 && isParticipationVolontaireEnabled()) {
          this.props.setParticipationVolontaire(dossier.participationVolontaire);
        } else {
          this.props.setParticipation(dossier.participation);
        }

        this.setState({
          redirect: true
        });
      })
      .catch((error) => {
        if (error && error.message) {
          this.setState({
            errorMsg: error.message
          });
        }
      });
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={URLS_EPARGNE.KYC} />;
    }

    return (
      <div>
        <ParticipationForm onSubmit={this.submit} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setInformationsUtilisateur: (utilisateur) => dispatch(setInformationsUtilisateur(utilisateur)),
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setParticipation: (participation) => dispatch(setParticipation(participation)),
  setParticipationVolontaire: (participationVolontaire) => dispatch(setParticipationVolontaire(participationVolontaire))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EpargneParticipationPage);

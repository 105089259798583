/* Built in imports */
import React from 'react';

/* Third party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

/* Internal imports */
import { YesCheckboxV2 } from '../../../../form/CheckboxV2';
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';

const AbondementToggler = ({ config, classDisableOuverture, name }) => (
  <div className={`checkboxMiseEnPlaceAbondement ${classDisableOuverture} grid-x align-middle`}>
    <div className="cell small-6">
      <Field component={YesCheckboxV2} type="checkbox" name={name} id={name} />
      <label htmlFor={name} className="label-checkbox-v2">
        <TransHtmlText i18nKey="dispositif.info.abondement.link" params={{ config: config }} />
      </label>
    </div>
  </div>
);

AbondementToggler.propTypes = {
  config: PropTypes.object,
  classDisableOuverture: PropTypes.string,
  name: PropTypes.string.isRequired
};

export default AbondementToggler;

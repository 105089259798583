import { BOOLEAN, COUNTRY } from '../../../common/constants';
import { stringToBoolean } from '../../../common/FunctionsUtils';
import { PPE_FORM_FIELD } from '../../constantes/US/PPEFORM';

export const hasPersonne = (personne) => (personne ? `${personne}.` : '');

export const changePpeFields = (ppe, change, personne) => {
  if (ppe) {
    const { pays, fonction, finFonction } = ppe;

    fonction && change(`${hasPersonne(personne)}${PPE_FORM_FIELD.FONCTION}`, fonction);
    finFonction && change(`${hasPersonne(personne)}${PPE_FORM_FIELD.FIN_FONCTION}`, finFonction);

    change(`${hasPersonne(personne)}${PPE_FORM_FIELD.IS_PPE}`, BOOLEAN.YES);
    change(`${hasPersonne(personne)}${PPE_FORM_FIELD.PAYS}`, JSON.stringify(pays ? pays : COUNTRY.FRANCE));
  } else {
    change(`${hasPersonne(personne)}${PPE_FORM_FIELD.IS_PPE}`, BOOLEAN.NO);
    change(`${hasPersonne(personne)}${PPE_FORM_FIELD.PAYS}`, JSON.stringify(COUNTRY.FRANCE));
  }
};

export const changeProchePpeFields = (prochePpe, change, personne) => {
  if (prochePpe) {
    const { nom, prenom, lien, pays, fonction, finFonction } = prochePpe;

    nom && change(`${hasPersonne(personne)}${PPE_FORM_FIELD.NOM_PPE}`, nom);
    prenom && change(`${hasPersonne(personne)}${PPE_FORM_FIELD.PRENOM_PPE}`, prenom);
    lien && change(`${hasPersonne(personne)}${PPE_FORM_FIELD.LIEN_PPE}`, lien);
    fonction && change(`${hasPersonne(personne)}${PPE_FORM_FIELD.FONCTION_PPE}`, fonction);
    finFonction && change(`${hasPersonne(personne)}${PPE_FORM_FIELD.FIN_FONCTION_PPE}`, finFonction);

    change(`${hasPersonne(personne)}${PPE_FORM_FIELD.IS_PROCHE_PPE}`, BOOLEAN.YES);
    change(`${hasPersonne(personne)}${PPE_FORM_FIELD.PAYS_PPE}`, JSON.stringify(pays ? pays : COUNTRY.FRANCE));
  } else {
    change(`${hasPersonne(personne)}${PPE_FORM_FIELD.IS_PROCHE_PPE}`, BOOLEAN.NO);
    change(`${hasPersonne(personne)}${PPE_FORM_FIELD.PAYS_PPE}`, JSON.stringify(COUNTRY.FRANCE));
  }
};

export const getPpeFormValues = (values, uidDossier) => {
  const {
    isPpe,
    paysExercice,
    fonction,
    finFonction,
    isProchePpe,
    nomPpe,
    prenomPpe,
    lienPpe,
    fonctionPpe,
    paysExercicePpe,
    finFonctionPpe
  } = values;

  return {
    uidDossier,
    ppe: stringToBoolean(isPpe)
      ? {
          pays: JSON.parse(paysExercice),
          fonction,
          finFonction: finFonction ? finFonction : null
        }
      : null,
    prochePpe: stringToBoolean(isProchePpe)
      ? {
          pays: JSON.parse(paysExercicePpe),
          fonction: fonctionPpe,
          finFonction: finFonctionPpe ? finFonctionPpe : null,
          nom: nomPpe,
          prenom: prenomPpe,
          lien: lienPpe
        }
      : null
  };
};

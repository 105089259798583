import INPUTS from './constantes/DS/INPUTS';

function limitNumberValue(min, max, number) {
  const parseIntNumber = filterInt(number);
  let numberParseInt = Number.isNaN(parseIntNumber) ? min : parseIntNumber

  if (number > max) numberParseInt = max;
  if (number < min) numberParseInt = min;

  return numberParseInt
}

const getPdfMediaFileTypes = () => {
  return getMediaFileTypes().concat([INPUTS.FILETYPES.PDF]);
};

const getMediaFileTypes = () => {
  return [
    INPUTS.FILETYPES.PNG,
    INPUTS.FILETYPES.JPEG,
    INPUTS.FILETYPES.JPG
  ];
};

const getExcelFileTypes = () => {
  return [
    INPUTS.FILETYPES.EXCEL2003,
    INPUTS.FILETYPES.EXCEL2007
  ];
};

const getPdfFileType = () => {
  return [INPUTS.FILETYPES.PDF];
};

//Private methods category
function filterInt(value) {
  if (/^(-|\+)?(\d+|Infinity)$/.test(value))
    return Number(value);
  return NaN;
}

export default {
  limitNumberValue,
  getPdfMediaFileTypes,
  getMediaFileTypes,
  getExcelFileTypes,
  getPdfFileType
}

/* Built in imports */
import React, { Component } from 'react';

/* Third-party imports */
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

/* Internal imports */
import FormControlLabel from '../../../DS/inputs/form/FormControlLabel';
import FormLabel from '../../../DS/inputs/form/FormLabel';
import Radio from '../../../DS/inputs/Radio';
import SelectCountries from '../../../form/SelectCountries';
import Typography from '../../../DS/data-display/Typography';
import WorkAdvicesSheetsModal from '../../modals/WorkAdvicesSheetsModal';
import YesInput from '../../../form/Input';
import { ENTREPRISE_FORM_FIELDS_ASSURANCE } from '../../../../utils/constantes/US/ENTREPRISEFORM';
import { LISTE_FORMES_JURIDIQUE } from '../../../../common/constants';
import { NormalizeSirenSpace, NormalizeSiretSpace } from '../../../../common/Normalizers';
import { RESEAU_LFAC, RESEAU_AGENT_GENERAUX } from '../../../../common/Configuration';
import {
  Required,
  Siret,
  CodeNaf,
  CodePostal,
  DateFr,
  MaxLength50,
  MaxLength100,
  Siren,
  Rna,
  NbSalaries,
  DateJourMois
} from '../../../../common/Validators';
import { YesButton } from '../../../form/Button';
import { YesDropdown } from '../../../form/Dropdown';
import { changeFieldsValues } from '../../../../utils/US/components/epargne_salariale/forms/entrepriseUtils';
import { changeFieldsValuesAssurance } from '../../../../utils/US/components/assurance/forms/entrepriseUtils';
import { getJoursMoisOfDate, isAssociation, isAutreFormeJuridique, nombreAvecEspaces } from '../../../../common/FunctionsUtils';
import { removeWorkAdvicesSheets } from '../../../../reducers/work_advices_sheets/workAdvicesSheetsActions';
import TooltipInfo from '../../../../utils/US/components/TooltipInfo';

class EntrepriseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAssociationForms: isAssociation(this.props.tunnel.entreprise.formeJuridique),
      showAutreForms: isAutreFormeJuridique(this.props.tunnel.entreprise.formeJuridique),
      open: false,
      isWorkAdvicesSheets: (this.props.workAdvicesSheets !== null).toString()
    };

    this.setOpenModal = this.setOpenModal.bind(this);
    this.handleWorkAdvicesClick = this.handleWorkAdvicesClick.bind(this);
    this.handleIsWorkAdvicesSheetsChange = this.handleIsWorkAdvicesSheetsChange.bind(this);
  }

  handleWorkAdvicesClick() {
    this.setOpenModal(true);
  }

  handleIsWorkAdvicesSheetsChange(event) {
    const value = event.target.value;
    this.setState((state) => ({
      ...state,
      isWorkAdvicesSheets: value
    }));
  }

  setOpenModal(value) {
    this.setState((state) => ({
      ...state,
      open: value
    }));
  }

  isWorkAdvicesSheetsWarning() {
    let isWarning = false;

    if (this.props.tunnel.dossier.apporteurAffaire.reseau === RESEAU_LFAC) {
      isWarning = this.props.workAdvicesSheets === null;
    } else if (this.props.tunnel.dossier.apporteurAffaire.reseau === RESEAU_AGENT_GENERAUX) {
      isWarning = this.props.workAdvicesSheets === null && this.state.isWorkAdvicesSheets === 'true';
    }
    return isWarning;
  }

  componentDidMount() {
    const {
      change,
      tunnel: { sirene, entreprise }
    } = this.props;
    if (this.props.tunnel.entreprise && this.props.tunnel.entreprise.complement) {
      this.props.change('nombreSalaries', nombreAvecEspaces(this.props.tunnel.entreprise.complement.nombreSalaries));
      this.props.change('dateClotureComptable', getJoursMoisOfDate(this.props.tunnel.entreprise.complement.dateClotureComptable));
    }
    if ((sirene && !entreprise) || (sirene && entreprise)) {
      // CAS 1 : SIRENE RENSEIGNE SUR LE PREMIER ECRAN SANS ENTREPRISE PERSISTEE AUPARAVANT
      // CAS 2 : ENTREPRISE PERSISTEE ET SIRENE RENSEIGNE A L'ETAPE UNE (le bouton retour a été cliqué et l'utilisateur a renseigné un autre SIRENE)
      // dans ce cas, le formulaire sera renseigné par les informations remontées par SIRENE
      changeFieldsValues(sirene, change);
      changeFieldsValuesAssurance(sirene, change);
    } else if (entreprise && !sirene) {
      // CAS 3 : ENTREPRISE PERSISTEE, MAIS PAS DE SIRENE RENSEIGNE A LA PREMIERE ETAPE
      // dans ce cas, le formulaire serai renseigné par les informations entreprises persistées et potentiellement modifiés par l'utilisateur (sauf le SIRET)
      changeFieldsValues(entreprise, change);
      changeFieldsValuesAssurance(entreprise, change);
    }
  }

  componentDidUpdate(prevProps) {
    const { formeJuridiqueSelectionnee } = this.props;

    if (prevProps.formeJuridiqueSelectionnee !== formeJuridiqueSelectionnee) {
      if (isAssociation(formeJuridiqueSelectionnee) !== this.state.showAssociationForms) {
        this.setState({ showAssociationForms: isAssociation(formeJuridiqueSelectionnee) });
      }
      if (isAutreFormeJuridique(formeJuridiqueSelectionnee) !== this.state.showAutreForms) {
        this.setState({ showAutreForms: isAutreFormeJuridique(formeJuridiqueSelectionnee) });
      }
    }
  }

  render() {
    const { handleSubmit, pristine, invalid, submitting, tunnel, removeWorkAdvicesSheets } = this.props;

    const submitWithWorkAdvicesSheets = (values) => {
      if (
        this.state.isWorkAdvicesSheets === 'false' &&
        this.props.tunnel.dossier.apporteurAffaire.reseau === RESEAU_AGENT_GENERAUX &&
        this.props.workAdvicesSheets !== null
      ) {
        removeWorkAdvicesSheets(this.props.tunnel.dossier.uid);
      }
      handleSubmit(values);
    };

    return (
      <form onSubmit={submitWithWorkAdvicesSheets} noValidate>
        <WorkAdvicesSheetsModal
          open={this.state.open}
          onClose={() => this.setOpenModal(false)}
          handleSubmit={() => this.setOpenModal(false)}
          dossier={tunnel.dossier}
          isWorkAdvicesSheets={this.state.isWorkAdvicesSheets}
        />
        <div className="titreFormulaire">
          <Trans i18nKey={'entreprise.titre.retraite'} />
        </div>

        <div className="grid-x grid-margin-x listed">
          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_ASSURANCE.SIRET}
              labeltext="Siret"
              validate={[Required, Siret]}
              normalize={NormalizeSiretSpace}
              disabled={true}
              tabIndex="-1"
            />
          </div>

          <div className="cell medium-6 formeJuridique">
            <Field
              id="formeJuridique"
              name={ENTREPRISE_FORM_FIELDS_ASSURANCE.FORME_JURIDIQUE}
              label="Forme juridique"
              component={YesDropdown}
              item={LISTE_FORMES_JURIDIQUE}
            />
          </div>

          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_ASSURANCE.SIREN}
              labeltext="Siren"
              validate={[Required, Siren]}
              normalize={NormalizeSirenSpace}
            />
          </div>

          {this.state.showAssociationForms && (
            <div className="cell medium-6">
              <Field
                component={YesInput}
                type="text"
                name={ENTREPRISE_FORM_FIELDS_ASSURANCE.RNA}
                labeltext="Code RNA"
                validate={[Required, Rna]}
              />
            </div>
          )}

          {this.state.showAutreForms && (
            <div className="cell medium-6">
              <Field
                component={YesInput}
                type="text"
                name={ENTREPRISE_FORM_FIELDS_ASSURANCE.AUTRE_FORME_JURIDIQUE}
                labeltext="Intitulé autre forme juridique"
                validate={[Required]}
              />
            </div>
          )}

          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_ASSURANCE.AUTRE_IDENTIFIANT}
              labeltext="Autre (numéro d'association, ...)"
            />
          </div>

          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              inputMode="numeric"
              pattern="\d*"
              name={ENTREPRISE_FORM_FIELDS_ASSURANCE.DATE_CREATION}
              labeltext="Date de création"
              validate={[Required, DateFr]}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              placeholder="jj/mm/aaaa"
            />
          </div>

          <div className={`cell medium-6 codeNaf true`}>
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_ASSURANCE.CODE_NAF}
              labeltext="Code NAF / APE"
              validate={[Required, CodeNaf]}
              maxLength="5"
            />
          </div>

          <div className={`cell medium-6 true separateur'}`}>
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_ASSURANCE.LIBELLE_NAF}
              labeltext="Libellé activité NAF / APE"
              maxLength="50"
            />
          </div>

          {(this.state.showAssociationForms || this.state.showAutreForms) && <div className="cell medium-6" />}

          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_ASSURANCE.RAISON_SOCIALE}
              labeltext="Raison sociale"
              validate={[Required, MaxLength100]}
              maxLength="100"
            />
          </div>

          <div className="cell medium-6" />

          <div className="cell medium-12">
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_ASSURANCE.RUE}
              labeltext="Adresse de l'entreprise"
              validate={[Required, MaxLength100]}
              maxLength="100"
            />
          </div>

          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_ASSURANCE.CODE_POSTAL}
              labeltext="Code postal"
              validate={[Required, CodePostal]}
              maxLength="5"
            />
          </div>

          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_ASSURANCE.VILLE}
              labeltext="Ville"
              validate={[Required, MaxLength50]}
              maxLength="50"
            />
          </div>

          <div className="cell medium-6">
            <Field name={ENTREPRISE_FORM_FIELDS_ASSURANCE.PAYS} labeltext="Pays" component={SelectCountries} validate={Required} />
          </div>

          <div
            className="cell medium-6"
            style={{
              display: 'flex',
              alignItems: 'baseline'
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <Field
                component={YesInput}
                type="text"
                inputMode="numeric"
                pattern="\d*"
                name={ENTREPRISE_FORM_FIELDS_ASSURANCE.NOMBRE_SALARIES}
                labeltext="Nombre de salariés"
                validate={[Required, NbSalaries]}
              />
            </div>
            <div>
              <TooltipInfo
                placement="top-start"
                tooltipContent={
                  <Typography info>
                    <Trans i18nKey="entreprise.info.salarie" />
                  </Typography>
                }
              />
            </div>
          </div>
          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              inputMode="numeric"
              pattern="\d*"
              name={ENTREPRISE_FORM_FIELDS_ASSURANCE.DATE_CLOTURE_COMPTABLE}
              labeltext="Date de cloture comptable"
              validate={[Required, DateJourMois]}
              mask={[/\d/, /\d/, '/', /\d/, /\d/]}
              placeholder="jj/mm"
            />
          </div>
        </div>

        <div>
          {this.props.tunnel.dossier.apporteurAffaire.reseau === RESEAU_LFAC && (
            <div className="buttonCreationContainer">
              <YesButton
                text={<Trans i18nKey={'components.US.modals.work-advices-sheets.bottom.label'} />}
                onClick={(event) => {
                  event.preventDefault();
                  this.setOpenModal(true);
                }}
              />
            </div>
          )}
        </div>
        <div>
          {this.props.tunnel.dossier.apporteurAffaire.reseau === RESEAU_AGENT_GENERAUX && (
            <div className="cell medium-12 top-separator">
              <Stack>
                <FormControl>
                  <FormLabel sx={{ fontSize: '1rem' }} id="fiche-devoir-conseils">
                    <Trans i18nKey={'components.US.modals.work-advices-sheets.title'} /> :
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="fiche-devoir-conseils"
                    name="fiche-devoir-conseil-radio-buttons-group"
                    value={this.state.isWorkAdvicesSheets}
                    onChange={this.handleIsWorkAdvicesSheetsChange}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio onClick={this.handleWorkAdvicesClick} />}
                      label=<Trans i18nKey={'components.US.modals.work-advices-sheets.radio.label'} />
                    />
                    {this.isWorkAdvicesSheetsWarning() && (
                      <Typography warning indicator>
                        <Trans i18nKey={'entreprise.work-advices-sheets-warning'} />
                      </Typography>
                    )}
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label=<Trans i18nKey={'components.US.modals.work-advices-sheets.radio.no.description'} />
                    />
                    {this.state.isWorkAdvicesSheets === 'false' && (
                      <Typography warning indicator>
                        <Trans i18nKey={'entreprise.work-advices-sheets.no.infos'} />
                      </Typography>
                    )}
                  </RadioGroup>
                </FormControl>
              </Stack>
            </div>
          )}
        </div>
        <div className="buttonCreationContainer">
          <YesButton text="Confirmer" disabled={invalid || submitting || pristine || this.isWorkAdvicesSheetsWarning()} />
        </div>
      </form>
    );
  }
}

EntrepriseForm.propTypes = {
  tunnel: PropTypes.shape({
    sirene: PropTypes.object,
    entreprise: PropTypes.object,
    dossier: PropTypes.object
  }).isRequired,
  formeJuridiqueSelectionnee: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  change: PropTypes.func.isRequired
};

// REDUX + REDUX FORM
const selectEntrepriseForm = formValueSelector('informationsEntrepriseForm');
const mapStateToProps = (state) => ({
  tunnel: state.tunnel,
  formeJuridiqueSelectionnee: selectEntrepriseForm(state, ENTREPRISE_FORM_FIELDS_ASSURANCE.FORME_JURIDIQUE),
  workAdvicesSheets: state.workAdvicesSheets
});

const mapDispatchToProps = (dispatch) => ({
  removeWorkAdvicesSheets: (uid) => dispatch(removeWorkAdvicesSheets(uid))
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'informationsEntrepriseForm' })(EntrepriseForm));

/* Built in imports */
import React, { Component } from 'react';

/* Third party imports */
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

/* Internal imports */
import PpeForm from '../../../../components/US/assurance/forms/PPE/PpeForm';
import { URLS_ASSURANCE } from '../../../../utils/constantes/ROUTES/ASSURANCE';
import { getPpeFormValues } from '../../../../utils/US/components/ppeFieldsUtils';
import { mettreAjourFichePersonneMoralePpe } from '../../../../services/FichesServices';
import { setDonneesPpe, setRetourRoute } from '../../../../modules/tunnel/redux/tunnelActions';

class AssurancePpePage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    // On modifie la route lorsque l'utilisateur clique le bouton Retour
    this.props.setRetourRoute(URLS_ASSURANCE.IDENTITE.substring(1));
    this.state = { redirect: false };
  }

  submit(values) {
    const valuesAenvoyer = getPpeFormValues(values, this.props.tunnel.dossier.uid);

    mettreAjourFichePersonneMoralePpe(this.props.tunnel.dossier.uid, { valuesAenvoyer })
      .then((donneesPpe) => {
        this.props.setDonneesPpe(donneesPpe);
      })
      .catch((error) => {
        this.setMessageErreur(error);
      });

    this.redirect();
  }

  redirect() {
    this.setState({
      redirect: true
    });
  }

  setMessageErreur(error) {
    if (error && error.message) {
      this.setState({
        errorMsg: error.message
      });
    }
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={URLS_ASSURANCE.GARANTES_PREVOYANCE} />;
    }

    return (
      <div>
        <PpeForm onSubmit={this.submit} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setDonneesPpe: (donneesPpe) => dispatch(setDonneesPpe(donneesPpe))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssurancePpePage);

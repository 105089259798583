import React, { Component } from "react";
import { connect } from "react-redux";
import {
  obtenirTexteObjectif,
  listeObjectifs
} from "../../../../services/ObjectifService";
import { Curseurs } from "./Curseurs";
import Economies from "./Economies";
import Charges from "./Charges";

class Restitution extends Component {
  render() {
    const { simulateur } = this.props;

    return (
      <div className="grid-x grid-margin-x center-item-simu-container">
        <div className="cell medium-12">
          <h2>
            Recommandation pour{" "}
            <span className="yes-green">
              {listeObjectifs()
                .filter(o => o.type === simulateur.objectif)[0]
                .titre.toLowerCase()}
            </span>
          </h2>
          {obtenirTexteObjectif(
            simulateur.objectif,
            simulateur.effectif,
            simulateur.benefice
          )}
          <p className="yes-gras-2 marge-gras-2-recommandation">
            Voici le montant et la répartition de l’enveloppe individuelle que
            nous vous préconisons ainsi que son efficacité par rapport à une
            prime classique.
          </p>
        </div>

        <div className="cell medium-4">
          <div className="yes-rectangle curseurs-rectangle">
            <Curseurs />
          </div>
        </div>

        <div className="cell medium-8">
          <div className="yes-rectangle economies-rectangle">
            <Economies />
          </div>
        </div>
        <Charges />
        <div className="cell medium-12 valeur-non-contractuelle">
          Simulation effectuée à titre d’information et se basant sur les
          données que vous saisissez et sur la législation qui devrait être en
          vigueur dès 2019 (loi PACTE). <br />
          Les résultats n’ont pas de valeur contractuelle.
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  simulateur: state.simulateur
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(Restitution);

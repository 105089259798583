export const COUNTRIES = {
  UPDATE_COUNTRIES: 'UPDATE_COUNTRIES'
};

export const initialState = {
  france: {
    nom: 'France',
    code: 'FR',
    codeInsee: 'XXXXX'
  },
  countryList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COUNTRIES.UPDATE_COUNTRIES: {
      return {
        ...state,
        countryList: action.countries.sort((a, b) => a.nom.localeCompare(b.nom))
      };
    }

    default:
      return state;
  }
};

export const setCountries = (countries) => (dispatch, getState) => {
  dispatch({
    type: COUNTRIES.UPDATE_COUNTRIES,
    countries
  });
};

/* Built in imports */
import React from 'react';

/* Third party imports */
import { Trans } from 'react-i18next';

/* Internal imports */
import Typography from '../../components/DS/data-display/Typography';
import config from '../../common/StaticSiteConfig';
import { COLOR } from '../../utils/constantes/DS/TYPOGRAPHY';
import { TransHtmlText } from '../../components/yes/utils/TransHtmlText';
import { URLS_MAROQUINERIE } from '../../utils/constantes/ROUTES/MAROQUINERIE';
import { getListeBeneficiaire } from '../../services/ResourcesService';
import {
  isDirigeantInfoEnabled,
  isInfoCardEntreprisePart1Enabled,
  isParticipationVolontaireEnabled
} from '../../services/TenantService';

export const obtenirTexteInfoMaroquinerie = (etape, tunnel) => {
  switch (etape) {
    case URLS_MAROQUINERIE.ENTREPRISE_INFORMATION:
      return (
        isInfoCardEntreprisePart1Enabled() && (
          <div className="info-dossier">
            <Typography variant="h1" color={COLOR.PRIMARY} sx={{
              marginBottom: '0.166rem',
              marginTop: '0.166rem',
              lineHeight: '1.54',
            }}>
              <Trans i18nKey="entreprise.info.title" />
            </Typography>
            <Typography info>
              <Trans i18nKey="entreprise.info.part.1" />
            </Typography>
          </div>
        )
      );
    case URLS_MAROQUINERIE.IDENTITE:
      return (
        isDirigeantInfoEnabled() && (
          <div className="info-dossier">
            <Typography
              variant="h1"
              color={COLOR.PRIMARY} sx={{
              marginBottom: '0.166rem',
              marginTop: '0.166rem',
              lineHeight: '1.54',
            }}>
              <TransHtmlText i18nKey="dirigeant.info.title" />
            </Typography>

            <Typography info>
              <TransHtmlText i18nKey="dirigeant.info.part.1" />
            </Typography>
          </div>
        )
      );
    case URLS_MAROQUINERIE.DISPOSITIF_ACTUEL:
      return (
        <div className="info-dossier">
          <span className="titre-info">Infos</span>
          <p className="yes-green">
            <TransHtmlText i18nKey="transfert.info" />
          </p>
        </div>
      );
    case URLS_MAROQUINERIE.DISPOSITIF_ABONDEMENT:
      return (
        <div className="info-dossier">
          <span className="titre-info">Infos</span>
          <p className="yes-green">
            <TransHtmlText i18nKey="dispositif.maroquinerie.info.part.1" />
          </p>
          <br />
          <p className="yes-green">
            <TransHtmlText i18nKey="dispositif.maroquinerie.info.part.2" params={{ config: config }} />
          </p>
        </div>
      );
    case URLS_MAROQUINERIE.INTERESSEMENT:
      return (
        <div className="info-dossier">
          <span className="titre-info">Infos</span>
          <p className="yes-green">
            <TransHtmlText
              i18nKey="interessement.info"
              params={{
                config: config
              }}
            />
          </p>
        </div>
      );
    case URLS_MAROQUINERIE.PARTICIPATION:
      if (tunnel.entreprise && tunnel.entreprise.complement && tunnel.entreprise.complement.nombreSalaries) {
        return (
          <div>
            {(tunnel.entreprise.complement.nombreSalaries >= 50 || !isParticipationVolontaireEnabled()) && (
              <div className="info-dossier">
                <span className="titre-info">Infos</span>
                <p className="yes-green">
                  <TransHtmlText
                    i18nKey="participation.info.plus.49"
                    params={{
                      config: config
                    }}
                  />
                </p>
              </div>
            )}
            {tunnel.entreprise.complement.nombreSalaries < 50 && isParticipationVolontaireEnabled() && (
              <div className="info-dossier">
                <span className="titre-info">Infos</span>
                <p className="yes-green">
                  <TransHtmlText
                    i18nKey="participation.info.moins.50"
                    params={{
                      config: config
                    }}
                  />
                </p>
              </div>
            )}
          </div>
        );
      }
      break;
    case URLS_MAROQUINERIE.TARIFICATION:
      return (
        <div className="info-dossier">
          <span className="titre-info">Infos</span>
          <p className="yes-green">
            <TransHtmlText i18nKey="tarification.info" />
          </p>
        </div>
      );
    case URLS_MAROQUINERIE.KYC:
      return(
        <div className="info-dossier">
          <Typography
            variant={"h1"}
            color={COLOR.PRIMARY}
            sx={{
              marginBottom: '0.166rem',
              marginTop: '0.166rem',
              lineHeight: '1.54',
            }}
          >
            <Trans i18nKey="kyc.page.infos.epargne.title" />
          </Typography>
          <ul>
            <Typography info sx={{
              marginBottom:'12px'
            }}>
              <li>
                <TransHtmlText i18nKey="kyc.page.infos.epargne.mentions"/>
              </li>
            </Typography>
            <Typography info sx={{
              marginBottom:'12px'
            }}>
              <li>
                <TransHtmlText i18nKey="kyc.page.infos.epargne.kbis"/>
              </li>
            </Typography>
            <Typography info>
              <li>
                <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions"/>
              </li>
            </Typography>
            <ul>
              <Typography info>
                <li>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.parts"/>
                </li>
                <li>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.B"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.B.sub.1"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.B.sub.2"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.B.sub.3"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.B.sub.4"/>
                </li>
                <li>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.P"/>
                </li>
                <li>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.A"/>
                </li>
                <li>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.D"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.D.sub.1"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.D.sub.2"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.D.sub.3"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.D.sub.4"/>
                </li>
              </Typography>
            </ul>
            <br/>
          </ul>
        </div>
      )
    case URLS_MAROQUINERIE.FINALISATION:
      return (
        <div className="info-dossier">
          <label className="titreFormulaire">
            <Trans i18nKey="epargne_salariale.informations.sidemenu.kbis.title" />
          </label>
          <p className="margeInfo">
            <TransHtmlText i18nKey="epargne_salariale.informations.sidemenu.kbis.content" />
          </p>
          <p className="margeInfo">
            <a className="infoLink" href="https://www.infogreffe.fr/documents-officiels/extrait-kbis"
               target="_blank" rel="noopener noreferrer">
              <TransHtmlText i18nKey="epargne_salariale.informations.sidemenu.kbis.infogreffe"/>
            </a>
          </p>
          <label className="titreFormulaire">
            <Trans i18nKey="epargne_salariale.informations.sidemenu.identity.title" />
          </label>
          <p className="margeInfo">
            <TransHtmlText i18nKey="epargne_salariale.informations.sidemenu.identity.content" />
          </p>
          <p className="margeInfo">
            <a role="button" className="infoLink" onClick={() => getListeBeneficiaire()}>
              <TransHtmlText i18nKey="epargne_salariale.informations.sidemenu.beneficiaryList.link" />
            </a>
          </p>
        </div>
      );
    default:
      break;
  }
};

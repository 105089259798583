const BORDER_STYLE = '1px solid #425668';

export const style = {
  modalContent: {
    padding: '2rem 4rem 3rem 4rem'
  },
  reviewBlock: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '1.25rem',
    paddingBottom: '.5rem'
  },
  logoContainer: {
    textAlign: 'center',
    marginBottom: '4rem'
  },
  logo: {
    marginTop: '2rem',
    width: '45%'
  },
  textBlock: {
    paddingLeft: '1rem',
    paddingTop: '1.25rem'
  },
  subTitle: {
    paddingLeft: '.75rem',
    color: '#4f5051',
    fontFamily: 'Source Sans Pro'
  },
  reviewTitle: {
    color: 'white',
    fontSize: '1.15rem',
    padding: '5px 10px',
    marginBottom: 0,
    backgroundColor: '#752127',
    borderBottom: BORDER_STYLE,
    textTransform: 'uppercase',
    fontFamily: 'Source Sans Pro'
  },
  mainTitle: {
    margin: 'auto',
    marginBottom: '2.5rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: '0.2rem',
    fontSize: '1.5rem',
    fontFamily: 'Source Sans Pro',
    border: '1px solid #425668'
  },
  reviewBox: {
    border: BORDER_STYLE,
    marginBottom: '2rem'
  },
  reviewDispositif: {
    padding: '1.25rem',
    backgroundColor: '#fbfbfb',
    border: BORDER_STYLE
  },
  reviewMentionsLegales: {
    marginTop: '2rem',
    gridColumn: '1/3',
    gridRow: '4'
  },
  mentionBloc: {
    padding: '0.5rem 1rem',
    marginBottom: '0.5rem',
    backgroundColor: '#f9f9f9'
  },
  mention: {
    color: '#516273',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    fontSize: 'large'
  },
  mentionsLegales: {
    fontSize: '12px',
    lineHeight: 'inherit'
  },
  paragraphText: {
    fontSize: '1rem'
  }
};

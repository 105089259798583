/* third-party imports */
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';


const FormControlLabelRoot = styled(FormControlLabel, { name: 'FormControlLabel' })(() => ({
  '& .MuiTypography-root': {
    fontFamily: 'var(--cn-font-family-source-pro-sans-serif)',
    color: 'var(--cn-typo-default-color)'
  }
}))

export default FormControlLabelRoot
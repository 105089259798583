import { COUNTRY, IS_REQUIRED} from '../../../../../common/constants';
import { stringToBoolean } from '../../../../../common/FunctionsUtils';
import { QUALITE_ACTIONNNAIRE } from '../../../../../utils/constantes/US/KYCFORM';

const kycValidators = (values) => {
  const errors = {};

  if (
    stringToBoolean(values.needPaysPersonneMorale) &&
    values.paysPersonneMorale &&
    values.paysPersonneMorale === JSON.stringify(COUNTRY.FRANCE)
  ) {
    errors.paysPersonneMorale = "Vous devez indiquer le pays de la personne morale dont l'adresse n'est pas en France";
  }

  if (!values.actionnaires || !values.actionnaires.length) {
    errors.nbActionnaires = 'Vous devez renseigner au moins un actionnaire';
  }
  if (values.actionnaires) {
    const actionnairesArrayErrors = [];
    values.actionnaires.forEach((actionnaire, index) => {
      if (!actionnaire.nom) {
        actionnairesArrayErrors[index] = { ...actionnairesArrayErrors[index], nom: IS_REQUIRED };
      }
      if (!actionnaire.prenom && !(actionnaire.qualite === 'B' && actionnaire.beneficiaireIsPersonneMorale === 'oui')) {
        actionnairesArrayErrors[index] = { ...actionnairesArrayErrors[index], prenom: IS_REQUIRED };
      }
      if (!actionnaire.adressePermanenteRue) {
        actionnairesArrayErrors[index] = { ...actionnairesArrayErrors[index], adressePermanenteRue: IS_REQUIRED };
      }
      if (!actionnaire.adressePermanenteCode) {
        actionnairesArrayErrors[index] = { ...actionnairesArrayErrors[index], adressePermanenteCode: IS_REQUIRED };
      }
      if (!actionnaire.adressePermanenteVille) {
        actionnairesArrayErrors[index] = { ...actionnairesArrayErrors[index], adressePermanenteVille: IS_REQUIRED };
      }
      if (!actionnaire.isSameAddress) {
        if (!actionnaire.adressePostaleRue) {
          actionnairesArrayErrors[index] = { ...actionnairesArrayErrors[index], adressePostaleRue: IS_REQUIRED };
        }
        if (!actionnaire.adressePostaleCode) {
          actionnairesArrayErrors[index] = { ...actionnairesArrayErrors[index], adressePostaleCode: IS_REQUIRED };
        }
        if (!actionnaire.adressePostaleVille) {
          actionnairesArrayErrors[index] = { ...actionnairesArrayErrors[index], adressePostaleVille: IS_REQUIRED };
        }
      }
      if (!actionnaire.paysFiscal) {
        actionnairesArrayErrors[index] = { ...actionnairesArrayErrors[index], paysFiscal: IS_REQUIRED };
      }
      if (!actionnaire.parts) {
        actionnairesArrayErrors[index] = { ...actionnairesArrayErrors[index], parts: IS_REQUIRED };
      }
      if (actionnaire.parts) {
        if (isNaN(actionnaire.parts)) {
          actionnairesArrayErrors[index] = { ...actionnairesArrayErrors[index], parts: 'Seul les nombres sont acceptés' };
        } else if (+actionnaire.parts > 100) {
          actionnairesArrayErrors[index] = {
            ...actionnairesArrayErrors[index],
            parts: 'Les pourcentages doivent être inférieur ou égal à 100'
          };
        }
      }
      if (actionnaire.qualite === QUALITE_ACTIONNNAIRE.DIRIGEANT && !actionnaire.fonctionDirigeant) {
        actionnairesArrayErrors[index] = { ...actionnairesArrayErrors[index], fonctionDirigeant: IS_REQUIRED };
      }
    });
    if (actionnairesArrayErrors.length) {
      errors.actionnaires = actionnairesArrayErrors;
    }
  }
  return errors;
};

export default kycValidators;

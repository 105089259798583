/* Built-in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Internal imports */
import TextWithTitle from './TextWithTitle';
import RecapitulatifBloc from './RecapitulatifBloc';
import { getAddressLabel } from '../../../../utils/US/components/assurance/recapitulatif/recapitulatifUtils';
import { style } from './recapitulatif.style';

const RecapitulatifBeneficiaires = ({ t, actionnaires }) => (
  <>
    {actionnaires.length > 0 &&
      actionnaires.map(
        (
          {
            civilite,
            nom,
            prenom,
            nomNaissance,
            dateNaissance,
            adresseNaissance,
            paysNationalite,
            adressePermanente,
            adressePostale,
            typeControle,
            paysFiscal,
            parts,
            ppe,
            prochePpe
          },
          index
        ) => (
          <RecapitulatifBloc key={`recap.beneficiaire#${index + 1}`} title={`${t('recap.beneficiaire.titre')} n°${index + 1}`}>
            <TextWithTitle title={t('recap.beneficiaire.civilite')} content={`${civilite} ${prenom} ${nom}`} />
            {nomNaissance && <TextWithTitle title={t('recap.beneficiaire.nomNaissance')} content={nomNaissance} />}
            <TextWithTitle title={t('recap.beneficiaire.controle')} content={typeControle} />
            <TextWithTitle
              title={t('recap.beneficiaire.dateNaissance')}
              content={`${dateNaissance} au ${getAddressLabel(adresseNaissance)}`}
            />
            <TextWithTitle title={t('recap.beneficiaire.nationalites')} content={paysNationalite} />
            <TextWithTitle
              title={t('recap.beneficiaire.adressePostale')}
              content={`${getAddressLabel(adressePostale ? { ...adressePostale, pays: adressePermanente.pays } : adressePermanente)}`}
            />
            <TextWithTitle title={t('recap.beneficiaire.adressePermanente')} content={`${getAddressLabel(adressePermanente)}`} />
            <TextWithTitle title={t('recap.beneficiaire.paysFiscal')} content={paysFiscal.nom} />
            <TextWithTitle title={t('recap.beneficiaire.parts')} content={`${parts}%`} />

            <br />

            {ppe && (
              <div style={style.mentionBloc}>
                <p style={style.mention}>{t('recap.beneficiaire.ppe')}</p>
                <TextWithTitle title={t('recap.beneficiaire.ppe.fonction')} content={ppe.fonction} />
                {ppe.finFonction && <TextWithTitle title={t('recap.beneficiaire.ppe.finFonction')} content={ppe.finFonction} />}
                <TextWithTitle title={t('recap.beneficiaire.ppe.pays')} content={ppe.pays.nom} />
              </div>
            )}
            {prochePpe && (
              <div style={style.mentionBloc}>
                <p style={style.mention}>{t('recap.beneficiaire.prochePpe')}</p>
                <TextWithTitle
                  title={t('recap.beneficiaire.prochePpe.designation')}
                  content={`${prochePpe.prenom} ${prochePpe.nom}`}
                />
                <TextWithTitle title={t('recap.beneficiaire.ppe.fonction')} content={prochePpe.fonction} />
                {prochePpe.finFonction && (
                  <TextWithTitle title={t('recap.beneficiaire.ppe.finFonction')} content={prochePpe.finFonction} />
                )}
                <TextWithTitle title={t('recap.beneficiaire.ppe.pays')} content={prochePpe.pays.nom} />
                <TextWithTitle title={t('recap.beneficiaire.prochePpe.lien')} content={prochePpe.lien} />
              </div>
            )}
          </RecapitulatifBloc>
        )
      )}
  </>
);

RecapitulatifBeneficiaires.propTypes = {
  t: PropTypes.func.isRequired,
  actionnaires: PropTypes.array
};

// REDUX + REDUX FORM
const mapStateToProps = ({
  tunnel: {
    dossier: { donneesAnnexeKYC: actionnaires }
  }
}) => actionnaires;

export default connect(mapStateToProps)(withTranslation()(RecapitulatifBeneficiaires));

import { TUNNEL_AT } from './tunnelActions';

/**
 * Initial state, à mettre dans le champ 'simulateur' du state global
 */
export const initialState = {
  routeRetour: 'none',
  redirectEtape: false,
  information: 1,
  loader: false,
  participationVolontaire: {
    dates: null,
    option: 'B',
    exerciceNonConnu: false
  },
  emailDejaExistant: null,
  entreprise: {
    annexeKyc: {
      paysFiscalEntreprise: '',
      adressePostaleEntreprise: '',
      regulateur: false,
      entrepriseCotee: false,
      codeBIC: '',
      paysActivité: '',
      actionnaires: [],
      paysActionairePrincipal: ''
    }
  },
  fichePersonneMorale: {
    nouveauClient: true,
    ppe: null,
    prochePpe: null,
    moyensPaiement: {
      debitCompteSouscripteur: true,
      etablissementHorsFrance: null
    }
  }
};

const emptyFichePM = {
  nouveauClient: true,
  ppe: null,
  prochePpe: null,
  moyensPaiement: {
    debitCompteSouscripteur: true,
    etablissementHorsFrance: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TUNNEL_AT.UPDATE_SIRENE_INFO: {
      return {
        ...state,
        sirene: action.sirene
      };
    }
    case TUNNEL_AT.UPDATE_RETOUR_ROUTE: {
      return {
        ...state,
        routeRetour: action.routeRetour
      };
    }
    case TUNNEL_AT.UPDATE_SERVEUR_ERROR_FORM: {
      return {
        ...state,
        errorForm: action.errorForm
      };
    }
    case TUNNEL_AT.UPDATE_LOADER: {
      return {
        ...state,
        loader: action.loader
      };
    }
    case TUNNEL_AT.UPDATE_LOADER_MESSAGE: {
      return {
        ...state,
        loaderMessage: action.loaderMessage
      };
    }
    case TUNNEL_AT.UPDATE_ENTREPRISE_INFO: {
      return {
        ...state,
        entreprise: action.entreprise
      };
    }
    case TUNNEL_AT.UPDATE_DOSSIER_INFO: {
      return {
        ...state,
        dossier: action.dossier
      };
    }
    case TUNNEL_AT.UPDATE_REDIRECT_ETAPE: {
      return {
        ...state,
        redirectEtape: action.redirectEtape
      };
    }
    case TUNNEL_AT.UPDATE_UTILISATEUR_INFO: {
      return {
        ...state,
        utilisateur: action.utilisateur
      };
    }

    case TUNNEL_AT.UPDATE_DONNEES_DIRIGEANT: {
      const dossier = {
        ...state.dossier,
        donneesDirigeant: action.donneesDirigeant
      };
      return {
        ...state,
        dossier: dossier
      };
    }

    case TUNNEL_AT.UPDATE_FICHE_PERSONNE_MORALE: {
      return {
        ...state,
        fichePersonneMorale: action.fiche ? action.fiche : emptyFichePM
      };
    }

    case TUNNEL_AT.UPDATE_DONNEES_PPE: {
      const fichePersonneMorale = {
        ...state.fichePersonneMorale,
        ppe: action.fichePersonneMorale.ppe,
        prochePpe: action.fichePersonneMorale.prochePpe
      };
      return {
        ...state,
        fichePersonneMorale
      };
    }

    case TUNNEL_AT.UPDATE_INFOS_COMPL_ENTREPRISE: {
      const entreprise = {
        ...state.entreprise,
        complement: action.complement
      };
      return {
        ...state,
        entreprise
      };
    }

    case TUNNEL_AT.UPDATE_MODALITES_PERO: {
      const nouveauDossier = {
        ...state.dossier,
        modalitesPero: action.modalitesPero
      };
      return {
        ...state,
        dossier: nouveauDossier
      };
    }

    case TUNNEL_AT.UPDATE_GARANTIES_PREVOYANCE: {
      const nouveauDossier = {
        ...state.dossier,
        garantiesPrevoyance: action.garantiesPrevoyance
      };
      return {
        ...state,
        dossier: nouveauDossier
      };
    }

    case TUNNEL_AT.UPDATE_FRAIS_CONTRAT: {
      const nouveauDossier = {
        ...state.dossier,
        fraisContrat: action.fraisContrat
      };
      return {
        ...state,
        dossier: nouveauDossier
      };
    }

    case TUNNEL_AT.UPDATE_PLAN_EXISTANT: {
      const dossier = {
        ...state.dossier,
        planExistant: action.planExistant
      };
      return {
        ...state,
        dossier
      };
    }

    case TUNNEL_AT.UPDATE_TARIFICATION: {
      const dossier = {
        ...state.dossier,
        compteDePrelevement: action.compteDePrelevement
      };
      return {
        ...state,
        dossier
      };
    }
    case TUNNEL_AT.UPDATE_GES_TARIFICATION: {
      const dossier = {
        ...state.dossier,
        tarification: action.tarification
      };
      return {
        ...state,
        dossier
      };
    }

    case TUNNEL_AT.UPDATE_ABONDEMENTS: {
      const dossier = {
        ...state.dossier,
        dispositifs: action.dispositifs
      };
      return {
        ...state,
        dossier
      };
    }

    case TUNNEL_AT.UPDATE_ABONDEMENT_UNILATERAL: {
      const dossier = {
        ...state.dossier,
        dispositifs: {
          ...state.dossier.dispositifs,
          dispositifPercoi: {
            ...state.dossier.dispositifs.dispositifPercoi,
            abondementUnilateral: action.abondementUnilateral
          }
        }
      };
      return {
        ...state,
        dossier
      };
    }

    case TUNNEL_AT.UPDATE_INTERESSEMENT: {
      const dossier = {
        ...state.dossier,
        interessement: action.interessement
      };
      return {
        ...state,
        dossier
      };
    }

    case TUNNEL_AT.UPDATE_PARTICIPATION: {
      const dossier = {
        ...state.dossier,
        participation: action.participation
      };
      return {
        ...state,
        dossier
      };
    }

    case TUNNEL_AT.UPDATE_PARTICIPATION_VOLONTAIRE: {
      const dossier = {
        ...state.dossier,
        participationVolontaire: action.participationVolontaire
      };
      return {
        ...state,
        dossier
      };
    }

    case TUNNEL_AT.UPDATE_INFORMATIONS: {
      return {
        ...state,
        information: action.information
      };
    }

    case TUNNEL_AT.UPDATE_LIASSE: {
      return {
        ...state,
        liasse: action.liasse
      };
    }

    case TUNNEL_AT.REMOVE_LIASSE: {
      return {
        ...state,
        liasse: null
      };
    }

    case TUNNEL_AT.UPDATE_DATES_PARTICIPATION_VOLONTAIRE: {
      const participationVolontaire = {
        ...state.participationVolontaire,
        dates: action.dates
      };

      return {
        ...state,
        participationVolontaire
      };
    }

    case TUNNEL_AT.UPDATE_EMAIL_DEJA_EXISTANT: {
      return {
        ...state,
        emailDejaExistant: action.emailDejaExistant
      };
    }

    case TUNNEL_AT.RESET_ALL_STATE: {
      return {
        ...initialState
      };
    }

    case TUNNEL_AT.UPDATE_MOYENS_PAIEMENT: {
      return {
        ...state,
        fichePersonneMorale: {
          ...state.fichePersonneMorale,
          moyensPaiement: action.moyensPaiement
        }
      };
    }

    case TUNNEL_AT.UPDATE_FICHES_PERO: {
      return {
        ...state,
        fichesPero: action.fichesPero
      };
    }

    case TUNNEL_AT.UPDATE_CATEGORIES_SALARIES: {
      return {
        ...state,
        dossier: {
          ...state.dossier,
          categoriesSalaries: action.categoriesSalaries
        }
      };
    }

    default:
      return state;
  }
};

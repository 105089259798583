/* Built in imports */
import React from 'react';

/* Third party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

/* Internal imports */
import { TransHtmlText } from '../../../../../yes/utils/TransHtmlText';
import {YesChoix} from "../../../../../form/Choix";

const ApplicationFiscale = ({ dates }) => (
  <div className="cell medium-12">
    <h3>
      <TransHtmlText i18nKey="participation.participationIntegre.applicationFiscale"/>
    </h3>

    <div className="tunnelRadios cell medium-3">
      <div className="tunnelRadio">
        <Field
          name="optionParticipation.applicationFiscale"
          component={YesChoix}
          type="radio"
          value={dates[0].value}
          label={dates[0].label}
          id={`optionParticipation.applicationFiscale.annee-N`}
        />
      </div>
      <div className="tunnelRadio">
        <Field
          name="optionParticipation.applicationFiscale"
          component={YesChoix}
          type="radio"
          value={dates[1].value}
          label={dates[1].label}
          id={`optionParticipation.applicationFiscale.annee-NPlus1`}
        />
      </div>
    </div>
  </div>
);

ApplicationFiscale.propTypes = {
  dates: PropTypes.array.isRequired
};

export default ApplicationFiscale;

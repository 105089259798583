import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import yes from "./yes/texts.json";
import ges from "./ges/texts.json";
import { getTenant } from "../services/TenantService";

const resources = {
  yes: {
    translation: yes
  },
  ges: {
    translation: ges
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: getTenant(),
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;

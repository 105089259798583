/* Built in imports */
import React, { Component } from 'react';

/* Third party imports */
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';

/* Internal imports */
import ParticipationIntegre from './participationIntegre/ParticipationIntegre';
import config from '../../../../../common/StaticSiteConfig';
import { BOOLEAN } from '../../../../../common/constants';
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';
import { YesButton } from '../../../../form/Button';
import { YesChoix } from '../../../../form/Choix';
import { YesDropdown } from '../../../../form/Dropdown';
import { isParticipationVolontaireEnabled, isMessagesAccompagnementAccordsEnabled } from '../../../../../services/TenantService';
import { setDatesParticipationVolontaire } from '../../../../../modules/tunnel/redux/tunnelActions';
import {stringToBoolean, dateFromString, booleanToString, getExerciceEnd} from '../../../../../common/FunctionsUtils';
import {
  construireChoixModesDeRepartition,
  construireChoixDurees,
  isExerciceNonConnu,
  getOptions,
  getDatesDebutFinOption
} from '../../../../../services/ParticipationService';

let mapDurees = null;

class ParticipationForm extends Component {
  constructor(props) {
    super(props);

    mapDurees = construireChoixDurees();

    this.state = {
      durees: [],
      options: [],
      showParticipationVolontaire: false,
      modesRepartition: construireChoixModesDeRepartition(),
      modeParticipationVolontaire:
        this.props.tunnel.entreprise.complement.nombreSalaries < 50 && isParticipationVolontaireEnabled(),
      dateCloture:
        this.props.tunnel.dossier.participationVolontaire && this.props.tunnel.dossier.participationVolontaire.participationActive
          ? dateFromString(this.props.tunnel.dossier.participationVolontaire.dateClotureComptableAjour)
          /* A supprimer si l'on bascule sur la nouvlle option */
          : dateFromString(`${this.props.tunnel.entreprise.complement.dateClotureComptable}/${new Date().getFullYear()}`)
          /* A décommenter pour la nouvelle option */
          // : getExerciceEnd(this.props.tunnel.entreprise.complement.dateClotureComptable)
    };
  }

  componentDidMount() {
    // CHARGEMENT des dates calculées, des durées et des modes de répartition

    // Mode de répartition par défaut
    this.props.change('modeRepartition', 'PROPORTIONNEL_SALAIRE');

    // Exercice connu : OUI ou NON
    this.setState(
      {
        isExerciceNonConnu: isExerciceNonConnu(this.state.dateCloture)
      },
      // Puis on peuple la liste des options disponibles en fct de la date comptable
      () => {
        this.setState(
          {
            options: getOptions(this.state.dateCloture)
          },
          // Puis on peuple la liste des durées disponible en fonction de l'option choisie et de l'exercice connu (oui ou non)
          () => {
            this.props.change('option', 'B');
            this.props.change('duree', 0);
            // Go REDUX
            this.props.setDatesParticipationVolontaire(getDatesDebutFinOption(this.state.dateCloture, 'B'));

            this.setState(
              {
                durees: mapDurees.get('B').get(this.state.isExerciceNonConnu)
              },

              // Pour finir, on set les valeurs dans les champs s'il y a persistance
              () => {
                // Affichage des valeurs
                if (this.props.tunnel.dossier.participation || this.props.tunnel.dossier.participationVolontaire) {
                  // Participation standard
                  if (this.props.tunnel.dossier.participation) {
                    if (this.props.tunnel.dossier.participation.participationActive) {
                      this.props.change('participation', BOOLEAN.YES);
                      this.setState({ showParticipationVolontaire: true });
                    } else {
                      this.props.change('participation', BOOLEAN.NO);
                    }
                    // Participation intégré
                    this.props.change(
                      'optionParticipation.enabled',
                      booleanToString(
                        this.props.tunnel.dossier.participation.optionParticipation &&
                          this.props.tunnel.dossier.participation.optionParticipation.enabled
                      )
                    );

                    if (
                      this.props.tunnel.dossier.participation.optionParticipation &&
                      this.props.tunnel.dossier.participation.optionParticipation.enabled
                    ) {
                      this.props.change(
                        'optionParticipation.formule.type',
                        this.props.tunnel.dossier.participation.optionParticipation.type.toString()
                      );
                      this.props.change(
                        'optionParticipation.repartition.type',
                        this.props.tunnel.dossier.participation.optionParticipation.repartition.type.toString()
                      );
                      if (this.props.tunnel.dossier.participation.optionParticipation.repartition.type === 4) {
                        Object.entries(this.props.tunnel.dossier.participation.optionParticipation.repartition.pourcent).forEach(
                          ([key, value]) => this.props.change(`optionParticipation.repartition.pourcent.${key}`, value || 0)
                        );
                      }
                      this.props.change(
                        'optionParticipation.duree.type',
                        this.props.tunnel.dossier.participation.optionParticipation.duree.type.toString()
                      );
                      if (this.props.tunnel.dossier.participation.optionParticipation.duree.type === 2) {
                        this.props.change(
                          'optionParticipation.duree.duree',
                          this.props.tunnel.dossier.participation.optionParticipation.duree.duree.toString()
                        );
                      }
                      this.props.change(
                        'optionParticipation.applicationFiscale',
                        this.props.tunnel.dossier.participation.optionParticipation.applicationFiscale
                      );
                    }
                  }

                  // Participation volontaire
                  if (this.props.tunnel.dossier.participationVolontaire) {
                    if (this.props.tunnel.dossier.participationVolontaire.participationActive) {
                      this.props.change('participation', BOOLEAN.YES);

                      let hasDateDebutExistante = this.state.options.mapOptions.has(
                        this.props.tunnel.dossier.participationVolontaire.dateDebut
                      );

                      if (hasDateDebutExistante) {
                        let optionChoisi = this.state.options.mapOptions.get(
                          this.props.tunnel.dossier.participationVolontaire.dateDebut
                        );
                        this.props.change('option', optionChoisi);
                        this.props.setDatesParticipationVolontaire(getDatesDebutFinOption(this.state.dateCloture, optionChoisi));
                      } else {
                        this.props.change('option', 'B');
                      }

                      this.props.change('modeRepartition', this.props.tunnel.dossier.participationVolontaire.modeRepartition);
                      this.props.change('duree', this.props.tunnel.dossier.participationVolontaire.duree);

                      this.setState({
                        showParticipationVolontaire: true
                      });
                    } else {
                      this.props.change('participation', BOOLEAN.NO);
                    }
                  }
                } else {
                  this.props.change('participation', BOOLEAN.NO);
                  this.props.change('optionParticipation.enabled', BOOLEAN.NO);
                }
              }
            );
          }
        );
      }
    );
  }

  toggleParticipationVolontaire = (value) => {
    this.setState({
      showParticipationVolontaire: stringToBoolean(value.target.value)
    });
  };

  changerOption = (value) => {
    // Store dans redux pour le retrouver dans la méthode submit
    this.props.setDatesParticipationVolontaire(getDatesDebutFinOption(this.state.dateCloture, value.target.value));

    this.setState({
      durees: mapDurees.get(value.target.value).get(this.state.isExerciceNonConnu)
    });
  };

  showLabelParticipationVolontaire() {
    if (this.state.modeParticipationVolontaire) {
      return 'volontaire';
    }
    return '';
  }

  optionParticipationIsValid = () =>
    !stringToBoolean(this.props.optionParticipation.enabled) ||
    (this.props.optionParticipation.formule &&
      +this.props.optionParticipation.formule.type > 0 &&
      this.props.optionParticipation.repartition &&
      +this.props.optionParticipation.repartition.type > 0 &&
      this.props.optionParticipation.duree &&
      +this.props.optionParticipation.duree.type > 0 &&
      (this.props.optionParticipation.duree.type !== '2' || this.props.optionParticipation.duree.duree) &&
      this.props.optionParticipation.applicationFiscale
    );

  render() {
    const { handleSubmit, pristine, invalid, submitting, participationValue, optionParticipation } = this.props;
    return (
      <form onSubmit={handleSubmit} className="formTunnel formParticipation">
        <label className="titreFormulaire">
          <Trans i18nKey="participation.titre" /> {this.showLabelParticipationVolontaire()}
        </label>

        <div className="grid-x grid-margin-x">
          <div className="cell medium-12">
            <h3>
              <TransHtmlText
                i18nKey="participation.question.miseEnPlace"
                params={{
                  config: config,
                  volontaire: this.showLabelParticipationVolontaire()
                }}
              />
            </h3>
          </div>
          <div className="cell medium-12">
            <div className="tunnelRadios">
              <div className="tunnelRadio">
                <Field
                  name="participation"
                  component={YesChoix}
                  type="radio"
                  value={BOOLEAN.YES}
                  label="Oui"
                  id="participation"
                  onChange={this.toggleParticipationVolontaire}
                />
              </div>
              <div className="tunnelRadio">
                <Field
                  name="participation"
                  component={YesChoix}
                  type="radio"
                  value={BOOLEAN.NO}
                  label="Non"
                  id="noParticipation"
                  onChange={this.toggleParticipationVolontaire}
                />
              </div>
            </div>
          </div>

          {this.state.modeParticipationVolontaire && this.state.showParticipationVolontaire && (
            <React.Fragment>
              <div className="cell medium-12">
                <p className="infoCloture">
                  <TransHtmlText
                    i18nKey="participation.question.datesExercices"
                    params={{
                      config: config,
                      dateCloture: this.props.tunnel.entreprise.complement.dateClotureComptable
                    }}
                  />
                </p>
              </div>
              <div className="cell medium-5">
                <Field
                  id="option"
                  name="option"
                  component={YesDropdown}
                  onChange={this.changerOption}
                  item={this.state.options.optionsDropDown}
                />
              </div>
              <div className="cell medium-3">
                <p className="dureeParticipation">sur une durée de :</p>
              </div>
              <div className="cell medium-4">
                <Field id="duree" name="duree" component={YesDropdown} item={this.state.durees} />
              </div>
              <div className="cell medium-12 modeRepartitionChoix">
                <p>
                  {' '}
                  <Trans i18nKey="participation.question.modesRepartition" />{' '}
                </p>
              </div>
              <div className="cell medium-12">
                {this.state.modesRepartition.map((item, i) => {
                  return (
                    <Field
                      name="modeRepartition"
                      component={YesChoix}
                      type="radio"
                      value={item.value}
                      label={item.label}
                      id={item.value}
                      key={i}
                    />
                  );
                })}
              </div>
            </React.Fragment>
          )}
        </div>

        {this.state.showParticipationVolontaire && isMessagesAccompagnementAccordsEnabled() && (
          <div className="cell medium-12 messageAccompagnement">
            <TransHtmlText
              i18nKey="participation.message.accompagnement.accords"
              params={{
                config: config
              }}
            />
          </div>
        )}

        {this.state.showParticipationVolontaire && (
          <ParticipationIntegre
            optionParticipation={optionParticipation}
            cloture={this.state.dateCloture}
            pourcentRepartition={(optionParticipation.repartition && optionParticipation.repartition.pourcent) || {}}
          />
        )}
        <div className="buttonCreationContainer">
          <YesButton
            text="Confirmer"
            disabled={invalid || submitting || pristine || !participationValue || !this.optionParticipationIsValid()}
          />
        </div>
      </form>
    );
  }
}

ParticipationForm.propTypes = {
  tunnel: PropTypes.object.isRequired,
  optionParticipation: PropTypes.object,
  participationValue: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  change: PropTypes.func.isRequired,
  setDatesParticipationVolontaire: PropTypes.func.isRequired
}
// REDUX + REDUX FORM
const selectorForm = formValueSelector('participationForm');

const mapStateToProps = (state) => ({
  tunnel: state.tunnel,
  participationValue: selectorForm(state, 'participation'),
  optionParticipation: selectorForm(state, 'optionParticipation')
})
const mapDispatchToProps = (dispatch) => ({
  setDatesParticipationVolontaire: (dates) => dispatch(setDatesParticipationVolontaire(dates))
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'participationForm' })(ParticipationForm));

/* Built in imports */
import React, { Component } from 'react';

/* Third party imports */
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import EntrepriseForm from '../../../../components/US/assurance/forms/EntrepriseForm';
import { URLS_ASSURANCE } from '../../../../utils/constantes/ROUTES/ASSURANCE';
import {
  enregistrerEntreprise,
  mettreAjourEntreprise,
  mettreAjourInformationsComplementairesEntreprise
} from '../../../../services/EntrepriseService';
import { getAssuranceEntrepriseFormSubmit } from '../../../../utils/US/components/assurance/forms/entrepriseUtils';
import { setToken } from '../../../../services/ResourcesService';
import { ROUTE_CONTINUER_SUR_ORDINATEUR, SEUIL_MOBILE } from '../../../../common/Configuration';
import {
  setRetourRoute,
  setInformationsEntreprise,
  setInformationsDossier,
  setInformationsSirene,
  setInformationsUtilisateur,
  setNouvelleEntreprise
} from '../../../../modules/tunnel/redux/tunnelActions';
import { getJoursMoisOfDate } from '../../../../common/FunctionsUtils';

class AssuranceEntreprisePage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    // On modifie la route lorsque l'utilisateur clique le bouton Retour
    this.props.setRetourRoute(URLS_ASSURANCE.SIRET.substring(1));
    this.state = {
      redirect: false
    };
  }

  submit(values) {
    const uidUtilisateur = this.props.tunnel.utilisateur.uid;
    let valuesComplementaire = {
      valuesAenvoye: {
        dateClotureComptable: getJoursMoisOfDate(values.dateClotureComptable),
        nombreSalaries: values.nombreSalaries
      }
    };
    // S'il y a un dossier, on va mettre à jour les INFOS ENTREPRISE
    if (this.props.tunnel.dossier) {
      mettreAjourEntreprise(this.props.tunnel.entreprise.uid, getAssuranceEntrepriseFormSubmit(values, uidUtilisateur))
        .then(() => {
          mettreAjourInformationsComplementairesEntreprise(this.props.tunnel.entreprise.uid, valuesComplementaire).then(
            (entrepriseAjour) => {
              // on sauvegarde dans Redux les informations de l'entreprise
              this.props.setInformationsEntreprise(entrepriseAjour);
              this.setState({
                redirect: true
              });
            }
          );
        })
        .catch((error) => {
          if (error && error.message) {
            this.setState({
              errorMsg: error.message
            });
          }
        });

      // Pas de dossier ? on va créer l'entreprise
    } else {
      enregistrerEntreprise(getAssuranceEntrepriseFormSubmit(values, uidUtilisateur))
        .then(() =>
          mettreAjourInformationsComplementairesEntreprise(this.props.tunnel.entreprise.uid, valuesComplementaire).then(
            (ajoutEntreprise) => {
              // on sauvegarde dans Redux les informations de l'entreprise
              this.props.setNouvelleEntreprise(ajoutEntreprise.dossierAvecEntreprise);

              // Puis on met à jour le jeton JWT
              setToken(ajoutEntreprise.jwt);

              this.setState({
                redirect: true
              });
            }
          )
        )
        .catch((error) => {
          if (error && error.message) {
            this.setState({
              errorMsg: error.message
            });
          }
        });
    }
    this.props.setInformationsSirene(undefined);
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      // Pour les mobiles, on redirige vers la page informant de continuer sur un desktop
      if (window.innerWidth <= SEUIL_MOBILE) {
        return <Redirect to={ROUTE_CONTINUER_SUR_ORDINATEUR} />;
      }
      return <Redirect to={URLS_ASSURANCE.IDENTITE} />;
    }

    return (
      <div>
        <EntrepriseForm onSubmit={this.submit} />
      </div>
    );
  }
}

const mapStateToProps = ({ tunnel }) => ({
  tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setInformationsEntreprise: (entreprise) => dispatch(setInformationsEntreprise(entreprise)),
  setInformationsDossier: (dossier) => dispatch(setInformationsDossier(dossier)),
  setInformationsSirene: (sireneInfos) => dispatch(setInformationsSirene(sireneInfos)),
  setInformationsUtilisateur: (utilisateur) => dispatch(setInformationsUtilisateur(utilisateur)),
  setNouvelleEntreprise: (infos) => dispatch(setNouvelleEntreprise(infos))
});

AssuranceEntreprisePage.propTypes = {
  tunnel: PropTypes.object.isRequired,
  setRetourRoute: PropTypes.func.isRequired,
  setNouvelleEntreprise: PropTypes.func.isRequired,
  setInformationsSirene: PropTypes.func.isRequired,
  setInformationsDossier: PropTypes.func.isRequired,
  setInformationsEntreprise: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AssuranceEntreprisePage);

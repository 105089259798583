/* Built in imports */
import React from 'react';

/* Third party imports */
import { Field } from 'redux-form';

/* Internal imports */
import { YesChoix } from '../../../../form/Choix';
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';
import { requiredValidator } from '../../../../../common/Validators';
import { ANCIENNETE_MINIMUM } from '../../../../../utils/constantes/US/DISPOSITIFABONDEMENT';

const AncienneteMinimum = ({ ancienneteMinimum }) => (
  <>
    <div className="anciennete-mini-selector">
      <label className="label-checkbox-v2 anciennete-mini-selector">
        <TransHtmlText i18nKey="dispositif.info.abondement.anciennete.minimum" />
      </label>
    </div>
    <div className="tunnelRadios">
      {ANCIENNETE_MINIMUM.map((anciennete) => (
        <div className="tunnelRadio" key={anciennete.value}>
          <Field
            name="ancienneteMinimum"
            component={YesChoix}
            type="radio"
            value={anciennete.value}
            label={anciennete.label}
            id={anciennete.value}
            validate={requiredValidator}
          />
        </div>
      ))}
    </div>
    {!ancienneteMinimum && <p className="errorMsgTauxDegressif">Vous devez fixer la condition d’ancienneté.</p>}
  </>
);

export default AncienneteMinimum;

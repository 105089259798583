/* Built in import */
import React from 'react';

/* Internal imports */
import NoMobilePage from '../../../../components/yes/utils/NoMobilePage';
import RoutesUtils from '../../../../utils/routes/RoutesUtils';
import TunnelLayout from '../../../../layouts/default/TunnelLayout';
import { ACTIVITY_DOMAINS, FOLDER_TYPES } from '../../../../utils/constantes/ROUTES/ROUTES';
import { NoLayout } from '../../../../layouts/default/NoLayout';
import { PrivateAppRoute } from '../../../../helpers/routes/PrivateAppRoute';
import { getEpargneArborescence } from './EpargneArborescence';
import {
  ROUTE_CONTINUER_SUR_ORDINATEUR
} from '../../../../common/Configuration';

const buildRoute= (path, component, activityDomain, folderType) => {
  const routeProps = RoutesUtils
    .getRoutePropsByDomainAndType(component,
      TunnelLayout,
      activityDomain,
      ACTIVITY_DOMAINS.DEFAULT.NAME,
      folderType,
      FOLDER_TYPES.EPARGNE
    );

  return (
    <PrivateAppRoute
      exact path={path}
      layout={routeProps.layout}
      component={routeProps.component}
    />);
}

const PrivateRoutesEpargne = (activityDomain, folderType) => {
  const routesArray = getEpargneArborescence();
  return (
    [
      routesArray.map(route => {
        return(buildRoute(route.path, route.component, activityDomain, folderType));
      }),
      <PrivateAppRoute exact path={ROUTE_CONTINUER_SUR_ORDINATEUR} layout={NoLayout} component={NoMobilePage} />
    ]
  )
};

export default PrivateRoutesEpargne;
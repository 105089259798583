/* Built in imports */
import React, { Component } from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

/* Internal imports */
import YesInput from '../../../form/Input';
import { ENTREPRISE_FORM_FIELDS_EPARGNE } from '../../../../utils/constantes/US/ENTREPRISEFORM';
import { LISTE_FORMES_JURIDIQUE } from '../../../../common/constants';
import { NormalizeSiretSpace } from '../../../../common/Normalizers';
import { Required, Siret, CodeNaf, CodePostal, DateFr, MaxLength50, MaxLength100 } from '../../../../common/Validators';
import { YesButton } from '../../../form/Button';
import { YesDropdown } from '../../../form/Dropdown';
import { changeFieldsValues } from '../../../../utils/US/components/epargne_salariale/forms/entrepriseUtils';
import { isAssociation, isAutreFormeJuridique } from '../../../../common/FunctionsUtils';

class EntrepriseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAssociationForms: isAssociation(this.props.tunnel.entreprise.formeJuridique),
      showAutreForms: isAutreFormeJuridique(this.props.tunnel.entreprise.formeJuridique)
    };
  }

  componentDidMount() {
    const {
      change,
      tunnel: { sirene, entreprise }
    } = this.props;

    if ((sirene && !entreprise) || (sirene && entreprise)) {
      // CAS 1 : SIRENE RENSEIGNE SUR LE PREMIER ECRAN SANS ENTREPRISE PERSISTEE AUPARAVANT
      // CAS 2 : ENTREPRISE PERSISTEE ET SIRENE RENSEIGNE A L'ETAPE UNE (le bouton retour a été cliqué et l'utilisateur a renseigné un autre SIRENE)
      // dans ce cas, le formulaire sera renseigné par les informations remontées par SIRENE
      changeFieldsValues(sirene, change);
    } else if (entreprise && !sirene) {
      // CAS 3 : ENTREPRISE PERSISTEE, MAIS PAS DE SIRENE RENSEIGNE A LA PREMIERE ETAPE
      // dans ce cas, le formulaire serai renseigné par les informations entreprises persistées et potentiellement modifiés par l'utilisateur (sauf le SIRET)
      changeFieldsValues(entreprise, change);
    }
  }

  render() {
    const { handleSubmit, pristine, invalid, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <div className="titreFormulaire">
          <Trans i18nKey={'entreprise.titre'} />
        </div>

        <div className="grid-x grid-margin-x listed">
          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_EPARGNE.SIRET}
              labeltext="Siret"
              validate={[Required, Siret]}
              normalize={NormalizeSiretSpace}
              disabled={true}
              tabIndex="-1"
            />
          </div>
          <div className="cell medium-6" />
          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_EPARGNE.RAISON_SOCIALE}
              labeltext="Raison sociale"
              validate={[Required, MaxLength100]}
              maxLength="100"
            />
          </div>

          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              inputMode="numeric"
              pattern="\d*"
              name={ENTREPRISE_FORM_FIELDS_EPARGNE.DATE_CREATION}
              labeltext="Date de création"
              validate={[Required, DateFr]}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              placeholder="jj/mm/aaaa"
            />
          </div>

          <div className={`cell medium-6 codeNaf false`}>
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_EPARGNE.CODE_NAF}
              labeltext="Code NAF / APE"
              validate={[Required, CodeNaf]}
              maxLength="5"
            />
          </div>

          <div className="cell medium-6 formeJuridique">
            <Field
              name={ENTREPRISE_FORM_FIELDS_EPARGNE.FORME_JURIDIQUE}
              label="Forme juridique"
              component={YesDropdown}
              item={LISTE_FORMES_JURIDIQUE}
            />
          </div>

          <div className="cell medium-6" />

          <div className="cell medium-12">
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_EPARGNE.RUE}
              labeltext="Adresse de l'entreprise"
              validate={[Required, MaxLength100]}
              maxLength="100"
            />
          </div>

          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_EPARGNE.CODE_POSTAL}
              labeltext="Code postal"
              validate={[Required, CodePostal]}
              maxLength="5"
            />
          </div>

          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              name={ENTREPRISE_FORM_FIELDS_EPARGNE.VILLE}
              labeltext="Ville"
              validate={[Required, MaxLength50]}
              maxLength="50"
            />
          </div>
        </div>
        <div className="buttonCreationContainer">
          <YesButton text="Confirmer" disabled={invalid
            || submitting
            || pristine
          } />
        </div>
      </form>
    );
  }
}

EntrepriseForm.propTypes = {
  tunnel: PropTypes.shape({
    sirene: PropTypes.object,
    entreprise: PropTypes.object,
    dossier: PropTypes.object
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  change: PropTypes.func.isRequired
}

// REDUX + REDUX FORM
const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

export default connect(mapStateToProps)(reduxForm({ form: 'informationsEntrepriseForm' })(EntrepriseForm));

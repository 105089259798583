/* Internal imports */
import CITRA from '../../../../../api/citra';
import { TYPE_DOCUMENT } from '../../../../../common/Configuration';
import { isUploadListeRatificationEnabled } from '../../../../../services/TenantService';
import {
  DOCUMENTS_EPARGNE_OPT_ASSOC_DECLARE,
  DOCUMENTS_EPARGNE_OPT_ASSOC_UTILITE_PUBLIQUE,
  DOCUMENTS_EPARGNE_OPT_PROFESSION_LIBERALE,
  DOCUMENTS_EPARGNE_OPT_SOCIETES,
  DOCUMENTS_EPARGNE_REQ_ASSOC_DECLARE,
  DOCUMENTS_EPARGNE_REQ_ASSOC_UTILITE_PUBLIQUE,
  DOCUMENTS_EPARGNE_REQ_PROFESSION_LIBERALE,
  DOCUMENTS_EPARGNE_REQ_SOCIETES,
  FILESKEYS,
  FORMES_JURIDIQUES
} from '../../../../constantes/US/FINALISATION_FORM';

const getFormData = (file) => {
  const serializedFiles = new FormData();
  serializedFiles.append('files', file, file.name);
  return serializedFiles;
};

const getDirectorIdentityFiles = (justificatifIdentite) => {
  const identityFiles = [
    {
      file: null,
      networkError: false,
      networkErrorMessage: null,
      isLoading: false
    },{
      file: null,
      networkError: false,
      networkErrorMessage: null,
      isLoading: false
    }
  ];

  if(!justificatifIdentite || justificatifIdentite.length == 0) {
    return identityFiles;
  }

  if(justificatifIdentite && justificatifIdentite.length) {
    for(let i = 0 ; i < justificatifIdentite.length ; i++) {
      identityFiles[i].file = { name: justificatifIdentite[i] };
    }
  }

  return identityFiles;
};

const getFile = (file) => {
  return [
    {
      file: file?.length > 0 ? { name: file[0] } : null,
      networkError: false,
      networkErrorMessage: null,
      isLoading: false
    }
  ];
};

const getMultipleFilesData = (filesName) => {
  const multipleFiles = [];

  if (!filesName || filesName.length == 0) {
    multipleFiles.push({
      file: null,
      networkError: false,
      networkErrorMessage: null,
      isLoading: false
    });
    return multipleFiles;
  }

  for (let i = 0, filesNameLength = filesName.length; i < filesNameLength; i++) {
    multipleFiles.push({
      file: { name: filesName[i] },
      networkError: false,
      networkErrorMessage: null,
      isLoading: false
    });
  }

  return multipleFiles;
};

const filterDocuments = function(planExistant, annexeKyc, formeJuridique) {
  let { required: requiredDocuments, optional: optionalDocuments } = getDocumentsByLegalStatusType(formeJuridique);

  //Si pas de plan place, un supprime la partie plan existant de l'upload des pièces annexes
  if (!planExistant.accordInteressementEnPlace) {
    requiredDocuments = requiredDocuments.filter((document) => document.type !== TYPE_DOCUMENT.ACCORD_INTERESSEMENT);
  }
  if (!planExistant.accordParticipationEnPlace) {
    requiredDocuments = requiredDocuments.filter((document) => document.type !== TYPE_DOCUMENT.ACCORD_PARTICIPATION);
  }

  if(Array.isArray(annexeKyc?.actionnaires)) {
    const nbActionnairesPhysiques = annexeKyc.actionnaires.filter((actionnaire) => !actionnaire.beneficiaireIsPersonneMorale).length;

    //Si pas d'actionnaires physiques, pas besoin de demander l'upload de pièces d'identités pour KYC
    if(nbActionnairesPhysiques === 0) {
      requiredDocuments = requiredDocuments.filter((document) => document.type !== TYPE_DOCUMENT.ANNEXE_KYC_PERSON);
    }
    //Si pas d'actionnaires moraux, pas besoin de demander l'upload de KBis pour KYC
    if(annexeKyc?.actionnaires.length - nbActionnairesPhysiques === 0) {
      requiredDocuments = requiredDocuments.filter((document) => document.type !== TYPE_DOCUMENT.ANNEXE_KYC_ENTITY);
    }
  }

  //TODO: supprimer ce if si ajout de la PJ ou changer variable d'env à TRUE
  if (!isUploadListeRatificationEnabled()) {
    requiredDocuments = requiredDocuments.filter((document) => document.type !== TYPE_DOCUMENT.CONSULTATION_ENTREPRISE);
  }

  return {
    required: requiredDocuments,
    optional: optionalDocuments
  };
};

const sendFolder = function (url) {
  return CITRA().post(url);
};

const setCurrentFile = (files, setFiles, index, key, isLoading, networkError, networkErrorMessage) => {
  files[key][index] = {
    ...files[key][index],
    isLoading,
    networkErrorMessage,
    networkError
  };

  setFiles({
    ...files,
    [key]: files[key]
  });
};

const uploadFile = function (url, file) {
  return CITRA().post(url, getFormData(file));
};

const deleteFile = function(url, fileName) {
  return CITRA().delete(`${url}?nom=${encodeURIComponent(fileName)}`);
};

/**
 * Retourne la liste des documents associés à la forme juridique de l'entreprise souscriptrice
 * @param legalStatus {String}
 */
const getDocumentsByLegalStatusType = (legalStatus) => {
  switch (legalStatus) {
    case FORMES_JURIDIQUES.ASSOCIATION_DECLARE.key:
      return {
        required: DOCUMENTS_EPARGNE_REQ_ASSOC_DECLARE,
        optional: DOCUMENTS_EPARGNE_OPT_ASSOC_DECLARE
      }
    case FORMES_JURIDIQUES.ASSOCIATION_UTILITE_PUBLIQUE.key:
      return {
        required: DOCUMENTS_EPARGNE_REQ_ASSOC_UTILITE_PUBLIQUE,
        optional: DOCUMENTS_EPARGNE_OPT_ASSOC_UTILITE_PUBLIQUE
      }
    case FORMES_JURIDIQUES.PROFESSION_LIBERALE.key:
      return {
        required: DOCUMENTS_EPARGNE_REQ_PROFESSION_LIBERALE,
        optional: DOCUMENTS_EPARGNE_OPT_PROFESSION_LIBERALE
      }
    default:
      return {
        required: DOCUMENTS_EPARGNE_REQ_SOCIETES,
        optional: DOCUMENTS_EPARGNE_OPT_SOCIETES
      }
  }
}

const getFormeJuridiqueByCode = (codeFormeJuridique) => {
  for(const formeJuridique of Object.values(FORMES_JURIDIQUES)) {
    if(formeJuridique.codes && Array.isArray(formeJuridique.codes) && formeJuridique.codes.includes(parseInt(codeFormeJuridique))) {
      return formeJuridique.key;
    }
  }
  return FORMES_JURIDIQUES.SOCIETE.key;
}

const checkMissingRequiredDocuments = (documents, files) => {
  for(const requiredDocument of documents.required) {
    if(!files[requiredDocument.key] || files[requiredDocument.key].find((upload) => (upload.file !== undefined && upload.file !== null)) === undefined) {
      return true;
    }
  }
  return false;
}


export default {
  FILESKEYS,
  checkMissingRequiredDocuments,
  deleteFile,
  filterDocuments,
  getDirectorIdentityFiles,
  getFile,
  getFormeJuridiqueByCode,
  getMultipleFilesData,
  sendFolder,
  setCurrentFile,
  uploadFile
};

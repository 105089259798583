/* built-in imports */
import React, { useEffect, useState} from "react";

/* third-party imports */
import PropTypes from 'prop-types';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

/* internal imports */
import KycForm from '../../../../components/US/epargne_salariale/forms/KYC/KycForm';
import { URLS_MAROQUINERIE } from "../../../../utils/constantes/ROUTES/MAROQUINERIE";
import { formatActionnaires } from '../../../../components/US/epargne_salariale/forms/KYC/kycMapper';
import { sendKycFields } from "../../../../services/DossierService";
import { setInformationsDossier, setRetourRoute } from "../../../../modules/tunnel/redux/tunnelActions";
import { stringToBoolean } from "../../../../common/FunctionsUtils";

const MaroquinerieKycPage = ({ dossier, setRetourRoute, setInformationsDossier }) => {

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    // On modifie la route lorsque l'utilisateur clique le bouton Retour
    if (!dossier.planExistant?.accordParticipationEnPlace) {
      setRetourRoute(URLS_MAROQUINERIE.PARTICIPATION.substring(1));
    }
    else if (!dossier.planExistant?.accordInteressementEnPlace) {
      setRetourRoute(URLS_MAROQUINERIE.INTERESSEMENT.substring(1));
    }
    else {
      setRetourRoute(URLS_MAROQUINERIE.DISPOSITIF_ABONDEMENT.substring(1));
    }
  }, []);

  const submit = (values) => {
    sendKycFields(dossier.uid, formatKyc(values))
      .then((annexe) => {
        setInformationsDossier(annexe);
        setRedirect(true);
      })
      .catch((error) => {
        if (error && error.message) {
          error.log("error: ", error.message);
        }
      });
  }

  const formatKyc = (values) => {
    return {
      actionnaires: formatActionnaires(values.actionnaires),
      adressePostaleEntreprise: values.isSameCompanyAddress
          ? null
          : {
            codePostal: values.adressePostaleEntrepriseCode,
            rue: values.adressePostaleEntrepriseRue,
            ville: values.adressePostaleEntrepriseVille
          },
      bonsPorteur: stringToBoolean(values.isBonsPorteur) ? values.bonsPorteur : null,
      codeBIC: stringToBoolean(values.isBanque) ? values.codeBIC : null,
      entrepriseCotee: stringToBoolean(values.isEntrepriseCotee) ? values.entrepriseCotee : null,
      paysCotation: stringToBoolean(values.isEntrepriseCotee) ? JSON.parse(values.paysCotation) : null,
      paysActivite: {
        chiffreAffaire: values.chiffreAffaire,
        pays: JSON.parse(values.pays)
      },
      paysFiscalEntreprise: JSON.parse(values.paysFiscalEntreprise),
      paysPersonneMorale: stringToBoolean(values.needPaysPersonneMorale) ? JSON.parse(values.paysPersonneMorale) : null,
      principauxMarches: values.principauxMarches,
      principauxPays: values.principauxPays,
      regulateur: stringToBoolean(values.regulateur)
          ? {
            nomRegulateur: values.nomRegulateur,
            referenceAgrement: values.referenceAgrement
          }
          : null,
      lieuExercice: {
        france: values.hasActivityFrance,
        ue: values.hasActivityUE ? values.countryActivityUE : null,
        horsUe: values.hasActivityHorsUE ? values.countryActivityHorsUE : null
      }
    };
  }

  return (
    redirect
      ? (<Redirect to={URLS_MAROQUINERIE.TARIFICATION} />)
      : (
          <div>
            <KycForm onSubmit={submit} />
          </div>
      )
  );
}

MaroquinerieKycPage.propTypes = {
  dossier: PropTypes.object.isRequired,
  setRetourRoute: PropTypes.func.isRequired,
  setInformationsDossier: PropTypes.func.isRequired
}

const mapStateToProps = ({ tunnel: { dossier } }) => ({
  dossier
});

const mapDispatchToProps = {
  setRetourRoute,
  setInformationsDossier
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MaroquinerieKycPage);

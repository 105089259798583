import { CITRA_DATA_ACTIONS } from '../../utils/constantes/REDUCERS/CITRA_DATA_ACTIONS';

export const citraDataReducer = (state = {}, action) => {
  switch (action.type) {
    case CITRA_DATA_ACTIONS.UPDATE_PASS_VALUE_SUCCESS:
      return {
        ...state,
        pass: action.res
      };
    case CITRA_DATA_ACTIONS.UPDATE_PASS_VALUE_FAILED:
      console.log("Error retrieving PASS value : " + action.error);
      return {
        ...state
      };
    default:
      return {
        ...state
      };
  }
};
import { PREFIX_BACK } from '../common/Configuration';
import { fetch, uploadFiles } from './ResourcesService';

const fichesUrl = `${PREFIX_BACK}fiches`;

const putDossierElement = (uid, file, urlPart) => uploadFiles(true, `${fichesUrl}${uid}/${urlPart}`, 'POST', file);
const deleteDossierElement = (uid, nom, urlPart) => fetch(true, `${fichesUrl}${uid}/${urlPart}?nom=${nom}`, { method: 'DELETE' });

export const mettreAjourFichePersonneMoralePpe = (uidDossier, { valuesAenvoyer }) => {
  return fetch(true, `${fichesUrl}/fichePersonneMorale/${uidDossier}/ppe`, {
    method: 'PUT',
    body: JSON.stringify(valuesAenvoyer)
  });
};

export const stockerLiasseFiscale = (uid, files) => putDossierElement(uid, files, 'liasseFiscale');

export const supprimerLiasseFiscale = (uid, nom) => deleteDossierElement(uid, nom, 'liasseFiscale');


/* Built in imports */
import React from 'react';

/* Third party imports */
import { Field } from 'redux-form';
import { withTranslation } from 'react-i18next';

/* Internal imports */
import SelectCountries from '../../../../form/SelectCountries';
import StaticSiteConfig from '../../../../../common/StaticSiteConfig';
import YesInput from '../../../../form/Input';
import { MOYENS_PAIEMENT } from '../../../../../utils/constantes/US/MOYENSPAIEMENT';
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';
import { YesChoix } from '../../../../form/Choix';
import { requiredValidator } from '../../../../../common/Validators';

const TiersPayeur = ({ t }) => (
  <>
    <div className="grid-x grid-margin-x">
      <div className="cell medium-12 grid-x grid-margin-x align-middle">
        <h3 className="cell medium-7">
          <TransHtmlText i18nKey="moyenPaiement.tiers.nature" params={{ config: StaticSiteConfig }} />
        </h3>
        <div className="tunnelRadios cell medium-5">
          <div className="tunnelRadios">
            <div className="tunnelRadio">
              <Field
                name={`${MOYENS_PAIEMENT.NATURE_PAYEUR}`}
                component={YesChoix}
                type="radio"
                value={'personnePhysique'}
                label="Physique"
                id={`${MOYENS_PAIEMENT.NATURE_PAYEUR}-pp`}
              />
            </div>

            <div className="tunnelRadio">
              <Field
                name={`${MOYENS_PAIEMENT.NATURE_PAYEUR}`}
                component={YesChoix}
                type="radio"
                value={'personneMorale'}
                label="Morale"
                id={`${MOYENS_PAIEMENT.NATURE_PAYEUR}-pm`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="grid-x grid-margin-x fields">
      <div className="cell medium-6 text-field  ">
        <Field
          id={MOYENS_PAIEMENT.NOM}
          name={MOYENS_PAIEMENT.NOM}
          component={YesInput}
          labeltext={t('moyenPaiement.tiers.nom')}
          validate={requiredValidator}
        />
      </div>
      <div className="cell medium-6 text-field  ">
        <Field
          id={MOYENS_PAIEMENT.PRENOM}
          name={MOYENS_PAIEMENT.PRENOM}
          component={YesInput}
          labeltext={t('moyenPaiement.tiers.prenom')}
          validate={requiredValidator}
        />
      </div>
      <div className="cell medium-6 text-field  ">
        <Field
          id={MOYENS_PAIEMENT.MOTIF_TIERS}
          name={MOYENS_PAIEMENT.MOTIF_TIERS}
          component={YesInput}
          labeltext={t('moyenPaiement.tiers.motif')}
          validate={requiredValidator}
        />
      </div>
      <div className="cell medium-6 text-field  ">
        <Field
          id={MOYENS_PAIEMENT.LIEN}
          name={MOYENS_PAIEMENT.LIEN}
          component={YesInput}
          labeltext={t('moyenPaiement.tiers.lien')}
          validate={requiredValidator}
        />
      </div>
    </div>

    <div className="grid-x grid-margin-x">
      <div className="cell medium-12 grid-x grid-margin-x align-middle">
        <div className="cell medium-12">
          <h3>
            <TransHtmlText i18nKey="moyenPaiement.tiers.addresse" params={{ config: StaticSiteConfig }} />
          </h3>
        </div>
        <div className="grid-x grid-margin-x fields">
          <div className="cell medium-6 text-field  ">
            <Field
              id={MOYENS_PAIEMENT.ADRESSE}
              name={MOYENS_PAIEMENT.ADRESSE}
              component={YesInput}
              labeltext={t('common.addresse.rue')}
              validate={requiredValidator}
            />
          </div>
          <div className="cell medium-6 text-field  ">
            <Field
              id={MOYENS_PAIEMENT.CODE_POSTAL}
              name={MOYENS_PAIEMENT.CODE_POSTAL}
              component={YesInput}
              labeltext={t('common.addresse.code')}
              validate={requiredValidator}
            />
          </div>
          <div className="cell medium-6 text-field  ">
            <Field
              id={MOYENS_PAIEMENT.VILLE}
              name={MOYENS_PAIEMENT.VILLE}
              component={YesInput}
              labeltext={t('common.addresse.ville')}
              validate={requiredValidator}
            />
          </div>
          <div className="cell medium-6 text-field  ">
            <Field
              id={MOYENS_PAIEMENT.PAYS}
              name={MOYENS_PAIEMENT.PAYS}
              component={SelectCountries}
              labeltext={t('common.addresse.pays')}
              validate={requiredValidator}
            />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default withTranslation()(TiersPayeur);

import API from '../API';
import inputUtils from '../../inputs';
import { TYPE_DOCUMENT } from '../../../common/Configuration';


export const FORMES_JURIDIQUES = {
  ASSOCIATION_DECLARE: {
    key: 'ASSOCIATION_DECLARE',
    label: 'Association déclarée',
    codes: [9220]
  },
  ASSOCIATION_UTILITE_PUBLIQUE: {
    key: 'ASSOCIATION_UTILITE_PUBLIQUE',
    label: 'Association d\'utilité publique',
    codes: [9230]
  },
  PROFESSION_LIBERALE: {
    key: 'PROFESSION_LIBERALE',
    label: 'Profession libérale',
    codes: [5585, 5485, 5785, 5385]
  },
  SOCIETE: {
    key: 'SOCIETE',
    label: 'Entreprise'
  }
};

/**
 * Les pièces jointes doivent suivre le schema suivant :
 *  {
 *    -- Champs Obligatoires --
 *    type : Type utilisé côté CITRA pour faire le mapping des PJs
 *    tradKey : clé dans le fichier de trad pour le libelle
 *    entite : repertoire de stockage sur le s3 permettant de trier les PJs
 *    key : Clé utilisée pour l'objet react
 *    baseUrl : url d'upload/delete sur CITRA
 *    fileExtension : extension du fichier (pdf, xlsx, ...)
 *
 *    -- Champs Optionnels --
 *    canBeJustOne : booléen qui indique si il est possible de renseigner un seul fichier pour cette section
 *    canAddFiles : booléen qui indique si l'utilisateur peut rajouter des fichiers
 *  }
 */

export const FILESKEYS = {
  //Communes
  BENEFICIARYLIST: 'beneficiaryList',
  DIRECTORIDENTITY: 'directorIdentity',
  EXISTINGPLAN: 'existingPlan',
  KYC_PERSON: 'kycPersonIdentities',
  KYC_ENTITY: 'kycEntityIdentities',
  PARTICIPATIONAGREEMENT: 'participationAgreement',
  POWERDELEGATION: 'powerDelegation',
  PROFITSHARINGAGREEMENT: 'profitSharingAgreement',
  RIB: 'rib',
  SPECIALCASES: 'specialCases',

  //Sociétés
  CAPITALDISTRIBUTION: 'capitalDistribution',
  ENTERPRISECONSULTING: 'enterpriseConsulting',
  ENTERPRISELEGALESTATUS: 'enterpriseLegalStatus',
  KBIS: 'kbis',

  //Associations déclarés & publiques
  ASSOCIATIONLEGALSTATUS: 'associationLegalStatus',
  RECEPDECLAPREF: 'recepisseDeclarationPrefecture',
  RECEPINSERTION: 'recepisseInsertion',

  //Associations publiques
  DECRETRECO: 'decretReconnaissance',

  //Professions libérales
  PROFESSIONALCARD: 'professionalCard',
  ORDEROFDOCTORS: 'orderOfDoctors'

};

/**
 * Documents obligatoires & optionnels - COMMUNS
 */
const DOCUMENTS_EPARGNE_REQ_COMMUNS = [
  {
    type: TYPE_DOCUMENT.JUSTIFICATIF_IDENTITE,
    tradKey: 'directorIdentity.label',
    entite: API.CITRA.USERS,
    key: FILESKEYS.DIRECTORIDENTITY,
    baseUrl: API.USERS.JUSTIFICATIFIDENTITY,
    fileExtension: inputUtils.getPdfFileType(),
    canBeJustOne: true
  },
  {
    type: TYPE_DOCUMENT.LISTE_BENEFICIAIRES,
    tradKey: 'beneficiaryList.label',
    entite: API.CITRA.FOLDERS,
    key: FILESKEYS.BENEFICIARYLIST,
    baseUrl: API.FOLDERS.BENEFICIARYLIST,
    fileExtension: inputUtils.getExcelFileTypes(),
    tooltipTradKey: 'beneficiaryList.tooltip'
  },
  {
    type: TYPE_DOCUMENT.RIB,
    tradKey: 'rib.label',
    entite: API.CITRA.FOLDERS,
    key: FILESKEYS.RIB,
    baseUrl: API.FOLDERS.RIB,
    fileExtension: inputUtils.getPdfFileType()
  },
  {
    type: TYPE_DOCUMENT.ANNEXE_KYC_PERSON,
    tradKey:
      'kycCNIIdentities.label',
    entite: API.CITRA.FOLDERS,
    key: FILESKEYS.KYC_PERSON,
    baseUrl: API.FOLDERS.KYC_PERSON,
    fileExtension: inputUtils.getPdfFileType(),
    canBeJustOne: true,
    canAddFiles: true
  },
  {
    type: TYPE_DOCUMENT.ANNEXE_KYC_ENTITY,
    tradKey:
      'kycKbisIdentities.label',
    entite: API.CITRA.FOLDERS,
    key: FILESKEYS.KYC_ENTITY,
    baseUrl: API.FOLDERS.KYC_ENTITY,
    fileExtension: inputUtils.getPdfFileType(),
    canBeJustOne: true,
    canAddFiles: true
  },
  {
    type: TYPE_DOCUMENT.ACCORD_INTERESSEMENT,
    tradKey: 'profitSharingAgreement.label',
    entite: API.CITRA.FOLDERS,
    key: FILESKEYS.PROFITSHARINGAGREEMENT,
    baseUrl: API.FOLDERS.PROFITSHARINGAGREEMENT,
    fileExtension: inputUtils.getPdfFileType()
  },
  {
    type: TYPE_DOCUMENT.ACCORD_PARTICIPATION,
    tradKey: 'participationAgreement.label',
    entite: API.CITRA.FOLDERS,
    key: FILESKEYS.PARTICIPATIONAGREEMENT,
    baseUrl: API.FOLDERS.PARTICIPATIONAGREEMENT,
    fileExtension: inputUtils.getPdfFileType()
  }
];
const DOCUMENTS_EPARGNE_OPT_COMMUNS = [];

/**
 * Documents obligatoires & optionnels - SOCIETES
 */
export const DOCUMENTS_EPARGNE_REQ_SOCIETES = [
  ...DOCUMENTS_EPARGNE_REQ_COMMUNS,
  {
    type: TYPE_DOCUMENT.JUSTIFICATIF_IDENTITE_ENTREPRISE,
    tradKey: 'kbis.label',
    entite: API.CITRA.ENTERPRISE,
    key: FILESKEYS.KBIS,
    baseUrl: API.ENTERPRISE.JUSTIFICATIFIDENTITY,
    fileExtension: inputUtils.getPdfFileType(),
    tooltipTradKey: 'kbis.tooltip'
  },
  {
    type: TYPE_DOCUMENT.STATUTS_ENTREPRISE,
    tradKey: 'enterpriseLegalStatus.label',
    entite: API.CITRA.ENTERPRISE,
    key: FILESKEYS.ENTERPRISELEGALESTATUS,
    baseUrl: API.ENTERPRISE.STATUTS,
    fileExtension: inputUtils.getPdfFileType()
  }
];
export const DOCUMENTS_EPARGNE_OPT_SOCIETES = [
  ...DOCUMENTS_EPARGNE_OPT_COMMUNS,
  {
    type: TYPE_DOCUMENT.CONSULTATION_ENTREPRISE,
    tradKey: 'enterpriseConsulting.label',
    entite: API.CITRA.FOLDERS,
    key: FILESKEYS.ENTERPRISECONSULTING,
    baseUrl: API.FOLDERS.ENTERPRISECONSULTING,
    fileExtension: inputUtils.getPdfFileType()
  }
];

/**
 * Documents obligatoires & optionnels - ASSOCIATIONS DECLARES
 */
export const DOCUMENTS_EPARGNE_REQ_ASSOC_DECLARE = [
  ...DOCUMENTS_EPARGNE_REQ_COMMUNS,
  {
    type: TYPE_DOCUMENT.STATUTS_ENTREPRISE,
    tradKey: 'associationLegalStatus.label',
    entite: API.CITRA.ENTERPRISE,
    key: FILESKEYS.ASSOCIATIONLEGALSTATUS,
    baseUrl: API.ENTERPRISE.STATUTS,
    fileExtension: inputUtils.getPdfFileType()
  },
  {
    type: TYPE_DOCUMENT.RECEP_DECLA_PREF,
    tradKey: 'recepDeclaPref.label',
    entite: API.CITRA.ENTERPRISE,
    key: FILESKEYS.RECEPDECLAPREF,
    baseUrl: API.ENTERPRISE.RECEP_DECLA_PREF,
    fileExtension: inputUtils.getPdfFileType()
  },
  {
    type: TYPE_DOCUMENT.RECEP_INSERTION,
    tradKey: 'recepInsertion.label',
    entite: API.CITRA.ENTERPRISE,
    key: FILESKEYS.RECEPINSERTION,
    baseUrl: API.ENTERPRISE.RECEP_INSERTION,
    fileExtension: inputUtils.getPdfFileType()
  }
];
export const DOCUMENTS_EPARGNE_OPT_ASSOC_DECLARE = [
  ...DOCUMENTS_EPARGNE_OPT_COMMUNS
];

/**
 * Documents obligatoires & optionnels - ASSOCIATIONS UTILITE PUBLIQUE
 */
export const DOCUMENTS_EPARGNE_REQ_ASSOC_UTILITE_PUBLIQUE = [
  ...DOCUMENTS_EPARGNE_REQ_ASSOC_DECLARE,
  {
    type: TYPE_DOCUMENT.DECRET_RECO,
    tradKey: 'decretReco.label',
    entite: API.CITRA.ENTERPRISE,
    key: FILESKEYS.DECRETRECO,
    baseUrl: API.ENTERPRISE.DECRET_RECO,
    fileExtension: inputUtils.getPdfFileType()
  }
];
export const DOCUMENTS_EPARGNE_OPT_ASSOC_UTILITE_PUBLIQUE = [
  ...DOCUMENTS_EPARGNE_OPT_COMMUNS
];

/**
 * Documents obligatoires & optionnels - PROFESSION LIBERALE
 */
export const DOCUMENTS_EPARGNE_REQ_PROFESSION_LIBERALE = [
  //Carte professionnelle
  ...DOCUMENTS_EPARGNE_REQ_COMMUNS,
  {
    type: TYPE_DOCUMENT.CARTE_PROFESSIONELLE,
    tradKey: 'professionalCard.label',
    entite: API.CITRA.ENTERPRISE,
    key: FILESKEYS.PROFESSIONALCARD,
    baseUrl: API.ENTERPRISE.PROFESSIONAL_CARD,
    fileExtension: inputUtils.getPdfFileType()
  },
  {
    type: TYPE_DOCUMENT.STATUTS_ENTREPRISE,
    tradKey: 'orderOfDoctors.label',
    entite: API.CITRA.ENTERPRISE,
    key: FILESKEYS.ORDEROFDOCTORS,
    baseUrl: API.ENTERPRISE.STATUTS,
    fileExtension: inputUtils.getPdfFileType()
  }
];
export const DOCUMENTS_EPARGNE_OPT_PROFESSION_LIBERALE = [
  ...DOCUMENTS_EPARGNE_OPT_COMMUNS
]

const SET_TOKEN = "auth/SET_TOKEN";
const REMOVE_TOKEN = "auth/REMOVE_TOKEN";
const initialState = {};

/**
 * Le state est un objet au lieu d'un string car on va integrer
 * le token de renouvellement du token jwt.
 * Ce state doit être sauvé dans le localstorage par Redux-persist.
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.token
      };
    case REMOVE_TOKEN:
      return {
        ...state,
        token: undefined
      };
    default:
      return state;
  }
};

export const setToken = token => ({
  type: SET_TOKEN,
  token
});

export const removeToken = () => ({
  type: REMOVE_TOKEN
});

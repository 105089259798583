/* built-in imports */
import React from 'react';

/* third-party imports */
import DIVIDER from '../../../utils/constantes/DS/DIVIDER';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';

const dividerCustom = ({ color, children, sx, ...other }) => {
  const getStyle = () => {
    let style = {};

    switch (color) {
      case DIVIDER.COLOR.PRIMARY:
        style.color = DIVIDER.COLOR.PRIMARY;
        style['&::before, &::after'] = {
          ...style['&::before, &::after'],
          borderColor: DIVIDER.COLOR.PRIMARY
        }
        break;
      default:
        style.color = DIVIDER.COLOR.DEFAULT;
        style['&::before, &::after'] = {
          ...style['&::before, &::after'],
          borderColor: DIVIDER.COLOR.DEFAULT
        }
    }

    if(children) {
      centerElem(style);
    }

    if(sx && Object.keys(sx).length > 0) {
      style = Object.assign(sx, style);
    }

    return style;
  }

  const centerElem = (style) => {
    style.padding = '1rem 0';
  }

  return (
    <>
      {children
        ? <Divider {...other} sx={getStyle}>
          {children}
        </Divider>
        : <Divider {...other} sx={getStyle} />
      }
    </>
  );
}

dividerCustom.propTypes = {
  color: PropTypes.string
}

export default dividerCustom
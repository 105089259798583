import React, { Component } from "react";
import { Link } from "react-router-dom";

export class YesInputPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "password"
    };

    // permet de propager le "this" dans la méthode, pour pouvoir ensuite faire le this.setState
    this.showHide = this.showHide.bind(this);
  }

  showHide(e) {
    // TODO : what ?
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      type: this.state.type === "input" ? "password" : "input"
    });
  }

  render() {
    const {
      input,
      labeltext,
      passwordForgotten,
      indication,
      customError,
      meta: { touched, active, error, warning, valid },
      ...rest
    } = this.props;

    let customErrorMsg = customError;

    const classnameInput = input.value ? "inputText hasValue" : "inputText";
    const classnameDivInput =
      touched && (error || (!active && customErrorMsg))
        ? "divInput errorInput"
        : "divInput";
    const url = "/assets/img/icons/" + this.state.type + "_eye.svg";

    let classnameValid = "";
    if (valid) {
      classnameValid = " inputValid";
    }

    let classnameLabel = "label-input";
    if (this.props.input.value.length !== 0 || this.props.meta.active) {
      classnameLabel = "label-input label-color";
    }

    return (
      <div className="inputEnabled">
        <div>
          <div className={classnameDivInput + classnameValid}>
            <span className="eyeContainer">
              <img
                onClick={this.showHide}
                className="oeil-yes"
                src={url}
                alt="oeil"
              />
            </span>
            <input
              className={classnameInput}
              {...input}
              {...rest}
              type={this.state.type}
            />
            <label className={classnameLabel}>{labeltext}</label>
          </div>
          {touched &&
            ((error && <span className="errorMsgInput">{error}</span>) ||
              (!active && customErrorMsg && (
                <span className="errorMsgInput">{customErrorMsg}</span>
              )) ||
              (warning && <span className="warningInput">{warning}</span>))}
        </div>
        <span className="conditionPassword">
          {touched && error !== undefined && (
            <span className="show-for-small-only deplacementMdpOublie" />
          )}

          {passwordForgotten === "true" && (
            <Link to="/demandeChangementMotDePasse">
              <span className="mdpOublieSpan mdpOublie">
                Mot de passe oublié ?
              </span>
            </Link>
          )}

          {indication === "true" && (!touched || error === undefined) && (
            <span className="indicationPassword">
              10 caractères minimum, dont au moins une minuscule, une majuscule,
              un chiffre et un caractère spécial
            </span>
          )}
        </span>
      </div>
    );
  }
}

/* built-in imports */
import React from "react";

/* third-party imports */
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "../data-display/Typography";
import { Trans } from "react-i18next";
import { styled } from "@mui/material/styles";

/* internal imports */
import { TEXT_PLACEMENT } from "../../../utils/constantes/DS/PROGRESS";

const ProgressRoot = styled(CircularProgress, { name: 'CircularProgress' })(() => ({
  '&.MuiCircularProgress-root': {
    color: 'var(--cn-color-primary)',
    margin: '1rem'
  }
}));

const ProgressCustom = ({ description,
                          textPlacement = TEXT_PLACEMENT.BOTTOM,
                          value,
                          ...other}) => {
  const getPlacementStyle = () => {
    let placementStyle = {
      display: 'flex',
      alignItems: 'center'
    };

    switch (textPlacement) {
      case TEXT_PLACEMENT.TOP:
        placementStyle = {
          ...placementStyle,
          flexDirection: 'column-reverse'
        }
        break;
      case TEXT_PLACEMENT.BOTTOM:
        placementStyle = {
          ...placementStyle,
          flexDirection: 'column'
        }
        break;
      case TEXT_PLACEMENT.LEFT:
        placementStyle = {
          ...placementStyle,
          flexDirection: 'row-reverse'
        }
        break;
      case TEXT_PLACEMENT.RIGHT:
        placementStyle = {
          ...placementStyle,
          flexDirection: 'row'
        }
        break;
    }

    return placementStyle
  }

  const getProgressType = () => {
    let progress;

    if (value) {
      progress = (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <ProgressRoot
            {...other}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div">
              {`${value}%`}
            </Typography>
          </Box>
        </Box>
      )
    }
    else {
      progress = (<ProgressRoot {...other} />)
    }

    return progress;
  }

  return (
    <Box sx={getPlacementStyle()}>
      {getProgressType()}
      <Typography>
        <Trans i18nKey={description} />
      </Typography>
    </Box>
  )
}

export default ProgressCustom;
// Liste des taux d'abondement possible
export const LISTE_TAUX_ABONDEMENT_MAROQUINERIE = [
  {
    label: '50 %',
    value: 0.5
  },
  {
    label: '100 %',
    value: 1
  },
  {
    label: '150 %',
    value: 1.5
  }
];

export const MODALITES = {
  DATE_EFFET: 'dateEffet'
}
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  isDeconnexionVersPortailEnabled,
  isRoutesAuthentificationEnabled
} from "../../../services/TenantService";

export default class Home extends Component {
  render() {
    if (
      isDeconnexionVersPortailEnabled() &&
      !isRoutesAuthentificationEnabled()
    ) {
      return (window.location = process.env.REACT_APP_STATIC_URL);
    }
    return <Redirect to={"/connexion"} />;
  }
}

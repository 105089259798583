import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "./notificationActions";

export const initialState = {
  message: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return {
        ...state,
        message: action.message,
        id: action.id
      };
    }
    case REMOVE_NOTIFICATION: {
      return initialState;
    }

    default:
      return state;
  }
};

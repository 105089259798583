/* built-in imports */
import React, { useEffect } from 'react';

/* third-party imports */
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

/* Internal imports */
import Alert from '../../../DS/feedback/Alert';
import FormLabel from '../../../DS/inputs/form/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '../../../DS/inputs/InputLabel';
import MenuItem from '../../../DS/inputs/MenuItem';
import Select from '../../../DS/inputs/Select';
import Switch from '../../../DS/inputs/Switch';
import TextField from '../../../DS/inputs/TextField';
import Typography from '../../../DS/data-display/Typography';
import { Field, reduxForm } from 'redux-form';
import { REGIMENTS } from '../../../../utils/constantes/US/WORKADVICESSHEETSMODAL';
import INPUTS from '../../../../utils/constantes/DS/INPUTS';
import { isRegimentSelectedError } from '../../../../utils/US/components/workAdvicesSheetsModal/workAdvicesSheetsEmployeesForm/employeesSubForm';
import inputUtils from '../../../../utils/inputs';

const renderSwitch = ( { checked, input } ) => (
  <Switch
    inputProps={{ role: 'switch' }}
    checked={checked}
    {...input}
  />
)

const renderSelectField = ({ input, children, ...custom }) => (
  <Select
    {...input}
    {...custom}
  >
    {children}
  </Select>
)

const renderTextField = ({ input, label, placeholder, ...custom }) => (
  <TextField
    sx={{ display: 'flex'}}
    label={label}
    placeholder={placeholder}
    variant="filled"
    {...input}
    {...custom}
  />
)

const EmployeesSubForm = function(props) {
  const tradKey = 'components.US.form.work-advices-sheets-employees.employees-sub-form',
    { t }   = useTranslation(),
    ageMax  = 99,
    employeesCountMax = 1000000,
    iddcMax = 9999,
    {
      isEmployeesRegiment,
      regimentInfos,
      showErrors,
      handleIsEmployeesRetirementChange,
      handleRegimentInfosChange,
    } = props;

  const {
    regimentSelected,
    employeesAverageAge,
    employeesCount,
    iddc,
    conventionName
  } = regimentInfos;

  useEffect(() => {
    props.change("regime-select", regimentSelected);
    props.change("employees-average-age", employeesAverageAge);
    props.change("employees-count", employeesCount);
    props.change("employees-iddc", iddc);
    props.change("employees-convention", conventionName);
  });

  return (
    <Box
      component="div"
      sx={{ display: 'flex' }}
    >
      <Grid container>
        <Grid item xs={12}>
          <FormGroup
            sx={{
              flexDirection: 'row',
              flexGrow: 1,
              alignItems: 'center',
              flexWrap: 'nowrap',
            }}
          >
            <FormLabel
              sx={{ flexGrow: 1 }}
            >
              {t(`${tradKey}.retirement.label`)}
            </FormLabel>
            <Stack
              direction="row"
              alignItems="center"
            >
              <Typography>{t('utils.form.radio.no')}</Typography>
              <Field
                name="employees-regiment"
                component={renderSwitch}
                onChange={handleIsEmployeesRetirementChange}
                checked={isEmployeesRegiment}
              />
              <Typography>{t('utils.form.radio.yes')}</Typography>
            </Stack>
          </FormGroup>
        </Grid>
        { isEmployeesRegiment &&
          <Grid item xs={12}
            sx={{ paddingTop: '1em' }}
          >
            <FormControl
              variant="filled"
              sx={{ display: 'flex' }}
              required
            >
              <InputLabel id="employees-regime">{t(`${tradKey}.retirement.regime.label`)}</InputLabel>
              <Field
                labelId="employees-regime"
                name="regime-select"
                component={renderSelectField}
                value={regimentSelected}
                onChange={(event, value) => {
                  event = {
                    target:{
                      value: value,
                    }
                  };
                  handleRegimentInfosChange(event, 'regimentSelected')
                }}
                disabled
              >
                <MenuItem name='dues' value={REGIMENTS.DUES}>{t(`${tradKey}.retirement.select.regime.dues`)}</MenuItem>
              </Field>
            </FormControl>
          </Grid>
        }
        { isRegimentSelectedError(isEmployeesRegiment, regimentSelected)
          && isEmployeesRegiment
          && showErrors
          && <Grid item xs={12} sx={{ marginTop: '.5em' }}>
            <Alert sx={{ alignItems: 'center' }} severity="error">{t('utils.form.errors.asterisk.required')}</Alert>
          </Grid>
        }
        <Grid
          container
          spacing={2}
          sx={{ paddingTop: '2em' }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sm={4}
          >
            <Field
              name="employees-average-age"
              label={t(`${tradKey}.age-average.label`)}
              type='number'
              component={renderTextField}
              value={employeesAverageAge}
              onChange={(event) => {
                event.target.value = inputUtils.limitNumberValue(0, ageMax, event.target.value)
                handleRegimentInfosChange(event, 'employeesAverageAge')
              }}
              InputProps={{
                min: 0,
                max: ageMax,
                endAdornment: <InputAdornment position="end">{t(`${tradKey}.age-average.adornment`)}</InputAdornment>,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sm={4}
          >
            <Field
              name="employees-count"
              label={t(`${tradKey}.employees.count.label`)}
              type='number'
              component={renderTextField}
              value={employeesCount}
              onChange={(event) => {
                event.target.value = inputUtils.limitNumberValue(0, employeesCountMax, event.target.value)
                handleRegimentInfosChange(event, 'employeesCount')
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sm={4}
          >
            <Field
              name="employees-iddc"
              label={t(`${tradKey}.iddc.label`)}
              type='number'
              component={renderTextField}
              value={iddc}
              onChange={(event) => {
                event.target.value = inputUtils.limitNumberValue(0, iddcMax, event.target.value)
                handleRegimentInfosChange(event, 'iddc')
              }}
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <Field
              name="employees-convention"
              label={t(`${tradKey}.convention.label`)}
              component={renderTextField}
              value={conventionName}
              inputProps={{ maxLength: INPUTS.TEXTLENGTH.MEDIUM }}
              onChange={(event) => handleRegimentInfosChange(event, 'conventionName')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

EmployeesSubForm.propTypes = {
  isEmployeesRegiment: PropTypes.bool.isRequired,
  regimentInfos: PropTypes.object.isRequired,
  handleIsEmployeesRetirementChange: PropTypes.func.isRequired,
  handleRegimentInfosChange: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: 'EmployeesSubForm'
})(EmployeesSubForm)
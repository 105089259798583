/* internal imports */
import { BOOLEAN, COUNTRY } from '../../../../../common/constants';
import { booleanToString, stringToBoolean } from '../../../../../common/FunctionsUtils';
import { BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE } from '../../../../../utils/constantes/US/KYCFORM';

const getGenericField = (sh) => ({
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.NOM]: sh.nom,
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.PARTS]: sh.parts,
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.PRENOM]: sh.prenom,
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.QUALITE]: sh.qualite,
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.SAME_ADDRESS_COMPANY]: !sh.adressePostale,
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.FONCTION_DIRIGEANT]: sh.fonctionDirigeant,
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.PAYS_FISCAL]: JSON.stringify(sh.paysFiscal),
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.ACTIVITE]: sh.activiteBeneficiaire,
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.RUE]: sh.adressePermanente?.rue,
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.VILLE]: sh.adressePermanente?.ville,
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.CODE_POSTAL]: sh.adressePermanente?.codePostal,
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.RAISON_SOCIALE]: sh.beneficiaireIsPersonneMorale ? sh.nom : '',
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.RUE_ENTREPRISE]: sh.adressePostale ? sh.adressePostale.rue : '',
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.VILLE_ENTREPRISE]: sh.adressePostale ? sh.adressePostale.ville : '',
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.CODE_POSTAL_ENTREPRISE]: sh.adressePostale ? sh.adressePostale.codePostal : '',
  [BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE.IS_PERSONNE_MORALE]: booleanToString(sh.beneficiaireIsPersonneMorale)
});

const mapStockholders = (list) => ( list.map((sh) => getGenericField(sh)) );

const initEmptyFields = (change) => {
  change('pays', JSON.stringify(COUNTRY.FRANCE));
  change('chiffreAffaire', 100);
  change('isSameCompanyAddress', true);
  change('principauxPays', COUNTRY.FRANCE.nom);
  change('principauxMarches', COUNTRY.FRANCE.nom);
  change('isBanque', BOOLEAN.NO);
  change('paysFiscalEntreprise', JSON.stringify(COUNTRY.FRANCE));
  change('regulateur', BOOLEAN.NO);
  change('isEntrepriseCotee', BOOLEAN.NO);
  change('paysCotation', JSON.stringify(COUNTRY.FRANCE));
  change('isBonsPorteur', BOOLEAN.NO);
  change('needPaysPersonneMorale', BOOLEAN.NO);
};

const initFieldsWithTunnelValues = (donneesKyc, change) => {
  if (donneesKyc.adressePostaleEntreprise) {
    change('adressePostaleEntrepriseRue', donneesKyc.adressePostaleEntreprise.rue);
    change('adressePostaleEntrepriseCode', donneesKyc.adressePostaleEntreprise.codePostal);
    change('adressePostaleEntrepriseVille', donneesKyc.adressePostaleEntreprise.ville);
  } else {
    change('isSameCompanyAddress', true);
  }
  change('paysFiscalEntreprise', JSON.stringify(donneesKyc.paysFiscalEntreprise) || JSON.stringify(COUNTRY.FRANCE));
  change('codeBIC', donneesKyc.codeBIC);
  change('isBanque', booleanToString(!!donneesKyc.codeBIC));
  change('regulateur', booleanToString(!!donneesKyc.regulateur));
  if (donneesKyc.regulateur) {
    change('nomRegulateur', donneesKyc.regulateur.nomRegulateur);
    change('referenceAgrement', donneesKyc.regulateur.referenceAgrement);
  }
  if (donneesKyc.paysActivite) {
    change('chiffreAffaire', donneesKyc.paysActivite.chiffreAffaire || 100);
    change('pays', JSON.stringify(donneesKyc.paysActivite.pays));
  } else {
    change('pays', JSON.stringify(COUNTRY.FRANCE));
  }
  change('isEntrepriseCotee', booleanToString(!!donneesKyc.entrepriseCotee));
  if (donneesKyc.entrepriseCotee) {
    change('paysCotation', JSON.stringify(donneesKyc.paysCotation));
    change('entrepriseCotee', donneesKyc.entrepriseCotee);
  } else {
    change('paysCotation', JSON.stringify(COUNTRY.FRANCE));
  }
  change('isBonsPorteur', booleanToString(!!donneesKyc.bonsPorteur));
  if (donneesKyc.bonsPorteur) {
    change('bonsPorteur', donneesKyc.bonsPorteur);
  }
  change('needPaysPersonneMorale', booleanToString(!!donneesKyc.paysPersonneMorale?.nom));
  if (donneesKyc.paysPersonneMorale) {
    change('paysPersonneMorale', JSON.stringify(donneesKyc.paysPersonneMorale));
  } else {
    change('paysPersonneMorale', JSON.stringify(COUNTRY.FRANCE));
  }

  // LIEU EXERCICE
  if (donneesKyc.lieuExercice) {
    const lieuExercice = donneesKyc.lieuExercice;
    change('hasActivityFrance', lieuExercice.france);
    lieuExercice.ue
      ? change('hasActivityUE', true) && change('countryActivityUE', lieuExercice.ue)
      : change('hasActivityHorsUE', false)
    lieuExercice.horsUe
      ? change('hasActivityHorsUE', true) && change('countryActivityHorsUE', lieuExercice.horsUe)
      : change('hasActivityHorsUE', false)
  }

  change('principauxMarches', donneesKyc.principauxMarches);
  change('principauxPays', donneesKyc.principauxPays);

  if (donneesKyc.actionnaires) {
    change('actionnaires', mapStockholders(donneesKyc.actionnaires));
  }
};

export const initKycFields = (donneesKyc, change) => {
  if (donneesKyc) {
    initFieldsWithTunnelValues(donneesKyc, change);
  } else {
    initEmptyFields(change);
  }
};

export const formatActionnaires = (values) => values
    ? values.map((value) => ({
      activiteBeneficiaire: value.activiteBeneficiaire,
      adressePermanente: {
        codePostal: value.adressePermanenteCode,
        rue: value.adressePermanenteRue,
        ville: value.adressePermanenteVille,
        pays: value.pays ? JSON.parse(value.pays) : null
      },
      adressePostale: value.isSameAddress
        ? null
        : {
          codePostal: value.adressePostaleCode,
          rue: value.adressePostaleRue,
          ville: value.adressePostaleVille,
          pays: JSON.parse(value.paysFiscal)
        },
      fonctionDirigeant: value.fonctionDirigeant,
      beneficiaireIsPersonneMorale: stringToBoolean(value.beneficiaireIsPersonneMorale),
      nom: value.nom,
      prenom: value.prenom,
      parts: value.parts,
      paysFiscal: JSON.parse(value.paysFiscal),
      qualite: value.qualite,
      // champs pero
      civilite: value.civilite && value.civilite.value,
      typeControle: value.typeControle,
      paysNationalite: value.paysNationalite,
      adresseNaissance: value.rueNaissance
        ? {
          codePostal: value.codePostalNaissance,
          rue: value.rueNaissance,
          ville: value.villeNaissance,
          pays: JSON.parse(value.paysNaissance)
        }
        : null,
      nomNaissance: value.nomNaissance,
      dateNaissance: value.dateNaissance,
      ppe: stringToBoolean(value.isPpe)
        ? {
          fonction: value.fonction,
          pays: JSON.parse(value.paysExercice),
          finFonction: value.finFonction
        }
        : null,
      prochePpe: stringToBoolean(value.isProchePpe)
        ? {
          fonction: value.fonctionPpe,
          pays: JSON.parse(value.paysExercicePpe),
          finFonction: value.finFonctionPpe,
          nom: value.nomPpe,
          prenom: value.prenomPpe,
          lien: value.lienPpe
        }
        : null
    }))
    : null;

import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';

const RadioRoot = styled(Radio, { name: 'Radio' })(() => ({
  '&.Mui-checked': {
    color: 'var(--cn-color-primary)',
  },
  '&:hover': {
    backgroundColor: 'var(--cn-color-primary-opacity-04)'
  }
}))

export default RadioRoot
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { YesChoix } from "../../../components/form/Choix";
import { OBJECTIF_CONSTITUER_CAPITALE } from "../../../modules/simulateur/redux/simulateurActions";
import { connect } from "react-redux";
import { listeObjectifs } from "../../../services/ObjectifService";
import { getSvgEnFonctionDuTenant } from "../../../services/TenantService";

class ChoixObjectif extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectifs: listeObjectifs(),
      submitted: false
    };
    this.myRef = React.createRef();
  }

  desactiverConstituerCapital = type => {
    if (type === OBJECTIF_CONSTITUER_CAPITALE && this.props.effectif > 10) {
      return true;
    }
    return false;
  };

  isClicked = () => {
    this.setState({
      submitted: true
    });
  };

  changerObjectif = event => {
    this.props.moteurCalcul(event.target.value);
    if (this.state.submitted) {
    }
  };

  render() {
    const { handleSubmit, pristine, invalid, submitting } = this.props;
    const { objectifs } = this.state;

    const listObjectifs = objectifs.map(o => (
      <div
        className={
          "choix-objectif " +
          (this.desactiverConstituerCapital(o.type) ? "griserObjectif" : "")
        }
        key={o.type}
      >
        <div className="radio-objectif">
          <Field
            disabled={
              this.desactiverConstituerCapital(o.type) ? "disabled" : ""
            }
            name="objectif"
            component={YesChoix}
            type="radio"
            value={o.type}
            id={o.type}
            onChange={this.changerObjectif}
            // onChange={this.test}
          />
        </div>
        <label htmlFor={o.type} className="text-objectif">
          <div className="text-objectif-titre">{o.titre}</div>
          <div className="text-objectif-desc">
            <p>{o.description}</p>
          </div>
        </label>
      </div>
    ));

    return (
      <div className="grid-x grid-margin-x center-item-simu-container">
        <div className="cell medium-12 titres-simulateur">
          <h2>Parlons de votre objectif</h2>
          <h3 className="h3-with-bottom-marge">
            Quel objectif souhaitez-vous principalement atteindre avec la mise
            en place de l'épargne salariale ?
          </h3>
        </div>

        <div className="cell medium-4 illuSimulateurContainer illus-personnalisation">
          <img
            className="illu-objectif"
            src={getSvgEnFonctionDuTenant(
              "/assets/img/illustrations/",
              "/illus-personnalisation.svg"
            )}
            alt="objectif epargne salariale"
          />
        </div>

        <div className="cell medium-8">
          <form onSubmit={handleSubmit}>
            <div className="yes-rectangle formObjectifs">{listObjectifs}</div>
            {/* <p className="guideText">
              Si vous avez déjà mis en place un plan d’épargne salariale, <a href="#simulateur">suivez le guide.</a>
            </p> */}
            <div className="buttonContainer buttonDecouvrirRecommandation">
              <button
                ref={this.myRef}
                className="yes-button"
                type="submit"
                disabled={invalid || submitting || pristine}
                onClick={this.isClicked}
              >
                Découvrir notre recommandation
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

// REDUX AND REDUX FORM
ChoixObjectif = reduxForm({ form: "choixObjectifs" })(ChoixObjectif);
ChoixObjectif = connect(state => {
  const effectif = state.simulateur.effectif;
  return { effectif };
})(ChoixObjectif);

export default ChoixObjectif;

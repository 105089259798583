const TEXTLENGTH = {
  SMALL: 24,
  MEDIUM: 256,
  LARGE: 512,
}

const FILETYPES = {
  PDF: {
    ACCEPT: '.pdf',
    FILETYPE: 'application/pdf'
  },
  PNG: {
    ACCEPT: '.png',
    FILETYPE: 'image/png'
  },
  JPG: {
    ACCEPT: '.jpg',
    FILETYPE: 'image/jpg'
  },
  JPEG: {
    ACCEPT: '.jpeg',
    FILETYPE: 'image/jpeg'
  },
  CSV: {
    ACCEPT: '.csv',
    FILETYPE: 'text/csv'
  },
  EXCEL2003: {
    ACCEPT: '.xls',
    FILETYPE: 'application/vnd.ms-excel'
  },
  EXCEL2007: {
    ACCEPT: '.xlsx',
    FILETYPE: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }
}

const FILESIZE = {
  SMALL: 5000000, //5mo
  MEDIUM: 10000000, //10mo
  LARGE: 20971520 //20mo
};

export default {
  TEXTLENGTH,
  FILETYPES,
  FILESIZE
}
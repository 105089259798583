export default function WorkAdvicesSheetsModel(
  {
    contractCategorySelected,
    contractorInfos,
    employeesAgeDistribution,
    isAllEmployeesSubscribe,
    isContractor,
    isEmployeesRegiment,
    isFirstWorkAdvicesSheets,
    isRetirementContract,
    isSignatory,
    legalObligationSelected,
    particularObservations,
    particularObservationsPensionGuarantees,
    regimentInfos,
    subscriptionReasons,
  } = {}) {
  this.contractCategorySelected = contractCategorySelected || null;
  this.contractorInfos = contractorInfos || null;
  this.employeesAgeDistribution = employeesAgeDistribution || null;
  this.isAllEmployeesSubscribe = isAllEmployeesSubscribe || null;
  this.isContractor = isContractor || null;
  this.isEmployeesRegiment = isEmployeesRegiment || null;
  this.isFirstWorkAdvicesSheets = isFirstWorkAdvicesSheets || null;
  this.isRetirementContract = isRetirementContract || null;
  this.isSignatory = isSignatory || null;
  this.legalObligationSelected = legalObligationSelected || null;
  this.particularObservations = particularObservations || null;
  this.particularObservationsPensionGuarantees = particularObservationsPensionGuarantees || null;
  this.regimentInfos = regimentInfos || null;
  this.subscriptionReasons = subscriptionReasons || null;
  this.loading = false;
}


const IDENTITE_FIELDS_COMMON = {
  PRENOM: 'prenom',
  NOM: 'nom',
  ADRESSE_EMAIL: 'adresseEmail',
  INDICATIF: 'indicatifCode',
  TELEPHONE: 'telephone',
  FONCTION: 'fonction',
  CIVILITE: 'civilite'
};

export const IDENTITE_FIELDS_EPARGNE = {
  ...IDENTITE_FIELDS_COMMON
};

export const IDENTITE_FIELDS_ASSURANCE = {
  ...IDENTITE_FIELDS_COMMON,
  NOM_NAISSANCE: 'nomNaissance',
  DATE_NAISSANCE: 'dateNaissance',
  LIEU_NAIISANCE: 'lieuNaissance',
  CODE_POSTAL_NAISSANCE: 'codePostalNaissance',
  PAYS_NAISSANCE: 'paysNaissance',
  PAYS_RESIDENCE_FISCALE: 'paysResidence',
  NATIONALITES: 'nationalites',
  NIR: 'nir',
  // Adresse
  RUE: 'rue',
  CODE_POSTAL: 'codePostal',
  VILLE: 'ville',
  PAYS: 'pays'
};

// TODO: attente de specs Lucas
export const FONCTIONS_EPARGNE = {
  PDG: 'PDG',
  GERANT: 'Gérant',
  DG: 'DG',
  DF: 'DF',
  DAF: 'DAF',
  RESP_COMPTABLE: 'Resp. Comptable',
  DRH: 'DRH',
  AUTRE: 'Autre'
}

export const FONCTIONS_EPARGNE_LIST = Object.values(FONCTIONS_EPARGNE).map((value => ({ label: value, value } )));

import AssuranceCollegePage from '../../../../pages/private_pages/assurance/default/AssuranceCollegePage';
import AssuranceEntreprisePage from '../../../../pages/private_pages/assurance/default/AssuranceEntreprisePage';
import AssuranceFinalisationPage from '../../../../pages/private_pages/assurance/default/AssuranceFinalisationPage';
import AssuranceGarantiesPrevoyancePage from '../../../../pages/private_pages/assurance/default/AssuranceGarantiesPrevoyancePage';
import AssuranceIdentitePage from '../../../../pages/private_pages/assurance/default/AssuranceIdentitePage';
import AssuranceKycPage from '../../../../pages/private_pages/assurance/default/AssuranceKycPage';
import AssuranceMoyensPaiementPage from '../../../../pages/private_pages/assurance/default/AssuranceMoyensPaiementPage';
import AssurancePpePage from '../../../../pages/private_pages/assurance/default/AssurancePpePage';
import AssuranceSiretPage from '../../../../pages/private_pages/assurance/default/AssuranceSiretPage';
import AssuranceTarificationPage from '../../../../pages/private_pages/assurance/default/AssuranceTarificationPage';
import AssuranceVerificationWLFPage from '../../../../pages/private_pages/assurance/default/AssuranceVerificationWLFPage';
import { ETAPES_DOSSIER_ASSURANCE, URLS_ASSURANCE } from '../../../../utils/constantes/ROUTES/ASSURANCE';

/** Arborescence de la partie Assurance sous forme d'objet JS.
 *
 *  Chaque route sera représentée de la manière suivante:
 *  {
 *    path: PATH_CONST,
 *    component: mainComponentPage,
 *    etapeIndex: indexFromConst (-> permet la redirection en fonction de l'étape)
 *  }
 *
 */
export const getAssuranceArborescence = () => {
  return [
    {
      path: URLS_ASSURANCE.SIRET,
      component: AssuranceSiretPage,
      etapeIndex: ETAPES_DOSSIER_ASSURANCE.SIRET
    },
    {
      path: URLS_ASSURANCE.ENTREPRISE_INFORMATION,
      component: AssuranceEntreprisePage,
      etapeIndex: ETAPES_DOSSIER_ASSURANCE.ENTREPRISE_INFORMATION
    },
    {
      path: URLS_ASSURANCE.IDENTITE,
      component: AssuranceIdentitePage,
      etapeIndex: ETAPES_DOSSIER_ASSURANCE.IDENTITE
    },
    {
      path: URLS_ASSURANCE.PPE,
      component: AssurancePpePage,
      etapeIndex: ETAPES_DOSSIER_ASSURANCE.PPE
    },
    {
      path: URLS_ASSURANCE.GARANTES_PREVOYANCE,
      component: AssuranceGarantiesPrevoyancePage,
      etapeIndex: ETAPES_DOSSIER_ASSURANCE.GARANTIES_PREVOYANCE
    },
    {
      path: URLS_ASSURANCE.CATEGORIES_SALARIES,
      component: AssuranceCollegePage,
      etapeIndex: ETAPES_DOSSIER_ASSURANCE.CATEGORIES_SALARIES
    },
    {
      path: URLS_ASSURANCE.KYC,
      component: AssuranceKycPage,
      etapeIndex: ETAPES_DOSSIER_ASSURANCE.KYC
    },
    {
      path: URLS_ASSURANCE.MOYENS_PAIEMENT,
      component: AssuranceMoyensPaiementPage,
      etapeIndex: ETAPES_DOSSIER_ASSURANCE.MOYENS_PAIEMENT
    },
    {
      path: URLS_ASSURANCE.TARIFICATION,
      component: AssuranceTarificationPage,
      etapeIndex: ETAPES_DOSSIER_ASSURANCE.TARIFICATION
    },
    {
      path: URLS_ASSURANCE.FINALISATION,
      component: AssuranceFinalisationPage,
      etapeIndex: ETAPES_DOSSIER_ASSURANCE.INFORMATIONS
    },
    {
      path: URLS_ASSURANCE.VERIFICATION_WLF,
      component: AssuranceVerificationWLFPage,
      etapeIndex: ETAPES_DOSSIER_ASSURANCE.EN_ATTENTE_DE_SIGNATURE
    }
  ];
};

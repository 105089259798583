import EpargneDispositifAbondementPage from '../../../../pages/private_pages/epargne/default/EpargneDispositifAbondementPage';
import EpargneDispositifActuelPage from '../../../../pages/private_pages/epargne/default/EpargneDispositifActuelPage';
import EpargneEntrepriseComplementPage from '../../../../pages/private_pages/epargne/default/EpargneEntrepriseComplementPage';
import EpargneEntreprisePage from '../../../../pages/private_pages/epargne/default/EpargneEntreprisePage';
import EpargneFinalisationPage from '../../../../pages/private_pages/epargne/default/EpargneFinalisationPage';
import EpargneIdentitePage from '../../../../pages/private_pages/epargne/default/EpargneIdentitePage';
import EpargneInteressementPage from '../../../../pages/private_pages/epargne/default/EpargneInteressementPage';
import EpargneKycPage from '../../../../pages/private_pages/epargne/default/EpargneKycPage';
import EpargneParticipationPage from '../../../../pages/private_pages/epargne/default/EpargneParticipationPage';
import EpargneSiretPage from '../../../../pages/private_pages/epargne/default/EpargneSiretPage';
import EpargneTarificationPage from '../../../../pages/private_pages/epargne/default/EpargneTarificationPage';
import { ETAPES_DOSSIER_EPARGNE, URLS_EPARGNE } from '../../../../utils/constantes/ROUTES/EPARGNE';

/** Arborescence de la partie Épargne sous forme d'objet JS.
 *
 *  Chaque route sera représentée de la manière suivante:
 *  {
 *    path: PATH_CONST,
 *    component: mainComponentPage,
 *    etapeIndex: indexFromConst (-> permet la redirection en fonction de l'étape)
 *  }
 *
 */
export const getEpargneArborescence = () => {
  return [
    {
      path: URLS_EPARGNE.SIRET,
      component: EpargneSiretPage,
      etapeIndex: ETAPES_DOSSIER_EPARGNE.SIRET
    },
    {
      path: URLS_EPARGNE.ENTREPRISE_INFORMATION,
      component: EpargneEntreprisePage,
      etapeIndex: ETAPES_DOSSIER_EPARGNE.ENTREPRISE_INFORMATION
    },
    {
      path: URLS_EPARGNE.ENTREPRISE_COMPLEMENT,
      component: EpargneEntrepriseComplementPage,
      etapeIndex: ETAPES_DOSSIER_EPARGNE.ENTREPRISE_COMPLEMENT
    },
    {
      path: URLS_EPARGNE.IDENTITE,
      component: EpargneIdentitePage,
      etapeIndex: ETAPES_DOSSIER_EPARGNE.IDENTITE
    },
    {
      path: URLS_EPARGNE.DISPOSITIF_ACTUEL,
      component: EpargneDispositifActuelPage,
      etapeIndex: ETAPES_DOSSIER_EPARGNE.DISPOSITIF_ACTUEL
    },
    {
      path: URLS_EPARGNE.DISPOSITIF_ABONDEMENT,
      component: EpargneDispositifAbondementPage,
      etapeIndex: ETAPES_DOSSIER_EPARGNE.DISPOSITIF_ABONDEMENT
    },
    {
      path: URLS_EPARGNE.INTERESSEMENT,
      component: EpargneInteressementPage,
      etapeIndex: ETAPES_DOSSIER_EPARGNE.INTERESSEMENT
    },
    {
      path: URLS_EPARGNE.PARTICIPATION,
      component: EpargneParticipationPage,
      etapeIndex: ETAPES_DOSSIER_EPARGNE.PARTICIPATION
    },
    {
      path: URLS_EPARGNE.KYC,
      component: EpargneKycPage,
      etapeIndex: ETAPES_DOSSIER_EPARGNE.KYC
    },
    {
      path: URLS_EPARGNE.TARIFICATION,
      component: EpargneTarificationPage,
      etapeIndex: ETAPES_DOSSIER_EPARGNE.TARIFICATION
    },
    {
      path: URLS_EPARGNE.FINALISATION,
      component: EpargneFinalisationPage,
      etapeIndex: ETAPES_DOSSIER_EPARGNE.INFORMATIONS
    }
  ];
};

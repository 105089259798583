/* built-in imports */
import React from "react";

/* third-party imports */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { styled } from '@mui/material/styles';

/* internal imports */
import { CHECKBOXSHAPE } from "../../../utils/constantes/DS/CHECKBOX";
import ICON from "../../../utils/constantes/DS/ICON";

const CheckboxRoot = styled(Checkbox, { name: 'Checkbox' })(() => ({
  '&.MuiCheckbox-root': {
    color: 'var(--cn-color-primary)'
  },
  '&:hover': {
    backgroundColor: 'var(--cn-color-primary-opacity-04)'
  },
  '&.Mui-disabled': {
    pointerEvents: 'all',
    background: 'none',
    '&:hover': {
      background: 'inherit',
      cursor: 'not-allowed'
    }
  }
}))

const CheckboxCustom = ({ shape = CHECKBOXSHAPE.SQUARE, sx, ...other }) => {
  let style = {};
  let blankIcon, checkedIcon;

  switch (shape) {
    case CHECKBOXSHAPE.ROUND:
      // TODO: Style pour correspondre au anciens radio button, à supprimer en temps voulu
      style = {
        '&.MuiCheckbox-root': {
          color: 'var(--cn-color-grey-150)'
        },
        '&:hover': {
          backgroundColor: 'transparent'
        },
        '& .MuiSvgIcon-root': {
          fontSize: ICON.SIZE.SMALL
        },
        '& + span': {
          color: '#89969f'
        }
      };

      blankIcon = (<RadioButtonUncheckedIcon />);
      checkedIcon = (
          <CheckCircleIcon sx={{
            outline: '2px solid var(--cn-color-grey-150)',
            outlineOffset: '-3px',
            borderRadius: '50%',
            color: other.disabled ? 'var(--cn-color-grey-200)' : 'var(--cn-color-primary)'
          }}
          />
      );
      break;

    default:
      blankIcon = (<CheckBoxOutlineBlankIcon />);
      checkedIcon = (
        <CheckBoxIcon
          sx={{
            color: other.disabled ? 'var(--cn-color-grey-200)' : 'var(--cn-color-primary)'
          }}
        />)
      break;
  }

  if (sx && Object.keys(sx).length > 0) {
    style = Object.assign(sx, style);
  }

  return (
      <CheckboxRoot
        {...other}
        sx={style}
        icon={blankIcon}
        checkedIcon={checkedIcon}
        disableRipple={(shape === CHECKBOXSHAPE.ROUND)} // TODO: Style pour correspondre au anciens radio button, à supprimer en temps voulu
      />
  );
}

CheckboxCustom.propTypes = {
  shape: PropTypes.string
}

export default CheckboxCustom
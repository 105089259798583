import store from '../store';
import download from 'js-file-download';

import decode from 'jwt-decode';
import { rafraichirToken } from './CompteService';
import { setLoader } from '../modules/tunnel/redux/tunnelActions';
import { PREFIX_BACK, WAIT_REFRAICHISSEMENT_TOKEN_EN_COURS } from '../common/Configuration';
import { addNotification } from '../modules/notification/redux/notificationActions';

export const setToken = (idToken) => {
  // Sauvegarde du token dans la session storage pour permettre la gestion du multi onglets
  sessionStorage.setItem('id_token', idToken);
};

export const setRefreshTokenEnCours = (isRefreshTokenEnCours) => {
  // Saves flag refresh to localStorage
  sessionStorage.setItem('isRefreshTokenEnCours', isRefreshTokenEnCours);
};

export const getToken = () => {
  // Retrieves the user token from sessionStorage
  return sessionStorage.getItem('id_token');
};

export const isRefreshTokenEnCours = () => {
  // Saves flag refresh to localStorage
  return sessionStorage.getItem('isRefreshTokenEnCours') === 'true';
};

export const deleteToken = () => {
  // Retrieves the user token from sessionStorage
  return sessionStorage.removeItem('id_token');
};

const isTokenExpired = (token) => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired. N
      return true;
    } else return false;
  } catch (err) {
    return false;
  }
};

export const getAttribute = (attribut) => {
  const decoded = decode(getToken());
  return decoded[attribut];
};

export const refreshToken = () => {
  if (isLoggedIn()) {
    setRefreshTokenEnCours(true);
    rafraichirToken().then(
      (token) => {
        // Ajout du JWT dans le session storage
        setToken(token.jwt);
        setRefreshTokenEnCours(false);
      },
      (reason) => {
        setRefreshTokenEnCours(false);
      }
    );
  }
};

export const isLoggedIn = () => {
  // Checks if there is a saved token and it's still valid
  const token = getToken();
  return !!token && !isTokenExpired(token); // handwaiving here
};

export const uploadFiles = (privateUrl, url, method, files) => {
  const headers = {};
  let data = new FormData();

  for (let i = 0; i < files.length; i++) {
    let file = files[i];
    data.append('files', file, file.name);
  }

  // data.append('files', files[0], files[0].name)

  // cas des URL privées
  if (privateUrl) {
    // Setting Authorization header
    headers['Authorization'] = 'Bearer ' + getToken();

    if (!isLoggedIn()) {
      window.location.href = '/connexion';
    }
  }

  let ok, status;

  // ici on utilise global pour eviter un appel recursif de fetch.
  return global
    .fetch(url, {
      method: method,
      headers,
      body: data
    })
    .then((response) => {
      ok = response.ok;
      status = response.status;
      if (status === 204) {
        // response.json() throws an error if 204 No Content
        return {};
      }
      return response.json().catch((err) => {
        store.dispatch(addNotification('Oups ! Une erreur est survenue.'));
        return Promise.reject();
      });
    })
    .then((json) => {
      if (ok) {
        // success
        return json;
      }

      store.dispatch(setLoader(false));

      if (status >= 500) {
        // failure: internal error with json
        store.dispatch(addNotification(json.message));
        return Promise.reject();
      }

      // failure: error to be handled by component/service
      return Promise.reject(json);
    });
};

export const fetch = (privateUrl, url, options) => {
  if (url.endsWith('donnees/rafraichirJwtToken') || url.endsWith('contributeurs/ssoIn')) {
    return send(privateUrl, url, options);
  }

  // permet d'attendre la fin du refresh jwt
  return waitingValidateCondition(fetchValidateFct, WAIT_REFRAICHISSEMENT_TOKEN_EN_COURS).then((res) => {
    return send(privateUrl, url, options);
  });
};

export const getCountries = () => fetch(true, `${PREFIX_BACK}donnees/countries`);

const waitingValidateCondition = (validateFct, delayMs) => {
  return new Promise((resolve, reject) => {
    if (validateFct()) {
      resolve();
    } else {
      setTimeout(() => {
        waitingValidateCondition(validateFct, delayMs).then((response) => {
          resolve(response);
        });
      }, delayMs);
    }
  });
};

export const fetchValidateFct = () => {
  return !isRefreshTokenEnCours();
};

export const send = (privateUrl, url, options) => {
  // performs api calls sending the required authentication headers
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  // cas des URL privées
  if (privateUrl) {
    // Setting Authorization header
    headers['Authorization'] = 'Bearer ' + getToken();

    if (!isLoggedIn()) {
      window.location.href = '/connexion';
    }
  }

  let ok, status;

  // ici on utilise global pour eviter un appel recursif de fetch.
  return global
    .fetch(url, {
      headers,
      ...options
    })
    .then((response) => {
      ok = response.ok;
      status = response.status;
      if (status === 204) {
        // response.json() throws an error if 204 No Content
        return {};
      }
      return response.json().catch((err) => {
        // En cas de succès d'une reponse vide, on laissep passé
        if (status === 200) {
          return Promise.resolve();
        }

        store.dispatch(addNotification('Oups ! Une erreur est survenue.'));
        return Promise.reject();
      });
    })
    .then((json) => {
      if (ok) {
        // success
        return json;
      }

      store.dispatch(setLoader(false));

      if (status >= 500) {
        // failure: internal error with json
        store.dispatch(addNotification(json.message));
        return Promise.reject();
      }

      // failure: error to be handled by component/service
      return Promise.reject(json);
    });
};

export const creerCompteUtilisateur = ({ values }) => {
  return fetch('/v1/utilisateurs/', {
    method: 'POST',
    body: JSON.stringify(values)
  });
};

export const getListeBeneficiaire = () =>
  global
    .fetch(`${PREFIX_BACK}bibliotheque?filename=Liste_des_beneficiaires.xlsx`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: `Bearer ${getToken()}`
      },
      responseType: 'blob'
    })
    .then((response) => response.blob())
    .then((data) => download(data, 'Liste des beneficiaires.xlsx'))
    .catch((e) => console.log(e));

export const fetchText = (url) =>
  global
    .fetch(url, {
      Accept: 'text/plain',
      'Content-Type': 'text/plain',
      Authorization: `Bearer ${getToken()}`
    })
    .then((response) => {
      if (response.status >= 400) {
        store.dispatch(addNotification(response.statusText));
        Promise.reject();
      }
      return response.text();
    })
    .catch((err) => {
      store.dispatch(addNotification(err.message));
      Promise.reject();
    });

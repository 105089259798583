import { LISTE_FORMES_JURIDIQUE } from '../common/constants';

const ASSOCIATIONS = ['ASSOCIATION DECLAREE', 'ASSOCIATION UTILITE PUBLIQUE'];
const AUTRES = ['Autre'];

export const getFormesJuridiquesValues = (formesListe) =>
  LISTE_FORMES_JURIDIQUE.reduce((liste, forme) => (formesListe.includes(forme.label) ? [...liste, forme.value] : liste), []);

export const FORMES_JURIDIQUES_ASSOCIATIVES = getFormesJuridiquesValues(ASSOCIATIONS);

export const AUTRES_FORMES_JURIDIQUES = getFormesJuridiquesValues(AUTRES);

import React, { Component } from "react";

export class YesCheckboxV2 extends Component {
  render() {
    const {
      input,
      labeltext,
      id,
      meta: { error, touched }
    } = this.props;

    const classnameDivCheckbox =
      touched && error ? "divCheckbox errorCheckbox" : "divCheckbox";
    const classnameDisabled = this.props.disabled ? " disableCheckbox" : "";

    let cssClassNotChecked = "checked";
    if (!input.checked) {
      cssClassNotChecked = "noChecked";
    }

    return (
      <div className={classnameDivCheckbox + " " + cssClassNotChecked}>
        <div className={"yes-checkbox" + classnameDisabled}>
          {!this.props.disabled && <input id={id} {...input} type="checkbox" />}
          {this.props.disabled && (
            <input id={id} disabled {...input} type="checkbox" />
          )}
          <span>
            <img
              className="icon-check"
              src="/assets/img/icons/yes/correct.svg"
              alt="check"
            />
          </span>
        </div>
        <label htmlFor={id} className="label-checkbox-v2">
          {labeltext}
        </label>
      </div>
    );
  }
}

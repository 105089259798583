/* Built in imports */
import React, { Component } from 'react';

/* Third party imports */
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import AssuranceRouteUtils from '../../utils/routes/assurance/AssuranceRouteUtils';
import EpargneRouteUtils from '../../utils/routes/epargne/EpargneRouteUtils';
import { ACTIVITY_DOMAINS, FOLDER_TYPES } from '../../utils/constantes/ROUTES/ROUTES';
import { URLS_EPARGNE } from '../../utils/constantes/ROUTES/EPARGNE';
import { getAttribute, setToken } from '../../services/ResourcesService';
import { getDossierByUidEntreprise } from '../../services/DossierService';
import { getJwtContributeur } from '../../services/CompteService';
import { setLoader } from '../../modules/tunnel/redux/tunnelActions';
import MaroquinerieRoutesUtils from '../../utils/routes/epargne/MaroquinerieRoutesUtils';

class AccesApporteurPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      domain: "",
      etapeDossier: ""
    };
  }

  disableLoader() {
    this.props.setLoader(false);
    this.setState({
      redirect: true
    });
  }

  getDomainFromDossier() {
    const entrepriseUid = getAttribute('entrepriseUid');
    // Le token JWT contient un id de dossier => On récupère le domain d'activité à partir du dossier
    if (entrepriseUid) {
      getDossierByUidEntreprise(entrepriseUid).then((dossiers) => {
        const { dossier } = dossiers[0];
        const domain = dossier.activityDomain || ACTIVITY_DOMAINS.DEFAULT.NAME;
        const folderType = dossier.type;
        this.setState({
          domain,
          folderType,
          etapeDossier: dossier.etapeDossier
        });
        //Updates App.js state with new domain if (App.state.isLoading = true)
        if(this.props.updateAppActivityDomainAndFolderType){
          this.props.updateAppActivityDomainAndFolderType(domain, folderType);
        }
      }).finally(() => {
        this.disableLoader();
      });
    } else {
      this.disableLoader();
    }
  }

  componentDidMount() {
    this.props.setLoader(true);
    //récupération du token dans l'url
    const token = this.props.location.search.split('token=')[1];
    //on set le token pour qu'il soit automatiquement envoyé dans la prochaine requête
    setToken(token);
    getJwtContributeur(token)
      .then((response) => {
        //on récupère le jwt contributeur
        if (response.jwt) {
          setToken(response.jwt);
          this.getDomainFromDossier();
        }
      })
      .catch((error) => console.error('GetJwtContributeur error: ', error));
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      switch (this.state.folderType + this.state.domain){
        case FOLDER_TYPES.EPARGNE:
          return <Redirect to={EpargneRouteUtils.getUrlFromEtapeDossier(this.state.etapeDossier)} />;
        case FOLDER_TYPES.ASSURANCE:
          return <Redirect to={AssuranceRouteUtils.getUrlFromEtapeDossier(this.state.etapeDossier)} />;
        case FOLDER_TYPES.EPARGNE + ACTIVITY_DOMAINS.MAROQUINERIE.NAME:
          return <Redirect to={MaroquinerieRoutesUtils.getUrlFromEtapeDossier(this.state.etapeDossier)} />;
        default:
          return <Redirect to={URLS_EPARGNE.ENTREPRISE_INFORMATION} />;
      }
    }

    return <div />;
  }
}

const mapStateToProps = ({ tunnel }) => ({
  tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (actif) => dispatch(setLoader(actif))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccesApporteurPage);

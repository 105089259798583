import { fetch, uploadFiles } from './ResourcesService';
import { PREFIX_BACK } from '../common/Configuration';

export const getUtilisateur = (uidUtilisateur) => {
  return fetch(true, PREFIX_BACK + 'utilisateurs/' + uidUtilisateur, {
    method: 'GET'
  });
};

export const mettreAjourIdentiteDirigeant = (uidUtilisateur, { valuesAenvoye }) => {
  return fetch(true, PREFIX_BACK + 'utilisateurs/' + uidUtilisateur, {
    method: 'PUT',
    body: JSON.stringify(valuesAenvoye)
  });
};

export const mettreAjourFonctionDirigeant = (uidUtilisateur, { valuesAenvoye }) => {
  return fetch(true, PREFIX_BACK + 'utilisateurs/' + uidUtilisateur + '/fonctionDansEntreprise', {
    method: 'PUT',
    body: JSON.stringify(valuesAenvoye)
  });
};

export const stockerPagesJustificatifIdentite = (uid, files) => {
  return uploadFiles(true, PREFIX_BACK + 'utilisateurs/' + uid + '/justificatifIdentite', 'POST', files);
};

export const supprimerPageJustificatifIdentite = (uid, nom) => {
  return fetch(true, PREFIX_BACK + 'utilisateurs/' + uid + '/justificatifIdentite?nom=' + nom, {
    method: 'DELETE'
  });
};

export const stockerPagesJustificatifIdentiteRectoVerso = (uid, files, recto) => {
  return uploadFiles(true, `${PREFIX_BACK}utilisateurs/${uid}/justificatifIdentite/${!!recto}/`, 'POST', files);
};

export const supprimerPageJustificatifIdentiteRectoVerso = (uid, nom, recto) => {
  return fetch(true, `${PREFIX_BACK}utilisateurs/${uid}/justificatifIdentite/${!!recto}/?nom=${nom}`, {
    method: 'DELETE'
  });
};

export const stockerPagePasseport = (uid, files) => {
  return uploadFiles(true, `${PREFIX_BACK}utilisateurs/${uid}/passeport`, 'POST', files);
};

export const supprimerPagePasseport = (uid, nom) => {
  return fetch(true, `${PREFIX_BACK}utilisateurs/${uid}/passeport/?nom=${nom}`, {
    method: 'DELETE'
  });
};

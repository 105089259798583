/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

/* Internal imports */
import YesInput from '../../../form/Input';
import { Required, NbSalaries, DateJourMois } from '../../../../common/Validators';
import { YesButton } from '../../../form/Button';
import { nombreAvecEspaces, getJoursMoisOfDate } from '../../../../common/FunctionsUtils';

class EntrepriseComplementForm extends Component {
  componentDidMount() {
    if (this.props.tunnel.entreprise && this.props.tunnel.entreprise.complement) {
      this.props.change('nombreSalaries', nombreAvecEspaces(this.props.tunnel.entreprise.complement.nombreSalaries));
      this.props.change('dateClotureComptable', getJoursMoisOfDate(this.props.tunnel.entreprise.complement.dateClotureComptable));
    }
  }

  render() {
    const { handleSubmit, pristine, invalid, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} className="formTunnel formEntrepriseInfosComplementaires" noValidate>
        <label className="titreFormulaire">
          <Trans i18nKey="entreprise.complement.titre" />
        </label>

        <div className="grid-x grid-margin-x">
          <div className="cell medium-12">
            <h3>
              <Trans i18nKey="entreprise.complement.question.nbSalaries" />
            </h3>
            <Field
              component={YesInput}
              type="text"
              inputMode="numeric"
              pattern="\d*"
              name="nombreSalaries"
              labeltext=""
              validate={[Required, NbSalaries]}
              numberfield={'true'}
            />

            <h3>
              <Trans i18nKey="entreprise.complement.question.clotureComptable" />
            </h3>
            <Field
              component={YesInput}
              type="text"
              inputMode="numeric"
              pattern="\d*"
              name="dateClotureComptable"
              labeltext=""
              validate={[Required, DateJourMois]}
              mask={[/\d/, /\d/, '/', /\d/, /\d/]}
              placeholder="jj/mm"
            />
          </div>
        </div>
        <div className="buttonCreationContainer">
          <YesButton text="Confirmer" disabled={invalid || submitting || pristine} />
        </div>
      </form>
    );
  }
}

EntrepriseComplementForm.propTypes = {
  tunnel: PropTypes.shape({
    entreprise: PropTypes.object
  }).isRequired
};

// REDUX + REDUX FORM
const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

export default connect(mapStateToProps)(reduxForm({ form: 'informationsEntrepriseComplementForm' })(EntrepriseComplementForm));

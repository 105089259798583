import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Required, Effectif, MasseSalariale } from '../../../common/Validators';
import YesInput from '../../../components/form/Input';
import { getSvgEnFonctionDuTenant } from '../../../services/TenantService';

class InformationsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false
    };
  }

  isClicked = () => {
    this.setState({
      submitted: true
    });
  };

  render() {
    const { handleSubmit, pristine, invalid, submitting } = this.props;
    const { submitted } = this.state;
    return (
      <div className="center-item-simu-container grid-x grid-margin-x">
        <div className="cell medium-12 titres-simulateur">
          <h1>Construisons votre épargne salariale</h1>
          <p className="sous-titre">
            Notre outil de simulation vous permet d'estimer les avantages de l'épargne salariale dans le cadre de votre politique
            RH.
          </p>
        </div>

        <div className="cell medium-8">
          <h2 className="simulateur-h2">Faisons connaissance</h2>
          <h3 className="h3-with-bottom-marge">Donnez-nous quelques informations sur votre entreprise</h3>

          <form onSubmit={handleSubmit} autoComplete="off" noValidate>
            <div className="grid-x grid-margin-x informationsForm">
              <div className="cell medium-6">
                <Field
                  component={YesInput}
                  type="text"
                  inputMode="numeric"
                  pattern="\d*"
                  name="effectif"
                  labeltext="Effectif"
                  validate={[Required, Effectif]}
                  tooltip="Tous les salariés de l'entreprise y compris les statuts TNS à l'exception des stagiaires et intérimaires."
                  numberfield={'true'}
                />
              </div>
              <div className="cell medium-6">
                <Field
                  component={YesInput}
                  type="text"
                  inputMode="numeric"
                  pattern="\d*"
                  name="masseSalariale"
                  labeltext="Masse salariale annuelle"
                  validate={[Required, MasseSalariale]}
                  tooltip="La masse salariale est la somme des rémunérations brutes versées aux salariés, hors rémunérations en nature et cotisations patronales."
                  euroField={true}
                />
              </div>
            </div>
            <div className="buttonContainer buttonValiderInformation">
              <button className="yes-button" type="submit" disabled={invalid || submitting || pristine} onClick={this.isClicked}>
                Valider mes informations
              </button>
              {submitted && (
                <p className="yes-indication indication-maj-donnees">
                  Il vous est possible de modifier vos données à tout moment de la simulation. Pour que nous prenions bien en compte
                  vos nouvelles données, n'oubliez pas de valider vos informations.
                </p>
              )}
            </div>
          </form>
        </div>

        <div className="cell medium-4 illuSimulateurContainer">
          <img
            className="illu-calcul"
            src={getSvgEnFonctionDuTenant('/assets/img/illustrations/', '/illus-calculator.svg')}
            alt="creation du compte utilisateur"
          />
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: 'simulateurInformationsForm'
})(InformationsForm);

/* Built in imports */
import React, { Component } from 'react';

/* Third party imports */
import { connect } from 'react-redux';
import Slider from '@appigram/react-rangeslider';
import { Field, reduxForm, formValueSelector } from 'redux-form';

/* Internal imports */
import YesInputWithoutReduxForm from '../../../../form/InputWithoutReduxForm';
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';
import { YesCheckboxV2 } from '../../../../form/CheckboxV2';
import { isCumulPercoiAbondementUnilateralEnabled } from '../../../../../services/TenantService';
import { nombre, nombreSansEspaces } from '../../../../../common/FunctionsUtils';
import { plafondAbondementPERCO, plafondAbondementUnilateral } from '../../../../../modules/simulateur/fonctions';

export class AbondementUnilateralForm extends Component {
  constructor(props) {
    super(props);
    this.changerValeurSliderPlafondAbnUnilateral = this.changerValeurSliderPlafondAbnUnilateral.bind(this);
    this.changerValeurPlafondAbnUnilateral = this.changerValeurPlafondAbnUnilateral.bind(this);
    this.state = {
      showPlafondPassAbnUnilateral: true
    };
  }

  componentDidMount() {
    this.props.change('plafondAbnUnilateral', 0);

    // On set les valeurs persistées s'ils elles existent
    const abnUnilateral = this.props.tunnel.dossier.dispositifs.dispositifPercoi.abondementUnilateral;

    if (abnUnilateral) {
      if (abnUnilateral.miseEnPlace) {
        this.props.change('abnUnilateral', true);
        if (abnUnilateral.plafond) {
          this.props.change('plafond', abnUnilateral.plafond);
        }
        if (!abnUnilateral.plafondPass) {
          if (!isCumulPercoiAbondementUnilateralEnabled() && abnUnilateral.plafond > this.getPlafondPercoi()) {
            this.props.change('plafondAbnUnilateral', this.getPlafondPercoi());
          } else {
            this.props.change('plafondAbnUnilateral', abnUnilateral.plafond);
          }
          this.setState({
            showPlafondPassAbnUnilateral: false
          });
        } else {
          this.props.change('plafondAbnUnilateral', this.getPlafondPercoi());
        }
      } else {
        this.props.change('abnUnilateral', false);
      }
    }
  }

  /**
   * Si feature abondement unilatéral intégré dans les 16% du percoi
   * on calcule le max ici.
   * Si feature non activée, on retourne directement le plafond autorisé
   * @returns {*}
   */
  getPlafondPercoi = () => {
    if (isCumulPercoiAbondementUnilateralEnabled()) {
      return plafondAbondementUnilateral();
    }

    //Si pas de paliers, on retourne 0 à l'abondement
    if (!this.props.tunnel.dossier.dispositifs.dispositifPercoi.abondement) {
      return plafondAbondementUnilateral();
    }

    if (plafondAbondementPERCO() - this.props.plafondPercoi < plafondAbondementUnilateral()) {
      return plafondAbondementPERCO() - this.props.plafondPercoi;
    }
    return plafondAbondementUnilateral();
  };

  getMaxPaliersAbondement = (paliers) => {
    let plafond = 0;
    if (paliers && paliers.length > 1) {
      for (let index in paliers) {
        let palier = paliers[index];
        if (palier.versementMax > 0) {
          plafond += (palier.versementMax - palier.versementMin) * palier.taux;
        }
      }
    } else {
      plafond = paliers && paliers[0] && paliers[0].versementMax;
    }
    return plafond;
  };

  afficherMessagePlafondAbondementUnilateralDansPercoi = () => {
    if (isCumulPercoiAbondementUnilateralEnabled()) {
      return false;
    }

    return (
      this.getPlafondPercoi() < plafondAbondementUnilateral() &&
      this.props.plafondAbnUnilateral === this.getPlafondPercoi() &&
      this.props.showContent
    );
  };

  choixSelectionne() {
    return this.props.abondementUnilateralForm && this.props.abondementUnilateralForm.values.abnUnilateral;
  }

  changerValeurSliderPlafondAbnUnilateral = (value) => {
    this.props.change('plafondAbnUnilateral', value);
    this.setState({
      showPlafondPassAbnUnilateral: value >= plafondAbondementUnilateral()
    });
  };

  changerValeurPlafondAbnUnilateral = (event) => {
    if (event.target.validity.valid) {
      let valueAbnUnilateral = nombre(nombreSansEspaces(event.target.value));
      if (event.target.value === '') {
        valueAbnUnilateral = 0;
      }
      if (valueAbnUnilateral > plafondAbondementUnilateral()) {
        valueAbnUnilateral = plafondAbondementUnilateral();
      }

      this.props.change('plafondAbnUnilateral', valueAbnUnilateral);
      this.setState({
        showPlafondPassAbnUnilateral: valueAbnUnilateral >= plafondAbondementUnilateral()
      });
    }
  };

  render() {
    return (
      <>
        <div className={`grid-x grid-margin-x ${this.props.disabled}`}>
          <div className="cell medium-12">
            <div className="tunnelRadios">
              <div className="tunnelRadio">
                <Field
                  name="abnUnilateral"
                  component={YesCheckboxV2}
                  type="checkbox"
                  id="abnUnilateral"
                  labeltext={<TransHtmlText i18nKey="abn.unilateral.question.miseEnPlace" params={{ config: this.props.config }} />}
                />
              </div>
            </div>
            {this.props.showContent && (
              <div className="alignContentInRow">
                <div className="yes-label">Plafond d’abondement (max 2% du PASS)</div>
                <div className={`cell sliderDispositifAbondement`}>
                  <Slider
                    value={this.props.plafondAbnUnilateral}
                    orientation="horizontal"
                    onChange={this.changerValeurSliderPlafondAbnUnilateral}
                    tooltip={false}
                    max={this.getPlafondPercoi()}
                  />
                  {`${(+this.props.plafondAbnUnilateral * 100 / this.props.PASS).toFixed(2)} %`}
                  {this.state.showPlafondPassAbnUnilateral && isCumulPercoiAbondementUnilateralEnabled() && (
                    <span className="infoMaxSlider">2% du PASS</span>
                  )}
                </div>
                <div className={`plafondAbondementInput`}>
                  <YesInputWithoutReduxForm
                    value={this.props.plafondAbnUnilateral || '0'}
                    onChange={this.changerValeurPlafondAbnUnilateral}
                    euroField={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {this.afficherMessagePlafondAbondementUnilateralDansPercoi() && (
          <h3 className="msgInfoAbondementUnilateralDansPercoi">
            Vous ne pouvez pas dépasser ce plafond car l'abondement ne peut excéder 16% du PASS au total sur le PERCOL(i).
          </h3>
        )}
      </>
    );
  }
}

// REDUX + REDUX FORM
const mapDispatchToProps = (dispatch) => ({});
AbondementUnilateralForm = reduxForm({ form: 'dispositifAbondementForm' })(AbondementUnilateralForm);
const selectAbondementUnilateralForm = formValueSelector('dispositifAbondementForm');
AbondementUnilateralForm = connect(
  (state) => {
    const plafondAbnUnilateral = selectAbondementUnilateralForm(state, 'plafondAbnUnilateral');
    const tunnel = state.tunnel;
    const abondementUnilateralForm = state.form.abondementUnilateralForm;
    return {
      abondementUnilateralForm,
      plafondAbnUnilateral,
      tunnel
    };
  },
  mapDispatchToProps
)(AbondementUnilateralForm);

export default AbondementUnilateralForm;

/* Built in imports */
import React from 'react';

/* Third party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

/* Internal imports */
import { YesChoix } from '../../../../form/Choix';
import { YesDropdown } from '../../../../form/Dropdown';
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';

const Duree = ({ t, optionParticipation, mep }) => (
  <>
    <div className="cell medium-12">
      <h3>
        <TransHtmlText i18nKey="participation.participationIntegre.mep" />
      </h3>
      <Field name="optionParticipation.duree.type" component={YesDropdown} item={mep} />
    </div>
    {optionParticipation.duree && optionParticipation.duree.type === '2' && (
      <div className="cell medium-12">
        <div className="tunnelRadios">
          <div className="tunnelRadio">
            <Field
              name="optionParticipation.duree.duree"
              component={YesChoix}
              type="radio"
              value={'1'}
              label={t('participation.participationIntegre.duree1')}
              id={'duree1'}
            />
          </div>
          <div className="tunnelRadio">
            <Field
              name="optionParticipation.duree.duree"
              component={YesChoix}
              type="radio"
              value={'2'}
              label={t('participation.participationIntegre.duree2')}
              id={'duree2'}
            />
          </div>
          <div className="tunnelRadio">
            <Field
              name="optionParticipation.duree.duree"
              component={YesChoix}
              type="radio"
              value={'3'}
              label={t('participation.participationIntegre.duree3')}
              id={'duree3'}
            />
          </div>
        </div>
      </div>
    )}
  </>
);

Duree.propTypes = {
  t: PropTypes.func.isRequired,
  mep: PropTypes.array.isRequired,
  optionParticipation: PropTypes.object.isRequired
};

export default Duree;

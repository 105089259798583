import React, { Component } from "react";
import { connect } from "react-redux";
import YesInput from "../../../form/Input";
import { YesButton } from "../../../form/Button";
import { Required, Email } from "../../../../common/Validators";
import { reduxForm, Field } from "redux-form";

class ResultatGlobalMailForm extends Component {
  // constructor(props) {
  //   super(props)
  // }

  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;

    return (
      <div className="grid-x center-item-simu-container resultat-email-simulation">
        <div className="cell medium-12">
          <h2 className="titre-mail-simulation">
            Pour obtenir le résultat global de votre simulation, indiquez votre
            e-mail.
          </h2>
        </div>
        <div className="cell medium-12">
          <form
            autoComplete="off"
            className="grid-x formMailSimulation"
            onSubmit={handleSubmit}
          >
            <div className="cell medium-9">
              <Field
                component={YesInput}
                type="text"
                name="adresseEmail"
                labeltext="Adresse e-mail"
                validate={[Required, Email]}
              />
            </div>
            <div className="cell medium-3 btnValideMailSimulation">
              <YesButton
                cssClass="background-bleugris"
                text="Valider"
                disabled={invalid || submitting || pristine}
              />
            </div>
            <div className="cell medium-12 txtEmailConditions">
              <p>
                Votre e-mail sera exclusivement utilisé afin de vous contacter
                dans le cadre de votre simulation d’épargne salariale.
              </p>
              <p>
                Pour tout savoir sur les conditions d’utilisation de vos données
                personnelles chez Yomoni,{" "}
                <a href="https://www.yomoni.fr/legal/politique-confidentialite">
                  rendez-vous ici
                </a>
                .
              </p>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  simulateur: state.simulateur
});

const mapDispatchToProps = dispatch => ({});

ResultatGlobalMailForm = reduxForm({ form: "mailSimulationForm" })(
  ResultatGlobalMailForm
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(ResultatGlobalMailForm);

import { DEFAULT_CIVILITE } from '../../../../../common/constants';
import { DIRIGEANT } from '../../../../../common/Configuration';
import { FONCTIONS_EPARGNE, IDENTITE_FIELDS_EPARGNE } from '../../../../constantes/US/IDENTITEFORM';
import { NormalizeTelephoneSpace } from '../../../../../common/Normalizers';
import { getAppDefaultCountry } from '../../../../data/countriesUtils';
import { stringSansEspaces } from '../../../../../common/FunctionsUtils';
import { assignNonNullValues } from '../../../../jsObjectUtils';

const DEFAULT_VALUES = {
  civilite: DEFAULT_CIVILITE,
  indicatifCode: getAppDefaultCountry().countryCodeA2
}

export const initFieldsValuesEpargne = (dirigeant, change) => {
  const {
    civilite,
    nom,
    prenom,
    indicatifCode,
    telephone,
    roleDansEntreprise
  } = assignNonNullValues(DEFAULT_VALUES, dirigeant);

  change(IDENTITE_FIELDS_EPARGNE.CIVILITE, civilite);
  change(IDENTITE_FIELDS_EPARGNE.INDICATIF, indicatifCode);

  nom && change(IDENTITE_FIELDS_EPARGNE.NOM, nom);
  prenom && change(IDENTITE_FIELDS_EPARGNE.PRENOM, prenom);
  telephone && change(IDENTITE_FIELDS_EPARGNE.TELEPHONE, NormalizeTelephoneSpace(telephone));


  if(roleDansEntreprise?.length > 0) {
    roleDansEntreprise.map((roleDansEntreprise) => {
      if (roleDansEntreprise.role === DIRIGEANT) {
        if(!roleDansEntreprise.fonction) {
          change(IDENTITE_FIELDS_EPARGNE.FONCTION, FONCTIONS_EPARGNE.PDG);
        } else if(!Object.values(FONCTIONS_EPARGNE).includes(roleDansEntreprise.fonction)){
          change(IDENTITE_FIELDS_EPARGNE.FONCTION, FONCTIONS_EPARGNE.AUTRE);
        } else {
          change(IDENTITE_FIELDS_EPARGNE.FONCTION, roleDansEntreprise.fonction);
        }
      }
      return null;
    });
  }
}

export const getEpargneIdentiteFormSubmit = (values, uidEntreprise) => {
  const {
    civilite,
    prenom,
    nom,
    fonction,
    adresseEmail,
    indicatifCode,
    telephone,
  } = values;

  const valuesAenvoye = {
    prenom,
    nom,
    adresseEmail,
    indicatifCode,
    telephone: stringSansEspaces(telephone),
    civilite,
    fonction,
    uidEntreprise
  };

  return { valuesAenvoye };
};

/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

/* Internal imports */
import SelectDialingCode from "../../common/forms/SelectDialingCode";
import TooltipInfo from "../../../../utils/US/components/TooltipInfo";
import Typography from "../../../DS/data-display/Typography";
import YesInput from '../../../form/Input';
import { CIVILITES_LIST } from '../../../../common/constants';
import { YesButton } from '../../../form/Button';
import { YesDropdown } from '../../../form/Dropdown';
import { initFieldsValuesEpargne } from '../../../../utils/US/components/epargne_salariale/forms/identiteUtils';
import { isUtilisateurAutoriseASInscrire } from '../../../../services/TenantService';
import {
  FONCTIONS_EPARGNE_LIST,
  IDENTITE_FIELDS_EPARGNE
} from '../../../../utils/constantes/US/IDENTITEFORM';
import {
  Email,
  MaxLength40,
  MaxLength50,
  Required,
  Telephone
} from '../../../../common/Validators';

class IdentiteForm extends Component {
  constructor(props) {
    super(props);
    const { pristine, invalid, submitting } = props;
    this.state = {
      submitDisabled: pristine || invalid || submitting
    };
  }

  componentDidMount() {
    const { change, dirigeant } = this.props;
    initFieldsValuesEpargne(dirigeant, change);
    if (!isUtilisateurAutoriseASInscrire() && dirigeant?.adresseEmail) {
      change(IDENTITE_FIELDS_EPARGNE.ADRESSE_EMAIL, dirigeant.adresseEmail);
    }
  }

  componentDidUpdate(prevProps) {
    const { pristine, invalid, submitting } = this.props;
    if (prevProps.invalid !== invalid || prevProps.submitting !== submitting || prevProps.pristine !== pristine) {
      this.setState({
        submitDisabled: invalid || submitting || pristine
      });
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <label className="titreFormulaire">
          <Trans i18nKey="dirigeant.titre" />
        </label>

        <div className="grid-x grid-margin-x">
          <div className="cell medium-4 civilite">
            <Field
              id="civilite"
              name={IDENTITE_FIELDS_EPARGNE.CIVILITE}
              label="Civilité"
              component={YesDropdown}
              item={CIVILITES_LIST}
            />
          </div>

          <div className="cell medium-4">
            <Field
              component={YesInput}
              type="text"
              name={IDENTITE_FIELDS_EPARGNE.NOM}
              labeltext="Nom"
              validate={[Required, MaxLength50]}
              maxLength="50"
            />
          </div>

          <div className="cell medium-4">
            <Field
              component={YesInput}
              type="text"
              name={IDENTITE_FIELDS_EPARGNE.PRENOM}
              labeltext="Prénom"
              validate={[Required, MaxLength40]}
              maxLength="40"
            />
          </div>

          <div className="cell medium-12">
            <Field
              id="fonction"
              name={IDENTITE_FIELDS_EPARGNE.FONCTION}
              label="Fonction"
              component={YesDropdown}
              item={FONCTIONS_EPARGNE_LIST}
            />
          </div>

          {!isUtilisateurAutoriseASInscrire() && (
            <div className="cell medium-5">
              <Field
                component={YesInput}
                type="text"
                name={IDENTITE_FIELDS_EPARGNE.ADRESSE_EMAIL}
                labeltext="Adresse e-mail"
                validate={[Required, Email]}
                maxLength="100"
              />
            </div>
          )}

          <div className="cell medium-3">
            <Field
              name={IDENTITE_FIELDS_EPARGNE.INDICATIF}
              labeltext="Indicatif du pays"
              component={SelectDialingCode}
              validate={[Required]}
            />
          </div>

          <Box className="cell medium-4 separateur" sx={{
            display: 'flex',
            alignItems: 'baseline'
          }}>
            <Box sx={{flexGrow: 1}}>
              <Field
                component={YesInput}
                type="tel"
                name={IDENTITE_FIELDS_EPARGNE.TELEPHONE}
                labeltext="N° téléphone portable"
                validate={[Required, Telephone]}
                mask={[/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]}
                guide={false}
              />
            </Box>
            <Box>
              <TooltipInfo placement="top-start" tooltipContent={
                <Typography info>
                  <Trans i18nKey="dirigeant.info.part.2" />
                </Typography>
              }/>
            </Box>
          </Box>
        </div>

        <div className="buttonCreationContainer">
          <YesButton text="Confirmer" disabled={this.state.submitDisabled} />
        </div>
      </form>
    );
  }
}

IdentiteForm.propTypes = {
  change: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  dirigeant: PropTypes.object.isRequired
};

// REDUX + REDUX FORM
const mapStateToProps = (state) => ({
  dirigeant: state.tunnel.utilisateur
});

export default connect(mapStateToProps)(reduxForm({ form: 'informationsIdentiteForm' })(IdentiteForm));

import React from "react";
import PropTypes from "prop-types";
import Header from "../../components/static-site/Header/Header";
import CookieBanner from "../../components/static-site/CookieBanner";
import Footer from "../../components/static-site/Footer/Footer";

export const StaticSiteLayout = ({ children }) => (
  <div className="App">
    <Header />
    <main className="App-intro">{children}</main>
    <Footer />
    <CookieBanner />
  </div>
);

StaticSiteLayout.propTypes = {
  children: PropTypes.object
};

export default StaticSiteLayout;

/* Internal imports */
import { ETAPES_DOSSIER_EPARGNE, SECTIONS_EPARGNE } from '../../constantes/ROUTES/EPARGNE';
import { getEpargneArborescence } from '../../../routes/private-routes/epargne/default/EpargneArborescence';

const getUrlFromEtapeIndex = (etapeIndex) => {
  return getEpargneArborescence().find(route => route.etapeIndex === etapeIndex)?.path || "";
}

const getEtapeIndexFromUrl = (url) => {
  return getEpargneArborescence().find(route => route.path === url)?.etapeIndex || 0;
}

const getEtapeIndexFromEtapeDossier = (etapeDossier) => {
  return ETAPES_DOSSIER_EPARGNE[etapeDossier];
}
const getUrlFromEtapeDossier = (etapeDossier) => {
  return getEpargneArborescence().find(route => route.etapeIndex === ETAPES_DOSSIER_EPARGNE[etapeDossier])?.path || "";
}

const isInFirstSection = (route) => {
  return route.etapeIndex < SECTIONS_EPARGNE.BESOIN.ETAPE;
}

const isInSecondSection = (route) => {
  return (
    route.etapeIndex >= SECTIONS_EPARGNE.BESOIN.ETAPE
    && route.etapeIndex < SECTIONS_EPARGNE.ADHESION.ETAPE
  );
}

const isLastStepTunnel = (url) => {
  return getEpargneArborescence().find(route => route.path === url)?.etapeIndex === ETAPES_DOSSIER_EPARGNE.INFORMATIONS;
}

const getSectionIndexFromUrl = (url) => {
  const route = getEpargneArborescence().find(route => route.path === url);

  if (!route || isInFirstSection(route)){
    return SECTIONS_EPARGNE.ENTREPRISE.INDEX;
  }

  if (isInSecondSection(route)) {
    return SECTIONS_EPARGNE.BESOIN.INDEX;
  }

  return SECTIONS_EPARGNE.ADHESION.INDEX;
}

export default {
  getUrlFromEtapeIndex,
  getEtapeIndexFromUrl,
  getEtapeIndexFromEtapeDossier,
  getUrlFromEtapeDossier,
  isLastStepTunnel,
  getSectionIndexFromUrl
}

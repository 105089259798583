/* built-in imports */
import React, { Component } from 'react';

/* third-party imports */
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';

/* internal imports */
import Typography from '../../../DS/data-display/Typography';
import YesInput from '../../../form/Input';
import renderCheckbox from '../../../DS/inputs/reduxForm/RenderCheckbox';
import { BOOLEAN } from '../../../../common/constants';
import { CHECKBOXSHAPE } from '../../../../utils/constantes/DS/CHECKBOX';
import { DateContratRequired, DateFr, isGreaterThanAccountingClosingDate } from '../../../../common/Validators';
import { YesButton } from '../../../form/Button';
import { setEffectDate } from '../../../../common/FunctionsUtils';
import {
  GARANTIESPREVOYANCE_FORM_FIELDS,
  MODALITESPERO_FORM_FIELDS,
  MODALITESPERO_PERIODICITE_VALUE
} from '../../../../utils/constantes/US/GARANTIESPREVOYANCEFORM';

class GarantiesPrevoyanceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalitesDateEffet:
        this.props.tunnel.dossier.modalitesPero.dateEffet ??
        setEffectDate(this.props.tunnel.entreprise.complement.dateClotureComptableWithYear),
      modalitesPeriodicite: this.props.tunnel.dossier.modalitesPero.periodicite ?? MODALITESPERO_PERIODICITE_VALUE.MENSUEL,
      nombreSalaries: this.props.tunnel.entreprise.nombreSalaries,
      nombreSalariesMin: 6,
      garantieDeBonneFin: !!this.props.tunnel.dossier.garantiesPrevoyance.garantieDeBonneFin,
      garantiePlancher: !!this.props.tunnel.dossier.garantiesPrevoyance.garantiePlancher
    };
  }

  componentDidMount() {
    this.props.change(
      MODALITESPERO_FORM_FIELDS.DATE_EFFET,
      setEffectDate(this.props.tunnel.entreprise.complement.dateClotureComptableWithYear)
    );
    this.props.change(MODALITESPERO_FORM_FIELDS.PERIODICITE, this.state.modalitesPeriodicite);
    this.props.change(GARANTIESPREVOYANCE_FORM_FIELDS.AU_MOINS_CINQ_SALARIES, !this.areGarantueesDisabled());
    this.props.change(GARANTIESPREVOYANCE_FORM_FIELDS.GARANTIE_DE_BONNE_FIN, this.state.garantieDeBonneFin);
    this.props.change(GARANTIESPREVOYANCE_FORM_FIELDS.GARANTIE_PLANCHER, this.state.garantiePlancher);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dateEffet !== this.state.dateEffet) {
      this.props.change(MODALITESPERO_FORM_FIELDS.DATE_EFFET, this.state.dateEffet);
    }
    if (prevState.periodicite !== this.state.periodicite) {
      this.props.change(MODALITESPERO_FORM_FIELDS.PERIODICITE, this.state.periodicite);
    }
    if (prevState.garantieDeBonneFin !== this.state.garantieDeBonneFin) {
      this.props.change(GARANTIESPREVOYANCE_FORM_FIELDS.GARANTIE_DE_BONNE_FIN, this.state.garantieDeBonneFin);
    }
    if (prevState.garantiePlancher !== this.state.garantiePlancher) {
      this.props.change(GARANTIESPREVOYANCE_FORM_FIELDS.GARANTIE_PLANCHER, this.state.garantiePlancher);
    }
    if (this.state.garantieDeBonneFin && this.areGarantueesDisabled()) {
      this.updateGuarantyState(GARANTIESPREVOYANCE_FORM_FIELDS.GARANTIE_DE_BONNE_FIN, false);
    }
    if (this.state.garantiePlancher && this.areGarantueesDisabled()) {
      this.updateGuarantyState(GARANTIESPREVOYANCE_FORM_FIELDS.GARANTIE_PLANCHER, false);
    }
  }

  updateGuarantyState = (guarantyType, value) => {
    this.setState({
      ...this.state,
      [guarantyType]: value
    });
  };

  areGarantueesDisabled = () => {
    return this.state.nombreSalaries < this.state.nombreSalariesMin;
  };

  render() {
    const { handleSubmit, invalid, submitting } = this.props;
    const { garantieDeBonneFin, garantiePlancher } = this.state;

    return (
      <form onSubmit={handleSubmit} noValidate className="garantiesPrevoyance">
        <label className="titreFormulaire">
          <Trans i18nKey="modalitesPero.titre" />
        </label>

        <div className="dateEffet">
          <div className="yes-label">
            <Trans i18nKey="modalitesPero.dateEffet" />
          </div>

          <div className="cell medium-6">
            <Field
              component={YesInput}
              type="text"
              inputMode="numeric"
              name={MODALITESPERO_FORM_FIELDS.DATE_EFFET}
              labeltext="Date d'effet"
              validate={[DateContratRequired, DateFr, isGreaterThanAccountingClosingDate]}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              placeholder="jj/mm/aaaa"
            />
          </div>
        </div>

        <p className="titreFormulaire">
          <Trans i18nKey="garantiesPrevoyance.titre.1" />
        </p>

        <Typography sx={{ marginBottom: '20px' }}>
          <Trans i18nKey="garantiesPrevoyance.titre.2" />
        </Typography>

        <Typography>
          <Trans i18nKey="garantiesPrevoyance.titre.3" />
        </Typography>
        <div>
          <Field
            name="garantiePlancher-oui"
            component={renderCheckbox}
            shape={CHECKBOXSHAPE.ROUND}
            label={'Oui'}
            value={BOOLEAN.YES}
            onChange={() => this.updateGuarantyState(GARANTIESPREVOYANCE_FORM_FIELDS.GARANTIE_PLANCHER, true)}
            checked={garantiePlancher}
            disabled={this.areGarantueesDisabled()}
          />
          <Field
            name="garantiePlancher-non"
            component={renderCheckbox}
            shape={CHECKBOXSHAPE.ROUND}
            label={'Non'}
            value={BOOLEAN.NO}
            onChange={() => this.updateGuarantyState(GARANTIESPREVOYANCE_FORM_FIELDS.GARANTIE_PLANCHER, false)}
            checked={!garantiePlancher}
            disabled={this.areGarantueesDisabled()}
          />
        </div>
        <Typography>
          <Trans i18nKey="garantiesPrevoyance.titre.4" />
        </Typography>
        <div>
          <Field
            name="garantieDeBonneFin-oui"
            component={renderCheckbox}
            shape={CHECKBOXSHAPE.ROUND}
            label={'Oui'}
            value={BOOLEAN.YES}
            onChange={() => this.updateGuarantyState(GARANTIESPREVOYANCE_FORM_FIELDS.GARANTIE_DE_BONNE_FIN, true)}
            checked={garantieDeBonneFin}
            disabled={this.areGarantueesDisabled()}
          />
          <Field
            name="garantieDeBonneFin-non"
            component={renderCheckbox}
            shape={CHECKBOXSHAPE.ROUND}
            label={'Non'}
            value={BOOLEAN.NO}
            onChange={() => this.updateGuarantyState(GARANTIESPREVOYANCE_FORM_FIELDS.GARANTIE_DE_BONNE_FIN, false)}
            checked={!garantieDeBonneFin}
            disabled={this.areGarantueesDisabled()}
          />
        </div>
        <div className="buttonCreationContainer">
          <YesButton text="Confirmer" disabled={invalid || submitting} />
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ tunnel }) => ({ tunnel });

export default connect(mapStateToProps)(reduxForm({ form: 'garantiesPrevoyanceForm' })(GarantiesPrevoyanceForm));

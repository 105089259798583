/* built-in imports */
import * as React from 'react'

/* external imports */
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next';

/* Internal imports */
import FormLabel from '../../DS/inputs/form/FormLabel';
import Switch from '../../DS/inputs/Switch';
import Typography from '../../DS/data-display/Typography';

const renderSwitch = ( { checked, input } ) => (
  <Switch
    inputProps={{ role: 'switch' }}
    checked={checked}
    {...input}
  />
)

const WorkAdvicesSheetsCguForm = (props) => {
  const tradKey = 'components.US.form.work-advices-sheets-cgu-form',
    { t }   = useTranslation(),
  {
    isFirstWorkAdvicesSheets,
    isFirstWorkAdvicesSheetsChange
  } = props;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: '1em'
          }}
        >
          <Typography>{t(`${tradKey}.body`)}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: '1em'
          }}
        >
          <FormGroup
            sx={{
              flexDirection: 'row',
              flexGrow: 1,
              alignItems: 'center',
              flexWrap: 'nowrap',
            }}
          >
            <FormLabel
              sx={{ flexGrow: 1 }}
            >
              {t(`${tradKey}.radio.label`)}
            </FormLabel>
            <Stack
              direction="row"
              alignItems="center"
            >
              <Typography>{t('utils.form.radio.no')}</Typography>
              <Field
                name="first-work-advices-sheet"
                component={renderSwitch}
                checked={isFirstWorkAdvicesSheets}
                onChange={isFirstWorkAdvicesSheetsChange}
              />
              <Typography>{t('utils.form.radio.yes')}</Typography>
            </Stack>
          </FormGroup>
        </Grid>
      </Grid>
    </Box>
  )
}

WorkAdvicesSheetsCguForm.propTypes = {
  isFirstWorkAdvicesSheets: PropTypes.bool.isRequired,
  isFirstWorkAdvicesSheetsChange: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'WorkAdvicesSheetsCguForm'
})(WorkAdvicesSheetsCguForm)

import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import createHistory from "history/createBrowserHistory";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "./reducers";
import { gaMiddleware } from "./middleware";

// Persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "simulation"], // Ajouter ici les stores à persister
};
const persistedReducer = persistReducer(persistConfig, reducer);

// History
export const history = createHistory();
const historyMiddleware = routerMiddleware(history);

// Load state & middlewares
const initialState = {};
const enhancers = [];
const middleware = [thunk, gaMiddleware, historyMiddleware];

// Activate Redux devtools on dev only. cf : 'npm start'
if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(persistedReducer, initialState, composedEnhancers);

export const persistor = persistStore(store);

export default store;

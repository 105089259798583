import Page404 from '../../components/yes/utils/Page404';
import { NoLayout } from '../../layouts/default/NoLayout';
import { STATUT_LIASSE } from '../../common/Configuration';
import { recupererUrlSignature } from '../../services/LiasseService';

const getRoutePropsByDomainAndType = (component, layout, currentActivityDomain, expectedActivityDomain, currentType, expectedType) => {
  let routeProps = { component, layout };

  if ((currentActivityDomain !== expectedActivityDomain)
    || (currentType !== expectedType)){
    routeProps.component = Page404;
    routeProps.layout = NoLayout;
  }

  return routeProps;
}

export const redirectDocuSign = (liasse, redirectUrl) => {
  if (!liasse || (liasse.statut !== STATUT_LIASSE.SIGNEE && liasse.statut !== STATUT_LIASSE.ENVOYEE)) {
    return false;
  }

  recupererUrlSignature(liasse.uid, redirectUrl)
    .then((urlSignature) => {
      window.location.assign(urlSignature.url);
    })
    .catch(() => {
      return false;
    });

  return true;
};

export default {
  getRoutePropsByDomainAndType,
  redirectDocuSign
};
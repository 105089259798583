/* Internal imports */
import { ETAPES_DOSSIER_ASSURANCE, SECTIONS_ASSURANCE } from '../../constantes/ROUTES/ASSURANCE';
import { getAssuranceArborescence } from '../../../routes/private-routes/assurance/default/AssuranceArboresence';

const getUrlFromEtapeIndex = (etapeIndex) => {
  return getAssuranceArborescence().find(route => route.etapeIndex === etapeIndex)?.path || "";
}

const getEtapeIndexFromUrl = (url) => {
  return getAssuranceArborescence().find(route => route.path === url)?.etapeIndex || 0;
}

const getEtapeIndexFromEtapeDossier = (etapeDossier) => {
  return ETAPES_DOSSIER_ASSURANCE[etapeDossier];
}
const getUrlFromEtapeDossier = (etapeDossier) => {
  return getAssuranceArborescence().find(route => route.etapeIndex === ETAPES_DOSSIER_ASSURANCE[etapeDossier])?.path || "";
}

const isInFirstSection = (route) => {
  return route.etapeIndex < SECTIONS_ASSURANCE.BESOIN.ETAPE;
}

const isInSecondSection = (route) => {
  return (
    route.etapeIndex >= SECTIONS_ASSURANCE.BESOIN.ETAPE
    && route.etapeIndex < SECTIONS_ASSURANCE.ADHESION.ETAPE
  );
}

const isLastStepTunnel = (url) => {
  return getAssuranceArborescence().find(route => route.path === url)?.etapeIndex === ETAPES_DOSSIER_ASSURANCE.INFORMATIONS;
}

const getSectionIndexFromUrl = (url) => {
  const route = getAssuranceArborescence().find(route => route.path === url);

  if (!route || isInFirstSection(route)){
    return SECTIONS_ASSURANCE.ENTREPRISE.INDEX;
  }

  if (isInSecondSection(route)) {
    return SECTIONS_ASSURANCE.BESOIN.INDEX;
  }

  return SECTIONS_ASSURANCE.ADHESION.INDEX;
}

export default {
  getUrlFromEtapeIndex,
  getEtapeIndexFromUrl,
  getEtapeIndexFromEtapeDossier,
  getUrlFromEtapeDossier,
  isLastStepTunnel,
  getSectionIndexFromUrl
}

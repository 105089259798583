/* Built-in imports */
import React from "react";

/* Third-party imports */
import { Field } from "redux-form";
import { Trans } from "react-i18next";

/* Internal imports */
import TarificationBic from '../../../../../DS/form/TarificationBic';
import TarificationIban from "../../../../../DS/form/TarificationIban";
import { Grid } from '@mui/material';
import { Required } from '../../../../../../common/Validators';
import { YesChoix } from "../../../../../form/Choix";

const MaroquinerieRemunerationSection = ({ customErrorIban }) => {
  return (
    <div className="remunerationSection">
      <label className="titreFormulaireGes" htmlFor="remunerationSection">
        <Trans i18nKey="ges-tarification.remuneration.titre" />
        <Trans i18nKey="ges-tarification.HT" />
      </label>

      <div>
        <label className="titreFormulaire titreTarificationGes" htmlFor="droitsEntreeSubsection">
          <Trans i18nKey="ges-tarification.titre.4" />
        </label>

        <p className={'ges-libelle-tarification'}>
          <Trans i18nKey="ges-tarification.fraisEntree.question.partFraisMaroquinerie" />
        </p>

        <div className="grid-x grid-margin-x">
          <div className="cell medium-12">
            <h3>
              <Trans i18nKey="ges-tarification.fraisEntree.question.cibleFraisMaroquinerie" />
            </h3>
          </div>

          <div className="cell medium-12">
            <div className="tunnelRadios">
              <div className="tunnelRadio">
                <Field
                  name="ciblePrelevements"
                  component={YesChoix}
                  type="radio"
                  value={"entreprise"}
                  label="L'entreprise"
                  id="ciblePrelevements-entreprise"
                  validate={[Required]}
                />
              </div>
              <div className="tunnelRadio">
                <Field
                  name="ciblePrelevements"
                  component={YesChoix}
                  type="radio"
                  value={"salarie"}
                  label="Le bénéficiaire"
                  id="ciblePrelevements-beneficiaire"
                  validate={[Required]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <label className="titreFormulaire" htmlFor="ibanBicSubsection">
          <Trans i18nKey="ges-tarification.titre.5" />
        </label>
        <Grid container spacing={2} sx={{ mt: "-30px" }}>
          <Grid item xs={6}>
            <TarificationIban customError={customErrorIban} />
          </Grid>
          <Grid item xs={3}>
            <TarificationBic />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default MaroquinerieRemunerationSection;
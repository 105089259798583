import React, { Component } from "react";
import { connect } from "react-redux";
import Typography from '../../DS/data-display/Typography';

class YesLoader extends Component {
  render() {
    let classLoader = "loader-content";

    const { loaderMessage } = this.props;

    if (this.props.tunnel.loader) {
      classLoader = classLoader + " loader-actif";
    }

    return (
      <div className={classLoader}>
        <div className="loader">
          <div className="loader__bar" />
          <div className="loader__bar" />
          <div className="loader__bar" />
          <div className="loader__bar" />
          <div className="loader__bar" />
          <div className="loader__ball" />
        </div>
        {loaderMessage &&
          <Typography>
            {loaderMessage}
          </Typography>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tunnel: state.tunnel,
  loaderMessage: state.tunnel.loaderMessage
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YesLoader);

/* Built in imports */
import React, { Component } from 'react';

/* Internal imports */
import TooltipInfo  from '../../utils/US/components/TooltipInfo';

export default class YesInputWithoutReduxForm extends Component {
  render() {
    const { labeltext, euroField, tooltip, value, onChange, pattern, disabled } = this.props;

    let classnameInput = 'inputText';
    const classnameDivInput = 'divInput';
    let classnameDisabled = '';

    if (euroField) {
      classnameInput = classnameInput + ' inputEuro';
    }

    if (disabled) {
      classnameDisabled = 'inputDisabled';
    } else {
      classnameDisabled = 'inputEnabled';
    }

    return (
      <div className={'inputEnabled inputWithoutRedux ' + classnameDisabled}>
        {
          tooltip &&
          <TooltipInfo tooltipContent={tooltip} />
        }
        <div className={classnameDivInput}>
          <input type="text" value={value || ''} pattern={pattern} className={classnameInput} onChange={onChange} />
          <label className="label-input">{labeltext}</label>
          {euroField && <span className="euroSign">€</span>}
        </div>
      </div>
    );
  }
}

YesInputWithoutReduxForm.defaultProps = {
  disabled: false
};

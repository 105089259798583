import { BOOLEAN, COUNTRY } from '../../../../../common/constants';
import { ENTREPRISE_FORM_FIELDS_ASSURANCE } from '../../../../constantes/US/ENTREPRISEFORM';
import {
  booleanToString,
  isAssociation,
  sirenAvecEspace,
  siretAvecEspace,
  stringSansEspaces,
  stringToBoolean
} from '../../../../../common/FunctionsUtils';

export const changeFieldsValuesAssurance = (values, change) => {
  const {
    raisonSociale,
    dateCreation,
    formeJuridique,
    siret,
    siren,
    codeNaf,
    adresse: { rue, codePostal, ville, pays },
    autreFormeJuridique,
    rna,
    autreIdentifiant,
    libelleNaf,
    nouveauClient,
    nombreSalaries,
    dateClotureComptable
  } = values;

  raisonSociale && change(ENTREPRISE_FORM_FIELDS_ASSURANCE.RAISON_SOCIALE, raisonSociale);
  dateCreation && change(ENTREPRISE_FORM_FIELDS_ASSURANCE.DATE_CREATION, dateCreation);
  change(ENTREPRISE_FORM_FIELDS_ASSURANCE.FORME_JURIDIQUE, formeJuridique);
  siret && change(ENTREPRISE_FORM_FIELDS_ASSURANCE.SIRET, siretAvecEspace(siret));
  siren && change(ENTREPRISE_FORM_FIELDS_ASSURANCE.SIREN, sirenAvecEspace(siren));
  codeNaf && change(ENTREPRISE_FORM_FIELDS_ASSURANCE.CODE_NAF, codeNaf);
  rue && change(ENTREPRISE_FORM_FIELDS_ASSURANCE.RUE, rue);
  codePostal && change(ENTREPRISE_FORM_FIELDS_ASSURANCE.CODE_POSTAL, codePostal);
  ville && change(ENTREPRISE_FORM_FIELDS_ASSURANCE.VILLE, ville);
  change(ENTREPRISE_FORM_FIELDS_ASSURANCE.PAYS, JSON.stringify(pays ? pays : COUNTRY.FRANCE));

  //Assurance Specific Values
  autreFormeJuridique && change(ENTREPRISE_FORM_FIELDS_ASSURANCE.AUTRE_FORME_JURIDIQUE, autreFormeJuridique);
  autreIdentifiant && change(ENTREPRISE_FORM_FIELDS_ASSURANCE.AUTRE_IDENTIFIANT, autreIdentifiant);
  libelleNaf && change(ENTREPRISE_FORM_FIELDS_ASSURANCE.LIBELLE_NAF, libelleNaf);
  rna && change(ENTREPRISE_FORM_FIELDS_ASSURANCE.RNA, rna);
  nombreSalaries && change(ENTREPRISE_FORM_FIELDS_ASSURANCE.NOMBRE_SALARIES, nombreSalaries);
  dateClotureComptable && change(ENTREPRISE_FORM_FIELDS_ASSURANCE.DATE_CLOTURE_COMPTABLE, dateClotureComptable);
  change(
    ENTREPRISE_FORM_FIELDS_ASSURANCE.NOUVEAU_CLIENT,
    nouveauClient !== null && nouveauClient !== undefined ? booleanToString(nouveauClient) : BOOLEAN.YES
  );
};

export const getAssuranceEntrepriseFormSubmit = (values, uidUtilisateur) => {
  const {
    nouveauClient,
    raisonSociale,
    dateCreation,
    siret,
    siren,
    rna,
    autreIdentifiant,
    codeNaf,
    libelleNaf,
    formeJuridique,
    autreFormeJuridique,
    rue,
    codePostal,
    ville,
    pays,
    nombreSalaries
  } = values;

  const valuesAenvoye = {
    raisonSociale,
    dateCreation,
    siret: stringSansEspaces(siret),
    codeNaf: codeNaf.toUpperCase(),
    formeJuridique,
    adresse: { ville, codePostal, rue, pays: JSON.parse(pays) },
    uidDirigeant: uidUtilisateur,
    nouveauClient: stringToBoolean(nouveauClient),
    siren: siren ? stringSansEspaces(siren) : undefined,
    rna: isAssociation(formeJuridique) ? (rna ? stringSansEspaces(rna) : undefined) : null,
    libelleNaf: libelleNaf ? libelleNaf : undefined,
    autreIdentifiant: autreIdentifiant,
    autreFormeJuridique,
    nombreSalaries
  };

  return { valuesAenvoye };
};

import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

const DialogContentRoot = styled(DialogContent, { name: 'DialogContent' })(() => ({
  '&.MuiDialogContent-root': {
    borderColor: 'var(--cn-color-primary)',
    paddingLeft: '.8em',
    paddingRight: '.8em',
  },
}))

export default DialogContentRoot
/* Built in imports */
import React, { useEffect } from 'react';

/* Third party imports */
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

/* Internal imports */
import Alert from '../../../DS/feedback/Alert';
import FormLabel from '../../../DS/inputs/form/FormLabel';
import Switch from '../../../DS/inputs/Switch';
import TextField from '../../../DS/inputs/TextField';
import Typography from '../../../DS/data-display/Typography';
import renderCheckbox from '../../../DS/inputs/reduxForm/RenderCheckbox';
import inputUtils from '../../../../utils/inputs';



const renderSwitch = ( { checked, input } ) => (
  <Switch
    inputProps={{ role: 'switch' }}
    checked={checked}
    {...input}
  />
)

const renderTextField = ({ input, label, placeholder, ...custom }) => (
  <TextField
      sx={{ display: 'flex'}}
      label={label}
      placeholder={placeholder}
      variant="filled"
      {...input}
      {...custom}
    />
)

const EmployeesSubscriptionSubForm = function(props) {
  const {
      isAllEmployeesSubscribe,
      isSignatory,
      contractCategorySelected,
      contractSubscriptionInfos,
      showErrors,
      handleIsAllEmployeesSubscribe,
      handleContractSubscriptionCadreChange,
      handleContractSubscriptionNoCadreChange,
      handleIsSignatoryChange,
      isEmployeesSubscriptionErrors
    } = props,
    tradKey  = 'components.US.form.work-advices-sheets-employees.employees-subscription-sub-form',
    { t }    = useTranslation(),
    maxEmployeesCount = 100000;

  const {
    cadre,
    noCadre
  } = contractSubscriptionInfos;

  useEffect(() => {
    props.change("category-select", contractCategorySelected);
    props.change("cadre-subscription-category-art21-employees-count", cadre.art21.employeesCount);
    props.change("cadre-subscription-category-art2122-employees-count", cadre.art2122.employeesCount);
    props.change("cadre-subscription-category-art2122br-employees-count", cadre.art2122Br.employeesCount);
    props.change("no-cadre-subscription-category-art21-employees-count", noCadre.art21.employeesCount);
    props.change("no-cadre-subscription-category-art2122-employees-count", noCadre.art2122.employeesCount);
    props.change("no-cadre-subscription-category-art2122br-employees-count", noCadre.art2122Br.employeesCount);
    props.change("cadre-subscription-category-other", cadre.other);

  });

  return(
    <Box
      component="div"
      sx={{ display: 'flex' }}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <FormGroup
            sx={{
              flexDirection: 'row',
              flexGrow: 1,
              alignItems: 'center',
              flexWrap: 'nowrap',
            }}
          >
            <FormLabel
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {t(`${tradKey}.contract`)}
            </FormLabel>
            <Stack direction="row" alignItems="center">
              <Typography>{t('utils.form.radio.no')}</Typography>
              <Field
                name="employees-subscription"
                component={renderSwitch}
                onChange={handleIsAllEmployeesSubscribe}
                checked={isAllEmployeesSubscribe}
              />
              <Typography>{t('utils.form.radio.yes')}</Typography>
            </Stack>
          </FormGroup>
        </Grid>
        {!isAllEmployeesSubscribe &&
        <Grid item xs={12} sx={{ marginTop: '1em' }}>
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: '1em'
            }}
          >
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {t(`${tradKey}.employees.category.label.cadre`)}
              </FormLabel>
            </FormControl>
          </Grid>
          <FormGroup>
            <Box
              component="div"
            >
              <Field
                name="cadre-subscription-category-art21"
                component={renderCheckbox}
                onChange={(event) => handleContractSubscriptionCadreChange(event,'cadre', 'art21', 'checked')}
                label={t(`${tradKey}.employees.category.art21`)}
                checked={cadre.art21.checked}
              />
              {cadre.art21.checked &&
              <Field
                name="cadre-subscription-category-art21-employees-count"
                label={t(`${tradKey}.employees.category.effective`)}
                type='number'
                component={renderTextField}
                value={cadre.art21.employeesCount}
                onChange={(event) => {
                  event.target.value = inputUtils.limitNumberValue(0, maxEmployeesCount, event.target.value)
                  handleContractSubscriptionCadreChange(event,'cadre', 'art21', 'employeesCount')
                }}
                InputProps={{
                  min: 0,
                  max: maxEmployeesCount,
                }}
              />
              }
            </Box>
            <Box
              component="div"
              sx={{ marginTop: '1em' }}
            >
              <Field
                name="cadre-subscription-category-art2122"
                component={renderCheckbox}
                onChange={(event) => handleContractSubscriptionCadreChange(event,'cadre', 'art2122', 'checked')}
                label={t(`${tradKey}.employees.category.art2122`)}
                checked={cadre.art2122.checked}
              />
              {cadre.art2122.checked &&
              <Field
                name="cadre-subscription-category-art2122-employees-count"
                label={t(`${tradKey}.employees.category.effective`)}
                type='number'
                component={renderTextField}
                value={cadre.art2122.employeesCount}
                onChange={(event) => {
                  event.target.value = inputUtils.limitNumberValue(0, maxEmployeesCount, event.target.value)
                  handleContractSubscriptionCadreChange(event,'cadre', 'art2122', 'employeesCount')
                }}
                InputProps={{
                  min: 0,
                  max: maxEmployeesCount,
                }}
              />
              }
            </Box>
            <Box
              component="div"
              sx={{ marginTop: '1em' }}
            >
              <Field
                name="cadre-subscription-category-art2122br"
                component={renderCheckbox}
                onChange={(event) => handleContractSubscriptionCadreChange(event,'cadre', 'art2122Br', 'checked')}
                label={t(`${tradKey}.employees.category.art2122br`)}
                checked={cadre.art2122Br.checked}
              />
              {cadre.art2122Br.checked &&
              <Field
                name="cadre-subscription-category-art2122br-employees-count"
                label={t(`${tradKey}.employees.category.effective`)}
                type='number'
                component={renderTextField}
                value={cadre.art2122Br.employeesCount}
                onChange={(event) => {
                  event.target.value = inputUtils.limitNumberValue(0, maxEmployeesCount, event.target.value)
                  handleContractSubscriptionCadreChange(event,'cadre', 'art2122Br', 'employeesCount')
                }}
                InputProps={{
                  min: 0,
                  max: maxEmployeesCount,
                }}
              />
              }
            </Box>
          </FormGroup>
        </Grid>
        }
        {!isAllEmployeesSubscribe &&
        <Grid item xs={12} sx={{ marginTop: '1em' }}>
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: '1em'
            }}
          >
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {t(`${tradKey}.employees.category.label.no-cadre`)}
              </FormLabel>
            </FormControl>
          </Grid>
          <FormGroup>
            <Box
              component="div"
            >
              <Field
                name="no-cadre-subscription-category-art21"
                component={renderCheckbox}
                onChange={(event) => handleContractSubscriptionNoCadreChange(event,'noCadre', 'art21', 'checked')}
                label={t(`${tradKey}.employees.category.no-cadre.art21`)}
                checked={noCadre.art21.checked}
              />
              {noCadre.art21.checked &&
              <Field
                name="no-cadre-subscription-category-art21-employees-count"
                label={t(`${tradKey}.employees.category.effective`)}
                type='number'
                component={renderTextField}
                value={noCadre.art21.employeesCount}
                onChange={(event) => {
                  event.target.value = inputUtils.limitNumberValue(0, maxEmployeesCount, event.target.value)
                  handleContractSubscriptionNoCadreChange(event,'noCadre', 'art21', 'employeesCount')
                }}
                InputProps={{
                  min: 0,
                  max: maxEmployeesCount,
                }}
              />
              }
            </Box>
            <Box
              component="div"
              sx={{ marginTop: '1em' }}
            >
              <Field
                name="no-cadre-subscription-category-art2122"
                component={renderCheckbox}
                onChange={(event) => handleContractSubscriptionNoCadreChange(event,'noCadre', 'art2122', 'checked')}
                label={t(`${tradKey}.employees.category.no-cadre.art2122`)}
                checked={noCadre.art2122.checked}
              />
              {noCadre.art2122.checked &&
              <Field
                name="no-cadre-subscription-category-art2122-employees-count"
                label={t(`${tradKey}.employees.category.effective`)}
                type='number'
                component={renderTextField}
                value={noCadre.art2122.employeesCount}
                onChange={(event) => {
                  event.target.value = inputUtils.limitNumberValue(0, maxEmployeesCount, event.target.value)
                  handleContractSubscriptionNoCadreChange(event,'noCadre', 'art2122', 'employeesCount')
                }}
                InputProps={{
                  min: 0,
                  max: maxEmployeesCount,
                }}
              />
              }
            </Box>
            <Box
              component="div"
              sx={{ marginTop: '1em' }}
            >
              <Field
                name="no-cadre-subscription-category-art2122br"
                component={renderCheckbox}
                onChange={(event) => handleContractSubscriptionNoCadreChange(event,'noCadre', 'art2122Br', 'checked')}
                label={t(`${tradKey}.employees.category.no-cadre.art2122br`)}
                checked={noCadre.art2122Br.checked}
              />
              {noCadre.art2122Br.checked &&
              <Field
                name="no-cadre-subscription-category-art2122br-employees-count"
                label={t(`${tradKey}.employees.category.effective`)}
                type='number'
                component={renderTextField}
                value={noCadre.art2122Br.employeesCount}
                onChange={(event) => {
                  event.target.value = inputUtils.limitNumberValue(0, maxEmployeesCount, event.target.value)
                  handleContractSubscriptionNoCadreChange(event,'noCadre', 'art2122Br', 'employeesCount')
                }}
                InputProps={{
                  min: 0,
                  max: maxEmployeesCount,
                }}
              />
              }
            </Box>
            {isEmployeesSubscriptionErrors() && showErrors &&
            <Grid item xs={12} sx={{
              marginTop: '1em'
            }}>
              <Alert sx={{ alignItems: 'center' }} severity="error">{t(`${tradKey}.errors.subscriptionForm`)}</Alert>
            </Grid>
            }
          </FormGroup>

        </Grid>
        }
        <Grid item xs={12}>
          <Divider sx={{
            marginTop: '1em',
            marginBottom: '1em',
            borderColor: 'var(--cn-color-primary)'
          }}/>
          <FormGroup>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="cadre-subscription-signatory"
                  component={renderCheckbox}
                  required
                  onChange={handleIsSignatoryChange}
                  label={t(`${tradKey}.signatory`)}
                  checked={isSignatory}
                />
              </Grid>
              {!isSignatory && showErrors &&
              <Grid item xs={12}>
                <Alert sx={{ alignItems: 'center' }} severity="error">{t(`${tradKey}.errors.habilitation`)}</Alert>
              </Grid>
              }
            </Grid>
          </FormGroup>
        </Grid>
      </Grid>
    </Box>
  )
}

EmployeesSubscriptionSubForm.propTypes = {
  isAllEmployeesSubscribe: PropTypes.bool.isRequired,
  isSignatory: PropTypes.bool.isRequired,
  contractCategorySelected: PropTypes.string.isRequired,
  contractSubscriptionInfos: PropTypes.object.isRequired,
  showErrors: PropTypes.bool.isRequired,
  handleContractSubscriptionCadreChange: PropTypes.func.isRequired,
  handleContractSubscriptionNoCadreChange: PropTypes.func.isRequired,
  handleIsAllEmployeesSubscribe: PropTypes.func.isRequired,
  handleIsSignatoryChange: PropTypes.func.isRequired,
  handleOtherContractChange: PropTypes.func.isRequired
}

export default reduxForm({
  form: 'EmployeesSubscriptionSubForm'
})(EmployeesSubscriptionSubForm)
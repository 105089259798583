import {
  plafondAbondementPEE,
  plafondAbondementPERCO,
  plafondInteressement,
  plafondParticipation,
  recommandationCapitaliser,
  recommandationMotiver,
  recommandationFideliser,
  budget,
  coutEntrepriseEpargneSalariale,
  sommePercueSalarieEpargneSalariale,
  coutEntreprisePrimeClassique,
  sommePercueSalariePrimeClassique,
  appliquer20pourcentMasseSalarialeEnCasDeDepassement
} from "../fonctions";

import {
  TAUX_CHARGES_PATRONALES,
  TAUX_CHARGES_SALARIALES,
  TAUX_MARGINAUX_DIMPOSITION
} from "../configuration";

// Actions déclenchées par les actioncreators publiques
export const UPDATE_MASSE_SALARIALE_AND_EFFECTIF =
  "simulateur/UPDATE_MASSE_SALARIALE_AND_EFFECTIF";
export const UPDATE_MASSE_SALARIALE = "simulateur/UPDATE_MASSES_SALARIALE";
export const UPDATE_EFFECTIF = "simulateur/UPDATE_EFFECTIF";
export const UPDATE_OBJECTIF = "simulateur/UPDATE_OBJECTIF";
export const UPDATE_ABONDEMENT_PEE = "simulateur/UPDATE_ABONDEMENT_PEE";
export const UPDATE_ABONDEMENT_PERCO = "simulateur/UPDATE_ABONDEMENT_PERCO";
export const UPDATE_INTERESSEMENT = "simulateur/UPDATE_INTERESSEMENT";
export const UPDATE_PARTICIPATION = "simulateur/UPDATE_PARTICIPATION";
export const UPDATE_TAUX_CHARGES_PATRONALES =
  "simulateur/UPDATE_TAUX_CHARGES_PATRONALES";
export const UPDATE_TAUX_MARGINAL_DIMPOSITION =
  "simulateur/UPDATE_TAUX_MARGINAL_DIMPOSITION";
export const UPDATE_TAUX_CHARGES_SALARIALES =
  "simulateur/UPDATE_TAUX_CHARGES_SALARIALES";
export const GET_ECRAN_SYNTHESE = "simulateur/GET_ECRAN_SYNTHESE";
export const GET_ECRAN_DETAIL_ENTREPRISE =
  "simulateur/GET_ECRAN_DETAIL_ENTREPRISE";
export const GET_ECRAN_DETAIL_SALARIE = "simulateur/GET_ECRAN_DETAIL_SALARIE";
export const UPDATE_ANIMER_GRAPHIQUES = "simulateur/UPDATE_ANIMER_GRAPHIQUES";
export const UPDATE_FAIRE_BENEFICE = "simulateur/UPDATE_FAIRE_BENEFICE";
export const UPDATE_VOIR_INFO_PLAFOND_INT_ATTEINT =
  "simulateur/UPDATE_VOIR_INFO_PLAFOND_INT_ATTEINT";

// Actions déclenchées par les actioncreators privés
export const UPDATE_BUDGET = "simulateur/UPDATE_BUDGET";
export const UPDATE_EPARGNE_SALARIALE_DISPOSITIF =
  "simulateur/UPDATE_EPARGNE_SALARIALE_DISPOSITIF";
export const UPDATE_EPARGNE_SALARIALE_COUT_ENTREPRISE =
  "simulateur/UPDATE_EPARGNE_SALARIALE_COUT_ENTREPRISE";
export const UPDATE_EPARGNE_SALARIALE_SOMME_PERCUE_SALARIE =
  "simulateur/UPDATE_EPARGNE_SALARIALE_SOMME_PERCUE_SALARIE";
export const UPDATE_PRIME_ATTRIBUEE = "simulateur/UPDATE_PRIME_ATTRIBUEE";
export const UPDATE_PRIME_CLASSIQUE_COUT_ENTREPRISE =
  "simulateur/UPDATE_PRIME_CLASSIQUE_COUT_ENTREPRISE";
export const UPDATE_PRIME_CLASSIQUE_SOMME_PERCUE_SALARIE =
  "simulateur/UPDATE_PRIME_CLASSIQUE_SOMME_PERCUE_SALARIE";

export const OBJECTIF_FIDELISER = "fideliser";
export const OBJECTIF_MOTIVER = "motiver";
export const OBJECTIF_CONSTITUER_CAPITALE = "constituer_capital";

// --- ActionCreators publiques, déclenchées a l'issue d'une interaction utilisateur ---
// -------------------------------------------------------------------------------------

export const setMasseSalarialeAndEffectif = (masseSalariale, effectif) => (
  dispatch,
  getState
) => {
  dispatch({
    type: UPDATE_MASSE_SALARIALE_AND_EFFECTIF,
    masseSalariale,
    effectif
  });
  updateBudget(dispatch, getState);
};

export const setParticipationPlusDe50Salarie = montantParticipation => (
  dispatch,
  getState
) => {};

export const setMasseSalariale = masseSalariale => (dispatch, getState) => {
  dispatch({
    type: UPDATE_MASSE_SALARIALE,
    masseSalariale
  });
  updateBudget(dispatch, getState);
};

export const setEffectif = effectif => (dispatch, getState) => {
  dispatch({
    type: UPDATE_EFFECTIF,
    effectif
  });
  updateBudget(dispatch, getState);
};

export const choisirObjectifFideliser = participation => (
  dispatch,
  getState
) => {
  dispatch({ type: UPDATE_OBJECTIF, objectif: OBJECTIF_FIDELISER });
  updateEpargneSalarialeDispositif(dispatch, getState, participation);
};

export const choisirObjectifMotiver = participation => (dispatch, getState) => {
  dispatch({ type: UPDATE_OBJECTIF, objectif: OBJECTIF_MOTIVER });
  updateEpargneSalarialeDispositif(dispatch, getState, participation);
};

export const choisirObjectifConstituerCapitale = participation => (
  dispatch,
  getState
) => {
  dispatch({ type: UPDATE_OBJECTIF, objectif: OBJECTIF_CONSTITUER_CAPITALE });
  updateEpargneSalarialeDispositif(dispatch, getState, participation);
};

export const setAbondementPEE = abondementPEE => (dispatch, getState) => {
  let {
    abondementPERCO,
    interessement,
    participation
  } = getState().simulateur.epargneSalariale.dispositif;
  dispatch({
    type: UPDATE_ABONDEMENT_PEE,
    abondementPEE: Math.min(abondementPEE, plafondAbondementPEE(abondementPEE)),
    enveloppeIndividuelle:
      abondementPEE + abondementPERCO + interessement + participation
  });
  updateEpargneSalarialeCoutEntreprise(dispatch, getState);
  updateEpargneSalarialeSommePercueSalarie(dispatch, getState);
  updatePrimeAttribuee(dispatch, getState);
};

export const setAbondementPERCO = abondementPERCO => (dispatch, getState) => {
  let {
    abondementPEE,
    interessement,
    participation
  } = getState().simulateur.epargneSalariale.dispositif;
  dispatch({
    type: UPDATE_ABONDEMENT_PERCO,
    abondementPERCO: Math.min(
      abondementPERCO,
      plafondAbondementPERCO(abondementPERCO)
    ),
    enveloppeIndividuelle:
      abondementPEE + abondementPERCO + interessement + participation
  });
  updateEpargneSalarialeCoutEntreprise(dispatch, getState);
  updateEpargneSalarialeSommePercueSalarie(dispatch, getState);
  updatePrimeAttribuee(dispatch, getState);
};

export const setInteressement = interessement => (dispatch, getState) => {
  let { masseSalariale, effectif } = getState().simulateur;
  let {
    abondementPEE,
    abondementPERCO,
    participation
  } = getState().simulateur.epargneSalariale.dispositif;

  const interessementAjour = appliquer20pourcentMasseSalarialeEnCasDeDepassement(
    Math.min(interessement, plafondInteressement(interessement)),
    masseSalariale / effectif
  );

  dispatch({
    type: UPDATE_INTERESSEMENT,
    interessement: interessementAjour.interessement,
    depassement20pourcentMasseSalariale:
      interessementAjour.depassement20pourcentMasseSalariale,
    enveloppeIndividuelle:
      abondementPEE +
      abondementPERCO +
      interessementAjour.interessement +
      participation
  });
  updateEpargneSalarialeCoutEntreprise(dispatch, getState);
  updateEpargneSalarialeSommePercueSalarie(dispatch, getState);
  updatePrimeAttribuee(dispatch, getState);
};

export const setParticipation = participation => (dispatch, getState) => {
  let {
    abondementPEE,
    abondementPERCO,
    interessement
  } = getState().simulateur.epargneSalariale.dispositif;
  dispatch({
    type: UPDATE_PARTICIPATION,
    participation: Math.min(participation, plafondParticipation(participation)),
    enveloppeIndividuelle:
      abondementPEE + abondementPERCO + interessement + participation
  });
  updateEpargneSalarialeCoutEntreprise(dispatch, getState);
  updateEpargneSalarialeSommePercueSalarie(dispatch, getState);
  updatePrimeAttribuee(dispatch, getState);
};

export const setPrimeAttribuee = primeAttribuee => (dispatch, getState) => {
  dispatch({ type: UPDATE_PRIME_ATTRIBUEE, primeAttribuee });
  updatePrimeClassiqueCoutEntreprise(dispatch, getState);
  updatePrimeClassiqueSommePercueSalarie(dispatch, getState);
};

export const setTauxChargesPatronales = tauxChargesPatronales => (
  dispatch,
  getState
) => {
  if (TAUX_CHARGES_PATRONALES.indexOf(tauxChargesPatronales) !== -1) {
    dispatch({
      type: UPDATE_TAUX_CHARGES_PATRONALES,
      tauxChargesPatronales
    });
    updatePrimeClassiqueCoutEntreprise(dispatch, getState);
  }
};

export const setTauxChargesSalariales = tauxChargesSalariales => (
  dispatch,
  getState
) => {
  if (TAUX_CHARGES_SALARIALES.indexOf(tauxChargesSalariales) !== -1) {
    dispatch({
      type: UPDATE_TAUX_CHARGES_SALARIALES,
      tauxChargesSalariales
    });
    updatePrimeClassiqueSommePercueSalarie(dispatch, getState);
  }
};

export const setTauxMarginalDImposition = tauxMarginalDImposition => (
  dispatch,
  getState
) => {
  if (TAUX_MARGINAUX_DIMPOSITION.indexOf(tauxMarginalDImposition) !== -1) {
    dispatch({
      type: UPDATE_TAUX_MARGINAL_DIMPOSITION,
      tauxMarginalDImposition
    });
    updatePrimeClassiqueSommePercueSalarie(dispatch, getState);
  }
};

export const voirEcranSynthese = () => (dispatch, getState) => {
  dispatch({
    type: GET_ECRAN_SYNTHESE,
    ecran: {
      synthese: true,
      detailEntreprise: false,
      detailSalarie: false
    }
  });
};

export const voirEcranDetailEntreprise = () => (dispatch, getState) => {
  dispatch({
    type: GET_ECRAN_DETAIL_ENTREPRISE,
    ecran: {
      synthese: false,
      detailEntreprise: true,
      detailSalarie: false
    }
  });
};

export const voirEcranDetailSalarie = () => (dispatch, getState) => {
  dispatch({
    type: GET_ECRAN_DETAIL_SALARIE,
    ecran: {
      synthese: false,
      detailEntreprise: false,
      detailSalarie: true
    }
  });
};

export const setFaireBenefice = faireBenefice => (dispatch, getState) => {
  dispatch({
    type: UPDATE_FAIRE_BENEFICE,
    benefice: faireBenefice
  });
};

export const voirInformationPlafondInteressementAtteint = voir => (
  dispatch,
  getState
) => {
  dispatch({
    type: UPDATE_VOIR_INFO_PLAFOND_INT_ATTEINT,
    voirInformationPlafondInteressementAtteint: voir
  });
};

export const animerGraphiques = animer => (dispatch, getState) => {
  dispatch({
    type: UPDATE_ANIMER_GRAPHIQUES,
    animerGraphique: animer
  });
};

// --- ActionCreators privés, déclenchés par les actionCreators publiques ---
// --------------------------------------------------------------------------
const updateBudget = (dispatch, getState) => {
  const { masseSalariale, effectif } = getState().simulateur;
  dispatch({
    type: UPDATE_BUDGET,
    budget: budget(masseSalariale, effectif)
  });
  updateEpargneSalarialeDispositif(dispatch, getState);
};

const updateEpargneSalarialeDispositif = (
  dispatch,
  getState,
  participation
) => {
  const { masseSalariale, effectif, objectif } = getState().simulateur;

  const recommanderDispositif = objectif => {
    switch (objectif) {
      case OBJECTIF_FIDELISER:
        return recommandationFideliser(masseSalariale, effectif, participation);
      case OBJECTIF_MOTIVER:
        return recommandationMotiver(masseSalariale, effectif, participation);
      case OBJECTIF_CONSTITUER_CAPITALE:
        return recommandationCapitaliser(
          masseSalariale,
          effectif,
          participation
        );
      default:
        throw Error("Objectif non supporté : " + objectif);
    }
  };

  dispatch({
    type: UPDATE_EPARGNE_SALARIALE_DISPOSITIF,
    dispositifEpargneSalariale: recommanderDispositif(objectif)
  });

  updateEpargneSalarialeCoutEntreprise(dispatch, getState);
  updateEpargneSalarialeSommePercueSalarie(dispatch, getState);
  updatePrimeAttribuee(dispatch, getState);
};

const updateEpargneSalarialeCoutEntreprise = (dispatch, getState) => {
  let {
    epargneSalariale: { dispositif },
    effectif
  } = getState().simulateur;
  dispatch({
    type: UPDATE_EPARGNE_SALARIALE_COUT_ENTREPRISE,
    coutEntrepriseEpargneSalariale: coutEntrepriseEpargneSalariale(
      dispositif,
      effectif
    )
  });
};

const updateEpargneSalarialeSommePercueSalarie = (dispatch, getState) => {
  let enveloppeIndividuelle = getState().simulateur.epargneSalariale.dispositif
    .enveloppeIndividuelle;
  dispatch({
    type: UPDATE_EPARGNE_SALARIALE_SOMME_PERCUE_SALARIE,
    sommePercueSalarie: sommePercueSalarieEpargneSalariale(
      enveloppeIndividuelle
    )
  });
};

export const updatePrimeAttribuee = (dispatch, getState) => {
  const primeAttribuee = getState().simulateur.epargneSalariale.dispositif
    .enveloppeIndividuelle;
  dispatch({ type: UPDATE_PRIME_ATTRIBUEE, primeAttribuee });
  updatePrimeClassiqueCoutEntreprise(dispatch, getState);
  updatePrimeClassiqueSommePercueSalarie(dispatch, getState);
};

const updatePrimeClassiqueCoutEntreprise = (dispatch, getState) => {
  const {
    primeClassique: { primeAttribuee },
    taux: { tauxChargesPatronales }
  } = getState().simulateur;
  dispatch({
    type: UPDATE_PRIME_CLASSIQUE_COUT_ENTREPRISE,
    coutEntreprise: coutEntreprisePrimeClassique(
      primeAttribuee,
      tauxChargesPatronales
    )
  });
};

const updatePrimeClassiqueSommePercueSalarie = (dispatch, getState) => {
  const {
    primeClassique: { primeAttribuee },
    taux: { tauxChargesSalariales, tauxMarginalDImposition }
  } = getState().simulateur;
  dispatch({
    type: UPDATE_PRIME_CLASSIQUE_SOMME_PERCUE_SALARIE,
    sommePercueSalarie: sommePercueSalariePrimeClassique(
      primeAttribuee,
      tauxChargesSalariales,
      tauxMarginalDImposition
    )
  });
};

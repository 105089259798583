import { YesDropdown } from '../components/form/Dropdown';
import YesInput from '../components/form/Input';
import SelectCountries from '../components/form/SelectCountries';
import YesNoSelector from '../components/form/YesNoSelector';
import { AUTRES_FORMES_JURIDIQUES, FORMES_JURIDIQUES_ASSOCIATIVES } from '../services/FormeJuridiqueService';
import { BOOLEAN } from './constants';

export const nombre = (value) => {
  return parseInt(value, 10);
};

export const isAssociation = (formeJuridique) => FORMES_JURIDIQUES_ASSOCIATIVES.includes(+formeJuridique);

export const isAutreFormeJuridique = (formeJuridique) => AUTRES_FORMES_JURIDIQUES.includes(+formeJuridique);

export const stringToBoolean = (value) => (value === BOOLEAN.YES ? true : false);

export const booleanToString = (value) => (value ? BOOLEAN.YES : BOOLEAN.NO);

export const nombreAvecEspaces = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const siretAvecEspace = (x) => {
  return `${x.slice(0, 3)} ${x.slice(3, 6)} ${x.slice(6, 9)} ${x.slice(9)}`;
};

export const sirenAvecEspace = (x) => {
  return `${x.slice(0, 3)} ${x.slice(3, 6)} ${x.slice(6, 9)}`;
};

export const stringSansEspaces = (value) => {
  return value.replace(/ /g, '');
};

export const nombreSansEspaces = (value) => {
  return nombre(value.replace(/\D/g, ''));
};

export const nombreAvirgule = (value) => {
  return +value.replace(/\s/g, '').replace(',', '.');
};

export const dateFormatFrancais = (x) => {
  const date = x.replace(/[^\d]/g, '');
  return `${date.slice(6)}/${date.slice(4, 6)}/${date.slice(0, 4)}`;
};

export const getJoursMoisOfDate = (d) => {
  const parts = d && d.split('/');
  return parts && `${parts[0]}/${parts[1]}`;
};

export const dateFromString = (d) => {
  const dateParts = d.split('/');
  const dt = new Date();
  dt.setFullYear(nombre(dateParts[2]));
  dt.setMonth(nombre(dateParts[1]) - 1);
  dt.setDate(nombre(dateParts[0]));
  return dt;
};
export const setEffectDate = (accountingClosingDate) => {
  return dateToString(addDay(dateFromString(accountingClosingDate), 1));
};
export const addDay = (d, nb) => {
  const date = new Date(d.getTime());
  date.setDate(date.getDate() + nb);
  return date;
};

export const addMonth = (d, nb) => {
  const date = new Date(d.getTime());
  date.setMonth(date.getMonth() + nb);
  return date;
};

export const addYear = (d, nb) => {
  const date = new Date(d.getTime());
  date.setYear(date.getFullYear() + nb);
  return date;
};

export const dateDuJourToString = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  return dd + '/' + mm + '/' + yyyy;
};

export const tomorrowToString = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return dateToString(tomorrow);
};

export const dateToString = (date) => {
  function pad(s) {
    return s < 10 ? '0' + s : s;
  }

  const d = new Date(date.getTime());
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
};

export const roundAmount = (amount) => {
  return Math.round(amount * 100) / 100;
};

export const frenchDecimalNumber = (value) => roundAmount(value).toLocaleString('fr');

export const construireChaineParametresFormulaire = (arr) =>
  Object.entries(arr)
    .reduce((res, [key, value]) => [...res, `${encodeURIComponent(key)}=${encodeURIComponent(value)}`], [])
    .join('&');

export const cloneValues = (src) => Object.assign({}, src);

export const retirerPourcent = (value) => value.substring(0, value.length - 2);

export const retirerEuro = (value) => value.substring(0, value.length - 2);

export const hasSpecialsCharacters = (text) => {
  const regex = RegExp('^.*[<>"/`%&^].*$');
  return regex.test(text);
};

export const minutes = (value) => value * 1000 * 60;

export const secondes = (value) => value * 1000;

/**
 * Construit un objet YesInput pour alimenter un composant Field
 *
 * @param {string} name
 * @param {string} label
 * @param {string} type
 */
export const fieldInput = (name, label, type) => ({
  name,
  label,
  type,
  component: YesInput
});

/**
 * Construit un objet DropDown pour alimenter un composant Field
 *
 * @param {string} name
 * @param {string} label
 * @param {Array} item
 */
export const fieldDropDown = (name, label, item) => ({
  name,
  label,
  item,
  component: YesDropdown
});

/**
 * Construit un objet SelectCountries pour alimenter un composant Field
 *
 * @param {string} name
 * @param {string} label
 */
export const fieldCountries = (name, label) => ({
  name,
  label,
  component: SelectCountries
});

/**
 * Construit un objet SelectCountries pour alimenter un composant Field
 *
 * @param {string} name
 * @param {string} label
 */
export const fieldYesNoSelector = (fieldName, label) => ({
  fieldName,
  label,
  component: YesNoSelector
});

/**
 * Créer un objet permettant le paramètrage d'un composant Field
 * @param {*} validators
 * @param {*} maxLength
 * @param {*} mask
 * @param {*} pattern
 * @param {*} placeholder
 * @param {*} normalize
 * @param {*} notDisplayed
 * @returns
 */
export const fieldBehavior = (validators, maxLength, mask, pattern, placeholder, normalize, notDisplayed) => ({
  validators,
  maxLength,
  mask,
  pattern,
  placeholder,
  normalize,
  notDisplayed
});

/**
 * Détaille un comportement de composant Field
 *
 * @param {Object} fieldBaseObject objet type pour formeer un composant Field
 * @param {Object} fieldBehaviorObject objet d'attributs définissant le comportement du composant Field
 */
export const fieldContent = (fieldBaseObject, fieldBehaviorObject) => ({
  ...fieldBaseObject,
  ...fieldBehaviorObject
});

// export const passPourcent = (value) => ((value * 100) / PASS).toFixed(2);

// export function isIn6FirstMonthes(date, today = new Date()) {
//   const lower = new Date(date >= today ? today : date);
//   const higher = new Date(date >= today ? date : today);
//   const d = lower.getDate();
//   lower.setMonth(lower.getMonth() + +6);
//   if (lower.getDate() !== d) {
//     lower.setDate(0);
//   }
//   return lower <= higher;
// }

export function isIn6FirstMonths(date, today = new Date()) {
  if(date > today) {
    return new Date(today.setMonth(today.getMonth() + 6)) < date;
  } else {
    return new Date(today.setMonth(today.getMonth() + 6)) < new Date(new Date(date).setFullYear(date.getFullYear() + 1));
  }
}

export function getExerciceEnd(stringDate, today = new Date()) {
  const initialDate = dateFromString(`${stringDate}/${new Date().getFullYear()}`);
  return today > initialDate ? new Date(dateFromString(`${stringDate}/${new Date().getFullYear() + 1}`)) : initialDate;
}

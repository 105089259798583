/* Built-in imports */
import React from "react";

/* Third-party imports */
import PropTypes from "prop-types";
import { Field } from "redux-form";

/* Internal imports */
import YesInput from "../../form/Input";
import { Bic, Required } from '../../../common/Validators';
import { MOYENS_PAIEMENT } from '../../../utils/constantes/US/MOYENSPAIEMENT';

const TarificationBic = () => (
  <Field
    component={YesInput}
    type="text"
    name={MOYENS_PAIEMENT.BIC}
    labeltext={MOYENS_PAIEMENT.BIC}
    validate={[Required, Bic]}
  />
);

TarificationBic.propTypes = {
  customError: PropTypes.string
};

export default TarificationBic;
/* Internal imports */
import CITRA from '../../../../../api/citra';
import { getDocumentsAUploader } from '../../../../../services/TenantService';

const FILESKEYS = {
  PASSPORT: 'passport',
  DIRECTORIDENTITY: 'directorIdentity',
  POWERDELEGATION: 'powerDelegation',
  ENTERPRISELEGALESTATUS: 'enterpriseLegalStatus',
  SPECIALCASES: 'specialCases',
  TAXRETURN: 'taxReturn',
  KBIS: 'kbis',
  KYC: 'kyc'
};

const getFormData = (file) => {
  const serializedFiles = new FormData();
  serializedFiles.append('files', file, file.name);
  return serializedFiles;
};

const getFile = (file) => {
  return file?.length > 0 ? { name: file[0] } : null;
};

const setCurrentFile = (files, setFiles, index, key, isLoading, networkError, networkErrorMessage) => {
  files[key][index] = {
    ...files[key][index],
    isLoading,
    networkErrorMessage,
    networkError
  };

  setFiles({
    ...files,
    [key]: files[key]
  });
};

const uploadFile = function(url, file) {
  return CITRA().post(url, getFormData(file));
};

const deleteFile = function(url, fileName) {
  return CITRA().delete(`${url}?nom=${encodeURIComponent(fileName)}`);
};

const sendFolder = function (url) {
  return CITRA().post(url);
};

const isDirectorIdentityDocuments = function(passport, identityJustificatif) {
  return !!passport[0].file || (!!identityJustificatif[0].file && !!identityJustificatif[1].file);
};

const getListeDocuments = function(planExistant, folderType) {
  return getDocumentsAUploader(planExistant, folderType);
};

const isNoRequired = function(files) {
  return(
    isDirectorIdentityDocuments(files[FILESKEYS.PASSPORT], files[FILESKEYS.DIRECTORIDENTITY])
    && (files[FILESKEYS.ENTERPRISELEGALESTATUS] ? !!files[FILESKEYS.ENTERPRISELEGALESTATUS][0]?.file : true)
    && (files[FILESKEYS.KBIS] ? !!files[FILESKEYS.KBIS][0]?.file : true)
    && (files[FILESKEYS.TAXRETURN] ? !!files[FILESKEYS.TAXRETURN][0]?.file : true)
    && (files[FILESKEYS.KYC] ? !!files[FILESKEYS.KYC][0]?.file: true)
  );
};

export default {
  FILESKEYS,
  getFormData,
  setCurrentFile,
  uploadFile,
  deleteFile,
  isDirectorIdentityDocuments,
  sendFolder,
  isNoRequired,
  getFile,
  getListeDocuments
};

import {TAUX_CATEOGORY} from "../../../../constantes/US/TAUX_CATEGORIES";
import {COLLEGE_CATEGORIES} from "../../../../constantes/US/COLLEGE_CATEGORIES";

export function isStringEmpty(college) {
  return !(typeof college == "string" && college.length > 0);
}

export const validatorTaux = (tauxCategory, tauxPMSS, tauxTranche1, tauxTranche2) =>
    !(isStringEmpty(tauxCategory)
    || tauxCategory === TAUX_CATEOGORY.TAUX_TRANCHES && (isStringEmpty(tauxTranche1) || isStringEmpty(tauxTranche2))
    || tauxCategory === TAUX_CATEOGORY.TAUX_PMSS && isStringEmpty(tauxPMSS))

export const validatorCollege = (collegeCategory, collegeOption) =>
    (!isStringEmpty(collegeCategory) && !isStringEmpty(collegeOption))
    || collegeCategory === COLLEGE_CATEGORIES.ENTIRE_STAFF;
/* Built in imports */
import React, { useEffect } from 'react';

/* Third party imports */
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { formValueSelector, getFormSyncErrors, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

/* Internal imports */
import MaroquinerieAbondementPEI from './MaroquinerieAbondementPEI';
import { TransHtmlText } from '../../../../../yes/utils/TransHtmlText';
import { YesButton } from '../../../../../form/Button';
import { nbMaxPaliers } from '../../../../../../services/TauxService';
import {dateDuJourToString, nombre, nombreSansEspaces} from '../../../../../../common/FunctionsUtils';
import { plafondAbondementPEE } from '../../../../../../modules/simulateur/fonctions';

const MaroquinerieAbondementForm = ({
                                      handleSubmit,
                                      pristine,
                                      invalid,
                                      submitting,
                                      change,
                                      tunnel,
                                      palierAbondementsPei,
                                      ouverturePei,
                                      miseEnPlaceAbondementPei,
                                      modeAnciennete,
                                      plafondPei,
                                      genericErrors,
                                      PASS
                                    }) => {

  useEffect(() => {
    change('ouverturePei', false);
    change(`palierAbondementsPei[${0}].taux`, 1);
    change(`palierAbondementsPei[${0}].versementMin`, 0);
    change('anciennete.pei.taux', 0.5);
    change('miseEnPlaceAbondementPei', false);
    change('anciennete.pei.plafond1an', 1);

    //RG Maroquinerie
    change(`palierAbondementsPei[${0}].versementMax`, 3291);
    change('plafondPei', 3291);
    change('ancienneteMinimum', 3);
    change('periodicite.option', 1);
    change('periodicite.trimestre', null);
    change('sourcesVersementsPei.INTERESSEMENT', true);
    change('dateEffet', dateDuJourToString());
  }, []);

  useEffect(() => {
    // On set les valeurs persistées s'ils elles existent
    if (tunnel.dossier.dispositifs) {
      const dispositifs = tunnel.dossier.dispositifs;
      if (dispositifs.dispositifPei.ouverture !== ouverturePei) {
        change('ouverturePei', dispositifs.dispositifPei.ouverture);

        if (dispositifs.dispositifPei.abondement) {
          change('miseEnPlaceAbondementPei', true);
          if (
            dispositifs.dispositifPei.abondement.palierAbondements &&
            dispositifs.dispositifPei.abondement.palierAbondements.length >= 1
          ) {
            change('palierAbondementsPei', dispositifs.dispositifPei.abondement.palierAbondements);
          }
          if (dispositifs.dispositifPei.abondement.palierAncienneteAbondements) {
            change('anciennete.pei.taux', dispositifs.dispositifPei.abondement.palierAncienneteAbondements.taux);
            change('anciennete.pei.taux', dispositifs.dispositifPei.abondement.palierAncienneteAbondements.taux);
            change(
              'anciennete.pei.plafond1an',
              dispositifs.dispositifPei.abondement.palierAncienneteAbondements.plafond1an
            );
            change(
              'anciennete.pei.plafond3an',
              dispositifs.dispositifPei.abondement.palierAncienneteAbondements.plafond3an
            );
            change(
              'anciennete.pei.plafond5an',
              dispositifs.dispositifPei.abondement.palierAncienneteAbondements.plafond5an
            );
            change(
              'anciennete.pei.plafondMax',
              dispositifs.dispositifPei.abondement.palierAncienneteAbondements.plafondMax
            );
          }

          if (dispositifs.dispositifPei.abondement.plafondPass) {
            change('plafondPei', plafondAbondementPEE());
          } else {
            change(
              'plafondPei',
              calculerAbondementMaxDesPaliers(dispositifs.dispositifPei.abondement.palierAbondements)
            );
          }
        }
      }
      if (dispositifs.modalites?.dateEffet){
        change('dateEffet', dispositifs.modalites.dateEffet);
      }
    }
  }, [tunnel]);

  const desactiverMiseEnPlaceAbondementPei = (event) => {
    if (event.target.value) {
      change('miseEnPlaceAbondementPei', false);
    }
  };

  /***
   * On update la valeur totale de l'abondement seulement s'il a plus d'un palier
   * @param event
   * @param index
   */
  const changerTauxPei = (event, index) => {
    if (palierAbondementsPei.length > 1) {
      let paliers = palierAbondementsPei;
      if (event.target.validity.valid) {
        let tauxPei = event.target.value;
        if (event.target.value === '') {
          tauxPei = 1;
        }
        paliers[index].taux = tauxPei;
        change(`palierAbondementsPei[${index}].taux`, tauxPei);

        changerValeurSliderPlafondPei(calculerAbondementMaxDesPaliers(paliers));
      }
    }
  };

  const changerValeurMaxPalier = (event, index, type) => {
    // assume type === Pei
    const field = `palierAbondements${type}`;
    const paliers = [field];
    if (event.target.validity.valid) {
      let value = nombre(nombreSansEspaces(event.target.value));
      if (event.target.value === '') {
        value = 0;
      }
      paliers[index].versementMax = value;

      change(`${field}[${index}].versementMax`, value);

      if (index < nbMaxPaliers - 1 && paliers[index + 1]) {
        change(`${field}[${index + 1}].versementMin`, value);
        paliers[index + 1].versementMin = value;
      }
      this[`changerValeurSliderPlafond${type}`](calculerAbondementMaxDesPaliers(paliers));
    }
  };

  // TODO: replace by paliers.reduce
  const calculerAbondementMaxDesPaliers = (paliers) => {
    let totalAbondementMax = 0;
    if (paliers && paliers.length === 1) {
      return paliers[0].versementMax;
    }
    for (let index in paliers) {
      let palier = paliers[index];
      if (palier.versementMax > 0) {
        totalAbondementMax += (palier.versementMax - palier.versementMin) * palier.taux;
      }
    }
    return totalAbondementMax;
  };

  const changerValeurSliderPlafondPei = (value) => {
    change('plafondPei', +value);
    if (palierAbondementsPei && palierAbondementsPei.length === 1) {
      change(`palierAbondementsPei[${0}].versementMax`, value);
    }
  };

  const changerDateEffet = (value) => {
    change('dateEffet', value);
  };


  //On vérifie pour chaque ligne que le taux est inférieur
  //à celui de la ligne précédente
  const checkTauxDegressif = (array) => {
    if (array && array.length > 1) {
      let taux = array[0].taux;
      for (let i = 1; i < array.length; i++) {
        if (taux > array[i].taux) {
          taux = array[i].taux;
        } else {
          return true;
        }
      }
    }
    return undefined;
  };

  const getPalierAbondementMaxPei = (ligne) => {
    if (palierAbondementsPei[ligne] && palierAbondementsPei[ligne].versementMax) {
      const differencePalier =
        palierAbondementsPei[ligne].versementMax - palierAbondementsPei[ligne].versementMin;
      return `soit ${differencePalier * palierAbondementsPei[ligne].taux}€ d'abond. max`;
    } else {
      return `soit XXX€ d'abond. max`;
    }
  };

  const checkPlafondPei = () => calculerAbondementMaxDesPaliers(palierAbondementsPei) > plafondAbondementPEE();

  //recupération du min via le nom parce que pas de paramètre dans la fonction de validation
  const checkMaxPalierSuperieurAMin = (value, allValues, props, name) => {
    const splittedName = name.split('[');
    const index = splittedName[1][0];
    return (parseInt([splittedName[0]][index].versementMin) >= parseInt(value));
  };

  const getPeiUnchecked = () => {
    return !(ouverturePei);
  };

  const classDisableOuverturePei = !ouverturePei ? 'disablePartieTunnel' : '';
  const classDisablePei = !miseEnPlaceAbondementPei ? 'disablePartieTunnel' : '';

  const palierPeiActive = !!(palierAbondementsPei && palierAbondementsPei.length > 1);

  return (
    <form className="formDispositifAbondement" onSubmit={handleSubmit}>
      <div className="titreFormulaire">
        <Trans i18nKey="dispositif.titre" />
      </div>
      <div className="anciennete-mini-selector">
        <div className="label-checkbox-v2 anciennete-mini-selector">
          <TransHtmlText i18nKey="maroquinerie.dispositif.info.abondement.anciennete" />
        </div>
      </div>
      <br />
      <div className="grid-margin-x alignPeiPercoiColumn">
        <MaroquinerieAbondementPEI
          config
          classDisablePei={classDisablePei}
          palierPeiActive={palierPeiActive}
          plafondPei={plafondPei}
          changerTauxPei={changerTauxPei}
          checkPlafondPei={checkPlafondPei}
          modeAnciennete={modeAnciennete}
          checkTauxDegressif={checkTauxDegressif}
          classDisableOuverturePei={classDisableOuverturePei}
          changerValeurMaxPalier={changerValeurMaxPalier}
          palierAbondementsPei={palierAbondementsPei}
          getPalierAbondementMaxPei={getPalierAbondementMaxPei}
          miseEnPlaceAbondementPei={miseEnPlaceAbondementPei}
          checkMaxPalierSuperieurAMin={checkMaxPalierSuperieurAMin}
          desactiverMiseEnPlaceAbondementPei={desactiverMiseEnPlaceAbondementPei}
          changerDateEffet={changerDateEffet}
          PASS={PASS}
        />
      </div>
      {getPeiUnchecked() && (
        <p className="errorMsgTauxDegressif" style={{marginTop: '20px'}}>Vous devez sélectionner au moins un dispositif.</p>
      )}
      <div className="buttonCreationContainer">
        <YesButton
          text="Confirmer"
          disabled={
            invalid ||
            submitting ||
            pristine ||
            getPeiUnchecked() ||
            (genericErrors &&
              (genericErrors.plafondPei ||
                genericErrors.periodicite))
          }
        />
      </div>
    </form>
  );

}

MaroquinerieAbondementForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  change: PropTypes.func.isRequired,
  tunnel: PropTypes.object.isRequired,
  palierAbondementsPei: PropTypes.array,
  ouverturePei: PropTypes.bool,
  miseEnPlaceAbondementPei: PropTypes.bool,
  modeAnciennete: PropTypes.func.isRequired,
  plafondPei: PropTypes.number,
  genericErrors: PropTypes.object,
  PASS: PropTypes.number
}

// REDUX + REDUX FORM
const ReduxMaroquinerieAbondementForm = reduxForm({
  form: 'dispositifAbondementForm',
  destroyOnUnmount: false
})(MaroquinerieAbondementForm);

const selectDispositifAbondementForm = formValueSelector('dispositifAbondementForm');

const mapStateToProps = (state) => ({
  miseEnPlaceAbondementPei: selectDispositifAbondementForm(state, 'miseEnPlaceAbondementPei'),
  palierAbondementsPei: selectDispositifAbondementForm(state, 'palierAbondementsPei'),
  anciennete: selectDispositifAbondementForm(state, 'anciennete'),
  ouverturePei: selectDispositifAbondementForm(state, 'ouverturePei'),
  plafondPei: selectDispositifAbondementForm(state, 'plafondPei'),
  abnUnilateral: selectDispositifAbondementForm(state, 'abnUnilateral'),
  plafondAbnUnilateral: selectDispositifAbondementForm(state, 'plafondAbnUnilateral'),
  sourcesVersementsPei: selectDispositifAbondementForm(state, 'sourcesVersementsPei'),
  genericErrors: getFormSyncErrors('dispositifAbondementForm')(state),
  tunnel: state.tunnel,
  PASS: state.citraData?.pass?.currentPass
});

export default connect(mapStateToProps)(ReduxMaroquinerieAbondementForm);

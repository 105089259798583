import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Required, Email, Password, SpecialChar, requiredValidator } from '../../../../common/Validators';
import YesInput from '../../../form/Input';
import { YesInputPassword } from '../../../form/Password';
import { YesButton } from '../../../form/Button';
import { Link } from 'react-router-dom';
import config from '../../../../common/StaticSiteConfig';
import { UTILISATEUR_EXISTE_DEJA } from '../../../../common/ApiError';
import { setEmailDejaExistant } from '../../../../modules/tunnel/redux/tunnelActions';
import { connect } from 'react-redux';
import { YesCheckboxV2 } from '../../../form/CheckboxV2';

class CreationCompteForm extends Component {
  constructor(props) {
    super(props);
    this.isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  }

  componentDidMount() {
    if (this.props.tunnel.emailDejaExistant) {
      this.props.change('adresseEmail', this.props.tunnel.emailDejaExistant);
      this.props.setEmailDejaExistant(null);
    }
  }

  render() {
    const { handleSubmit, pristine, invalid, submitting, customError, disableClick } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <label className="titreFormulaire">Créer mon compte</label>
        <div className="grid-x grid-margin-x">
          <div
            className={
              this.props.errorCode === UTILISATEUR_EXISTE_DEJA ? 'cell medium-12 hideCustomErrorInputForCompte' : 'cell medium-12'
            }
          >
            {this.isChrome && (
              <div className="fakeInputChrome">
                <input type="password" name="fake" />
              </div>
            )}
            <Field
              component={YesInput}
              type="email"
              name="adresseEmail"
              labeltext="Adresse e-mail"
              validate={[Required, Email]}
              customError={customError}
            />
          </div>
          {this.props.errorCode === UTILISATEUR_EXISTE_DEJA && (
            <span className="customErrorMsgCompte customErrorMsg errorMsgInput">
              {customError}{' '}
              <Link to="/connexion">
                <span>Essayez de vous connecter</span>
              </Link>
            </span>
          )}
          <div className="cell medium-12">
            <Field
              component={YesInputPassword}
              type="password"
              name="motDePasse"
              labeltext="Mot de passe"
              indication="true"
              passwordForgotten="false"
              validate={[Required, Password, SpecialChar]}
            />
          </div>

          <div className="cell medium-12">
            <Field
              component={YesCheckboxV2}
              type="checkbox"
              name="conditionsUtilisation"
              id="conditionsUtilisation"
              validate={requiredValidator}
            />
            <label htmlFor="conditionsUtilisation" className="label-checkbox custom-label-politique-confid">
              J’accepte la{' '}
              <a target="_blank" rel="noopener noreferrer" href={config.mentionsLegalesUrl}>
                Politique de confidentialité{' '}
              </a>
              et les{' '}
              <a target="_blank" rel="noopener noreferrer" href={config.infosReglementairesUrl}>
                Conditions d’utilisation
              </a>{' '}
              de Yomoni.
            </label>
          </div>
        </div>

        <div className="buttonCreationContainer">
          <div className="buttonCreationContent">
            <YesButton text="C'est parti !" disabled={invalid || submitting || pristine || disableClick} />

            <p className="connectezVous">
              Vous avez déjà un compte ? <Link to="/connexion">Connectez-vous</Link>
            </p>
          </div>
        </div>
      </form>
    );
  }
}

CreationCompteForm = reduxForm({
  form: 'creationCompte'
})(CreationCompteForm);

const mapDispatchToProps = (dispatch) => ({
  setEmailDejaExistant: (email) => dispatch(setEmailDejaExistant(email))
});

CreationCompteForm = connect(
  (state) => {
    const tunnel = state.tunnel;
    return { tunnel };
  },
  mapDispatchToProps
)(CreationCompteForm);

export default CreationCompteForm;

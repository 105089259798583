import { CONTRACTCATEGORIES } from '../../../../constantes/US/WORKADVICESSHEETSMODAL';

export function isEmployeesSubscriptionErrors( isAllEmployeesSubscribe, contractSubscriptionInfos ) {

  const isSubscriptionFormCorrect = () => {
    let atLeastOneFieldCorrect = false;
    let atLeastOneFieldCheckedButEmpty = false;

    for (const category in CONTRACTCATEGORIES){
      const employeeCategory = CONTRACTCATEGORIES[category];
      for (const key in contractSubscriptionInfos[employeeCategory]){
        if(contractSubscriptionInfos[employeeCategory][key].checked && contractSubscriptionInfos[employeeCategory][key].employeesCount === 0){
          atLeastOneFieldCheckedButEmpty = true;
        }
        if(contractSubscriptionInfos[employeeCategory][key].checked && contractSubscriptionInfos[employeeCategory][key].employeesCount > 0){
          atLeastOneFieldCorrect = true;
        }
      }
    }
    return atLeastOneFieldCorrect && !atLeastOneFieldCheckedButEmpty;
  }

  return isAllEmployeesSubscribe ? false : !isSubscriptionFormCorrect();
}
import { makeStyles } from '@mui/styles';

export default makeStyles({
  elementFlash:  {
    animationDuration: '1.5s',
    animationName: '$flash',
    animationIterationCount: 'infinite',
    transition: 'none'
  },
  '@keyframes flash': {
    '0%':  { opacity: 1 },
    '40%':  { opacity: 0 },
    '100%': { opacity: 1 }
  }
});

/* Built in imports */
import React from 'react';

/* Third party imports */
import smoothscroll from 'smoothscroll-polyfill';
import { Route, Switch } from 'react-router-dom';

/* Internal imports */
import '../../../texts/MessageConfig';
import AccesApporteurPage from '../../../pages/public_pages/AccesApporteurPage';
import AppRoute from '../../../helpers/routes/AppRoute';
import Home from '../home/Home';
import MaroquineriePrivateRoutes from '../../../routes/private-routes/epargne/maroquinerie/MaroquineriePrivateRoutes';
import Page404 from '../../../components/yes/utils/Page404';
import PrivateRoutesAssurance from '../../../routes/private-routes/assurance/default/PrivateRoutesAssurance';
import PrivateRoutesEpargne from '../../../routes/private-routes/epargne/default/PrivateRoutesEpargne';
import PublicRoutesDefault from '../../../routes/public-routes/PublicRoutesDefault';
import StaticSiteLayout from '../../../layouts/default/StaticSiteLayout';
import { ACTIVITY_DOMAINS, FOLDER_TYPES } from '../../../utils/constantes/ROUTES/ROUTES';
import { getAttribute, refreshToken } from '../../../services/ResourcesService';
import { getDossierByUidEntreprise } from "../../../services/DossierService";
import {
  FREQUENCE_RAFRAICHISSEMENT_TOKEN,
  ROUTE_ACCES_CONTRIBUTEUR,
  ROUTE_HOME,
  ROUTE_VERSION_VIEWER
} from '../../../common/Configuration';

// kick off the polyfill!
smoothscroll.polyfill();

class App extends React.Component {
  constructor(props) {
    super(props);
    require('../../../scss/app/tenants/' + process.env.REACT_APP_ID + '/' + process.env.REACT_APP_ID + '-app.scss');
    this.getDomainFromDossier = this.getDomainFromDossier.bind(this);
    this.updateAppActivityDomainAndFolderType = this.updateAppActivityDomainAndFolderType.bind(this);
    this.state = {
      isLoading: true,
      activityDomain: ACTIVITY_DOMAINS.DEFAULT.NAME,
      folderType: FOLDER_TYPES.EPARGNE
    };
  }

  getDomainFromDossier() {
    const entrepriseUid = getAttribute('entrepriseUid');
    // Le token JWT contient un id de dossier => On récupère le domain d'activité à partir du dossier
    if (entrepriseUid) {
      getDossierByUidEntreprise(entrepriseUid).then((dossiers) => {
        const { dossier } = dossiers[0];
        this.updateAppActivityDomainAndFolderType(
          dossier.activityDomain || ACTIVITY_DOMAINS.DEFAULT.NAME,
          FOLDER_TYPES[dossier.type]
          );
      })
    }
  }

  updateAppActivityDomainAndFolderType( domain, type ) {
    this.setState({ activityDomain: domain, folderType: type, isLoading: false });
  }


  componentDidMount() {
    if (window.location.pathname !== ROUTE_VERSION_VIEWER) {
      this.getDomainFromDossier();
    }
  }

  componentWillMount() {
    // First rafraichisssement : on rafraichi le token
    refreshToken();
    // Rafraichissement du token JWT toutes les 14 min 30 ensuite
    setInterval(function() {
      refreshToken();
    }, FREQUENCE_RAFRAICHISSEMENT_TOKEN);
  }

  render() {
    //Au premier accès à l'app, il faut passer par AccesApporteurPage pour récupérer le token / les infos du dossier
    if (this.state.isLoading) {
      return (
        <Switch>
          <AppRoute
            exact
            path={ROUTE_ACCES_CONTRIBUTEUR}
            component={(props) =>
              <AccesApporteurPage
                updateAppActivityDomainAndFolderType={this.updateAppActivityDomainAndFolderType}
                {...props}
              />
            }
          />
          <AppRoute exact path={ROUTE_HOME} layout={StaticSiteLayout} component={Home} />
          {PublicRoutesDefault}
        </Switch>
      )
    }

    return (
      <Switch>
        {/* ROUTES PUBLIQUES */}
        {PublicRoutesDefault}

        {/* ROUTES PRIVEES */}
        {PrivateRoutesEpargne(this.state.activityDomain, this.state.folderType)}
        {PrivateRoutesAssurance(this.state.activityDomain, this.state.folderType)}
        {MaroquineriePrivateRoutes(this.state.activityDomain, this.state.folderType)}

        {/* ROUTE POUR LES ROUTES NON TROUVEES, ATTENTION, CETTE ROUTE DOIT ETRE LA DERNIERE */}
        <Route component={Page404} />
      </Switch>
    );
  }
}

export default App;

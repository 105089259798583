import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import {
  Required,
  Email,
  PasswordSixCaracteres
} from "../../../../common/Validators";
import YesInput from "../../../form/Input";
import { YesInputPassword } from "../../../form/Password";
import { YesButton } from "../../../form/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setEmailDejaExistant } from "../../../../modules/tunnel/redux/tunnelActions";
import { COMBINAISON_UTILISATEUR_MOTDEPASSE_INCORRECTE } from "../../../../common/ApiError";

class ConnexionForm extends Component {
  constructor(props) {
    super(props);
    this.isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  }

  componentDidMount() {
    if (this.props.tunnel.emailDejaExistant) {
      this.props.change("adresseEmail", this.props.tunnel.emailDejaExistant);
      this.props.setEmailDejaExistant(null);
    }
  }

  render() {
    const {
      handleSubmit,
      pristine,
      invalid,
      submitting,
      customError
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="grid-x grid-margin-x">
          <div
            className={
              this.props.errorCode ===
              COMBINAISON_UTILISATEUR_MOTDEPASSE_INCORRECTE
                ? "cell medium-12 hideCustomErrorInputForCompte"
                : "cell medium-12"
            }
          >
            {this.isChrome && (
              <div className="fakeInputChrome">
                <input type="password" name="fake" />
              </div>
            )}
            <Field
              component={YesInput}
              type="email"
              name="adresseEmail"
              labeltext="Adresse e-mail"
              validate={[Required, Email]}
              customError={customError}
            />
          </div>
          <div className="cell medium-12">
            <Field
              component={YesInputPassword}
              type="password"
              name="motDePasse"
              labeltext="Mot de passe"
              validate={[Required, PasswordSixCaracteres]}
              indication="false"
              passwordForgotten="true"
              customError={customError}
            />
          </div>
        </div>
        <div className="buttonCreationContainer">
          <YesButton
            text="Se connecter"
            disabled={invalid || submitting || pristine}
          />

          <p className="connectezVous">
            Pas encore de compte ?{" "}
            <Link to="/creationCompte">Inscrivez-vous</Link>
          </p>
        </div>
      </form>
    );
  }
}

ConnexionForm = reduxForm({
  form: "connexionCompte"
})(ConnexionForm);

const mapDispatchToProps = dispatch => ({
  setEmailDejaExistant: email => dispatch(setEmailDejaExistant(email))
});

ConnexionForm = connect(
  state => {
    const tunnel = state.tunnel;
    return { tunnel };
  },
  mapDispatchToProps
)(ConnexionForm);

export default ConnexionForm;

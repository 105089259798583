import { fetch } from "./ResourcesService";
import { PREFIX_BACK } from "../common/Configuration";

export const creerLiasseSouscription = valuesAenvoye => {
  return fetch(true, PREFIX_BACK + "liassesSouscription", {
    method: "POST",
    body: JSON.stringify(valuesAenvoye)
  });
};

export const recupererUrlSignature = (uidLiasse, redirectUrl) => {
  return fetch(
    true,
    PREFIX_BACK +
      "liassesSouscription/" +
      encodeURIComponent(uidLiasse) +
      "/url?redirectUrl=" +
      encodeURIComponent(redirectUrl),
    {
      method: "GET"
    }
  );
};

export const annulerLiasseSouscription = uidLiasse => {
  return fetch(true, PREFIX_BACK + "liassesSouscription/" + uidLiasse, {
    method: "DELETE"
  });
};

/* Built in imports */
import React, { useEffect, useState } from 'react';

/* Third Party imports */
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import MaroquinerieAbondementForm from '../../../../components/US/epargne_salariale/maroquinerie/form/Abondement/MaroquinerieAbondementForm';
import { URLS_MAROQUINERIE } from '../../../../utils/constantes/ROUTES/MAROQUINERIE';
import { isSourcesVersementAbondeesEnabled } from '../../../../services/TenantService';
import { mettreAjourAbondements } from '../../../../services/DossierService';
import { sourcesVersementsPei, sourcesVersementsPercoi } from '../../../../services/SourcesVersementsService';
import {
  plafondAbondementPEE,
  plafondAbondementPERCO,
  plafondAbondementUnilateral
} from '../../../../modules/simulateur/fonctions';
import {
  setAbondements, setAbondementUnilateral,
  setInformationsUtilisateur,
  setRetourRoute
} from '../../../../modules/tunnel/redux/tunnelActions';

const TYPE_ABONDEMENT = {
  ANCIENNETE: 'anciennete',
  VERSEMENT: 'versement'
};

const MaroquinerieDispositifAbondementPage = ({
                                                tunnel,
                                                setRetourRoute,
                                                setAbondements,
                                                setAbondementUnilateral
                                              }) => {

  const [dispositifAnciennete, setDispositifAnciennete] = useState(
    {
      pei:
        tunnel.dossier.dispositifs.dispositifPei.abondement &&
        tunnel.dossier.dispositifs.dispositifPei.abondement.typePalier === TYPE_ABONDEMENT.ANCIENNETE,
      percol:
        tunnel.dossier.dispositifs.dispositifPercoi.abondement &&
        tunnel.dossier.dispositifs.dispositifPercoi.abondement.typePalier === TYPE_ABONDEMENT.ANCIENNETE
    }
  );
  
  const [redirect, setRedirect] = useState(false);
  
  useEffect(() => {
    setRetourRoute(URLS_MAROQUINERIE.DISPOSITIF_ACTUEL.substring(1));
  }, [])
  
  const submit = (values) => {
    const dispositifs = {
      dispositifPei: {
        ouverture: values.ouverturePei,
        abondement: values.miseEnPlaceAbondementPei
          ? {
            palierAbondements:
              !dispositifAnciennete.pei && values.palierAbondementsPei.length >= 1
                ? mapPaliersAsNumber(values.palierAbondementsPei)
                : null,
            palierAncienneteAbondements:
              dispositifAnciennete.pei && values.anciennete.pei
                ? mapAncienneteAsNumber(values.anciennete.pei)
                : null,
            plafondPass: values.plafondPei >= plafondAbondementPEE(),
            sourceVersements: getSourcesVersementsPei(values.sourcesVersementsPei),
            typePalier: dispositifAnciennete.pei ? TYPE_ABONDEMENT.ANCIENNETE : TYPE_ABONDEMENT.VERSEMENT
          }
          : null
      },
      dispositifPercoi: {
        ouverture: values.ouverturePercoi,
        abondement: values.miseEnPlaceAbondementPercoi
          ? {
            plafondPass: values.plafondPercoi >= plafondAbondementPERCO(),
            palierAbondements:
              !dispositifAnciennete.percol && values.palierAbondementsPercoi.length >= 1
                ? mapPaliersAsNumber(values.palierAbondementsPercoi)
                : null,
            palierAncienneteAbondements:
              dispositifAnciennete.percol && values.anciennete.percol
                ? mapAncienneteAsNumber(values.anciennete.percol)
                : null,
            sourceVersements: getSourcesVersementsPercoi(values.sourcesVersementsPercoi),
            typePalier: dispositifAnciennete.percol ? TYPE_ABONDEMENT.ANCIENNETE : TYPE_ABONDEMENT.VERSEMENT
          }
          : null,
        abondementUnilateral: {
          miseEnPlace: values.abnUnilateral,
          plafond: values.plafondAbnUnilateral,
          plafondPass: values.plafondAbnUnilateral >= plafondAbondementUnilateral()
        }
      },
      anciennete: +values.ancienneteMinimum,
      periodicite: {
        option: +values.periodicite.option,
        trimestre: values.periodicite.option === '2' ? +values.periodicite.trimestre : null
      },
      modalites: {
          dateEffet: values.dateEffet
      }
    };

    // Si plus de percoi, on delete l'abondement unilateral dans redux
    if (!dispositifs.dispositifPercoi) {
      setAbondementUnilateral(null);
    }

    mettreAjourAbondements(tunnel.dossier.uid, { dispositifs })
      .then((dossier) => {
        setAbondements(dossier.dispositifs);
        setRedirect(true);
      })
      .catch((error) => {
        if (error && error.message) {
          error.log("error: ", error.message);
        }
      });
  }

  const getSourcesVersementsPei = (sources) => {
    if (isSourcesVersementAbondeesEnabled()) {
      return filtrerSources(sources);
    }
    return sourcesVersementsPei.map((source) => source.name);
  };

  const getSourcesVersementsPercoi = (sources) => {
    if (isSourcesVersementAbondeesEnabled()) {
      return filtrerSources(sources);
    }
    return sourcesVersementsPercoi.map((source) => source.name);
  };

  const filtrerSources = (sources) => {
    const sourcesFiltrees = [];
    for (const source in sources) {
      if (sources[source]) {
        sourcesFiltrees.push(source);
      }
    }
    return sourcesFiltrees;
  };

  const modeAnciennete = (type, bool) => {
    setDispositifAnciennete({ ...dispositifAnciennete, [type]: bool})
  }

  const mapPaliersAsNumber = (arr) =>
    arr.map((elt) => ({
      taux: +elt.taux,
      versementMin: +elt.versementMin,
      versementMax: +elt.versementMax
    }));

  const mapAncienneteAsNumber = (obj) => ({
    taux: +obj.taux,
    plafond1an: +obj.plafond1an,
    plafond3an: +obj.plafond3an,
    plafond5an: +obj.plafond5an,
    plafondMax: +obj.plafondMax
  });

  if (redirect) {
    return <Redirect to={URLS_MAROQUINERIE.INTERESSEMENT} />;
  }

  return (
    <div>
      <MaroquinerieAbondementForm
        onSubmit={submit}
        modeAnciennete={modeAnciennete}
        dispositifAnciennete={dispositifAnciennete}
      />
    </div>
  );

}

MaroquinerieDispositifAbondementPage.propTypes = {
  tunnel: PropTypes.object.isRequired,
  setRetourRoute: PropTypes.func.isRequired,
  setAbondements: PropTypes.func.isRequired,
  setAbondementUnilateral: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setInformationsUtilisateur: (utilisateur) => dispatch(setInformationsUtilisateur(utilisateur)),
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setAbondements: (dispositifs) => dispatch(setAbondements(dispositifs)),
  setAbondementUnilateral: (abondementUnilateral) => dispatch(setAbondementUnilateral(abondementUnilateral))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaroquinerieDispositifAbondementPage);

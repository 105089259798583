/* Built in imports */
import React, { useEffect, useState } from 'react';

/* Third Party Imports */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

/* Internal imports */
import EntrepriseForm from '../../../../components/US/epargne_salariale/forms/EntrepriseForm';
import { URLS_MAROQUINERIE } from '../../../../utils/constantes/ROUTES/MAROQUINERIE';
import { enregistrerEntreprise, mettreAjourEntreprise } from '../../../../services/EntrepriseService';
import { getEpargneEntrepriseFormSubmit } from '../../../../utils/US/components/epargne_salariale/forms/entrepriseUtils';
import { setToken } from '../../../../services/ResourcesService';
import {
  setInformationsEntreprise, setInformationsSirene, setNouvelleEntreprise,
  setRetourRoute
} from '../../../../modules/tunnel/redux/tunnelActions';


const MaroquinerieEntreprisePage = ({
                                      setRetourRoute,
                                      setInformationsEntreprise,
                                      setInformationsSirene,
                                      setNouvelleEntreprise,
                                      tunnel
                                    }) => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setRetourRoute('none');
  },[])

  const submit = (values) => {
    const typeDossier = tunnel.dossier.type;
    const uidUtilisateur = tunnel.utilisateur.uid;

    // S'il y a un dossier, on va mettre à jour les INFOS ENTREPRISE
    if (tunnel.dossier) {
      mettreAjourEntreprise(
        tunnel.entreprise.uid,
        getEpargneEntrepriseFormSubmit(values, typeDossier, uidUtilisateur)
      ).then((entrepriseAjour) => {
        // on sauvegarde dans Redux les informations de l'entreprise
        setInformationsEntreprise(entrepriseAjour);
        setRedirect(true);
      });

      // Pas de dossier ? on va créer l'entreprise
    } else {
      enregistrerEntreprise(getEpargneEntrepriseFormSubmit(values, typeDossier, uidUtilisateur))
        .then((ajoutEntreprise) => {
          // on sauvegarde dans Redux les informations de l'entreprise
          setNouvelleEntreprise(ajoutEntreprise.dossierAvecEntreprise);

          // Puis on met à jour le jeton JWT
          setToken(ajoutEntreprise.jwt);

          setRedirect(true);
        })
        .catch((error) => {
          if (error && error.message) {
            error.log(error.message);
          }
        });
    }
    setInformationsSirene(undefined);
  }

  if (redirect) {
    return <Redirect to={ URLS_MAROQUINERIE.ENTREPRISE_SALARIES} />;
  }

  return(
    <div>
      <EntrepriseForm onSubmit={submit} />
    </div>
  );

}

const mapStateToProps = ({ tunnel }) => ({
  tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setInformationsEntreprise: (entreprise) => dispatch(setInformationsEntreprise(entreprise)),
  setInformationsSirene: (sireneInfos) => dispatch(setInformationsSirene(sireneInfos)),
  setNouvelleEntreprise: (infos) => dispatch(setNouvelleEntreprise(infos))
});

MaroquinerieEntreprisePage.propTypes = {
  tunnel: PropTypes.object.isRequired,
  setRetourRoute: PropTypes.func.isRequired,
  setNouvelleEntreprise: PropTypes.func.isRequired,
  setInformationsSirene: PropTypes.func.isRequired,
  setInformationsEntreprise: PropTypes.func.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaroquinerieEntreprisePage);

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { YesChoix } from './Choix';
import { stringToBoolean } from '../../common/FunctionsUtils';
import { BOOLEAN } from '../../common/constants';

const YesNoSelector = ({ label, fieldValue, fieldName, children, customClass }) => (
  <div className={`grid-x grid-margin-x grid-margin-y checkBox${customClass ? ` ${customClass}` : ''}`}>
    <div className="cell medium-8 labelYesNo">{`${label}`}</div>
    <div className="cell medium-4 grid-x text-field  ">
      <div className="cell medium-6">
        <Field id={`${fieldName}-oui`} name={fieldName} component={YesChoix} type="radio" value={BOOLEAN.YES} label="Oui" />
      </div>
      <div className="cell medium-6">
        <Field id={`${fieldName}-non`} name={fieldName} component={YesChoix} type="radio" value={BOOLEAN.NO} label="Non" />
      </div>
    </div>
    {stringToBoolean(fieldValue) && <div className="cell">{children}</div>}
  </div>
);

YesNoSelector.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  fieldValue: PropTypes.string,
  children: PropTypes.object
};

export default YesNoSelector;

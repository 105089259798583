const CONSTANTES = {
    ICONMARGIN: '0px 5px',
    BORDERRADIUS: '4px'
};

const defaultIcons = {
    margin: CONSTANTES.ICONMARGIN,
    color: 'var(--cn-color-primary)'
};

const defaultIconsActive = {
    ...defaultIcons,
    color: 'var(--cn-color-valid)'
};

const card = {
    default: {
        borderColor: 'var(--cn-color-primary)',
        backgroundColor: 'var(--cn-color-white)',
        height: '100%'
    },
    active: {
        borderColor: 'var(--cn-color-valid)',
        backgroundColor: 'var(--cn-color-valid-20)',
        height: '100%'
    },
    required: {
        borderColor: 'var(--cn-color-primary)',
        height: '100%'
    }
};

const cardContentContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'flex-wrap',
    marginTop: '1rem',
    '&::first-of-type': {
        marginBottom: 0
    }
};

const cardContentInputContainer = {
    position: 'relative',
    backgroundColor: 'var(--cn-color-grey-100)',
    borderRadius: CONSTANTES.BORDERRADIUS,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'flex-wrap',
    alignItems: 'center',
    flexGrow: 1,
    minHeight: '47px',
    marginBottom: 0
};

const loaderContainer = {
    ...cardContentInputContainer,
    justifyContent: 'center'
};

const cardContentTypography = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexGrow: 1
};

const buttonBaseInputMask = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
};

const addContainer = {
    border: '1px solid',
    borderRadius: CONSTANTES.BORDERRADIUS,
    ...card.default,
    backgroundColor: 'var(--cn-color-grey-100)',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem'
};

const addButton = {
    width: '100%',
    height: '100%',
    borderRadius: 0
};

const addIcon = {
    ...defaultIcons,
    margin: '0'
};

const deleteIcon = {
    ...defaultIcons,
    padding: 0,
    margin: 0,
    width: '47px'
};

const labelTypo = {
  'span > .label-bold-secondary': {
      fontWeight: 'bold',
      color: 'var(--cn-color-secondary)'
  }
}

const utils = {
    getTypographyMaxWidth(isFile, deleteIconButtonElemWidth, optional) {
        return (isFile || optional) && deleteIconButtonElemWidth ? `calc(100% - ${deleteIconButtonElemWidth}px)` : '100%';
    }
};

export default {
    CONSTANTES,
    addButton,
    addContainer,
    addIcon,
    buttonBaseInputMask,
    card,
    cardContentContainer,
    cardContentInputContainer,
    cardContentTypography,
    defaultIcons,
    defaultIconsActive,
    deleteIcon,
    loaderContainer,
    labelTypo,
    utils
};

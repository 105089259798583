export const ADD_NOTIFICATION = "notification/ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "notification/REMOVE_NOTIFICATION";

let id = 0;

export const addNotification = message => (dispatch, getState) => {
  dispatch({
    type: ADD_NOTIFICATION,
    id: id++,
    message
  });
  setTimeout(() => {
    dispatch({
      type: REMOVE_NOTIFICATION,
      id
    });
  }, 4000);
};

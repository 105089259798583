import React from "react";
import onClickOutside from "react-onclickoutside";

const linkAccountClass = "topbar-menu__link-account";

class MyAccountButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      linkAccountClass
    };
  }

  handleToggleMenuAccount = () => {
    const switchedMenuAccount =
      this.state.linkAccountClass === linkAccountClass
        ? "topbar-menu__link-account--open"
        : linkAccountClass;

    this.setState({
      linkAccountClass: switchedMenuAccount
    });
  };

  // Utilisé par onClickOutside
  handleClickOutside = () => {
    const switchedMenuAccount =
      this.state.linkAccountClass === linkAccountClass
        ? "topbar-menu__link-account"
        : linkAccountClass;

    this.setState({
      linkAccountClass: switchedMenuAccount
    });
  };

  render() {
    const { linkAccountClass } = this.state;
    return (
      <div className="my-account-button">
        <button
          className={`topbar-menu__link-account ${linkAccountClass}`}
          onClick={this.handleToggleMenuAccount}
        >
          Mon espace personnel
        </button>
        <ul
          className="topbar-menu__account-access"
          id="js-topbar-menu__account-access"
        >
          <li>
            <a
              href="https://www.epargne-entreprise.federal-finance.fr"
              target="_blank"
              rel="noopener noreferrer"
              data-ga-event="entreprise_navbar"
            >
              Je suis une entreprise
            </a>
          </li>
          <li>
            <a
              href="https://www.epargne-salariale.federal-finance.fr"
              target="_blank"
              rel="noopener noreferrer"
              data-ga-event="salarie_navbar"
            >
              Je suis un salarié
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default onClickOutside(MyAccountButton);

import React from "react";
import { Translation } from "react-i18next";

export function TransHtmlText({ i18nKey, params }) {
  return (
    <Translation>
      {t => <span dangerouslySetInnerHTML={{ __html: t(i18nKey, params) }} />}
    </Translation>
  );
}

import WorkAdvicesSheetsModel from '../../models/WorkAdvicesSheetsModel';
import WORKADVICESSHEETSACTIONS from '../../utils/constantes/REDUCERS/WORKADVICESSHEETSACTIONS';

export default function workAdvicesSheetsReducer(state = null, { type, ...data }) {
  switch (type) {
    case WORKADVICESSHEETSACTIONS.UPDATESTART:
      return {
        ...state,
        loading: true
      };

    case WORKADVICESSHEETSACTIONS.UPDATESUCCESS:
      return {
        ...setWorkAdvicesSheets(data),
        loading: false
      };

    case WORKADVICESSHEETSACTIONS.DELETESUCCESS:
      return null;

    case WORKADVICESSHEETSACTIONS.UPDATEERROR:
      console.log("Error sending form (work Advices sheets) : ", data.error);
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}

function setWorkAdvicesSheets({ workAdvicesSheets }) {
  return new WorkAdvicesSheetsModel(workAdvicesSheets);
}
import { CONTRACTCATEGORIES, LEGALOBLIGATIONS, REGIMENTS } from '../../../constantes/US/WORKADVICESSHEETSMODAL';

export function getInitialState(
  {
    isFirstWorkAdvicesSheets,
    isRetirementContract,
    isContractor,
    isEmployeesRegiment,
    isSignatory,
    isAllEmployeesSubscribe,
    contractCategorySelected,
    contractorInfos,
    regimentInfos,
    subscriptionReasons,
    legalObligationSelected,
    particularObservations,
    particularObservationsPensionGuarantees
  }) {
  return {
    isFirstWorkAdvicesSheets: isFirstWorkAdvicesSheets ? isFirstWorkAdvicesSheets.checked : true,
    workAdvicesContract: {
      isRetirementContract: isRetirementContract ? isRetirementContract.checked : false,
      retirementContractOptions: {
        isRetirementCollectivePerco: isRetirementContract ? isRetirementContract.values.isRetirementCollectivePerco.checked : false,
        isRetirementCollectiveRequiredPerco: isRetirementContract ? isRetirementContract.values.isRetirementCollectiveRequiredPerco.checked : false,
        isRetirementCollectivePercol: isRetirementContract ? isRetirementContract.values.isRetirementCollectivePercol.checked : false,
        isRetirementArt83: isRetirementContract ? isRetirementContract.values.isRetirementArt83.checked : false,
        isRetirementArt39: isRetirementContract ? isRetirementContract.values.isRetirementArt39.checked : false,
        otherContract: isRetirementContract ? isRetirementContract.values.other.value : '',
      },
      subscriptionReasons: {
        isPerobToPacte: subscriptionReasons ? subscriptionReasons.isPerobToPacte.checked : false,
        isCapitalToSavings: subscriptionReasons ? subscriptionReasons.isCapitalToSavings.checked : false,
        isPaymentsVoluntaryPunctual: subscriptionReasons ? subscriptionReasons.isPaymentsVoluntaryPunctual.checked : false,
        isPaymentsSavings: subscriptionReasons ? subscriptionReasons.isPaymentsSavings.checked : false,
        isInvestments: subscriptionReasons ? subscriptionReasons.isInvestments.checked : false,
        isManagementChoice: subscriptionReasons ? subscriptionReasons.isManagementChoice.checked : false,
      },
    },
    employeesInfos: {
      isContractor: isContractor ? isContractor.checked : true,
      isEmployeesRegiment: isEmployeesRegiment ? isEmployeesRegiment.checked : true,
      isSignatory: isSignatory ? isSignatory.checked : false,
      isAllEmployeesSubscribe: isAllEmployeesSubscribe ? isAllEmployeesSubscribe.checked : true,
      contractCategorySelected: contractCategorySelected ? getContractCategorySelectedValue(contractCategorySelected) : '',
      contractSubscriptionInfos: {
        cadre: {
          art21: {
            checked:  contractCategorySelected ? contractCategorySelected.cadre.values.art21.checked : false,
            employeesCount: contractCategorySelected ? contractCategorySelected.cadre.values.art21.employeesCount : 0,
          },
          art2122: {
            checked: contractCategorySelected ? contractCategorySelected.cadre.values.art2122.checked : false,
            employeesCount: contractCategorySelected ? contractCategorySelected.cadre.values.art2122.employeesCount : 0,
          },
          art2122Br: {
            checked: contractCategorySelected ? contractCategorySelected.cadre.values.art2122Br.checked : false,
            employeesCount: contractCategorySelected ? contractCategorySelected.cadre.values.art2122Br.employeesCount : 0,
          }
        },
        noCadre: {
          art21: {
            checked:  contractCategorySelected ? contractCategorySelected.noCadre.values.art21.checked : false,
            employeesCount: contractCategorySelected ? contractCategorySelected.noCadre.values.art21.employeesCount : 0,
          },
          art2122: {
            checked: contractCategorySelected ? contractCategorySelected.noCadre.values.art2122.checked : false,
            employeesCount: contractCategorySelected ? contractCategorySelected.noCadre.values.art2122.employeesCount : 0,
          },
          art2122Br: {
            checked: contractCategorySelected ? contractCategorySelected.noCadre.values.art2122Br.checked : false,
            employeesCount: contractCategorySelected ? contractCategorySelected.noCadre.values.art2122Br.employeesCount : 0,
          }
        },
      },
      contractorInfos: {
        firstname: contractorInfos ? contractorInfos.firstname : '',
        lastname: contractorInfos ? contractorInfos.lastname : '',
        job: contractorInfos ? contractorInfos.job : '',
      },
      regimentInfos: {
        regimentSelected: regimentInfos ? getRegimentSelectedValue(regimentInfos.regimentSelected) : REGIMENTS.DUES,
        employeesAverageAge: regimentInfos ? regimentInfos.employeesAverageAge.value : '',
        employeesCount: regimentInfos ? regimentInfos.employeesCount.value : 0,
        iddc: regimentInfos ? regimentInfos.iddc.value : '',
        conventionName: regimentInfos ? regimentInfos.conventionName.value : '',
      },
      employeesAgeDistribution: {
        ageCount0to10: 0,
        ageCount11to15: 0,
        ageCount16to20: 0,
        ageCount21to30: 0,
        ageCount30More: 0,
      },
    },
    observationsInfos: {
      legalObligationSelected: legalObligationSelected ? getLegalObligationsSelectedValue(legalObligationSelected) : LEGALOBLIGATIONS.LEGALOBLIGATIONS,
      particularObservations: particularObservations ? particularObservations.value : '',
      particularObservationsPensionGuarantees: particularObservationsPensionGuarantees ? particularObservationsPensionGuarantees.value : '',
    }
  }
}
function getContractCategorySelectedValue({ contractCategorySelected }) {
  if (contractCategorySelected === CONTRACTCATEGORIES.CADRE) {
    return CONTRACTCATEGORIES.CADRE;
  }
  if (contractCategorySelected === CONTRACTCATEGORIES.NOCADRE) {
    return CONTRACTCATEGORIES.NOCADRE;
  }

  return '';
}

function getRegimentSelectedValue({ dues }) {
  if (dues.checked) {
    return REGIMENTS.DUES;
  }

  return '';
}

function getLegalObligationsSelectedValue({ beyondLegalObligations, legalObligations }) {
  if (beyondLegalObligations.checked) {
    return LEGALOBLIGATIONS.BEYONDLEGALOBLIGATIONS;
  } else if (legalObligations.checked) {
    return LEGALOBLIGATIONS.LEGALOBLIGATIONS;
  }

  return '';
}
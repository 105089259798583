import React, { Component } from "react";
import DemandeChangementMotDePasseForm from "./DemandeChangementMotDePasseForm";
import { connect } from "react-redux";
import { demandeChangementMotDePasse } from "../../../../services/CompteService";
import { setLoader } from "../../../../modules/tunnel/redux/tunnelActions";
import { getTenantUppercase } from "../../../../services/TenantService";

class DemandeChangementMotDePassePage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      redirect: false,
      formMode: true
    };
    this.props.handlerIllustration("illu_mdp-oublie");
  }

  submit(values) {
    let valuesWithTenantId = {
      ...values,
      tenant: getTenantUppercase()
    };

    this.props.setLoader(true);

    demandeChangementMotDePasse({ values: valuesWithTenantId })
      .then(() => {
        this.setState({
          formMode: false,
          adresseMail: values.adresseEmail
        });
      })
      .then(() => this.props.setLoader(false));
  }

  render() {
    const { formMode, adresseMail } = this.state;

    return (
      <div className="grid-x grid-margin-x">
        <div className="cell medium-12 formMdpOublie">
          {formMode && (
            <div>
              <h2>Cela arrive même aux meilleurs !</h2>

              <p>
                Pour réinitialiser votre mot de passe, entrez l'adresse e-mail
                que vous avez utilisée pour vous connecter à votre compte .
              </p>

              <label className="titreFormulaire">
                Quelle est l’adresse e-mail de votre compte ?
              </label>

              <DemandeChangementMotDePasseForm onSubmit={this.submit} />
            </div>
          )}

          {!formMode && (
            <div>
              <h2>Regardez dans votre boîte mail</h2>

              <p className="mpdOublieMailEnvoye">
                Un e-mail a été envoyé à {adresseMail}.
              </p>
              <br />
              <p>
                En cliquant sur ce lien vous arriverez sur une nouvelle page où
                vous pourrez choisir votre nouveau mot de passe.
              </p>
              <br />
              <p>
                Une fois ce nouveau mot de passe validé, vous serez connecté(e)
                à votre compte et pourrez poursuivre votre souscription.
              </p>
              <br />
              <p>
                Si vous ne recevez pas ces instructions, veuillez nous contacter
                à{" "}
                <a href="mailto:epargnesalariale@yomoni.fr">
                  epargnesalariale@yomoni.fr
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  setLoader: actif => dispatch(setLoader(actif))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DemandeChangementMotDePassePage);

import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';

const InputLabelRoot = styled(InputLabel, { name: 'InputLabel' })(() => ({
  fontFamily: 'var(--cn-font-family-source-pro-sans-serif)',
  '&.Mui-focused': {
    color: 'var(--cn-color-primary)',
  }
}))

export default InputLabelRoot
/* built-in imports */
import React from 'react';

/* third-party imports */
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';

/* internal imports */
import FormControlLabel from '../../../DS/inputs/form/FormControlLabel';
import FormLabel from '../../../DS/inputs/form/FormLabel';
import InputLabel from '../../../DS/inputs/InputLabel';
import MenuItem from '../../../DS/inputs/MenuItem';
import Radio from '../../../DS/inputs/Radio';
import Select from '../../../DS/inputs/Select';
import TextField from '../../../DS/inputs/TextField';
import TooltipInfo from '../../../../utils/US/components/TooltipInfo';
import Typography from '../../../DS/data-display/Typography';
import { YesButton } from '../../../form/Button';
import { MAXWIDTH } from '../../../../utils/constantes/DS/TOOLTIP';
import { TAUX_CATEOGORY } from '../../../../utils/constantes/US/TAUX_CATEGORIES';
import { getCurrentPassFromStore } from '../../../../utils/passUtils';
import { COLLEGE_CATEGORIES, COLLEGE_OPTIONS } from '../../../../utils/constantes/US/COLLEGE_CATEGORIES';

//TODO: A supprimer lors de la refonte graphique => utiliser un DS
const CssTextField = withStyles({
  root: {
    marginTop: '1.2em',
    '& .MuiInputLabel-root': {
      letterSpacing: '0.25px',
      fontSize: '0.7rem'
    },
    '& .MuiInputLabel-shrink': {
      top: '-1.4em',
      fontSize: '0.8rem',
      color: 'var(--cn-color-primary)'
    },
    '& .MuiInputBase-input': {
      color: 'var(--cn-typo-in-filled-input)',
      paddingTop: '0.5em'
    },
    '& .MuiInput-underline': {
      borderBottomColor: 'var(--cn-color-primary)',
      '&:after': {
        borderBottomColor: 'var(--cn-color-primary)'
      }
    },
    '& .MuiFilledInput-root': {
      backgroundColor: 'var(--cn-ges-input-bg-color)',
      padding: '0.55rem',
      height: '2.5rem',
      textAlign: 'center'
    }
  }
})(TextField);

const CollegeForm = (props) => {
  const {
    submit,
    handleCollegeChange,
    collegeCategory,
    collegeOption,
    handleCollegeOptionChange,
    tauxCategory,
    handleTauxCategoryChange,
    tauxTranche1,
    handleTauxTranche1Change,
    tauxTranche2,
    handleTauxTranche2Change,
    tauxPMSS,
    handleTauxPMSSChange,
    submitDisabled
  } = props;

  const tradKey = 'categories-salaries.form';
  const { t } = useTranslation();
  const passValue = getCurrentPassFromStore();
  const pmssValue = passValue / 12;
  const tradCategoryKey = `${tradKey}.collegeOption.category`;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <form onSubmit={submit} className="CollegeForm">
        <Grid container spacing={2}>
          <Grid item>
            <label className="titreFormulaire">{t(`${tradKey}.title`)}</label>
          </Grid>

          <Grid item xs={12}>
            <Typography>{t(`${tradKey}.college`)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="filled" sx={{ marginTop: '1.5rem', width: '100%' }}>
              <InputLabel id="collegeLabel">{t(`${tradKey}.college.select.label`)}</InputLabel>
              <Select
                labelId="collegeLabel"
                name="CollegeForm-collegeCategory"
                value={collegeCategory ?? ''}
                onChange={(event) => handleCollegeChange(event.target.value)}
                sx={{
                  width: '100%',
                  color: 'var(--cn-typo-in-filled-input)',
                  backgroundColor: 'var(--cn-ges-input-bg-color)'
                }}
              >
                <MenuItem value={COLLEGE_CATEGORIES.ENTIRE_STAFF}>{t(`${tradKey}.college.select.option1`)}</MenuItem>
                <MenuItem value={COLLEGE_CATEGORIES.EMPLOYEE_CATEGORIES}>{t(`${tradKey}.college.select.option2`)}</MenuItem>
                <MenuItem value={COLLEGE_CATEGORIES.EMPLOYEE_SEUIL}>{t(`${tradKey}.college.select.option4`)}</MenuItem>
                <MenuItem value={COLLEGE_CATEGORIES.OTHER}>{t(`${tradKey}.college.select.option3`)}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {collegeCategory === COLLEGE_CATEGORIES.EMPLOYEE_CATEGORIES && (
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <FormControl sx={{ paddingTop: '1em' }}>
                  <FormLabel id="employee-categories-label">{t(`${tradKey}.college.category.label`)}</FormLabel>
                  <RadioGroup
                    aria-labelledby="employee-categories-label"
                    name="employee-categories-radio-buttons-group"
                    value={collegeOption}
                    onChange={(event) => handleCollegeOptionChange(event.target.value)}
                  >
                    {Object.values(COLLEGE_OPTIONS.EMPLOYEE_CATEGORIES).map((key, index) => (
                      <FormControlLabel
                        key={`${key}-${index}`}
                        name={key}
                        value={key}
                        control={<Radio />}
                        label={t(`${tradCategoryKey}.${key}`)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}

          {collegeCategory === COLLEGE_CATEGORIES.EMPLOYEE_SEUIL && (
            <Grid item container>
              <Grid item xs={12}>
                <FormControl sx={{ paddingTop: '1em' }}>
                  <FormLabel id="employee-seuil-label">{t(`${tradKey}.collegeOption.seuil.label`)}</FormLabel>
                  <RadioGroup
                    aria-labelledby="employee-seuil-label"
                    name="employee-seuil-radio-buttons-group"
                    value={collegeOption}
                    onChange={(event) => handleCollegeOptionChange(event.target.value)}
                  >
                    {COLLEGE_OPTIONS.EMPLOYEE_SEUIL.map((seuil, index) => {
                      return (
                        <div key={`${index}-seuil`} style={{ display: 'flex', alignItems: 'baseline' }}>
                          <FormControlLabel
                            name={seuil.NOM}
                            value={seuil.NOM}
                            control={<Radio />}
                            label={`${(seuil.FACTEUR * passValue).toLocaleString('fr-FR')} € (${seuil.FACTEUR} PASS)`}
                          />
                          {index === COLLEGE_OPTIONS.EMPLOYEE_SEUIL.length - 1 && (
                            <TooltipInfo
                              placement="right"
                              width={MAXWIDTH.SMALL}
                              tooltipContent={<Typography info>{t(`${tradKey}.collegeOption.seuil.tooltip`)}</Typography>}
                            />
                          )}
                        </div>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}

          {collegeCategory === COLLEGE_CATEGORIES.OTHER && (
            <Grid item xs={12}>
              <CssTextField
                sx={{ display: 'flex', textAlign: 'left' }}
                name="CollegeForm-college"
                label={t(`${tradKey}.collegeOption.input.label`)}
                variant="filled"
                inputProps={{
                  maxLength: 500
                }}
                value={collegeOption}
                onChange={(event) => handleCollegeOptionChange(event.target.value)}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography>{t(`${tradKey}.tauxCotisation`)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="filled" sx={{ marginTop: '1.5rem', width: '100%' }}>
              <InputLabel id="tauxCategoryLabel">{t(`${tradKey}.tauxCotisation.select.label`)}</InputLabel>
              <Select
                labelId="tauxCategorySelectLabel"
                name="CollegeForm-tauxCategory"
                value={tauxCategory}
                onChange={(event) => handleTauxCategoryChange(event.target.value)}
                sx={{
                  width: '100%',
                  color: 'var(--cn-typo-in-filled-input)',
                  backgroundColor: 'var(--cn-ges-input-bg-color)'
                }}
              >
                <MenuItem value={TAUX_CATEOGORY.TAUX_TRANCHES}>{t(`${tradKey}.tauxCotisation.select.option1`)}</MenuItem>
                <MenuItem value={TAUX_CATEOGORY.TAUX_PMSS}>{t(`${tradKey}.tauxCotisation.select.option2`)}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {tauxCategory === TAUX_CATEOGORY.TAUX_TRANCHES && (
            <Grid item container spacing={2} >
              <Grid item xs={12} md={6}>
                <CssTextField
                  sx={{ display: 'flex', textAlign: 'left' }}
                  name="CollegeForm-tauxTranche1"
                  label={t(`${tradKey}.tauxCotisation.input.tranche1`)}
                  variant="filled"
                  inputProps={{
                    maxLength: 3
                  }}
                  value={tauxTranche1}
                  onChange={(event) => handleTauxTranche1Change(event.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CssTextField
                  sx={{ display: 'flex', textAlign: 'left' }}
                  name="CollegeForm-tauxTranche2"
                  label={t(`${tradKey}.tauxCotisation.input.tranche2`)}
                  variant="filled"
                  inputProps={{
                    maxLength: 3
                  }}
                  value={tauxTranche2}
                  onChange={(event) => handleTauxTranche2Change(event.target.value)}
                />
              </Grid>
            </Grid>
          )}

          {tauxCategory === TAUX_CATEOGORY.TAUX_PMSS && (
            <Grid item>
              <CssTextField
                name="CollegeForm-tauxPMSS"
                label={t(`${tradKey}.tauxCotisation.input.pmss`) + ` (${pmssValue}€)`}
                variant="filled"
                inputProps={{
                  maxLength: 3
                }}
                value={tauxPMSS}
                onChange={(event) => handleTauxPMSSChange(event.target.value)}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <div className="buttonCreationContainer">
              <YesButton text={t(`button.confirm`)} disabled={submitDisabled} />
            </div>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

const mapStateToProps = ({ tunnel }) => ({ tunnel });

export default connect(mapStateToProps)(CollegeForm);

import React from 'react';

/* third-party imports */
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';

/* Internal imports */
import ContractorSubForm from './work-advices-sheets-employees/ContractorSubForm';
import EmployeesSubForm from './work-advices-sheets-employees/EmployeesSubForm';
import EmployeesSubscriptionSubForm from './work-advices-sheets-employees/EmployeesSubscriptionSubForm';

const WorkAdvicesSheetsEmployeesForm = function(props) {
  const {
    isContractor,
    isSignatory,
    contractorInfos,
    handleIsContractorChange,
    handleContractorInfosChange,
    isEmployeesRegiment,
    regimentInfos,
    isAllEmployeesSubscribe,
    contractCategorySelected,
    contractSubscriptionInfos,
    handleIsEmployeesRetirementChange,
    handleRegimentInfosChange,
    handleEmployeesInfosErrors,
    handleContractSubscriptionCadreChange,
    handleContractSubscriptionNoCadreChange,
    handleIsAllEmployeesSubscribe,
    handleIsSignatoryChange,
    handleOtherContractChange,
    handleContractCategorySelectedChange,
    showErrors,
    isEmployeesSubscriptionErrors
    } = props;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Box>
        <ContractorSubForm
          isContractor={isContractor}
          contractorInfos={contractorInfos}
          handleIsContractorChange={handleIsContractorChange}
          handleContractorInfosChange={handleContractorInfosChange}
          showErrors={showErrors}
          handleIsContractorInfosErrorChange={(value) => handleEmployeesInfosErrors(value, 'isContractorInfosError')}
        />
      </Box>
      <Divider sx={{
        marginTop: '2em',
        marginBottom: '1em',
        borderColor: 'var(--cn-color-primary)'
      }}/>
      <Box sx={{ paddingTop: '1em' }}>
        <EmployeesSubForm
          isEmployeesRegiment={isEmployeesRegiment}
          regimentInfos={regimentInfos}
          showErrors={showErrors}
          handleIsEmployeesRetirementChange={handleIsEmployeesRetirementChange}
          handleRegimentInfosChange={handleRegimentInfosChange}
        />
      </Box>
      <Divider sx={{
        marginTop: '2em',
        marginBottom: '1em',
        borderColor: 'var(--cn-color-primary)'
      }}/>
      <Box sx={{ paddingTop: '1em' }}>
        <EmployeesSubscriptionSubForm
          isAllEmployeesSubscribe={isAllEmployeesSubscribe}
          contractCategorySelected={contractCategorySelected}
          contractSubscriptionInfos={contractSubscriptionInfos}
          isSignatory={isSignatory}
          showErrors={showErrors}
          handleContractSubscriptionCadreChange={handleContractSubscriptionCadreChange}
          handleContractSubscriptionNoCadreChange={handleContractSubscriptionNoCadreChange}
          handleIsAllEmployeesSubscribe={handleIsAllEmployeesSubscribe}
          handleIsSignatoryChange={handleIsSignatoryChange}
          handleOtherContractChange={handleOtherContractChange}
          handleContractCategorySelectedChange={handleContractCategorySelectedChange}
          isEmployeesSubscriptionErrors={isEmployeesSubscriptionErrors}
        />
      </Box>
    </Box>
  );
}

WorkAdvicesSheetsEmployeesForm.propTypes = {
  isContractor: PropTypes.bool.isRequired,
  isSignatory: PropTypes.bool.isRequired,
  contractorInfos: PropTypes.object.isRequired,
  handleIsContractorChange: PropTypes.func.isRequired,
  handleContractorInfosChange: PropTypes.func.isRequired,
  isEmployeesRegiment: PropTypes.bool.isRequired,
  regimentInfos: PropTypes.object.isRequired,
  handleIsEmployeesRetirementChange: PropTypes.func.isRequired,
  handleRegimentInfosChange: PropTypes.func.isRequired,
  handleEmployeesInfosErrors: PropTypes.func.isRequired,
  isAllEmployeesSubscribe: PropTypes.bool.isRequired,
  contractCategorySelected: PropTypes.string.isRequired,
  contractSubscriptionInfos: PropTypes.object.isRequired,
  handleContractSubscriptionCadreChange: PropTypes.func.isRequired,
  handleContractSubscriptionNoCadreChange: PropTypes.func.isRequired,
  handleIsAllEmployeesSubscribe: PropTypes.func.isRequired,
  handleIsSignatoryChange: PropTypes.func.isRequired,
  handleOtherContractChange: PropTypes.func.isRequired,
  handleContractCategorySelectedChange: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
  isEmployeesSubscriptionErrors: PropTypes.func.isRequired
}

export default WorkAdvicesSheetsEmployeesForm

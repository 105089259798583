/* Built in imports */
import React from 'react';

/* Third party import */
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Field, formValueSelector, reduxForm } from 'redux-form';

/* Internal imports */
import EtablissementEtranger from './EtablissementEtranger';
import TiersPayeur from './TiersPayeur';
import config from '../../../../../common/StaticSiteConfig';
import { BOOLEAN, COUNTRY } from '../../../../../common/constants';
import { MOYENS_PAIEMENT } from '../../../../../utils/constantes/US/MOYENSPAIEMENT';
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';
import { YesButton } from '../../../../form/Button';
import { YesChoix } from '../../../../form/Choix';
import { booleanToString, stringToBoolean } from '../../../../../common/FunctionsUtils';

const validate = (values) => {
  const errors = {};
  if (!values[MOYENS_PAIEMENT.NATURE_PAYEUR]) {
    errors[MOYENS_PAIEMENT.NATURE_PAYEUR] = 'Vous devez selectionner la nature du tiers payeur';
  }
  return errors;
};

class MoyensPaiementForm extends React.Component {
  componentDidMount() {
    const { change, moyensPaiement} = this.props;
    if (moyensPaiement) {
      const { nom, prenom, lien, motif, debitCompteSouscripteur, nature, adresse, etablissementHorsFrance } = moyensPaiement;
      const foreignBank = etablissementHorsFrance && Object.keys(etablissementHorsFrance).length > 0;

      change(MOYENS_PAIEMENT.ETABLISSEMENT_HORS_FRANCE, booleanToString(foreignBank));
      change(MOYENS_PAIEMENT.IS_COMPTE_SOUSCRIPTEUR, booleanToString(debitCompteSouscripteur));

      if (!debitCompteSouscripteur) {
        change(MOYENS_PAIEMENT.NOM, nom || '');
        change(MOYENS_PAIEMENT.LIEN, lien || '');
        change(MOYENS_PAIEMENT.PRENOM, prenom || '');
        change(MOYENS_PAIEMENT.MOTIF_TIERS, motif || '');
        change(MOYENS_PAIEMENT.NATURE_PAYEUR, nature || '');
        if (adresse) {
          change(MOYENS_PAIEMENT.ADRESSE, adresse.rue || '');
          change(MOYENS_PAIEMENT.VILLE, adresse.ville || '');
          change(MOYENS_PAIEMENT.CODE_POSTAL, (adresse.codePostal && adresse.codePostal.toString()) || '');
          change(MOYENS_PAIEMENT.PAYS, JSON.stringify(adresse.pays || COUNTRY.FRANCE));
        }
      }

      if (foreignBank) {
        change(MOYENS_PAIEMENT.PAYS_FONDS, JSON.stringify(etablissementHorsFrance.pays) || '');
        change(MOYENS_PAIEMENT.MOTIF_ETABLISSEMENT, etablissementHorsFrance.motif || '');
      }
    } else {
      change(MOYENS_PAIEMENT.ETABLISSEMENT_HORS_FRANCE, BOOLEAN.NO);
      change(MOYENS_PAIEMENT.IS_COMPTE_SOUSCRIPTEUR, BOOLEAN.YES);
      change(MOYENS_PAIEMENT.PAYS, JSON.stringify(COUNTRY.FRANCE));
      change(MOYENS_PAIEMENT.PAYS_FONDS, JSON.stringify(COUNTRY.FRANCE));
    }
  }

  render() {
    const { handleSubmit, pristine, invalid, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} className="formTunnel formMoyensPaiement">
        <label className="titreFormulaire">
          <Trans i18nKey="moyenPaiement.titre" />
        </label>

        <div className="grid-x grid-margin-x">
          <div className="cell medium-12 grid-x grid-margin-x align-middle">
            <h3 className="cell medium-9">
              <TransHtmlText i18nKey="moyenPaiement.compteSouscripteur" params={{ config: config }} />
            </h3>
            <div className="tunnelRadios cell medium-3">
              <div className="tunnelRadio">
                <Field
                  name={MOYENS_PAIEMENT.IS_COMPTE_SOUSCRIPTEUR}
                  component={YesChoix}
                  type="radio"
                  value={'oui'}
                  label="Oui"
                  id={`${MOYENS_PAIEMENT.IS_COMPTE_SOUSCRIPTEUR}-oui`}
                />
              </div>

              <div className="tunnelRadio">
                <Field
                  name={MOYENS_PAIEMENT.IS_COMPTE_SOUSCRIPTEUR}
                  component={YesChoix}
                  type="radio"
                  value={'non'}
                  label="Non"
                  id={`${MOYENS_PAIEMENT.IS_COMPTE_SOUSCRIPTEUR}-non`}
                />
              </div>
            </div>
          </div>
        </div>

        {!stringToBoolean(this.props.tiersNeeded) && <TiersPayeur />}
        <div className="grid-x grid-margin-x">
          <div className="cell medium-12 grid-x grid-margin-x align-middle">
            <h3 className="cell medium-9">
              <TransHtmlText i18nKey="moyenPaiement.etablissementFrancais" params={{ config: config }} />
            </h3>
            <div className="tunnelRadios cell medium-3">
              <div className="tunnelRadios">
                <div className="tunnelRadio">
                  <Field
                    name={MOYENS_PAIEMENT.ETABLISSEMENT_HORS_FRANCE}
                    component={YesChoix}
                    type="radio"
                    value={'non'}
                    label="Oui"
                    id={`${MOYENS_PAIEMENT.ETABLISSEMENT_HORS_FRANCE}-non`}
                  />
                </div>

                <div className="tunnelRadio">
                  <Field
                    name={MOYENS_PAIEMENT.ETABLISSEMENT_HORS_FRANCE}
                    component={YesChoix}
                    type="radio"
                    value={'oui'}
                    label="Non"
                    id={`${MOYENS_PAIEMENT.ETABLISSEMENT_HORS_FRANCE}-oui`}
                  />
                </div>
              </div>
            </div>
          </div>
          {stringToBoolean(this.props.isForeignBank) && <EtablissementEtranger />}
        </div>

        <div className="buttonCreationContainer">
          <YesButton text="Confirmer" disabled={invalid || submitting || pristine} />
        </div>
      </form>
    );
  }
}

MoyensPaiementForm.proptype = {
  change: PropTypes.func.isRequired,
  moyensPaiement: PropTypes.shape({
    nom: PropTypes.string,
    prenom: PropTypes.string,
    lien: PropTypes.string,
    motif: PropTypes.string,
    debitCompteSouscripteur: PropTypes.bool,
    adresse: PropTypes.shape({
      rue: PropTypes.string,
      codePostale: PropTypes.string,
      ville: PropTypes.string
    }),
    etablissementHorsFrance: PropTypes.shape({
      pays: PropTypes.object,
      motif: PropTypes.string
    })
  }),
  tiersNeeded: PropTypes.bool,
  isForeignBank: PropTypes.bool
};

const selectorForm = formValueSelector('MoyensPaiementForm');

const mapStateToProps = (state) => ({
  moyensPaiement: state.tunnel.fichePersonneMorale && state.tunnel.fichePersonneMorale.moyensPaiement,
  tiersNeeded: selectorForm(state, MOYENS_PAIEMENT.IS_COMPTE_SOUSCRIPTEUR),
  isForeignBank: selectorForm(state, MOYENS_PAIEMENT.ETABLISSEMENT_HORS_FRANCE),
  countries: state.countries,
  initialValues: {
    PAYS: state.tunnel.fichePersonneMorale?.moyensPaiement?.adresse?.pays
      ? JSON.stringify(state.tunnel.fichePersonneMorale.moyensPaiement.adresse.pays)
      : JSON.stringify(state.countries.france),
    PAYS_FONDS: state.tunnel.fichePersonneMorale?.moyensPaiement?.etablissementHorsFrance?.pays
      ? JSON.stringify(state.tunnel.fichePersonneMorale.moyensPaiement.etablissementHorsFrance.pays)
      : JSON.stringify(state.countries.france),
  }
});

export default connect(mapStateToProps)(reduxForm({
  form: 'MoyensPaiementForm',
  validate,
  enableReinitialize: true
})(MoyensPaiementForm));

/* built-in imports */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

/* Internal imports */
import FinalisationAttachmentsForm from './FinalisationAttachmentsForm';
import componentUtils from '../../../../../utils/US/components/epargne_salariale/forms/finalisationAttachmentsFormUtils';
import { FORMES_JURIDIQUES } from '../../../../../utils/constantes/US/FINALISATION_FORM';
import { TYPE_DOCUMENT } from '../../../../../common/Configuration';

const EpargneInformationsAttachmentsFormSociete = (
  {
    annexeKyc,
    decretReco,
    planExistant,
    recepDeclaPref,
    recepInsertion,
    tradKey
  }) => {

  const documents = componentUtils.filterDocuments(planExistant, annexeKyc, FORMES_JURIDIQUES.ASSOCIATION_UTILITE_PUBLIQUE.key);

  const getSpecificFilesData = () => {
    const filesData = {};
    const allDocuments = Array.prototype.concat(documents.required, documents.optional);
    for(let i = 0, documentsLength = allDocuments.length; i < documentsLength; i++) {
      const document = allDocuments[i];
      let fileData;
      switch (document.type) {
        case TYPE_DOCUMENT.RECEP_DECLA_PREF:
          fileData = componentUtils.getFile(recepDeclaPref);
          break;
        case TYPE_DOCUMENT.RECEP_INSERTION:
          fileData = componentUtils.getFile(recepInsertion);
          break;
        case TYPE_DOCUMENT.DECRET_RECO:
          fileData = componentUtils.getFile(decretReco);
          break;
        default:
          continue;
      }
      filesData[document.key] = fileData;
    }
    return filesData;
  };

  return (
    <FinalisationAttachmentsForm documents={documents} getSpecificFilesData={getSpecificFilesData} tradKey={tradKey} />
  );
};

const mapStateToProps = (state) => ({
  annexeKyc: state.tunnel.dossier.donneesAnnexeKYC,
  recepDeclaPref: state.tunnel.entreprise.justificatifRecepDeclaPref,
  recepInsertion: state.tunnel.entreprise.justificatifRecepInsertion,
  decretReco: state.tunnel.entreprise.justificatifDecretReco,
  planExistant: state.tunnel.dossier.planExistant
});

export default connect(mapStateToProps)(EpargneInformationsAttachmentsFormSociete);
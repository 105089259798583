/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import KycForm from '../../../../components/US/assurance/forms/KYC/KycForm';
import { URLS_ASSURANCE } from '../../../../utils/constantes/ROUTES/ASSURANCE';
import { formatActionnairesPerob } from '../../../../components/US/assurance/forms/KYC/kycMapper';
import { sendKycFields } from '../../../../services/DossierService';
import { setRetourRoute, setInformationsDossier } from '../../../../modules/tunnel/redux/tunnelActions';
import { stringToBoolean } from '../../../../common/FunctionsUtils';

class AssuranceKycPage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    // On modifie la route lorsque l'utilisateur clique le bouton Retour
    this.props.setRetourRoute(URLS_ASSURANCE.CATEGORIES_SALARIES.substring(1));


    this.state = {
      redirect: false
    };
  }

  submit(values) {
    sendKycFields(this.props.dossier.uid, this.formatKyc(values, this.props.dossier.type))
      .then((annexe) => {
        this.props.setInformationsDossier(annexe);
        this.setState({
          redirect: true
        });
      })
      .catch((error) => {
        if (error && error.message) {
          this.setState({
            errorMsg: error.message
          });
        }
      });
  }

  formatKyc(values) {
    return {
      actionnaires: formatActionnairesPerob(values.actionnaires),
      adressePostaleEntreprise: values.isSameCompanyAddress
        ? null
        : {
          codePostal: values.adressePostaleEntrepriseCode,
          rue: values.adressePostaleEntrepriseRue,
          ville: values.adressePostaleEntrepriseVille
        },
      bonsPorteur: stringToBoolean(values.isBonsPorteur) ? values.bonsPorteur : null,
      codeBIC: stringToBoolean(values.isBanque) ? values.codeBIC : null,
      entrepriseCotee: stringToBoolean(values.isEntrepriseCotee) ? values.entrepriseCotee : null,
      paysCotation: stringToBoolean(values.isEntrepriseCotee) ? JSON.parse(values.paysCotation) : null,
      paysActivite: {
        chiffreAffaire: values.chiffreAffaire,
        pays: JSON.parse(values.pays)
      },
      paysFiscalEntreprise: JSON.parse(values.paysFiscalEntreprise),
      paysPersonneMorale: stringToBoolean(values.needPaysPersonneMorale) ? JSON.parse(values.paysPersonneMorale) : null,
      principauxMarches: values.principauxMarches,
      principauxPays: values.principauxPays,
      regulateur: stringToBoolean(values.regulateur)
        ? {
          nomRegulateur: values.nomRegulateur,
          referenceAgrement: values.referenceAgrement
        }
        : null,
      lieuExercice: {
        france: values.hasActivityFrance,
        ue: values.hasActivityUE ? values.countryActivityUE : null,
        horsUe: values.hasActivityHorsUE ? values.countryActivityHorsUE : null
      }
    };

  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={URLS_ASSURANCE.MOYENS_PAIEMENT} />;
    }

    return (
      <div>
        <KycForm onSubmit={this.submit} />
      </div>
    );
  }
}

const mapStateToProps = ({ tunnel: { dossier } }) => ({
  dossier
});

const mapDispatchToProps = {
  setRetourRoute,
  setInformationsDossier
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssuranceKycPage);

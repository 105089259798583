/* internal imports */
import { BOOLEAN, CIVILITES, CIVILITES_LIST, COUNTRY, NATIONALITE } from '../../../../../common/constants';
import { PPE_FORM_FIELD } from '../../../../../utils/constantes/US/PPEFORM';
import { booleanToString, stringToBoolean } from '../../../../../common/FunctionsUtils';
import {
  BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE,
  QUALITE_ACTIONNNAIRE,
  TYPE_CONTROLE
} from '../../../../../utils/constantes/US/KYCFORM';

const getGenericField = (sh) => ({
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.NOM]: sh.nom,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PARTS]: sh.parts,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PRENOM]: sh.prenom,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.QUALITE]: sh.qualite,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.SAME_ADDRESS_COMPANY]: !sh.adressePostale,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.FONCTION_DIRIGEANT]: sh.fonctionDirigeant,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PAYS_FISCAL]: JSON.stringify(sh.paysFiscal),
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.ACTIVITE]: sh.activiteBeneficiaire,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.RUE]: sh.adressePermanente?.rue,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.VILLE]: sh.adressePermanente?.ville,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.CODE_POSTAL]: sh.adressePermanente?.codePostal,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.RAISON_SOCIALE]: sh.beneficiaireIsPersonneMorale ? sh.nom : '',
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.RUE_ENTREPRISE]: sh.adressePostale ? sh.adressePostale.rue : '',
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.VILLE_ENTREPRISE]: sh.adressePostale ? sh.adressePostale.ville : '',
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.CODE_POSTAL_ENTREPRISE]: sh.adressePostale ? sh.adressePostale.codePostal : '',
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.IS_PERSONNE_MORALE]: booleanToString(sh.beneficiaireIsPersonneMorale)
});

const getPeroFields = (sh) => ({
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.CIVILITE]: CIVILITES_LIST.find((civ) => civ.value === sh.civilite)?.value,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.NOM_NAISSANCE]: sh.nomNaissance,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.TYPE_CONTROLE]: sh.typeControle,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.DATE_NAISSANCE]: sh.dateNaissance,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.NATIONALITE]: sh.paysNationalite,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PAYS]: JSON.stringify(sh.adressePermanente?.pays || COUNTRY.FRANCE),
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.LIEU_NAISSANCE]: sh.adresseNaissance?.ville ? sh.adresseNaissance.ville : '',
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.CODE_POSTAL_NAISSANCE]: sh.adresseNaissance?.codePostal ? sh.adresseNaissance.codePostal : '',
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PAYS_NAISSANCE]: sh.adresseNaissance?.pays ? JSON.stringify(sh.adresseNaissance.pays || COUNTRY.FRANCE) : '',
  [PPE_FORM_FIELD.IS_PPE]: booleanToString(sh.ppe),
  [PPE_FORM_FIELD.IS_PROCHE_PPE]: booleanToString(sh.prochePpe),
  ...mapPpe(sh.ppe),
  ...mapPpe(sh.prochePpe, false),
  [PPE_FORM_FIELD.NOM_PPE]: sh.prochePpe?.nom ? sh.prochePpe.nom : '',
  [PPE_FORM_FIELD.PRENOM_PPE]: sh.prochePpe?.prenom ? sh.prochePpe.prenom : '',
  [PPE_FORM_FIELD.LIEN_PPE]: sh.prochePpe?.lien ? sh.prochePpe.lien : ''
});

const mapPpe = (ppe, isPpe = true) =>
  ppe
    ? {
      [`fonction${isPpe ? '' : 'Ppe'}`]: ppe.fonction ? ppe.fonction : '',
      [`finFonction${isPpe ? '' : 'Ppe'}`]: ppe.finFonction,
      [`paysExercice${isPpe ? '' : 'Ppe'}`]: JSON.stringify(ppe.pays || COUNTRY.FRANCE)
    }
    : null;

const mapStockholders = (list) =>
  list.map((sh) => ({ ...getGenericField(sh), ...getPeroFields(sh) }));

const initEmptyFields = (change) => {
  change('pays', JSON.stringify(COUNTRY.FRANCE));
  change('chiffreAffaire', 100);
  change('isSameCompanyAddress', true);
  change('principauxPays', COUNTRY.FRANCE.nom);
  change('principauxMarches', COUNTRY.FRANCE.nom);
  change('isBanque', BOOLEAN.NO);
  change('paysFiscalEntreprise', JSON.stringify(COUNTRY.FRANCE));
  change('regulateur', BOOLEAN.NO);
  change('isEntrepriseCotee', BOOLEAN.NO);
  change('paysCotation', JSON.stringify(COUNTRY.FRANCE));
  change('isBonsPorteur', BOOLEAN.NO);
  change('needPaysPersonneMorale', BOOLEAN.NO);
  change('hasActivityFrance', true)
};

const initFieldsWithTunnelValues = (donneesKyc, change) => {
  if (donneesKyc.adressePostaleEntreprise) {
    change('adressePostaleEntrepriseRue', donneesKyc.adressePostaleEntreprise.rue);
    change('adressePostaleEntrepriseCode', donneesKyc.adressePostaleEntreprise.codePostal);
    change('adressePostaleEntrepriseVille', donneesKyc.adressePostaleEntreprise.ville);
  } else {
    change('isSameCompanyAddress', true);
  }
  change('paysFiscalEntreprise', JSON.stringify(donneesKyc.paysFiscalEntreprise) || JSON.stringify(COUNTRY.FRANCE));
  change('codeBIC', donneesKyc.codeBIC);
  change('isBanque', booleanToString(!!donneesKyc.codeBIC));
  change('regulateur', booleanToString(!!donneesKyc.regulateur));
  if (donneesKyc.regulateur) {
    change('nomRegulateur', donneesKyc.regulateur.nomRegulateur);
    change('referenceAgrement', donneesKyc.regulateur.referenceAgrement);
  }
  if (donneesKyc.paysActivite) {
    change('chiffreAffaire', donneesKyc.paysActivite.chiffreAffaire || 100);
    change('pays', JSON.stringify(donneesKyc.paysActivite.pays));
  } else {
    change('pays', JSON.stringify(COUNTRY.FRANCE));
  }
  change('isEntrepriseCotee', booleanToString(!!donneesKyc.entrepriseCotee));
  if (donneesKyc.entrepriseCotee) {
    change('paysCotation', JSON.stringify(donneesKyc.paysCotation));
    change('entrepriseCotee', donneesKyc.entrepriseCotee);
  } else {
    change('paysCotation', JSON.stringify(COUNTRY.FRANCE));
  }
  change('isBonsPorteur', booleanToString(!!donneesKyc.bonsPorteur));
  if (donneesKyc.bonsPorteur) {
    change('bonsPorteur', donneesKyc.bonsPorteur);
  }
  change('needPaysPersonneMorale', booleanToString(!!donneesKyc.paysPersonneMorale?.nom));
  if (donneesKyc.paysPersonneMorale) {
    change('paysPersonneMorale', JSON.stringify(donneesKyc.paysPersonneMorale));
  } else {
    change('paysPersonneMorale', JSON.stringify(COUNTRY.FRANCE));
  }

  // LIEU EXERCICE
  if (donneesKyc.lieuExercice) {
    const lieuExercice = donneesKyc.lieuExercice;
    change('hasActivityFrance', lieuExercice.france);
    lieuExercice.ue
      ? change('hasActivityUE', true) && change('countryActivityUE', lieuExercice.ue)
      : change('hasActivityHorsUE', false)
    lieuExercice.horsUe
      ? change('hasActivityHorsUE', true) && change('countryActivityHorsUE', lieuExercice.horsUe)
      : change('hasActivityHorsUE', false)
  }

  change('principauxMarches', donneesKyc.principauxMarches);
  change('principauxPays', donneesKyc.principauxPays);

  if (donneesKyc.actionnaires) {
    change('actionnaires', mapStockholders(donneesKyc.actionnaires));
  }
};

export const initKycFields = (donneesKyc, change) => {
  if (donneesKyc) {
    initFieldsWithTunnelValues(donneesKyc, change);
  } else {
    initEmptyFields(change);
  }
};

export const initBenefiaireEffectif = () => ({
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.SAME_ADDRESS_COMPANY]: true,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.CIVILITE]: CIVILITES.MONSIEUR,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.TYPE_CONTROLE]: TYPE_CONTROLE.DETENTION_CAPITAL,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.NATIONALITE]: NATIONALITE.DEFAULT,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.QUALITE]: QUALITE_ACTIONNNAIRE.BENEFICIAIRE,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.IS_PERSONNE_MORALE]: BOOLEAN.NO,
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PAYS]: JSON.stringify(COUNTRY.FRANCE),
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PAYS_FISCAL]: JSON.stringify(COUNTRY.FRANCE),
  [BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PAYS_NAISSANCE]: JSON.stringify(COUNTRY.FRANCE),
  //PPE
  [PPE_FORM_FIELD.IS_PROCHE_PPE]: BOOLEAN.NO,
  [PPE_FORM_FIELD.PAYS]: JSON.stringify(COUNTRY.FRANCE),
  [PPE_FORM_FIELD.IS_PPE]: BOOLEAN.NO,
  [PPE_FORM_FIELD.PAYS_PPE]: JSON.stringify(COUNTRY.FRANCE)
});

export const formatActionnairesPerob = (values) => values
    ? values.map((value) => ({
      adressePermanente: {
        codePostal: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.CODE_POSTAL],
        rue: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.RUE],
        ville: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.VILLE],
        pays: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PAYS] ? JSON.parse(value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PAYS]) : null
      },
      adressePostale: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.SAME_ADDRESS_COMPANY]
        ? null
        : {
          codePostal: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.CODE_POSTAL_ENTREPRISE],
          rue: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.RUE_ENTREPRISE],
          ville: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.VILLE_ENTREPRISE]
        },
    beneficiaireIsPersonneMorale: stringToBoolean(value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.IS_PERSONNE_MORALE]),
      nom: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.NOM],
      prenom: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PRENOM],
      parts: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PARTS],
      paysFiscal: JSON.parse(value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PAYS_FISCAL]),
      qualite: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.QUALITE],
      // champs pero
      civilite: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.CIVILITE],
      typeControle: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.TYPE_CONTROLE],
      paysNationalite: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.NATIONALITE],
      adresseNaissance: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.LIEU_NAISSANCE]
        ? {
          codePostal: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.CODE_POSTAL_NAISSANCE],
          ville: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.LIEU_NAISSANCE],
          pays: JSON.parse(value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PAYS_NAISSANCE])
        }
        : null,
      nomNaissance: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.NOM_NAISSANCE],
      dateNaissance: value[BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.DATE_NAISSANCE],
      ppe: stringToBoolean(value[PPE_FORM_FIELD.IS_PPE])
        ? {
          fonction: value[PPE_FORM_FIELD.FONCTION],
          pays: JSON.parse(value[PPE_FORM_FIELD.PAYS]),
          finFonction: value[PPE_FORM_FIELD.FIN_FONCTION]
        }
        : null,
      prochePpe: stringToBoolean(value[PPE_FORM_FIELD.IS_PROCHE_PPE])
        ? {
          fonction: value[PPE_FORM_FIELD.FONCTION_PPE],
          pays: JSON.parse(value[PPE_FORM_FIELD.PAYS_PPE]),
          finFonction: value[PPE_FORM_FIELD.FIN_FONCTION_PPE],
          nom: value[PPE_FORM_FIELD.NOM_PPE],
          prenom: value[PPE_FORM_FIELD.PRENOM_PPE],
          lien: value[PPE_FORM_FIELD.LIEN_PPE]
        }
        : null
    }))
    : null;

/* Built in imports */
import React from 'react';

/* Third party imports */
import { Route } from 'react-router-dom';

/* Internal imports */
import { NoLayout } from '../../layouts/default/NoLayout';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

AppRoute.defaultProps = {
  layout: NoLayout
};

export default AppRoute;
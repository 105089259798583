/* Built-in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Internal imports */
import RecapitulatifBloc from './RecapitulatifBloc';
import TextWithTitle from './TextWithTitle';
import { style } from './recapitulatif.style';

const RecapitulatifMoyensPaiement = ({ t, moyensPaiement }) => {
  return (
    <RecapitulatifBloc title={t('recap.moyensPaiement.titre')}>
      <div style={style.mentionBloc}>
        <p style={style.mention}>
          {t('recap.moyensPaiement.compteSouscripteur').replace(
            '{0}',
            t(moyensPaiement.debitCompteSouscripteur ? 'recap.moyensPaiement.is' : 'recap.moyensPaiement.isNot')
          )}
        </p>
        {!moyensPaiement.debitCompteSouscripteur && (
          <>
            <TextWithTitle
              title={t('recap.moyensPaiement.tiers')}
              content={`${moyensPaiement.prenom} ${moyensPaiement.nom} (${moyensPaiement.nature})`}
            />
            <TextWithTitle title={t('recap.moyensPaiement.lien')} content={moyensPaiement.lien} />
            <TextWithTitle title={t('recap.moyensPaiement.motif')} content={moyensPaiement.motif} />
          </>
        )}
      </div>

      <div style={style.mentionBloc}>
        <p style={style.mention}>
          {t('recap.moyensPaiement.creditFrancais').replace(
            '{0}',
            t(moyensPaiement.etablissementHorsFrance ? 'recap.moyensPaiement.isNot' : 'recap.moyensPaiement.is')
          )}
        </p>
        {moyensPaiement.etablissementHorsFrance && (
          <>
            <TextWithTitle title={t('recap.moyensPaiement.pays')} content={`${moyensPaiement.etablissementHorsFrance.pays.nom}`} />
            <TextWithTitle title={t('recap.moyensPaiement.motif')} content={`${moyensPaiement.etablissementHorsFrance.motif}`} />
          </>
        )}
      </div>
    </RecapitulatifBloc>
  );
};

RecapitulatifMoyensPaiement.propTypes = {
  t: PropTypes.func.isRequired,
  moyensPaiement: PropTypes.object.isRequired
};

// REDUX + REDUX FORM
const mapStateToProps = ({
  tunnel: {
    fichePersonneMorale: { moyensPaiement }
  }
}) => ({ moyensPaiement });

export default connect(mapStateToProps)(withTranslation()(RecapitulatifMoyensPaiement));

/* Built-in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

/* Internal imports */
import { YesChoix } from '../../../../form/Choix';

const optionnalyRequired = (value) => (value ? undefined : 'Ce champ est obligatoire');

const EpargneEmployeeCompanySelector = ({ label, target, disabled = false }) => (
  <div className="ges-tarification-inline-display">
    <div className="ges-tarification-label">{label ? `${label} : ` : ''}</div>
    <div className="ges-tarification-inline-display">
      <div className="tunnelRadio ges-libelle-tarification">
        <Field
          disabled={disabled}
          name={target}
          component={YesChoix}
          type="radio"
          value={'entreprise'}
          label="l'entreprise"
          id={`${target}-entreprise`}
          validate={disabled ? undefined : [optionnalyRequired]}
        />
      </div>
      <div className="tunnelRadio ges-libelle-tarification">
        <Field
          disabled={disabled}
          name={target}
          component={YesChoix}
          type="radio"
          value={'salarie'}
          label="le bénéficiaire"
          id={`${target}-salarie`}
          validate={disabled ? undefined : [optionnalyRequired]}
        />
      </div>
    </div>
  </div>
);

EpargneEmployeeCompanySelector.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  target: PropTypes.string.isRequired
};

export default EpargneEmployeeCompanySelector;

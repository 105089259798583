import API from '../../utils/constantes/API';
import CITRA from '../../api/citra';
import WORKADVICESSHEETSACTIONS from '../../utils/constantes/REDUCERS/WORKADVICESSHEETSACTIONS';
import workAdvicesSheetsDeserializer from '../../deserializers/workAdvicesSheetsDeserializers';

export const setWorkAdvicesSheets = (workAdvicesSheets) => (dispatch) => {
  dispatch({
    type: WORKADVICESSHEETSACTIONS.UPDATESUCCESS,
    workAdvicesSheets
  });
};

export const removeWorkAdvicesSheets = (dossierUid) => (dispatch) => {
  dispatch({ type: WORKADVICESSHEETSACTIONS.UPDATESTART });

  CITRA().delete(`${API.FICHES.WORKADVICESSHEET}/${encodeURIComponent(dossierUid)}`)
    .then(() => {
      dispatch({
        type: WORKADVICESSHEETSACTIONS.DELETESUCCESS
      });
    })
    .catch((error) => {
      dispatch({
        type: WORKADVICESSHEETSACTIONS.UPDATEERROR,
        error,
      });
    })
};


export const updateWorkAdvicesSheets = (workAdvicesSheets, dossierUid, t, submitCallback) => (dispatch) => {
  dispatch({ type: WORKADVICESSHEETSACTIONS.UPDATESTART });

  CITRA().put(`${API.FICHES.WORKADVICESSHEET}/${encodeURIComponent(dossierUid)}`, workAdvicesSheetsDeserializer(workAdvicesSheets, t))
    .then((res) => {
      dispatch({
        type: WORKADVICESSHEETSACTIONS.UPDATESUCCESS,
        workAdvicesSheets: res.data
      });
      submitCallback();
    })
    .catch((error) => {
      dispatch({
        type: WORKADVICESSHEETSACTIONS.UPDATEERROR,
        error,
      });
    })
};
/* Built-in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Internal imports */
import RecapitulatifBeneficiaires from './RecapitulatifBeneficiaires';
import RecapitulatifDirigeant from './RecapitulatifDirigeant';
import RecapitulatifEntreprise from './RecapitulatifEntreprise';
import RecapitulatifModalites from './RecapitulatifModalites';
import RecapitulatifMoyensPaiement from './RecapitulatifMoyensPaiement';
import WorkAdvicesSheetsSummary from '../../summary/workAdvicesSheetsSummary/WorkAdvicesSheetsSummary';
import { getSvgEnFonctionDuTenant } from '../../../../services/TenantService';
import { style } from './recapitulatif.style.js';

const Recapitulatifs = ({ workAdvicesSheets }) => {
  return (
    <div style={style.modalContent}>
      <div style={style.logoContainer}>
        <img style={style.logo} src={getSvgEnFonctionDuTenant('/assets/img/logos/logo-', '-tunnel.svg')}
             alt="logo ggp" />
      </div>

      <h2 style={style.mainTitle}>Récapitulatif</h2>
      <div className="grid-x grid-margin-x">
        <RecapitulatifEntreprise />
        <RecapitulatifDirigeant />
        <RecapitulatifModalites />
        <RecapitulatifMoyensPaiement />
        <RecapitulatifBeneficiaires />
        {workAdvicesSheets && (
          <WorkAdvicesSheetsSummary />
        )}
      </div>
    </div>
  )
}

Recapitulatifs.propTypes = {
  workAdvicesSheets: PropTypes.object
};

export default connect(({ workAdvicesSheets }) => ({ workAdvicesSheets }))(withTranslation()(Recapitulatifs));

/* Built in imports */
import React, { useState } from 'react';

/* Third party imports */
import { connect } from 'react-redux';

/* Internal imports */
import MaroquinerieTarificationForm from "../../../../components/US/epargne_salariale/maroquinerie/form/tarification/MaroquinerieTarificationForm";
import { FORMAT_INVALIDE } from '../../../../common/ApiError';
import { STATUT_LIASSE } from "../../../../common/Configuration";
import { URLS_MAROQUINERIE } from '../../../../utils/constantes/ROUTES/MAROQUINERIE';
import { creerLiasseSouscription, recupererUrlSignature } from '../../../../services/LiasseService';
import { mettreAjourTarification } from '../../../../services/DossierService';
import { retirerPourcent, stringSansEspaces } from '../../../../common/FunctionsUtils';
import {
  setLiasse, setLoader,
  setRetourRoute,
  setTarification
} from '../../../../modules/tunnel/redux/tunnelActions';

const MaroquinerieTarificationPage = ({ uid, setRetourRoute, setTarification, setLoader, setLiasse }) => {

  const [errorMessage, setErrorMessage] = useState("");

  setRetourRoute(URLS_MAROQUINERIE.KYC.substring(1));

  const mapEpargneValues = (values) =>
    Object.assign({
      iban: stringSansEspaces(values.iban),
      bic: values.bic,
      fraisDossier: values.fraisDossier,

      fraisEntree: retirerPourcent(values.fraisEntree.replace(',', '.')),
      optionChargeFrais: values.optionChargeFrais,
      applicationFraisConseil: values.applicationFraisConseil ? values.applicationFraisConseil : false,
      ciblePrelevements: values.ciblePrelevements
    });

  const redirectOrGetUrlSignature = (liasse) => {
    if (liasse?.statut !== STATUT_LIASSE.SIGNEE && liasse?.statut !== STATUT_LIASSE.ENVOYEE) {
      setLoader(false);
      return;
    }

    const redirectUrl = window.location.origin + URLS_MAROQUINERIE.FINALISATION;
    recupererUrlSignature(liasse.uid, redirectUrl)
      .then((urlSignature) => {
        window.location.assign(urlSignature.url);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  const submit = (values) => {
    const valuesAenvoye = mapEpargneValues(values);
    setLoader(true);

    mettreAjourTarification(uid, { valuesAenvoye })
      .then((dossier) => (setTarification(dossier.tarification)))
      .then(() => {
        creerLiasseSouscription({ uid })
          .then((liasse) => {
            setLiasse(liasse);
            redirectOrGetUrlSignature(liasse);
          })
      })
      .catch((error) => {
        if (error?.message && error.code === FORMAT_INVALIDE) {
          setErrorMessage(error.message);
          error.log("error: ", error.message);
        }
      });
  }

  return (
    <div>
      <MaroquinerieTarificationForm onSubmit={submit} customErrorIban={errorMessage} />
    </div>
  );
}

const mapStateToProps = ({ tunnel }) => ({
  uid: tunnel.dossier.uid
});

const mapDispatchToProps = (dispatch) => ({
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setTarification: (tarification) => dispatch(setTarification(tarification)),
  setLoader: (actif) => dispatch(setLoader(actif)),
  setLiasse: (liasse) => dispatch(setLiasse(liasse))
});

export default connect(mapStateToProps, mapDispatchToProps)(MaroquinerieTarificationPage);

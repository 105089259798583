const countries = [
  {
    name: "France",
    countryCodeA2: "FR",
    countryCodeA3: "FRA",
    iddCode: "+33"
  },
  {
    name: "Guadeloupe",
    countryCodeA2: "GP",
    countryCodeA3: "GLP",
    iddCode: "+590"
  },
  {
    name: "Guyane",
    countryCodeA2: "GF",
    countryCodeA3: "GUF",
    iddCode: "+594"
  },
  {
    name: "Martinique",
    countryCodeA2: "MQ",
    countryCodeA3: "MTQ",
    iddCode: "+596"
  },
  {
    name: "Mayotte",
    countryCodeA2: "YT",
    countryCodeA3: "MYT",
    iddCode: "+262"
  },
  {
    name: "La Réunion",
    countryCodeA2: "RE",
    countryCodeA3: "REU",
    iddCode: "+262"
  },
];

export default countries;
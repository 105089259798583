import React, { Component } from "react";
import { nombreAvecEspaces } from "../../../../common/FunctionsUtils";

/* EXEMPLES : https://codepen.io/maydie/pen/OVmxZZ */
// Composant customisé : ajout de la notion de rotation pour tourner le graphique
export class Pie extends Component {
  render() {
    const {
      colors,
      labels,
      hole,
      radius,
      strokeWidth,
      stroke,
      percent,
      rotate,
      animer
    } = this.props;
    const colorsLength = colors.length;
    const diameter = radius * 2;
    const sum = this.props.data.reduce(function(carry, current) {
      return carry + current;
    }, 0);
    let startAngle = 0;

    let rotateValuePie = rotate;
    let rotateValueText = rotate;

    if (!rotate) {
      rotateValuePie = 0;
      rotateValueText = 0;
    }

    if (this.props.noPieRotation) {
      rotateValuePie = 0;
    }

    return (
      <svg
        transform={"rotate(" + rotateValuePie + ")"}
        width={diameter}
        height={diameter}
        viewBox={"0 0 " + diameter + " " + diameter}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        {this.props.data.map(function(slice, sliceIndex) {
          var angle, nextAngle;

          nextAngle = startAngle;
          angle = (slice / sum) * 360;
          var percentCalculated = (slice / sum) * 100;
          startAngle += angle;

          return (
            <Slice
              key={sliceIndex}
              value={slice}
              percent={percent}
              rotate={rotateValueText}
              percentValue={percentCalculated.toFixed(1)}
              startAngle={nextAngle}
              angle={angle}
              radius={radius}
              hole={radius - hole}
              trueHole={hole}
              showLabel={labels}
              fill={colors[sliceIndex % colorsLength]}
              stroke={stroke}
              strokeWidth={strokeWidth}
              animer={animer}
            />
          );
        })}
      </svg>
    );
  }
}

class Slice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "",
      x: 0,
      y: 0
    };
  }

  componentWillReceiveProps() {
    this.setState({ path: "" });
    this.animate();
  }

  componentDidMount() {
    this.animate();
  }

  animate() {
    this.draw(0);
  }

  draw(s) {
    // if (!this.isMounted()) {
    // 	return;
    // }

    var p = this.props,
      path = [],
      a,
      b,
      c,
      self = this,
      step;

    step = p.angle / (37.5 / 2);

    if (s + step > p.angle) {
      s = p.angle;
    }

    // Get angle points
    a = getAnglePoint(
      p.startAngle,
      p.startAngle + s,
      p.radius,
      p.radius,
      p.radius
    );
    b = getAnglePoint(
      p.startAngle,
      p.startAngle + s,
      p.radius - p.hole,
      p.radius,
      p.radius
    );

    path.push("M" + a.x1 + "," + a.y1);
    path.push(
      "A" +
        p.radius +
        "," +
        p.radius +
        " 0 " +
        (s > 180 ? 1 : 0) +
        ",1 " +
        a.x2 +
        "," +
        a.y2
    );
    path.push("L" + b.x2 + "," + b.y2);
    path.push(
      "A" +
        (p.radius - p.hole) +
        "," +
        (p.radius - p.hole) +
        " 0 " +
        (s > 180 ? 1 : 0) +
        ",0 " +
        b.x1 +
        "," +
        b.y1
    );

    // Close
    path.push("Z");

    this.setState({ path: path.join(" ") });

    if (s < p.angle) {
      if (this.props.animer === true) {
        setTimeout(function() {
          self.draw(s + step);
        }, 16);
      } else {
        self.draw(s + step);
      }

      // self.draw(s + step)
    } else if (p.showLabel) {
      c = getAnglePoint(
        p.startAngle,
        p.startAngle + p.angle / 2,
        p.radius / 2 + p.trueHole / 2,
        p.radius,
        p.radius
      );

      this.setState({
        x: c.x2,
        y: c.y2
      });
    }
  }

  render() {
    let rotateValue = "";
    if (this.state.x && this.state.y) {
      rotateValue =
        "rotate(" +
        Math.abs(this.props.rotate) +
        "," +
        this.state.x +
        "," +
        this.state.y +
        ")";
    }

    return (
      <g overflow="hidden">
        <path
          d={this.state.path}
          fill={this.props.fill}
          stroke={this.props.stroke}
          strokeWidth={this.props.strokeWidth ? this.props.strokeWidth : 3}
        />
        {this.props.showLabel && this.props.percentValue > 5 ? (
          <text
            style={{ fontFamily: "Source Sans Pro", fontSize: 16 }}
            transform={rotateValue}
            x={this.state.x}
            y={this.state.y}
            fill="#fff"
            textAnchor="middle"
          >
            {this.props.percent
              ? this.props.percentValue + "%"
              : nombreAvecEspaces(this.props.value) + " €"}
          </text>
        ) : null}
      </g>
    );
  }
}

function getAnglePoint(startAngle, endAngle, radius, x, y) {
  var x1, y1, x2, y2;

  x1 = x + radius * Math.cos((Math.PI * startAngle) / 180);
  y1 = y + radius * Math.sin((Math.PI * startAngle) / 180);
  x2 = x + radius * Math.cos((Math.PI * endAngle) / 180);
  y2 = y + radius * Math.sin((Math.PI * endAngle) / 180);

  return { x1, y1, x2, y2 };
}

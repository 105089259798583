export const MODALITESPERO_FORM_FIELDS = {
  DATE_EFFET: 'dateEffet',
  PERIODICITE: 'periodicite'
};

export const MODALITESPERO_PERIODICITE_VALUE = {
  MENSUEL: 'Mensuellement'
};

export const GARANTIESPREVOYANCE_FORM_FIELDS = {
  AU_MOINS_CINQ_SALARIES: 'auMoinsCinqSalaries',
  GARANTIE_DE_BONNE_FIN: 'garantieDeBonneFin',
  GARANTIE_PLANCHER: 'garantiePlancher'
};

/* Built in imports */
import React from 'react';

/* Third party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

/* Internal imports */
import AddContributionLevel from './AddContributionLevel';
import YesInput from '../../../../form/Input';
import { NormalizeNumber } from '../../../../../common/Normalizers';
import { Required } from '../../../../../common/Validators';
import { YesDropdown } from '../../../../form/Dropdown';

const ContributionLine = ({
  index,
  contributionType,
  isActive,
  changeRate,
  deletable,
  deleteLine,
  addLine,
  isLineEnabled,
  checkDecliningRate,
  checkMaxValue,
  checkMaxHigherThanMin,
  changeMaxValue,
  maxStepValue,
  modeAnciennete,
  doValidation,
  listTauxAbondement
}) => {
  return (
    <div className="selectionTaux alignContentInRow">
      <div className="palier alignContentInRow">
        <div className="yes-label">Taux de</div>
        <div>
          <Field
            id={`${contributionType}[${index}].taux`}
            name={`${contributionType}[${index}].taux`}
            label=""
            component={YesDropdown}
            item={listTauxAbondement}
            onChange={changeRate}
            validate={doValidation ? [checkDecliningRate] : []}
          />
        </div>
        {isActive && (
          <div className="displayContents">
            <div className="yes-label">pour un versement de</div>
            <div className="contribution-line">
              <Field
                id={`${contributionType}[${index}].versementMin`}
                name={`${contributionType}[${index}].versementMin`}
                component={YesInput}
                disabled={true}
                euroField={true}
                normalize={NormalizeNumber}
                onChange={changeMaxValue}
              />
            </div>
            <div className="yes-label">à</div>
            <div className="contribution-line">
              <Field
                id={`${contributionType}[${index}].versementMax`}
                name={`${contributionType}[${index}].versementMax`}
                component={YesInput}
                euroField={true}
                validate={doValidation ? [Required, checkMaxValue, checkMaxHigherThanMin] : []}
                normalize={NormalizeNumber}
                onChange={changeMaxValue}
              />
            </div>
            <span className="infosMaxPalier">{maxStepValue(index)}</span>
          </div>
        )}
        {!isActive && addLine && (
          <>
            <AddContributionLevel isEnabled={isLineEnabled} handleAdd={() => addLine()} contributionTypeLabel="abondement" />
            <AddContributionLevel
              isEnabled={isLineEnabled}
              handleAdd={() => modeAnciennete(contributionType.match(/.*Pei$/) ? 'pei' : 'percol', true)}
              contributionTypeLabel="ancienneté"
            />
          </>
        )}
      </div>
      {deletable && (
        <div className="boutonSupprimerPalier" onClick={deleteLine}>
          <img className="suppressionPalierLogo" src="/assets/img/icons/ges/red-cross.svg" alt="information tooltip" />
        </div>
      )}
    </div>
  );
};

ContributionLine.propTypes = {
  deletable: PropTypes.bool,
  deleteLine: PropTypes.func,
  modeAnciennete: PropTypes.func,
  addLine: PropTypes.func,
  isActive: PropTypes.bool.isRequired,
  changeRate: PropTypes.func.isRequired,
  maxStepValue: PropTypes.func.isRequired,
  checkMaxValue: PropTypes.func.isRequired,
  changeMaxValue: PropTypes.func.isRequired,
  checkDecliningRate: PropTypes.func.isRequired,
  checkMaxHigherThanMin: PropTypes.func.isRequired,
  doValidation: PropTypes.bool,
  listTauxAbondement: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  })).isRequired
};

export default ContributionLine;

import { fetch, uploadFiles } from './ResourcesService';
import { PREFIX_BACK } from '../common/Configuration';

export const rechercherEntrepriseDansSirene = (siret) => fetch(true, PREFIX_BACK + 'donnees/sirene/search?siret=' + siret);

export const getEntreprise = (uidEntreprise) => fetch(true, PREFIX_BACK + 'entreprises/' + uidEntreprise);

export const enregistrerEntreprise = ({ valuesAenvoye }) =>
  fetch(true, PREFIX_BACK + 'entreprises', {
    method: 'POST',
    body: JSON.stringify(valuesAenvoye)
  });

export const mettreAjourEntreprise = (uidEntreprise, { valuesAenvoye }) =>
  fetch(true, PREFIX_BACK + 'entreprises/' + uidEntreprise, {
    method: 'PUT',
    body: JSON.stringify(valuesAenvoye)
  });

export const mettreAjourInformationsComplementairesEntreprise = (uidEntreprise, { valuesAenvoye }) =>
  fetch(true, PREFIX_BACK + 'entreprises/' + uidEntreprise + '/informationsComplementaires', {
    method: 'PUT',
    body: JSON.stringify(valuesAenvoye)
  });

export const stockerPagesStatut = (uid, files) => uploadFiles(true, PREFIX_BACK + 'entreprises/' + uid + '/statuts', 'POST', files);

export const supprimerPageStatut = (uid, nom) =>
  fetch(true, PREFIX_BACK + 'entreprises/' + uid + '/statuts?nom=' + nom, {
    method: 'DELETE'
  });

export const stockerPagesJustificatifIdentiteEntreprise = (uid, files) =>
  uploadFiles(true, PREFIX_BACK + 'entreprises/' + uid + '/justificatifIdentiteEntreprise', 'POST', files);

export const supprimerPageJustificatifIdentiteEntreprise = (uid, nom) =>
  fetch(true, PREFIX_BACK + 'entreprises/' + uid + '/justificatifIdentiteEntreprise?nom=' + nom, {
    method: 'DELETE'
  });

export const submitKycDoc = (uid, doc) => fetch(true, PREFIX_BACK + `entreprises/${uid}/annexeKyc`, 'POST', doc);

export const stockerPagesDelegationPouvoir = (uid, files) => {
  return uploadFiles(true, PREFIX_BACK + 'entreprises/' + uid + '/delegationPouvoir', 'POST', files);
};

export const supprimerPageDelegationPouvoir = (uid, nom) => {
  return fetch(true, PREFIX_BACK + 'entreprises/' + uid + '/delegationPouvoir?nom=' + nom, {
    method: 'DELETE'
  });
};

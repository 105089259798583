/* Built-in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Internal imports */
import RecapitulatifBloc from './RecapitulatifBloc';
import TextWithTitle from './TextWithTitle';
import { getAddressLabel } from '../../../../utils/US/components/assurance/recapitulatif/recapitulatifUtils';
import { style } from './recapitulatif.style';

const RecapitulatifDirigeant = ({ t, utilisateur, ppe, prochePpe }) => (
  <RecapitulatifBloc title={t('recap.dirigeant.titre')}>
    <TextWithTitle
      title={t('recap.dirigeant.civilite')}
      content={`${utilisateur.civilite} ${utilisateur.prenom} ${utilisateur.nom}`}
    />
    {utilisateur.nomNaissance && <TextWithTitle title={t('recap.dirigeant.nomNaissance')} content={utilisateur.nomNaissance} />}
    <TextWithTitle title={t('recap.dirigeant.mail')} content={utilisateur.adresseEmail} />
    <TextWithTitle title={t('recap.dirigeant.telephone')} content={utilisateur.telephone} />
    <TextWithTitle
      title={t('recap.dirigeant.dateNaissance')}
      content={`${utilisateur.dateNaissance} à ${getAddressLabel(utilisateur.adresseNaissance)}`}
    />
    <TextWithTitle title={t('recap.dirigeant.nationalites')} content={utilisateur.nationalites} />
    <TextWithTitle title={t('recap.dirigeant.residence')} content={getAddressLabel(utilisateur.adresse)} />
    <TextWithTitle title={t('recap.dirigeant.nir')} content={utilisateur.nir} />
    {ppe && (
      <div style={style.mentionBloc}>
        <p style={style.mention}>{t('recap.dirigeant.ppe')}</p>
        <TextWithTitle title={t('recap.dirigeant.ppe.fonction')} content={ppe.fonction} />
        {ppe.finFonction && <TextWithTitle title={t('recap.dirigeant.ppe.finFonction')} content={ppe.finFonction} />}
        <TextWithTitle title={t('recap.dirigeant.ppe.pays')} content={ppe.pays.nom} />
      </div>
    )}
    {prochePpe && (
      <div style={style.mentionBloc}>
        <p style={style.mention}>{t('recap.dirigeant.prochePpe')}</p>
        <TextWithTitle title={t('recap.dirigeant.prochePpe.designation')} content={`${prochePpe.prenom} ${prochePpe.nom}`} />
        <TextWithTitle title={t('recap.dirigeant.ppe.fonction')} content={prochePpe.fonction} />
        {prochePpe.finFonction && <TextWithTitle title={t('recap.dirigeant.ppe.finFonction')} content={prochePpe.finFonction} />}
        <TextWithTitle title={t('recap.dirigeant.ppe.pays')} content={prochePpe.pays.nom} />
        <TextWithTitle title={t('recap.dirigeant.prochePpe.lien')} content={prochePpe.lien} />
      </div>
    )}
  </RecapitulatifBloc>
);

RecapitulatifDirigeant.propTypes = {
  ppe: PropTypes.object,
  prochePpe: PropTypes.object,
  t: PropTypes.func.isRequired,
  utilisateur: PropTypes.object.isRequired
};

const mapStateToProps = ({
  tunnel: {
    utilisateur,
    fichePersonneMorale: { ppe, prochePpe }
  }
}) => ({
  utilisateur,
  ppe,
  prochePpe
});

export default connect(mapStateToProps)(withTranslation()(RecapitulatifDirigeant));

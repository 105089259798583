import React, { Component } from "react";
import { connect } from "react-redux";
import { nombreAvecEspaces } from "../../../../common/FunctionsUtils";
import { getSvgEnFonctionDuTenant } from "../../../../services/TenantService";

class ResultatFinal extends Component {
  // constructor(props) {
  //   super(props)
  // }

  render() {
    const { simulateur } = this.props;

    return (
      <div className="grid-x grid-margin-x center-item-simu-container resultat-global">
        <div className="cell medium-12 yes-rectangle">
          <div className="grid-x content-resultat-global grid-margin-x">
            <div className="cell medium-5">
              <div className="yes-text-medium-resultat">
                En choisissant de mettre en place les dispositifs d’épargne
                salariale suivants :{" "}
              </div>

              <div className="yes-text-medium-resultat yes-text-medium-enum-resultat">
                {simulateur.epargneSalariale.dispositif.abondementPEE > 0 && (
                  <div>• Abondement PEE</div>
                )}
                {simulateur.epargneSalariale.dispositif.abondementPERCO > 0 && (
                  <div>• Abondement PERCO</div>
                )}
                {simulateur.epargneSalariale.dispositif.interessement > 0 && (
                  <div>• Intéressement</div>
                )}
                {simulateur.epargneSalariale.dispositif.participation > 0 && (
                  <div>• Participation</div>
                )}
              </div>

              <div className="img-resultat-simulateur">
                <img
                  src={getSvgEnFonctionDuTenant(
                    "/assets/img/illustrations/",
                    "/illu-resultat.svg"
                  )}
                  alt="resultat"
                />
              </div>
            </div>
            <div className="cell medium-7">
              <div className="yes-text-medium-resultat">
                Votre entreprise verse à l’ensemble de vos salariés
                <span className="yes-gras">
                  {" " +
                    nombreAvecEspaces(
                      simulateur.epargneSalariale.dispositif
                        .enveloppeIndividuelle * simulateur.effectif
                    )}{" "}
                  €{" "}
                </span>
              </div>
              <div className="yes-text-medium-resultat cout-global-resultat">
                pour un coût global de{" "}
                <span className="yes-gras">
                  {" " +
                    nombreAvecEspaces(
                      simulateur.epargneSalariale.coutEntreprise
                        .coutTotalEntreprise * simulateur.effectif
                    )}{" "}
                  €
                </span>{" "}
                (primes versées + forfait social).
              </div>

              <div className="yes-text-medium-resultat">
                Le versement d'une prime de rémunération classique aurait coûté
                <span className="yes-gras">
                  {" " +
                    nombreAvecEspaces(
                      simulateur.primeClassique.coutEntreprise
                        .coutTotalEntreprise * simulateur.effectif
                    ) +
                    " "}
                  {"€ "}
                </span>
                à votre entreprise.
              </div>

              <div className="yes-text-medium-resultat economie-resultat">
                Vous réalisez une économie de
              </div>
              <div className="resultat-economie-global">
                {nombreAvecEspaces(
                  (simulateur.primeClassique.coutEntreprise
                    .coutTotalEntreprise -
                    simulateur.epargneSalariale.coutEntreprise
                      .coutTotalEntreprise) *
                    simulateur.effectif
                )}{" "}
                €
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  simulateur: state.simulateur
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(ResultatFinal);

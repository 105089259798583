/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import InteressementForm from '../../../../components/US/epargne_salariale/forms/InteressementForm';
import { URLS_EPARGNE } from '../../../../utils/constantes/ROUTES/EPARGNE';
import { mettreAjourInteressement } from '../../../../services/DossierService';
import { setRetourRoute, setInformationsUtilisateur, setInteressement } from '../../../../modules/tunnel/redux/tunnelActions';
import { stringToBoolean, cloneValues } from '../../../../common/FunctionsUtils';

class EpargneInteressementPage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.props.setRetourRoute(URLS_EPARGNE.DISPOSITIF_ABONDEMENT.substring(1));

    this.state = {
      redirect: false || this.props.tunnel.dossier.planExistant?.accordInteressementEnPlace
    };
  }

  submit(values) {
    let valuesAenvoye = Object.assign(cloneValues(values), {
      interessementActive: stringToBoolean(values.interessement)
    });

    mettreAjourInteressement(this.props.tunnel.dossier.uid, { valuesAenvoye })
      .then((dossier) => {
        this.props.setInteressement(dossier.interessement);

        this.setState({
          redirect: true
        });
      })
      .catch((error) => {
        if (error && error.message) {
          this.setState({
            errorMsg: error.message
          });
        }
      });
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={URLS_EPARGNE.PARTICIPATION} />;
    }

    return (
      <div>
        <InteressementForm onSubmit={this.submit} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setInformationsUtilisateur: (utilisateur) => dispatch(setInformationsUtilisateur(utilisateur)),
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setInteressement: (interessement) => dispatch(setInteressement(interessement))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EpargneInteressementPage);

/* Built-in imports */
import React, { memo } from 'react';

/* Third-party imports */
import PropTypes from 'prop-types'
import { FormControl, Select } from '@mui/material';

/* Internal imports */
import constants from '../../../utils/constantes/DS/OUTLINED_SELECT_FIELD'
import styles from '../../../styles/DS/inputs.styles';

const OutlinedSelect = memo(({ children, size, value, ...other }) => {
  return (
    <FormControl
      fullWidth
      size={size}
      sx={styles.OutlinedSelectFormControl}
    >
      <Select
        autoWidth
        size={size}
        value={value}
        {...other}
      >
        {children}
      </Select>
    </FormControl>
  );
});

OutlinedSelect.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  size: PropTypes.oneOf(Object.values(constants.SIZES)),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

OutlinedSelect.defaultProps= {
  size: constants.SIZES.SMALL,
  value: ''
};

export default  OutlinedSelect;
import React, { Component } from "react";
import { YesButton } from "../../form/Button";
import config from "../../../common/StaticSiteConfig";
import { getSvgEnFonctionDuTenant } from "../../../services/TenantService";

export default class NoMobilePage extends Component {
  redirectToSite = () => {
    window.location.replace(config.siteUrl);
  };

  render() {
    return (
      <div className="noMobileScreen">
        <div className="blason-no-mobile">
          <img
            width="10%"
            src="/assets/img/icons/yes/blason.svg"
            alt="epargne salariale mobile"
          />
        </div>
        <div className="txt-felicitation">
          Félicitations !
          <br />
          Votre compte a bien été créé.
        </div>
        <div className="illu-no-mobile">
          <img
            width="100%"
            src={getSvgEnFonctionDuTenant(
              "/assets/img/illustrations/",
              "/illu-suite-tunnel-desktop.svg"
            )}
            alt="poursuivre tunnel sur ordinateur"
          />
        </div>

        <p className="txt-connexion-desktop">
          Vous pouvez maintenant vous installer confortablement devant votre
          ordinateur, et utiliser vos identifiants pour compléter votre
          souscription.
        </p>

        <div className="white-button">
          <YesButton
            text="Retourner sur le site"
            onClick={this.redirectToSite}
          />
        </div>
      </div>
    );
  }
}

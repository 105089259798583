import React, { Component } from "react";
import { connect } from "react-redux";
import { voirEcranSynthese } from "../../../../modules/simulateur/redux/simulateurActions";
import { nombreAvecEspaces } from "../../../../common/FunctionsUtils";

class Graphiques extends Component {
  render() {
    const { simulateur } = this.props;

    return (
      <div className="details-restitution">
        <div className="grid-x grid-margin-x">
          <div className="cell medium-12 yes-titre-rectangle">
            Économies réalisées - Détails
          </div>

          <div className="cell medium-12 yes-titre-rectangle">
            <div className="titreGraph">Côté entreprise</div>
            <div className="enDetails" onClick={this.props.voirEcranSynthese}>
              Retour à la synthèse
              <img
                src="/assets/img/icons/yes/minus-circular-button.svg"
                alt="detail entreprise"
              />
            </div>
          </div>

          <div className="cell medium-12">
            <table className="yes-table">
              <thead>
                <tr>
                  <th />
                  <th>Prime classique</th>
                  <th>Épargne salariale</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Montant Brut</td>
                  <td>
                    {nombreAvecEspaces(
                      simulateur.primeClassique.primeAttribuee
                    )}{" "}
                    €
                  </td>
                  <td>
                    {nombreAvecEspaces(
                      simulateur.epargneSalariale.dispositif
                        .enveloppeIndividuelle
                    )}{" "}
                    €
                  </td>
                </tr>
                <tr>
                  <td>Charges patronales</td>
                  <td>
                    {nombreAvecEspaces(
                      simulateur.primeClassique.coutEntreprise.chargesPatronales
                    )}{" "}
                    €
                  </td>
                  <td>exonéré</td>
                </tr>
                <tr>
                  <td>Forfait social</td>
                  <td>0 €</td>
                  <td>
                    {nombreAvecEspaces(
                      simulateur.epargneSalariale.coutEntreprise.forfaitSocial
                        .total
                    )}{" "}
                    €
                  </td>
                </tr>
                <tr className="background-green-white-text">
                  <td>Coût pour l’entreprise</td>
                  <td>
                    {nombreAvecEspaces(
                      simulateur.primeClassique.coutEntreprise
                        .coutTotalEntreprise
                    )}{" "}
                    €
                  </td>
                  <td className="yes-gras">
                    {nombreAvecEspaces(
                      simulateur.epargneSalariale.coutEntreprise
                        .coutTotalEntreprise
                    )}{" "}
                    €
                  </td>
                </tr>
              </tbody>
            </table>

            <p className="detail-explication">
              <span>L’économie réalisée par l’entreprise est de </span>
              <span className="yes-green yes-gras">
                {nombreAvecEspaces(
                  simulateur.primeClassique.coutEntreprise.coutTotalEntreprise -
                    simulateur.epargneSalariale.coutEntreprise
                      .coutTotalEntreprise
                )}
              </span>{" "}
              <span className="yes-green">€</span>
            </p>
            <p className="detail-explication">
              Les sommes versées aux salariés à travers l’abondement,
              l’intéressement et la participation sont :
            </p>
            <p className="detail-explication-enum">
              <span>• déductibles du bénéfice imposable de l’entreprise, </span>
              <span>• exonérées de charges patronales.</span>
            </p>
            <p className="detail-explication">
              Dans le cadre de l’épargne salariale, un forfait social peut être
              appliqué à :{" "}
            </p>
            <p className="detail-explication-enum">
              <span>
                • L’abondement PEE à hauteur de 0% si l’entreprise a moins de 50
                salariés, sinon 20%.{" "}
              </span>
              <span>
                • L’abondement PERCO à hauteur de 0% si l’entreprise a moins de
                50 salariés, sinon 16%.{" "}
              </span>
              <span>
                • L’intéressement à hauteur de 0% si l’entreprise a moins de 250
                salariés, sinon 20%.{" "}
              </span>
              <span>
                • La participation à hauteur de 0% si l’entreprise a moins de 50
                salariés, sinon 20%.{" "}
              </span>
            </p>
            {simulateur.epargneSalariale.dispositif.interessement > 0 &&
              simulateur.effectif < 250 && (
                <p className="detail-explication">
                  <span>
                    Votre entreprise compte moins de 250 salariés, vous ne payez
                    donc pas de coût supplémentaire sur le versement de
                    l'intéressement.
                  </span>
                </p>
              )}
            {simulateur.epargneSalariale.dispositif.participation > 0 &&
              simulateur.effectif < 50 && (
                <p className="detail-explication">
                  <span>
                    Votre entreprise compte moins de 50 salariés, vous ne payez
                    donc pas de coût supplémentaire sur le versement de la
                    participation.
                  </span>
                </p>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  simulateur: state.simulateur
});

const mapDispatchToProps = dispatch => ({
  voirEcranSynthese: () => dispatch(voirEcranSynthese())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Graphiques);

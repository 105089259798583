import {
  CONTRACTCATEGORIES,
  REGIMENTS,
  LEGALOBLIGATIONS
} from '../utils/constantes/US/WORKADVICESSHEETSMODAL';

export default function workAdvicesSheetsDeserializer({ isFirstWorkAdvicesSheets, workAdvicesContract, employeesInfos, observationsInfos }, t) {
  const {
      isRetirementContract,
      subscriptionReasons,
      retirementContractOptions,
    } = workAdvicesContract,
    {
      contractCategorySelected,
      contractSubscriptionInfos,
      contractorInfos,
      employeesAgeDistribution,
      isAllEmployeesSubscribe,
      isContractor,
      isEmployeesRegiment,
      isSignatory,
      regimentInfos,
    } = employeesInfos,
    {
      legalObligationSelected,
      particularObservations,
      particularObservationsPensionGuarantees
    } = observationsInfos;

  return {
    isFirstWorkAdvicesSheets: {
      checked: isFirstWorkAdvicesSheets,
      label: t('components.US.form.work-advices-sheets-cgu-form.radio.label'),
    },
    isRetirementContract: {
      checked: isRetirementContract,
      label: t('components.US.form.work-advices-sheets-contract-form.radio.treat.label'),
      values: {
        isRetirementCollectivePerco: getRetirementContractObject(
          retirementContractOptions.isRetirementCollectivePerco,
          'components.US.form.work-advices-sheets-contract-form.radio.treat.checkbox.options.collective.pero.label',
          t
        ),
        isRetirementCollectiveRequiredPerco: getRetirementContractObject(
          retirementContractOptions.isRetirementCollectiveRequiredPerco,
        'components.US.form.work-advices-sheets-contract-form.radio.treat.checkbox.options.required.perco.label',
          t
        ),
        isRetirementCollectivePercol: getRetirementContractObject(
          retirementContractOptions.isRetirementCollectivePercol,
        'components.US.form.work-advices-sheets-contract-form.radio.treat.checkbox.options.collective.percol.label',
          t
        ),
        isRetirementArt83: getRetirementContractObject(
          retirementContractOptions.isRetirementArt83,
        'components.US.form.work-advices-sheets-contract-form.radio.treat.checkbox.options.defined-contributions.label',
          t
        ),
        isRetirementArt39: getRetirementContractObject(
          retirementContractOptions.isRetirementArt39,
        'components.US.form.work-advices-sheets-contract-form.radio.treat.checkbox.options.defined-prestations.label',
          t
        ),
        other: {
          label: t('components.US.form.work-advices-sheets-contract-form.radio.treat.checkbox.options.other.label'),
          value: retirementContractOptions.otherContract
        }
      },
    },
    subscriptionReasons: {
      isPerobToPacte: getSubscriptionReasonObject(
        subscriptionReasons.isPerobToPacte,
      'components.US.form.work-advices-sheets-contract-form.radio.treat.subscription.checkbox.pacte-law',
        t
      ),
      isCapitalToSavings: getSubscriptionReasonObject(
        subscriptionReasons.isCapitalToSavings,
      'components.US.form.work-advices-sheets-contract-form.radio.treat.subscription.checkbox.capital',
        t
      ),
      isPaymentsVoluntaryPunctual: getSubscriptionReasonObject(
        subscriptionReasons.isPaymentsVoluntaryPunctual,
      'components.US.form.work-advices-sheets-contract-form.radio.treat.subscription.checkbox.voluntary-payment',
        t
      ),
      isPaymentsSavings: getSubscriptionReasonObject(
        subscriptionReasons.isPaymentsSavings,
      'components.US.form.work-advices-sheets-contract-form.radio.treat.subscription.checkbox.deposit',
        t
      ),
      isInvestments: getSubscriptionReasonObject(
        subscriptionReasons.isInvestments,
      'components.US.form.work-advices-sheets-contract-form.radio.treat.subscription.checkbox.payment-euros',
        t
      ),
      isManagementChoice: getSubscriptionReasonObject(
        subscriptionReasons.isManagementChoice,
      'components.US.form.work-advices-sheets-contract-form.radio.treat.subscription.checkbox.employee-choice',
        t
      )
    },
    isContractor: {
      checked: isContractor,
      label: t('components.US.form.work-advices-sheets-employees.contractor-sub-form.representative.label'),
    },
    isEmployeesRegiment: {
      checked: isEmployeesRegiment,
      label: t('components.US.form.work-advices-sheets-employees.employees-sub-form.retirement.label')
    },
    isSignatory: {
      checked: isSignatory,
      label: t('components.US.form.work-advices-sheets-employees.employees-subscription-sub-form.signatory')
    },
    isAllEmployeesSubscribe: {
      checked: isAllEmployeesSubscribe,
      label: t('components.US.form.work-advices-sheets-employees.employees-subscription-sub-form.contract'),
    },
    contractCategorySelected: {
      cadre: {
        values: getCadreContractCategorySelected(contractSubscriptionInfos.cadre, t),
      },
      noCadre: {
        values: getNoCadreContractCategorySelected(contractSubscriptionInfos.noCadre, t),
      }
    },
    contractorInfos: {
      firstname: contractorInfos.firstname,
      lastname: contractorInfos.lastname,
      job: contractorInfos.job,
    },
    regimentInfos: {
      regimentSelected: {
        dues: {
          checked: isEmployeesRegiment && regimentInfos.regimentSelected === REGIMENTS.DUES,
          label: t('components.US.form.work-advices-sheets-employees.employees-sub-form.retirement.select.regime.dues'),
        }
      },
      employeesAverageAge: {
        value: regimentInfos.employeesAverageAge,
        label: t('components.US.form.work-advices-sheets-employees.employees-sub-form.age-average.label')
      },
      employeesCount: {
        value: regimentInfos.employeesCount,
        label: t('components.US.form.work-advices-sheets-employees.employees-sub-form.employees.count.label')
      },
      iddc: {
        value: regimentInfos.iddc,
        label: t('components.US.form.work-advices-sheets-employees.employees-sub-form.iddc.label')
      },
      conventionName: {
        value: regimentInfos.conventionName,
        label: t('components.US.form.work-advices-sheets-employees.employees-sub-form.convention.label')
      },
    },
    employeesAgeDistribution: {
      ageCount0to10: {
        value: employeesAgeDistribution.ageCount0to10,
        label: t('components.US.form.work-advices-sheets-employees.employees-age-count-sub-form.seniority.0to10')
      },
      ageCount11to15: {
        value: employeesAgeDistribution.ageCount11to15,
        label: t('components.US.form.work-advices-sheets-employees.employees-age-count-sub-form.seniority.11to15')
      },
      ageCount16to20: {
        value: employeesAgeDistribution.ageCount16to20,
        label: t('components.US.form.work-advices-sheets-employees.employees-age-count-sub-form.seniority.16to20')
      },
      ageCount21to30: {
        value: employeesAgeDistribution.ageCount21to30,
        label: t('components.US.form.work-advices-sheets-employees.employees-age-count-sub-form.seniority.21to30')
      },
      ageCount30More: {
        value: employeesAgeDistribution.ageCount30More,
        label: t('components.US.form.work-advices-sheets-employees.employees-age-count-sub-form.seniority.more30')
      },
    },
    legalObligationSelected: {
      legalObligations: {
        checked: legalObligationSelected === LEGALOBLIGATIONS.LEGALOBLIGATIONS,
        label: t('components.US.form.work-advices-sheets-observations-form.wish.legal-obligations'),
      },
      beyondLegalObligations: {
        checked: legalObligationSelected === LEGALOBLIGATIONS.BEYONDLEGALOBLIGATIONS,
        label: t('components.US.form.work-advices-sheets-observations-form.wish.beyond-legal-obligations'),
      },
    },
    particularObservations: {
      value: particularObservations,
      label: t('components.US.form.work-advices-sheets-observations-form.particular-observations.label')
    },
    particularObservationsPensionGuarantees: {
      value: particularObservationsPensionGuarantees,
      label: t('components.US.form.work-advices-sheets-observations-form.particular-observations-pension-guarantees.label')
    }
  };
}

//Utils
function getRetirementContractObject(retirementContractChecked, tradKey, t) {
  return {
    checked: retirementContractChecked,
    label: t(tradKey)
  }
}

function getSubscriptionReasonObject(subscriptionReasonChecked, tradKey, t) {
  return {
    checked: subscriptionReasonChecked,
    label: t(tradKey),
  }
}

function getCadreContractCategorySelected(cadreContractSubscriptionInfos, t) {
  const tradKey = 'components.US.form.work-advices-sheets-employees.employees-subscription-sub-form';

  return {
    art21: {
      checked: cadreContractSubscriptionInfos.art21.checked,
      label: t(`${tradKey}.employees.category.art21`),
      employeesCount: cadreContractSubscriptionInfos.art21.employeesCount,
    },
    art2122: {
      checked: cadreContractSubscriptionInfos.art2122.checked,
      label: t(`${tradKey}.employees.category.art2122`),
      employeesCount: cadreContractSubscriptionInfos.art2122.employeesCount,
    },
    art2122Br: {
      checked: cadreContractSubscriptionInfos.art2122Br.checked,
      label: t(`${tradKey}.employees.category.art2122br`),
      employeesCount: cadreContractSubscriptionInfos.art2122Br.employeesCount,
    },
    other: {
      label: t(`${tradKey}.employees.category.other.label`),
      value: cadreContractSubscriptionInfos.other
    },
  };
}

function getNoCadreContractCategorySelected(noCadreContractSubscriptionInfos, t) {
  const tradKey = 'components.US.form.work-advices-sheets-employees.employees-subscription-sub-form';

  return {
    art21: {
      checked: noCadreContractSubscriptionInfos.art21.checked,
      label: t(`${tradKey}.employees.category.no-cadre.art21`),
      employeesCount: noCadreContractSubscriptionInfos.art21.employeesCount,
    },
    art2122: {
      checked: noCadreContractSubscriptionInfos.art2122.checked,
      label: t(`${tradKey}.employees.category.no-cadre.art2122`),
      employeesCount: noCadreContractSubscriptionInfos.art2122.employeesCount,
    },
    art2122Br: {
      checked: noCadreContractSubscriptionInfos.art2122Br.checked,
      label: t(`${tradKey}.employees.category.no-cadre.art2122br`),
      employeesCount: noCadreContractSubscriptionInfos.art2122Br.employeesCount,
    }
  };
}
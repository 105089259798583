import React, { Component } from 'react';

export class YesDropdown extends Component {
  renderSelectOptions = (item) => (
    <option key={item.value} value={item.value}>
      {item.label}
    </option>
  );

  render() {
    const { input, label, item } = this.props;
    return (
      <div className="divDropdown">
        <label className="labelDropDown">{label}</label>
        {/* <label htmlFor={label}>{label}</label> */}
        <select className="dropdown" {...input}>
          {/* <option value="">Select</option> */}
          {item.map(this.renderSelectOptions)}
        </select>
      </div>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux';

import Notification from './Notification';

const NotificationContainer = ({ notification }) => (
  <div className="notification-container">
    <Notification {...notification} key={notification.id} />
  </div>
);

const mapStateToProps = ({ notification }) => ({
  notification
});

export default connect(mapStateToProps)(NotificationContainer);

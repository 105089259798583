/* built-in imports */
import React, { useEffect, useMemo } from 'react';

/* third-party imports */
import { Field, FieldArray, formValueSelector, getFormSyncErrors, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

/* internal imports */
import SelectCountries from '../../../../form/SelectCountries';
import Stockholders from './Stockholders';
import YesInput from '../../../../form/Input';
import YesNoSelector from '../../../../form/YesNoSelector';
import validate from './kycForm.validator';
import { BOOLEAN } from '../../../../../common/constants';
import { Required, isBicValid, isMaxOneHundred, isValidNumberString, requiredValidator } from '../../../../../common/Validators';
import { YesButton } from '../../../../form/Button';
import { YesCheckboxV2 } from '../../../../form/CheckboxV2';
import { initKycFields } from './kycMapper';

const KycForm = ({
                   change,
                   invalid,
                   pristine,
                   isBanque,
                   submitting,
                   donneesKyc,
                   regulateur,
                   typeDossier,
                   handleSubmit,
                   isBonsPorteur,
                   synchronousError,
                   isEntrepriseCotee,
                   isSameCompanyAddress,
                   needPaysPersonneMorale
                 }) => {

  useEffect(() => {
    initKycFields(donneesKyc, change, typeDossier);
    // launch only at component did mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisabled = useMemo(
    () =>
      invalid ||
      submitting ||
      pristine ||
      synchronousError.nbActionnaires ||
      (synchronousError.actionnaires && synchronousError.actionnaires.length),
    [invalid, submitting, pristine, synchronousError.nbActionnaires, synchronousError.actionnaires]
  );

  return (
    <form className="formDispositifAbondement grid-container kyc-class" onSubmit={handleSubmit}>
      <label className="titreFormulaire">
        <Trans i18nKey="kyc.page.title" />
      </label>
      <div className="grid-x grid-margin-x grid-margin-y fields-area">
        <div className="yes-label cell medium-8">Adresse postale de l'entreprise :</div>
        <div className="cell medium-4">
          <Field
            id="isSameCompanyAddress"
            name="isSameCompanyAddress"
            component={YesCheckboxV2}
            type="checkbox"
            labeltext="Identique au siège social"
          />
        </div>
        {!isSameCompanyAddress && (
          <>
            <div className="cell medium-4 text-field  ">
              <Field
                id="adressePostaleEntrepriseRue"
                name="adressePostaleEntrepriseRue"
                component={YesInput}
                labeltext={"N° et rue de l'entreprise"}
                validate={requiredValidator}
              />
            </div>
            <div className="cell medium-4 text-field  ">
              <Field
                id="adressePostaleEntrepriseCode"
                name="adressePostaleEntrepriseCode"
                component={YesInput}
                labeltext={"Code postal de l'entreprise"}
                validate={requiredValidator}
              />
            </div>
            <div className="cell medium-4 text-field  ">
              <Field
                id="adressePostaleEntrepriseVille"
                name="adressePostaleEntrepriseVille"
                component={YesInput}
                labeltext={"Ville de l'entreprise"}
                validate={requiredValidator}
              />
            </div>
          </>
        )}
      </div>
      <div className="grid-x grid-margin-x grid-margin-y fields-area">
        <div className="cell medium-6 text-field formCountries">
          <Field
            id="paysFiscalEntreprise"
            name="paysFiscalEntreprise"
            component={SelectCountries}
            labeltext={"Pays fiscal de l'entreprise"}
            validate={requiredValidator}
          />
        </div>
      </div>

      <YesNoSelector label="L'entreprise est un établissement bancaire" fieldName="isBanque" fieldValue={isBanque || BOOLEAN.NO}>
        <div className="grid-x grid-margin-x grid-margin-y">
          <div className="cell medium-6 text-field  ">
            <Field
              id="codeBIC"
              name="codeBIC"
              component={YesInput}
              labeltext="Code d’identification BIC"
              validate={[Required, isBicValid]}
            />
          </div>
        </div>
      </YesNoSelector>
      <YesNoSelector
        label="Entreprise exerçant une activité relevant d’un régulateur"
        fieldName="regulateur"
        fieldValue={regulateur || BOOLEAN.NO}
      >
        <div className="grid-x grid-margin-x grid-margin-y">
          <div className="cell medium-6 text-field  ">
            <Field
              id="nomRegulateur"
              name="nomRegulateur"
              component={YesInput}
              validate={requiredValidator}
              labeltext="Nom du régulateur"
            />
          </div>
          <div className="cell medium-6 text-field  ">
            <Field
              id="referenceAgrement"
              name="referenceAgrement"
              component={YesInput}
              validate={requiredValidator}
              labeltext="Référence de son agrément"
            />
          </div>
        </div>
      </YesNoSelector>
      <YesNoSelector label="Entreprise cotée" fieldName="isEntrepriseCotee" fieldValue={isEntrepriseCotee || BOOLEAN.NO}>
        <div className="grid-x grid-margin-x grid-margin-y">
          <div className="cell medium-6 formCountries">
            <Field
              id="paysCotation"
              name="paysCotation"
              component={SelectCountries}
              labeltext="Pays de cotation"
              validate={Required}
            />
          </div>
          <div className="cell medium-6">
            <Field
              id="entrepriseCotee"
              name="entrepriseCotee"
              component={YesInput}
              validate={requiredValidator}
              labeltext="Marché de cotation"
            />
          </div>
        </div>
      </YesNoSelector>
      <YesNoSelector
        label="Structure composée de bons au porteur"
        fieldName="isBonsPorteur"
        fieldValue={isBonsPorteur || BOOLEAN.NO}
      >
        <div className="grid-x grid-margin-x grid-margin-y">
          <div className="cell medium-6 text-field  ">
            <Field
              id="bonsPorteur"
              name="bonsPorteur"
              component={YesInput}
              inputMode="numeric"
              numberfield={'true'}
              validate={[Required, isValidNumberString]}
              labeltext="pourcentage"
            />
          </div>
        </div>
      </YesNoSelector>
      <YesNoSelector
        label="L'actionnaire principal est une personne morale dont l'adresse fiscale n'est pas en France"
        fieldName="needPaysPersonneMorale"
        fieldValue={needPaysPersonneMorale || BOOLEAN.NO}
      >
        <div className="grid-x grid-margin-x grid-margin-y">
          <div className="cell medium-6 text-field formCountries">
            <Field
              id="paysPersonneMorale"
              name="paysPersonneMorale"
              component={SelectCountries}
              validate={requiredValidator}
              labeltext="pays de l'adresse fiscale"
              customError={synchronousError.paysPersonneMorale}
            />
          </div>
        </div>
      </YesNoSelector>

      <div className="grid-x grid-margin-x grid-margin-y fields-area">
        <div className="cell medium-6 formCountries">
          <Field id="pays" name="pays" component={SelectCountries} validate={[Required]} labeltext="Pays" />
        </div>
        <div className="cell medium-6">
          <Field
            id="chiffreAffaire"
            name="chiffreAffaire"
            component={YesInput}
            validate={[Required, isValidNumberString, isMaxOneHundred]}
            inputMode="numeric"
            numberfield={'true'}
            labeltext="% du chiffre d'affaire"
          />
        </div>
        <div className="cell medium-6 text-field  ">
          <Field
            id="principauxMarches"
            name="principauxMarches"
            component={YesInput}
            labeltext="Principaux marchés"
            validate={requiredValidator}
          />
        </div>
        <div className="cell medium-6 text-field ">
          <Field
            id="principauxPays"
            name="principauxPays"
            component={YesInput}
            labeltext="Principaux pays où les fournisseurs sont situés"
            validate={requiredValidator}
          />
        </div>
      </div>
      <FieldArray name="actionnaires" component={Stockholders} meta={{ error: synchronousError.actionnairesArrayErrors }} />
      {
        synchronousError && synchronousError.nbActionnaires && typeof synchronousError.nbActionnaires === 'string' && (
          <p>
            <span className="errorMsgInput">{synchronousError.nbActionnaires}</span>
          </p>
        )
      }
      <div className="buttonCreationContainer">
        <YesButton text="Confirmer" disabled={isDisabled} />
      </div>
    </form >
  );
};

const selectorForm = formValueSelector('annexeKyc');

const mapStateToProps = (state) => ({
  tunnel: state.tunnel,
  typeDossier: state.tunnel.dossier.type,
  donneesKyc: state.tunnel.dossier.donneesAnnexeKYC,
  qualite: selectorForm(state, 'qualite'),
  isBanque: selectorForm(state, 'isBanque'),
  regulateur: selectorForm(state, 'regulateur'),
  isBonsPorteur: selectorForm(state, 'isBonsPorteur'),
  isEntrepriseCotee: selectorForm(state, 'isEntrepriseCotee'),
  isSameCompanyAddress: selectorForm(state, 'isSameCompanyAddress'),
  needPaysPersonneMorale: selectorForm(state, 'needPaysPersonneMorale'),
  hasActivityUE: selectorForm(state, 'hasActivityUE'),
  hasActivityHorsUE: selectorForm(state, 'hasActivityHorsUE'),
  synchronousError: getFormSyncErrors('annexeKyc')(state)
});

export default connect(mapStateToProps)(reduxForm({ form: 'annexeKyc', validate })(KycForm));

/* Built in imports */
import React from 'react';

/* Third party imports */
import PropTypes from 'prop-types';

const AddContributionLevel = ({ handleAdd, isEnabled = true, additionnalClass, contributionTypeLabel }) =>
  isEnabled && (
    <div className={`boutonAjouterPalier${additionnalClass ? ` ${additionnalClass}` : ''}`} onClick={handleAdd}>
      <img className="ajoutPalierLogo" src="/assets/img/icons/ges/red-add.svg" alt="information tooltip" />
      {`Ajouter un palier d’${contributionTypeLabel}`}
    </div>
  );

AddContributionLevel.propTypes = {
  isEnabled: PropTypes.bool,
  additionnalClass: PropTypes.string,
  handleAdd: PropTypes.func.isRequired,
  contributionTypeLabel: PropTypes.string.isRequired
};

export default AddContributionLevel;

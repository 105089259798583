import React, { Component } from "react";

export class YesButton extends Component {
  render() {
    const { text, disabled, cssClass, onClick } = this.props;

    let typeButton = "submit";
    let yesButtonClass = "yes-button";
    if (disabled) {
      typeButton = "text";
      yesButtonClass = "yes-button yes-button-disabled";
    }

    return (
      <span>
        <button
          onClick={onClick}
          type={typeButton}
          disabled={disabled}
          className={yesButtonClass + " " + cssClass}
        >
          {text}
        </button>
      </span>
    );
  }
}

YesButton.defaultProps = {
  cssClass: ""
};

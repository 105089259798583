import React, { Component } from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import YesInputWithoutReduxForm from '../../../form/InputWithoutReduxForm';
import { connect } from 'react-redux';
import { nombreSansEspaces, nombre, nombreAvecEspaces } from '../../../../common/FunctionsUtils';

import {
  plafondAbondementPEE,
  plafondAbondementPERCO,
  plafondInteressement,
  plafondParticipation
} from '../../../../modules/simulateur/fonctions';

import {
  setAbondementPEE,
  setAbondementPERCO,
  setInteressement,
  setParticipation,
  voirInformationPlafondInteressementAtteint,
  animerGraphiques
} from '../../../../modules/simulateur/redux/simulateurActions';

import Slider from '@appigram/react-rangeslider';
import '@appigram/react-rangeslider/lib/index.css';
import TooltipInfo from '../../../../utils/US/components/TooltipInfo';

export class Curseurs extends Component {
  constructor(props) {
    super(props);

    this.changerValeurChampPee = this.changerValeurChampPee.bind(this);
    this.changerValeurChampPerco = this.changerValeurChampPerco.bind(this);
    this.changerValeurChampIntessement = this.changerValeurChampIntessement.bind(this);
    this.changerValeurChampParticipation = this.changerValeurChampParticipation.bind(this);

    this.changerValeurSliderPee = this.changerValeurSliderPee.bind(this);
    this.changerValeurSliderPerco = this.changerValeurSliderPerco.bind(this);
    this.changerValeurSliderInteressement = this.changerValeurSliderInteressement.bind(this);
    this.changerValeurSliderParticipation = this.changerValeurSliderParticipation.bind(this);

    this.state = {
      left: 0
    };
    this.myRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.state.left !== nombre(this.myRef.current.handle.style.left.replace(/px/g, ''))) {
      this.updateLeftPositionInfoBulleInteressement();
    }
  }

  changerValeurChampPee = (event) => {
    if (event.target.validity.valid) {
      let nbPee = nombre(nombreSansEspaces(event.target.value));
      if (event.target.value === '') {
        nbPee = 0;
      }
      if (nbPee > plafondAbondementPEE()) {
        nbPee = plafondAbondementPEE();
      }
      this.props.setAbondementPEE(nbPee);
    }
  };

  changerValeurChampPerco = (event) => {
    if (event.target.validity.valid) {
      let nbPerco = nombre(nombreSansEspaces(event.target.value));
      if (event.target.value === '') {
        nbPerco = 0;
      }
      if (nbPerco > plafondAbondementPERCO()) {
        nbPerco = plafondAbondementPERCO();
      }
      this.props.setAbondementPERCO(nbPerco);
    }
  };

  changerValeurChampIntessement = (event) => {
    if (event.target.validity.valid) {
      let int = nombre(nombreSansEspaces(event.target.value));
      if (event.target.value === '') {
        int = 0;
      }
      if (int > plafondInteressement()) {
        int = plafondInteressement();
      }
      // this.updateLeftPositionInfoBulleInteressement()
      this.props.voirInformationPlafondInteressementAtteint(true);
      this.props.setInteressement(int);
    }
  };

  changerValeurChampParticipation = (event) => {
    if (event.target.validity.valid) {
      let part = nombre(nombreSansEspaces(event.target.value));
      if (event.target.value === '') {
        part = 0;
      }
      if (part > plafondParticipation()) {
        part = plafondParticipation();
      }
      this.props.setParticipation(part);
    }
  };

  changerValeurSliderPee = (value) => {
    this.props.setAbondementPEE(value);
  };

  changerValeurSliderPerco = (value) => {
    this.props.setAbondementPERCO(value);
  };

  changerValeurSliderInteressement = (value) => {
    // this.updateLeftPositionInfoBulleInteressement()
    this.props.voirInformationPlafondInteressementAtteint(true);
    this.props.setInteressement(value);
  };

  changerValeurSliderParticipation = (value) => {
    this.props.setParticipation(value);
  };

  updateLeftPositionInfoBulleInteressement(previousProps, previousState) {
    if (this.props.simulateur.epargneSalariale.dispositif.depassement20pourcentMasseSalariale) {
      let leftValue = nombre(this.myRef.current.handle.style.left.replace(/px/g, ''));
      if (leftValue !== this.state.left) {
        this.changeLeftValue(leftValue);
      }
    }
  }

  changeLeftValue = (c) => {
    this.setState({
      left: c
    });
  };

  render() {
    const { simulateur } = this.props;

    // if (simulateur.budget !== undefined) {
    //   this.props.change('enveloppeIndividuelle', simulateur.budget.parPersonne)
    // }

    const peeInput = nombreAvecEspaces(simulateur.epargneSalariale.dispositif.abondementPEE);
    const percoInput = nombreAvecEspaces(simulateur.epargneSalariale.dispositif.abondementPERCO);
    const intInput = nombreAvecEspaces(simulateur.epargneSalariale.dispositif.interessement);
    const partInput = nombreAvecEspaces(simulateur.epargneSalariale.dispositif.participation);

    return (
      <div className="grid-x curseurs">
        <div className="cell medium-12 yes-titre-rectangle">Dispositifs recommandés</div>
        <div className="cell medium-12 yes-rectangle rectangle-curseurs">
          <form autoComplete="off" className="grid-x grid-margin-x formCurseurs">
            <div className="cell medium-12 titreTypePrime">
              <span>Prime à l’épargne (abondement)</span>
              <TooltipInfo
                tooltipContent={
                    "Compléter le versement d’un salarié dans son PEE, jusqu’à 8% du PASS ou dans son PERCO, jusqu’à 16% du PASS."
                }
              />
            </div>
            {/* PEE */}
            <div className="cell medium-8 sliderDiv abondementSlider">
              <span className="yes-indication plafondPrime">{nombreAvecEspaces(plafondAbondementPEE())} €</span>
              <div className="cell medium-12 sousTitreTypePrime">PEE</div>
              <Slider
                value={simulateur.epargneSalariale.dispositif.abondementPEE}
                orientation="horizontal"
                onChange={this.changerValeurSliderPee}
                tooltip={false}
                max={plafondAbondementPEE()}
              />
            </div>
            <div className="cell medium-4 fieldAbondement">
              <YesInputWithoutReduxForm value={peeInput} onChange={this.changerValeurChampPee} euroField={true} />
            </div>

            {/* PERCO */}
            <div className="cell medium-8 sliderDiv abondementSlider">
              <span className="yes-indication plafondPrime">{nombreAvecEspaces(plafondAbondementPERCO())} €</span>
              <div className="cell medium-12 sousTitreTypePrime">PERCO</div>
              <Slider
                value={simulateur.epargneSalariale.dispositif.abondementPERCO}
                orientation="horizontal"
                onChange={this.changerValeurSliderPerco}
                tooltip={false}
                max={plafondAbondementPERCO()}
              />
            </div>
            <div className="cell medium-4 fieldAbondement">
              <YesInputWithoutReduxForm value={percoInput} onChange={this.changerValeurChampPerco} euroField={true} />
            </div>

            <div className="cell medium-12 titreTypePrime titreInt">
              <span>Prime à la performance (intéressement)</span>
              <TooltipInfo
                tooltipContent={
                    "Associer les salariés au développement de l’entreprise, sur la base de critères librement choisis et objectivement mesurables, jusqu’à 75% du PASS."
                }
              />
            </div>

            {/* INT */}
            <div
              className="cell medium-8 sliderDiv sliderInt"
              onMouseOver={() => {
                this.props.voirInformationPlafondInteressementAtteint(true);
              }}
              onMouseOut={() => {
                this.props.voirInformationPlafondInteressementAtteint(false);
              }}
            >
              <span className="yes-indication plafondPrime">{nombreAvecEspaces(plafondInteressement())} €</span>

              {this.props.simulateur.ecran.voirInformationPlafondInteressementAtteint &&
                simulateur.epargneSalariale.dispositif.depassement20pourcentMasseSalariale && (
                  <div className="yes-tooltip tooltipNoForm">
                    <div className="tooltip-container">
                      <div className="input-tooltip" style={{ left: -120 + this.state.left }}>
                        La prime à la performance (interessement) ne peut excéder 20% de votre masse salariale.
                      </div>
                    </div>
                  </div>
                )}

              <Slider
                ref={this.myRef}
                value={simulateur.epargneSalariale.dispositif.interessement}
                orientation="horizontal"
                onChange={this.changerValeurSliderInteressement}
                tooltip={false}
                max={plafondInteressement()}
              />
            </div>
            <div className="cell medium-4">
              <YesInputWithoutReduxForm value={intInput} onChange={this.changerValeurChampIntessement} euroField={true} />
              <span className="percentSalaire">
                soit{' '}
                <span className="yes-green">
                  {nombreAvecEspaces(
                    Math.round(
                      (simulateur.epargneSalariale.dispositif.interessement / (simulateur.masseSalariale / simulateur.effectif)) *
                        100
                    )
                  )}
                </span>{' '}
                % du salaire
              </span>
            </div>

            <div className="cell medium-12 titreTypePrime titrePart">
              <span>Prime au résultat (participation)</span>
              <TooltipInfo
                tooltipContent={
                    "Attribuer aux salariés une part des bénéfices annuels. Obligatoire pour les entreprises de plus de 50 salariés, jusqu’à 75% du PASS."
                }
              />
            </div>

            {/* PART */}
            <div className="cell medium-8 sliderDiv">
              <span className="yes-indication plafondPrime">{nombreAvecEspaces(plafondParticipation())} €</span>
              <Slider
                value={simulateur.epargneSalariale.dispositif.participation}
                orientation="horizontal"
                onChange={this.changerValeurSliderParticipation}
                tooltip={false}
                max={plafondParticipation()}
              />
            </div>
            <div className="cell medium-4">
              <YesInputWithoutReduxForm value={partInput} onChange={this.changerValeurChampParticipation} euroField={true} />
              <span className="percentSalaire">
                soit{' '}
                <span className="yes-green">
                  {Math.round(
                    (simulateur.epargneSalariale.dispositif.participation / (simulateur.masseSalariale / simulateur.effectif)) * 100
                  )}
                </span>{' '}
                % du salaire
              </span>
            </div>
          </form>
        </div>

        {/* ENVELOPPE INDIVIDUELLE */}
        <div className="cell medium-12 yes-rectangle rectangle-enveloppe background-green">
          <div className="grid-x grid-margin-x">
            <div className="cell medium-8 titreTypePrime white">Enveloppe individuelle</div>
            <div className="cell medium-4">
              <YesInputWithoutReduxForm
                value={nombreAvecEspaces(simulateur.epargneSalariale.dispositif.enveloppeIndividuelle)}
                euroField={true}
                onChange={() => {}}
                disabled={true}
              />
            </div>
            <div className="cell medium-12 infosPrimes">
              Soit prime fixe :{' '}
              {nombreAvecEspaces(
                nombre(simulateur.epargneSalariale.dispositif.abondementPEE) +
                  nombre(simulateur.epargneSalariale.dispositif.abondementPERCO)
              )}{' '}
              €
              <br />
              et prime variable :{' '}
              {Math.round(
                ((nombre(simulateur.epargneSalariale.dispositif.interessement) +
                  nombre(simulateur.epargneSalariale.dispositif.participation)) /
                  (simulateur.masseSalariale / simulateur.effectif)) *
                  100
              )}{' '}
              %
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// REDUX + REDUX FORM
const mapDispatchToProps = (dispatch) => ({
  setAbondementPEE: (pee) => dispatch(setAbondementPEE(pee)),
  setAbondementPERCO: (perco) => dispatch(setAbondementPERCO(perco)),
  setInteressement: (int) => dispatch(setInteressement(int)),
  setParticipation: (part) => dispatch(setParticipation(part)),
  voirInformationPlafondInteressementAtteint: (voir) => dispatch(voirInformationPlafondInteressementAtteint(voir)),
  animerGraphiques: (animer) => dispatch(animerGraphiques(animer))
});

Curseurs = reduxForm({ form: 'curseurs' })(Curseurs);

const selectCurseursForm = formValueSelector('curseurs');

Curseurs = connect(
  (state) => {
    const peeValueInput = selectCurseursForm(state, 'pee');
    const percoValueInput = selectCurseursForm(state, 'perco');
    const intValueInput = selectCurseursForm(state, 'int');
    const partValueInput = selectCurseursForm(state, 'part');

    const simulateur = state.simulateur;

    return {
      simulateur,
      peeValueInput,
      percoValueInput,
      intValueInput,
      partValueInput
    };
  },
  mapDispatchToProps
)(Curseurs);

export default Curseurs;

import React, { Component } from "react";
import { connect } from "react-redux";
import { YesDropdown } from "../../../form/Dropdown";
import { Field, reduxForm } from "redux-form";
import {
  tauxChargesPatronales,
  tauxImposition,
  tauxChargesSalariales,
  tauxCsg
} from "../../../../services/TauxService";
import {
  setTauxMarginalDImposition,
  setTauxChargesSalariales,
  setTauxChargesPatronales,
  animerGraphiques
} from "../../../../modules/simulateur/redux/simulateurActions";
import YesInputWithoutReduxForm from "../../../form/InputWithoutReduxForm";

class Charges extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tauxChargesPatronales: tauxChargesPatronales,
      tauxChargesSalariales: tauxChargesSalariales,
      tauxImposition: tauxImposition,
      panelOuvert: false
    };

    this.props.change(
      "tauxImposition",
      this.props.simulateur.taux.tauxMarginalDImposition
    );
    this.props.change(
      "tauxChargesSalariales",
      this.props.simulateur.taux.tauxChargesSalariales
    );
    this.props.change(
      "tauxChargesPatronales",
      this.props.simulateur.taux.tauxChargesPatronales
    );

    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel() {
    if (this.state.panelOuvert) {
      this.setState({ panelOuvert: false });
    } else {
      this.setState({ panelOuvert: true });
    }
  }

  changerTauxImposition = event => {
    setTimeout(
      function() {
        this.props.animerGraphiques(true);
      }.bind(this),
      100
    );
    this.props.setTauxMarginalDImposition(parseFloat(event.target.value));
    setTimeout(
      function() {
        this.props.animerGraphiques(false);
      }.bind(this),
      100
    );
  };

  changerTauxChargesSalariales = event => {
    setTimeout(
      function() {
        this.props.animerGraphiques(true);
      }.bind(this),
      100
    );
    this.props.setTauxChargesSalariales(parseFloat(event.target.value));
    setTimeout(
      function() {
        this.props.animerGraphiques(false);
      }.bind(this),
      100
    );
  };

  changerTauxChargesPatronales = event => {
    setTimeout(
      function() {
        this.props.animerGraphiques(true);
      }.bind(this),
      100
    );
    this.props.setTauxChargesPatronales(parseFloat(event.target.value));
    setTimeout(
      function() {
        this.props.animerGraphiques(false);
      }.bind(this),
      100
    );
  };

  render() {
    const { panelOuvert } = this.state;
    const cssClass = panelOuvert ? "panelChargeOuvert" : "";
    const cssClassBtn = panelOuvert ? "btnOuvrirPanelChargesOuvert" : "";

    return (
      <div className="cell medium-12 grid-charges">
        <div className={"btnOuvrirPanelCharges " + cssClassBtn}>
          <div>
            <img
              src="/assets/img/icons/yes/down-chevron.svg"
              alt="charges"
              onClick={this.togglePanel}
            />
          </div>
        </div>

        <div className={"panelCharges " + cssClass}>
          <div className="choixCharges">
            <div className="chargeTx">
              <Field
                id="tauxImposition"
                name="tauxImposition"
                label="• Taux marginal d’imposition"
                component={YesDropdown}
                item={this.state.tauxImposition}
                onChange={this.changerTauxImposition}
              />
            </div>
            <div className="chargeTx">
              <Field
                id="tauxChargesPatronales"
                name="tauxChargesPatronales"
                label="• Charges patronales"
                component={YesDropdown}
                item={this.state.tauxChargesPatronales}
                onChange={this.changerTauxChargesPatronales}
              />
            </div>
            <div className="chargeTx">
              <Field
                id="tauxChargesSalariales"
                name="tauxChargesSalariales"
                label="• Charges salariales"
                component={YesDropdown}
                item={this.state.tauxChargesSalariales}
                onChange={this.changerTauxChargesSalariales}
              />
            </div>
            <div className="chargeTx">
              <label>• CSG-CRDS</label>
              <YesInputWithoutReduxForm
                disabled={true}
                value={(tauxCsg * 100).toFixed(1) + "%"}
                onChange={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  simulateur: state.simulateur
});

const mapDispatchToProps = dispatch => ({
  setTauxMarginalDImposition: tx => dispatch(setTauxMarginalDImposition(tx)),
  setTauxChargesSalariales: tx => dispatch(setTauxChargesSalariales(tx)),
  setTauxChargesPatronales: tx => dispatch(setTauxChargesPatronales(tx)),
  animerGraphiques: animer => dispatch(animerGraphiques(animer))
});

Charges = reduxForm({ form: "charges", enableReinitialize: true })(Charges);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(Charges);

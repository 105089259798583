/* Built-in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';

/* Internal imports */
import YesInput from '../../../../form/Input';

const pourcent = (name) => (value, previousValue, allValues) => {
  let nextValue = value && value.match(/[0-9]/) ? parseInt(value, 10) : '';
  nextValue = nextValue <= 100 ? nextValue : previousValue;
  const maxValue = 100 - (parseInt(allValues[name], 10) || 0);
  return nextValue <= maxValue ? nextValue : maxValue;
};

const mustBeCovered = (_, allValues) =>
  (allValues.repartitionEntreprise + allValues.repartitionBeneficiaire === 100)
    ? undefined
    : "L'intégralité des frais doit être réparti";

const EpargneRepartitionFees = ({ disabled = false }) => {
  const { t } = useTranslation();

  return (
    <div className="repartition ges-tarification-inline-display inputText">
      <div className="repartition-block ges-tarification-inline-display inputText">
        <span>{t('ges-tarification.fraisEntree.question.cibleFrais3.repartitionEntreprise')}</span>
        <Field
          disabled={disabled}
          inputMode="number"
          name="repartitionEntreprise"
          component={YesInput}
          value="repartitionEntreprise"
          validate={disabled ? [] : [mustBeCovered]}
          normalize={pourcent('repartitionBeneficiaire')}
        />
        <span>%</span>
      </div>
      <div className="repartition repartition-block ges-tarification-inline-display inputText">
        <span>{t('ges-tarification.fraisEntree.question.cibleFrais3.repartitionBeneficiaire')}</span>
        <Field
          disabled={disabled}
          inputMode="number"
          name="repartitionBeneficiaire"
          component={YesInput}
          value="repartitionBeneficiaire"
          validate={disabled ? [] : [mustBeCovered]}
          normalize={pourcent('repartitionEntreprise')}
        />
        <span>%</span>
      </div>
    </div>
  );
};

EpargneRepartitionFees.propTypes = {
  disabled: PropTypes.bool
};

export default EpargneRepartitionFees;

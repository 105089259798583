/** ---- Common ---- */
const BENEFICIAIRE_EFFECTIF_FIELDS_COMMON = {
  CIVILITE: 'civilite',
  NOM: 'nom',
  PRENOM: 'prenom',
  NOM_NAISSANCE: 'nomNaissance',
  ACTIVITE: 'activiteBeneficiaire',
  FONCTION_DIRIGEANT: 'fonctionDirigeant',
  IS_PERSONNE_MORALE: 'beneficiaireIsPersonneMorale',
  QUALITE: 'qualite',
  RAISON_SOCIALE: 'raisonSociale',
  PARTS: 'parts',
  PAYS_FISCAL: 'paysFiscal',

  // Main Address
  RUE: 'adressePermanenteRue',
  CODE_POSTAL: 'adressePermanenteCode',
  VILLE: 'adressePermanenteVille',

  // Company Adress
  SAME_ADDRESS_COMPANY: 'isSameAddress',
  VILLE_ENTREPRISE: 'adressePostaleVille',
  CODE_POSTAL_ENTREPRISE: 'adressePostaleCode',
  RUE_ENTREPRISE: 'adressePostaleRue'
};

/** ---- Epargne ---- **/
export const BENEFICIAIRE_EFFECTIF_FIELDS_EPARGNE = {
  ...BENEFICIAIRE_EFFECTIF_FIELDS_COMMON
};

/** ---- Assurance ---- **/
export const TYPE_CONTROLE = {
  DETENTION_CAPITAL: 'capital',
  DETENTION_DROIT_DE_VOTE: 'vote',
  POUVOIR_CONTROLE: 'pouvoir'
};
export const TYPES_CONTROLE_LIST = [
  { label: 'Détention capital', value: TYPE_CONTROLE.DETENTION_CAPITAL },
  { label: 'Détention droit de vote', value: TYPE_CONTROLE.DETENTION_DROIT_DE_VOTE },
  { label: 'Pouvoir de contrôle', value: TYPE_CONTROLE.POUVOIR_CONTROLE }
];

export const BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE = {
  ...BENEFICIAIRE_EFFECTIF_FIELDS_COMMON,
  TYPE_CONTROLE: 'typeControle',

  // Birth Infos
  DATE_NAISSANCE: 'dateNaissance',
  RUE_NAISSANCE: 'rueNaissance',
  CODE_POSTAL_NAISSANCE: 'codePostalNaissance',
  VILLE_NAISSANCE: 'villeNaissance',
  PAYS_NAISSANCE: 'paysNaissance',
  NATIONALITE: 'paysNationalite',
  LIEU_NAISSANCE: 'lieuNaissance',

  // Main Address
  PAYS: 'pays'
};

export const QUALITE_ACTIONNNAIRE = {
  PRINCIPAL: 'A',
  BENEFICIAIRE: 'B',
  DIRIGEANT: 'D',
  PROCURATION: 'P'
};

export const QUALITES = [
  {
    label: 'Actionnaire Principal',
    value: QUALITE_ACTIONNNAIRE.PRINCIPAL
  },
  {
    label: 'Bénéficiaire effectif',
    value: QUALITE_ACTIONNNAIRE.BENEFICIAIRE
  },
  {
    label: 'Dirigeant',
    value: QUALITE_ACTIONNNAIRE.DIRIGEANT
  },
  {
    label: 'Procuration',
    value: QUALITE_ACTIONNNAIRE.PROCURATION
  }
];
/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import FinalisationAttachmentFormLegalStatusHandler from '../../../../components/US/epargne_salariale/forms/finalisation/FinalisationAttachmentFormLegalStatusHandler';
import { URLS_MAROQUINERIE } from '../../../../utils/constantes/ROUTES/MAROQUINERIE';
import { annulerLiasseSouscription } from '../../../../services/LiasseService';
import {
  removeLiasse,
  setFichesPero,
  setInformations,
  setInformationsDossier,
  setLoader,
  setLoaderMessage,
  setRetourRoute
} from '../../../../modules/tunnel/redux/tunnelActions';

class MaroquinerieFinalisationPage extends Component {
  constructor(props) {
    super(props);
    const isDossierSigne =
      (this.props.tunnel.liasse
        && this.props.tunnel.liasse.statut === 'SIGNEE')
      || (window.location.search.includes('?event=')
        && window.location.search.split('?event=')[1] === 'signing_complete');

    // On modifie la route lorsque l'utilisateur clique sur le bouton Retour
    this.props.setRetourRoute(URLS_MAROQUINERIE.FINALISATION.substring(1));
    this.state = {
      redirectToTarification: false,
      isDossierSigne: isDossierSigne
    };
  }

  componentDidMount() {
    this.setState({ redirectToTarification: !this.state.isDossierSigne });
  }

  onBack = () => {
    return new Promise((resolve, reject) => {
      if (
        window.confirm(
          'Toute modification du formulaire invalidera le présent contrat ! '
          + 'Êtes-vous certain de vouloir retourner à l’étape précédente ?'
        )
      ) {
        if (this.props.tunnel.liasse) {
          annulerLiasseSouscription(this.props.tunnel.liasse.uid)
            .then(() => {
              this.props.removeLiasse();
              resolve();
            })
            .catch((/*error*/) => {
              reject();
              this.setState({
                redirectToTarification: true
              });
            });
        } else {
          resolve();
        }
      } else {
        reject();
      }
    });
  };

  render() {
    if (this.state.redirectToTarification) {
      return <Redirect to={URLS_MAROQUINERIE.TARIFICATION} />;
    }
    return (
      <div className="tunnel-infos-container">
        <FinalisationAttachmentFormLegalStatusHandler codeFormeJuridique={this.props.formeJuridique} />
      </div>
    );
  }
}

MaroquinerieFinalisationPage.propTypes = {
  removeLiasse: PropTypes.func,
  setRetourRoute: PropTypes.func.isRequired,
  tunnel: PropTypes.object,
  formeJuridique: PropTypes.string
};

// REDUX + REDUX FORM
const mapStateToProps = (state) => ({
  tunnel: state.tunnel,
  formeJuridique: state.tunnel.entreprise.formeJuridique
});

const mapDispatchToProps = (dispatch) => ({
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setInformations: (information) => dispatch(setInformations(information)),
  setInformationsDossier: (dossier) => dispatch(setInformationsDossier(dossier)),
  setFichesPero: (fichesPero) => dispatch(setFichesPero(fichesPero)),
  removeLiasse: () => dispatch(removeLiasse()),
  setLoader: (loader) => dispatch(setLoader(loader)),
  setLoaderMessage: (loaderMessage) => dispatch(setLoaderMessage(loaderMessage))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true }
)(MaroquinerieFinalisationPage);

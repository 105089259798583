/* Built-in imports */
import React, { useState, useEffect } from 'react';

/* Third-party imports */
import { Redirect } from 'react-router-dom';
import { YesButton } from '../../form/Button';
import { connect } from 'react-redux';

/* Internal imports */
import { ROUTE_DOSSIERS_GALAXY } from "../../../common/Configuration";
import { deconnecter } from '../../../services/CompteService';
import { getToken, deleteToken } from '../../../services/ResourcesService';
import { isDeconnexionVersPortailEnabled } from '../../../services/TenantService';
import { minutes, secondes } from '../../../common/FunctionsUtils';

const DELAI_AVANT_DECONNEXION = minutes(15);

const PopInInactivite = ({ state }) => {
  const { tunnel } = state;
  const [derniereModification, updateDerniereModification] = useState(new Date().getTime());
  const [compteARebours, updateCompteARebours] = useState(DELAI_AVANT_DECONNEXION);
  const [redirectDeconnexion, setRedirectDeconnexion] = useState(false);

  //Réinitialisation du timer si clavier actif ou clic de souris
  useEffect(() => {
    window.addEventListener('click', resetTimer);
    return () => window.removeEventListener('click', resetTimer);
  });
  useEffect(() => {
    window.addEventListener('keydown', resetTimer);
    return () => window.removeEventListener('keydown', resetTimer);
  });

  useEffect(() => {
    //on met à jour l'affichage toutes les secondes
    const interval = setInterval(() => {
      updateCompteARebours(Math.round(DELAI_AVANT_DECONNEXION - (new Date() - derniereModification)));
    }, secondes(1));
    return () => clearInterval(interval);
  }, [derniereModification]);

  //On vérifie la valeur du compte à rebours à chaque update
  useEffect(() => {
    if (compteARebours <= 0) {
      deconnexion();
    }
  }, [compteARebours]);

  const afficherEnSecondes = (temps) => {
    return Math.round(temps / 1000);
  };

  const resetTimer = () => {
    updateDerniereModification(new Date().getTime());
  };

  const deconnexion = () => {
    deconnecter({
      jwt: getToken(),
      dirigeantUid: tunnel.utilisateur.uid
    });
    deleteToken();
    setRedirectDeconnexion(true);
  };

  useEffect(() => {
    if (redirectDeconnexion) {
      if (isDeconnexionVersPortailEnabled()) {
        window.location = process.env.REACT_APP_STATIC_URL + ROUTE_DOSSIERS_GALAXY;
      } else {
        return <Redirect to={'/connexion'} />;
      }
    }
  }, [redirectDeconnexion]);

  return (
    <>
      {compteARebours < minutes(1) && (
        <div className="popInDeconnexion">
          <div>Il semble que vous ne soyez plus actif(ve) sur notre site, vous allez être déconnecté(e) automatiquement dans :</div>
          <div className="timer">{afficherEnSecondes(compteARebours)} secondes</div>
          <YesButton onClick={() => resetTimer()} text={'Rester en ligne'} />
          <div className="text-aide">Vous pourrez vous reconnecter ultérieurement à l’aide de votre e-mail et mot de passe.</div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  state: state
});

export default connect(mapStateToProps)(PopInInactivite);

/* Built-in imports */
import React, { useEffect } from "react";

/* Third-party imports */
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, formValueSelector, reduxForm } from "redux-form";

/* Internal imports */
import config from "../../../../../common/StaticSiteConfig";
import { BOOLEAN } from "../../../../../common/constants";
import { TransHtmlText } from "../../../../yes/utils/TransHtmlText";
import { YesButton } from "../../../../form/Button";
import { YesChoix } from "../../../../form/Choix";
import { isMessagesAccompagnementAccordsEnabled } from "../../../../../services/TenantService";
import { setDatesParticipationVolontaire } from "../../../../../modules/tunnel/redux/tunnelActions";
import { stringToBoolean } from "../../../../../common/FunctionsUtils";

const MaroquinerieParticipationForm = ({ participation, accompagnementParticipation, change, handleSubmit, pristine, invalid, submitting }) => {


  useEffect(() => {
    // Mode de répartition par défaut
    change('modeRepartition', 'PROPORTIONNEL_SALAIRE');

    change('accompagnementParticipation', participation?.participationActive ? BOOLEAN.YES : BOOLEAN.NO);
    change('optionParticipation.enabled', BOOLEAN.NO);
  }, [participation]);

  return (
    <form onSubmit={handleSubmit} className="formTunnel formParticipation">
      <label className="titreFormulaire" htmlFor="particiationForm">
        <Trans i18nKey="participation.titre" />
      </label>

      <div className="grid-x grid-margin-x">
        <div className="cell medium-12">
          <h3>
            <TransHtmlText i18nKey="participation.question.miseEnPlace" params={{ config: config }} />
          </h3>
        </div>

        <div className="cell medium-12">
          <div className="tunnelRadios">
            <div className="tunnelRadio">
              <Field
                name="accompagnementParticipation"
                component={YesChoix}
                type="radio"
                value={BOOLEAN.YES}
                label="Oui"
                id="accompagnementParticipation-oui"
              />
            </div>
            <div className="tunnelRadio">
              <Field
                name="accompagnementParticipation"
                component={YesChoix}
                type="radio"
                value={BOOLEAN.NO}
                label="Non"
                id="accompagnementParticipation-non"
              />
            </div>
          </div>
        </div>
      </div>

      {stringToBoolean(accompagnementParticipation) && isMessagesAccompagnementAccordsEnabled() && (
        <div className="cell medium-12 messageAccompagnement">
          <TransHtmlText i18nKey="participation.message.accompagnement.accordsMaroquinerie" params={{ config: config }} />
        </div>
      )}

      <div className="buttonCreationContainer">
        <YesButton
          text="Confirmer"
          disabled={invalid || submitting || pristine}
        />
      </div>
    </form>
  );
}

MaroquinerieParticipationForm.propTypes = {
  participation: PropTypes.object.isRequired,
  accompagnementParticipation: PropTypes.string,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
}

// REDUX + REDUX FORM
const selectParticipationForm = formValueSelector('MaroquinerieParticipationForm');

const mapStateToProps = (state) => ({
  participation: state.tunnel.dossier.participation,
  accompagnementParticipation: selectParticipationForm(state, 'accompagnementParticipation'),
  optionParticipation: selectParticipationForm(state, 'optionParticipation')
});

const mapDispatchToProps = (dispatch) => ({
  setDatesParticipationVolontaire: (dates) => dispatch(setDatesParticipationVolontaire(dates))
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'MaroquinerieParticipationForm' })(MaroquinerieParticipationForm));
/* Built-in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

/* Internal imports */
import RecapitulatifBloc from './RecapitulatifBloc';
import TextWithTitle from './TextWithTitle';
import { LISTE_FORMES_JURIDIQUE } from '../../../../common/constants';

const RecapitulatifEntreprise = ({
  t,
  siret,
  siren,
  codeNaf,
  raisonSociale,
  dateCreation,
  formeJuridique,
  adresse,
  salaries,
  cloture,
  donneesAnnexeKYC
}) => (
  <RecapitulatifBloc title={t('siret.titre')}>
    <TextWithTitle title={t('recap.entreprise.raison')} content={raisonSociale} />
    <TextWithTitle title={t('recap.entreprise.siret')} content={siret} />
    {siren && <TextWithTitle title={t('recap.entreprise.siren')} content={siren} />}
    {codeNaf && <TextWithTitle title={t('recap.entreprise.codeNaf')} content={codeNaf} />}
    <TextWithTitle
      title={t('recap.entreprise.forme')}
      content={LISTE_FORMES_JURIDIQUE.find((forme) => forme.value === parseInt(formeJuridique, 10)).label}
    />
    <TextWithTitle title={t('recap.entreprise.adresseSiege')} content={`${adresse.rue} ${adresse.codePostal} ${adresse.ville}`} />
    <TextWithTitle title={t('recap.entreprise.paysFiscal')} content={donneesAnnexeKYC.paysFiscalEntreprise.nom} />
    {salaries && <TextWithTitle title={t('recap.entreprise.salaries')} content={salaries.toString()} />}
    <TextWithTitle title={t('recap.entreprise.creation')} content={dateCreation} />
    {cloture && <TextWithTitle title={t('recap.entreprise.cloture')} content={cloture} />}
    {donneesAnnexeKYC.codeBIC && <TextWithTitle title={t('recap.entreprise.codeBic')} content={donneesAnnexeKYC.codeBIC} />}
    {donneesAnnexeKYC.regulateur && (
      <>
        <TextWithTitle title={t('recap.entreprise.regulateur')} content={donneesAnnexeKYC.regulateur.nomRegulateur} />
        <TextWithTitle title={t('recap.entreprise.referenceRegulateur')} content={donneesAnnexeKYC.regulateur.referenceAgrement} />
      </>
    )}
    {donneesAnnexeKYC.entrepriseCotee && (
      <>
        <TextWithTitle title={t('recap.entreprise.entrepriseCotee')} content={donneesAnnexeKYC.entrepriseCotee} />
        <TextWithTitle title={t('recap.entreprise.paysCotation')} content={donneesAnnexeKYC.paysCotation.nom} />
      </>
    )}
    {donneesAnnexeKYC.bonsPorteur && (
      <TextWithTitle title={t('recap.entreprise.bonsPorteur')} content={`${donneesAnnexeKYC.bonsPorteur} %`} />
    )}
    {donneesAnnexeKYC.paysPersonneMorale && (
      <TextWithTitle title={t('recap.entreprise.paysPersonneMorale')} content={donneesAnnexeKYC.paysPersonneMorale.nom} />
    )}

    <TextWithTitle title={t('recap.entreprise.paysActivite')} content={donneesAnnexeKYC.paysActivite.pays.nom} />
    <TextWithTitle title={t('recap.entreprise.chiffreAffaire')} content={`${donneesAnnexeKYC.paysActivite.chiffreAffaire} %`} />
    <TextWithTitle title={t('recap.entreprise.principauxMarches')} content={donneesAnnexeKYC.principauxMarches} />
    <TextWithTitle title={t('recap.entreprise.fournisseurs')} content={donneesAnnexeKYC.principauxPays} />
  </RecapitulatifBloc>
);

RecapitulatifEntreprise.propTypes = {
  salaries: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  formeJuridique: PropTypes.string.isRequired,
  raisonSociale: PropTypes.string.isRequired,
  dateCreation: PropTypes.string.isRequired,
  siret: PropTypes.string.isRequired,
  siren: PropTypes.string,
  adresse: PropTypes.shape({
    rue: PropTypes.string,
    ville: PropTypes.string,
    codePostal: PropTypes.string
  }).isRequired,
  cloture: PropTypes.string,
  donneesAnnexeKYC: PropTypes.object
};

// REDUX + REDUX FORM
const maptStateToProps = ({
  tunnel: {
    entreprise,
    dossier: { donneesAnnexeKYC }
  }
}) => ({
  donneesAnnexeKYC,
  siret: entreprise.siret,
  siren: entreprise.siren,
  codeNaf: entreprise.codeNaf,
  adresse: entreprise.adresse,
  raisonSociale: entreprise.raisonSociale,
  dateCreation: entreprise.dateCreation,
  formeJuridique: entreprise.formeJuridique,
  salaries: entreprise.complement?.nombreSalaries,
  cloture: entreprise.complement?.dateClotureComptable
});

export default connect(maptStateToProps)(withTranslation()(RecapitulatifEntreprise));

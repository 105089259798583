/* built-in imports */
import React, { useEffect, useState } from 'react';

/* third-party imports */
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* internal imports */
import CollegeForm from '../../../../components/US/assurance/forms/CollegeForm';
import { ROUTE_CONTINUER_SUR_ORDINATEUR, SEUIL_MOBILE } from '../../../../common/Configuration';
import { URLS_ASSURANCE } from '../../../../utils/constantes/ROUTES/ASSURANCE';
import { validatorCollege, validatorTaux } from '../../../../utils/US/components/assurance/forms/collegeFormUtils';
import { setCategoriesSalaries, setRetourRoute } from '../../../../modules/tunnel/redux/tunnelActions';
import { updateCategoriesSalaries } from '../../../../services/DossierService';
import { TAUX_CATEOGORY } from '../../../../utils/constantes/US/TAUX_CATEGORIES';

const AssuranceCollegePage = ({ setCategoriesSalaries, setRetourRoute, tunnel }) => {
  const [collegeCategory, setCollegeCategory] = useState('');
  const [collegeOption, setCollegeOption] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [tauxCategory, setTauxCategory] = useState('');
  const [tauxTranche1, setTauxTranche1] = useState('');
  const [tauxTranche2, setTauxTranche2] = useState('');
  const [tauxPMSS, setTauxPMSS] = useState('');


  useEffect(() => {
    setRetourRoute(URLS_ASSURANCE.GARANTES_PREVOYANCE.substring(1));

    const storedCategoriesSalaries = tunnel.dossier.categoriesSalaries;

    const storedcollegeOption = storedCategoriesSalaries?.collegeOption;
    const storedCollegeCategory = storedCategoriesSalaries.collegeCategory;
    setCollegeCategory(storedCollegeCategory);
    setCollegeOption(storedcollegeOption);

    const storedTauxCategory = storedCategoriesSalaries?.tauxCategory;
    if (storedTauxCategory) {
      setTauxCategory(storedTauxCategory);

      if (storedTauxCategory === TAUX_CATEOGORY.TAUX_PMSS) {
        setTauxPMSS(storedCategoriesSalaries.tauxPMSS);
        return;
      }

      setTauxTranche1(storedCategoriesSalaries.tauxTranche1);
      setTauxTranche2(storedCategoriesSalaries.tauxTranche2);
    }
  }, [tunnel.dossier.categoriesSalaries]);

  useEffect(() => {
    setSubmitDisabled(
      !validatorCollege(collegeCategory, collegeOption) ||
        !validatorTaux(tauxCategory, tauxPMSS, tauxTranche1, tauxTranche2)
    );
  }, [collegeCategory, tauxCategory, tauxPMSS, tauxTranche1, tauxTranche2, collegeOption]);

  const getCollegeCategory = () => {
    return {
      collegeCategory: collegeCategory,
      collegeOption: collegeOption
    };
  };

  const submit = (event) => {
    event.preventDefault();
    updateCategoriesSalaries(tunnel.dossier.uid, {
      ...getCollegeCategory(),
      tauxCategory,
      tauxTranche1,
      tauxTranche2,
      tauxPMSS
    })
      .then((collegeEtTaux) => {
        setCategoriesSalaries(collegeEtTaux);
        setRedirect(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCollegeCategoryChange = (value) => {
    setCollegeCategory(value);
    setCollegeOption('');
  };

  const handleTauxCategoryChange = (value) => {
    setTauxCategory(value);
    setTauxTranche1('');
    setTauxTranche2('');
    setTauxPMSS('');
  };

  const handleCollegeOptionChange = (value) => {
    setCollegeOption(value);
  }

  const handleTauxTranche1Change = (value) => {
    setTauxTranche1(value);
  };

  const handleTauxTranche2Change = (value) => {
    setTauxTranche2(value);
  };

  const handleTauxPMSSChange = (value) => {
    setTauxPMSS(value);
  };

  if (redirect) {
    // Pour les mobiles, on redirige vers la page informant de continuer sur un desktop
    if (window.innerWidth <= SEUIL_MOBILE) {
      return <Redirect to={ROUTE_CONTINUER_SUR_ORDINATEUR} />;
    }
    return <Redirect to={URLS_ASSURANCE.KYC} />;
  }

  return (
    <div>
      <CollegeForm
        handleCollegeChange={handleCollegeCategoryChange}
        collegeCategory={collegeCategory}
        collegeOption={collegeOption}
        handleCollegeOptionChange={handleCollegeOptionChange}
        tauxCategory={tauxCategory}
        handleTauxCategoryChange={handleTauxCategoryChange}
        tauxTranche1={tauxTranche1}
        handleTauxTranche1Change={handleTauxTranche1Change}
        tauxTranche2={tauxTranche2}
        handleTauxTranche2Change={handleTauxTranche2Change}
        tauxPMSS={tauxPMSS}
        handleTauxPMSSChange={handleTauxPMSSChange}
        submit={submit}
        submitDisabled={submitDisabled}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setCategoriesSalaries: (collegeEtTaux) => dispatch(setCategoriesSalaries(collegeEtTaux)),
  setRetourRoute: (route) => dispatch(setRetourRoute(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssuranceCollegePage);

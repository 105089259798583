/* Built-in imports */
import React from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';

/* Internal imports */
import { style } from './recapitulatif.style';

const RecapitulatifBloc = ({ children, title }) => (
  <div className="review-director cell medium-6" style={style.reviewBox}>
    <h2 style={style.reviewTitle}>{title}</h2>
    <div style={style.reviewBlock}>{children}</div>
  </div>
);

RecapitulatifBloc.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired
};

export default RecapitulatifBloc;

/* built-in imports */
import * as React from 'react'

/* external imports */
import Check from '@mui/icons-material/Check'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const QontoStepIconRoot = styled('div', { name: 'QontoStepIcon' })(({ ownerState }) => ({
  color: 'var(--cn-color-grey-200)',
  display: 'flex',
  height: 22,
  alignItems: 'center',
    ...(ownerState.active && {
    color: 'var(--cn-color-primary)',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: 'var(--cn-color-primary)',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}))

const  QontoStepIcons = ({ active, completed, className }) => {
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcons.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
}

export default QontoStepIcons

/* Built in imports */
import React from 'react';

/* Third party imports */
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

/* Internal imports */
import YesInput from '../../../../../form/Input';
import { YesDropdown } from '../../../../../form/Dropdown';
import { RequiredOr0 } from '../../../../../../common/Validators';
import { TransHtmlText } from '../../../../../yes/utils/TransHtmlText';

const pourcent = (name) => (value, previousValue, allValues) => {
  let nextValue = value && value.match(/[0-9]/) ? parseInt(value, 10) : '';
  nextValue = nextValue <= 100 ? nextValue : previousValue;
  const maxValue =
    100 -
    (name === 'uniforme' ? 0 : parseInt(allValues.optionParticipation.repartition.pourcent.uniforme, 10) || 0) -
    (name === 'salaire' ? 0 : parseInt(allValues.optionParticipation.repartition.pourcent.salaire, 10) || 0) -
    (name === 'temps' ? 0 : parseInt(allValues.optionParticipation.repartition.pourcent.temps, 10) || 0);
  return nextValue <= maxValue ? nextValue : maxValue;
};

const mustBeCovered = (_, allValues) =>
  allValues.optionParticipation.repartition.pourcent &&
  !!(
    allValues.optionParticipation.repartition.pourcent.uniforme +
      allValues.optionParticipation.repartition.pourcent.salaire +
      allValues.optionParticipation.repartition.pourcent.temps ===
    100
  )
    ? undefined
    : true;

const Repartition = ({ t, repartitions, selected, pourcentRepartition }) => (
  <div className="cell medium-12">
    <h3>
      <TransHtmlText i18nKey="participation.participationIntegre.repartition" />
    </h3>
    <Field name="optionParticipation.repartition.type" component={YesDropdown} item={repartitions} />
    {selected === '4' && (
      <div className="grid-x grid-margin-x align-middle">
        <div className="cell small-4">
          <div className="grid-x align-bottom">
            <div className="cell small-2">
              <Field
                inputMode="number"
                name="optionParticipation.repartition.pourcent.uniforme"
                component={YesInput}
                value="optionParticipation.repartition.pourcent.uniforme"
                validate={[mustBeCovered]}
                normalize={pourcent('uniforme')}
              />
            </div>
            <div className="cell small-10 repartition-text">
              <span>{t('participation.participationIntegre.combinaison1')}</span>
            </div>
          </div>
        </div>
        <div className="cell small-4">
          <div className="grid-x align-bottom">
            <div className="cell small-2">
              <Field
                inputMode="number"
                name="optionParticipation.repartition.pourcent.salaire"
                component={YesInput}
                value="optionParticipation.repartition.pourcent.salaire"
                validate={[mustBeCovered]}
                normalize={pourcent('salaire')}
              />
            </div>
            <div className="cell small-10 repartition-text">
              <span className="">{t('participation.participationIntegre.combinaison2')}</span>
            </div>
          </div>
        </div>
        <div className="cell small-4">
          <div className="grid-x align-bottom">
            <div className="cell small-2">
              <Field
                inputMode="number"
                name="optionParticipation.repartition.pourcent.temps"
                component={YesInput}
                value="optionParticipation.repartition.pourcent.temps"
                validate={[mustBeCovered, RequiredOr0]}
                normalize={pourcent('temps')}
              />
            </div>
            <div className="cell small-10 repartition-text">
              <span>{t('participation.participationIntegre.combinaison3')}</span>
            </div>
          </div>
        </div>
        {pourcentRepartition &&
          (Object.values(pourcentRepartition).reduce((acc, curr) => acc + +curr, 0) !== 100 ? (
            <div className="cell small-12 errorMsg">La somme des pourcentage doit être de 100 %</div>
          ) : !(
              (pourcentRepartition.uniforme || pourcentRepartition.uniforme === 0) &&
              (pourcentRepartition.salaire || pourcentRepartition.salaire === 0) &&
              (pourcentRepartition.temps || pourcentRepartition.temps === 0)
            ) ? (
            <div className="cell small-12 errorMsg">Tous les champs doivent être complétés</div>
          ) : (
            <></>
          ))}
      </div>
    )}
  </div>
);

Repartition.propTypes = {
  selected: PropTypes.string,
  t: PropTypes.func.isRequired,
  repartitions: PropTypes.array.isRequired,
  pourcentRepartition: PropTypes.object.isRequired
};

export default Repartition;

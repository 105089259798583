import React from "react";
import { Field, reduxForm } from "redux-form";
import { Required, ResetPassword } from "../../../../common/Validators";
import { YesInputPassword } from "../../../form/Password";
import { YesButton } from "../../../form/Button";

export const ChangementMotDePasseOublieForm = props => {
  const { handleSubmit, pristine, invalid, submitting, customError } = props;

  return (
    <form className="formChangementMdpOublie" onSubmit={handleSubmit}>
      <div className="grid-x grid-margin-x">
        <div className="cell medium-12">
          <label className="titreFormulaire">
            Définissez votre nouveau mot de passe
          </label>
          <Field
            component={YesInputPassword}
            type="password"
            name="nouveauMotDePasse"
            labeltext="Mot de passe"
            validate={[Required, ResetPassword]}
            indication="true"
            passwordForgotten="false"
            customError={customError}
          />
        </div>
        <div className="cell medium-12">
          <label className="titreFormulaire">
            Confirmez votre nouveau mot de passe
          </label>
          <Field
            component={YesInputPassword}
            type="password"
            name="nouveauMotDePasseConfirme"
            labeltext="Mot de passe"
            validate={[Required, ResetPassword]}
            indication="false"
            passwordForgotten="false"
          />
        </div>
      </div>
      <div className="buttonCreationContainer">
        <YesButton
          text="Réinitialiser mon mot de passe"
          disabled={invalid || submitting || pristine}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: "changementMotDePasseForm"
})(ChangementMotDePasseOublieForm);

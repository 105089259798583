import {
  CSG_CRDS,
  NOMBRE_MAX_PALIERS_ABONDEMENT,
  TAUX_CHARGES_PATRONALES,
  TAUX_CHARGES_SALARIALES,
  TAUX_MARGINAUX_DIMPOSITION
} from '../modules/simulateur/configuration';

export const tauxImposition = TAUX_MARGINAUX_DIMPOSITION.map(v => {
  return {
    value: v,
    label: Math.floor(v * 100) + "%"
  };
});

export const tauxChargesSalariales = TAUX_CHARGES_SALARIALES.map(v => {
  return {
    value: v,
    label: Math.floor(v * 100) + "%"
  };
});

export const tauxChargesPatronales = TAUX_CHARGES_PATRONALES.map(v => {
  return {
    value: v,
    label: Math.floor(v * 100) + "%"
  };
});

export const tauxCsg = CSG_CRDS;

export const nbMaxPaliers = NOMBRE_MAX_PALIERS_ABONDEMENT;

// Liste des taux d'abondement possible
export const LISTE_TAUX_ABONDEMENT = [
  {
    label: "50 %",
    value: 0.5
  },
  {
    label: "100 %",
    value: 1
  },
  {
    label: "150 %",
    value: 1.5
  },
  {
    label: "200 %",
    value: 2
  },
  {
    label: "250 %",
    value: 2.5
  },
  {
    label: "300 %",
    value: 3
  }
];


/* Built-in imports */
import React, { Component } from 'react';

/* Thirs-party imports */
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import DispositifActuelForm from '../../../../components/US/epargne_salariale/forms/DispositifActuelForm';
import { URLS_EPARGNE } from '../../../../utils/constantes/ROUTES/EPARGNE';
import { stringToBoolean, cloneValues } from '../../../../common/FunctionsUtils';
import {
  mettreAjourInteressement,
  mettreAjourParticipation,
  mettreAjourPlanExistant
} from '../../../../services/DossierService';
import {
  setRetourRoute,
  setPlanExistant,
  setInteressement,
  setParticipation
} from '../../../../modules/tunnel/redux/tunnelActions';

class EpargneDispositifActuelPage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    // On modifie la route lorsque l'utilisateur clique le bouton Retour
    this.props.setRetourRoute(URLS_EPARGNE.IDENTITE.substring(1));

    this.state = {
      redirect: false
    };
  }

  async submit(values) {
    let valuesAenvoye = Object.assign(cloneValues(values), {
      hasPlanExistant: stringToBoolean(values.hasPlanExistant),
      transfertPlan: stringToBoolean(values.transfertPlan),
      accordInteressementEnPlace: stringToBoolean(values.accordInteressementEnPlace),
      accordParticipationEnPlace: stringToBoolean(values.accordParticipationEnPlace)
    });

    try {
      const dossier = await mettreAjourPlanExistant(this.props.dossierUid, { valuesAenvoye });
      this.props.setPlanExistant(dossier.planExistant);

      if (valuesAenvoye.accordInteressementEnPlace && this.props.interessementActive) {
        await this.resetInteressementActive();
      }
      if (valuesAenvoye.accordParticipationEnPlace && this.props.participationActive) {
        await this.resetParticipationActive();
      }
      this.setState({
        redirect: true
      });
    } catch (error) {
      if (error && error.message) {
        this.setState({
          errorMsg: error.message
        });
      }
    }
  }
  resetInteressementActive = async () => {
    const interessementResetValues = {
      valuesAenvoye: {
        interessementActive: false
      }
    };
    try {
      const dossier = await mettreAjourInteressement(this.props.dossierUid, interessementResetValues);

      this.props.setInteressement(dossier.interessement);
    } catch (error) {
      if (error && error.message) {
        this.setState({
          errorMsg: error.message
        });
      }
    }
  }
  
  resetParticipationActive = async () => {
    const participationResetValues = {
      valuesAenvoye: {
        participationActive: false,
        optionParticipation: null
      }
    }
    try {
      const dossier = await mettreAjourParticipation(
        this.props.nombreSalaries,
        this.props.dossierUid,
        participationResetValues);

      this.props.setParticipation(dossier.participation);
    } catch (error) {
      if (error && error.message) {
        this.setState({
          errorMsg: error.message
        });
      }
    }
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={URLS_EPARGNE.DISPOSITIF_ABONDEMENT} />;
    }

    return (
      <div>
        <DispositifActuelForm onSubmit={this.submit} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dossierUid: state.tunnel.dossier.uid,
  nombreSalaries: state.tunnel.entreprise.complement.nombreSalaries,
  interessementActive: state.tunnel.dossier.interessement?.interessementActive,
  participationActive: state.tunnel.dossier.participation?.participationActive
});

const mapDispatchToProps = (dispatch) => ({
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setPlanExistant: (planExistant) => dispatch(setPlanExistant(planExistant)),
  setInteressement: (interessement) => dispatch(setInteressement(interessement)),
  setParticipation: (participation) => dispatch(setParticipation(participation)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EpargneDispositifActuelPage);

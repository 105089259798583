/* built-in imports */
import React, { useEffect, useState } from 'react';

/* external imports */
import { Field } from 'redux-form';
import { withTranslation } from 'react-i18next';

/* Internal imports */
import SelectCountries from '../../../../form/SelectCountries';
import YesInput from '../../../../form/Input';
import { BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE, TYPES_CONTROLE_LIST } from '../../../../../utils/constantes/US/KYCFORM';
import { CIVILITES_LIST } from '../../../../../common/constants';
import { PPE_FORM_FIELD } from '../../../../../utils/constantes/US/PPEFORM';
import { PpeFields } from '../PPE/PpeFields';
import { YesButton } from '../../../../form/Button';
import { YesCheckboxV2 } from '../../../../form/CheckboxV2';
import { YesDropdown } from '../../../../form/Dropdown';
import { changePpeFields, changeProchePpeFields } from '../../../../../utils/US/components/ppeFieldsUtils';
import { isValidPercentage, isValidPostalCode } from '../../../../../utils/validators/inputs';
import { stringToBoolean } from '../../../../../common/FunctionsUtils';
import {
  DateFr, iPercentage, isCodePostal,
  Required,
  requiredValidator
} from '../../../../../common/Validators';


const BeneficiairesEffectifsFields = ({ t, beneficiaryName, beneficiarys, index, closing, change, error }) => {
  const [isBirthPostalCodeError, setIsBirthPostalCodeError] = useState(true);
  const [isLocationPostalCodeError, setIsLocationPostalCodeError] = useState(true);
  const [isEnterprisePostalCodeError, setIsEnterprisePostalCodeError] = useState(true);
  const [isPercentageError, setIsPercentageError] = useState(true);
  const [errorsBeneficiary, setErrorsBeneficiary] = useState(false);

  useEffect(() => {
    if(!beneficiarys?.[index]) {
      return
    }
    const beneficiary = beneficiarys[index];

    let ppe, prochePpe;
    if(beneficiary.isPpe === "oui") {
      console.log('beneficiary.isPpe: ', beneficiary.isPpe );
      ppe = {
        pays: JSON.parse(beneficiary.paysExercice),
        fonction: beneficiary.fonction,
        finFonction:beneficiary.finFonction
      }
    }
    if(beneficiary.isProchePpe === "oui") {
      console.log('beneficiary.isProchePpe: ', beneficiary.isProchePpe );
      prochePpe = {
        nom: beneficiary.nomPpe,
        prenom: beneficiary.prenomPpe,
        lien: beneficiary.lienPpe,
        pays: JSON.parse(beneficiary.paysExercicePpe),
        fonction: beneficiary.fonctionPpe,
        finFonction: beneficiary.finFonctionPpe
      }
    }
    changePpeFields(ppe, change, beneficiaryName);
    changeProchePpeFields(prochePpe, change, beneficiaryName);
  }, [beneficiarys, index])

  useEffect(() => {
    setErrorsBeneficiary(error?.[index] && Object.keys(error[index]).length > 0);
  },[error])

  const isFormError = function() {
    return (isBirthPostalCodeError
      || isLocationPostalCodeError
      || (beneficiarys[index].isSameAddress ? false : isEnterprisePostalCodeError)
      || isPercentageError
      || errorsBeneficiary)
  }

  useEffect(() => {
    if(beneficiarys[index].codePostalNaissance){
      setIsBirthPostalCodeError(!isValidPostalCode(beneficiarys[index].codePostalNaissance));
    }
    if(beneficiarys[index].adressePermanenteCode){
      setIsLocationPostalCodeError(!isValidPostalCode(beneficiarys[index].adressePermanenteCode));
    }
    if(beneficiarys[index].adressePostaleCode){
      setIsEnterprisePostalCodeError(!isValidPostalCode(beneficiarys[index].adressePostaleCode));
    }
    if(beneficiarys[index].parts){
      setIsPercentageError(!isValidPercentage(beneficiarys[index].parts))
    }
  },[])

  return (
    <div className="beneficiaire-modal-content grid-x grid-margin-x">
      <div className="cell medium-4">
        <Field
          id={BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.CIVILITE}
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.CIVILITE}`}
          label={t("beneficiaire_effectif.civility")}
          component={YesDropdown}
          item={CIVILITES_LIST}
        />
      </div>

      <div className="cell medium-4 text-field">
        <Field
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PRENOM}`}
          type="text"
          component={YesInput}
          labeltext={t("beneficiaire_effectif.first_name")}
          required
          validate={requiredValidator}
        />
      </div>

      <div className="cell medium-4 text-field">
        <Field
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.NOM}`}
          type="text"
          component={YesInput}
          labeltext={t("beneficiaire_effectif.last_name")}
          required
          validate={requiredValidator}
        />
      </div>

      <div className="cell medium-4 text-field">
        <Field
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.NOM_NAISSANCE}`}
          type="text"
          component={YesInput}
          labeltext={t("beneficiaire_effectif.birth_name")}
        />
      </div>

      <div className="cell medium-4 typeControle">
        <Field
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.TYPE_CONTROLE}`}
          item={TYPES_CONTROLE_LIST}
          component={YesDropdown}
          label={t("beneficiaire_effectif.control_type")}
        />
      </div>

      <div className="cell medium-12 separateur" />

      <div className="yes-label cell medium-12">Informations naissance :</div>
      <div className="cell medium-4">
        <Field
          component={YesInput}
          type="text"
          inputMode="numeric"
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.DATE_NAISSANCE}`}
          labeltext={t("beneficiaire_effectif.birth_date")}
          required
          validate={[Required, DateFr]}
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          placeholder="jj/mm/aaaa"
        />
      </div>
      <div className="cell text-field medium-4 text-field">
        <Field
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.LIEU_NAISSANCE}`}
          type="text"
          component={YesInput}
          labeltext={t("beneficiaire_effectif.birth_place")}
          required
          validate={requiredValidator}
        />
      </div>
      <div className="cell text-field medium-4 text-field">
        <Field
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.CODE_POSTAL_NAISSANCE}`}
          type="text"
          component={YesInput}
          labeltext={t("beneficiaire_effectif.birth_zip_code")}
          required
          validate={isCodePostal}
          onChange={(event) => {
            setIsBirthPostalCodeError(!isValidPostalCode(event.target.value))
          }}
        />
      </div>
      <div className="cell medium-4">
        <Field
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PAYS_NAISSANCE}`}
          labeltext={t("beneficiaire_effectif.birth_country")}
          component={SelectCountries}
          required
          validate={requiredValidator}
        />
      </div>
      <div className="cell text-field medium-4 text-field">
        <Field
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.NATIONALITE}`}
          type="text"
          component={YesInput}
          labeltext={t("beneficiaire_effectif.nationality")}
          required
          validate={requiredValidator}
        />
      </div>

      <div className="cell medium-12 separateur" />

      <div className="yes-label cell medium-12">Adresse permanente :</div>
      <div className="cell text-field medium-4 text-field">
        <Field
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.RUE}`}
          type="text"
          component={YesInput}
          labeltext={t("beneficiaire_effectif.street_address")}
          required
          validate={requiredValidator}
        />
      </div>
      <div className="cell text-field medium-4 text-field">
        <Field
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.CODE_POSTAL}`}
          type="text"
          component={YesInput}
          labeltext={t("beneficiaire_effectif.zip_code")}
          required
          validate={isCodePostal}
          onChange={(event) => {
            setIsLocationPostalCodeError(!isValidPostalCode(event.target.value))
          }}
        />
      </div>
      <div className="cell text-field medium-4 text-field">
        <Field
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.VILLE}`}
          type="text"
          component={YesInput}
          labeltext={t("beneficiaire_effectif.city")}
          required
          validate={requiredValidator}
        />
      </div>
      <div className="cell medium-4">
        <Field
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PAYS}`}
          labeltext={t("beneficiaire_effectif.country")}
          component={SelectCountries}
          required
          validate={requiredValidator}
        />
      </div>
      <div className="cell medium-8" />

      <div className="cell medium-12 separateur" />

      <div className="yes-label cell medium-4">Adresse postale de l'entreprise :</div>
      <div className="cell medium-8 text-field">
        <Field
          type="checkbox"
          component={YesCheckboxV2}
          id={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.SAME_ADDRESS_COMPANY}`}
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.SAME_ADDRESS_COMPANY}`}
          labeltext={t("beneficiaire_effectif.same_company_address")}
        />
      </div>

      {!beneficiarys[index][BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.SAME_ADDRESS_COMPANY] && (
        <>
          <div className="cell text-field medium-4 text-field">
            <Field
              name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.RUE_ENTREPRISE}`}
              type="text"
              component={YesInput}
              labeltext={t("beneficiaire_effectif.street_address")}
              required
              validate={requiredValidator}
            />
          </div>
          <div className="cell text-field medium-4 text-field">
            <Field
              name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.CODE_POSTAL_ENTREPRISE}`}
              type="text"
              component={YesInput}
              labeltext={t("beneficiaire_effectif.zip_code")}
              required
              validate={isCodePostal}
              onChange={(event) => {
                setIsEnterprisePostalCodeError(!isValidPostalCode(event.target.value))
              }}
            />
          </div>
          <div className="cell text-field medium-4 text-field">
            <Field
              name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.VILLE_ENTREPRISE}`}
              type="text"
              component={YesInput}
              labeltext={t("beneficiaire_effectif.city")}
              required
              validate={requiredValidator}
            />
          </div>
        </>
      )}

      <div className="cell text-field medium-4 formCoutries">
        <Field name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PAYS_FISCAL}`} type="text" component={SelectCountries} labeltext={t("beneficiaire_effectif.tax_country")} />
      </div>
      <div className="cell medium-4 text-field">
        <Field
          name={`${beneficiaryName}.${BENEFICIAIRE_EFFECTIF_FIELDS_ASSURANCE.PARTS}`}
          type="text"
          component={YesInput}
          labeltext={t("beneficiaire_effectif.shares")}
          inputMode="numeric"
          numberfield={'true'}
          required
          validate={iPercentage}
          onChange={(event) => {
            setIsPercentageError(!isValidPercentage(event.target.value))
          }}
        />
      </div>
      <div className="cell medium-4" />

      <div className="cell medium-12 separateur" />

      {/* PPE */}
      <div className="cell medium-12">
        <PpeFields
          t={t}
          personne={beneficiaryName}
          isPpe={stringToBoolean(beneficiarys[index][PPE_FORM_FIELD.IS_PPE])}
          isProchePpe={stringToBoolean(beneficiarys[index][PPE_FORM_FIELD.IS_PROCHE_PPE])}
          trad="beneficiaireEffectif"
          change={change}
        />
      </div>

      <div className="cell medium-12 separateur" />
      <div className="cell medium-12 text-center">
        <YesButton
          text={t("beneficiaire_effectif.validate")}
          disabled={isFormError()}
          onClick={closing}
        />
      </div>
    </div>
  );
}

export default withTranslation()(BeneficiairesEffectifsFields);

const CITRA = {
  FOLDERS: 'dossiers',
  USERS: 'utilisateurs',
  FICHES: 'fiches',
  ENTERPRISE: 'entreprises',
  DATA: 'donnees'
};

const PARAMS = {
  FOLDERID: '{folderId}',
  FOLDERTYPE: '{folderType}',
  USERID: '{userId}',
  ENTERPRISEID: '{enterpriseId}'
};

const FOLDERS = {
  BENEFICIARYLIST: `${CITRA.FOLDERS}/${PARAMS.FOLDERID}/listeBeneficiaires`,
  RIB: `${CITRA.FOLDERS}/${PARAMS.FOLDERID}/rib`,
  KYC_PERSON: `${CITRA.FOLDERS}/${PARAMS.FOLDERID}/justificatifCNIKYC`,
  KYC_ENTITY: `${CITRA.FOLDERS}/${PARAMS.FOLDERID}/justificatifKBisKYC`,
  PARTICULARCASES: `${CITRA.FOLDERS}/${PARAMS.FOLDERID}/justificatifsCasParticuliers`,
  SEND: `${CITRA.FOLDERS}/${PARAMS.FOLDERID}/transmettre${PARAMS.FOLDERTYPE}`,
  EXISTINGPLAN: `${CITRA.FOLDERS}/${PARAMS.FOLDERID}/documentPlanExistant`,
  PROFITSHARINGAGREEMENT: `${CITRA.FOLDERS}/${PARAMS.FOLDERID}/documentAccordInteressement`,
  PARTICIPATIONAGREEMENT: `${CITRA.FOLDERS}/${PARAMS.FOLDERID}/documentAccordParticipation`,
  ENTERPRISECONSULTING: `${CITRA.FOLDERS}/${PARAMS.FOLDERID}/consultationEntreprise`
};

const USERS = {
  JUSTIFICATIFIDENTITY: `${CITRA.USERS}/${PARAMS.USERID}/justificatifIdentite`,
  PASSPORT: `${CITRA.USERS}/${PARAMS.USERID}/passeport`
};

const FICHES = {
  WORKADVICESSHEET: `${CITRA.FICHES}/ficheDevoirConseil`,
  LIASSEFISCALE: `${CITRA.FICHES}/${PARAMS.FOLDERID}/liasseFiscale`
};

const ENTERPRISE = {
  STATUTS: `${CITRA.ENTERPRISE}/${PARAMS.ENTERPRISEID}/statuts`,
  JUSTIFICATIFIDENTITY: `${CITRA.ENTERPRISE}/${PARAMS.ENTERPRISEID}/justificatifIdentiteEntreprise`,
  POWERDELEGATION: `${CITRA.ENTERPRISE}/${PARAMS.ENTERPRISEID}/delegationPouvoir`,
  RECEP_DECLA_PREF: `${CITRA.ENTERPRISE}/${PARAMS.ENTERPRISEID}/justificatifRecepDeclaPref`,
  RECEP_INSERTION: `${CITRA.ENTERPRISE}/${PARAMS.ENTERPRISEID}/justificatifRecepInsertion`,
  DECRET_RECO: `${CITRA.ENTERPRISE}/${PARAMS.ENTERPRISEID}/justificatifDecretReco`,
  PROFESSIONAL_CARD: `${CITRA.ENTERPRISE}/${PARAMS.ENTERPRISEID}/justificatifCarteProfessionnelle`,
};

const DATA = {
  PASS: `${CITRA.DATA}/pass`
}

export default {
  CITRA,
  DATA,
  ENTERPRISE,
  FICHES,
  FOLDERS,
  PARAMS,
  USERS
};
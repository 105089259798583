import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const FormControlLabelRoot = styled(TextField, { name: 'TextField' })(() => ({
  '& .MuiInputLabel-root': {
    fontFamily: 'var(--cn-font-family-source-pro-sans-serif)',
    '&.Mui-focused': {
      color: 'var(--cn-color-primary)',
    }
  },
  '& .MuiInputBase-input': {
    fontFamily: 'var(--cn-font-family-source-pro-sans-serif)',
  },
  '& .MuiFilledInput-root': {
    '&::after': {
      borderBottomColor: 'var(--cn-color-primary)'
    }
  }
}))

export default FormControlLabelRoot
/* Built in imports*/
import React from 'react';

/* Third party imports */
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

/* Internal imports */
import YesInput from '../../../../form/Input';
import { YesDropdown } from '../../../../form/Dropdown';
import { NormalizeNumber } from '../../../../../common/Normalizers';
import { LISTE_TAUX_ABONDEMENT } from '../../../../../services/TauxService';
import { isHigher, isNotNull, lowerThanPass, Required, requiredValidator } from '../../../../../common/Validators';
import { Button } from 'react-foundation';
import { SENIORITY_STEPS } from '../../../../../utils/constantes/US/DISPOSITIFABONDEMENT';

const ancienneteValidator = [Required, isHigher, lowerThanPass, isNotNull];

const styles = {
  button: {
    backgroundColor: '#C21B17',
    padding: '.25rem',
    fontSize: '.7rem',
    width: '5rem'
  }
};

const Anciennete = ({ type, anciennete, onCancel, PASS }) => (
  <>
    <div className="anciennete-rate">Taux de:</div>
    <div>
      <Field
        id={`anciennete[${type}].taux`}
        name={`anciennete[${type}].taux`}
        component={YesDropdown}
        item={LISTE_TAUX_ABONDEMENT}
        validate={requiredValidator}
      />
    </div>
    {SENIORITY_STEPS.map((step, i) => (
      <div className="anciennete-step grid-x align-middle" key={`anciennete-step-${type}-${i}`}>
        <div className="yes-label cell small-4">{step.label}</div>
        <div className="cell small-3">
          <Field
            id={`anciennete[${type}].${step.field}`}
            name={`anciennete[${type}].${step.field}`}
            component={YesInput}
            euroField={true}
            normalize={NormalizeNumber}
            validate={ancienneteValidator}
          />
        </div>
        <div className="cell small-4">
          {anciennete && anciennete[step.field] && <span>{Math.round((anciennete[step.field] * 100)  / PASS)} % du PASS</span>}
        </div>
      </div>
    ))}
    <Button style={styles.button} onClick={onCancel}>
      Annuler
    </Button>
  </>
);

Anciennete.propTypes = {
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  anciennete: PropTypes.object.isRequired,
  PASS: PropTypes.number
};

export default Anciennete;

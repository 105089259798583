export const CIVILITES = {
  MONSIEUR: 'Monsieur',
  MADAME: 'Madame',
  MADEMOISELLE: 'Mademoiselle'
};

export const CIVILITES_LIST = [
  {
    label: CIVILITES.MONSIEUR,
    value: CIVILITES.MONSIEUR
  },
  {
    label: CIVILITES.MADAME,
    value: CIVILITES.MADAME
  },
  {
    label: CIVILITES.MADEMOISELLE,
    value: CIVILITES.MADEMOISELLE
  }
];

export const DEFAULT_CIVILITE = CIVILITES.MONSIEUR;

export const COUNTRY = {
  FRANCE: {
    nom: 'France',
    code: 'FR',
    codeInsee: 'XXXXX'
  }
};

export const NATIONALITE = {
  DEFAULT: 'Française'
};

export const BOOLEAN = {
  YES: 'oui',
  NO: 'non'
};

export const IS_REQUIRED = 'Ce champ est obligatoire';

export const MSG_FORMAT_INVALIDE = 'Ce format n’est pas valide';

export const TYPE_DOSSIER = {
  EPARGNE: 'EPARGNE',
  ASSURANCE: 'ASSURANCE'
};

export const TAUX_FRAIS_PERDOB = {
  cotisations_obligatoires: {
    fixe: 4.95,
    plafond: 4.95,
    plancher: 0.2
  },
  versements_volontaires: {
    fixe: 4.95,
    plafond: 4.95,
    plancher: 0.2
  },
  tauxDerogationFraisEuros: {
    fixe: 0.6,
    plafond: 0.6,
    plancher: 0.6
  },
  tauxDerogationFraisUC: {
    fixe: 0.96,
    plafond: 0.96,
    plancher: 0.6
  }
};

export const IBAN_MASK = [
  /[^\W_]/,
  /[^\W_]/,
  /[^\W_]/,
  /[^\W_]/,
  ' ',
  /[^\W_]/,
  /[^\W_]/,
  /[^\W_]/,
  /[^\W_]/,
  ' ',
  /[^\W_]/,
  /[^\W_]/,
  /[^\W_]/,
  /[^\W_]/,
  ' ',
  /[^\W_]/,
  /[^\W_]/,
  /[^\W_]/,
  /[^\W_]/,
  ' ',
  /[^\W_]/,
  /[^\W_]/,
  /[^\W_]/,
  /[^\W_]/,
  ' ',
  /[^\W_]/,
  /[^\W_]/,
  /[^\W_]/,
  /[^\W_]/,
  ' ',
  /[^\W_]/,
  /[^\W_]/,
  /[^\W_]/
];

// Liste de formes juridique et de leur code Insee (remonté par SIRENE) à afficher dans la liste déroulante
export const LISTE_FORMES_JURIDIQUE = [
  {
    label: 'ASSOCIATION DECLAREE',
    value: 9220
  },
  {
    label: 'ASSOCIATION UTILITE PUBLIQUE',
    value: 9230
  },
  {
    label: 'EARL',
    value: 6598
  },
  {
    label: 'EI',
    value: 1000
  },
  {
    label: 'EURL',
    value: 5498
  },
  {
    label: 'GAEC',
    value: 6533
  },
  {
    label: 'GIE',
    value: 6220
  },
  {
    label: 'SA',
    value: 5599
  },
  {
    label: 'SARL',
    value: 5499
  },
  {
    label: 'SAS',
    value: 5710
  },
  {
    label: 'SASU',
    value: 5720
  },
  {
    label: 'SC',
    value: 6599
  },
  {
    label: 'SCI',
    value: 6540
  },
  {
    label: 'SCM',
    value: 6589
  },
  {
    label: 'SCOP',
    value: 5458
  },
  {
    label: 'SCP',
    value: 6585
  },
  {
    label: 'SCS',
    value: 5306
  },
  {
    label: 'SELAFA',
    value: 5585
  },
  {
    label: 'SELARL',
    value: 5485
  },
  {
    label: 'SELAS',
    value: 5785
  },
  {
    label: 'SELCA',
    value: 5385
  },
  {
    label: 'SICA',
    value: 5632
  },
  {
    label: 'SNC',
    value: 5202
  },
  {
    label: 'SCA',
    value: 5770
  },
  {
    label: 'Autre',
    value: 0
  }
];

export const DATE_PLACEHOLDER = 'jj/mm/aaaa';
export const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export const CONTRACTANT = {
  DIRIGEANT: 'DIRIGEANT',
  SIGNATAIRE: 'SIGNATAIRE'
};

import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

const MenuItemRoot = styled(MenuItem, { name: 'MenuItem' })(() => ({
  fontFamily: 'var(--cn-font-family-source-pro-sans-serif)',
  '&.Mui-selected': {
    backgroundColor: 'var(--cn-color-primary-opacity-1)',
    '&:hover': {
      backgroundColor: 'var(--cn-color-primary-opacity-1)',
    }
  }
}))

export default MenuItemRoot
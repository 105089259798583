/* built-in imports */
import React, { useEffect, useState } from 'react';

/* Third party imports */
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

/* Internal imports */
import CircularProgress from '../../../../DS/feedback/Progress';
import FinalisationAttachmentsFormAssoDeclare from './FinalisationAttachmentsFormAssoDeclare';
import FinalisationAttachmentsFormAssoPublique from './FinalisationAttachmentsFormAssoPublique';
import FinalisationAttachmentsFormProfessionLiberale from './FinalisationAttachmentsFormProfessionLiberale';
import FinalisationAttachmentsFormSociete from './FinalisationAttachmentsFormSociete';
import OutlinedSelect from '../../../../DS/inputs/OutlinedSelectField';
import OutlinedSelectMenuItem from '../../../../DS/inputs/OutlinedSelectMenuItem';
import Typography from '../../../../DS/data-display/Typography';
import componentUtils from '../../../../../utils/US/components/epargne_salariale/forms/finalisationAttachmentsFormUtils';
import { FORMES_JURIDIQUES } from '../../../../../utils/constantes/US/FINALISATION_FORM';
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';

const EpargneFinalisationAttachmentFormLegalStatusHandler = ({ codeFormeJuridique }) => {

  const [currentFormeJuridique, setCurrentFormeJuridique] = useState(undefined);
  const { t } = useTranslation();
  const tradKey = "components.US.epargne_salariale.forms.informationsAttachmentsForm";

  useEffect(() => {
    setCurrentFormeJuridique(componentUtils.getFormeJuridiqueByCode(codeFormeJuridique));
  }, [codeFormeJuridique]);

  const handleLegalStatusChange = (event) => {
    setCurrentFormeJuridique(event.target.value);
  }

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography
          title
          className="margin_bottom_1 titreFormulaire">
          <TransHtmlText i18nKey={(`${tradKey}.title`)} />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          {t(`${tradKey}.description`)}
        </Typography>
      </Grid>
      <Grid item>
        {currentFormeJuridique && (
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography>
                {t(`${tradKey}.legalStatusChange`)}
              </Typography>
            </Grid>
            <Grid item>
              <OutlinedSelect value={currentFormeJuridique} onChange={handleLegalStatusChange}>
                {Object.values(FORMES_JURIDIQUES).map((formeJuridique) => {
                    return <OutlinedSelectMenuItem
                      value={formeJuridique.key}
                      key={formeJuridique.key}
                    >
                      {formeJuridique.label}
                    </OutlinedSelectMenuItem>
                  }
                )}
              </OutlinedSelect>
            </Grid>
          </Grid>
        )}
      </Grid>
      {currentFormeJuridique === undefined && <CircularProgress className="margin_O" />}
      {currentFormeJuridique === FORMES_JURIDIQUES.ASSOCIATION_DECLARE.key && (
        <FinalisationAttachmentsFormAssoDeclare tradKey={tradKey} />
      )}
      {currentFormeJuridique === FORMES_JURIDIQUES.ASSOCIATION_UTILITE_PUBLIQUE.key && (
        <FinalisationAttachmentsFormAssoPublique tradKey={tradKey} />
      )}
      {currentFormeJuridique === FORMES_JURIDIQUES.PROFESSION_LIBERALE.key && (
        <FinalisationAttachmentsFormProfessionLiberale tradKey={tradKey} />
      )}
      {currentFormeJuridique === FORMES_JURIDIQUES.SOCIETE.key && (
        <FinalisationAttachmentsFormSociete tradKey={tradKey} />
      )}
    </Grid>
  );
};

export default EpargneFinalisationAttachmentFormLegalStatusHandler;

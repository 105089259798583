/* Built-in imports */
import React from "react";

/* Third-party imports */
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Trans } from "react-i18next";

/* Internal imports */
import YesInput from "../../form/Input";
import { IBAN_MASK } from "../../../common/constants";
import { Iban, IbanUppercase, Required } from "../../../common/Validators";
import { MOYENS_PAIEMENT } from '../../../utils/constantes/US/MOYENSPAIEMENT';

const TarificationIban = ({ customError }) => (
  <Field
    component={YesInput}
    type="text"
    name={MOYENS_PAIEMENT.IBAN}
    labeltext={MOYENS_PAIEMENT.IBAN}
    mask={IBAN_MASK}
    placeholder="FR__ ____ ____ ____ ____ ___"
    validate={[Required, Iban, IbanUppercase]}
    customError={customError}
  />
);

TarificationIban.propTypes = {
  customError: PropTypes.string
};

export default TarificationIban;
/* Built-in imports */
import React, { useEffect, useState } from "react";

/* Third-party imports */
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

/* Internal imports */
import MaroquinerieInteressementForm from "../../../../components/US/epargne_salariale/maroquinerie/form/MaroquinerieInteressementForm";
import { URLS_MAROQUINERIE } from "../../../../utils/constantes/ROUTES/MAROQUINERIE";
import { cloneValues, stringToBoolean } from "../../../../common/FunctionsUtils";
import { mettreAjourInteressement } from "../../../../services/DossierService";
import { setInteressement, setRetourRoute } from "../../../../modules/tunnel/redux/tunnelActions";

const MaroquinerieInteressementPage = ({ tunnel, setRetourRoute, setInteressement }) => {

  const [redirect, setRedirect] = useState(false || tunnel.dossier.planExistant?.accordInteressementEnPlace);

  useEffect(() => {
    setRetourRoute(URLS_MAROQUINERIE.DISPOSITIF_ABONDEMENT.substring(1))
  }, [])

  const submit = (values) => {
    let valuesAenvoye = Object.assign(cloneValues(values), {
      interessementActive: stringToBoolean(values.accompagnementInteressement)
    });

    mettreAjourInteressement(tunnel.dossier.uid, { valuesAenvoye })
      .then((dossier) => {
        setInteressement(dossier.interessement);
        setRedirect(true);
      })
      .catch((error) => {
        if (error && error.message) {
          error.log("error: ", error.message);
        }
      });
  };

  return (
    redirect
      ? (<Redirect to={URLS_MAROQUINERIE.PARTICIPATION} />)
      : (
          <div>
            <MaroquinerieInteressementForm onSubmit={submit} />
          </div>
      )
  );
}

MaroquinerieInteressementPage.propTypes = {
  tunnel: PropTypes.object.isRequired,
  setRetourRoute: PropTypes.func.isRequired,
  setInteressement: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setInteressement: (interessement) => dispatch(setInteressement(interessement))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MaroquinerieInteressementPage);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Pie } from "./Pie";
import { nombreAvecEspaces } from "../../../../common/FunctionsUtils";
import {
  voirEcranSynthese,
  voirEcranDetailEntreprise,
  voirEcranDetailSalarie
} from "../../../../modules/simulateur/redux/simulateurActions";

class Graphiques extends Component {
  render() {
    const { simulateur } = this.props;
    const efficaciteSalariePrimeClassique = [
      simulateur.primeClassique.sommePercueSalarie.sommePercueSalarie,
      simulateur.primeClassique.sommePercueSalarie.chargesTotalesSalarie
    ];

    const efficaciteSalarieEparneSalariale = [
      simulateur.epargneSalariale.sommePercueSalarie.sommePercueSalarie,
      simulateur.epargneSalariale.sommePercueSalarie.csgCrds
    ];

    const percentCoutBrutEpargneSalariale = Math.floor(
      (simulateur.epargneSalariale.dispositif.enveloppeIndividuelle /
        simulateur.epargneSalariale.coutEntreprise.coutTotalEntreprise) *
        100
    );

    const percentCoutBrutPrimeClassique = Math.floor(
      (simulateur.epargneSalariale.dispositif.enveloppeIndividuelle /
        simulateur.primeClassique.coutEntreprise.coutTotalEntreprise) *
        100
    );

    const percentChargesEpargneSalariale =
      100 - percentCoutBrutEpargneSalariale;
    const percentChargesPrimeClassique = 100 - percentCoutBrutPrimeClassique;

    let colorsPrimeClassiques = ["#a6b9c2", "#c2ced4"];
    let colorsEpargneSalariale = ["#68b2bb", "rgba(104, 178, 187, 0.6)"];

    return (
      <div className="graphiques-restitution">
        <div className="grid-x grid-margin-x graph-bar">
          <div className="cell medium-12 yes-titre-rectangle">
            Économies réalisées - Synthèse
          </div>

          <div className="cell medium-12 yes-rectangle efficacite-entreprise">
            <div className="grid-x grid-margin-x">
              <div className="medium-12 titreGraph">Coût pour l’entreprise</div>
              <div
                className="medium-12 enDetails"
                onClick={this.props.voirEcranDetailEntreprise}
              >
                En détails
                <img
                  src="/assets/img/icons/yes/add-circular-button.svg"
                  alt="detail entreprise"
                />
              </div>

              <div className="cell medium-3 graph-titreTypePrime">
                Prime classique
              </div>

              <div className="cell medium-7">
                <div className="horizontal-bar graph-primeClassique">
                  <div
                    className="graph-env-ind"
                    style={{ width: percentCoutBrutPrimeClassique + "%" }}
                  >
                    <span className="yes-indication title-graph-montant">
                      Montant brut
                    </span>
                    <span>
                      {nombreAvecEspaces(
                        Math.floor(
                          simulateur.epargneSalariale.dispositif
                            .enveloppeIndividuelle
                        )
                      )}{" "}
                      €
                    </span>
                  </div>

                  <div
                    className="graph-charges"
                    style={{ width: percentChargesPrimeClassique + "%" }}
                  >
                    <span className="yes-indication title-graph-montant">
                      Charges
                    </span>
                    <span>
                      {nombreAvecEspaces(
                        simulateur.primeClassique.coutEntreprise
                          .chargesPatronales
                      )}{" "}
                      €
                    </span>
                  </div>
                </div>
              </div>

              <div className="cell medium-2 graph-montantGlobal">
                {nombreAvecEspaces(
                  simulateur.primeClassique.coutEntreprise.coutTotalEntreprise
                )}{" "}
                €
              </div>

              <div className="cell medium-3 graph-titreTypePrime">
                Épargne salariale
              </div>

              <div className="cell medium-7">
                <div className="horizontal-bar graph-epargne-salariale">
                  <div
                    className="graph-env-ind"
                    style={{ width: percentCoutBrutPrimeClassique + "%" }}
                  >
                    {nombreAvecEspaces(
                      Math.floor(
                        simulateur.epargneSalariale.dispositif
                          .enveloppeIndividuelle
                      )
                    )}{" "}
                    €
                  </div>

                  <div
                    className="graph-charges"
                    style={{ width: percentChargesEpargneSalariale + "%" }}
                  >
                    <span
                      className={
                        percentChargesEpargneSalariale <= 11
                          ? "graph-total-cout-ep"
                          : ""
                      }
                    >
                      {nombreAvecEspaces(
                        simulateur.epargneSalariale.coutEntreprise.forfaitSocial
                          .total
                      )}{" "}
                      €
                    </span>
                  </div>
                </div>
              </div>

              <div className="cell medium-2 graph-montantGlobal yes-green">
                {nombreAvecEspaces(
                  simulateur.epargneSalariale.coutEntreprise.coutTotalEntreprise
                )}{" "}
                €
              </div>
            </div>

            <div className="medium-12">
              <h3 className="graph-economie yes-gras">
                L’économie réalisée par l’entreprise est de{" "}
                <span className="yes-green somme-economie">
                  {nombreAvecEspaces(
                    simulateur.primeClassique.coutEntreprise
                      .coutTotalEntreprise -
                      simulateur.epargneSalariale.coutEntreprise
                        .coutTotalEntreprise
                  )}
                </span>{" "}
                <span className="yes-green">€</span>.
              </h3>
            </div>
          </div>
        </div>

        <div className="graphiques-trait-horizontal" />

        <div className="cell medium-12 yes-rectangle graph-pie">
          <div className="grid-x grid-margin-x">
            <div className="cell medium-12 titreGraph">
              Efficacité pour le salarié
            </div>
            <div
              className="medium-12 enDetails"
              onClick={this.props.voirEcranDetailSalarie}
            >
              En détails
              <img
                src="/assets/img/icons/yes/add-circular-button.svg"
                alt="detail entreprise"
              />
            </div>
            <div className="cell medium-12 sousTitreGraph">
              Pour un montant brut de{" "}
              {nombreAvecEspaces(
                simulateur.epargneSalariale.dispositif.enveloppeIndividuelle
              )}{" "}
              €
            </div>

            <div className="cell medium-6">
              <div className="graph-titreTypePrime">Prime classique</div>

              <div className="grid-x graph-pie-item">
                <div className="cell medium-1 yes-indication">
                  <span className="graph-charges-text">Charges</span>
                </div>
                <div className="cell medium-10 graph-pie-prime-classique">
                  <Pie
                    data={efficaciteSalariePrimeClassique}
                    animer={simulateur.ecran.animerGraphique}
                    radius={70}
                    hole={0}
                    noPieRotation={true}
                    rotate={-70}
                    colors={colorsPrimeClassiques}
                    labels={true}
                    percent={false}
                  />
                </div>
                <div className="cell medium-1 yes-indication">
                  <span className="graph-somme-text">Somme perçue</span>
                </div>
              </div>
            </div>

            <div className="cell medium-6">
              <div className="graph-titreTypePrime">Épargne salariale</div>
              <div className="grid-x graph-pie-item">
                <div className="cell medium-1 yes-indication">
                  <span className="graph-charges-text">Charges</span>
                </div>
                <div className="cell medium-10 graph-pie-es">
                  <Pie
                    data={efficaciteSalarieEparneSalariale}
                    radius={70}
                    hole={0}
                    rotate={-120}
                    noPieRotation={true}
                    colors={colorsEpargneSalariale}
                    labels={true}
                    percent={false}
                  />
                </div>
                <div className="cell medium-1 yes-indication">
                  <span className="graph-somme-text">Somme perçue</span>
                </div>
              </div>
            </div>
            <div className="cell medium-12">
              <span className="graph-pie-trait" />
            </div>
            <div className="medium-12 divGraphEconomie">
              <h3 className="graph-economie yes-gras">
                Le gain financier pour le salarié est de{" "}
                <span className="yes-green somme-economie">
                  {nombreAvecEspaces(
                    simulateur.epargneSalariale.sommePercueSalarie
                      .sommePercueSalarie -
                      simulateur.primeClassique.sommePercueSalarie
                        .sommePercueSalarie
                  )}
                </span>{" "}
                <span className="yes-green">€</span>.
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  simulateur: state.simulateur
});

const mapDispatchToProps = dispatch => ({
  voirEcranSynthese: () => dispatch(voirEcranSynthese()),
  voirEcranDetailEntreprise: () => dispatch(voirEcranDetailEntreprise()),
  voirEcranDetailSalarie: () => dispatch(voirEcranDetailSalarie())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Graphiques);

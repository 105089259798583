/* Built-in imports */
import React, { useEffect, useState } from "react";

/* Third-party imports */
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

/* Internal imports */
import MaroquinerieParticipationForm from "../../../../components/US/epargne_salariale/maroquinerie/form/MaroquinerieParticipationForm";
import { URLS_MAROQUINERIE } from "../../../../utils/constantes/ROUTES/MAROQUINERIE";
import { cloneValues, stringToBoolean } from "../../../../common/FunctionsUtils";
import { mettreAjourParticipation } from "../../../../services/DossierService";
import {
  setInformationsUtilisateur,
  setParticipation,
  setRetourRoute
} from "../../../../modules/tunnel/redux/tunnelActions";

const MaroquinerieParticipationPage = ({ tunnel, setRetourRoute, setParticipation }) => {

  const [redirect, setRedirect] = useState(false || tunnel.dossier.planExistant?.accordParticipationEnPlace);

  useEffect(() => {
    if (tunnel.dossier.planExistant?.accordInteressementEnPlace) {
      setRetourRoute(URLS_MAROQUINERIE.DISPOSITIF_ABONDEMENT.substring(1))
    } else {
      setRetourRoute(URLS_MAROQUINERIE.INTERESSEMENT.substring(1));
    }
  }, []);

  const submit = (values) => {
    let valuesAenvoye = Object.assign(cloneValues(values), {
      participationActive: stringToBoolean(values.accompagnementParticipation),
      optionParticipation: {
        enabled: stringToBoolean(values.optionParticipation.enabled),
        type: values.optionParticipation.formule && +values.optionParticipation.formule.type,
        repartition: values.optionParticipation.repartition
          ? {
            type: +values.optionParticipation.repartition.type,
            pourcent: values.optionParticipation.repartition.pourcent
              ? {
                uniforme: +values.optionParticipation.repartition.pourcent.uniforme || 0,
                salaire: +values.optionParticipation.repartition.pourcent.salaire || 0,
                temps: +values.optionParticipation.repartition.pourcent.temps || 0
              }
              : {}
          }
          : {},
        duree: values.optionParticipation.duree
          ? {
            type: +values.optionParticipation.duree.type,
            duree: +values.optionParticipation.duree.duree
          }
          : {}
      }
    });

    mettreAjourParticipation(tunnel.entreprise.complement.nombreSalaries, tunnel.dossier.uid, {
      valuesAenvoye
    })
      .then((dossier) => {
        setParticipation(dossier.participation);
        setRedirect(true);
      })
      .catch((error) => {
        if (error && error.message) {
          error.log(error.message);
        }
      });
  };

  return (
    redirect
      ? <Redirect to={URLS_MAROQUINERIE.KYC} />
      : (
          <div>
            <MaroquinerieParticipationForm onSubmit={submit} />
          </div>
      )
  );
}

MaroquinerieParticipationPage.propTypes = {
  tunnel: PropTypes.object.isRequired,
  setRetourRoute: PropTypes.func.isRequired,
  setParticipation: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setInformationsUtilisateur: (utilisateur) => dispatch(setInformationsUtilisateur(utilisateur)),
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setParticipation: (participation) => dispatch(setParticipation(participation))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MaroquinerieParticipationPage);

/**
 * Ce fichier contient toutes les constantes relatives à la configuration du simulateur.
 * Ces constantes sont principalement issues de toutes les contraintes réglementaires relatives à l'épargne salariale
 * et plus généralement les calculs de rémunération.
 *
 * Il pourra être nécessaire de modifier ces constantes puis faire une MEP pour suivre les évolutions réglementaires
 *
 */

// Convertit une valeur en pourcentage (de 0 à 100) en taux (de 0 à 1)
const pourcent = (value) => Math.round((value / 100) * 10000) / 10000;

// ------ Constantes relatives aux calculs des plafonds du dispositif d'épargne salariale ------
// Taux appliqué au PASS pour déterminer le plafond de l'abondement PEE
export const TAUX_MAX_ABONDEMENT_PEE = pourcent(8);
// Taux appliqué au PASS pour déterminer le plafond de l'abondement PERCO
export const TAUX_MAX_ABONDEMENT_PERCO = pourcent(16);
// Taux appliqué au PASS pour déterminer le plafond de l'intéressement
export const TAUX_MAX_INTERESSEMENT = pourcent(75);
// Taux appliqué au PASS pour déterminer le plafon de la participation
export const TAUX_MAX_PARTICIPATION = pourcent(75);
// Taux appliqué au PASS pour déterminer le plafond de l'abondement unilateral
export const TAUX_MAX_ABONDEMENT_UNILATERAL = pourcent(2);
// Nombre de paliers d'abondement maximum
export const NOMBRE_MAX_PALIERS_ABONDEMENT = 3;

// ------- Constantes relatives aux calcul des charges sociales sur le dispositif d'épargne salariale -----
// Pourcentage appliqué à l'abondement PEE
export const FORFAIT_SOCIAL_PEE_MOINS_50 = 0;
export const FORFAIT_SOCIAL_PEE_PLUS_50 = pourcent(20);
// Pourcentage appliqué à l'abondement PERCO
export const FORFAIT_SOCIAL_PERCO_MOINS_50 = 0;
export const FORFAIT_SOCIAL_PERCO_PLUS_50 = pourcent(16);
// Pourcentage appliqué à l'interessement
export const FORFAIT_SOCIAL_INTERESSEMENT_MOINS_250 = 0;
export const FORFAIT_SOCIAL_INTERESSEMENT_250_PLUS = pourcent(20);
// Pourcentage appliqué à la participation
export const FORFAIT_SOCIAL_PARTICIPATION_MOINS_50 = 0;
export const FORFAIT_SOCIAL_PARTICIPATION_50_PLUS = pourcent(20);

// ------ Constantes relatives aux calculs des charges et impots sur les primes versées en rémunération ------
// Listes des valeurs possibles pour le taux marginal d'imposition
export const TAUX_MARGINAUX_DIMPOSITION = [0, 11, 30, 41, 45].map(pourcent);
// Valeur par défaut du taux marginal d'imposition si l'utilisateur ne le choisit pas explicitement
export const TAUX_MARGINAL_DIMPOSITION_DEFAULT = pourcent(30);

// Listes des valeurs possibles pour le taux des charges patronales
export const TAUX_CHARGES_PATRONALES = [30, 35, 40, 45, 50, 55, 60].map(pourcent);
// Valeur par défaut du taux de charges patronales si l'utilisateur ne le choisit pas explicitement
export const TAUX_CHARGES_PATRONALES_DEFAUT = pourcent(45);

// Listes des valeurs possibles pour le taux des charges salariales
export const TAUX_CHARGES_SALARIALES = [15, 20, 22, 25, 26, 28, 30, 32, 34].map(pourcent);
// Valeur par défaut du taux de charges salariales si l'utilisateur ne le choisit pas explicitement
export const TAUX_CHARGES_SALARIALES_DEFAUT = pourcent(15);

// Taux de CSG-CRDS
export const CSG_CRDS = pourcent(9.7);

export const INTERESSEMENT_POURCENTAGE_MAX_MASSE_SALARIALE = pourcent(20);

import React, { Component } from "react";
import { YesButton } from "../../form/Button";
import config from "../../../common/StaticSiteConfig";
import { getSvgEnFonctionDuTenant } from "../../../services/TenantService";
import { Translation } from "react-i18next";

export default class Page404 extends Component {
  redirectToSite = () => {
    window.location.replace(config.siteUrl);
  };

  getPhoneNumber = phoneNumber => {
    return "+33" + phoneNumber.substring(1);
  };

  render() {
    return (
      <div className="erreur404">
        <a
          className={"hide-for-small-only lienLogoTunnel"}
          href={config.siteUrl}
        >
          <img
            className="logoYomoni"
            src={getSvgEnFonctionDuTenant(
              "/assets/img/logos/logo-",
              "-tunnel.svg"
            )}
            alt="Yomoni"
          />
        </a>

        <a
          className={"show-for-small-only lienLogoTunnel divLogoMobile404"}
          href={config.siteUrl}
        >
          <img
            width="137"
            src="/assets/img/logos/logo-blason-green.svg"
            alt="Yomoni"
            className="logoYomoniMobile"
          />
        </a>

        <div className="content404">
          <img
            className="illuPageIntrouvable"
            src={getSvgEnFonctionDuTenant(
              "/assets/img/illustrations/",
              "/illu-besoin.svg"
            )}
            alt="erreur page introuvable Yomoni"
          />

          <h1>Il semble que la page n’existe pas ou plus…</h1>

          <p>
            N’hésitez pas à contacter notre service client au{" "}
            <Translation>
              {t => (
                <a
                  className="telephone404"
                  href={"tel:" + this.getPhoneNumber(t("contact.telephone"))}
                >
                  <span>{t("contact.telephone")}</span>
                </a>
              )}
            </Translation>{" "}
            ou par chat (en bas à droite de l’écran) pour que nous puissions
            vous éclairer.
          </p>

          <YesButton
            text="Retourner sur le site"
            onClick={this.redirectToSite}
          />
        </div>
      </div>
    );
  }
}

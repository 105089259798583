import Modal from 'react-modal';
import { connect } from 'react-redux';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { formValueSelector, reduxForm } from 'redux-form';

import { initBenefiaireEffectif } from './kycMapper';
import BeneficiairesEffectifsFields from './BeneficiairesEffectifsFields';

const modalBeneficiairesEffectifsStyle = {
  overlay: {
    backgroundColor: 'rgba(0,0,0, 0.5)'
  },
  content: {
    boxShadow: '2px 2px 5px black',
    border: '3px solid #752127',
    inset: '4rem 12rem',
    minHeight: '400px',
    minWidth: '400px',
    padding: 0
  }
};
export const BeneficiairesEffectifsForm = ({ fields, meta: { error, submitFailed }, beneficiarys = [], change }) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentStockholder, setCurrentStockholder] = useState();
  const [currentIndex, setCurrentIndex] = useState();
  const newBeneficiaryRef = useRef(null);

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  const handleClick = (beneficiary, index) => {
    setCurrentStockholder(beneficiary);
    setCurrentIndex(index);
    setOpenModal(true);
  };

  const handleNewBeneficiary = function() {
    fields.push(initBenefiaireEffectif());
    setTimeout(() => {
      newBeneficiaryRef.current.click();
    }, 150);
  }

  const closing = useCallback(() => setOpenModal(false), []);

  return (
    <>
      <div className="grid-x grid-margin-x grid-margin-y fields-area">
        {fields &&
          fields.map((beneficiary, index) => (
            <div className="grid-x cell" key={`${beneficiary}-${index}`}>
              <div className="edition-beneficaire medium-11">
                <h4  ref={newBeneficiaryRef} onClick={() => handleClick(beneficiary, index)}>
                  <img className="edition-beneficiaire-icon" src="/assets/img/icons/edit.png" alt="edition bénéficiaire effectif" />
                  Bénéficiaire N° {index + 1}: {beneficiarys[index].prenom} {beneficiarys[index].nom}
                </h4>
              </div>
              <div className="suppression-bénéficiaire medium-1">
                <button type="button" onClick={() => fields.remove(index)}>
                  <img src="/assets/img/icons/white-cross.png" alt="suppression bénéficiaire effectif" />
                </button>
              </div>
            </div>
          ))}
      </div>
      {fields && fields.length < 4 && (
        <div className="ajout-beneficiaire">
          <button type="button" onClick={handleNewBeneficiary} className="ajout-beneficiaire">
            <img src="/assets/img/icons/ges/red-add.svg" alt="information tooltip" /> Ajouter un Bénéficiaire Effectif
          </button>
        </div>
      )}
      {submitFailed && error && <span>{error}</span>}
      <Modal isOpen={openModal} style={modalBeneficiairesEffectifsStyle} onRequestClose={closing}>
        <div className="beneficiaire-modal">
          <div className="beneficaire-modal-header">
            <span className="beneficaire-modal-title">{`Bénéficiaire N° ${currentIndex + 1}`}</span>
            <img
              src="/assets/img/icons/white-cross.png"
              alt="croix de fermeture"
              className="beneficaire-modal-closing"
              onClick={closing}
            />
          </div>
          <BeneficiairesEffectifsFields
            beneficiaryName={currentStockholder}
            beneficiarys={beneficiarys}
            index={currentIndex}
            closing={closing}
            change={change}
            error={error}
          />
        </div>
      </Modal>
    </>
  );
};

const selectorForm = formValueSelector('annexeKyc');

const mapStateToProps = (state) => ({
  beneficiarys: selectorForm(state, 'actionnaires')
});

export default connect(mapStateToProps)(reduxForm({ form: 'annexeKyc' })(BeneficiairesEffectifsForm));

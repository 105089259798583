/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Internal imports */
import IdentiteForm from '../../../../components/US/epargne_salariale/forms/IdentiteForm';
import { URLS_EPARGNE } from "../../../../utils/constantes/ROUTES/EPARGNE";
import { getEpargneIdentiteFormSubmit } from '../../../../utils/US/components/epargne_salariale/forms/identiteUtils';
import { mettreAjourFonctionDirigeant, mettreAjourIdentiteDirigeant } from '../../../../services/UtilisateurService';
import { setDonneesDirigeant, setInformationsUtilisateur, setRetourRoute } from '../../../../modules/tunnel/redux/tunnelActions';

class EpargneIdentitePage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.props.setRetourRoute(URLS_EPARGNE.ENTREPRISE_COMPLEMENT.substring(1));
    this.state = {
      redirect: false
    };
  }

  async submit(values) {
    const utilisateurUid = this.props.tunnel.utilisateur.uid;
    const valuesAenvoye = getEpargneIdentiteFormSubmit(values, this.props.tunnel.entreprise.uid);

    try {
      await mettreAjourIdentiteDirigeant(utilisateurUid, valuesAenvoye);
      const infosFonctionDirigeant = await mettreAjourFonctionDirigeant(utilisateurUid, valuesAenvoye);
      this.props.setInformationsUtilisateur(infosFonctionDirigeant);
      this.props.setDonneesDirigeant(infosFonctionDirigeant);
      this.redirect();
    } catch (error) {
      this.setMessageErreur(error);
    }
  }

  redirect() {
    this.setState({
      redirect: true
    });
  }

  setMessageErreur(error) {
    if (error && error.message) {
      this.setState({
        errorMsg: error.message
      });
    }
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={URLS_EPARGNE.DISPOSITIF_ACTUEL} />;
    }

    return (
      <div>
        <IdentiteForm onSubmit={this.submit} />
      </div>
    );
  }
}

EpargneIdentitePage.propTypes = {
  setDonneesDirigeant: PropTypes.func.isRequired,
  setInformationsUtilisateur: PropTypes.func.isRequired,
  setRetourRoute: PropTypes.func.isRequired,
  tunnel: PropTypes.object.isRequired
};

// REDUX + REDUX FORM
const mapStateToProps = (state) => ({
  tunnel: state.tunnel
});

const mapDispatchToProps = (dispatch) => ({
  setInformationsUtilisateur: (utilisateur) => dispatch(setInformationsUtilisateur(utilisateur)),
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setDonneesDirigeant: (donneesDirigeant) => dispatch(setDonneesDirigeant(donneesDirigeant))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EpargneIdentitePage);

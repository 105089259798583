/* Internal imports */

import {
  ETAPES_DOSSIER_MAROQUINERIE,
  URLS_MAROQUINERIE
} from '../../../../utils/constantes/ROUTES/MAROQUINERIE';
import MaroquinerieEntreprisePage
  from '../../../../pages/private_pages/epargne/maroquinerie/MaroquinerieEntreprisePage';
import MaroquinerieEntrepriseComplementPage
  from '../../../../pages/private_pages/epargne/maroquinerie/MaroquinerieEntrepriseComplementPage';
import MaroquinerieDispositifActuelPage
  from '../../../../pages/private_pages/epargne/maroquinerie/MaroquinerieDispositifActuelPage';
import MaroquinerieDispositifAbondementPage
  from '../../../../pages/private_pages/epargne/maroquinerie/MaroquinerieDispositifAbondementPage';
import MaroquinerieIdentitePage from '../../../../pages/private_pages/epargne/maroquinerie/MaroquinerieIdentitePage';
import MaroquinerieTarificationPage
  from '../../../../pages/private_pages/epargne/maroquinerie/MaroquinerieTarificationPage';
import MaroquinerieFinalisationPage
  from '../../../../pages/private_pages/epargne/maroquinerie/MaroquinerieFinalisationPage';
import MaroquinerieInteressementPage
  from '../../../../pages/private_pages/epargne/maroquinerie/MaroquinerieInteressementPage';
import MaroquinerieParticipationPage
  from '../../../../pages/private_pages/epargne/maroquinerie/MaroquinerieParticipationPage';
import MaroquinerieKycPage from '../../../../pages/private_pages/epargne/maroquinerie/MaroquinerieKycPage';

export const getMaroquinerieArborescence = () => {
  return [
    {
      path: URLS_MAROQUINERIE.ENTREPRISE_INFORMATION,
      component: MaroquinerieEntreprisePage,
      etapeIndex: ETAPES_DOSSIER_MAROQUINERIE.ENTREPRISE_INFORMATION
    },
    {
      path: URLS_MAROQUINERIE.ENTREPRISE_SALARIES,
      component: MaroquinerieEntrepriseComplementPage,
      etapeIndex: ETAPES_DOSSIER_MAROQUINERIE.ENTREPRISE_COMPLEMENT
    },
    {
      path: URLS_MAROQUINERIE.IDENTITE,
      component: MaroquinerieIdentitePage,
      etapeIndex: ETAPES_DOSSIER_MAROQUINERIE.IDENTITE
    },
    {
      path: URLS_MAROQUINERIE.DISPOSITIF_ACTUEL,
      component: MaroquinerieDispositifActuelPage,
      etapeIndex: ETAPES_DOSSIER_MAROQUINERIE.DISPOSITIF_ACTUEL
    },
    {
      path: URLS_MAROQUINERIE.DISPOSITIF_ABONDEMENT,
      component: MaroquinerieDispositifAbondementPage,
      etapeIndex: ETAPES_DOSSIER_MAROQUINERIE.DISPOSITIF_ABONDEMENT
    },
    {
      path: URLS_MAROQUINERIE.INTERESSEMENT,
      component: MaroquinerieInteressementPage,
      etapeIndex: ETAPES_DOSSIER_MAROQUINERIE.INTERESSEMENT
    },
    {
      path: URLS_MAROQUINERIE.PARTICIPATION,
      component: MaroquinerieParticipationPage,
      etapeIndex: ETAPES_DOSSIER_MAROQUINERIE.PARTICIPATION
    },
    {
      path: URLS_MAROQUINERIE.KYC,
      component: MaroquinerieKycPage,
      etapeIndex: ETAPES_DOSSIER_MAROQUINERIE.KYC
    },
    {
      path: URLS_MAROQUINERIE.TARIFICATION,
      component: MaroquinerieTarificationPage,
      etapeIndex: ETAPES_DOSSIER_MAROQUINERIE.TARIFICATION
    },
    {
      path: URLS_MAROQUINERIE.FINALISATION,
      component: MaroquinerieFinalisationPage,
      etapeIndex: ETAPES_DOSSIER_MAROQUINERIE.INFORMATIONS
    }
  ]
}

import { URL_SUBSCRIPTION_TYPE } from './ROUTES';

export const URLS_ASSURANCE = {
  SIRET: URL_SUBSCRIPTION_TYPE.ASSURANCE + '/siret',
  ENTREPRISE_INFORMATION: URL_SUBSCRIPTION_TYPE.ASSURANCE + '/informations',
  IDENTITE: URL_SUBSCRIPTION_TYPE.ASSURANCE + '/representant',
  PPE: URL_SUBSCRIPTION_TYPE.ASSURANCE + '/ppe',
  GARANTES_PREVOYANCE: URL_SUBSCRIPTION_TYPE.ASSURANCE + "/garanties-prevoyance",
  CATEGORIES_SALARIES: URL_SUBSCRIPTION_TYPE.ASSURANCE + "/categories-salaries",
  KYC: URL_SUBSCRIPTION_TYPE.ASSURANCE + '/kyc',
  MOYENS_PAIEMENT: URL_SUBSCRIPTION_TYPE.ASSURANCE + '/moyens-de-paiement',
  TARIFICATION: URL_SUBSCRIPTION_TYPE.ASSURANCE + '/tarification',
  VERIFICATION_WLF: URL_SUBSCRIPTION_TYPE.ASSURANCE + '/verification-wlf',
  FINALISATION: URL_SUBSCRIPTION_TYPE.ASSURANCE + '/finalisation'
};

export const ETAPES_DOSSIER_ASSURANCE = {
  SIRET: 0,
  ENTREPRISE_INFORMATION: 1,
  IDENTITE: 2,
  PPE: 3,
  GARANTIES_PREVOYANCE: 4,
  MODALITES_PERO: 4,
  DISPOSITIF_ACTUEL: 4,
  CATEGORIES_SALARIES: 5,
  KYC: 6,
  MOYENS_PAIEMENT: 7,
  TARIFICATION: 8,
  INFORMATIONS: 9,
  EN_ATTENTE_DE_SIGNATURE: 9,
  TRANSMIS: 10
};

export const SECTIONS_ASSURANCE = {
  ENTREPRISE: {
    URL: URLS_ASSURANCE.ENTREPRISE_INFORMATION,
    INDEX: 0,
    ETAPE: ETAPES_DOSSIER_ASSURANCE.ENTREPRISE_INFORMATION
  },
  BESOIN: {
    URL: URLS_ASSURANCE.PPE,
    INDEX: 1,
    ETAPE: ETAPES_DOSSIER_ASSURANCE.PPE
  },
  ADHESION: {
    URL: URLS_ASSURANCE.TARIFICATION,
    INDEX: 2,
    ETAPE: ETAPES_DOSSIER_ASSURANCE.TARIFICATION
  }
};

/* built-in imports */
import React from 'react';

/* third-party imports */
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

/* Internal imports */
import { COLOR } from '../../../utils/constantes/DS/TYPOGRAPHY';

const TypographyRoot =  styled(Typography, { name: 'Typography' })(() => ({
  fontFamily: 'var(--cn-font-family-source-pro-sans-serif)',
  '&.MuiTypography-h1, &.MuiTypography-h2, &.MuiTypography-h3, &.MuiTypography-h4, &.MuiTypography-h5': {
    fontFamily: 'var(--cn-font-family-playfair-display-serif)'
  },
  '&.MuiTypography-h1': {
    fontSize: 'var(--cn-typo-font-size-large)'
  },
  '&.MuiTypography-h2': {
    fontSize: 'var(--cn-typo-font-size-medium)'
  }
}));

const TypographyCustom = ({ title, warning, indicator, info, color = COLOR.DEFAULT, children, sx, ...other }) => {
  const getStyle = () => {
    let style = { color: color };

    if (warning) {
      style.fontSize = 'var(--cn-typo-font-size-tiny)';
      style.fontStyle = 'italic';
      style.color = 'var(--cn-typo-warning-color)';
    } else if (info || title) {
      style.fontSize = 'var(--cn-typo-font-size-small)';
    }

    if(sx && Object.keys(sx).length > 0) {
      style = Object.assign(sx, style);
    }

    return style;
  }

  return (
    <TypographyRoot {...other} sx={getStyle}>
      {indicator && '* '}{children}
    </TypographyRoot>
  );
}

TypographyCustom.propTypes = {
  title: PropTypes.bool,
  warning: PropTypes.bool,
  indicator: PropTypes.bool,
  color: PropTypes.string
}

export default TypographyCustom;
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ButtonRoot =  styled(Button, { name: 'Button' })(() => ({
  fontFamily: 'var(--cn-font-family-source-pro-sans-serif)',
  '&:hover': {
    backgroundColor: 'var(--cn-color-primary-opacity-04)',
  },
  '&:active': {
    backgroundColor: 'var(--cn-color-primary-opacity-04)',
  },
  '&:focus': {
    backgroundColor: 'var(--cn-color-primary-opacity-04)',
  },
  '&.MuiButton-root': {
    '&.MuiButton-outlined': {
      color: 'var(--cn-color-high-emphasis)',
      border: '1px solid var(--cn-color-high-emphasis)',
    },
    '&.MuiButton-contained': {
      backgroundColor: 'var(--cn-color-high-emphasis)',
      '&:hover': {
        backgroundColor: 'var(--cn-color-primary-opacity-04)',
        color: 'var(--cn-color-high-emphasis)',
      },
      '&.Mui-disabled': {
        backgroundColor: 'var(--cn-color-grey-150)',
        '&:hover': {
          backgroundColor: 'var(--cn-color-grey-150)',
          color: 'var(--cn-color-grey-200)',
        },
      }
    },
    '&.Mui-disabled': {
      pointerEvents: 'all',
      '&:hover': {
        backgroundColor: 'inherit',
        cursor: 'not-allowed',
      },
    }
  },
}));

export default ButtonRoot;
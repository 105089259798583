export const SIZE = {
  SMALL: "3rem",
  MEDIUM: "3.5rem",
  LARGE: "4.5rem",
}

export const TEXT_PLACEMENT = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right"
}
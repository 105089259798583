export const style = {
  title: {
    fontSize: '1rem',
    color: '#6f7072',
    textTransform: 'uppercase',
    fontFamily: 'Source Sans Pro'
  },
  field: {
    color: '#000000'
  },
  value: {
    fontSize: '1rem',
    color: '#425668',
    marginLeft: '8px'
  }
};
